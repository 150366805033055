export const PAYMENT_TYPE = {
  CREDIT: 'CREDIT',
  CASH: 'CASH',
}

export const PERSON_TYPE = {
  INDIVIDUAL: 'INDIVIDUAL',
  CORPORATION: 'CORPORATION',
}

export const GENDER = {
  MALE: 'MALE',
  FEMALE: 'FEMALE',
}

export const a = {}
