import gql from 'graphql-tag'

export const REMAIN_JOB_SUBSCRIPTION = gql`
  subscription remainingTaskSub {
    remainingTaskSub {
      success
      message
      data {
        remainingJob {
          po
          gr
          pt
          accountant
          tax
        }
        remainingAccountant {
          remainingDepositReceipt
          remainingInvoice
          remainingBillingNote
          remainingCashReceipt
          remainingCreditReceipt
          totalRemainingIncome
          remainingPaymentNote
          totalRemainingOutcome
        }
        remainingTax {
          waitingTaxInvoice
          waitingDeliver
          waitingIVConfirm
          taxInvoiced
          all
        }
      }
    }
  }
`

export const PO_SUBSCRIPTION = gql`
  subscription purchaseOrderChangeSub {
    purchaseOrderChangeSub {
      success
      message
      data {
        id
        number
        status
        dueDate
        supplier {
          id
          name
        }
        confirmable
        createdAt
      }
    }
  }
`

export const TAX_SUBSCRIPTION = gql`
  subscription taxInvoiceChangeSub {
    taxInvoiceChangeSub {
      success
      message
      data {
        id
        number
        ivNumber
        status
        invoice {
          id
          number
        }
        receipt {
          id
          number
        }
      }
    }
  }
`

export default REMAIN_JOB_SUBSCRIPTION
