import React, { useState, useEffect, useContext } from 'react'
import numeral from 'numeral'
import { useQuery } from '@apollo/client'
import { useBarcode } from 'react-barcodes'
import _, { first, isEmpty, isNil } from 'lodash'
import {
  Div, P,
} from '@konsys-ui-custom'
import { Row, Col } from 'antd'
import { theme } from '../../styles/_variables'
import {
  LargeTextWithLabel, Tag, Collapse, ComponentWithLabelLarge, Loading,
} from '../../components'
import { PartImage, ThumbnailImage } from './styled'
import imageNotAvailable from '../../assets/image-not-available.png'
import { INVENTORY } from '../../api/query'
import { AuthorizationContext } from '../../store/StoreProvider'

const Info = ({
  data: {
    sku, productName, inventoryOption, barcode,
  },
}) => {
  const { authorization } = useContext(AuthorizationContext)
  const { inputRef } = !isEmpty(barcode) ? useBarcode({
    value: barcode,
  }) : {}
  return (
    <Div width='100%'>
      <Row type='flex' style={{ margin: '16px 0' }}>
        <Col span={8}>
          <LargeTextWithLabel title={{ text: 'รหัสสินค้า' }} content={{ text: sku || 'ไม่ระบุ' }} />
        </Col>
      </Row>
      <Row type='flex' style={{ margin: '16px 0' }}>
        <Col span={8}>
          <LargeTextWithLabel title={{ text: 'ชื่อสินค้า' }} content={{ text: productName || 'ไม่ระบุ' }} />
        </Col>
        {
          authorization.inventory.viewCostChangeLog
        && <Col span={8}>
          <LargeTextWithLabel title={{ text: 'ราคาซื้อ' }} content={{ text: `${numeral(inventoryOption.buyPrice).format('0,0.00')} บาท` || 'ไม่ระบุ' }} />
        </Col>
        }
        <Col span={8}>
          <LargeTextWithLabel title={{ text: 'ราคาขาย' }} content={{ text: `${numeral(first(inventoryOption.pack)?.salesPrice).format('0,0.00')} บาท` || 'ไม่ระบุ' }} />
        </Col>
      </Row>
      <Row type='flex' style={{ margin: '16px 0' }}>
        <Col span={8}>
          <LargeTextWithLabel title={{ text: 'สต๊อกขั้นต่ำ' }} content={{ text: inventoryOption.minimumStock || 'ไม่ระบุ' }} />
        </Col>
        <Col span={8}>
          <LargeTextWithLabel title={{ text: 'สต๊อกแนะนำ' }} content={{ text: inventoryOption.maximumStock || 'ไม่ระบุ' }} />
        </Col>
      </Row>
      {
        !isEmpty(barcode)
        && <div id='div-svg' style={{ cursor: 'pointer', width: 'fit-content', display: !isNil(barcode) ? 'block' : 'none' }}>
          <svg id='barcode-canvas' ref={inputRef} onClick={() => {
            const container = document.getElementById('div-svg')
            const width = '100%'
            const height = '100%'
            const printWindow = window.open('', 'PrintMap',
              `width=${ width },height=${ height}`)
            printWindow.document.writeln(container.innerHTML)
            printWindow.document.close()
            printWindow.print()
            printWindow.close()
          }}/>
        </div>
      }
    </Div>
  )
}

const Tags = ({ data: { tag } }) => (
  <Div>
    <P bold margin='16px 0'>แท็ก</P>
    <Div margin='0 -8px 16px -8px'>
      {
        tag.map((v) => <Tag key={v.id} margin='4px 8px' text={v.name} />)
      }
      {
        _.size(tag) < 1 && <P margin='4px 8px'>-</P>
      }
    </Div>
  </Div>
)

const StockCollapse = ({
  setIsOpenStock, isOpenStock, item, stockLocations,
}) => (
  <Collapse open={isOpenStock} text='สต๊อกในแต่ละสถานที่เก็บ' onClick={() => setIsOpenStock(!isOpenStock)} fullHeight={true} margin='8px 0'>
    <Row>
      <Col span={6} style={{ margin: '0 0 8px 0' }}>
        <ComponentWithLabelLarge title={{ text: 'สินค้าในคลัง' }}>
          <P bold color={theme.color.warning}>{item.totalStock}</P><P margin='0 0 0 4px'>ชิ้น</P>
        </ComponentWithLabelLarge>
      </Col>
      <Col span={6} style={{ margin: '0 0 8px 0' }}>
        <ComponentWithLabelLarge title={{ text: 'สินค้าที่ขายได้' }}>
          <P bold color={theme.color.success}>{item.totalStock - item.soldStock}</P><P color={theme.color.info} bold>({item.toBeStocked >= 0 && '+'}{item.toBeStocked})</P><P margin='0 0 0 4px'>ชิ้น</P>
        </ComponentWithLabelLarge>
      </Col>
      <Col span={6} style={{ margin: '0 0 8px 0' }}>
        <ComponentWithLabelLarge title={{ text: 'รอรับเข้าคลัง' }}>
          <P color={theme.color.info} bold>({item.toBeStocked >= 0 && '+'}{item.toBeStocked})</P><P margin='0 0 0 4px'>ชิ้น</P>
        </ComponentWithLabelLarge>
      </Col>
    </Row>
    <Row gutter={16}>
      {
        stockLocations.map((v, i) => (
          <Col key={i} span={8}>
            <Div padding='16px' style={{ border: `1px solid ${theme.color.lightBlue}`, borderRadius: 5, background: '#fafafa' }} margin='8px 0'>
              <Row key={i} type='flex' style={{ margin: '8px 0' }}>
                <Col span={24} style={{ margin: '0 0 8px 0' }}>
                  <LargeTextWithLabel title={{ text: 'สถานที่เก็บ' }} content={{ text: v.warehouse ? v.warehouse.warehouseName : 'ไม่ระบุ', bold: true }} />
                </Col>
              </Row>
              <Row>
                <Col span={24} style={{ margin: '0 0 8px 0' }}>
                  <ComponentWithLabelLarge title={{ text: 'สินค้าในคลัง' }}>
                    <P bold color={theme.color.warning}>{v.totalStock}</P><P margin='0 0 0 4px'>ชิ้น</P>
                  </ComponentWithLabelLarge>
                </Col>
              </Row>
            </Div>
          </Col>
        ))
      }
    </Row>
  </Collapse>
)

const PackDetail = (props) => {
  const [isOpenStock, setIsOpenStock] = useState(true)

  const [data, setData] = useState()
  const [imageIndex, setImageIndex] = useState(0)
  const { loading: inventoryLoading, data: inventoryDetail } = useQuery(INVENTORY, { variables: { id: props.data.id } })

  useEffect(() => {
    setData(inventoryDetail?.inventoryInfo?.data)
  }, [inventoryDetail?.inventoryInfo?.data])

  if (inventoryLoading) {
    return (
      <Div textAlign='center' margin='32px 0 0'>
        <Loading size='small' />
      </Div>
    )
  }
  if (_.isEmpty(data)) return null

  return (
    <Div>
      <Row type='flex' gutter={24} style={{ marginBottom: 16 }}>
        <Col xs={24} md={24} lg={24} xl={14} xxl={10} style={{ display: 'flex', flexDirection: 'column' }}>
          <PartImage src={data.inventoryOption?.files[imageIndex]?.url || imageNotAvailable} alt='' />
          {
            data.inventoryOption?.files?.length > 2
            && <Div display='flex' flexDirection='row' width='250px' justifyContent='center'>
              {
                data.inventoryOption?.files.map((file, i) => (
                  <Div key={i} onClick={() => setImageIndex(i)}>
                    <ThumbnailImage src={file.url}/>
                  </Div>
                ))
              }
            </Div>
          }
        </Col>
        <Col xs={24} md={24} lg={24} xl={10} xxl={14}><Info data={data}/></Col>
      </Row>
      <Tags data={data}/>
      <StockCollapse isOpenStock={isOpenStock} setIsOpenStock={setIsOpenStock} item={data.inventoryOption} stockLocations={data.inventoryOption.stockLocation}/>
    </Div>
  )
}

export default ({ data }) => <PackDetail data={data}/>
