import * as xlsx from 'xlsx/xlsx.mjs'
import { filter, findKey, first, isNaN, isNil, isNumber, keys, slice, split, toNumber, values } from 'lodash'
import { message } from 'antd'
import dayjs from 'dayjs'

const convertStringtoDate = (str) => {
  const splitDate = split(str, '/')
  return new Date(splitDate[2], splitDate[1] - 1, splitDate[0])
}

export const readExcelFile = (file, type, setData) => {
  if (file.type !== 'application/octet-stream' && file.type !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
    message.error('Please upload file with xlsx format.')
    return []
  }
  const reader = new FileReader()

  reader.onload = (e) => {
    const data = e.target.result
    const workbook = xlsx.read(data, {
      type: 'binary',
    })

    workbook.SheetNames.forEach((sheetName) => {
      const xlRowObject = xlsx.utils.sheet_to_row_object_array(workbook.Sheets[sheetName])
      switch (type) {
        case 'KERRY':
        {
          const trackingNumberKey = findKey(xlRowObject[3], (o) => o === 'เลขที่พัสดุ') || findKey(xlRowObject[4], (o) => o === 'เลขที่พัสดุ')
          const soNumberKey = findKey(xlRowObject[3], (o) => o === 'เลขอ้างอิง 1') || findKey(xlRowObject[4], (o) => o === 'เลขอ้างอิง 1')
          setData(filter(xlRowObject, (o) => !isNaN(toNumber(o[first(keys(o))])) && !isNil(o[soNumberKey]) && !isNil(o[trackingNumberKey])).map((o) => ({
            soNumber: o[soNumberKey],
            trackingNumber: o[trackingNumberKey],
          })))
          break
        }
        case 'KERRY_COD':
        {
          const keyRows = xlRowObject[0]
          const trackingNumberKey = findKey(keyRows, (o) => o === 'Con No.')
          const codCorrectedKey = findKey(keyRows, (o) => o === 'COD Collected')
          const codRemitDateKey = findKey(keyRows, (o) => o === 'COD Remit Date')

          setData(filter(xlRowObject, (o) => !isNil(o?.[trackingNumberKey]) && !isNil(o?.[codCorrectedKey]) && o?.[codCorrectedKey] > 0).map((o) => {
            const codRemitDate = isNumber(o?.[codRemitDateKey])
              ? new Date(Math.round((o?.[codRemitDateKey] - 25569) * 86400 * 1000))
              : convertStringtoDate(o?.[codRemitDateKey])
            return ({
              trackingNumber: o?.[trackingNumberKey],
              codAmount: parseFloat(o?.[codCorrectedKey]),
              codRemitDate,
            })
          }))
          break
        }
        default: setData([])
      }
    })
  }

  reader.onerror = (ex) => {
    console.error(ex)
  }

  reader.readAsBinaryString(file)

  return null
}

export default readExcelFile
