import { theme } from '../../styles/_variables'

export const INV_KEYS = {
  waitingPayment: 'WAITING_PAYMENT',
  paymentOverdue: 'PAYMENT_OVERDUE',
  paid: 'PAID',
  returned: 'RETURNED',
}

export const INV_STATUS = [
  {
    key: INV_KEYS.waitingPayment,
    text: 'รอชำระ',
    color: theme.color.gray50,
    icon: 'fal fa-check-circle',
  }, {
    key: INV_KEYS.paymentOverdue,
    text: 'เกินกำหนดชำระ',
    color: theme.color.error,
    icon: 'fal fa-check-circle',
  }, {
    key: INV_KEYS.paid,
    text: 'ชำระเเล้ว',
    color: theme.color.success,
    icon: 'fal fa-check-circle',
  }, {
    key: INV_KEYS.returned,
    text: 'คืนสินค้า',
    color: theme.color.gray50,
    icon: 'fal fa-check-circle',
  },
]

export default INV_STATUS
