import React, { useState, useEffect } from 'react'
import numeral from 'numeral'
import { useQuery } from '@apollo/client'
import _, { sumBy } from 'lodash'
import { Row, Col, Divider } from 'antd'
import {
  Div, P, SmallText, H3, Icon, H4,
} from '@konsys-ui-custom'
import moment from 'moment-timezone'
import { LargeTextWithLabel, Remark, Loading } from '../../../components'
import { theme } from '../../../styles/_variables'
import { renderText } from '../../../utils/util-services'
import { GET_RECEIPT } from '../../../api/query'
import history from '../../../history'
import { PATH } from '../../../constants/path'
import { TAX_INVOICE_TYPE } from '../../../constants/enum'

export default (props) => {
  const [data, setData] = useState()
  const { loading: receiptLoading, data: receiptInfoDetail } = useQuery(GET_RECEIPT, { variables: { id: props.data?.id }, skip: !_.isEmpty(props.receiptData) })

  const receiptDetail = receiptInfoDetail || props.receiptData
  const isShowInTaxInvoice = !_.isEmpty(props.receiptData)

  useEffect(() => {
    setData(receiptDetail?.receiptInfo?.data)
  }, [receiptDetail?.receiptInfo?.data])

  let count = 0
  if (receiptLoading) {
    return (
      <Div textAlign='center' margin='32px 0 0'>
        <Loading size='small' />
      </Div>
    )
  }
  if (_.isEmpty(data)) return null

  const lotSelect = data.lots || [data.lot]

  const taxInvoiceType = data?.taxInvoiceType || data?.taxInvoice?.taxInvoiceType
  const filterCreator = _.filter(data?.lots, (o) => !_.isEmpty(o.salesOrder?.creator))
  const creator = data?.lot?.salesOrder?.creator || filterCreator[0]?.salesOrder?.creator

  return (
    <Div display='flex' flexDirection='column'>
      <Div display='flex' justifyContent='space-between' margin='0 0 40px 0'>
        <Div>
          <H3 bold color={theme.color.primaryColor}>{data.number}</H3>
          {
            data.ivNumber
            && <Div margin='8px 0 0'>
              <SmallText display='block'>IV number</SmallText>
              <H4 bold color={theme.color.error}>{data.ivNumber || '-'}</H4>
            </Div>
          }
        </Div>
      </Div>
      <Row gutter={16} type='flex' style={{ marginBottom: 16 }}>
        <Col span={8}>
          <LargeTextWithLabel title={{ text: 'วันที่สร้าง' }} content={{ text: moment(data.createdAt).format('DD/MM/YYYY') }} />
        </Col>
        <Col span={8}>
          <LargeTextWithLabel title={{ text: 'ลูกค้า' }} content={{ text: `${data?.customer?.prefix ? `${data?.customer?.prefix} ` : ''}${data?.customer?.name}` }} />
        </Col>
      </Row>
      {
        data && ((data.lots && (_.some(_.flatten(data.lots.map((lot) => lot.items)), (o) => o.requireTaxInvoice))) || data?.lot)
        && <Row gutter={16} type='flex' style={{ marginBottom: 16 }}>
          {/* <Col span={8}>
            <LargeTextWithLabel margin='0 16px 0 0' title={{ text: 'ประเภทใบกำกับภาษี' }} content={{ text: _.find(TAX_INVOICE_TYPE, (v) => v.value === taxInvoiceType)?.text || '-' }} />
          </Col> */}
          <Col span={8}>
            <LargeTextWithLabel title={{ text: 'Sale ที่สร้าง SO นี้' }} content={{ text: _.isEmpty(creator) ? '-' : `${creator?.firstName} ${creator?.lastName}` }} />
          </Col>
        </Row>
      }
      {
        (!_.isEmpty(props?.receiptData?.receiptInfo?.data?.trackingNumber) || !_.isEmpty(props?.receiptData?.receiptInfo?.data?.providerName))
        && <Row gutter={16} type='flex' style={{ marginBottom: 16 }}>
          <Col span={8}>
            <LargeTextWithLabel margin='0 16px 0 0' title={{ text: 'ผู้ให้บริการ' }} content={{ text: props?.receiptData?.receiptInfo?.data?.providerName || '-' }} />
          </Col>
          <Col span={8}>
            <LargeTextWithLabel title={{ text: 'เลข Tracking Number' }} content={{ text: props?.receiptData?.receiptInfo?.data?.trackingNumber || '-' }} />
          </Col>
        </Row>
      }
      {
        data && data.lots && (_.some(_.flatten(data.lots.map((lot) => lot.items)), (o) => o.requireTaxInvoice))
          ? <Div margin='0 24px 16px 0' padding='16px' bgColor={theme.color.lightBlue}>
            <Icon icon='far fa-file-check' margin='0 8px 0 0' /><SmallText bold margin='0 0 8px'>ต้องการใบกำกับภาษี</SmallText>
            {
              _.filter(_.flatten(data.lots.map((lot) => ({ items: [...lot.items], billingAddress: lot?.billingAddress }))), (lot) => _.some(lot.items, (item) => item.requireTaxInvoice)).map((lot) => (
                <P key={lot?.billingAddress?.id} display='block' margin='0 0 4px'>{`${renderText(lot?.billingAddress?.contactName, true)} ${renderText(lot?.billingAddress?.taxId, true)} ${renderText(lot?.billingAddress?.addressNo, true)} ${renderText(lot?.billingAddress?.subDistrict, true)} ${renderText(lot?.billingAddress?.district, true)} ${renderText(lot?.billingAddress?.province, true)} ${renderText(lot?.billingAddress?.postcode, true)} ${renderText(lot?.billingAddress?.contactPhoneNumber, true)}`}</P>
              ))
            }
          </Div>
          : data.lot
          && <Div margin='0 24px 16px 0' padding='16px' bgColor={theme.color.lightBlue}>
            <Icon icon='far fa-file-check' margin='0 8px 0 0' /><SmallText bold margin='0 0 8px'>ต้องการใบกำกับภาษี</SmallText>
            <P key={data?.lot?.billingAddress?.id} display='block' margin='0 0 4px'>{`${renderText(data?.lot?.billingAddress?.contactName, true)} ${renderText(data?.lot?.billingAddress?.taxId, true)} ${renderText(data?.lot?.billingAddress?.addressNo, true)} ${renderText(data?.lot?.billingAddress?.subDistrict, true)} ${renderText(data?.lot?.billingAddress?.district, true)} ${renderText(data?.lot?.billingAddress?.province, true)} ${renderText(data?.lot?.billingAddress?.postcode, true)} ${renderText(data?.lot?.billingAddress?.contactPhoneNumber, true)}`}</P>
          </Div>
      }
      {
        (!_.isEmpty(data?.purchaseOrderList) || !_.isEmpty(data?.goodsReceiveList))
        && <Div margin='0 0 16px'>
          {
            !_.isEmpty(data?.purchaseOrderList)
            && <Div>
              <Div display='flex' width='100%'>
                <SmallText bold style={{ width: '30%' }} margin='0 16px 0 0'>ใบ PO</SmallText>
                <SmallText bold>PO Ref</SmallText>
              </Div>
              {
                data?.purchaseOrderList?.map((obj, i) => (
                  <Div width='100%' key={obj?.id} display='flex' margin={i !== 0 ? '4px 0 0' : '0'}>
                    <P style={{ width: '30%', color: theme.color.secondaryColor, cursor: 'pointer' }} margin='0 16px 0 0' onClick={() => {
                      history.push({
                        pathname: PATH.poList,
                        state: { search: obj?.number },
                      })
                    }}>{obj?.number}</P>
                    <P>{obj?.extRef}</P>
                  </Div>
                ))
              }
            </Div>
          }
          {
            !_.isEmpty(data?.goodsReceiveList)
            && <Div width='100%' margin={!_.isEmpty(data?.purchaseOrderList) ? '16px 0 0' : '0'}>
              <Div display='flex'>
                <SmallText bold style={{ width: '30%' }} margin='0 16px 0 0'>ใบ GR</SmallText>
                <SmallText bold>Invoice Reference</SmallText>
              </Div>
              {
                data?.goodsReceiveList?.map((obj, i) => (
                  <Div width='100%' key={obj?.id} display='flex' margin={i !== 0 ? '4px 0 0' : '0'}>
                    <P style={{ width: '30%', color: theme.color.secondaryColor, cursor: 'pointer' }} margin='0 16px 0 0' onClick={() => {
                      history.push({
                        pathname: PATH.gr,
                        state: { search: obj?.number },
                      })
                    }}>{obj?.number}</P>
                    <P>{obj?.refId || '-'}</P>
                  </Div>
                ))
              }
            </Div>
          }
        </Div>
      }
      { !_.isNil(data.pickingTicket) && _.size(data.pickingTicket) > 0 && <Remark style={{ marginBottom: 24 }} showIcon={true} message='การจัดส่งที่นำส่ง' description={data.pickingTicket?.number} /> }
      <Div>
        <P bold>รายละเอียด</P>
        <Divider />
        {
          data.paymentType === 'CREDIT'
            ? <Div>
              <Row gutter={16} type='flex' style={{ marginBottom: 8 }}>
                <Col span={1} />
                <Col span={5}>
                  <P bold>Invoice</P>
                </Col>
                <Col span={5}>
                  <P bold>เลขที่ SO</P>
                </Col>
                <Col span={5}>
                  <P bold>เลขที่ PT</P>
                </Col>
                <Col span={5}>
                  <P bold>ราคารวม</P>
                </Col>
              </Row>
              {
                data.lots.map((v) => {
                  count += 1
                  return (
                    <Row key={v.id} gutter={16} type='flex' style={{ marginBottom: 8 }}>
                      <Col span={1}>
                        <SmallText>{count}.</SmallText>
                      </Col>
                      <Col span={5}>
                        <SmallText>{v?.invoice?.number ? v?.invoice?.number : '-'}</SmallText>
                      </Col>
                      <Col span={5}>
                        <SmallText>#{v.salesOrder.soNumber}</SmallText>
                      </Col>
                      <Col span={5}>
                        <SmallText>#{v.pickingTicket.number}</SmallText>
                      </Col>
                      <Col span={5}>
                        <SmallText>{numeral(v.subtotal).format('0,0.00')} บาท</SmallText>
                      </Col>
                    </Row>
                  )
                })
              }
              {
                !_.isEmpty(data.debtItemList) && data.debtItemList.map((v) => {
                  count += 1
                  return (
                    <Row key={v.id} gutter={16} type='flex' style={{ marginBottom: 8 }}>
                      <Col span={1}>
                        <SmallText>{count}.</SmallText>
                      </Col>
                      <Col span={5}>
                        <SmallText color={v.type === 'DN' ? theme.color.success : theme.color.error}>#{v.number}</SmallText>
                      </Col>
                      <Col span={5}>
                        <SmallText>-</SmallText>
                      </Col>
                      <Col span={5}>
                        <SmallText>-</SmallText>
                      </Col>
                      <Col span={5}>
                        <SmallText>{numeral(Math.abs(v.totalPriceBefore - v.totalPriceAfter)).format('0,0.00')} บาท</SmallText>
                      </Col>
                    </Row>
                  )
                })
              }
            </Div>
            : <Div>
              <Row gutter={16} type='flex' style={{ marginBottom: 8 }}>
                <Col span={1} />
                <Col span={5}>
                  <P bold>ชื่อสินค้า</P>
                </Col>
                <Col span={2}>
                  <P bold>จำนวน</P>
                </Col>
                <Col span={3}>
                  <P bold>ราคา/หน่วย</P>
                </Col>
                <Col span={3}>
                  <P bold>ส่วนลด</P>
                </Col>
                <Col span={4}>
                  <P bold>ราคารวม</P>
                </Col>
                <Col span={3}>
                  <P bold>{isShowInTaxInvoice ? 'Document ref.' : 'PT Ref.'}</P>
                </Col>
              </Row>
              {
                lotSelect.map((v) => (
                  v.items.map((item, i) => (
                    <Row key={item.id} gutter={16} type='flex' style={{ marginBottom: 8 }}>
                      <Col span={1}>
                        <SmallText>{i + 1}.</SmallText>
                      </Col>
                      <Col span={5}>
                        <SmallText>{item.variantName || '-'}</SmallText>
                        {
                          item.replaceTo
                          && <SmallText color={theme.color.gray50}>แทนสินค้า {item.replaceTo}</SmallText>
                        }
                        {
                          item.replacedBy
                          && <SmallText color={theme.color.gray50}>ถูกแทนที่ด้วย {item.replacedBy}</SmallText>
                        }
                      </Col>
                      <Col span={2}>
                        <SmallText>{item.quantity}</SmallText>
                      </Col>
                      <Col span={3}>
                        <SmallText>{numeral(item.salesPrice).format('0,0.00')}</SmallText>
                      </Col>
                      <Col span={3}>
                        <SmallText>{numeral(item.discount).format('0,0.00')} บาท</SmallText>
                      </Col>
                      <Col span={4}>
                        <SmallText>{numeral((item.salesPrice - item.discount) * item.quantity).format('0,0.00')} บาท</SmallText>
                      </Col>
                      <Col span={3}>
                        {
                          isShowInTaxInvoice
                            ? <Div>
                              <SmallText>#{v.salesOrder?.soNumber} (SO)</SmallText>
                              <SmallText>#{v.pickingTicket?.number} (PT)</SmallText>
                            </Div>
                            : <SmallText>#{v.pickingTicket?.number}</SmallText>
                        }
                      </Col>
                    </Row>
                  ))
                ))
              }
            </Div>
        }
        <Divider />
        <Row gutter={16} type='flex' style={{ marginBottom: 8 }}>
          <Col span={24} style={{ display: 'flex', justifyContent: 'space-between' }}>
            <SmallText bold color={theme.color.gray80} margin='0 16px 0 0'>รวมเงิน</SmallText>
            <SmallText bold color={theme.color.gray80}>{`${numeral(data.subtotal - sumBy(data.lots[0].items, (o) => o.discount * o.quantity)).format('0,0.00')} บาท`}</SmallText>
          </Col>
          <Col span={24} style={{ display: 'flex', justifyContent: 'space-between' }}>
            <SmallText bold color={theme.color.gray80} margin='0 16px 0 0'>ค่าขนส่ง</SmallText>
            <SmallText bold color={theme.color.gray80}>{`${numeral(data.deliveryCost).format('0,0.00')} บาท`}</SmallText>
          </Col>
          <Divider />
          <Col span={24} style={{ display: 'flex', justifyContent: 'space-between' }}>
            <P bold margin='0 16px 0 0'>ยอดชำระ</P>
            <P bold>{`${numeral(data.subtotal - sumBy(data.lots[0].items, (o) => o.discount * o.quantity) + data.deliveryCost).format('0,0.00')} บาท`}</P>
          </Col>
        </Row>
      </Div>
    </Div>
  )
}
