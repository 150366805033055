import { useMutation, useQuery } from '@apollo/client'
import {
  Div, Icon, P,
} from '@konsys-ui-custom'
import { Input } from 'antd'
import { includes } from 'lodash'
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { CREATE_TAG, REMOVE_TAG } from '../../api/mutation'
import { TAG_LIST } from '../../api/query'
import { Tab } from '../../components'
import { theme } from '../../styles/_variables'
import { showMessageMutation } from '../../utils'
import { isAlphanumeric } from '../../utils/util-services'

export default () => {
  const [inputVal, setInputVal] = useState('')
  const [tags, setTags] = useState([])
  const { data, loading, refetch } = useQuery(TAG_LIST)
  const [createTag] = useMutation(CREATE_TAG)
  const [removeTag] = useMutation(REMOVE_TAG)

  useEffect(() => {
    if (data && !loading) {
      setTags(data?.tagList?.data?.list || [])
    }
  }, [data])

  return (
    <Div height='100%'>
      <Helmet key='meta'>
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>
      <Tab
        current={0}
        fullHeight
        contentHeight='calc(100% - 38px)'
        title={
          [
            { id: 'tag', text: 'จัดการแท็ค', link: '#' },
          ]
        }
        noDefaultTab
        key='content'
      >
        <Div display='flex' flexDirection='column' padding={window.innerWidth <= 820 ? '0' : '0 60px'}>
          <Input style={{ lineHeight: '34px', marginBottom: 8, width: 300, borderColor: includes(tags.map((v) => v.name), inputVal) || !isAlphanumeric(inputVal) ? theme.color.error : theme.color.lightBlue }} value={inputVal} placeholder='กรอกแท็ค' onChange={(e) => setInputVal(e.target.value)} onPressEnter={(e) => {
            if (!includes(tags.map((v) => v.name), inputVal) && isAlphanumeric(e.target.value)) {
              createTag({ variables: {
                name: e.target.value,
              } }).then((resp) => showMessageMutation(resp.data?.createTag, () => refetch()))
              setInputVal('')
            }
          }} />
          <Div display='flex' margin='0 -8px' style={{ flexWrap: 'wrap' }}>
            {
              data?.tagList?.data?.list.map((v) => (
                <Div key={v.id} bgColor={theme.color.offWhite} padding='8px' margin='8px' style={{ border: `1px solid ${theme.color.gray50}`, borderRadius: 4 }}>
                  <P margin='0 8px 0 0'>{v.name}</P>
                  <Icon icon='far fa-times' color={theme.color.error} style={{ cursor: 'pointer' }} onClick={() => {
                    removeTag({ variables: {
                      id: v.id,
                    } }).then((resp) => showMessageMutation(resp.data?.removeTag, () => refetch()))
                  }}/>
                </Div>
              ))
            }
          </Div>
        </Div>
      </Tab>
    </Div>
  )
}
