import styled from 'styled-components'
import { Button } from '@konsys-ui-custom'
import Img from '../common/img'
import { theme } from '../../styles/_variables'
import MobileBG from '../../assets/login-background-mobile.png'

export const CustomButton = styled(Button)`
  width: calc(100vw * 200/1280);
  margin-top: calc(100vw * 32/1280);
  padding: calc(100vw * 8/1280) 0;
  @media (min-width: 1441px) {
    width: 170px;
    margin-top: 32px;
  }
  @media (max-width: 640px) {
    padding: 0;
    width: calc(100vw * 100/640);
    height: calc(100vw * 100/640);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    margin: 0;
    > i {
      font-size: calc(100vw * 32/640) !important;
    }
  }
  > i {
    color: #FFFFFF;
  }
  > p {
    font-family: 'Helvetica', 'Sarabun';
    font-size: calc(100vw * 16/1280);
    font-weight: 500;
    @media (min-width: 1441px) {
      font-size: 16px;
    }
  }
  &:disabled {
    background-color: #ABE8E1 !important;
    border: 1px solid #ABE8E1 !important;
    color: #FFFFFF !important;
    > i {
      color: #FFFFFF !important;
    }
    @media (max-width: 640px) {
      background-color: #6e6d6d !important;
      border: 1px solid #6e6d6d !important;
    }
  }
`

export const CustomInput = styled.input`
  font-family: 'Helvetica', 'Sarabun';
  border: none;
  border-bottom: 1px solid #65638A;
  color: #66647C;
  padding: 4px 0px 2px;
  outline: 0 !important;
  width: 100%;
  font-size: calc(100vw * 16/1280);
  line-height: calc(100vw * 27/1280);
  @media (min-width: 1441px) {
    font-size: 16px;
    line-height: 27px;
  }
  @media (max-width: 640px) {
    font-size: calc(100vw * 24/640);
    line-height: calc(100vw * 40/640);
    border-radius: 0;
    padding: 8px 0;
    border-bottom: 1px solid #C8C8C8;
  }
  &::placeholder {
    font-family: 'Helvetica', 'Sarabun';
    color: #797891;
    font-size: calc(100vw * 16/1280);
    line-height: calc(100vw * 27/1280);
    @media (min-width: 1441px) {
      font-size: 16px;
      line-height: 27px;
    }
    @media (max-width: 640px) {
      font-size: calc(100vw * 24/640);
      line-height: calc(100vw * 40/640);
      color: #C8C8C8;
    }
  }
`

export const LoginWrapper = styled.div`
  background-color: #f0f1f6;
  width: 100vw;
  height: 100vh;
  overflow: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  > div {
    max-width: 1440px;
    background-color: #FFFFFF;
    display: flex;
    @media (max-width: 640px) {
      max-width: 100vw;
      width: 100%;
      height: 100%;
      padding: 0 calc(100vw * 60/640) 0;
      position: relative;
    }
    > img {
      width: calc(100vw * 450/1280);
      @media (min-width: 1441px) {
        width: 500px;
      }
      @media (max-width: 640px) {
        display: none;
      }
    }
    > div {
      display: flex;
      align-items: center;
      justify-content: center;
      width: calc(100vw * 450/1280);
      @media (min-width: 1441px) {
        width: 500px;
      }
      @media (max-width: 640px) {
        align-items: flex-start;
        padding-top: calc(100vw * 610/640);
      }
      @media (max-width: 640px) {
        width: 100%;
        height: 100%;
        &::before {
          content: '';
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          height: calc(100vw * 550/640);
          background-image: url('${MobileBG}');
          background-size: 100%;
          background-repeat: no-repeat;
        }
        &::after {
          content: 'Welcome Back';
          position: absolute;
          top: calc(100vw * 210/640);
          left: calc(100vw * 60/640);
          font-family: sans-serif;
          // font-family: 'Josefin Sans', sans-serif;
          color: #FFFFFF;
          font-weight: 600;
          font-size: calc(100vw * 65/640);
          width: calc(100vw * 400/640);
          line-height: calc(100vw * 70/640);
        }
        &.forgot-password {
          &::after {
            content: 'Forgot Password';
          }
        }
        &.reset-password {
          &::after {
            content: 'Reset Password';
          }
        }
      }
      > div {
        width: calc(100vw * 300/1280);
        position: relative;
        z-index: 2;
        @media (min-width: 1441px) {
          width: 300px;
        }
        @media (max-width: 640px) {
          width: 100%;
        }
        > div > h4,
        > h4 {
          font-family: 'Work Sans';
          margin: 0;
          font-size: calc(100vw * 48/1280);
          font-weight: 700;
          margin-bottom: calc(100vw * 32/1280);
          @media (min-width: 1441px) {
            font-size: 48px;
            margin-bottom: 32px;
          } 
          @media (max-width: 640px) {
            display: none;
          }
        }
        > div {
          > h4 {
            display: none;
            margin: 0;
            font-size: calc(100vw * 48/640);
          }
          @media (max-width: 640px) {
            margin-top: calc(100vw * 80/640);
            > h4 {
              color: #474646;
              font-family: sans-serif;
              // font-family: 'Josefin Sans', sans-serif;
              display: block;
            }
          }
        }
        > input {
          &:nth-child(2) {
            margin-bottom: calc(100vw * 24/1280);
            @media (min-width: 1441px) {
              margin-bottom: 24px;
            }
            @media (max-width: 640px) {
              margin-bottom: calc(100vw * 32/640);
            }
          }
        }
        > a > p {
          font-size: calc(100vw * 14/1280);
          margin-top: calc(100vw * 20/1280);
          color: #9190A6;
          @media (min-width: 1441px) {
            font-size: 14px;
            margin-top: 20px;
          }
          @media (max-width: 640px) {
            font-weight: 500;
            font-size: calc(100vw * 22/640);
            margin-top: calc(100vw * 32/640);
            color: #474646;
            text-decoration: underline;
          }
        }
      }
    }
  }
`

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background-image: ${(props) => `url(${props.bg})`};
  background-size: cover;
  z-index: -1;
`

export const LoginWrapper2 = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 100vw;
  height: 100vh;
  top: 0;
`

export const LoginBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 500px;
  background-color: ${(props) => (props.background ? props.background : theme.color.offWhite)};
  border-radius: 8px;
  flex-direction: column;
  padding: 40px;
  box-shadow: rgb(56, 53, 48) 0px 0px 50px;
  background-size: contain;
  background-position: right;
  background-repeat: no-repeat;
  background-image: ${(props) => `url(${props.cover})`};
  position: relative;
`

export const Image = styled(Img)`
  border-radius: 50%;
  width: 50%;
`

export default Wrapper
