import styled from 'styled-components'

export const Container = styled.div`
  padding: 65px 75px;
  width: 100%;
  height: 100vh;
  overflow-y: auto;
`

export const Main = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  > div.ant-design-pro {
    min-width: inherit;
    width: 100%;
    height: 100vh;
  }
`

export const ContentWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  overflow: hidden;
  > div {
    height: 100% !important;
  }
`

export default Container
