import ProLayout from '@ant-design/pro-layout'
import { useQuery, useSubscription } from '@apollo/client'
import _ from 'lodash'
import React, { useContext, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { REMAINING_JOB } from '../../api/query'
import { REMAIN_JOB_SUBSCRIPTION } from '../../api/subscription'
import config, { menuItems } from '../../constants/menu-config'
import { AccountantRemainingJobsContext, RemainingJobsContext, TaxRemainingJobsContext } from '../../store/StoreProvider'
import { getAccessToken } from '../../utils/local-data-service'

export default ({ children, menu }) => {
  const { data, loading } = useQuery(REMAINING_JOB)
  const { data: remainJobData } = useSubscription(REMAIN_JOB_SUBSCRIPTION)
  const { updateRemainingJobs, remainingJobs } = useContext(RemainingJobsContext)
  const { updateJobs } = useContext(AccountantRemainingJobsContext)
  const { updateTaxJobs } = useContext(TaxRemainingJobsContext)

  useEffect(() => {
    if (getAccessToken() && !loading) {
      updateRemainingJobs(data?.remainingJob?.data)
    }
  }, [loading])

  useEffect(() => {
    if (!_.isEmpty(remainJobData?.remainingTaskSub?.data)) {
      updateRemainingJobs(remainJobData?.remainingTaskSub?.data?.remainingJob)
      updateJobs(remainJobData?.remainingTaskSub?.data?.remainingAccountant)
      updateTaxJobs(remainJobData?.remainingTaskSub?.data?.remainingTax)
    }
  }, [remainJobData])

  const filteredChild = menuItems.map((v) => {
    if (!_.isEmpty(v.children)) {
      const mapped = {
        ...v,
        children: v.children.filter((i) => _.includes(menu, i.id)),
      }
      return mapped
    } return v
  })

  const renderMenu = filteredChild.filter((v) => _.includes(menu, v.id) || v.id === 'home' || (v.id === '#' && !_.isEmpty(v.children)))
  return (
    <ProLayout
      route={{
        routes: renderMenu.map((v) => {
          if (v.id === 'good-receives') return ({ ...v, name: `${v.name}${remainingJobs?.gr > 0 ? `(${remainingJobs?.gr})` : ''}` })
          if (v.id === 'picking-tickets') return ({ ...v, name: `${v.name}${remainingJobs?.pt > 0 ? `(${remainingJobs?.pt})` : ''}` })
          if (v.id === 'purchase-orders') return ({ ...v, name: `${v.name}${remainingJobs?.po > 0 ? `(${remainingJobs?.po})` : ''}` })
          if (v.id === 'account') return ({ ...v, name: `${v.name}${remainingJobs?.accountant > 0 ? `(${remainingJobs?.accountant})` : ''}` })
          if (v.id === 'tax-invoice') return ({ ...v, name: `${v.name}${remainingJobs?.tax > 0 ? `(${remainingJobs?.tax})` : ''}` })
          if (v.id === 'unmatched-sale-orders') return ({ ...v, name: `${v.name}${remainingJobs?.unmatched > 0 ? `(${remainingJobs?.unmatched})` : ''}` })
          return v
        }),
      }}
      location={{
        pathname: useLocation()?.pathname,
      }}
      {...config}
    >
      {
        React.Children.map(children, (child) => React.cloneElement(child, {
          refetch: () => {},
        }))
      }
    </ProLayout>
  )
}
