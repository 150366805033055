import { useLazyQuery } from '@apollo/client'
import _ from 'lodash'
import React, {
  useContext,
  useEffect, useRef,
  useState,
} from 'react'
import { REMAINING_ACCOUNT_JOB } from '../../api/query'
import {
  Tab,
} from '../../components'
import { DebtTab } from '../../components/debt'
import { PATH } from '../../constants/path'
import { AccountantRemainingJobsContext } from '../../store/StoreProvider'
import EditReceiptForm from './receipt/edit-form'
import ReceiptForm from './receipt/form'
import ReceiptList from './receipt/list'

const IncomeTab = (props) => {
  const [fetchMoreLoading, setFetchMoreLoading] = useState(false)

  const renderReceiptCash = () => {
    if (props.form) {
      if (props.isEdit) return <EditReceiptForm {...props} />
      return <ReceiptForm {...props} type='CASH' />
    }
    if (props.creditNote || props.debitNote) {
      return <DebtTab
        type='receipt'
        isCreditNote={props.creditNote}
        debitNoteLink={`${PATH.account}${PATH.receipts.debitNote}/${props.match.params.id}`}
        creditNoteLink={`${PATH.receipts.name}${PATH.receipts.creditNote}/${props.match.params.id}`}
        id={props.match.params.id}
      />
    }
    return <ReceiptList setFetchMoreLoading={(value) => setFetchMoreLoading(value)} type='CASH' />
  }

  return (
    <Tab
      current={0}
      fullHeight
      loading={props.loading}
      tabLoading={fetchMoreLoading}
      contentHeight='calc(100% - 38px)'
      title={
        [
          { id: 'cashReceipt', text: 'ใบกำกับภาษี', link: '#' },
        ]
      }
      noDefaultTab
    >
      {
        renderReceiptCash()
      }
    </Tab>
  )
}

const AccountantPage = (props) => {
  const [getRemainingAccountJob, { data, loading }] = useLazyQuery(REMAINING_ACCOUNT_JOB)
  const { accountantRemainingJobs, updateJobs } = useContext(AccountantRemainingJobsContext)
  const isInitialMount = useRef(true)

  useEffect(() => {
    if (_.some(_.values(accountantRemainingJobs), _.isUndefined)) {
      getRemainingAccountJob()
    }
  }, [])

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false
    }
    if (!loading && data?.remainingAccountant?.data) {
      updateJobs(data?.remainingAccountant?.data)
    }
  }, [data])

  return (
    <IncomeTab accountantRemainingJobs={accountantRemainingJobs} currentPath={props.location && props.location.pathname} renderData={props.children} {...props} />
  )
}

export default AccountantPage
