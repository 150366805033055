const create = '/create'
const edit = '/edit'

export const PATH = {
  create,
  edit,
  login: '/login',
  forgotPassword: '/forgot-password',
  resetPassword: '/reset-password',
  users: '/users',
  poList: '/purchase-orders',
  pt: '/picking-tickets',
  gr: '/goods-receive',
  roles: '/roles',
  USER_PAGE_PERMISSIONS: '/user-page-permissions',
  COMMISSIONS: '/commissions',
  depositReceipt: {
    name: 'deposit-receipt',
    create: `${create}-deposit-receipt`,
    edit: `${edit}-deposit-receipt`,
    creditNote: '/credit-note-deposit-recipt',
  },
  invoice: {
    name: 'invoice',
    create: `${create}-invoice`,
    edit: `${edit}-invoice`,
    creditNote: '/credit-note-invoice',
    debitNote: '/debit-note-invoice',
  },
  bill: {
    name: 'bill',
    create: `${create}-bill`,
    edit: `${edit}-bill`,
  },
  receipts: {
    name: '/receipts',
    create: `${create}-receipts`,
    edit: `${edit}-receipts`,
    creditNote: '/credit-note-receipts',
    debitNote: '/debit-note-receipts',
  },
  receiptCredit: {
    name: 'receipt-credit',
    create: `${create}-receipt-credit`,
    edit: `${edit}-receipt-credit`,
  },
  paymentNote: {
    name: 'paymentNote',
    create: `${create}-paymentNote`,
    edit: `${edit}-paymentNote`,
    record: '/record-paymentNote',
  },
  taxInvoice: '/tax-invoice',
  TAG: '/tag',
  FACEBOOK: '/facebook',
}

export default PATH
