import jwt from 'jwt-decode'

const PRE_FIX_LOCAL_DATA = 'wink-white-official-erp:'
const FIXED_TOKEN = 'eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOjEsImZ1bGxuYW1lIjoiS2h1biBCZWVyIiwicm9sZXMiOlt7Im5hbWUiOiJkaXJlY3RvciIsImRlc2NyaXB0aW9uIjoiTWFuYWdlciJ9XSwiaGFzTmV3Q3VzdG9tZXJQZXJtaXNzaW9uIjp0cnVlLCJoYXNDcm1DdXN0b21lclBlcm1pc3Npb24iOnRydWUsImlhdCI6MTcwOTUyOTgzNSwiZXhwIjoxNzExOTQ5MDM1fQ.ry7Af_Hc8is_YCTya4t3hklFJr77A6dUVRMb90izNydlAI8yhys9oalWR7Vke8pAqBteBgTkf3mnKR3URLU9B_NmuaUyseI0xLFnjVFbQJ5lkhG52QNmp7rVyBnvNPybOcflO9FyXnkLC3CDRyxZ_-Evzxk5zGcCBRfB7AZTg8U'

export const saveLocalStorage = (key, value) => {
  localStorage.setItem(`${PRE_FIX_LOCAL_DATA}${key}`, value)
}

export const getLocalStorage = (key) => localStorage.getItem(`${PRE_FIX_LOCAL_DATA}${key}`)

export const removeLocalStorage = (key) => localStorage.removeItem(`${PRE_FIX_LOCAL_DATA}${key}`)

export const getAccessToken = () => getLocalStorage('accessToken')

export const getRefreshToken = () => getLocalStorage('refreshToken')

export const setAccessToken = (value) => {
  saveLocalStorage('accessToken', value)
}

export const removeAccessToken = () => {
  removeLocalStorage('accessToken')
}

export const setRefreshToken = (value) => {
  saveLocalStorage('refreshToken', value)
}

export const removeRefreshToken = () => {
  removeLocalStorage('refreshToken')
}

// export const getTokenData = () => jwt(getAccessToken())
export const getTokenData = () => {
  if (getAccessToken()) return jwt(getAccessToken())
  return getAccessToken()
}
