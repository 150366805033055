import styled from 'styled-components'

export const RowWrapper = styled.div`
  > div {
    padding: 8px 16px 8px 48px;
    background-color: #FFFFFF;
  }
`

export default RowWrapper
