import styled, { css } from 'styled-components'
import { Div, Icon } from '@konsys-ui-custom'
import { theme } from '../../styles/_variables'

export const RotateIcon = styled(Icon)`
  transition: all 0.3s ease-in-out;
  ${(props) => props.open && css`
    transform: rotate(90deg);
  `}
`

export const CollapseContent = styled.div`
  height: 0px;
  max-height: 0px;
  transition: max-height 0.3s ease-in-out;
  overflow: hidden;
  ${(props) => props.open && css`
    height: auto;
    max-height: 1000px;
  `}
`

export const CheckboxImage = styled.div`
  border-radius: 2px;
  overflow: hidden;
  width: 65px;
  height: 65px;
  margin: -26px 0 0;
  position: relative;
  background-image: ${(props) => `url(${props.src})`};
  background-size: cover;
  background-position: center;
`

export const CheckboxRowContainer = styled.label`
  display: block;
  padding: 16px 24px;
  background-color: ${theme.color.background};
  display: flex;
  border: 1px solid transparent;
  cursor: pointer;
  margin: ${(props) => props.margin || '0'};
  ${(props) => props.active && css`
    border: 1px solid ${theme.color.primaryColor};
    ${CheckboxImage} {
      &::after {
        content: '\f00c';
        background-color: rgba(93, 157, 210, 0.81);
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        font-size: ${theme.fonts.size.subHeading};
        color: ${theme.color.offWhite};
        font-family: 'Font Awesome 5 Pro';
        text-align: center;
      }
    }
  `}
`

export const CollapseDetailContainer = styled.div`
  padding: 0;
  border: 1px solid ${theme.color.primaryColor10};
  transition: max-height 0.3s ease-in-out;
  height: 0;
  max-height: 0;
  overflow: hidden;
  ${(props) => props.open && css`
    padding: 24px;
    height: auto;
    max-height: 10000px;
  `}
`

export const StepTitleContainer = styled(Div)`
  display: inline-flex;
  align-items: center;
  position: relative;
  z-index: 2;
`

export const StepContainer = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    height: 1px;
    background-color: ${theme.color.lightBlue};
    z-index: 1;
  }
`

export const BadgeContainer = styled.span`
  padding: 4px 6px;
  border-radius: 16px;
  background-color: ${theme.color.success};
  position: absolute;
  top: -18px;
  right: -20px;
`

export default CollapseContent
