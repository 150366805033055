import { CheckCircleOutlined, CloseCircleOutlined, QuestionCircleOutlined } from '@ant-design/icons'
import { useLazyQuery, useMutation, useQuery } from '@apollo/client'
import { Button, Div, H3, Icon, Input, P } from '@konsys-ui-custom'
import { Modal as AntModal, Col, DatePicker, Modal, Pagination, Row, Select as SelectAntd, Skeleton, message } from 'antd'
import axios from 'axios'
import fileDownload from 'js-file-download'
import { H4 } from 'konsys-ui'
import _, { cloneDeep, filter, find, first, includes, isEmpty, isNil, remove } from 'lodash'
import moment from 'moment-timezone'
import numeral from 'numeral'
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useHistory } from 'react-router-dom'
import { ASSIGN_UPSELL, CASH_PAYMENT_RECORD, EDIT_PAYMENT, UPDATE_PRINTED_ADDRESS_LABELS } from '../../api/mutation'
import {
  CHECK_REF_ID,
  FINNIC_BANKS,
  GET_RECEIPT,
  INVOICE_DETAIL,
  SALE_ORDER,
  SALE_ORDERS_EXPORT, SALE_ORDERS_FOR_UPSELL, USERS,
} from '../../api/query'
import {
  ComponentWithLabelLarge,
  DashedButton,
  DebtList,
  FullPageContainer,
  LargeTextWithLabel,
  NotFound, PaymentEvidenceList,
  PaymentMethodFormInAccount,
  Select,
  Tab,
  TextBetween,
  TextareaWithLabel,
  Uploader,
} from '../../components'
import { PAYMENT_TYPE as PAYMENTYPE, SO_TYPE } from '../../constants/enum'
import URL from '../../constants/environment'
import { theme } from '../../styles/_variables'
import { showMessageMutation } from '../../utils'
import { getAccessToken } from '../../utils/local-data-service'
import { toSafeFloat, validateData } from '../../utils/util-services'
import { ADDRESS_LABEL_STATUS, DELIVERY_CHANNEL, ORDER_TYPE, SO_STATUS_GROUP } from './constants'
import { RadioLabel, RadioTitleBoxWrapper } from './styled'

export const PaymentPage = (props) => {
  const history = useHistory()
  const isCreateMode = !_.includes(props.match.url, 'edit')
  const [doAction, { loading: mutationLoading }] = useMutation(
    CASH_PAYMENT_RECORD,
    { refetchQueries: ['remainingJob', 'remainingAccountant'] },
  )
  const { loading: SOLoading, data: SOData } = useQuery(SALE_ORDER, {
    variables: { id: parseInt(props.match.params.id) },
  })
  const { data: finnicBankData, loading: finnicBankLoading } = useQuery(FINNIC_BANKS)
  const [doEditPayment, { loading: editPaymentLoading }] = useMutation(EDIT_PAYMENT)
  const [mutationVariables, setMutationVariables] = useState({})
  const [oldAmount, setOldAmount] = useState(0)

  useEffect(() => {
    if (!_.isEmpty(props.match.params.pid)) {
      const paymentHistoryObj = _.find(
        SOData?.salesOrderDetail?.data?.paymentHistories,
        (v) => v.id === parseInt(props.match.params.pid),
      ) || []
      if (!_.isEmpty(paymentHistoryObj)) {
        setMutationVariables(
          _.omitBy(
            {
              amount: paymentHistoryObj.subtotal,
              paidDate: paymentHistoryObj.date,
              paidTime: paymentHistoryObj.time,
              referenceNO: paymentHistoryObj.referenceNO,
              paymentMethod: paymentHistoryObj.type,
              finnicBankId: paymentHistoryObj.finnicBankId,
              chequeDate: paymentHistoryObj.chequeDate,
              chequeNumber: paymentHistoryObj.chequeNumber,
              creditCard: paymentHistoryObj.creditCard,
              reference: paymentHistoryObj.reference,
            },
            _.isNull,
          ),
        )
        setOldAmount(paymentHistoryObj.subtotal)
      }
    }
  }, [SOData?.salesOrderDetail?.data?.paymentHistories, props.match.params.pid])

  const isPosType = SOData?.salesOrderDetail?.data?.salesOrderType === SO_TYPE[0].value

  useEffect(() => {
    if (isPosType) {
      setMutationVariables({
        ...mutationVariables,
        amount: SOData?.salesOrderDetail?.data?.subtotalAfterDiscount,
      })
    }
  }, [SOData?.salesOrderDetail?.data])

  const doPaymentRecord = (refId) => {
    const variables = {
      attachment: mutationVariables.attachment
        ? mutationVariables.attachment[0]
        : null,
      date: mutationVariables.paidDate,
      time: mutationVariables.paidTime,
      subtotal: parseFloat(mutationVariables.amount),
      type: mutationVariables.paymentMethod,
      remarks: mutationVariables.remarks,
      chequeDate: mutationVariables.chequeDate,
      chequeNumber: mutationVariables.chequeNumber,
      creditCard: mutationVariables.creditCard,
      finnicBankId: mutationVariables.finnicBankId,
      reference: refId || mutationVariables.reference,
      referenceNO: mutationVariables.referenceNO,
    }
    if (!isCreateMode) {
      if (
        !_.isUndefined(props.match.params.pid)
        && !_.isNil(props.match.params.pid)
        && parseInt(props.match.params.pid) > 0
      ) {
        doEditPayment({
          variables: {
            paymentHistoryId: parseInt(props.match.params.pid),
            salesOrderId: SOData.salesOrderDetail.data.id,
            ...variables,
          },
        }).then((resp) => {
          if (resp.data.editPayment.success) {
            message.success(resp.data.editPayment.message)
            history.push({
              pathname: '/sale-orders',
              state: {
                status: SO_STATUS_GROUP.INPROGRESS,
                search: props.location.state.soRef,
              },
            })
          } else message.error(resp.data.editPayment.message)
        })
      }
    } else {
      doAction({
        variables: {
          soId: SOData.salesOrderDetail.data.id,
          ...variables,
        },
      }).then((resp) => {
        if (resp.data.createPayment.success) {
          message.success(resp.data.createPayment.message)
          history.push({
            pathname: '/sale-orders',
            state: {
              status: SO_STATUS_GROUP.INPROGRESS,
              search: props.location.state.soRef,
            },
          })
        } else message.error(resp.data.createPayment.message)
      })
    }
  }

  const confirmModal = (messageText, data, icon, okType, refId) => (
    AntModal.confirm({
      title: `${messageText} ${okType === 'primary' ? '\n\nคุณต้องการยืนยันการแจ้งชำระเงินหรือไม่?' : ''}`,
      content: data,
      icon: icon === 'success'
        ? <CheckCircleOutlined style={{ color: theme.color.success }} />
        : icon === 'warning'
          ? <QuestionCircleOutlined style={{ color: theme.color.warining }} />
          : <CloseCircleOutlined style={{ color: theme.color.error }} />,
      okText: 'ยืนยันการแจ้งชำระเงิน',
      cancelText: 'ยกเลิก',
      okType: okType === 'success' ? 'primary' : okType,
      width: 600,
      okButtonProps: { disabled: okType === 'danger' },
      onOk() {
        doPaymentRecord(refId)
      },
    })
  )

  const [getPayment, { data, loading }] = useLazyQuery(CHECK_REF_ID, {
    onCompleted: () => {
      const isLess = data.checkRefId.data?.amount < parseFloat(mutationVariables.amount)
      const isDiff = data.checkRefId.data?.amount !== parseFloat(mutationVariables.amount)
      confirmModal(
        mutationVariables.attachment
          ? data.checkRefId.success && !isDiff && isNil(data.checkRefId.data?.duplicateOrder)
            ? 'หลักฐานการชำระเงินถูกต้องสามารถบันทึกการแจ้งชำระเงินได้'
            : !data.checkRefId.success
              ? 'หลักฐานการชำระเงินไม่ถูกต้อง กรุณาตรวจสอบ'
              : `${!isNil(data.checkRefId.data?.duplicateOrder) ? `หลักฐานการชำระเงินถูกต้อง\nแต่หลักฐานการชำระเงินนี้ถูกใช้ไปแล้วในออเดอร์\n${data.checkRefId.data?.duplicateOrder}` : ''}${isDiff && !isNil(data.checkRefId.data?.duplicateOrder) ? '\n' : ''}${isDiff ? `หลักฐานการชำระเงินถูกต้อง\nแต่พบว่ายอดไม่ตรงกับในสลิป\nต้องการแนบเพื่อบันทึกยอด ${numeral(parseFloat(mutationVariables.amount)).format('0,0.[00]')}` : ''}`
          : 'ไม่ได้อัพโหลดหลักฐานการชำระเงิน',
        mutationVariables.attachment && data.checkRefId.success
          ? <>
            <p style={{ marginBottom: 4 }}>เลขบัญชีผู้รับ: {data.checkRefId.data?.bankAccount}</p>
            <p style={{ marginBottom: 4 }}>วันที่เวลา: {moment(data.checkRefId.data?.date).format('DD/MM/YYYY HH:mm')}</p>
            <p style={{ marginBottom: 4, color: isDiff ? theme.color.error : 'inherit', fontWeight: isDiff ? 500 : 'inherit' }}>{isDiff ? `ยอดเงินในสลิป: ${numeral(data.checkRefId.data?.amount).format('0,0.[00]')} ยอดเงินที่จะบันทึกชำระเงิน ${numeral(parseFloat(mutationVariables.amount)).format('0,0.[00]')}` : `ยอดเงิน: ${numeral(data.checkRefId.data?.amount).format('0,0.[00]')}`}</p>
            <p style={{ marginBottom: 4 }}>รหัสอ้างอิง: {data.checkRefId.data?.refId}</p>
          </>
          : '',
        data.checkRefId.success && !isDiff && isNil(data.checkRefId.data?.duplicateOrder)
          ? 'success'
          : isDiff && mutationVariables.attachment && data.checkRefId.success
            ? 'warning' : 'error',
        data.checkRefId.success && !isLess && isNil(data.checkRefId.data?.duplicateOrder)
          ? 'success'
          : isLess && mutationVariables.attachment
            ? 'danger' : 'primary',
        data.checkRefId.data?.refId,
      )
    },
  })
  const [isOpenPaymentEvidence, setIsOpenPaymentEvidence] = useState(true)

  const menu = [
    {
      text: 'แจ้งการชำระเงิน',
      icon: 'far fa-money-check-edit-alt',
    },
  ]

  const finnicBankList = finnicBankLoading || _.isEmpty(finnicBankData)
    ? []
    : finnicBankData.finnicBankList.data.map((v) => ({
      text: `${v.bank?.name ? v.bank.name : '-'} ${
            v.bankAccountNumber ? `(${v.bankAccountNumber})` : '(-)'
          }`,
      value: v.id,
      number: v.bankAccountNumber,
    }))

  const validatePaymentData = () => {
    let validate = [mutationVariables.amount, mutationVariables.paidDate]
    if (_.isEmpty(mutationVariables.paymentMethod)) return [null]
    if (mutationVariables.paymentMethod === 'TRANSFER') {
      validate = [
        ...validate,
        mutationVariables.paidTime,
        mutationVariables.finnicBankId,
      ]
    } else if (mutationVariables.paymentMethod === 'CHEQUE') {
      validate = [
        ...validate,
        mutationVariables.chequeDate,
        mutationVariables.chequeNumber,
      ]
    } else if (mutationVariables.paymentMethod === 'CREDIT') {
      validate = [
        ...validate,
        mutationVariables.creditCard,
        mutationVariables.referenceNO,
      ]
    }
    return validate
  }

  return (
    <Div>
      <Helmet>
        <meta name="viewport" content="width=1280" />
      </Helmet>
      <FullPageContainer
        loading={SOLoading}
        title={[
          {
            text: 'Sale Order',
            icon: 'fad fa-receipt',
          },
        ]}
        detail={{
          title: menu,
          current: 0,
        }}
      >
        {!SOLoading ? (
          !isCreateMode && _.isNull(SOData.salesOrderDetail.data) ? (
            <NotFound moduleName="ใบออเดอร์" />
          ) : (
            <Div display="flex" flexDirection="column">
              <Div display="flex"></Div>
              <Div margin="0 0 16px">
                <PaymentMethodFormInAccount
                  margin="0"
                  isInSo
                  isPosType={isPosType}
                  amount={{
                    value: mutationVariables.amount,
                    onChange: (value) => {
                      const remainTotal = SOData?.salesOrderDetail?.data?.subtotalAfterDiscount
                      - (_.sumBy(
                        SOData?.salesOrderDetail?.data?.paymentHistories,
                        'subtotal',
                      ) || 0)
                      if (
                        parseFloat(value) <= remainTotal + oldAmount
                      || value === ''
                      ) {
                        setMutationVariables({
                          ...mutationVariables,
                          amount: value,
                        })
                      }
                    },
                  }}
                  paymentDate={{
                    value: mutationVariables.paidDate,
                    onChange: (value) => setMutationVariables({
                      ...mutationVariables,
                      paidDate: value,
                    }),
                  }}
                  paymentTime={{
                    value: mutationVariables.paidTime,
                    onChange: (value) => setMutationVariables({
                      ...mutationVariables,
                      paidTime: value,
                    }),
                  }}
                  reference={{
                    value: mutationVariables.reference,
                    onChange: (value) => setMutationVariables({
                      ...mutationVariables,
                      reference: value,
                    }),
                  }}
                  paymentMethod={{
                    value: mutationVariables.paymentMethod,
                    onChange: (value) => setMutationVariables({
                      ...mutationVariables,
                      paymentMethod: value,
                    }),
                  }}
                  account={{
                    list: finnicBankList,
                    value: mutationVariables.finnicBankId,
                    onChange: (value) => setMutationVariables({
                      ...mutationVariables,
                      finnicBankId: value,
                    }),
                  }}
                  cheque={{
                    date: {
                      value: mutationVariables.chequeDate,
                      onChange: (value) => setMutationVariables({
                        ...mutationVariables,
                        chequeDate: value,
                      }),
                    },
                    number: {
                      value: mutationVariables.chequeNumber,
                      onChange: (value) => setMutationVariables({
                        ...mutationVariables,
                        chequeNumber: value,
                      }),
                    },
                  }}
                  creditCard={{
                    number: {
                      value: mutationVariables.creditCard,
                      onChange: (value) => setMutationVariables({
                        ...mutationVariables,
                        creditCard: value,
                      }),
                    },
                    ref: {
                      value: mutationVariables.referenceNO,
                      onChange: (value) => setMutationVariables({
                        ...mutationVariables,
                        referenceNO: value,
                      }),
                    },
                  }}
                />
              </Div>
              <TextareaWithLabel
                onChange={(e) => setMutationVariables({
                  ...mutationVariables,
                  remarks: e.target.value,
                })
                }
                width="100%"
                title={{
                  text: 'หมายเหตุเพิ่มเติม',
                  margin: '0 0 8px',
                }}
                textarea={{
                  rows: 5,
                  margin: '16px 0 0',
                  style: { width: '100%' },
                }}
              />
              <Uploader
                onChange={(e) => setMutationVariables({ ...mutationVariables, attachment: e })
                }
                data={mutationVariables.attachment || []}
                accept="image/*"
                isMultiple={false}
                buttonText="อัพโหลดไฟล์"
              />
              {_.isEmpty(mutationVariables.attachment)
              && !_.isEmpty(
                _.find(
                  SOData?.salesOrderDetail?.data?.paymentHistories,
                  (v) => v.id === parseInt(props.match.params.pid),
                ),
              ) && (
                <Div margin="8px 0 0" width="250px">
                  <img
                    width="100%"
                    src={
                      _.find(
                        SOData?.salesOrderDetail?.data?.paymentHistories,
                        (v) => v.id === parseInt(props.match.params.pid),
                      ).attachment
                    }
                  />
                </Div>
              )}
              <Div
                display="flex"
                flexDirection="row"
                justifyContent="center"
                alignItems="center"
                width="100%"
                margin="20px 0 0"
              >
                <Button
                  disabled={
                    validateData(true, validatePaymentData(), {})
                  || mutationLoading
                  || editPaymentLoading
                  || loading
                  }
                  loading={mutationLoading || loading || editPaymentLoading}
                  text={isCreateMode ? 'ตรวจสอบการชำระเงิน' : 'อัพเดตการชำระเงิน'}
                  icon={isCreateMode ? 'fal fa-info-circle' : 'fal fa-check'}
                  style={{ alignItems: 'center' }}
                  width="fit-content"
                  margin="0 4px"
                  onClick={() => {
                    if (isCreateMode) {
                      getPayment({
                        variables: {
                          input: {
                            attachment: mutationVariables.attachment
                              ? mutationVariables.attachment[0]
                              : null,
                          },
                        },
                      })
                    } else {
                      doPaymentRecord()
                    }
                  }}
                />
              </Div>
              <PaymentEvidenceList
                soId={props.match?.params?.id}
                margin="48px 0"
                fullHeight={true}
                isOpen={isOpenPaymentEvidence}
                setIsOpen={setIsOpenPaymentEvidence}
                subtotal={SOData.salesOrderDetail.data.subtotalAfterDiscount}
                paid={_.sum(
                  SOData.salesOrderDetail.data.paymentHistories.map(
                    (v) => v.subtotal,
                  ),
                )}
                block
                title="ประวัติการแจ้งชำระเงิน"
                list={SOData.salesOrderDetail.data.paymentHistories.map(
                  (pay, i) => ({
                    ...pay,
                    index: i + 1,
                    src: pay.attachment,
                    info: pay.remarks ? pay.remarks : '-',
                    date: pay.dateTime,
                    subtotal: pay.subtotal,
                    type: pay.type,
                    isEdit: true,
                  }),
                )}
                history={props.history}
              />
            </Div>
          )
        ) : null}
      </FullPageContainer>
    </Div>
  )
}

export const DebitNotePageComponent = (props) => {
  const history = useHistory()
  const isCreateMode = _.includes(props.match.url, 'create')

  const typeData = props.type === 'invoice'
    ? {
      key: 'invoiceDetail',
      link: '/account/invoice',
      enum: 'INVOICE',
      query: INVOICE_DETAIL,
    }
    : props.type === 'receipt'
      ? {
        key: 'receiptInfo',
        link: '/account/receipt-cash',
        enum: 'RECEIPT',
        query: GET_RECEIPT,
      }
      : {
        key: 'salesOrderDetail',
        link: '/sale-orders',
        enum: '',
        query: SALE_ORDER,
      }

  const { loading: SOLoading, data: SOData } = useQuery(typeData.query, {
    variables: { id: parseInt(props.match.params.id) },
  })
  const [remarks, setRemarks] = useState()
  const [change, setChange] = useState(0)
  const [isOpenDebt, setIsOpenDebt] = useState(true)

  const menu = [
    {
      text: 'ใบเพิ่มหนี้',
      icon: 'far fa-file-plus',
    },
    {
      text: 'ใบลดหนี้',
      icon: 'far fa-file-minus',
      link:
        props.creditNoteLink
        || _.replace(props.location.pathname, 'debit-note', 'credit-note'),
    },
  ]

  const paymentType = SOData
    ? SOData[typeData.key].data?.lot?.salesOrder?.customer?.paymentType
    : undefined

  return (
    <Tab loading={SOLoading} title={menu} white>
      {!SOLoading ? (
        !isCreateMode && _.isNull(SOData[typeData.key].data) ? (
          <NotFound moduleName="ใบออเดอร์" />
        ) : (
          <Div display="flex" flexDirection="column">
            <Div display="flex" justifyContent="flex-end" margin="40px 0">
              <H3 color={theme.color.primaryColor} bold>
                #{SOData[typeData.key].data.number}
              </H3>
            </Div>
            <Div display="flex">
              <Div width="50%" margin="0 16px 0 0">
                <TextareaWithLabel
                  onChange={(e) => setRemarks(e.target.value)}
                  width="100%"
                  title={{
                    text: 'หมายเหตุ *',
                    margin: '0 0 8px',
                  }}
                  textarea={{
                    rows: 5,
                    placeholder: 'กรอกหมายเหตุเพิ่มเติม',
                    margin: '16px 0 0',
                    style: { width: '100%' },
                  }}
                />
              </Div>
              <Div
                width="50%"
                margin="24px 0 8px 16px"
                display="flex"
                flexDirection="column"
                justifyContent="space-between"
              >
                <TextBetween
                  margin="0 0 8px"
                  title={{ text: 'มูลค่าเดิม' }}
                  content={{
                    text: `${numeral(
                      toSafeFloat(
                        SOData[typeData.key].data.subtotalAfterDiscount,
                      ),
                    ).format('0,0.00')} บาท`,
                  }}
                />
                <Div
                  display="flex"
                  width="100%"
                  justifyContent="space-between"
                  alignItems="center"
                  margin="0 0 8px"
                >
                  <P bold>เพิ่มหนี้ *</P>
                  <Input
                    placeholder="กรอกหนี้"
                    defaultValue={toSafeFloat(change)}
                    onChange={(e) => setChange(parseFloat(e.target.value))}
                    rule={{ type: 'float' }}
                    state="default"
                  />
                </Div>
                <Div
                  display="flex"
                  width="100%"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <P bold>มูลค่าที่ถูกต้อง</P>
                  <P bold>
                    {numeral(
                      toSafeFloat(
                        SOData[typeData.key].data.subtotalAfterDiscount,
                      ) + change,
                    ).format('0,0.00')}{' '}
                    บาท
                  </P>
                </Div>
              </Div>
            </Div>
            <Div
              display="flex"
              flexDirection="row"
              justifyContent="center"
              alignItems="center"
              width="100%"
              margin="20px 0"
            >
              <Button
                disabled={
                  (props.mutation && props.mutation.mutationLoading)
                  || validateData(true, [remarks, change], { remarks, change })
                  || change < 0
                }
                loading={props.mutation && props.mutation.mutationLoading}
                text="ยืนยันสร้างใบเพิ่มหนี้"
                icon="far fa-check"
                style={{ alignItems: 'center' }}
                width="fit-content"
                margin="0 4px"
                onClick={() => {
                  if (props.mutation) {
                    props.mutation
                      .doAction({
                        variables: {
                          thisModule: typeData.enum,
                          id: SOData[typeData.key].data.id,
                          totalPriceAfter:
                            toSafeFloat(
                              SOData[typeData.key].data.subtotalAfterDiscount,
                            ) + change,
                          remarks,
                        },
                      })
                      .then((res) => {
                        if (res.data.createDebt.success) {
                          message.success(res.data.createDebt.message)
                          history.push(typeData.link)
                        } else message.error(res.data.createDebt.message)
                      })
                  }
                }}
              />
            </Div>
            <DebtList
              fullHeight={true}
              margin="48px 0"
              isOpen={isOpenDebt}
              setIsOpen={setIsOpenDebt}
              block
              title="ประวัติใบเพิ่มหนี้/ลดหนี้"
              list={SOData[typeData.key].data.debtHistories.map((note) => ({
                date: moment(note.createdAt).format('DD/MM/YYYY'),
                customer: SOData[typeData.key].data.customer.name,
                paymentType:
                  _.find(PAYMENTYPE, (v) => v.value === paymentType)?.text
                  || '-',
                remark: note.remarks,
                oldCost: note.totalPriceBefore,
                newCost: note.totalPriceAfter,
                itemChanges: note.itemChanges,
              }))}
            />
          </Div>
        )
      ) : null}
    </Tab>
  )
}

export const CreditNotePageComponent = (props) => {
  const isCreateMode = _.includes(props.match.url, 'create')
  const history = useHistory()

  const typeData = props.type === 'invoice'
    ? {
      key: 'invoiceDetail',
      link: '/account/invoice',
      enum: 'INVOICE',
      query: INVOICE_DETAIL,
    }
    : props.type === 'receipt'
      ? {
        key: 'receiptInfo',
        link: '/account/receipt-cash',
        enum: 'RECEIPT',
        query: GET_RECEIPT,
      }
      : {
        key: 'salesOrderDetail',
        link: '/sale-orders',
        enum: '',
        query: SALE_ORDER,
      }

  const [discount, setDiscount] = useState(0)
  const { loading: SOLoading, data: SOData } = useQuery(typeData.query, {
    variables: { id: parseInt(props.match.params.id) },
  })
  const [remarks, setRemarks] = useState()
  const [isOpenDebt, setIsOpenDebt] = useState(true)
  const [changeProductList, setChangeProductList] = useState([])

  const menu = [
    {
      text: 'ใบเพิ่มหนี้',
      icon: 'far fa-file-plus',
      link:
        props.debitNoteLink
        || _.replace(props.location.pathname, 'credit-note', 'debit-note'),
    },
    {
      text: 'ใบลดหนี้',
      icon: 'far fa-file-minus',
    },
  ]

  const mappedChangeProductList = changeProductList.map((v) => [
    v.orderItemId,
    v.quantity,
  ])

  const paymentType = SOData
    ? SOData[typeData.key].data?.lot?.salesOrder?.customer?.paymentType
    : undefined
  const moduleNumber = SOData
    ? props.type !== 'so'
      ? SOData[typeData.key].data.number
      : SOData[typeData.key].data.soNumber
    : undefined
  const itemOption = SOData
    ? props.type === 'invoice'
      ? SOData[typeData.key].data.lot.items.map((v) => ({
        text:
            v.inventoryOption?.inventory?.type === ORDER_TYPE.PACK
              ? `${v.inventoryOption?.inventory?.productName} (${v.pack?.name})`
              : v.inventoryOption?.inventory?.productName || '-',
        value: v.id,
        salesPrice: v.salesPrice,
        discount: v.discount,
        quantity: v.quantity,
      }))
      : props.type === 'receipt'
        ? _.flatten(
          SOData[typeData.key].data.lots.map((v) => v.items.map((o) => ({
            text: o.variantName || '-',
            value: o.id,
            salesPrice: o.salesPrice,
            discount: o.discount,
            quantity: o.quantity,
          }))),
        )
        : []
    : []

  return (
    <Tab loading={SOLoading} title={menu} white current={1}>
      {!SOLoading ? (
        !isCreateMode && _.isNull(SOData[typeData.key].data) ? (
          <NotFound moduleName="ใบออเดอร์" />
        ) : (
          <Div display="flex" flexDirection="column">
            <Div display="flex" justifyContent="flex-end" margin="40px 0">
              <H3 color={theme.color.primaryColor} bold>
                #{moduleNumber}
              </H3>
            </Div>
            {changeProductList.map((v) => (
              <Div
                key={v.id}
                display="flex"
                justifyContent="space-between"
                alignItems="flex-end"
              >
                <ComponentWithLabelLarge
                  width="50%"
                  margin="8px 16px 8px 0"
                  title={{ text: 'สินค้า *' }}
                >
                  <Select
                    width="100%"
                    margin="0 16px 0 0"
                    showSearch
                    style={{ width: '100%' }}
                    placeholder="เลือกสินค้า"
                    notFoundContent={null}
                    showArrow={false}
                    filterOption={false}
                    onSelect={(e) => setChangeProductList(
                      changeProductList.map((val) => {
                        if (val.id === v.id) {
                          return {
                            ...val,
                            orderItemId: e,
                            price: _.find(itemOption, (o) => o.value === e)
                              .salesPrice,
                            discount: _.find(itemOption, (o) => o.value === e)
                              .discount,
                            oldQuantity: _.find(
                              itemOption,
                              (o) => o.value === e,
                            ).quantity,
                          }
                        }
                        return val
                      }),
                    )
                    }
                    options={itemOption}
                  />
                </ComponentWithLabelLarge>
                <LargeTextWithLabel
                  width="10%"
                  margin="8px 16px 8px 0"
                  title={{ text: 'ราคาต่อหน่วย' }}
                  content={{ text: numeral(v.price).format('0,0.00') || 0 }}
                  display="flex"
                  height="55px"
                  flexDirection="column"
                  justifyContent="space-around"
                />
                <LargeTextWithLabel
                  width="10%"
                  margin="8px 16px 8px 0"
                  title={{ text: 'ส่วนลดต่อหน่วย' }}
                  content={{ text: numeral(v.discount).format('0,0.00') || 0 }}
                  display="flex"
                  height="55px"
                  flexDirection="column"
                  justifyContent="space-around"
                />
                <LargeTextWithLabel
                  width="10%"
                  margin="8px 16px 8px 0"
                  title={{ text: 'จำนวนเดิม' }}
                  content={{ text: v.oldQuantity || 0 }}
                  display="flex"
                  height="55px"
                  flexDirection="column"
                  justifyContent="space-around"
                />
                <ComponentWithLabelLarge
                  width="30%"
                  margin="8px 16px 8px 0"
                  title={{ text: 'จำนวนที่ถูกลด *' }}
                >
                  <Input
                    width="100%"
                    placeholder="กรอกจำนวน"
                    onChange={(e) => setChangeProductList(
                      changeProductList.map((val) => {
                        if (val.id === v.id) {
                          return {
                            ...val,
                            quantity: parseFloat(e.target.value),
                          }
                        }
                        return val
                      }),
                    )
                    }
                    rule={{ type: 'float' }}
                    state="default"
                  />
                </ComponentWithLabelLarge>
                <Icon
                  icon="fal fa-trash-alt"
                  color={theme.color.gray80}
                  style={{
                    cursor: 'pointer',
                    alignSelf: 'center',
                    marginTop: 24,
                  }}
                  onClick={() => {
                    setChangeProductList(
                      _.remove(
                        _.cloneDeep(changeProductList),
                        (o) => o.id !== v.id,
                      ),
                    )
                  }}
                />
              </Div>
            ))}
            <DashedButton
              margin="16px 0"
              text="เพิ่มรายการสินค้า"
              icon="fal fa-refresh"
              onClick={() => setChangeProductList([
                ...changeProductList,
                {
                  id:
                    _.size(changeProductList) === 0
                      ? 1
                      : _.maxBy(changeProductList, 'id').id + 1,
                },
              ])
              }
            />
            <Div display="flex">
              <Div width="50%" margin="0 16px 0 0">
                <TextareaWithLabel
                  onChange={(e) => setRemarks(e.target.value)}
                  width="100%"
                  title={{
                    text: 'หมายเหตุ *',
                    margin: '0 0 8px',
                  }}
                  textarea={{
                    rows: 5,
                    placeholder: 'กรอกหมายเหตุเพิ่มเติม',
                    margin: '16px 0 0',
                    style: { width: '100%' },
                  }}
                />
              </Div>
              <Div
                width="50%"
                margin="24px 0 8px 16px"
                display="flex"
                flexDirection="column"
                justifyContent="space-between"
              >
                <TextBetween
                  margin="0 0 8px"
                  title={{ text: 'มูลค่าเดิม', bold: true }}
                  content={{
                    text: `${numeral(
                      toSafeFloat(
                        SOData[typeData.key].data.subtotalAfterDiscount,
                      ),
                    ).format('0,0.00')} บาท`,
                    bold: true,
                  }}
                />
                <Div
                  display="flex"
                  width="100%"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <P bold>ผลต่าง</P>
                  <P bold>
                    {numeral(
                      _.sum(
                        changeProductList.map(
                          (v) => (v.price - v.discount) * v.quantity,
                        ),
                      ),
                    ).format('0,0.00')}{' '}
                    บาท
                  </P>
                </Div>
                <Div
                  display="flex"
                  width="100%"
                  justifyContent="space-between"
                  alignItems="center"
                  margin="0 0 8px"
                >
                  <P bold>ส่วนลดเพิ่มเติม *</P>
                  <Input
                    placeholder="กรอกส่วนลด"
                    defaultValue={0}
                    onChange={(e) => setDiscount(parseFloat(e.target.value))}
                    rule={{ type: 'float' }}
                    state="default"
                  />
                </Div>
                <Div
                  display="flex"
                  width="100%"
                  justifyContent="space-between"
                  alignItems="center"
                  margin="0 0 8px"
                >
                  <P bold>มูลค่าที่ถูกต้อง</P>
                  <P bold>
                    {numeral(
                      toSafeFloat(
                        SOData[typeData.key].data.subtotalAfterDiscount,
                      )
                        - _.sum(
                          changeProductList.map(
                            (v) => (v.price - v.discount) * v.quantity,
                          ),
                        )
                        - discount,
                    ).format('0,0.00')}{' '}
                    บาท
                  </P>
                </Div>
              </Div>
            </Div>
            <Div
              display="flex"
              flexDirection="row"
              justifyContent="center"
              alignItems="center"
              width="100%"
              margin="20px 0"
            >
              <Button
                inverse
                loading={props.mutation && props.mutation.mutationLoading}
                disabled={
                  (props.mutation && props.mutation.mutationLoading)
                  || validateData(
                    true,
                    [
                      remarks,
                      ...mappedChangeProductList,
                      _.size(mappedChangeProductList) > 0 ? 'check' : discount,
                    ],
                    { check: 'check' },
                  )
                }
                text="ยืนยันสร้างใบลดหนี้"
                icon="far fa-check"
                style={{ alignItems: 'center' }}
                width="fit-content"
                margin="0 4px"
                onClick={() => {
                  if (props.mutation) {
                    props.mutation
                      .doAction({
                        variables: {
                          thisModule: typeData.enum,
                          id: SOData[typeData.key].data.id,
                          itemList: changeProductList.map((v) => ({
                            orderItemId: v.orderItemId,
                            quantity: v.quantity,
                          })),
                          remarks,
                          discount,
                        },
                      })
                      .then((res) => {
                        if (res.data.createCreditNote.success) {
                          message.success(res.data.createCreditNote.message)
                          history.push(typeData.url)
                        } else message.error(res.data.createCreditNote.message)
                      })
                  }
                }}
              />
            </Div>
            <DebtList
              fullHeight={true}
              margin="48px 0"
              isOpen={isOpenDebt}
              setIsOpen={setIsOpenDebt}
              block
              title="ประวัติใบเพิ่มหนี้/ลดหนี้"
              list={SOData[typeData.key].data.debtHistories.map((note) => ({
                date: moment(note.createdAt).format('DD/MM/YYYY'),
                customer: SOData[typeData.key].data.customer.name,
                paymentType:
                  _.find(PAYMENTYPE, (v) => v.value === paymentType)?.text
                  || '-',
                remark: note.remarks,
                oldCost: note.totalPriceBefore,
                newCost: note.totalPriceAfter,
                itemChanges: note.itemChanges,
              }))}
            />
          </Div>
        )
      ) : null}
    </Tab>
  )
}

export const ExportSalesOrders = ({ type }) => {
  /*
    type:
    ADDRESS_LABEL_WAITING_PRINT,
    ADDRESS_LABEL_PRINTING,
    KERRY_EXPORT,
    UPSELL
  */
  const [visible, setVisible] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const [isExporting, setIsExporting] = useState(false)
  const [channel, setChannel] = useState(DELIVERY_CHANNEL.KERRY.KEY)
  const [search, setSearch] = useState('')
  const [deliveryDate, setDeliveryDate] = useState([moment().startOf('day'), moment().endOf('day')])
  const [createdDate, setCreatedDate] = useState([])
  const [upsellStatus, setUpsellStatus] = useState(0)
  const [printStatus, setPrintStatus] = useState(ADDRESS_LABEL_STATUS.ENUM.WAITING_PRINT)
  const [upsellAssignee, setUpsellAssignee] = useState()
  const [searchUpsell, setSearchUpsell] = useState()
  const [isEdited, setIsEdited] = useState('ALL')
  const [selected, setSelected] = useState([])
  const [doUpdate, { updating }] = useMutation(type === 'UPSELL' ? ASSIGN_UPSELL : UPDATE_PRINTED_ADDRESS_LABELS)
  const [getData, { data, loading }] = useLazyQuery(type === 'UPSELL' ? SALE_ORDERS_FOR_UPSELL : SALE_ORDERS_EXPORT, {
    fetchPolicy: 'cache-and-network',
  })
  const { data: upsellList, loading: upsellListLoading } = useQuery(USERS, {
    variables: {
      name: searchUpsell,
      offset: 0,
      limit: 10,
    },
    skip: type !== 'UPSELL',
  })

  useEffect(() => {
    if (visible) {
      getData({
        variables: {
          limit: 10,
          offset: 0,
          search,
          startDeliveryDate: deliveryDate[0],
          endDeliveryDate: deliveryDate[1],
          startCreatedDate: !isEmpty(createdDate) ? createdDate[0] : undefined,
          endCreatedDate: !isEmpty(createdDate) ? createdDate[1] : undefined,
          addressLabelStatus: type === 'ADDRESS_LABEL_WAITING_PRINT'
            ? printStatus
            : type === 'ADDRESS_LABEL_PRINTING'
              ? ADDRESS_LABEL_STATUS.ENUM.PRINTING
              : undefined,
          isUpsell: type === 'UPSELL' ? upsellStatus === 1 : undefined,
          channel: type === 'ADDRESS_LABEL_WAITING_PRINT' ? channel : undefined,
          isEdited: (type === 'ADDRESS_LABEL_WAITING_PRINT' || type === 'ADDRESS_LABEL_PRINTING')
            ? isEdited === 'ALL'
              ? undefined
              : isEdited
            : undefined,
        },
      })
    }
  }, [deliveryDate, search, createdDate, upsellStatus, printStatus, isEdited, channel])

  useEffect(() => {
    if (visible) {
      getData({
        variables: {
          limit: 10,
          offset: 0,
          search,
          startDeliveryDate: deliveryDate[0],
          endDeliveryDate: deliveryDate[1],
          startCreatedDate: !isEmpty(createdDate) ? createdDate[0] : undefined,
          endCreatedDate: !isEmpty(createdDate) ? createdDate[1] : undefined,
          addressLabelStatus: type === 'ADDRESS_LABEL_WAITING_PRINT'
            ? printStatus
            : type === 'ADDRESS_LABEL_PRINTING'
              ? ADDRESS_LABEL_STATUS.ENUM.PRINTING
              : undefined,
          isUpsell: type === 'UPSELL' ? upsellStatus === 1 : undefined,
          channel: type === 'ADDRESS_LABEL_WAITING_PRINT' ? channel : undefined,
          isEdited: (type === 'ADDRESS_LABEL_WAITING_PRINT' || type === 'ADDRESS_LABEL_PRINTING')
            ? isEdited === 'ALL'
              ? undefined
              : isEdited
            : undefined,
        },
      })
    }
  }, [visible])

  const list = data?.[type === 'UPSELL' ? 'salesOrderListForUpsell' : 'salesOrderListExport']?.data?.list
  const total = data?.[type === 'UPSELL' ? 'salesOrderListForUpsell' : 'salesOrderListExport']?.data?.total

  return (
    <Div>
      <Modal
        title={`
          เลือกใบออเดอร์เพื่อ ${
            type === 'ADDRESS_LABEL_WAITING_PRINT'
              ? 'พิมพ์ใบปะหน้า'
              : type === 'ADDRESS_LABEL_PRINTING'
                ? 'เปลี่ยนสถานะใบปะหน้าเป็นพิมพ์แล้ว'
                : type === 'UPSELL'
                  ? 'แจกงาน Upsell'
                  : 'Export Kerry Template'
          }`
        }
        visible={visible}
        okButton={{
          text: 'ยืนยัน',
          color: theme.color.success,
        }}
        width='80vw'
        style={{ top: '2%' }}
        onOk={() => {
          if (type === 'ADDRESS_LABEL_WAITING_PRINT') {
            setIsExporting(true)
            window.open(
              `${URL.API_HOST}/print/address-labels/[${selected.map((so) => so.id)}]?t=${getAccessToken()}`,
              '_blank',
              'toolbar=false,scrollbars=yes,resizable=false',
            )
            setVisible(false)
            setIsExporting(false)
            setChannel(DELIVERY_CHANNEL.KERRY.KEY)
            setSearch('')
            setSelected([])
            setDeliveryDate([moment().startOf('day'), moment().endOf('day')])
            setCreatedDate([])
          } else if (type === 'ADDRESS_LABEL_PRINTING') {
            doUpdate({
              variables: {
                soIds: selected.map((so) => so.id),
              },
            }).then((resp) => showMessageMutation(resp.data.updatePrintedAddressLabels, () => {
              setVisible(false)
              setIsExporting(false)
              setChannel(DELIVERY_CHANNEL.KERRY.KEY)
              setSearch('')
              setSelected([])
              setDeliveryDate([moment().startOf('day'), moment().endOf('day')])
              setCreatedDate([])
            }))
          } else if (type === 'UPSELL') {
            doUpdate({
              variables: {
                input: {
                  userId: upsellAssignee,
                  salesOrderIds: selected.map((so) => so.id),
                },
              },
            }).then((resp) => showMessageMutation(resp.data.assignUpsell, () => {
              setVisible(false)
              setIsExporting(false)
              setSearch('')
              setSelected([])
              setDeliveryDate([moment().startOf('day'), moment().endOf('day')])
              setCreatedDate([])
              setUpsellStatus()
              setUpsellAssignee()
              setSearchUpsell()
            }))
          } else {
            setIsExporting(true)
            axios({
              method: 'POST',
              responseType: 'blob',
              headers: {
                Authorization: `Bearer ${getAccessToken()}`,
              },
              url: `${URL.API_HOST}/kerry-template`,
              data: {
                soIDs: selected.map((so) => so.id),
              },
            }).then((resp) => {
              fileDownload(resp.data, 'kerry-template.xlsx')
              setIsExporting(false)
            })
          }
        }}
        okButtonProps={{ loading: isExporting || updating, disabled: isEmpty(selected) || (type === 'UPSELL' && isNil(upsellAssignee)) }}
        cancelButtonProps={{ disabled: isExporting || updating }}
        onCancel={() => {
          setSearch('')
          setChannel(DELIVERY_CHANNEL.KERRY.KEY)
          setSelected([])
          setDeliveryDate([moment().startOf('day'), moment().endOf('day')])
          setCreatedDate([])
          setVisible()
          setSearchUpsell()
        }}
      >
        <Div display='flex' width='100%' flexDirection='column' alignItems='center' justifyContent='space-between'>
          <Div height='75vh' width='100%' style={{ overflow: 'auto', height: '75vh', marginTop: 40 }}>
            <Div display='flex'>
              <Div display='flex' flexDirection='column' margin='0 16px 0 0'>
                <P style={{ lineHeight: '30px' }} bold>ค้นหา</P>
                <Input onChange={(e) => {
                  setCurrentPage(1)
                  setSearch(e.target.value)
                }} placeholder='กรอกคำค้นหา'/>
              </Div>
              <Div display='flex' flexDirection='column' width='fit-content' margin='0 16px 0 0'>
                <P style={{ lineHeight: '30px' }} bold>วันที่จัดส่ง</P>
                <DatePicker.RangePicker onChange={(e) => {
                  setCurrentPage(1)
                  setDeliveryDate(e)
                }} allowClear={false} value={deliveryDate} style={{ minWidth: 220 }} />
              </Div>
              <Div display='flex' flexDirection='column' width='fit-content' margin='0 16px 0 0'>
                <P style={{ lineHeight: '30px' }} bold>วันที่สร้าง</P>
                <DatePicker.RangePicker onChange={(e) => {
                  setCurrentPage(1)
                  setCreatedDate(e)
                }} allowClear={false} value={createdDate} style={{ minWidth: 220 }} />
              </Div>
              {
                type === 'UPSELL'
                && <Div display='flex' flexDirection='column' margin='0 16px 0 0'>
                  <P style={{ lineHeight: '30px' }} bold>สถานะ Upsell</P>
                  <SelectAntd value={upsellStatus} style={{ width: 240 }} onChange={(e) => {
                    setCurrentPage(1)
                    setUpsellStatus(e)
                  }}>
                    <SelectAntd.Option value={0}>ยังไม่แจกงาน Upsell</SelectAntd.Option>
                    <SelectAntd.Option value={1}>แจกงาน Upsell แล้ว</SelectAntd.Option>
                  </SelectAntd>
                </Div>
              }
              {
                type === 'ADDRESS_LABEL_WAITING_PRINT'
                && <Div display='flex' flexDirection='column' margin='0 16px 0 0'>
                  <P style={{ lineHeight: '30px' }} bold>สถานะใบปะหน้า</P>
                  <SelectAntd value={printStatus} style={{ width: 240 }} onChange={(e) => {
                    setCurrentPage(1)
                    setPrintStatus(e)
                  }}>
                    <SelectAntd.Option value={ADDRESS_LABEL_STATUS.ENUM.WAITING_PRINT}>{ADDRESS_LABEL_STATUS.TEXT.WAITING_PRINT}</SelectAntd.Option>
                    <SelectAntd.Option value={ADDRESS_LABEL_STATUS.ENUM.PRINTING}>{ADDRESS_LABEL_STATUS.TEXT.PRINTING}</SelectAntd.Option>
                    <SelectAntd.Option value={ADDRESS_LABEL_STATUS.ENUM.PRINTED}>{ADDRESS_LABEL_STATUS.TEXT.PRINTED}</SelectAntd.Option>
                  </SelectAntd>
                </Div>
              }
              {
                (type === 'ADDRESS_LABEL_WAITING_PRINT' || type === 'ADDRESS_LABEL_PRINTING')
                && <Div display='flex' flexDirection='column' margin='0 16px 0 0'>
                  <P style={{ lineHeight: '30px' }} bold>สถานะการแก้ไข</P>
                  <SelectAntd value={isEdited} style={{ width: 240 }} onChange={(e) => {
                    setCurrentPage(1)
                    setIsEdited(e)
                  }}>
                    <SelectAntd.Option value={'ALL'}>ทั้งหมด</SelectAntd.Option>
                    <SelectAntd.Option value={true}>มีการแก้ไขแล้ว</SelectAntd.Option>
                    <SelectAntd.Option value={false}>ยังไม่มีการแก้ไข</SelectAntd.Option>
                  </SelectAntd>
                </Div>
              }
              {
                type === 'ADDRESS_LABEL_WAITING_PRINT'
                && <Div display='flex' flexDirection='column' margin='0 16px 0 0'>
                  <P style={{ lineHeight: '30px' }} bold>ประเภทการขนส่ง</P>
                  <SelectAntd value={channel} style={{ width: 240 }} onChange={(e) => {
                    setCurrentPage(1)
                    setChannel(e)
                  }}>
                    <SelectAntd.Option value={DELIVERY_CHANNEL.EMS.KEY}>EMS</SelectAntd.Option>
                    <SelectAntd.Option value={DELIVERY_CHANNEL.KERRY.KEY}>Kerry และอื่นๆ</SelectAntd.Option>
                  </SelectAntd>
                </Div>
              }
              {
                type === 'UPSELL'
                && <Div display='flex' flexDirection='column' margin='0 16px 0 0'>
                  <P style={{ lineHeight: '30px' }} bold>แจกงานให้</P>
                  <SelectAntd
                    value={upsellAssignee}
                    loading={upsellListLoading}
                    showSearch
                    style={{ width: 240 }}
                    onSearch={(e) => setSearchUpsell(e)}
                    onChange={(e) => {
                      setCurrentPage(1)
                      setUpsellAssignee(e)
                    }}
                    notFoundContent={null}
                    showArrow={false}
                    filterOption={false}
                  >
                    {
                      upsellList?.userList?.data?.list?.map((user) => <SelectAntd.Option key={user.id} value={user.id}>{user.firstName} {user.lastName}</SelectAntd.Option>)
                    }
                  </SelectAntd>
                </Div>
              }
            </Div>
            {
              loading && !data
                ? <Div>
                  <Skeleton active={true}/>
                  <Skeleton active={true}/>
                </Div>
                : isEmpty(list) && !loading
                  ? <Div display='flex' flexDirection='column' alignItems='center' margin='40px 0 0 0'>
                    <Icon color={theme.color.gray10} icon='far fa-empty-set' fontSize='36px'/>
                    <H4 color={theme.color.gray10}>ไม่พบใบออเดอร์</H4>
                  </Div>
                  : <>
                    <Div margin='8px 0 0 8px' width='calc(100% - 28px)' padding='0 0 0 48px'>
                      <Row gutter={16}>
                        <Col span={4}>
                          <Div display='flex' flexDirection='column'>
                            <P bold>เลขใบออเดอร์</P>
                          </Div>
                        </Col>
                        <Col span={4}>
                          <Div display='flex' flexDirection='column'>
                            <P bold>ลูกค้า</P>
                          </Div>
                        </Col>
                        <Col span={4}>
                          <Div display='flex' flexDirection='column'>
                            <P bold>เบอร์โทร</P>
                          </Div>
                        </Col>
                        <Col span={4}>
                          <Div display='flex' flexDirection='column'>
                            <P bold>วันที่สร้าง</P>
                          </Div>
                        </Col>
                        <Col span={4}>
                          <Div display='flex' flexDirection='column'>
                            <P bold>กำหนดส่ง</P>
                          </Div>
                        </Col>
                        <Col span={4}>
                          <Div display='flex' flexDirection='column'>
                            <P bold>ยอดชำระ</P>
                          </Div>
                        </Col>
                      </Row>
                    </Div>
                    {
                      loading && data
                        ? <Skeleton active={true} style={{ marginTop: 16 }} />
                        : [...selected, ...filter(list, (item) => !includes(selected.map((selectedSO) => selectedSO.id), item.id))]?.map((o) => (
                          <RadioTitleBoxWrapper key={`${o.id}_${type}`}>
                            <input type='checkbox' id={`${o.id}_${type}`} value={`${o.id}_${type}`} name={o.soNumber} checked={find(selected, (v) => v.id === o.id)} onChange={(e) => {
                              if (e.target.checked) {
                                setSelected([...selected, find(list, (so) => so.id === o.id)])
                              } else {
                                setSelected(remove(cloneDeep(selected), (so) => so.id !== o.id))
                              }
                            }} />
                            <label htmlFor={`${o.id}_${type}`}>
                              <Div
                                display='flex'
                                alignItems='flex-start'
                                justifyContent='space-between'
                                padding='16px'
                                margin='4px 0'
                                borderRadius='4px'
                                border={`1px solid ${theme?.color?.gray20}`}
                                moreStyle={{ cursor: 'pointer' }}
                              >
                                <Div display='flex' alignItems='flex-start' width='100%'>
                                  <RadioLabel margin='2px 16px 0 0'>
                                    <Div style={{ top: -6 }}><Icon icon='fas fa-check' color={theme?.color?.primaryColor}/></Div>
                                  </RadioLabel>
                                  <Div margin='0 0 0 8px' width='100%'>
                                    <Row gutter={16}>
                                      <Col span={4}>
                                        <Div display='flex' flexDirection='column'>
                                          <P>{o.soNumber}</P>
                                        </Div>
                                      </Col>
                                      <Col span={4}>
                                        <Div display='flex' flexDirection='column'>
                                          <P>{o.customer?.name}</P>
                                        </Div>
                                      </Col>
                                      <Col span={4}>
                                        <Div display='flex' flexDirection='column'>
                                          <P>{
                                            first(o.lots)?.deliveryAddress?.contactPhoneNumber
                                        || find(o.customer?.contactPhoneNumber, (v) => v.isPrimary)?.phoneNumber
                                          }</P>
                                        </Div>
                                      </Col>
                                      <Col span={4}>
                                        <Div display='flex' flexDirection='column'>
                                          <P>{moment(o.createdAt).format('YYYY/MM/DD')}</P>
                                        </Div>
                                      </Col>
                                      <Col span={4}>
                                        <Div display='flex' flexDirection='column'>
                                          <P>{moment(first(o.lots).deliveryDate).format('YYYY/MM/DD')}</P>
                                        </Div>
                                      </Col>
                                      <Col span={4}>
                                        <Div display='flex' flexDirection='column'>
                                          <P>{numeral(o.subtotalAfterDiscount - (o.paidTotal || 0)).format('0,0.[00]')}</P>
                                        </Div>
                                      </Col>
                                    </Row>
                                  </Div>
                                </Div>
                              </Div>
                            </label>
                          </RadioTitleBoxWrapper>
                        ))}
                    {
                      loading && <Skeleton active={true}/>
                    }
                  </>
            }
          </Div>
          <Div display='flex'>
            <Pagination
              defaultCurrent={1}
              current={currentPage}
              total={total}
              showSizeChanger={false}
              onChange={(e) => {
                setCurrentPage(e)
                getData({
                  variables: {
                    limit: 10,
                    offset: (e - 1) * 10,
                    search,
                    startDeliveryDate: deliveryDate[0],
                    endDeliveryDate: deliveryDate[1],
                    startCreatedDate: !isEmpty(createdDate) ? createdDate[0] : undefined,
                    endCreatedDate: !isEmpty(createdDate) ? createdDate[1] : undefined,
                    channel: type === 'ADDRESS_LABEL_WAITING_PRINT' ? channel : undefined,
                    addressLabelStatus: type === 'ADDRESS_LABEL_WAITING_PRINT'
                      ? printStatus
                      : type === 'ADDRESS_LABEL_PRINTING'
                        ? ADDRESS_LABEL_STATUS.ENUM.PRINTING
                        : undefined,
                    isUpsell: type === 'UPSELL' ? upsellStatus === 1 : undefined,
                    isEdited: (type === 'ADDRESS_LABEL_WAITING_PRINT' || type === 'ADDRESS_LABEL_PRINTING')
                      ? isEdited === 'ALL'
                        ? undefined
                        : isEdited
                      : undefined,
                  },
                })
              }}
            />
          </Div>
        </Div>
      </Modal>
      <span onClick={() => setVisible(true)}>
        {
          type === 'ADDRESS_LABEL_WAITING_PRINT'
            ? 'พิมพ์ใบปะหน้า'
            : type === 'ADDRESS_LABEL_PRINTING'
              ? 'เปลี่ยนสถานะใบปะหน้า'
              : type === 'UPSELL'
                ? ' แจกงาน Upsell'
                : 'Export Kerry template'
        }
      </span>
    </Div>
  )
}
