import gql from 'graphql-tag'
import { SALES_ORDER_FIELDS, VARIANT_FIELDS, INVENTORY_FIELDS } from './fragments'

export const MyPerm = gql`
  query MyPerm {
    MyPerm {
      success
      message
    }
  }
`

export const GET_WITHHOLDING_TAX = gql`
  query withholdingTaxList {
    withholdingTaxList {
      success
      message
      data {
        id
        value
        createdAt
        updatedAt
      }
    }
  }
`

export const USERS = gql`
  query userList(
    $limit: Int
    $offset: Int
    $id: Int
    $teamId: Int
    $name: String
    $status: Status
    $department: String
    $orderField: UserOrderField
    $orderBy: OrderBy
  ) {
    userList(
      input: {
        limit: $limit
        offset: $offset
        id: $id
        teamId: $teamId
        name: $name
        status: $status
        department: $department
        orderField: $orderField
        orderBy: $orderBy
      }
    ) {
      data {
        list {
          id
          firstName
          lastName
          email
          phoneNumber
          status
          team {
            id
            teamName
          }
          roles {
            id
            name
            description
            permissions {
              id
              name
              description
            }
          }
          warehouse {
            id
            warehouseName
          }
          createdAt
          updatedAt
        }
        total
      }
    }
  }
`

export const ROLES = gql`
  query roleList($limit: Int, $offset: Int, $name: String) {
    roleList(input: { limit: $limit, offset: $offset, name: $name }) {
      data {
        list {
          id
          name
          description
          permissions {
            id
            name
            description
          }
          createdAt
          updatedAt
        }
        total
      }
    }
  }
`

export const BANKS = gql`
  query bankList {
    bankList {
      data {
        list {
          id
          code
          name
          status
          createdAt
          updatedAt
        }
        total
      }
    }
  }
`

export const EXPENSE = gql`
  query expenseDetail($id: Int!) {
    expenseDetail(id: $id) {
      data {
        id
        invoices {
          id
          number
          debtHistories {
            id
            files {
              id
              fileName
              url
            }
            number
            type
            totalPriceBefore
            totalPriceAfter
            createDate
            itemChanges {
              id
              price
              remarks
              quantity
              orderItem {
                id
                quantity
                salesPrice
                variantName
                type
                productSet {
                  id
                  name
                  sku
                }
              }
            }
          }
        }
        receipts {
          id
          number
          debtHistories {
            id
            files {
              id
              fileName
              url
            }
            number
            type
            totalPriceBefore
            totalPriceAfter
            createDate
            itemChanges {
              id
              price
              remarks
              quantity
              orderItem {
                id
                quantity
                salesPrice
                variantName
                type
                productSet {
                  id
                  name
                  sku
                }
              }
            }
          }
        }
      }
      success
      message
    }
  }
`

export const WAREHOUSES = gql`
  query warehouseList(
    $limit: Int
    $offset: Int
    $warehouseCode: String
    $warehouseName: String
    $orderField: WarehouseOrderField
    $orderBy: OrderBy
  ) {
    warehouseList(
      input: {
        limit: $limit
        offset: $offset
        warehouseCode: $warehouseCode
        warehouseName: $warehouseName
        orderField: $orderField
        orderBy: $orderBy
      }
    ) {
      data {
        list {
          id
          warehouseCode
          warehouseName
          address {
            id
            addressNo
            supplierId
            type
            province
            district
            subDistrict
            postcode
            contactName
            contactPhoneNumber
            contactFaxNumber
            contactEmail
            contactRemark
          }
        }
        total
      }
    }
  }
`

export const SUPPLIERS = gql`
  query supplierList(
    $limit: Int
    $offset: Int
    $name: String
    $orderField: SupplierOrderField
    $orderBy: OrderBy
  ) {
    supplierList(
      input: {
        limit: $limit
        offset: $offset
        name: $name
        orderField: $orderField
        orderBy: $orderBy
      }
    ) {
      data {
        list {
          id
          number
          paymentType
          prefix
          name
          taxId
          #bankAccountName
          #bankAccountNumber
          #bank {
          #  id
          #  code
          #  name
          #  status
          #}
          isVat
          contactName
          contactPhoneNumber {
            id
            name
            phoneNumber
            isPrimary
          }
          contactFaxNumber
          contactEmail {
            id
            email
            isPrimary
          }
          contactFacebookName
          contactRemark
          #creditLimit
          #creditUsage
          #creditTerm
          status
          createdAt
          updatedAt
          paymentInfo {
            id
            bank {
              id
              code
              name
              status
            }
            bankAccountType
            bankAccountName
            bankAccountNumber
          }
          gender
          birthDate
          idCardNumber
          contactAddress {
            id
            supplierId
            customerId
            type
            addressNo
            province
            district
            subDistrict
            postcode
            contactName
            contactOfficeTel
            contactPhoneNumber
            contactLineId
            contactFacebook
            contactFaxNumber
            contactEmail
            contactRemark
            isPrimary
          }
          shippingAddress {
            id
            supplierId
            customerId
            type
            addressNo
            province
            district
            subDistrict
            postcode
            contactName
            contactOfficeTel
            contactPhoneNumber
            contactLineId
            contactFacebook
            contactFaxNumber
            contactEmail
            contactRemark
            isPrimary
            thirdPartyAddress
          }
        }
        total
      }
    }
  }
`

export const PURCHASEORDER_DETAIL = gql`
  query purchaseOrderDetail($id: Int!) {
    purchaseOrderDetail(id: $id) {
      success
      message
      data {
        id
        number
        extRef
        status
        dueDate
        paymentType
        remark
        discountType
        discount
        confirmable
        deliveryCost
        supplier {
          id
          paymentType
          prefix
          name
          taxId
          #bankAccountName
          #bankAccountNumber
          paymentInfo {
            bank {
              id
              code
              name
              status
            }
            bankAccountType
            bankAccountName
            bankAccountNumber
          }
          billingAddress {
            id
            supplierId
            customerId
            type
            taxId
            addressNo
            province
            district
            subDistrict
            postcode
            contactName
            contactOfficeTel
            contactPhoneNumber
            contactFaxNumber
            contactEmail
            contactRemark
          }
          contactAddress {
            id
            supplierId
            customerId
            type
            addressNo
            province
            district
            subDistrict
            postcode
            contactName
            contactOfficeTel
            contactPhoneNumber
            contactFaxNumber
            contactEmail
            contactRemark
          }
          shippingAddress {
            id
            supplierId
            customerId
            type
            addressNo
            province
            district
            subDistrict
            postcode
            contactName
            contactOfficeTel
            contactPhoneNumber
            contactFaxNumber
            contactEmail
            contactRemark
            thirdPartyAddress
          }
          isVat
          contactName
          contactFaxNumber
          contactRemark
          status
          createdAt
          updatedAt
        }
        withholdingTax {
          id
          value
          createdAt
          updatedAt
        }
        warehouse {
          warehouseCode
          warehouseName
          id
        }
        orderItemWithSaleOrder {
          id
          _id
          soNumber
          status
          dueDate
          type
          remarks
          subtotal
          discountAmount
          vatAmount
          subtotalBeforeVat
          deliveryCost
          subtotalWithDelivery
          subtotalAfterDiscount
          items {
            id
            isMarkedAsCompleted
            itemSource
            status
            buyPrice
            salesPrice
            quantity
            expectedReceivedDate
            overdueDate
            isVat
            discount
            vat
            isSerialTracking
            lotIndex
            receivedQuantity
            serialNumbers {
              id
              serial
            }
            type
            purchaseOrderDiscount
            childOrderItems {
              id
              buyPrice
              quantity
              receivedQuantity
              variantName
              isVat
              vat
              pack {
                id
                name
                variantName
                quantity
                middlePrice
                itemPricingTypes {
                  id
                  salesPrice
                  pricingType {
                    id
                    code
                    name
                  }
                }
                inventoryOption {
                  id
                  variantName
                  inventory {
                    id
                    type
                    sku
                    brand {
                      id
                      name
                    }
                  }
                }
              }
            }
            productSet {
              id
              name
              sku
              buyPrice
              ProductSetItems {
                id
                quantity
                Pack {
                  id
                  name
                  variantName
                  quantity
                  middlePrice
                }
              }
            }
            pack {
              id
              name
              variantName
              quantity
              middlePrice
              itemPricingTypes {
                id
                salesPrice
                pricingType {
                  id
                  code
                  name
                }
              }
              inventoryOption {
                id
                buyPrice
                isVat
                vat
                initialStock
                minimumStock
                variantName
                inventory {
                  id
                  sku
                  type
                  productName
                  brand {
                    id
                    name
                  }
                }
                files {
                  id
                  url
                }
              }
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        orderItemWithOutSaleOrder {
          id
          _id
          isMarkedAsCompleted
          status
          buyPrice
          salesPrice
          quantity
          receivedQuantity
          purchaseOrderDiscount
          expectedReceivedDate
          overdueDate
          isVat
          discount
          vat
          isSerialTracking
          replaceTo
          serialNumbers {
            id
            serial
          }
          type
          childOrderItems {
            id
            buyPrice
            quantity
            receivedQuantity
            variantName
            pack {
              id
              name
              variantName
              quantity
              middlePrice
              itemPricingTypes {
                id
                salesPrice
                pricingType {
                  id
                  code
                  name
                }
              }
              inventoryOption {
                id
                variantName
                inventory {
                  id
                  type
                  sku
                  brand {
                    id
                    name
                  }
                }
              }
            }
          }
          productSet {
            id
            name
            sku
            buyPrice
            ProductSetItems {
              id
              quantity
              Pack {
                id
                name
                variantName
                quantity
                middlePrice
              }
            }
          }
          pack {
            id
            name
            variantName
            quantity
            middlePrice
            itemPricingTypes {
              id
              salesPrice
              pricingType {
                id
                code
                name
              }
            }
            inventoryOption {
              id
              buyPrice
              isVat
              vat
              initialStock
              minimumStock
              variantName
              inventory {
                id
                sku
                type
                productName
                brand {
                  id
                  name
                }
              }
            }
          }
          createdAt
          updatedAt
        }
        purchaseOrderFiles {
          id
          fileName
          url
          createdAt
          updatedAt
        }
        subtotal
        vatAmount
        subtotalBeforeVat
        discountAmount
        subtotalWithDelivery
        subtotalAfterDiscount
        deliveryCost
        createdAt
        updatedAt
      }
    }
  }
`

export const PURCHASEORDERS = gql`
  query purchaseOrderList(
    $limit: Int
    $offset: Int
    $search: String
    $status: PurchaseOrderStatus
    $createdDate: [Date]
    $paymentDueDate: [Date]
    $orderField: PurchaseOrderField
    $orderBy: OrderBy
  ) {
    purchaseOrderList(
      input: {
        limit: $limit
        offset: $offset
        search: $search
        status: $status
        createdDate: $createdDate
        paymentDueDate: $paymentDueDate
        orderField: $orderField
        orderBy: $orderBy
      }
    ) {
      success
      message
      data {
        total
        list {
          id
          number
          status
          dueDate
          supplier {
            id
            name
          }
          confirmable
          createdAt
        }
      }
    }
  }
`

export const CUSTOMERS = gql`
  query customerList(
    $limit: Int
    $offset: Int
    $name: String
    $pricingTypeId: Int
    $orderField: CustomerOrderField
    $orderBy: OrderBy
    $paymentType: PaymentTypes
  ) {
    customerList(
      input: {
        limit: $limit
        offset: $offset
        name: $name
        pricingTypeId: $pricingTypeId
        orderField: $orderField
        orderBy: $orderBy
        paymentType: $paymentType
      }
    ) {
      data {
        list {
          id
          number
          paymentType
          pricingType {
            id
            code
            name
            status
          }
          prefix
          name
          subtotalAccount
          type
          idCardNumber
          taxId
          gender
          birthDate
          idCardNumber
          billingAddress {
            id
            supplierId
            customerId
            type
            taxId
            addressNo
            province
            district
            subDistrict
            postcode
            contactName
            contactOfficeTel
            contactPhoneNumber
            contactFaxNumber
            contactEmail
            contactRemark
            isPrimary
          }
          contactAddress {
            id
            supplierId
            customerId
            type
            position
            addressNo
            province
            district
            subDistrict
            postcode
            contactName
            contactOfficeTel
            contactPhoneNumber
            contactFaxNumber
            contactEmail
            contactRemark
            contactLineId
            contactFacebook
            isPrimary
          }
          shippingAddress {
            id
            supplierId
            customerId
            type
            addressNo
            province
            district
            subDistrict
            postcode
            contactName
            contactOfficeTel
            contactPhoneNumber
            contactFaxNumber
            contactEmail
            contactRemark
            shopName
            isPrimary
            thirdPartyAddress
          }
          isVat
          contactName
          contactPhoneNumber {
            id
            name
            phoneNumber
            isPrimary
          }
          contactFaxNumber
          contactEmail {
            id
            email
            isPrimary
          }
          contactRemark
          creditLimit
          creditUsage
          creditTerm
          status
          createdAt
          updatedAt
        }
        total
      }
    }
  }
`

export const GET_PO_STATUS = gql`
  query getPOStatusList {
    getPOStatusList {
      success
      message
      data
    }
  }
`

export const CATEGORIES = gql`
  query getCategoryList {
    getCategoryList {
      data {
        id
        name
      }
    }
  }
`

export const PRODUCT_GROUPS = gql`
  query getProduceTypeList {
    getProduceTypeList {
      data {
        id
        name
      }
    }
  }
`

export const BRANDS = gql`
  query getBrandList {
    getBrandList {
      data {
        id
        name
      }
    }
  }
`

export const WAREHOUSE = gql`
  query findWarehouse($id: Int) {
    findWarehouse(id: $id) {
      data {
        id
        address {
          id
          addressNo
          province
          district
          subDistrict
          postcode
          contactName
          contactPhoneNumber
          contactFaxNumber
          contactEmail
          contactRemark
        }
        warehouseCode
        warehouseName
      }
    }
  }
`

export const VARIANTOPTIONSKEY = gql`
  query getOptionKey {
    getOptionKey {
      data
    }
  }
`

export const USER = gql`
  query userDetail($id: Int!) {
    userDetail(id: $id) {
      data {
        id
        firstName
        lastName
        email
        phoneNumber
        status
        team {
          id
          teamName
        }
        roles {
          id
          name
          description
          permissions {
            id
            name
            description
          }
        }
        warehouse {
          id
          warehouseName
        }
        createdAt
        updatedAt
      }
    }
  }
`

export const SUPPLIER = gql`
  query supplierDetail($id: Int!) {
    supplierDetail(id: $id) {
      data {
        id
        type
        paymentType
        prefix
        name
        taxId
        gender
        birthDate
        idCardNumber
        paymentInfo {
          bank {
            id
            code
            name
            status
          }
          bankAccountType
          bankAccountName
          bankAccountNumber
        }
        billingAddress {
          id
          supplierId
          customerId
          type
          addressNo
          province
          district
          subDistrict
          postcode
          taxId
          contactName
          contactOfficeTel
          contactPhoneNumber
          contactFaxNumber
          contactEmail
          contactRemark
        }
        contactAddress {
          id
          supplierId
          customerId
          type
          addressNo
          province
          district
          subDistrict
          postcode
          contactName
          contactOfficeTel
          contactPhoneNumber
          contactFaxNumber
          contactEmail
          contactRemark
          isPrimary
          contactLineId
          contactFacebook
        }
        shippingAddress {
          id
          supplierId
          customerId
          type
          addressNo
          province
          district
          subDistrict
          postcode
          contactName
          contactOfficeTel
          contactPhoneNumber
          contactFaxNumber
          contactEmail
          contactRemark
          isPrimary
          thirdPartyAddress
        }
        isVat
        contactName
        contactPhoneNumber {
          id
          name
          phoneNumber
          isPrimary
        }
        contactFaxNumber
        contactEmail {
          id
          email
          isPrimary
        }
        contactFacebookName
        contactRemark
        status
        createdAt
        updatedAt
      }
    }
  }
`

export const CUSTOMER = gql`
  query customerDetail($id: Int!) {
    customerDetail(id: $id) {
      data {
        id
        paymentType
        type
        pricingType {
          id
          code
          name
          status
        }
        prefix
        name
        taxId
        gender
        birthDate
        idCardNumber
        billingAddress {
          id
          supplierId
          customerId
          type
          addressNo
          province
          district
          subDistrict
          postcode
          contactName
          contactOfficeTel
          contactPhoneNumber
          contactFaxNumber
          contactEmail
          contactRemark
          taxId
          billingType
          isPrimary
        }
        contactAddress {
          id
          supplierId
          customerId
          type
          position
          addressNo
          province
          district
          subDistrict
          postcode
          contactName
          contactOfficeTel
          contactPhoneNumber
          contactFaxNumber
          contactEmail
          contactRemark
          contactLineId
          contactFacebook
          position
          isPrimary
        }
        shippingAddress {
          id
          supplierId
          customerId
          type
          addressNo
          province
          district
          subDistrict
          postcode
          contactName
          contactOfficeTel
          contactPhoneNumber
          contactFaxNumber
          contactEmail
          contactRemark
          shopName
          isPrimary
          thirdPartyAddress
        }
        isVat
        contactName
        contactPhoneNumber {
          id
          name
          phoneNumber
          isPrimary
        }
        contactFaxNumber
        contactEmail {
          id
          email
          isPrimary
        }
        contactRemark
        creditLimit
        creditUsage
        creditTerm
        status
        createdAt
        updatedAt
      }
    }
  }
`

export const ITEMS_BY_SUPPLIER = gql`
  query inventoryItemListBySupplier($id: Int!) {
    inventoryItemListBySupplier(id: $id) {
      success
      message
      data {
        inventoryFromSO {
          id
          sku
          type
          reference
          barcode
          productName
          productGroup {
            id
            name
          }
          stockable
          stockType
          productCategory {
            id
            name
          }
          brand {
            id
            name
          }
          initialCost
          sellable
          purchasable
          taxable
          serialTracking
          status
          inventoryOptions {
            id
            sku
            buyPrice
            salesPrice
            isVat
            vat
            option1 {
              id
              optionKey
              optionName
            }
            option2 {
              id
              optionKey
              optionName
            }
            option3 {
              id
              optionKey
              optionName
            }
            initialStock
            minimumStock
            pack {
              id
              name
            }
          }
        }
        inventoryLess {
          id
          sku
          type
          reference
          barcode
          productName
          productGroup {
            id
            name
          }
          stockable
          stockType
          productCategory {
            id
            name
          }
          brand {
            id
            name
          }
          initialCost
          sellable
          purchasable
          taxable
          serialTracking
          status
          inventoryOptions {
            id
            sku
            buyPrice
            salesPrice
            isVat
            vat
            option1 {
              id
              optionKey
              optionName
            }
            option2 {
              id
              optionKey
              optionName
            }
            option3 {
              id
              optionKey
              optionName
            }
            initialStock
            minimumStock
          }
        }
        inventoryOther {
          id
          sku
          type
          reference
          barcode
          productName
          productGroup {
            id
            name
          }
          stockable
          stockType
          productCategory {
            id
            name
          }
          brand {
            id
            name
          }
          initialCost
          sellable
          purchasable
          taxable
          serialTracking
          status
          inventoryOptions {
            id
            sku
            buyPrice
            salesPrice
            isVat
            vat
            option1 {
              id
              optionKey
              optionName
            }
            option2 {
              id
              optionKey
              optionName
            }
            option3 {
              id
              optionKey
              optionName
            }
            initialStock
            minimumStock
          }
        }
      }
    }
  }
`

export const INVENTORIES = gql`
  query inventoryList(
    $limit: Int
    $offset: Int
    $search: String
    $supplierId: Int
    $productGroup: String
    $productCategory: String
    $brand: String
    $status: Status
    $orderBy: OrderBy
    $orderField: InventoryOrderField
  ) {
    inventoryList(
      input: {
        limit: $limit
        offset: $offset
        search: $search
        supplierId: $supplierId
        productGroup: $productGroup
        productCategory: $productCategory
        brand: $brand
        status: $status
        orderField: $orderField
        orderBy: $orderBy
      }
    ) {
      data {
        list {
          id
          sku
          productName
          brand {
            id
            name
          }
          inventoryOption {
            id
          }
          sellable
          totalStock
          soldStock
          toBeStocked
        }
        total
      }
      success
      message
    }
  }
`

export const INVENTORY = gql`
  ${INVENTORY_FIELDS}
  query inventoryInfo($id: Int) {
    inventoryInfo(id: $id) {
      data {
        ...InventoryFields
      }
      success
      message
    }
  }
`

export const SALE_ORDER = gql`
  ${SALES_ORDER_FIELDS}
  query salesOrderDetail($id: Int!) {
    salesOrderDetail(id: $id) {
      data {
        ...SalesOrderFields
      }
    }
  }
`

export const SALE_ORDERS = gql`
  query salesOrderList(
    $limit: Int
    $offset: Int
    $search: String
    $status: SalesOrderStatusGroup
    $startCreatedDate: Date
    $endCreatedDate: Date
    $startDeliveryDate: Date
    $endDeliveryDate: Date
    $chatProviderId: Int
    $orderBy: OrderBy
    $orderField: SaleOrderField
    $salesOrderType: SalesOrderTypeFieldInput
    $addressLabelStatus: AddressLabelStatusInput
    $isEdited: Boolean
    $myUpsell: Boolean
  ) {
    salesOrderList(
      input: {
        limit: $limit
        offset: $offset
        search: $search
        chatProviderId: $chatProviderId
        status: $status
        startCreatedDate: $startCreatedDate
        endCreatedDate: $endCreatedDate
        startDeliveryDate: $startDeliveryDate
        endDeliveryDate: $endDeliveryDate
        orderField: $orderField
        orderBy: $orderBy
        salesOrderType: $salesOrderType
        addressLabelStatus: $addressLabelStatus
        isEdited: $isEdited
        myUpsell: $myUpsell
      }
    ) {
      data {
        list {
          id
          soNumber
          ordersn
          status
          type
          salesOrderType
          subtotalAfterDiscount
          paidTotal
          depositReceiptId
          addressLabelStatus
          createdAt
          canUpsell
          hasUpsell
          editCount
          lots {
            id
            deliveryDate
            deliveryType
            deliveryAddress {
              thirdPartyAddress
            }
          }
          paymentHistories {
            id
            type
            dateTime
            subtotal
            attachment
            remarks
          }
          customer {
            id
            prefix
            type
            contactFaxNumber
            contactName
            creditLimit
            creditUsage
            contactPhoneNumber {
              id
              name
              phoneNumber
              isPrimary
            }
            pricingType {
              id
              name
            }
            name
          }
        }
        total
      }
    }
  }
`

export const SALE_ORDERS_EXPORT = gql`
  query salesOrderListExport(
    $limit: Int
    $offset: Int
    $search: String
    $status: SalesOrderStatusGroup
    $startCreatedDate: Date
    $endCreatedDate: Date
    $startDeliveryDate: Date
    $endDeliveryDate: Date
    $orderBy: OrderBy
    $orderField: SaleOrderField
    $salesOrderType: SalesOrderTypeFieldInput
    $addressLabelStatus: AddressLabelStatusInput
    $channel: PrintDeliveryChannel
    $isEdited: Boolean
  ) {
    salesOrderListExport(
      input: {
        limit: $limit
        offset: $offset
        search: $search
        status: $status
        startCreatedDate: $startCreatedDate
        endCreatedDate: $endCreatedDate
        startDeliveryDate: $startDeliveryDate
        endDeliveryDate: $endDeliveryDate
        orderField: $orderField
        orderBy: $orderBy
        salesOrderType: $salesOrderType
        channel: $channel
        addressLabelStatus: $addressLabelStatus
        isEdited: $isEdited
      }
    ) {
      data {
        list {
          id
          soNumber
          status
          type
          salesOrderType
          subtotalAfterDiscount
          paidTotal
          depositReceiptId
          createdAt
          lots {
            id
            deliveryDate
            deliveryAddress {
              id
              addressNo
              province
              district
              subDistrict
              postcode
              contactName
              contactPhoneNumber
              thirdPartyAddress
            }
          }
          paymentHistories {
            id
            type
            dateTime
            subtotal
            attachment
            remarks
          }
          customer {
            id
            prefix
            type
            contactFaxNumber
            contactName
            creditLimit
            creditUsage
            contactPhoneNumber {
              id
              name
              phoneNumber
              isPrimary
            }
            pricingType {
              id
              name
            }
            name
          }
        }
        total
      }
    }
  }
`

export const SALE_ORDERS_FOR_UPSELL = gql`
  query salesOrderListForUpsell(
    $limit: Int
    $offset: Int
    $search: String
    $startCreatedDate: Date
    $endCreatedDate: Date
    $startDeliveryDate: Date
    $endDeliveryDate: Date
    $orderField: SaleOrderField
    $orderBy: OrderBy
    $isUpsell: Boolean
  ) {
    salesOrderListForUpsell(
      input: {
        limit: $limit
        offset: $offset
        search: $search
        startCreatedDate: $startCreatedDate
        endCreatedDate: $endCreatedDate
        startDeliveryDate: $startDeliveryDate
        endDeliveryDate: $endDeliveryDate
        orderField: $orderField
        orderBy: $orderBy
        isUpsell: $isUpsell
      }
    ) {
      data {
        list {
          id
          soNumber
          status
          type
          salesOrderType
          subtotalAfterDiscount
          paidTotal
          depositReceiptId
          createdAt
          lots {
            id
            deliveryDate
            deliveryAddress {
              id
              addressNo
              province
              district
              subDistrict
              postcode
              contactName
              contactPhoneNumber
              thirdPartyAddress
            }
          }
          paymentHistories {
            id
            type
            dateTime
            subtotal
            attachment
            remarks
          }
          customer {
            id
            prefix
            type
            contactFaxNumber
            contactName
            creditLimit
            creditUsage
            contactPhoneNumber {
              id
              name
              phoneNumber
              isPrimary
            }
            pricingType {
              id
              name
            }
            name
          }
        }
        total
      }
    }
  }
`

export const INVENTORIES_BY_SUPPLIER = gql`
  query inventoryItemListBySupplier($id: Int, $search: String) {
    inventoryItemListBySupplier(id: $id, input: { search: $search }) {
      success
      message
      data {
        id
        sku
        type
        reference
        barcode
        productName
        productGroup {
          id
          name
        }
        stockable
        stockType
        productCategory {
          id
          name
        }
        brand {
          id
          name
        }
        initialCost
        variantAmount
        sellable
        purchasable
        taxable
        serialTracking
        status
        tag {
          id
          name
        }
        supplier {
          id
          name
        }
        inventoryOption {
          id
          buyPrice
          isVat
          vat
          initialStock
          minimumStock
          variantName
          pack {
            id
            name
            quantity
          }
          stockLocation {
            id
            row
            shelf
            quantity
            warehouse {
              warehouseCode
              warehouseName
              id
            }
          }
        }
        createdAt
        updatedAt
      }
    }
  }
`

export const ITEM_LIST_BY_SUPPLIER = gql`
  query itemListBySupplier($supplierId: Int, $search: String) {
    itemListBySupplier(supplierId: $supplierId, input: { search: $search }) {
      success
      message
      data {
        itemListOther {
          id
          sku
          type
          _id
          buyPrice
          #reference
          #barcode
          productName
          #productGroup {
          #  id
          #name
          #}
          #stockable
          stockType
          brand {
            id
            name
          }
          #sellable
          #purchasable
          #taxable
          #serialTracking
          #status
          supplier {
            id
            name
            paymentType
          }
          productSetItems {
            id
            quantity
            Pack {
              id
              variantName
              inventoryOption {
                id
                buyPrice
                isVat
                vat
                variantName
                initialStock
                minimumStock
                totalStock
                soldStock
                toBeStocked
                preordered
                stockLocation {
                  id
                  totalStock
                  soldStock
                  toBeStocked
                  preordered
                  warehouse {
                    id
                  }
                }
                pack {
                  id
                }
                inventory {
                  id
                  sku
                  type
                  productName
                  brand {
                    id
                    name
                  }
                }
              }
            }
            ProductSet {
              id
              name
              sku
              availableStart
              availableEnd
              subtotalAfterDiscount
              ProductSetPricingTypes {
                id
                salesPrice
              }
            }
          }
          inventoryOption {
            id
            buyPrice
            isVat
            vat
            variantName
            initialStock
            minimumStock
            totalStock
            soldStock
            toBeStocked
            preordered
            stockLocation {
              id
              totalStock
              soldStock
              toBeStocked
              preordered
              warehouse {
                id
              }
            }
            pack {
              id
            }
            inventory {
              id
              sku
              type
              productName
              brand {
                id
                name
              }
            }
            files {
              id
              url
            }
          }
        }
        itemListBelowMinimum {
          id
          sku
          type
          _id
          reference
          barcode
          productName
          productGroup {
            id
            name
          }
          stockable
          stockType
          #productCategory {
          #  id
          #  name
          #}
          brand {
            id
            name
          }
          sellable
          purchasable
          taxable
          serialTracking
          status
          supplier {
            id
            name
            paymentType
          }
          inventoryOption {
            id
            buyPrice
            isVat
            vat
            variantName
            initialStock
            minimumStock
            totalStock
            soldStock
            toBeStocked
            maximumStock
            preordered
            stockLocation {
              id
              totalStock
              soldStock
              toBeStocked
              preordered
              warehouse {
                id
              }
            }
            pack {
              id
            }
            inventory {
              id
              sku
              type
              productName
              brand {
                id
                name
              }
            }
            files {
              id
              url
            }
          }
        }
      }
    }
  }
`

export const GET_BANK_TYPE = gql`
  query bankAccountTypeList {
    bankAccountTypeList {
      data
    }
  }
`

export const SO_LIST_BY_SUPPLIER = gql`
  query getSOBySupplier($supplierId: Int, $search: String) {
    getSOBySupplier(supplierId: $supplierId, input: { search: $search }) {
      success
      message
      data {
        id
        soNumber
        status
        dueDate
        type
        remarks
        subtotal
        discountAmount
        vatAmount
        subtotalBeforeVat
        deliveryCost
        subtotalWithDelivery
        subtotalAfterDiscount
        customer {
          id
          name
          prefix
        }
        contactAddress {
          id
          contactName
        }
        lots {
          id
          lotIndex
          deliveryDate
          items {
            id
            status
            buyPrice
            salesPrice
            quantity
            expectedReceivedDate
            overdueDate
            isVat
            vat
            isSerialTracking
            warehouse {
              id
              warehouseName
            }
            supplier {
              id
              name
              paymentType
            }
            serialNumbers {
              id
              serial
              createdAt
              updatedAt
            }
            inventoryOption {
              id
              buyPrice
              isVat
              vat
              variantName
              inventory {
                id
                sku
                type
                productName
                brand {
                  id
                  name
                }
                supplier {
                  id
                  name
                  paymentType
                }
              }
              pack {
                id
                name
              }
              initialStock
              minimumStock
              totalStock
              soldStock
              toBeStocked
              preordered
              stockLocation {
                id
                totalStock
                soldStock
                toBeStocked
                preordered
                warehouse {
                  id
                }
              }
              files {
                id
                url
              }
            }
            productSet {
              id
              name
              sku
              buyPrice
              ProductSetItems {
                id
                quantity
                Pack {
                  id
                  name
                  quantity
                  variantName
                  inventoryOption {
                    id
                    buyPrice
                    isVat
                    vat
                    variantName
                    inventory {
                      id
                      sku
                      type
                      productName
                      brand {
                        id
                        name
                      }
                      supplier {
                        id
                        name
                        paymentType
                      }
                    }
                    pack {
                      id
                      name
                    }
                    initialStock
                    minimumStock
                    totalStock
                    soldStock
                    toBeStocked
                    preordered
                    stockLocation {
                      id
                      totalStock
                      soldStock
                      toBeStocked
                      preordered
                      warehouse {
                        id
                      }
                    }
                  }
                }
              }
            }
            pack {
              id
              name
              quantity
              inventoryOption {
                id
                buyPrice
                isVat
                vat
                variantName
                inventory {
                  id
                  sku
                  type
                  productName
                  brand {
                    id
                    name
                  }
                }
                pack {
                  id
                  name
                }
                initialStock
                minimumStock
                totalStock
                soldStock
                toBeStocked
                preordered
                stockLocation {
                  id
                  totalStock
                  soldStock
                  toBeStocked
                  preordered
                  warehouse {
                    id
                  }
                }
                files {
                  id
                  url
                }
              }
            }
            #inventoryOption
          }
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
    }
  }
`

export const GET_PREFIX = gql`
  query supplierPrefix {
    supplierPrefix {
      data
    }
  }
`

export const PURCHASE_ORDERS_BY_SUPPLIER = gql`
  query purchaseOrderListBySupplier(
    $id: Int
    $filterStatus: Boolean
    $search: String
  ) {
    purchaseOrderListBySupplier(
      id: $id
      filterStatus: $filterStatus
      input: { search: $search }
    ) {
      success
      message
      data {
        id
        number
        extRef
        status
        dueDate
        paymentType
        creditDueDate
        remark
        discountType
        discount
        warehouse {
          warehouseCode
          warehouseName
          id
        }
        withholdingTax {
          id
          value
          createdAt
          updatedAt
        }
        supplier {
          id
          name
        }
        orderItemWithSaleOrder {
          id
          _id
          soNumber
          status
          dueDate
          type
          creditDueDate
          warrantyExpireDate
          remarks
          subtotal
          discountAmount
          vatAmount
          subtotalBeforeVat
          deliveryCost
          subtotalWithDelivery
          subtotalAfterDiscount
          items {
            id
            status
            buyPrice
            salesPrice
            receivedQuantity
            quantity
            expectedReceivedDate
            overdueDate
            isVat
            discount
            vat
            isSerialTracking
            purchaseOrderDiscount
            serialNumbers {
              id
              serial
            }
            childOrderItems {
              id
              buyPrice
              quantity
              receivedQuantity
              variantName
              isVat
              vat
              pack {
                id
                name
                variantName
                quantity
                middlePrice
                itemPricingTypes {
                  id
                  salesPrice
                  pricingType {
                    id
                    code
                    name
                  }
                }
                inventoryOption {
                  id
                  variantName
                  inventory {
                    id
                    type
                    sku
                    brand {
                      id
                      name
                    }
                  }
                }
              }
            }
            productSet {
              id
              name
              sku
              ProductSetItems {
                id
                quantity
                Pack {
                  id
                  name
                  variantName
                  quantity
                  middlePrice
                }
              }
            }
            pack {
              id
              name
              inventoryOption {
                id
                buyPrice
                isVat
                vat
                initialStock
                minimumStock
                variantName
                inventory {
                  id
                  sku
                  type
                  productName
                  brand {
                    id
                    name
                  }
                }
                pack {
                  id
                  name
                  quantity
                }
                stockLocation {
                  id
                  row
                  shelf
                  quantity
                  warehouse {
                    warehouseCode
                    warehouseName
                    id
                  }
                }
              }
            }
            #inventoryOption
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        orderItemWithOutSaleOrder {
          id
          _id
          status
          buyPrice
          salesPrice
          receivedQuantity
          quantity
          purchaseOrderDiscount
          expectedReceivedDate
          overdueDate
          isVat
          discount
          vat
          isSerialTracking
          serialNumbers {
            id
            serial
          }
          childOrderItems {
            id
            buyPrice
            quantity
            receivedQuantity
            variantName
            pack {
              id
              name
              variantName
              quantity
              middlePrice
              itemPricingTypes {
                id
                salesPrice
                pricingType {
                  id
                  code
                  name
                }
              }
              inventoryOption {
                id
                variantName
                inventory {
                  id
                  type
                  sku
                  brand {
                    id
                    name
                  }
                }
              }
            }
          }
          productSet {
            id
            name
            sku
            ProductSetItems {
              id
              quantity
              Pack {
                id
                name
                variantName
                quantity
                middlePrice
              }
            }
          }
          pack {
            id
            name
            inventoryOption {
              id
              buyPrice
              isVat
              vat
              initialStock
              minimumStock
              variantName
              inventory {
                id
                sku
                type
                productName
                brand {
                  id
                  name
                }
              }
              pack {
                id
                name
                quantity
              }
              stockLocation {
                id
                row
                shelf
                quantity
                warehouse {
                  warehouseCode
                  warehouseName
                  id
                }
              }
            }
          }
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
    }
  }
`

export const GET_OPTIONS = gql`
  query getOptions {
    getOptions {
      data {
        optionKey
        optionNames
      }
    }
  }
`

export const GET_TAGS = gql`
  query getTagList {
    getTagList {
      data
    }
  }
`

export const GOOD_RECEIVES = gql`
  query goodsReceiveList(
    $limit: Int
    $offset: Int
    $search: String
    $status: GoodsReceiveStatus
    $createdDate: [Date]
    $dueDate: [Date]
  ) {
    goodsReceiveList(
      input: {
        status: $status
        createdDate: $createdDate
        dueDate: $dueDate
        limit: $limit
        offset: $offset
        search: $search
      }
    ) {
      success
      message
      data {
        total
        list {
          id
          number
          status
          paymentDueDate
          supplier {
            id
            name
          }
        }
      }
    }
  }
`

export const GET_ITEM_SOURCES = gql`
  query possibleItemSource(
    $packId: String!
    $quantity: Float!
    $warehouseId: Int!
  ) {
    possibleItemSource(
      input: { packId: $packId, quantity: $quantity, warehouseId: $warehouseId }
    ) {
      data
    }
  }
`

export const GET_ITEMS = gql`
  query variantList($limit: Int, $offset: Int, $search: String) {
    variantList(input: { limit: $limit, offset: $offset, search: $search }) {
      data {
        id
        type
        variantName
      }
    }
  }
`

export const VARIANT_INFO = gql`
  ${VARIANT_FIELDS}
  query variantInfo(
    $id: String
    $sellerId: Int
  ) {
    variantInfo(
      id: $id
      sellerId: $sellerId
    ) {
      success
      message
      data {
        ...VariantFields
      }
    }
  }
`

export const GET_ITEMS_FOR_SET = gql`
  query variantListOnlyPack($limit: Int, $offset: Int, $search: String) {
    variantListOnlyPack(
      input: { limit: $limit, offset: $offset, search: $search }
    ) {
      data {
        id
        type
        name
        variantName
        quantity
        itemPricingTypes {
          id
          salesPrice
          pricingType {
            id
            name
          }
        }
        productSetItems {
          id
          quantity
          Pack {
            id
            variantName
          }
        }
        inventoryOption {
          id
          totalStock
          soldStock
          toBeStocked
          buyPrice
          avgCost
          inventory {
            id
            supplier {
              id
              name
            }
          }
        }
      }
    }
  }
`

export const NEXT_GR_NUMBER = gql`
  query nextGRNumber {
    nextGRNumber
  }
`

export const GET_NEXT_PO_NUMBER = gql`
  query nextPONumber {
    nextPONumber
  }
`

export const GET_NEXT_SO_NUMBER = gql`
  query nextSONumber($chatProviderId: Int!, $createdAt: Date!) {
    nextSONumber(chatProviderId: $chatProviderId, createdAt: $createdAt)
  }
`

export const GET_NEXT_PT_NUMBER = gql`
  query nextPTNumber {
    nextPTNumber
  }
`

export const NEXT_CN_NUMBER = gql`
  query nextCNNumber {
    nextCNNumber
  }
`

export const NEXT_DN_NUMBER = gql`
  query nextDNNumber {
    nextDNNumber
  }
`

export const GOOD_RECEIVE_INFO = gql`
  query goodsReceiveInfo($id: Int!) {
    goodsReceiveInfo(id: $id) {
      success
      message
      data {
        id
        number
        refId
        createdAt
        goodsReceiveFiles {
          id
          fileName
          url
        }
        status
        remarks
        paymentDueDate
        subtotal
        discountAmount
        withholdingTax
        withholdingTaxAmount
        subtotalAfterTax
        supplier {
          id
          name
        }
        warehouse {
          id
          warehouseName
        }
        orderItemGR {
          id
          price
          isExtra
          quantity
          vatInclude
          discount
          replace {
            id
            totalQuantity
            status
            quantity
            receivedQuantity
            buyPrice
            pack {
              id
              name
              inventoryOption {
                id
                variantName
                inventory {
                  id
                  sku
                  type
                  productName
                  brand {
                    id
                    name
                  }
                }
              }
            }
            purchaseOrder {
              id
              number
              warehouse {
                id
                warehouseName
              }
            }
            lot {
              id
              salesOrder {
                id
                soNumber
              }
            }
          }
          orderItem {
            id
            totalQuantity
            status
            quantity
            discount
            receivedQuantity
            buyPrice
            type
            variantName
            productSet {
              id
              name
              sku
            }
            pack {
              id
              name
              variantName
              inventoryOption {
                id
                variantName
                inventory {
                  id
                  sku
                  type
                  productName
                  brand {
                    id
                    name
                  }
                }
              }
            }
            purchaseOrder {
              id
              number
              warehouse {
                id
                warehouseCode
                warehouseName
              }
            }
            lot {
              id
              salesOrder {
                id
                soNumber
              }
            }
          }
        }
        createdAt
      }
    }
  }
`

export const SALES_ORDER_BY_STATUS = gql`
  query getPreparingSOByCustomer(
    $limit: Int
    $offset: Int
    $status: PrepareSalesOrderStatus!
    $search: String
  ) {
    getPreparingSOByCustomer(
      input: {
        limit: $limit
        offset: $offset
        status: $status
        search: $search
      }
    ) {
      success
      message
      data {
        total
        list {
          id
          soNumber
          status
          dueDate
          type
          deliveryChannel {
            id
            name
            trackingNumber
          }
          lots {
            id
            lotIndex
            deliveryDate
            deliveryType
            deliveryAddress {
              id
              addressNo
              province
              district
              subDistrict
              postcode
              contactName
              contactPhoneNumber
              thirdPartyAddress
            }
            items {
              id
              status
              quantity
              expectedReceivedDate
              overdueDate
              receivedQuantity
              isSerialTracking
              warehouse {
                id
                warehouseName
              }
              type
              replaceTo
              replacedBy
              childOrderItems {
                id
                status
                quantity
                expectedReceivedDate
                overdueDate
                receivedQuantity
                isSerialTracking
                warehouse {
                  id
                  warehouseName
                }
                type
                replaceTo
                replacedBy
                productSet {
                  id
                  name
                  sku
                  availableStart
                  availableEnd
                  useDate
                  subtotal
                  subtotalAfterDiscount
                  discount
                  remark
                  files {
                    id
                    fileName
                    url
                  }
                  ProductSetItems {
                    id
                    quantity
                    Pack {
                      id
                      variantName
                      quantity
                      inventoryOption {
                        id
                        variantName
                        inventory {
                          id
                          sku
                          brand {
                            id
                            name
                          }
                        }
                      }
                    }
                  }
                }
                pack {
                  id
                  name
                  inventoryOption {
                    id
                    variantName
                    files {
                      id
                      fileName
                      url
                    }
                    inventory {
                      id
                      sku
                      type
                      barcode
                      productName
                      brand {
                        id
                        name
                      }
                    }
                  }
                }
              }
              productSet {
                id
                name
                sku
                availableStart
                availableEnd
                useDate
                subtotal
                subtotalAfterDiscount
                discount
                remark
                files {
                  id
                  fileName
                  url
                }
                ProductSetItems {
                  id
                  quantity
                  Pack {
                    id
                    variantName
                    quantity
                    inventoryOption {
                      id
                      variantName
                      inventory {
                        id
                        sku
                        brand {
                          id
                          name
                        }
                      }
                    }
                  }
                }
              }
              pack {
                id
                name
                inventoryOption {
                  id
                  variantName
                  files {
                    id
                    fileName
                    url
                  }
                  inventory {
                    id
                    sku
                    type
                    barcode
                    productName
                    brand {
                      id
                      name
                    }
                  }
                }
              }
            }
            createdAt
            updatedAt
          }
          customer {
            id
            type
            name
            paymentType
            contactName
            contactPhoneNumber {
              id
              name
              phoneNumber
              isPrimary
            }
            contactFaxNumber
            shippingAddress {
              id
              supplierId
              customerId
              type
              addressNo
              province
              district
              subDistrict
              postcode
              contactName
              contactOfficeTel
              contactPhoneNumber
              contactFaxNumber
              contactEmail
              contactRemark
              thirdPartyAddress
            }
            contactAddress {
              id
              isPrimary
              contactName
              contactOfficeTel
              contactPhoneNumber
            }
          }
          createdAt
          updatedAt
        }
      }
    }
  }
`

export const ITEM_PO_LIST_BY_WAREHOUSE = gql`
  query itemListInPurchaseOrderByWarehouse($lotId: Int!, $warehouseId: Int!) {
    itemListInPurchaseOrderByWarehouse(
      input: { lotId: $lotId, warehouseId: $warehouseId }
    ) {
      success
      message
      data {
        id
        quantity
        status
        isSerialTracking
        type
        replaceTo
        replacedBy
        childOrderItems {
          id
          variantName
          replaceTo
          replacedBy
          quantity
          pack {
            id
            name
            variantName
            quantity
            inventoryOption {
              id
              totalStock
              toBeStocked
              variantName
              inventory {
                id
                sku
                brand {
                  id
                  name
                }
              }
            }
          }
        }
        productSet {
          id
          name
          sku
          availableStart
          availableEnd
          useDate
          subtotal
          subtotalAfterDiscount
          discount
          remark
          files {
            id
            fileName
            url
          }
          ProductSetItems {
            id
            Pack {
              id
              variantName
              quantity
              inventoryOption {
                id
                totalStock
                toBeStocked
              }
            }
          }
        }
        pack {
          id
          inventoryOption {
            id
            buyPrice
            isVat
            vat
            initialStock
            initialCost
            minimumStock
            variantName
            totalStock
            soldStock
            toBeStocked
            preordered
            inventory {
              id
              sku
              type
              productName
              brand {
                id
                name
              }
            }
          }
        }
      }
    }
  }
`

export const PICKING_TICKETS = gql`
  query pickingTicketList(
    $limit: Int
    $offset: Int
    $chatProviderId: Int
    $search: String
    $status: PickingTicketStatus
    $createdDate: [Date]
    $orderField: PickingTicketOrderField
    $orderBy: OrderBy
  ) {
    pickingTicketList(
      input: {
        limit: $limit
        offset: $offset
        search: $search
        chatProviderId: $chatProviderId
        status: $status
        createdDate: $createdDate
        orderField: $orderField
        orderBy: $orderBy
      }
    ) {
      success
      message
      data {
        total
        list {
          id
          number
          status
          deliveryDate
          deliveryType
          salesOrderType
          isSerialed
          customer {
            id
            type
            name
            contactName
            contactPhoneNumber {
              id
              name
              phoneNumber
              isPrimary
            }
            contactFaxNumber
          }
        }
      }
    }
  }
`

export const PT_INFO = gql`
  query pickingTicketDetail($id: Int!) {
    pickingTicketDetail(id: $id) {
      success
      message
      data {
        id
        number
        remark
        pickingTicketBoxes {
          box {
            id
            name
            price
          }
          quantity
          price
        }
        taxInvoices {
          id
          ivNumber
          invoice {
            id
            number
          }
          receipt {
            id
            number
          }
        }
        warehouse {
          id
          warehouseCode
          warehouseName
        }
        status
        files {
          id
          fileName
          url
          createdAt
          updatedAt
        }
        deliveryChannels {
          id
          name
          trackingNumber
        }
        lot {
          id
          deliveryDate
          lotIndex
          items {
            id
            status
            buyPrice
            salesPrice
            quantity
            expectedReceivedDate
            overdueDate
            receivedQuantity
            isVat
            discount
            vat
            isSerialTracking
            replaceTo
            replacedBy
            childOrderItems {
              id
              variantName
              replaceTo
              replacedBy
              quantity
              pack {
                id
                name
                variantName
                quantity
                inventoryOption {
                  id
                  isVat
                  vat
                }
              }
            }
            serialNumbers {
              id
              serial
            }
            type
            productSet {
              id
              name
              sku
              availableStart
              availableEnd
              useDate
              subtotal
              subtotalAfterDiscount
              discount
              remark
              # file {
              #   id
              #   fileName
              #   url
              # }
              ProductSetItems {
                id
                Pack {
                  id
                  variantName
                  quantity
                }
              }
            }
            pack {
              id
              name
              inventoryOption {
                id
                buyPrice
                isVat
                vat
                initialStock
                initialCost
                minimumStock
                variantName
                totalStock
                soldStock
                toBeStocked
                preordered
                inventory {
                  id
                  sku
                  type
                  productName
                  brand {
                    id
                    name
                  }
                }
                # url
              }
            }
            purchaseOrder {
              id
              number
              status
            }
            lotIndex
            createdAt
            updatedAt
          }
          salesOrder {
            id
            soNumber
            type
            salesOrderType
            customer {
              id
              type
              name
              contactName
              contactPhoneNumber {
                id
                name
                phoneNumber
                isPrimary
              }
              contactFaxNumber
            }
            contactAddress {
              id
              supplierId
              contactName
              contactOfficeTel
              contactPhoneNumber
            }
          }
          deliveryCost
          deliveryAddress {
            id
            type
            addressNo
            province
            district
            subDistrict
            postcode
            contactName
            contactPhoneNumber
            thirdPartyAddress
          }
          subtotal
          discountAmount
          vatAmount
          subtotalBeforeVat
          subtotalWithDelivery
          subtotalAfterDiscount
          deliveryType
          discount
          lotIndex
          createdAt
          updatedAt
          deliveryDetails {
            id
            name
            trackingNumber
          }
        }
        createdAt
        updatedAt
      }
    }
  }
`

export const GET_PT_STATUS = gql`
  query getPickingTicketStatus {
    getPickingTicketStatus {
      success
      message
      data
    }
  }
`

export const BILLING_INFO = gql`
  query billingDetail($id: Int!) {
    billingDetail(id: $id) {
      success
      message
      data {
        id
        remark
        status
        createDate
        paymentDueDate
        subtotal
        subtotalAfterDiscount
        createdAt
        updatedAt
        customer {
          id
          type
          prefix
          name
        }
        debtHistories {
          id
          type
          number
          status
          totalPriceBefore
          totalPriceAfter
        }
        invoices {
          id
          number
          createDate
          paymentDueDate
          status
          remark
          lot {
            id
            salesOrder {
              id
              soNumber
              customer {
                id
                prefix
                name
              }
              type
            }
            pickingTicket {
              id
              number
            }
            subtotalAfterDiscount
          }
          files {
            id
            fileName
            url
            createdAt
            updatedAt
          }
          subtotal
          subtotalAfterDiscount
          createdAt
          updatedAt
        }
        files {
          id
          fileName
          url
          createdAt
          updatedAt
        }
      }
    }
  }
`

export const GET_BILLING_NOTES = gql`
  query billingNoteList(
    $limit: Int
    $offset: Int
    $search: String
    $orderField: BillingNoteOrderField
    $orderBy: OrderBy
    $status: BillingNoteStatus
  ) {
    billingNoteList(
      input: {
        limit: $limit
        offset: $offset
        search: $search
        orderField: $orderField
        orderBy: $orderBy
        status: $status
      }
    ) {
      data {
        list {
          id
          number
          status
          subtotalAfterDiscount
          customer {
            id
            type
            prefix
            name
          }
        }
        total
      }
    }
  }
`

export const GET_BILLABLE_SO = gql`
  query getBillableLot($search: String, $customerId: Int) {
    getBillableLot(input: { search: $search, customerId: $customerId }) {
      success
      message
      data {
        id
        prefix
        name
        number
        contactName
        contactPhoneNumber {
          id
          name
          phoneNumber
          isPrimary
        }
        contactFaxNumber
        salesOrders {
          id
          soNumber
          status
          lots {
            id
            items {
              id
            }
            invoice {
              id
              number
              createDate
              paymentDueDate
            }
            lotIndex
            subtotal
            subtotalAfterDiscount
            deliveryCost
            discount
            pickingTicket {
              id
              number
            }
          }
          contactAddress {
            id
            contactName
            contactPhoneNumber
            contactOfficeTel
          }
          customer {
            id
            paymentType
            contactName
            contactPhoneNumber {
              id
              name
              phoneNumber
              isPrimary
            }
            contactFaxNumber
            creditTerm
            # pricingType
            prefix
            # type
            name
          }
        }
      }
    }
  }
`

// export const GET_BILLABLE_SO = gql`
//   query getBillableLot (
//     $search: String
//     $customerId: Int
//   ) {
//     getBillableLot (
//       input: {
//         search: $search
//         customerId: $customerId
//       }
//     ) {
//       success
//       message
//       data {
//     }
//   }
// `

export const GET_BILL = gql`
  query billingDetail($id: Int!) {
    billingDetail(id: $id) {
      success
      message
      data {
        id
        number
        remark
        status
        createDate
        paymentDueDate
        subtotal
        subtotalAfterDiscount
        debtHistories {
          id
          type
          number
          totalPriceBefore
          totalPriceAfter
        }
        invoices {
          id
          number
          subtotal
          subtotalAfterDiscount
          lot {
            id
            salesOrder {
              id
              soNumber
              customer {
                id
                name
                prefix
                paymentType
                contactFaxNumber
                contactName
                contactPhoneNumber {
                  id
                  name
                  phoneNumber
                  isPrimary
                }
                creditTerm
              }
            }
            pickingTicket {
              id
              number
            }
          }
        }
        files {
          id
          fileName
          url
        }
        createdAt
        updatedAt
      }
    }
  }
`

export const GET_RECEIPT = gql`
  query receiptInfo($id: Int!) {
    receiptInfo(id: $id) {
      success
      message
      data {
        id
        number
        remark
        paidDate
        paymentType
        paidTime
        reference
        paymentMethod
        chequeDate
        chequeNumber
        creditCard
        referenceNO
        fee
        subtotal
        deliveryCost
        discountAmount
        subtotalWithDelivery
        subtotalAfterDiscount
        taxInvoice {
          id
          taxInvoiceType
        }
        finnicBank {
          id
          bankAccountType
          bankAccountName
          bankAccountNumber
          bank {
            id
            name
          }
          status
        }
        files {
          id
          fileName
          url
        }
        customer {
          id
          name
          prefix
          paymentType
          contactFaxNumber
          contactName
          contactPhoneNumber {
            id
            name
            phoneNumber
            isPrimary
          }
        }
        lots {
          id
          deliveryType
          billingAddress {
            id
            addressNo
            subDistrict
            contactName
            district
            taxId
            province
            postcode
            contactPhoneNumber
          }
          items {
            id
            status
            avgCost
            quantity
            salesPrice
            discount
            requireTaxInvoice
            variantName
            type
            replaceTo
            replacedBy
            seller {
              id
              lastName
              firstName
            }
            productSet {
              id
              name
            }
            pack {
              id
              name
              inventoryOption {
                id
                buyPrice
                # salesPrice
                variantName
                inventory {
                  id
                  type
                  productName
                  sku
                  barcode
                  brand {
                    id
                    name
                  }
                }
              }
            }
            childOrderItems {
              id
              status
              avgCost
              quantity
              salesPrice
              discount
              requireTaxInvoice
              variantName
              type
              replaceTo
              replacedBy
              seller {
                id
                lastName
                firstName
              }
              productSet {
                id
                name
              }
              pack {
                id
                name
                inventoryOption {
                  id
                  buyPrice
                  # salesPrice
                  variantName
                  inventory {
                    id
                    type
                    productName
                    sku
                    barcode
                    brand {
                      id
                      name
                    }
                  }
                }
              }
            }
          }
          pickingTicket {
            id
            number
          }
          salesOrder {
            id
            soNumber
            type
            seller {
              id
              firstName
              lastName
            }
            creator {
              id
              firstName
              lastName
            }
          }
          deliveryCost
          subtotal
          subtotalAfterDiscount
          invoice {
            id
            number
            subtotal
            subtotalAfterDiscount
            billingNote {
              id
              number
            }
          }
        }
        debtItemList {
          id
          number
          status
          type
          totalPriceBefore
          totalPriceAfter
          remarks
          itemChanges {
            id
            quantity
            orderItem {
              id
              quantity
            }
          }
          createdAt
        }
        createdAt
        updatedAt
      }
    }
  }
`

export const GET_LOT_INVOICEABLE = gql`
  query getLotInvoiceable($search: String) {
    getLotInvoiceable(input: { search: $search }) {
      success
      message
      data {
        id
        soNumber
        status
        dueDate
        type
        remarks
        subtotal
        discountAmount
        vatAmount
        subtotalBeforeVat
        subtotalWithDelivery
        deliveryCost
        subtotalAfterDiscount
        lots {
          id
          deliveryDate
          items {
            id
            status
            quantity
            salesPrice
            discount
            requireTaxInvoice
            type
            productSet {
              id
              name
              sku
            }
            pack {
              id
              name
              inventoryOption {
                id
                buyPrice
                variantName
                inventory {
                  id
                  type
                  productName
                  brand {
                    id
                    name
                  }
                }
              }
            }
          }
          subtotal
          deliveryCost
          discountAmount
          subtotalAfterDiscount
          discount
          lotIndex
          pickingTicket {
            id
            number
          }
        }
        contactAddress {
          id
          contactName
          contactOfficeTel
          contactPhoneNumber
        }
        customer {
          id
          name
          paymentType
          creditLimit
          contactName
          creditTerm
          contactPhoneNumber {
            id
            name
            phoneNumber
            isPrimary
          }
        }
        createdAt
        updatedAt
      }
    }
  }
`

export const INVOICES = gql`
  query invoiceList(
    $limit: Int
    $offset: Int
    $search: String
    $orderField: InvoiceOrderField
    $orderBy: OrderBy
    $status: InvoiceStatus
  ) {
    invoiceList(
      input: {
        limit: $limit
        offset: $offset
        search: $search
        orderField: $orderField
        orderBy: $orderBy
        status: $status
      }
    ) {
      success
      message
      data {
        total
        list {
          id
          number
          status
          customer {
            id
            paymentType
            type
            name
          }
          paymentType
          deliveryType
          deliveryCost
          debtHistories {
            id
            type
            number
            totalPriceBefore
            totalPriceAfter
            remarks
            createDate
            createdAt
            files {
              id
              fileName
              url
            }
            itemChanges {
              id
              quantity
              price
              remarks
              orderItem {
                id
                salesPrice
                quantity
                type
                productSet {
                  id
                  name
                  sku
                }
                pack {
                  id
                  name
                }
                variantName
              }
            }
          }
          subtotalAfterDiscount
        }
      }
    }
  }
`

export const INVOICE_STATUS_LIST = gql`
  query getInvoiceStatusList {
    getInvoiceStatusList {
      success
      message
      data
    }
  }
`

export const INVOICE_DETAIL = gql`
  query invoiceDetail($id: Int!) {
    invoiceDetail(id: $id) {
      success
      message
      data {
        id
        number
        createDate
        paymentDueDate
        status
        remark
        subtotal
        taxInvoice {
          taxInvoiceType
        }
        lot {
          id
          deliveryDate
          deliveryType
          billingAddress {
            id
            addressNo
            subDistrict
            contactName
            district
            taxId
            province
            postcode
            contactPhoneNumber
          }
          salesOrder {
            id
            type
            soNumber
            customer {
              id
              paymentType
              type
              name
              contactName
              contactPhoneNumber {
                id
                name
                phoneNumber
                isPrimary
              }
              creditLimit
              creditTerm
              contactFaxNumber
            }
            creator {
              id
              firstName
              lastName
            }
          }
          pickingTicket {
            id
            number
          }
          items {
            id
            status
            quantity
            salesPrice
            discount
            requireTaxInvoice
            type
            productSet {
              id
              name
              sku
            }
            pack {
              id
              name
              inventoryOption {
                id
                buyPrice
                # salesPrice
                variantName
                inventory {
                  id
                  type
                  sku
                  productName
                  brand {
                    id
                    name
                  }
                }
              }
            }
            replaceTo
            replacedBy
            childOrderItems {
              id
              variantName
              replaceTo
              replacedBy
              quantity
              pack {
                id
                name
                variantName
                quantity
                inventoryOption {
                  id
                  isVat
                  vat
                }
              }
            }
          }
          subtotal
          deliveryCost
          subtotalAfterDiscount
          discount
          discountAmount
          lotIndex
          pickingTicket {
            id
            number
          }
        }
        files {
          id
          fileName
          url
          createdAt
          updatedAt
        }
        subtotal
        subtotalAfterDiscount
        createdAt
        updatedAt
        customer {
          id
          name
        }
        debtHistories {
          id
          type
          number
          totalPriceBefore
          totalPriceAfter
          remarks
          createDate
          createdAt
          files {
            id
            fileName
            url
          }
          itemChanges {
            id
            quantity
            price
            remarks
            orderItem {
              id
              salesPrice
              quantity
              type
              productSet {
                id
                name
                sku
              }
              pack {
                id
                name
              }
              variantName
            }
          }
        }
      }
    }
  }
`

export const GOOD_RECEIVE_FOR_PAYMENT_NOTE = gql`
  query goodsReceiveListForPayment(
    $limit: Int
    $offset: Int
    $search: String
    $supplierId: Int
  ) {
    goodsReceiveListForPayment(
      input: {
        limit: $limit
        offset: $offset
        search: $search
        supplierId: $supplierId
      }
    ) {
      success
      message
      data {
        total
        list {
          id
          number
          refId
          subtotal
          status
          supplier {
            id
            name
            paymentType
            contactName
            contactPhoneNumber {
              id
              name
              phoneNumber
              isPrimary
            }
          }
        }
      }
    }
  }
`

export const PAYMENT_NODE_DETAIL = gql`
  query paymentNoteDetail($id: Int!) {
    paymentNoteDetail(id: $id) {
      success
      message
      data {
        id
        number
        remark
        status
        createDate
        paymentDueDate
        subtotal
        reference
        paidDate
        paidTime
        paymentType
        bankFee
        fourDigit
        creditReference
        checkNumber
        checkDate
        supplierBank {
          id
          bank {
            id
            name
          }
        }
        goodsReceives {
          id
          number
          refId
          subtotal
          status
          supplier {
            id
            name
            paymentType
            contactName
            contactPhoneNumber {
              id
              name
              phoneNumber
              isPrimary
            }
            contactFaxNumber
            paymentInfo {
              id
              bank {
                id
                name
              }
              bankAccountNumber
            }
          }
        }
        files {
          id
          fileName
          url
        }
        createdAt
        updatedAt
      }
    }
  }
`

export const PAYMENT_NOTES = gql`
  query paymentNoteList(
    $limit: Int
    $offset: Int
    $search: String
    $orderField: PaymentNoteOrderField
    $orderBy: OrderBy
    $status: PaymentNoteStatus
  ) {
    paymentNoteList(
      input: {
        limit: $limit
        offset: $offset
        search: $search
        orderField: $orderField
        orderBy: $orderBy
        status: $status
      }
    ) {
      success
      message
      data {
        total
        list {
          id
          number
          remark
          status
          createDate
          paymentDueDate
          subtotal
          reference
          paidDate
          paidTime
          paymentType
          bankFee
          fourDigit
          creditReference
          checkNumber
          checkDate
          supplierBank {
            id
            bank {
              id
              name
            }
          }
          goodsReceives {
            id
            number
            refId
            status
            subtotal
            supplier {
              id
              name
              paymentType
              contactName
              contactPhoneNumber {
                id
                name
                phoneNumber
                isPrimary
              }
              paymentInfo {
                id
                bank {
                  id
                  name
                }
              }
            }
          }
          files {
            id
            fileName
            url
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
      }
    }
  }
`

export const PRICING_TYPES_LISTS = gql`
  query pricingTypeList {
    pricingTypeList {
      data {
        list {
          id
          code
          name
          price
          status
          createdAt
          updatedAt
        }
        total
      }
    }
  }
`

export const PAYMENT_NOTE_STATUS_LIST = gql`
  query paymentNoteStatusList {
    paymentNoteStatusList {
      success
      message
      data {
        status
        count
      }
    }
  }
`

export const GET_RECEIPTABLE = gql`
  query getReceiptableBillingNote($search: String, $customerId: Int) {
    getReceiptableBillingNote(
      input: { search: $search, customerId: $customerId }
    ) {
      success
      message
      data {
        id
        number
        remark
        status
        createDate
        paymentDueDate
        subtotal
        invoices {
          id
          number
          createDate
          paymentDueDate
          status
          remark
          lot {
            id
            salesOrder {
              id
              type
              soNumber
              contactAddress {
                id
                contactName
                contactPhoneNumber
                contactOfficeTel
              }
            }
            pickingTicket {
              id
              number
            }
          }
          subtotal
          subtotalAfterDiscount
          createdAt
          updatedAt
        }
        customer {
          id
          paymentType
          # pricingType
          prefix
          type
          name
          contactName
          contactFaxNumber
          contactPhoneNumber {
            id
            name
            phoneNumber
            isPrimary
          }
        }
        createdAt
        updatedAt
      }
    }
  }
`

export const FINNIC_BANKS = gql`
  query finnicBankList($limit: Int, $offset: Int) {
    finnicBankList(input: { limit: $limit, offset: $offset }) {
      success
      message
      data {
        id
        bankAccountType
        bankAccountName
        bankAccountNumber
        status
        bank {
          id
          code
          name
        }
      }
    }
  }
`

export const ALL_FINNIC_BANKS = gql`
  query allFinnicBankList($limit: Int, $offset: Int) {
    allFinnicBankList(input: { limit: $limit, offset: $offset }) {
      success
      message
      data {
        id
        bankAccountType
        bankAccountName
        bankAccountNumber
        status
        bank {
          id
          code
          name
        }
      }
    }
  }
`

export const GET_RECEIPTABLE_SO = gql`
  query getReceiptableSalesOrder($search: String, $customerId: Int) {
    getReceiptableSalesOrder(
      input: { search: $search, customerId: $customerId }
    ) {
      success
      message
      data {
        id
        type
        soNumber
        status
        contactAddress {
          id
          contactName
          contactPhoneNumber
          contactOfficeTel
        }
        customer {
          id
          name
          prefix
          paymentType
          contactName
          contactPhoneNumber {
            id
            name
            phoneNumber
            isPrimary
          }
          contactFaxNumber
        }
        subtotal
        discountAmount
        subtotalAfterDiscount
        subtotalWithDelivery
        paidTotal
        deliveryCost
        depositReceipt {
          id
          number
        }
        depositAmount
        paymentHistories {
          id
          type
          date
          time
          subtotal
          attachment
          remarks
          dateTime
          chequeDate
          chequeNumber
          creditCard
          referenceNO
          finnicBankId
          remark
          reference
          fee
          transactionId
          bankCode
          receiverEnName
          receiverThName
          bankAccount
          finnicBankCode
          finnicBankName
          finnicBankFullName
        }
        lots {
          id
          lotIndex
          subtotal
          discountAmount
          subtotalAfterDiscount
          subtotalWithDelivery
          deliveryCost
          items {
            id
            variantName
            quantity
            salesPrice
            discount
          }
          pickingTicket {
            id
            number
          }
        }
      }
    }
  }
`

export const GET_RECEIPTS = gql`
  query receiptList(
    $limit: Int
    $offset: Int
    $search: String
    $orderField: ReceiptOrderField
    $orderBy: OrderBy
    # $paymentType: ReceiptPaymentType!
  ) {
    receiptList(
      input: {
        limit: $limit
        offset: $offset
        search: $search
        orderField: $orderField
        orderBy: $orderBy
        # paymentType: $paymentType
      }
    ) {
      success
      message
      data {
        list {
          id
          number
          deliveryType
          deliveryCost
          paymentType
          isReturnSalesOrder
          subtotalAfterDiscount
          lots {
           id
           billingAddress {
             id
           }
          }
          debtHistories {
            id
            type
            number
            totalPriceBefore
            totalPriceAfter
            remarks
            createDate
            createdAt
            files {
              id
              fileName
              url
            }
            itemChanges {
              id
              quantity
              price
              remarks
              orderItem {
                id
                salesPrice
                quantity
                pack {
                  id
                  name
                }
                variantName
              }
            }
          }
          customer {
            id
            name
            prefix
          }
          createdAt
        }
        total
      }
    }
  }
`

export const GET_DEPOSIT_RECEIPTS = gql`
  query depositReceiptList(
    $limit: Int
    $offset: Int
    $search: String
    $orderField: DepositReceiptOrderField
    $orderBy: OrderBy
    $createdDate: [Date]
  ) {
    depositReceiptList(
      input: {
        limit: $limit
        offset: $offset
        search: $search
        orderField: $orderField
        orderBy: $orderBy
        createdDate: $createdDate
      }
    ) {
      success
      message
      data {
        list {
          id
          number
          subtotalAfterDiscount
          paidTotal
          status
          paymentType
          salesOrderId
          debtHistories {
            id
            type
            number
            status
            totalPriceBefore
            totalPriceAfter
            remarks
            createDate
            files {
              id
              fileName
              url
            }
          }
          customer {
            id
            name
            prefix
            contactFaxNumber
            contactName
            contactPhoneNumber {
              id
              name
              phoneNumber
              isPrimary
            }
          }
          createdAt
          updatedAt
        }
        total
        totalWaitingConfirm
      }
    }
  }
`

export const GET_DEPOSIT_RECEIPT = gql`
  query depositReceiptDetail($id: Int!) {
    depositReceiptDetail(id: $id) {
      success
      message
      data {
        id
        number
        salesOrder {
          id
          status
          type
          soNumber
          salesOrderType
          paymentHistories {
            id
            type
            dateTime
            subtotal
            attachment
            remarks
          }
          debtHistories {
            id
            totalPriceBefore
            totalPriceAfter
            remarks
            createdAt
            itemChanges {
              id
              quantity
              orderItem {
                id
                pack {
                  id
                  name
                  inventoryOption {
                    id
                    inventory {
                      id
                      sku
                      productName
                    }
                  }
                }
                variantName
              }
            }
          }
          lots {
            id
            deliveryDate
            deliveryType
            deliveryCost
            subtotal
            subtotalWithDelivery
            subtotalAfterDiscount
            discountAmount
            items {
              id
              status
              requireTaxInvoice
              serialNumbers {
                id
                serial
              }
              type
              productSet {
                id
                name
              }
              pack {
                id
                inventoryOption {
                  id
                  variantName
                }
              }
              quantity
              salesPrice
              discount
              itemSource
              expectedReceivedDate
              supplier {
                id
                name
              }
            }
            billingAddress {
              id
              addressNo
              subDistrict
              contactName
              district
              taxId
              province
              postcode
              contactPhoneNumber
            }
            deliveryAddress {
              id
              addressNo
              subDistrict
              district
              province
              postcode
              contactPhoneNumber
              thirdPartyAddress
            }
          }
          contactAddress {
            id
            isPrimary
            contactName
            contactOfficeTel
            contactPhoneNumber
          }
          customer {
            id
            name
            prefix
            contactFaxNumber
            contactName
            contactPhoneNumber {
              id
              name
              phoneNumber
              isPrimary
            }
          }
          subtotal
          paidTotal
          discountAmount
          deliveryCost
          subtotalAfterDiscount
        }
        paymentHistories {
          id
          subtotal
          type
          date
          time
          attachment
          remarks
          dateTime
          transactionId
          bankCode
          receiverThName
          receiverEnName
          finnicBankId
          finnicBankCode
          finnicBankName
          finnicBankFullName
          bankAccount
        }
        createdAt
        updatedAt
      }
    }
  }
`

export const HISTORY = gql`
  query historyLogByModuleName($moduleName: HistoryModuleName!, $refId: Int!) {
    historyLogByModuleName(input: { moduleName: $moduleName, refId: $refId }) {
      success
      message
      data {
        total
        list {
          id
          message
          createdAt
          actor {
            id
            firstName
            lastName
          }
        }
      }
    }
  }
`

export const REMAINING_JOB = gql`
  query remainingJob {
    remainingJob {
      data {
        po
        gr
        pt
        accountant
        tax
        unmatched
      }
    }
  }
`

export const GET_DEBT_FOR_OPEN_BILL = gql`
  query getBillableCreditNoteAndDebitNote($customerId: Int, $search: String) {
    getBillableCreditNoteAndDebitNote(
      input: { customerId: $customerId, search: $search }
    ) {
      success
      message
      data {
        id
        number
        status
        type
        totalPriceBefore
        totalPriceAfter
        remarks
        salesOrder {
          id
          soNumber
        }
        invoice {
          id
          number
        }
        itemChanges {
          id
          quantity
          orderItem {
            id
            quantity
            variantName
          }
        }
        customer {
          id
          paymentType
          name
          creditTerm
        }
        createdAt
      }
    }
  }
`

export const GET_PRICING_TYPES = gql`
  query getSupplierBrandPricingTypes {
    getSupplierBrandPricingTypes {
      data {
        id
        name
        supplierBrands {
          id
          name
          supplierBrandPricingTypes {
            id
            name
            value
            supplierBrandId
            pricingTypeId
          }
          type
          supplierDiscount
        }
      }
    }
  }
`

export const COST_CHANGE_LIST = gql`
  query CostChangeLogLists($inventoryOptionId: Int!) {
    CostChangeLogLists(input: { inventoryOptionId: $inventoryOptionId }) {
      data {
        total
        list {
          id
          inventoryOptionId
          oldCost
          currentCost
          differenceCost
          oldQuantity
          newQuantity
          differenceQuantity
          actionName
          createdAt
          reference
        }
      }
    }
  }
`

export const GET_DEBT_FOR_OPEN_RECEIPT = gql`
  query getReceiptableCreditNoteAndDebitNote(
    $customerId: Int
    $search: String
  ) {
    getReceiptableCreditNoteAndDebitNote(
      input: { customerId: $customerId, search: $search }
    ) {
      success
      message
      data {
        id
        number
        type
        totalPriceBefore
        totalPriceAfter
        remarks
        createDate
        billingNote {
          id
          number
        }
        invoice {
          id
          number
        }
        customer {
          id
          name
        }
      }
    }
  }
`

export const REMAINING_ACCOUNT_JOB = gql`
  query remainingAccountant {
    remainingAccountant {
      data {
        remainingDepositReceipt
        remainingInvoice
        remainingBillingNote
        remainingCashReceipt
        remainingCreditReceipt
        totalRemainingIncome
        remainingPaymentNote
        totalRemainingOutcome
      }
    }
  }
`

export const GET_INVENTORY_STRUCTURE = gql`
  query inventoryManagementList {
    inventoryManagementList {
      data {
        id
        name
        brands {
          id
          name
          productGroups {
            id
            name
            productCategories {
              id
              name
            }
          }
        }
      }
    }
  }
`

export const GET_BRANDS_SUPPLIER = gql`
  query getBrandsBySupplier($supplierIdList: [Int]!) {
    getBrandsBySupplier(supplierIdList: $supplierIdList) {
      data {
        id
        name
      }
    }
  }
`

export const GET_GROUPS_BRAND = gql`
  query getProductGroupBySupplierBrand(
    $supplierIdList: [Int]!
    $brandId: Int!
  ) {
    getProductGroupBySupplierBrand(
      supplierIdList: $supplierIdList
      brandId: $brandId
    ) {
      data {
        id
        name
      }
    }
  }
`

export const GET_CATEGORIES_GROUP = gql`
  query getProductCategoriesBySupplierBrandProductGroup(
    $supplierIdList: [Int]!
    $brandId: Int!
    $productGroupId: Int!
  ) {
    getProductCategoriesBySupplierBrandProductGroup(
      supplierIdList: $supplierIdList
      brandId: $brandId
      productGroupId: $productGroupId
    ) {
      data {
        id
        name
      }
    }
  }
`

export const PRODUCT_SET_LIST = gql`
  query ProductSetList(
    $limit: Int
    $offset: Int
    $search: String
    $status: FilterStatusTypeInProductSet
  ) {
    ProductSetList(
      input: {
        limit: $limit
        offset: $offset
        search: $search
        status: $status
      }
    ) {
      data {
        list {
          id
          name
          sku
          reference
          itemCount
          subtotal
        }
      }
    }
  }
`

export const GET_PRICE = gql`
  query getSuggestionPrice(
    $supplierId: Int
    $pricingTypeId: Int!
    $packId: String!
  ) {
    getSuggestionPrice(
      input: {
        supplierId: $supplierId
        pricingTypeId: $pricingTypeId
        packId: $packId
      }
    ) {
      data {
        type
        salesPrice
      }
      success
      message
    }
  }
`

export const PRODUCT_SET_INFO = gql`
  query ProductSetInfo($id: Int!) {
    ProductSetInfo(id: $id) {
      data {
        id
        name
        sku
        reference
        availableStart
        availableEnd
        useDate
        subtotal
        subtotalAfterDiscount
        buyPrice
        discount
        remark
        files {
          id
          fileName
          url
        }
        ProductSetPricingTypes {
          id
          salesPrice
          pricingType {
            id
            name
          }
        }
        ProductSetItems {
          id
          quantity
          salesPrice
          Pack {
            id
            name
            variantName
            quantity
            middlePrice
            inventoryOption {
              id
              buyPrice
              avgCost
            }
          }
        }
      }
    }
  }
`

export const GET_ROLES = gql`
  query rolePermissionList {
    rolePermissionList {
      data {
        role {
          id
          name
          description
        }
        moduleList {
          moduleName
          # description
          permissionList {
            id
            name
            description
            status
          }
        }
      }
    }
  }
`

export const GET_PERMISSIONS = gql`
  query myRole {
    myRole {
      data {
        id
        name
        description
        permissions {
          salesOrder {
            view
            viewHistoryLog
            create
            createWithDiscount
            edit
            confirmCredit
            recordCashPayment
            createUpdatePOS
            showCost
            upsell
          }
          purchaseOrder {
            view
            create
            edit
            supplierConfirm
            viewHistoryLog
          }
          goodsReceive {
            view
            create
            edit
            viewHistoryLog
            createDraft
            confirmDraft
            confirmGR
          }
          pickingTicket {
            view
            create
            viewHistoryLog
            confirmReceive
            addTrackingNumber
          }
          inventory {
            view
            viewStockLog
            viewCostChangeLog
            viewHistoryLog
            createBasic
            createAdvance
            adjustStock
            edit
            transfer
            pairProduct
          }
          client {
            view
            viewHistoryLog
            create
            createCreditCustomer
            createPricingTier
            editBasic
            editAdvance
          }
          supplier {
            view
            create
            viewHistoryLog
            editBasic
            editAdvance
          }
          productStructure {
            view
            create
            edit
          }
          productSet {
            view
            viewHistoryLog
            create
            edit
            showCost
          }
          depositReceipt {
            view
            printDR
            confirmCashPayment
          }
          invoice {
            view
            CNDN
            viewHistoryLog
            printInvoice
            edit
            create
            printCNDN
          }
          bill {
            view
            viewHistoryLog
            printBill
            create
            edit
          }
          cashReceipt {
            view
            viewHistoryLog
            printCashReceipt
            CNDN
            printCNDN
            create
          }
          creditReceipt {
            view
            viewHistoryLog
            printCreditReceipt
            create
          }
          paymentNote {
            view
            create
            edit
          }
          warehouse {
            view
            create
            edit
          }
          user {
            view
            viewHistoryLog
            create
            edit
          }
          permission {
            view
            edit
          }
          setting {
            all
          }
          report {
            all
          }
          commission {
            all
          }
          taxInvoice {
            all
            editIVNumber
          }
          tag {
            all
          }
          facebook {
            all
          }
          unMatchedSalesOrder {
            all
          }
        }
      }
    }
  }
`

export const SO_STATUS_LIST = gql`
  query salesOrderStatusList {
    salesOrderStatusList {
      success
      message
      data {
        count
        status
      }
    }
  }
`

export const PO_STATUS_LIST = gql`
  query purchaseOrderStatusList {
    purchaseOrderStatusList {
      success
      message
      data {
        count
        status
      }
    }
  }
`

export const GR_STATUS_LIST = gql`
  query goodsReceiveStatusList {
    goodsReceiveStatusList {
      success
      message
      data {
        count
        status
      }
    }
  }
`

export const INVOICE_STATUS_COUNT_LIST = gql`
  query invoiceStatusList {
    invoiceStatusList {
      success
      message
      data {
        count
        status
      }
    }
  }
`

export const BILLING_STATUS_LIST = gql`
  query billingNoteStatusList {
    billingNoteStatusList {
      success
      message
      data {
        count
        status
      }
    }
  }
`

export const CHECK_REPEAT_PAYMENT = gql`
  query checkRepeatPayment(
    $date: Date!
    $time: Time
    $subtotal: Float!
    $paymentMethod: ReceiptPaymentMethod!
  ) {
    checkRepeatPayment(
      input: {
        date: $date
        time: $time
        subtotal: $subtotal
        paymentMethod: $paymentMethod
      }
    ) {
      success
      message
    }
  }
`

export const QUOTATION_LIST = gql`
  query quotationList {
    quotationList {
      success
      message
      data {
        total
        list {
          id
          refId
        }
      }
    }
  }
`

export const QUOTATION_INFO = gql`
  query quotationInfo($id: Int) {
    quotationInfo(id: $id) {
      success
      message
      data {
        id
        paymentType
        customerId
        customer {
          id
          name
          paymentType
          type
          pricingType {
            id
            code
            name
            status
          }
          creditLimit
          creditUsage
          contactAddress {
            id
            addressNo
            province
            district
            subDistrict
            postcode
            contactName
            contactOfficeTel
            contactPhoneNumber
            contactRemark
            isPrimary
            shopName
            position
            taxId
          }
          shippingAddress {
            id
            addressNo
            province
            district
            subDistrict
            postcode
            contactName
            contactOfficeTel
            contactPhoneNumber
            contactRemark
            isPrimary
            shopName
            position
            taxId
            thirdPartyAddress
          }
        }
        contactAddress {
          id
          supplierId
          customerId
          type
          addressNo
          province
          district
          subDistrict
          postcode
          contactName
          contactOfficeTel
          contactPhoneNumber
          contactLineId
          contactFacebook
          contactFaxNumber
          contactEmail
          contactRemark
          isPrimary
          shopName
          position
          taxId
        }
        quotationLots {
          id
          isTax
          deliveryCost
          deliveryDate
          receiveType
          billingAddressId
          deliveryAddressId
          lotBillingAddress {
            id
            supplierId
            customerId
            type
            addressNo
            province
            district
            subDistrict
            postcode
            contactName
            contactOfficeTel
            contactPhoneNumber
            contactLineId
            contactFacebook
            contactFaxNumber
            contactEmail
            contactRemark
            isPrimary
            shopName
            position
            taxId
          }
          lotDeliveryAddress {
            id
            supplierId
            customerId
            type
            addressNo
            province
            district
            subDistrict
            postcode
            contactName
            contactOfficeTel
            contactPhoneNumber
            contactLineId
            contactFacebook
            contactFaxNumber
            contactEmail
            contactRemark
            isPrimary
            shopName
            position
            taxId
            thirdPartyAddress
          }
          quotationItems {
            id
            quantity
            discount
            price
            totalPrice
            packId
            setId
            pack {
              id
              name
              variantName
              inventoryOption {
                id
                totalStock
                soldStock
                toBeStocked
                stockLocation {
                  id
                  preordered
                  totalStock
                  soldStock
                  toBeStocked
                  warehouse {
                    id
                  }
                }
                inventory {
                  id
                  supplier {
                    id
                    name
                  }
                }
              }
            }
            set {
              id
              sku
              name
              suppliers {
                id
                name
              }
              inventoryOption {
                id
                totalStock
                soldStock
                toBeStocked
                stockLocation {
                  id
                  preordered
                  totalStock
                  soldStock
                  toBeStocked
                  warehouse {
                    id
                  }
                }
                inventory {
                  id
                  supplier {
                    id
                    name
                  }
                }
              }
              ProductSetItems {
                id
                Pack {
                  id
                  inventoryOption {
                    id
                    totalStock
                    soldStock
                    toBeStocked
                    inventory {
                      id
                      supplier {
                        id
                        name
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

export const GET_SO_TYPE = gql`
  query salesOrderTypeList {
    salesOrderTypeList {
      success
      message
      data
    }
  }
`

export const GET_DELIVERY_CHANNEL = gql`
  query deliveryChannelList {
    deliveryChannelList {
      data {
        id
        deliveryName
      }
      success
      message
    }
  }
`

export const GET_TAX_INVOICE_DELIVERY_CHANNEL = gql`
  query taxInvoiceDeliveryChannelList {
    taxInvoiceDeliveryChannelList {
      data {
        id
        name
      }
      success
      message
    }
  }
`

export const BANK_LIST = gql`
  query bankList {
    bankList {
      success
      message
      data {
        total
        list {
          id
          code
          name
          bankCode
        }
      }
    }
  }
`

export const TAX_INVOICE_STATUS_COUNT_LIST = gql`
  query taxInvoiceStatusList {
    taxInvoiceStatusList {
      data {
        statusName
        count
      }
      message
      success
    }
  }
`

export const TAX_INVOICES = gql`
  query taxInvoiceList(
    $orderField: InvoiceOrderField
    $orderBy: OrderBy
    $search: String
    $status: TaxInvoiceStatusEnum
    $limit: Int
    $offset: Int
  ) {
    taxInvoiceList(
      input: {
        orderField: $orderField
        orderBy: $orderBy
        search: $search
        status: $status
        limit: $limit
        offset: $offset
      }
    ) {
      success
      message
      data {
        total
        list {
          id
          number
          status
          invoiceId
          receiptId
        }
      }
    }
  }
`

export const TAX_INVOICE = gql`
  query taxInvoiceInfo($id: Int!) {
    taxInvoiceInfo(id: $id) {
      success
      message
      data {
        id
        ivNumber
        status
        taxInvoiceType
        trackingNumber
        providerName
        pickingTicket {
          id
          number
        }
        sales {
          id
          firstName
          lastName
        }
        invoice {
          id
          number
          createDate
          paymentDueDate
          status
          remark
          subtotal
          subtotalAfterDiscount
          files {
            id
            fileName
            url
          }
          customer {
            id
            name
            prefix
            paymentType
            contactFaxNumber
            contactName
            contactPhoneNumber {
              id
              name
              phoneNumber
              isPrimary
            }
          }
          lot {
            id
            lotIndex
            deliveryType
            deliveryCost
            subtotal
            subtotalAfterDiscount
            discountAmount
            subtotalWithDelivery
            discountAmount
            subtotalAfterDiscount
            pickingTicket {
              id
              number
            }
            salesOrder {
              id
              soNumber
              creator {
                id
                firstName
                lastName
              }
            }
            billingAddress {
              id
              addressNo
              subDistrict
              contactName
              district
              taxId
              province
              postcode
              contactPhoneNumber
            }
            items {
              id
              status
              quantity
              salesPrice
              discount
              requireTaxInvoice
              variantName
              type
              replaceTo
              replacedBy
              productSet {
                id
                name
              }
              pack {
                id
                name
                inventoryOption {
                  id
                  buyPrice
                  # salesPrice
                  variantName
                  inventory {
                    id
                    type
                    productName
                  }
                }
              }
              goodsReceiveList {
                id
                number
                refId
              }
            }
          }
        }
        receipt {
          id
          number
          remark
          paidDate
          paymentType
          paidTime
          reference
          paymentMethod
          chequeDate
          chequeNumber
          creditCard
          referenceNO
          fee
          subtotal
          deliveryCost
          discountAmount
          subtotalWithDelivery
          subtotalAfterDiscount
          files {
            id
            fileName
            url
          }
          finnicBank {
            id
            bankAccountType
            bankAccountName
            bankAccountNumber
            bank {
              id
              code
              name
              status
              bankCode
            }
          }
          customer {
            id
            name
            prefix
            paymentType
            contactFaxNumber
            contactName
            contactPhoneNumber {
              id
              name
              phoneNumber
              isPrimary
            }
          }
          lot {
            id
            deliveryType
            requireTaxInvoice
            pickingTicket {
              id
              number
            }
            salesOrder {
              id
              soNumber
              type
              creator {
                id
                firstName
                lastName
              }
            }
            billingAddress {
              id
              addressNo
              subDistrict
              contactName
              district
              taxId
              province
              postcode
              contactPhoneNumber
            }
            items {
              id
              status
              quantity
              salesPrice
              discount
              requireTaxInvoice
              variantName
              type
              replaceTo
              replacedBy
              productSet {
                id
                name
              }
              pack {
                id
                name
                inventoryOption {
                  id
                  buyPrice
                  # salesPrice
                  variantName
                  inventory {
                    id
                    type
                    productName
                  }
                }
              }
              goodsReceiveList {
                id
                number
                refId
              }
            }
          }
        }
        createdAt
        updatedAt
      }
    }
  }
`

export const REMAINING_TAX_JOB = gql`
  query remainingTaxInvoice {
    remainingTaxInvoice {
      data {
        waitingTaxInvoice
        waitingDeliver
        waitingIVConfirm
        taxInvoiced
        all
      }
    }
  }
`

export const TAX_INVOICE_BY_CUSTOMER = gql`
  query taxInvoiceListForPickingTicket(
    $limit: Int
    $offset: Int
    $customerId: Int!
  ) {
    taxInvoiceListForPickingTicket(
      input: { limit: $limit, offset: $offset, customerId: $customerId }
    ) {
      success
      message
      data {
        total
        list {
          id
          number
          status
          invoiceId
          receiptId
          ivNumber
          totalCost
        }
      }
    }
  }
`

export const CREDIT_NOTE_DETAIL = gql`
  query creditNoteDetail($lotId: Int!) {
    creditNoteDetail(lotId: $lotId) {
      success
      message
      data {
        id
        number
        status
        createDate
        type
        totalPriceBefore
        totalPriceAfter
        remarks
        deliveryCost
        files {
          id
          fileName
          url
        }
        itemChanges {
          id
          price
          remarks
          quantity
          orderItem {
            id
            buyPrice
            salesPrice
            quantity
            variantName
          }
        }
      }
    }
  }
`

export const CHAT_PROVIDERS = gql`
  query chatProviders($isWithoutPage: Boolean) {
    chatProviders(isWithoutPage: $isWithoutPage) {
      data {
        id
        name
        code
      }
    }
  }
`

export const COMMISSIONS = gql`
  query commissions {
    commissions {
      data {
        id
        sale
        products {
          id
          price
          name
          type
          commission
          files {
            id
            fileName
            url
          }
        }
      }
    }
  }
`

export const ROLE_COMMISSIONS = gql`
  query roleCommissions {
    roleCommissions {
      data {
        id
        roleDescription
        products {
          id
          price
          name
          type
          commission
          files {
            id
            fileName
            url
          }
        }
      }
    }
  }
`

export const BOX_LIST = gql`
  query boxList {
    boxList {
      data {
        id
        name
        price
      }
    }
  }
`

export const GET_ROLES_MOBILE = gql`
  query newRolePermissionList {
    newRolePermissionList {
      data {
        moduleName
        roleList {
          role {
            id
            name
            description
          }
          permissionList {
            id
            name
            description
            status
          }
        }
      }
    }
  }
`

export const DASHBOARD_COMMON_INFO = gql`
  query dashboardCommonInfo($input: DashboardCommonInfoInput) {
    dashboardCommonInfo(input: $input) {
      data {
        saleAmounts
        customerCount
        chatCount
        waitingThan15Mins
        sameDaySoCount
        chatAfterReminder
        chatAfterSalesOrder
      }
    }
  }
`

export const DASHBOARD_CHAT_DAILY = gql`
  query dashboardChatDaily($input: DashboardCommonInfoInput) {
    dashboardChatDaily(input: $input) {
      data {
        key
        value
      }
    }
  }
`

export const DASHBOARD_CHAT_HOURLY = gql`
  query dashboardChatHourly($input: DashboardHourlyInput) {
    dashboardChatHourly(input: $input) {
      data {
        key
        value
      }
    }
  }
`

export const DASHBOARD_SALES_DAILY = gql`
  query dashboardSalesDaily($input: DashboardCommonInfoInput) {
    dashboardSalesDaily(input: $input) {
      data {
        key
        value
      }
    }
  }
`

export const DASHBOARD_SALES_HOURLY = gql`
  query dashboardSalesHourly($input: DashboardHourlyInput) {
    dashboardSalesHourly(input: $input) {
      data {
        key
        value
      }
    }
  }
`

export const TOP_CHATS = gql`
  query TopChats($input: TopChatsInput) {
    TopChats(input: $input) {
      data {
        key
        value
      }
    }
  }
`

export const TOP_CUSTOMERS = gql`
  query TopCustomers($input: DashboardCommonInfoInput) {
    TopCustomers(input: $input) {
      data {
        key
        value
      }
    }
  }
`

export const TOP_PRODUCTS = gql`
  query TopProducts($input: TopProductsInput) {
    TopProducts(input: $input) {
      data {
        key
        value
      }
    }
  }
`

export const SALES_BY_PROVINCE = gql`
  query salesByProvince($input: DashboardCommonInfoInput) {
    salesByProvince(input: $input) {
      data {
        key
        value
      }
    }
  }
`

export const ORDER_SUMMARY = gql`
  query orderSummary($input: OrderSummaryDashboardInput) {
    orderSummary(input: $input) {
      data {
        key
        TRANSFER
        COD
      }
    }
  }
`

export const BOX_SUMMARY = gql`
  query boxSummary($input: BoxSummaryDashboardInput) {
    boxSummary(input: $input) {
      data {
        key
        TRANSFER
        COD
      }
    }
  }
`

export const TAG_LIST = gql`
  query tagList($name: String) {
    tagList(input: {
      name: $name
    }) {
      data {
        list {
          id
          name
        }
      }
    }
  }
`

export const TIME_SPENT_REPORT = gql`
  query timeSpentReport($input: TimeSpentReportInput!) {
    timeSpentReport(input: $input) {
      data {
        chart {
          lessThanTen
          tenToFifteen
          fifteenToThirty
          thirtyToSixty
          moreThanSixty
        }
        chatList {
          list {
            id
            maximumTimeGap
            reminderDate
            reminder
            reminderUser {
              id
              firstName
              lastName
            }
            providerId
            chatContactId
            chatgroupId
            currentUpsell{
              id
              firstName
              lastName
            }
            firstReader{
              id
              firstName
              lastName
            }
            chatProvider{
              id
              name
            }
            owner {
              id
              firstName
              lastName
            }
            chatContact{
              id
              customerId
              uid
              displayName
              profileUrl
              customer{
                id
                name
                contactName
                shippingAddress{
                  id
                  addressNo
                  province
                  district
                  subDistrict
                  postcode
                  thirdPartyAddress
                }
              }
              address{
                id
                addressNo
                province
                district
                subDistrict
                postcode
                contactName
                contactPhoneNumber
                contactOfficeTel
              }
            }
            chatGroupStatus{
              id
              name
            }
            totalMessages
            chatMessages{
              id
              chatContactId
              userId
              user{
                id
                firstName
                lastName
              }
              messageType
              messageResponseType
              isRead
              sendedMessage{
                type
                text
                url
              }
              sendedFlexMessage{
                refId
                status
                totalPrice
                quantity
                uri
                isActive
                imageUrl
                paymentType
                type
                isNewVersion
                deliveryCost
                itemList {
                  itemName
                  totalPrice
                }
              }
              receivedMessage{
                id
                type
                text
              }
              receivedSticker{
                id
                type
                packageId
                stickerId
                stickerResourceType
                url
              }
              receivedImage{
                id
                type
                url
              }
              receivedFile{
                id
                type
                fileName
                fileSize
                url
              }
              receivedLocation{
                id
                type
                title
                address
                latitude
                longitude
              }
              receivedVideo{
                id
                type
                url
              }
              receivedAudio{
                id
                type
                url
                duration
              }
              receivedFacebookText {
                mid
                text
              }
              receivedFacebookMessageWithAttachment {
                mid
                attachments {
                  type
                  payload {
                    url
                    filename
                  }
                }
              }
              createdAt
            }
            createdAt
            updatedAt
          }
          count
        }
      }
    }
  }
`

export const RETURN_SALES_ORDER_DETAIL = gql`
  query returnSalesOrderDetail($receiptId: Int!) {
    returnSalesOrderDetail(receiptId: $receiptId) {
      data {
        isDeliveryAndBoxCount
        isReturned
        deliveryAndBoxAmount
        brokenItemAmount
        returnDate
        returnFileUrl
        remarks
        orderItems {
          id
          status
          avgCost
          quantity
          salesPrice
          discount
          requireTaxInvoice
          variantName
          type
          replaceTo
          replacedBy
          brokenQuantity
          productSet {
            id
            name
          }
          seller {
            id
            firstName
            lastName
          }
          lot {
            id
            salesOrder {
              id
              isCOD
              customer {
                name
              }
              creator {
                id
                firstName
                lastName
              }
            }
          }
          pack {
            id
            name
            inventoryOption {
              id
              buyPrice
              # salesPrice
              variantName
              inventory {
                id
                type
                productName
                sku
                barcode
                brand {
                  id
                  name
                }
              }
            }
          }
        }
      }
    }
  }
`

export const USER_PROVIDERS = gql`
  query userProviders($userId: Int!) {
    userProviders(userId: $userId) {
      data {
        user {
          id
          firstName
          lastName
        }
        chatProviders {
          chatProvider {
            id
            name
            code
            shortName
          }
          status
        }
      }
    }
  }
`

export const TEAM_LIST = gql`
  query teamList {
    teamList {
      data {
        total
        list {
          id
          teamName
        }
      }
    }
  }
`

export const ORDER_BONUS_BY_ROLE = gql`
  query orderBonusByRole($roleId: Int!) {
    orderBonusByRole(roleId: $roleId) {
      data {
        id
        tierPrice
        bonus
      }
    }
  }
`

export const DEFAULT_DELIVERY_CHANNEL = gql`
  query defaultDeliveryChannel {
    defaultDeliveryChannel {
      data {
        id
        deliveryChannel
        isSet
      }
    }
  }
`

export const CHECK_REF_ID = gql`
  query CheckRefId($input: CheckRefIdInput!) {
    checkRefId(input: $input) {
      data {
        bankAccount
        amount
        date
        refId
        displayName
        duplicateOrder
      }
      success
      message
    }
  }
`

export const INVENTORY_LOGS = gql`
  query inventoryLogs($input: InventoryLogsInput!) {
    inventoryLogs(input: $input) {
      data {
        total
        logs {
          id
          refId
          remark
          newQuantity
          oldQuantity
          quantity
          type
          updatedAt
          createdAt
        }
        warehouse {
          warehouseCode
          warehouseName
          id
        }
      }
    }
  }
`

export const UNMATCHED_ORDER_LIST = gql`
  query unmatchedOrderList($input: UnmatchedOrderListInput!) {
    unmatchedOrderList(input: $input) {
      data {
        list {
          customerName
          id
          chatProvider {
            code
            id
            name
            shortName
          }
          customerFullAddress
          customerPhoneNumber
          customerZipCode
          unmatchedOrderItems {
            id
            productName
            productUrl
          }
          ordersn
        }
        total
      }
    }
  }
`

export const TOTAL_MATCH = gql`
  query totalMatch($totalMatchId: Int!) {
    totalMatch(id: $totalMatchId) {
      data
      message
      success
    }
  }
`
