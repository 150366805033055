/* eslint-disable no-underscore-dangle */
import React, { useState, useEffect, useRef, useContext } from 'react'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import _, { sumBy } from 'lodash'
import { DatePicker, Radio, message, Modal, Badge, TimePicker } from 'antd'
import numeral from 'numeral'
import moment from 'moment-timezone'
import { useQuery, useMutation, useSubscription } from '@apollo/client'
import {
  P,
  Div,
  Button,
  Icon,
  Input,
  H4,
  SmallText,
  Label,
} from '@konsys-ui-custom'
import {
  ListContainer,
  LargeTextWithLabel,
  Remark,
  Collapse,
  Divider,
  TableList,
  Select,
  ComponentWithLabelLarge,
  Loading,
  TextBetween,
} from '../../components'
import { theme } from '../../styles/_variables'
import {
  PURCHASEORDERS,
  PO_STATUS_LIST,
  PURCHASEORDER_DETAIL,
} from '../../api/query'
import {
  SUPPLIER_CONFIRM,
  ADJUST_DUE_DATE_PO,
  CANCEL_PO,
  MARK_AS_COMPLETE,
} from '../../api/mutation'
import { PO_SUBSCRIPTION } from '../../api/subscription'
import { PO_STATUS, PO_STATUS_CONST } from '../../constants/status/po'
import { LIST_LIMIT } from '../../constants/enum'
import ITEM_STATUS, { ITEM_KEYS } from '../../constants/status/item'
import { PATH } from '../../constants/path'
import History from '../../components/common/history'
import { permissionRedirect } from '../../utils/util-services'
import {
  AuthorizationContext,
  RemainingJobsContext,
} from '../../store/StoreProvider'
import { getAccessToken } from '../../utils/local-data-service'
import URL from '../../constants/environment'
import { delayInput } from '../../utils'

const Detail = (props) => {
  const [openCollapse, setOpenCollapse] = useState(true)
  const [data, setData] = useState()
  const {
    loading: purchaseOrderLoading,
    data: purchaseOrderDetail,
    refetch: purchaseOrderRefetch,
  } = useQuery(PURCHASEORDER_DETAIL, { variables: { id: props.data.id } })
  const [
    doMarkAsComplete,
    { loading: markAsCompleteLoading },
  ] = useMutation(MARK_AS_COMPLETE)
  const { createdDate, status } = props
  const tableSOStyled = {
    0: {},
    1: {
      width: '86px',
      flexGrow: 0,
      flexShrink: 0,
    },
    2: {
      width: '45px',
      flexGrow: 0,
      flexShrink: 0,
      wordBreak: 'break-all',
    },
    3: {
      width: '45px',
      flexGrow: 0,
      flexShrink: 0,
      wordBreak: 'break-all',
    },
    4: {
      width: '80px',
      flexGrow: 0,
      flexShrink: 0,
      wordBreak: 'break-all',
    },
    5: {
      width: '60px',
      flexGrow: 0,
      flexShrink: 0,
      wordBreak: 'break-all',
    },
    6: {
      width: '80px',
      flexGrow: 0,
      flexShrink: 0,
      wordBreak: 'break-all',
    },
  }

  const headerData = [
    {
      text: 'สินค้าอื่นๆ',
    },
    {
      text: ' ',
      textAlign: 'right',
    },
    {
      text: 'จำนวน',
      textAlign: 'right',
    },
    {
      text: 'รับแล้ว',
      textAlign: 'right',
    },
    {
      text: 'ราคา/หน่วย',
      textAlign: 'right',
    },
    {
      text: 'ส่วนลด',
      textAlign: 'right',
    },
    {
      text: 'ราคารวม',
      textAlign: 'right',
    },
  ]

  const headerDataSO = [
    {
      text: 'สินค้าจาก SO',
    },
    {
      text: ' ',
      textAlign: 'right',
    },
    {
      text: 'จำนวน',
      textAlign: 'right',
    },
    {
      text: 'รับแล้ว',
      textAlign: 'right',
    },
    {
      text: 'ราคา / หน่วย',
      textAlign: 'right',
    },
    {
      text: 'ส่วนลด',
      textAlign: 'right',
    },
    {
      text: 'ราคารวม',
      textAlign: 'right',
    },
  ]

  useEffect(() => {
    setData(purchaseOrderDetail?.purchaseOrderDetail?.data)
  }, [purchaseOrderDetail?.purchaseOrderDetail?.data])

  const ButtonMarkAsComplete = ({ orderItemId }) => (
    <Button
      text="สำเร็จ"
      icon="fal fa-check"
      small
      inverse
      color={theme.color.success}
      loading={markAsCompleteLoading}
      disabled={markAsCompleteLoading}
      onClick={() => {
        doMarkAsComplete({
          variables: {
            orderItemId,
          },
        }).then((resp) => {
          if (resp?.data?.markAsCompleted?.success) {
            message.success(resp?.data?.markAsCompleted?.message)
            if (props.refetch) {
              props.refetch()
              purchaseOrderRefetch()
            }
          } else {
            message.error(resp?.data?.markAsCompleted?.message)
          }
        })
      }}
    />
  )

  const TableListData = _.isEmpty(data?.orderItemWithOutSaleOrder)
    ? []
    : data?.orderItemWithOutSaleOrder?.map((v) => {
      const itemDetail = ITEM_STATUS.find((o) => o.key === v.status)
      const productName = v.type === 'PACK'
        ? v.pack?.inventoryOption?.variantName
        : v.productSet?.name
      const replaceDetail = !_.isEmpty(v.replaceTo) && `แทนสินค้า ${v.replaceTo}`
      if (v.type !== 'PACK') {
        return [
          {
            text: (
              <Div>
                <P display="block">{productName || '-'}</P>
                {!_.isEmpty(replaceDetail) && (
                  <SmallText display="block" bold color={theme.color.gray50}>
                    {replaceDetail}
                  </SmallText>
                )}
              </Div>
            ),
            subItems:
                v.type !== 'PACK'
                && v.childOrderItems.map((orderData) => [
                  { text: orderData.variantName || '-' },
                  { text: orderData.quantity },
                  { text: orderData.receivedQuantity || 0 },
                  { text: '' },
                  { text: '' },
                  { text: '' },
                ]),
          },
          {
            text:
                v?.receivedQuantity === v?.quantity ? (
                  ''
                ) : v?.isMarkedAsCompleted ? (
                  <Div>
                    <Icon
                      icon="fal fa-check-circle"
                      color={theme.color.success}
                    />
                    <SmallText margin="0 0 0 8px" color={theme.color.success}>
                      เสร็จสิ้น
                    </SmallText>
                  </Div>
                ) : (
                  <ButtonMarkAsComplete orderItemId={v?._id} />
                ),
          },
          { text: v.quantity, textAlign: 'right' },
          { text: v.receivedQuantity || 0, textAlign: 'right' },
          { text: numeral(v.buyPrice).format('0,0.00'), textAlign: 'right' },
          {
            text: numeral(v.purchaseOrderDiscount).format('0,0.00'),
            textAlign: 'right',
          },
          {
            text: numeral(
              v.buyPrice * v.quantity - v.purchaseOrderDiscount,
            ).format('0,0.00'),
            textAlign: 'right',
          },
        ]
      }
      return [
        {
          text: (
            <Div>
              <P display="block">{productName || '-'}</P>
              {!_.isEmpty(replaceDetail) && (
                <SmallText display="block" bold color={theme.color.gray50}>
                  {replaceDetail}
                </SmallText>
              )}
            </Div>
          ),
          label: {
            text: (
              <Div>
                <SmallText display="block">
                  <Label margin="0 4px 0 0">Sku: </Label>
                  {v.pack?.inventoryOption?.inventory?.sku || '-'}
                </SmallText>
              </Div>
            ),
          },
          status: _.isEmpty(itemDetail)
            ? {
              text: itemDetail.text,
              color: itemDetail.color,
              icon: _.isUndefined(itemDetail.icon) ? null : itemDetail.icon,
            }
            : undefined,
        },
        {
          text:
              v?.receivedQuantity === v?.quantity ? (
                ''
              ) : v?.isMarkedAsCompleted ? (
                <Div>
                  <Icon
                    icon="fal fa-check-circle"
                    color={theme.color.success}
                  />
                  <SmallText margin="0 0 0 8px" color={theme.color.success}>
                    เสร็จสิ้น
                  </SmallText>
                </Div>
              ) : (
                <ButtonMarkAsComplete orderItemId={v?._id} />
              ),
        },
        { text: v.quantity, textAlign: 'right' },
        { text: v.receivedQuantity || 0, textAlign: 'right' },
        { text: numeral(v.buyPrice).format('0,0.00'), textAlign: 'right' },
        {
          text: numeral(v.purchaseOrderDiscount).format('0,0.00'),
          textAlign: 'right',
        },
        {
          text: numeral(
            (v.buyPrice - v.purchaseOrderDiscount) * v.quantity,
          ).format('0,0.00'),
          textAlign: 'right',
        },
      ]
    })

  const TableListSOData = _.isEmpty(data?.orderItemWithSaleOrder)
    ? []
    : _.flatten(
      data?.orderItemWithSaleOrder?.map((v) => {
        if (_.isEmpty(v.items)) {
          return []
        }
        return v.items.map((d) => {
          const itemDetail = ITEM_STATUS.find((o) => o.key === d.status)
          const productName = d.type === 'PACK'
            ? d.pack?.inventoryOption?.variantName
            : d.productSet?.name
          if (v.type !== 'PACK') {
            return [
              {
                text: productName || '-',
                label: {
                  text: `${v.soNumber} ${
                      d.lotIndex && `(ล็อตที่ ${d.lotIndex})`
                    }`,
                  color: theme.color.gray80,
                },
                subItems:
                    d.type !== 'PACK'
                    && d.childOrderItems.map((orderData) => [
                      { text: orderData.variantName || '-' },
                      { text: orderData.quantity },
                      { text: orderData.receivedQuantity || 0 },
                      { text: '' },
                      { text: '' },
                      { text: '' },
                    ]),
              },
              {
                text:
                    d?.receivedQuantity === d?.quantity
                    || d?.itemSource === 'PREORDER' ? (
                        ''
                      ) : d?.isMarkedAsCompleted ? (
                        <Div>
                          <Icon
                            icon="fal fa-check-circle"
                            color={theme.color.success}
                          />
                          <SmallText
                            margin="0 0 0 8px"
                            color={theme.color.success}
                          >
                          เสร็จสิ้น
                          </SmallText>
                        </Div>
                      ) : (
                        <ButtonMarkAsComplete orderItemId={d?._id} />
                      ),
              },
              { text: d.quantity || 0, textAlign: 'right' },
              { text: d.receivedQuantity || 0, textAlign: 'right' },
              {
                text: numeral(d.buyPrice).format('0,0.00'),
                textAlign: 'right',
              },
              {
                text: numeral(d.purchaseOrderDiscount).format('0,0.00'),
                textAlign: 'right',
              },
              {
                text: numeral(
                  d.buyPrice * d.quantity
                      - (d.purchaseOrderDiscount || 0) * d.quantity,
                ).format('0,0.00'),
                textAlign: 'right',
              },
            ]
          }
          return [
            {
              text: productName || '-',
              status: _.isEmpty(itemDetail)
                ? {}
                : {
                  text: itemDetail.text,
                  color: itemDetail.color,
                  icon: _.isUndefined(itemDetail.icon)
                    ? null
                    : itemDetail.icon,
                },
              label: {
                text: `${v.soNumber} ${
                    d.lotIndex && `(ล็อตที่ ${d.lotIndex})`
                  }`,
                color: theme.color.gray80,
              },
            },
            {
              text:
                  d?.receivedQuantity === d?.quantity
                  || d?.itemSource === 'PREORDER' ? (
                      ''
                    ) : (
                      <ButtonMarkAsComplete orderItemId={d?._id} />
                    ),
            },
            { text: d.quantity, textAlign: 'right' },
            { text: d.receivedQuantity || 0, textAlign: 'right' },
            {
              text: numeral(d.buyPrice).format('0,0.00'),
              textAlign: 'right',
            },
            {
              text: numeral(d.purchaseOrderDiscount).format('0,0.00'),
              textAlign: 'right',
            },
            {
              text: numeral(d.buyPrice * d.quantity - d.discount).format(
                '0,0.00',
              ),
              textAlign: 'right',
            },
          ]
        })
      }),
    )

  if (purchaseOrderLoading) {
    return (
      <Div textAlign="center" margin="32px 0 0">
        <Loading size="small" />
      </Div>
    )
  }
  if (_.isEmpty(data)) return null

  return (
    <Div>
      <Div display="flex" justifyContent="space-between" alignItems="center">
        <H4 bold color={theme.color.primaryColor}>
          {data.number}
        </H4>
        <P bold color={status.color}>
          {status.text}
        </P>
      </Div>
      <Div margin="32px 0">
        <Div margin="0 0 16px" display="flex" alignItems="flex-start">
          <LargeTextWithLabel
            margin="0 24px 0 0"
            title={{ text: 'วันที่สร้างใบ PO' }}
            content={{ text: createdDate }}
          />
          <LargeTextWithLabel
            margin="0 24px 0 0"
            title={{ text: 'วันที่กำหนดส่ง' }}
            content={{ text: moment(data.dueDate).format('DD/MM/YY') }}
          />
          <LargeTextWithLabel
            margin="0 24px 0 0"
            title={{ text: 'PO Reference' }}
            content={{ text: data.extRef || '-' }}
          />
        </Div>
        {!_.isEmpty(data.purchaseOrderFiles) && (
          <LargeTextWithLabel
            style={{ maxWidth: '100%', wordBreak: 'break-all' }}
            title={{ text: 'เอกสาร' }}
            margin="0 0 16px"
            content={data.purchaseOrderFiles.map((v, i) => ({
              text: v.fileName,
              type: 'a',
              link: v.url,
              margin: i === data.purchaseOrderFiles.length - 1 ? 0 : '0 0 4px',
              target: '_blank',
            }))}
          />
        )}
        <Remark description={_.isEmpty(data.remark) ? '-' : data.remark} />
      </Div>
      {(!_.isEmpty(TableListData) || !_.isEmpty(TableListSOData)) && (
        <Collapse
          text="รายละเอียดสินค้า"
          fullHeight
          open={openCollapse}
          onClick={() => setOpenCollapse(!openCollapse)}
        >
          <Div>
            {!_.isEmpty(TableListSOData) && (
              <TableList
                data={TableListSOData}
                headerData={headerDataSO}
                columnStyled={tableSOStyled}
              />
            )}
            {!_.isEmpty(TableListData) && !_.isEmpty(TableListSOData) && (
              <Divider margin="24px 0" />
            )}
            {!_.isEmpty(TableListData) && (
              <TableList
                data={TableListData}
                headerData={headerData}
                columnStyled={tableSOStyled}
              />
            )}
            <Divider margin="24px 0" />
            <TextBetween
              margin="0 0 8px"
              title={{ text: 'ยอดชำระ', bold: true }}
              content={{
                text: `${numeral(sumBy(data.orderItemWithOutSaleOrder, (o) => o.quantity * (o.buyPrice - o.purchaseOrderDiscount))).format('0,0.00')} บาท`,
                bold: true,
              }}
            />
          </Div>
        </Collapse>
      )}
    </Div>
  )
}

let poList = []

const orderBy = [
  { text: 'เรียงจาก: ล่าสุด ไป อดีต', value: 'DESC' },
  { text: 'เรียงจาก: อดีต ไป ล่าสุด', value: 'ASC' },
]

const orderField = [
  { text: 'กรองจาก: วันที่แก้ไข', value: 'updatedAt' },
  { text: 'กรองจาก: วันที่สร้าง', value: 'createdAt' },
]

const dateType = [
  { text: 'วันที่สร้าง', value: 'createdDate' },
  { text: 'วันที่กำหนดส่ง', value: 'paymentDate' },
]

const AdjustDueDate = ({ data, refetch }) => {
  const [doUpdatePurchaseOrder, { loading }] = useMutation(ADJUST_DUE_DATE_PO, {
    refetchQueries: ['remainingJob', 'remainingAccountant'],
  })
  const [dueDate, setDueDate] = useState()
  const [visible, setVisible] = useState(false)
  return (
    <Div>
      <Modal
        title="แก้ไขวันที่กำหนดส่ง"
        visible={visible}
        okButtonProps={{ loading }}
        cancelButtonProps={{ disabled: loading }}
        onOk={() => {
          doUpdatePurchaseOrder({
            variables: {
              id: data.id,
              dueDate,
            },
          }).then((resp) => {
            setVisible(false)
            setDueDate()
            if (_.isEmpty(resp.errors)) {
              if (resp.data.adjustDueDate.success) {
                message.success(resp.data.adjustDueDate.message)
                refetch()
              } else {
                message.error(resp.data.adjustDueDate.message)
              }
            }
          })
        }}
        onCancel={() => {
          setVisible(false)
          setDueDate()
        }}
      >
        <ComponentWithLabelLarge title={{ text: 'วันที่กำหนดส่งใหม่' }}>
          <DatePicker value={dueDate} onChange={(e) => setDueDate(e)} />
        </ComponentWithLabelLarge>
      </Modal>
      <Button
        small
        ghost
        color={theme.color.orange}
        onClick={() => setVisible(true)}
        text="แก้ไขวันที่กำหนดส่ง"
        icon="fal fa-clock"
      />
    </Div>
  )
}

const POList = (props) => {
  const [list, setList] = useState([])
  const [searchText, setSearchText] = useState('')
  const [firstLoad, setFirstLoad] = useState(true)
  const [selectedStatus, setSelectedStatus] = useState(undefined)
  const [selectedDateType, setSelectedDateType] = useState(dateType[0].value)
  const [selectedOrderBy, setSelectedOrderBy] = useState(orderBy[0].value)
  const [selectedOrderField, setSelectedOrderField] = useState(
    orderField[0].value,
  )
  const [selectedDate, setSelectedDate] = useState(null)
  const [filterLoading, setFilterLoading] = useState(false)
  const [fetchMoreLoading, setFetchMoreLoading] = useState(false)
  const [openModal, setOpenModal] = useState(false)
  const [poRef, setPoRef] = useState('')
  const [dateRef, setDateRef] = useState('')
  const [timeRef, setTimeRef] = useState('')
  const [channelRef, setChannelRef] = useState('')
  const [isPrintingLoading, setIsPrintingLoading] = useState(false)
  const isInitialMount = useRef(true)
  const [doSupplierConfirm, { loading: supplierConfirmLoading }] = useMutation(
    SUPPLIER_CONFIRM,
    {
      refetchQueries: [
        'remainingJob',
        'remainingAccountant',
        'purchaseOrderList',
      ],
    },
  )
  const [doCancelPO, { loading: cancelling }] = useMutation(CANCEL_PO, {
    refetchQueries: [
      'remainingJob',
      'remainingAccountant',
      'purchaseOrderList',
    ],
  })
  const { data, loading, fetchMore, refetch } = useQuery(PURCHASEORDERS, {
    variables: {
      limit: LIST_LIMIT,
      offset: 0,
    },
    fetchPolicy: 'network-only',
  })
  const { data: statusData, refetch: statusDataRefetch } = useQuery(PO_STATUS_LIST)
  const authorization = useContext(AuthorizationContext)
  const purchaseOrderAutho = authorization.authorization?.purchaseOrder || {}
  const { remainingJobs } = useContext(RemainingJobsContext)

  const { data: purchaseOrderData } = useSubscription(PO_SUBSCRIPTION)

  const statusOption = statusData?.purchaseOrderStatusList?.data.map((v) => ({
    text: (
      <SmallText
        color={
          v.status === PO_STATUS[0].key || v.status === PO_STATUS[5].key
            ? theme.color.error
            : theme.color.gray70
        }
      >{`${PO_STATUS.find((o) => o.key === v.status).text} (${
          v.count
        })`}</SmallText>
    ),
    value: v.status,
  })) || []
  statusOption.unshift({
    text: statusData?.purchaseOrderStatusList?.data
      ? `ทั้งหมด (${_.reduce(
        statusData.purchaseOrderStatusList.data,
        (sum, n) => sum + n.count,
        0,
      )})`
      : 'ทั้งหมด',
    value: 'all',
  })

  const download = (id) => {
    setIsPrintingLoading(true)
    window.open(
      `${
        URL.FILE_SERVER
      }/print/purchase-order/${id}?t=${getAccessToken()}`,
      '_blank',
      'toolbar=false,scrollbars=yes,resizable=false',
    )
    setIsPrintingLoading(false)
  }

  const renderConfirm = (rowData) => (
    <Div key={0} display="inline-block">
      <Modal
        width={700}
        title="ยืนยัน Supplier"
        visible={openModal}
        onOk={() => doSupplierConfirm({
          variables: {
            purchaseOrderId: rowData.id,
            extRef:
                poRef
                || `Supplier ยืนยันวันที่ ${dateRef} ${timeRef} ช่องทาง ${channelRef}`,
          },
        }).then((resp) => {
          if (resp.data.supplierConfirm.success) {
            setOpenModal(false)
            setPoRef('')
            setDateRef('')
            statusDataRefetch()
            message
              .success(resp.data.supplierConfirm.message, 2)
              .then(() => refetch())
          } else {
            message.error(resp.data.supplierConfirm.message)
          }
        })
        }
        cancelText="ยกเลิก"
        okText="ยืนยัน"
        okButtonProps={{
          disabled:
            supplierConfirmLoading
            || (_.isEmpty(poRef)
              && (_.isEmpty(dateRef)
                || _.isEmpty(timeRef)
                || _.isEmpty(channelRef))),
        }}
        onCancel={() => setOpenModal(false)}
      >
        <Div display="flex" alignItems="center">
          <Input
            disabled={
              !_.isEmpty(dateRef)
              || !_.isEmpty(timeRef)
              || !_.isEmpty(channelRef)
            }
            placeholder="กรอกเลขใบสั่งซื้อ"
            onChange={(e) => setPoRef(e.target.value)}
          />
          <SmallText margin="0 16px">หรือ</SmallText>
          <Div display="flex">
            <Div margin="0 8px 0 0">
              <DatePicker
                disabled={!_.isEmpty(poRef)}
                format="DD/MM/YYYY"
                onChange={(date, dateString) => setDateRef(dateString)}
              />
            </Div>
            <TimePicker
              disabled={!_.isEmpty(poRef)}
              format={'HH:mm'}
              onChange={(time, timeString) => setTimeRef(timeString)}
            />
            <Select
              allowClear
              disabled={!_.isEmpty(poRef)}
              style={{ width: '180px', margin: '0 0 0 8px' }}
              suffixIcon={<Icon icon="fal fa-chevron-down" />}
              placeholder="เลือกช่องทาง"
              options={[
                { value: 'Email', text: 'Email' },
                { value: 'Line', text: 'Line' },
                { value: 'โทรศัพท์', text: 'โทรศัพท์' },
              ]}
              onChange={(value) => setChannelRef(value)}
            />
          </Div>
        </Div>
      </Modal>
    </Div>
  )

  useEffect(() => {
    if (!purchaseOrderAutho?.view) {
      permissionRedirect()
    }
  }, [])

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false
    } else {
      setFilterLoading(true)
      setFirstLoad(false)
      refetch({
        search: searchText,
        status: selectedStatus === 'all' ? undefined : selectedStatus,
        createdDate:
          selectedDateType === 'createdDate' ? selectedDate : undefined,
        paymentDueDate:
          selectedDateType === 'paymentDate' ? selectedDate : undefined,
        orderField: selectedOrderField,
        orderBy: selectedOrderBy,
      }).then(() => {
        setFilterLoading(false)
      })
    }
  }, [
    searchText,
    selectedStatus,
    selectedDateType,
    selectedDate,
    selectedOrderField,
    selectedOrderBy,
  ])

  useEffect(() => {
    if (!_.isEmpty(props.location?.state?.search)) {
      setSearchText(props.location?.state?.search)
    }
  }, [props.location?.state?.search])

  useEffect(() => {
    if (!_.isEmpty(data?.purchaseOrderList?.data?.list)) {
      setList(data?.purchaseOrderList?.data?.list)
    }
  }, [data])

  const isApplySubscription = selectedStatus === PO_STATUS_CONST.WAITING_SUPPLIER
    || selectedStatus === 'all'
    || _.isEmpty(selectedStatus)
  useEffect(() => {
    if (
      !_.isEmpty(purchaseOrderData?.purchaseOrderChangeSub?.data)
      && isApplySubscription
      && props.setCurrentCard
    ) {
      const subscripList = _.isEmpty(
        purchaseOrderData?.purchaseOrderChangeSub?.data,
      )
        ? []
        : [
          {
            ...purchaseOrderData?.purchaseOrderChangeSub?.data,
            isDataFormSubscription: true,
          },
        ]
      props.setCurrentCard(props?.currentCard + 1)
      setList([...subscripList, ...list])
    }
  }, [purchaseOrderData])

  useEffect(() => {
    if (!_.isEmpty(purchaseOrderData?.purchaseOrderChangeSub?.data)) {
      props.setCurrentCard(props?.currentCard - 1)
      if (isApplySubscription) {
        setList(data?.purchaseOrderList?.data?.list || [])
      }
    }
  }, [selectedStatus])

  if (data && data.purchaseOrderList) {
    if (
      data.purchaseOrderList.data.total > 0
      || !_.isEmpty(purchaseOrderData?.purchaseOrderChangeSub?.data)
    ) {
      poList = list?.map((v) => {
        const createdDate = moment
          .tz(v.createdAt, 'Asia/Bangkok')
          .format('DD/MM/YYYY')
        const statusObj = PO_STATUS.find((o) => o.key === v.status)
        const totalPriceOther = !_.isEmpty(v.orderItemWithOutSaleOrder)
          ? _.reduce(
            v.orderItemWithOutSaleOrder,
            (total, obj) => total + obj.buyPrice * obj.quantity,
            0,
          )
          : 0
        const totalPriceSO = !_.isEmpty(v.orderItemWithSaleOrder)
          ? _.reduce(
            v.orderItemWithSaleOrder,
            (total, objOrder) => {
              const totalObjOrder = !_.isEmpty(objOrder.items)
                ? _.reduce(
                  objOrder.items,
                  (totalPriceObjOrder, d) => totalPriceObjOrder + d.buyPrice * d.quantity,
                  0,
                )
                : 0
              return total + totalObjOrder
            },
            0,
          )
          : 0

        return {
          title: v.number,
          label: `(สร้าง ${createdDate})`,
          status: statusObj,
          newCard: v?.isDataFormSubscription,
          content: {
            one: {
              title: { text: 'วันที่กำหนดส่ง' },
              content: { text: moment(v.dueDate).format('DD/MM/YY') },
            },
          },
          detail: {
            title: [
              {
                text: 'รายละเอียด',
                icon: 'fal fa-info-square',
              },
              purchaseOrderAutho?.viewHistoryLog && {
                text: 'ประวัติการใช้งาน',
                icon: 'fad fa-history',
              },
            ],
            buttonList: [
              v.confirmable
                && purchaseOrderAutho?.supplierConfirm
                && renderConfirm(v),
              (statusObj.key === ITEM_KEYS.waiting
                || (statusObj.key === ITEM_KEYS.shipmentOverdue
                  && _.isNil(v.extRef)))
                && purchaseOrderAutho?.edit && (
                <Link key={1} to={`${PATH.poList}${PATH.edit}/${v.id}`}>
                  <Button
                    small
                    ghost
                    text="แก้ไขใบ PO"
                    icon="fal fa-edit"
                    margin="0 6px 0 0"
                  />
                </Link>
              ),
              (statusObj.key === PO_STATUS_CONST.WAITING_SUPPLIER
                || statusObj.key === PO_STATUS_CONST.SUPPLIER_CF)
                && purchaseOrderAutho?.edit && (
                <Button
                  key={2}
                  onClick={() => Modal.confirm({
                    title: `คุณต้องการยกเลิกSupplier #${v.number}?`,
                    okText: 'ยกเลิกSupplierนี้',
                    okType: 'danger',
                    cancelText: 'เก็บไว้',
                    onOk() {
                      doCancelPO({
                        variables: { id: v.id },
                      }).then((resp) => {
                        if (resp.data.cancelPurchaseOrder.success) {
                          statusDataRefetch()
                          message.success(
                            resp.data.cancelPurchaseOrder.message,
                          )
                        } else {
                          message.error(
                            resp.data.cancelPurchaseOrder.message,
                          )
                        }
                      })
                    },
                    onCancel() {},
                  })
                  }
                  small
                  margin="0 6px 0 0"
                  loading={cancelling}
                  ghost
                  text="ยกเลิกใบ PO"
                  icon="fal fa-times"
                  color={theme.color.error}
                />
              ),
              statusObj.key === ITEM_KEYS.shipmentOverdue
                && !_.isNil(v.extRef)
                && purchaseOrderAutho?.edit && (
                <AdjustDueDate key={3} data={v} refetch={refetch} />
              ),
              purchaseOrderAutho?.view && (
                <Button
                  key="print-po"
                  disabled={isPrintingLoading}
                  loading={isPrintingLoading}
                  small
                  text="พิมพ์Supplier"
                  icon="fal fa-print"
                  onClick={() => download(v.id)}
                />
              ),
            ],
            content: [
              <Detail
                key={0}
                data={v}
                totalPrice={totalPriceSO + totalPriceOther}
                status={statusObj}
                createdDate={createdDate}
                refetch={() => refetch()}
              />,
              <History key={1} data={v} moduleName="PURCHASE_ORDER" />,
            ],
          },
        }
      })
    } else {
      poList = []
    }
  } else if (loading && !data) {
    poList = []
  }

  return (
    <ListContainer
      currentCard={props?.currentCard}
      setCurrentCard={(value) => props?.setCurrentCard(value)}
      tabLoading={fetchMoreLoading}
      listLoading={filterLoading || (firstLoad && _.isEmpty(data))}
      title={[
        {
          text: 'Supplier',
          icon: 'fal fa-envelope-open-dollar',
        },
      ]}
      buttonList={
        purchaseOrderAutho?.create
          ? [
            <Link key={0} to={`${PATH.poList}${PATH.create}`}>
              <Badge count={remainingJobs.po}>
                <Button
                  small
                  ghost
                  inverse
                  color={theme.color.success}
                  text="เพิ่มใบสั่งซื้อใหม่"
                  icon="fal fa-plus"
                />
              </Badge>
            </Link>,
          ]
          : []
      }
      filterHeight={100}
      filter={[
        <Div key={0} margin="0 0 24px">
          <Div
            display="flex"
            alignItems="flex-start"
            moreStyle={{ flexGrow: 1 }}
          >
            <Div moreStyle={{ flexGrow: 1 }} margin="0 16px 0 0">
              <Input
                type="text"
                width="100%"
                prefix={<Icon icon="prefix fal fa-search" />}
                placeholder="ใบสั่งซื้อ, Supplier Name, SO Number, ชื่อสินค้า, รหัสสินค้า"
                // value={searchText}
                onChange={(e) => {
                  const searchValue = e.target.value
                  delayInput(() => {
                    setSearchText(searchValue)
                  })
                }}
                suffix={
                  !loading
                  && !_.isEmpty(data?.purchaseOrderList?.data)
                  && !_.isEmpty(searchText) && (
                    <Div
                      position="absolute"
                      right="16px"
                      top="50%"
                      moreStyle={{ transform: 'translateY(-50%)' }}
                    >
                      <SmallText>
                        {numeral(
                          data?.purchaseOrderList?.data?.total || 0,
                        ).format('0,0')}{' '}
                        รายการ
                      </SmallText>
                    </Div>
                  )
                }
              />
            </Div>
            <Select
              style={{ width: '100%', flexBasis: '17%', margin: '0 16px 0 0' }}
              suffixIcon={<Icon icon="fal fa-chevron-down" />}
              placeholder="กรองสถานะ"
              options={statusOption}
              value={selectedStatus}
              onChange={(value) => setSelectedStatus(value)}
            />
            <DatePicker.RangePicker
              style={{ flexBasis: '27%' }}
              format="DD/MM/YYYY"
              suffixIcon={
                <Icon color={theme.color.gray50} icon="fal fa-calendar" />
              }
              value={selectedDate}
              onChange={(value) => setSelectedDate(value)}
            />
            <Radio.Group
              value={selectedDateType}
              style={{ margin: '0 0 0 8px' }}
            >
              {dateType.map((v) => (
                <Radio.Button
                  key={v.value}
                  value={v.value}
                  onChange={(e) => setSelectedDateType(e.target.value)}
                >
                  {v.text}
                </Radio.Button>
              ))}
            </Radio.Group>
          </Div>
          <Div display="flex" margin="8px 0 0">
            <Div display="flex" moreStyle={{ flexGrow: 1 }} margin="0 56px 0 0">
              <Select
                value={selectedOrderField}
                style={{
                  width: '41.65%',
                  minWidth: '200px',
                  margin: '0 16px 0 0',
                }}
                options={orderField}
                onChange={(value) => setSelectedOrderField(value)}
              />
              <Select
                value={selectedOrderBy}
                style={{ width: '41.65%', minWidth: '200px' }}
                options={orderBy}
                onChange={(value) => setSelectedOrderBy(value)}
              />
            </Div>
            <Div display="flex" alignItems="center">
              <Button
                text="ล้าง"
                icon="fal fa-sync"
                small
                ghost
                onClick={() => {
                  setSearchText('')
                  setSelectedStatus(undefined)
                  setSelectedDate(null)
                  setSelectedDateType(dateType[0].value)
                  setSelectedOrderField(orderField[0].value)
                  setSelectedOrderBy(orderBy[0].value)
                  setFilterLoading(true)
                  setFirstLoad(false)
                  refetch({
                    search: '',
                  }).then(() => {
                    setFilterLoading(false)
                  })
                }}
              />
            </Div>
          </Div>
        </Div>,
      ]}
      list={poList}
      onInfiniteScroll={() => {
        setFetchMoreLoading(true)
        fetchMore({
          variables: {
            offset: data.purchaseOrderList.data.list.length,
          },
          updateQuery: (prev, { fetchMoreResult }) => {
            setFetchMoreLoading(false)
            if (!fetchMoreResult) return prev
            return { ...prev,
              purchaseOrderList: {
                ...prev.purchaseOrderList,
                data: {
                  ...prev.purchaseOrderList.data,
                  total: prev.purchaseOrderList.data.total,
                  list: [
                    ...prev.purchaseOrderList.data.list,
                    ...fetchMoreResult.purchaseOrderList.data.list,
                  ],
                },
              } }
          },
        })
      }}
    />
  )
}

export default (props) => {
  const [currentCard, setCurrentCard] = useState(0)
  return (

    <Div>
      <Helmet>
        <meta name="viewport" content="width=1280" />
      </Helmet>
      <POList
        currentCard={currentCard}
        setCurrentCard={(value) => setCurrentCard(value)}
        {...props}
      />
    </Div>
  )
}
