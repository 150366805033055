/* eslint-disable import/no-cycle */
import React from 'react'
import Konsys from 'konsys-ui'
import { Link } from 'react-router-dom'
import styled, { withTheme, css } from 'styled-components'
import { theme } from '../../styles/_variables'
import { InputWithLabel as InputWithLabelCustomized } from '../index'

export const Icon = withTheme(Konsys.Icon)
export const P = withTheme(Konsys.P)
export const H1 = withTheme(Konsys.H1)
export const H2 = withTheme(Konsys.H2)
export const H3 = withTheme(Konsys.H3)
export const H4 = withTheme(Konsys.H4)
export const Label = withTheme(Konsys.Label)
export const SmallText = withTheme(Konsys.SmallText)
export const Radio = withTheme(Konsys.Radio)
export const Loading = withTheme(Konsys.Loading)
export const Select = withTheme(Konsys.Select)
export const Collapse = withTheme(Konsys.Collapse)
export const LoadingIcon = withTheme(Konsys.LoadingIcon)

export const InputWithLabel = withTheme(InputWithLabelCustomized)

export const Div = withTheme(Konsys.Div)

/* ====================== CUSTOM COMPONENT =========================== */

export const RadioGroup = styled(withTheme(Konsys.RadioGroup))`
  ${(props) => props.small && css`
    label {
      > p {
        font-size: ${theme.fonts.size.small};
        font-family: ${theme.fonts.family.normal};
      }
      > span {
        width: 14px;
        height: 14px;
      }
    }
  `}
`

const LinkVariable = css`
  color: ${(props) => (props.color || theme.color.secondaryColor)};
  font-size: ${theme.fonts.size.normal};
  &:hover {
    color: ${(props) => (props.color ? `${props.color}CC` : `${theme.color.secondaryColor}CC`)};
    > i {
      color: ${(props) => (props.color ? `${props.color}CC` : `${theme.color.secondaryColor}CC`)};
    }
  }
  &:visited {
    color: ${(props) => (props.color ? `${props.color}88` : `${theme.color.secondaryColor}88`)};
    > i {
      color: ${(props) => (props.color ? `${props.color}88` : `${theme.color.secondaryColor}88`)};
    }
  }
  > i {
    color: ${(props) => (props.color || theme.color.secondaryColor)};
    transition: ${theme.animate.transition};
  }
`

export const TextLink = styled(withTheme(Konsys.TextLink))`
  ${LinkVariable}
`

export const ReactRouterLink = styled(Link)`
  ${LinkVariable}
`

export const SmallTextLink = styled(withTheme(Konsys.TextLink))`
  ${LinkVariable}
  font-size: ${theme.fonts.size.small};
`

export const SmallReactRouterLink = styled(Link)`
  ${LinkVariable}
  font-size: ${theme.fonts.size.small};
`

export const Button = styled(withTheme(Konsys.Button))`
  border-radius: 2px;
  font-weight: ${(props) => (props.bold ? theme.fonts.style.regular : theme.fonts.style.light)};
  &:disabled {
    > i {
      color: ${(props) => (props.color ? `${props.color}33` : `${theme.color.primaryColor}33`)} !important;
    }
  }
`

export const Checkbox = styled(withTheme(Konsys.Checkbox))`
  > label {
    > span {
      border: 1px solid ${(props) => (props.activeColor || theme.color.darkBlue)};
      background-color: ${(props) => (props.activeColor || theme.color.background)};
      width: 24px;
      height: 24px;
      &:before {
        color: ${theme.color.primaryColor};
      }
    }
    ${(props) => props.disabled && css`
      > span {
        border: 1px solid ${props.disabledColor || theme.color.gray10};
        background-color: ${props.disabledColor || theme.color.gray10};

      }
    `}
  }
`

export const CheckboxGroup = styled(withTheme(Konsys.CheckboxGroup))`
  > div {
    > label {
      > span {
        border: 1px solid ${theme.color.background};
        background-color: ${theme.color.background};
        width: 24px;
        height: 24px;
        &:before {
          color: ${theme.color.primaryColor};
        }
      }
    } 
  }
`

export const Inputt = styled(withTheme(Konsys.Input))`
  border: 1px solid ${theme.color.lightBlue};
  border-radius: 2px;
`

export const Input = (props) => <Inputt {...props} state='default'/>

export default Button
