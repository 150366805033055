import React, { useState, useEffect } from 'react'
import _ from 'lodash'
import moment from 'moment-timezone'
import { Link } from 'react-router-dom'
import numeral from 'numeral'
import { useQuery, useMutation } from '@apollo/client'
import {
  Row, Col, DatePicker, message,
} from 'antd'
import {
  Div, SmallText, Input, Label, P, Button,
} from '@konsys-ui-custom'
import history from '../../../history'
import {
  Tab, MessagePage, RowSelect, Loading, Divider, SmallTextWithLabel,
  TextareaWithLabel, Uploader, ComponentWithLabelSmall, Select,
} from '../../../components'
import MESSAGE from '../../../constants/message'
import { theme } from '../../../styles/_variables'
import { GET_BILLABLE_SO, CUSTOMERS, GET_DEBT_FOR_OPEN_BILL } from '../../../api/query'
import { CREATE_BILLING } from '../../../api/mutation'
import { RowWrapper } from '../debt/styled'
import { soStatusMaptoText, soStatusMaptoColor } from '../../sale-orders/utils'
import { PAYMENT_TYPE } from '../../sale-orders/constants'

export const DebtDisplay = (props) => (
  <RowWrapper>
    <RowSelect list={[
      {
        value: props.data.id,
        onChange: (e) => props.onChange && props.onChange(e.target.checked, props.data.id),
        checked: props.checked,
        data: [
          {
            title: {
              text: 'วันที่สร้าง',
              width: '100px',
            },
            content: {
              text: `${moment(props.data.createdAt).format('DD/MM/YYYY')}`,
              width: '100px',
            },
            margin: '0 16px 0 0',
          }, {
            title: {
              text: props.data.type === 'DN' ? 'ใบเพิ่มหนี้' : 'ใบตีกลับ',
              color: props.data.type === 'DN' ? theme.color.success : theme.color.error,
              width: '100px',
            },
            content: {
              text: `#${props.data.number}`,
              color: props.data.type === 'DN' ? theme.color.success : theme.color.error,
              width: '100px',
            },
          },
          {
            title: {
              text: 'Invoice',
              width: '100px',
            },
            content: {
              text: `#${props.data.invoice?.number}`,
              width: '100px',
            },
            margin: '0 16px 0 0',
          }, {
            title: {
              text: 'หมายเหตุ',
            },
            content: {
              text: props.data.remarks || '-',
            },
            margin: '0 72px 0 0',
            style: { flexGrow: 1 },
          }, {
            title: {
              text: 'มูลค่าเดิม',
              width: '110px',
            },
            content: {
              text: `${numeral(props.data.totalPriceBefore).format('0,0.00')} บาท`,
              width: '110px',
            },
          }, {
            title: {
              text: 'มูลค่าที่ถูกต้อง',
              width: '110px',
            },
            content: {
              text: `${numeral(props.data.totalPriceAfter).format('0,0.00')} บาท`,
              width: '110px',
            },
          }, {
            title: {
              text: 'ส่วนต่าง',
              width: '140px',
            },
            content: {
              text: `${numeral(Math.abs(props.data.totalPriceBefore - props.data.totalPriceAfter)).format('0,0.00')} บาท`,
              bold: true,
              width: '140px',
            },
          },
        ],
      },
    ]} />
  </RowWrapper>
)

const Form = () => {
  const [search, setSearch] = useState('')
  const [customerId, setCustomerId] = useState()
  const [customer, setCustomer] = useState()
  const [name, setName] = useState()
  const [selectedLot, setSelectedLot] = useState([])
  const [variables, setVariables] = useState({ files: [], createDate: moment() })
  const [selectedInvoice, setSelectedInvoice] = useState([])

  const { data, loading } = useQuery(GET_BILLABLE_SO, { variables: { search, customerId } })
  const { data: customerData, loading: customerLoading } = useQuery(CUSTOMERS, { variables: { name } })
  const { data: debtData, loading: debtLoading } = useQuery(GET_DEBT_FOR_OPEN_BILL, { variables: { customerId } })
  const [doCreate, { loading: creating }] = useMutation(CREATE_BILLING, { refetchQueries: ['remainingJob', 'remainingAccountant'] })

  const LotDisplay = (props) => (
    <RowWrapper>
      <RowSelect header list={[
        {
          value: props.value,
          onChange: (e) => props.onChange && props.onChange(e),
          checked: props.checked,
          data: [
            {
              title: {
                text: 'วันที่สร้าง',
              },
              content: {
                text: moment(props.data.createDate).format('DD/MM/YYYY'),
              },
            },
            {
              title: {
                text: 'วันที่กำหนดชำระ',
              },
              content: {
                text: moment(props.data.paymentDueDate).format('DD/MM/YYYY'),
              },
              margin: '0 24px 0 0',
            },
            {
              title: {
                text: 'Invoice',
              },
              content: {
                text: `#${props.data.invoiceNumber}`,
              },
            }, {
              title: {
                text: 'SO Number',
              },
              content: {
                text: `#${props.soNumber}`,
              },
              margin: '0 16px 0 0',
            }, {
              title: {
                text: 'สถานะ',
              },
              content: {
                text: props.status?.text,
                color: props.status?.color,
                width: '110px',
              },
              margin: '0 32px 0 0',
            }, {
              title: {
                text: `ล็อตที่ ${props.data.lotIndex}`,
              },
              content: {
                text: `สินค้า ${props.data.itemsAmount} รายการ`,
              },
            }, {
              title: {
                text: 'PT Reference',
              },
              content: {
                text: props.data.ptNumber,
              },
            }, {
              title: {
                text: 'ยอดชำระ',
              },
              content: {
                text: numeral(props.data.subtotalAfterDiscount).format('0,0.00'),
              },
            },
          ],
        },
      ]} />
    </RowWrapper>
  )

  const SoDetail = (props) => (
    <Div>
      <Div display='flex' justifyContent='space-between' padding='8px 16px' bgColor='RGBA(99, 167, 224, 0.35)'>
        <Div moreStyle={{ flexGrow: 1 }}>
          <Label display='block' bold>ชื่อลูกค้า</Label>
          <SmallText bold>{props.data.name}</SmallText>
        </Div>
      </Div>
      {
        !_.isEmpty(props.data.salesOrder) && props.data.salesOrder.map((v) => v.invoices.map((o, i) => (
          <LotDisplay
            soNumber={v.soNumber}
            status={v.status}
            key={i}
            data={o}
            value={o.id}
            checked={_.includes(props.selectedLot, o.id)}
            onChange={(e) => props.onChangeLot(e.target.checked, o.id)}
          />
        )))
      }
    </Div>
  )

  const soList = (loading
    || _.isEmpty(data)
    || _.isEmpty(data.getBillableLot)
    || !data.getBillableLot.success
    || _.isEmpty(data.getBillableLot.data)) ? [] : data.getBillableLot.data.map((v) => (
      {
        id: v.id,
        name: `${v.prefix || ''}${v.name}`,
        customer: v,
        salesOrder: v.salesOrders.map((o) => (
          {
            id: o.id,
            soNumber: o.soNumber,
            status: {
              text: soStatusMaptoText(o.status),
              color: soStatusMaptoColor(o.status),
            },
            invoices: _.isEmpty(o.lots) ? [] : o.lots.map((lotData) => (
              {
                id: lotData.invoice.id,
                lotIndex: lotData.lotIndex,
                invoiceNumber: lotData.invoice.number,
                itemsAmount: _.size(lotData.items),
                ptNumber: _.isEmpty(lotData.pickingTicket) ? '' : lotData.pickingTicket.number,
                subtotalAfterDiscount: lotData.subtotalAfterDiscount || 0,
                deliveryCost: lotData.deliveryCost || 0,
                discount: lotData.discount || 0,
                createDate: lotData.invoice.createDate,
                paymentDueDate: lotData.invoice.paymentDueDate,
              }
            )),
          }
        )),
      }
    ))

  const filteredSOList = (_.isEmpty(selectedLot) || _.isEmpty(customer)) ? soList : _.filter(soList, (o) => o.customer.id === customer.id)
  let count = 0
  const sumTotal = _.sum(selectedLot.map((v) => v.subtotalAfterDiscount)) + _.sum(selectedInvoice.map((v) => {
    const total = v.totalPriceBefore - v.totalPriceAfter
    if (v.type === 'CN') {
      return parseFloat(`-${total}`)
    }
    return Math.abs(total)
  }))

  useEffect(() => {
    if (_.isEmpty(variables?.paidDate)) setVariables({ ...variables, paymentDueDate: moment().add((customer?.creditTerm || 0), 'day') })
  }, [selectedLot, selectedInvoice])

  return (
    <Div>
      <Tab white title={[{
        text: 'สร้างใบวางบิล',
        icon: 'far fa-plus',
      }]}>
        <Div margin='0 0 24px'>
          <Div>
            <SmallText bold display='block' margin='0 0 8px'>รายการล็อตใน SO ที่ยังไม่สร้างใบวางบิล</SmallText>
            <Div margin='0 0 8px 0' display='flex' justifyContent='space-between'>
              <Input
                width='70%'
                placeholder='ค้นหาจากเลข SO หรือ PT'
                margin='0 16px 0 0'
                value={search}
                onChange={(e) => {
                  setSearch(e.target.value)
                }}
              />
              <Select
                value={customerId}
                loading={customerLoading}
                placeholder='เลือกลูกค้า'
                onSelect={(e) => setCustomerId(e)}
                onSearch={(e) => setName(e)}
                showSearch={true}
                notFoundContent={null}
                showArrow={false}
                filterOption={false}
                style={{ width: '30%' }}
                options={customerLoading ? [] : customerData.customerList.data.list.map((c) => ({ text: c.name, value: c.id }))}
              />
            </Div>
          </Div>
          <Div>
            {
              loading
                ? <Div margin='16px 0' textAlign='center'>
                  <Loading size='extraSmall' />
                </Div>
                : _.isEmpty(soList)
                  ? <MessagePage
                    margin='16px 0'
                    icon={{
                      icon: 'fal fa-times', fontSize: theme.fonts.size.title, color: theme.color.lightBlue, margin: '0 0 16px',
                    }}
                    text={MESSAGE.empty.list}
                    color={theme.color.lightBlue}
                    bold
                  />
                  : filteredSOList.map((v) => <SoDetail
                    key={v.id}
                    data={v}
                    selectedLot={selectedLot.map((lot) => lot.id)}
                    onChangeLot={(isCheck, value) => {
                      if (isCheck) {
                        const selectedSO = _.find(v.salesOrder, (o) => _.find(o.invoices, (d) => d.id === value))
                        const selectedInvoiceInSo = _.find(selectedSO.invoices, (d) => d.id === value)
                        setSelectedLot([...selectedLot, {
                          id: value,
                          invoiceNumber: selectedInvoiceInSo.invoiceNumber,
                          soNumber: selectedSO.soNumber,
                          ptNumber: selectedInvoiceInSo.ptNumber,
                          subtotalAfterDiscount: selectedInvoiceInSo.subtotalAfterDiscount,
                        }])
                      } else {
                        const newSelectedLot = _.remove(_.cloneDeep(selectedLot), (o) => o.id !== value)
                        setSelectedLot(newSelectedLot)
                        if (_.isEmpty(newSelectedLot) && _.isEmpty(selectedInvoice)) {
                          setCustomer()
                          setCustomerId()
                        }
                      }
                      if (_.isEmpty(selectedLot)) {
                        setCustomerId(v.id)
                        setCustomer(v.customer)
                      }
                    }}
                  />)
            }
            <Div margin='24px 0 0'>
              <SmallText bold display='block' margin='0 0 8px'>รายการใบแจ้งหนี้ที่ยังไม่สร้างใบวางบิล</SmallText>
              <Div height='285px' moreStyle={{ overflow: 'auto' }}>
                {
                  debtLoading
                    ? <Div margin='16px 0' textAlign='center'>
                      <Loading size='extraSmall' />
                    </Div>
                    : _.isEmpty(debtData.getBillableCreditNoteAndDebitNote.data)
                      ? <MessagePage
                        margin='16px 0'
                        icon={{
                          icon: 'fal fa-times', fontSize: theme.fonts.size.title, color: theme.color.lightBlue, margin: '0 0 16px',
                        }}
                        text={MESSAGE.empty.list}
                        color={theme.color.lightBlue}
                        bold
                      />
                      : debtData.getBillableCreditNoteAndDebitNote.data.map((v, i) => (
                        <DebtDisplay key={i} data={v} checked={!_.isEmpty(_.find(selectedInvoice, (o) => o.id === v.id))} onChange={(isChecked, id) => {
                          const newList = [...selectedInvoice]
                          if (isChecked) {
                            newList.push({
                              ...v,
                            })
                            setCustomer(v.customer)
                            setCustomerId(v.customer?.id)
                          } else {
                            _.remove(newList, (o) => o.id === id)
                            if (_.isEmpty(selectedLot) && _.isEmpty(newList)) {
                              setCustomer()
                              setCustomerId()
                            }
                          }
                          setSelectedInvoice(newList)
                        }} />
                      ))
                }
              </Div>
            </Div>
          </Div>
          {
            (!_.isEmpty(selectedLot) || !_.isEmpty(selectedInvoice))
              && <Div margin='36px 0 0 0'>
                <Divider />
                <SmallText bold>สรุปรายละเอียด</SmallText>
                <Row gutter={16} type='flex' style={{ margin: '16px -8px 8px -8px' }}>
                  <Col span={1} />
                  <Col span={4}>
                    <SmallTextWithLabel title={{ text: 'ชื่อลูกค้า' }} content={{ text: `${!_.isNil(customer.prefix) ? `${customer.prefix} ` : ''}${customer.name}` }} />
                  </Col>
                  <Col span={4}>
                    <SmallTextWithLabel title={{ text: 'ประเภทการชำระเงิน' }} content={{ text: customer.paymentType === PAYMENT_TYPE.COD ? 'ชำระเงินปลายทาง' : 'โอนเงิน' }} />
                  </Col>
                  <Col span={4}>
                    <SmallTextWithLabel title={{ text: 'ชื่อผู้ติดต่อ' }} content={{ text: selectedLot.contactAddress?.contactName || '-' }} />
                  </Col>
                  <Col span={4}>
                    <SmallTextWithLabel title={{ text: 'เบอร์ผู้ติดต่อ' }} content={{ text: selectedLot.contactAddress?.contactPhoneNumber || '-' }} />
                  </Col>
                  <Col span={4}>
                    <SmallTextWithLabel title={{ text: 'เบอร์สำนักงาน' }} content={{ text: selectedLot.contactAddress?.contactFaxNumber || '-' }} />
                  </Col>
                </Row>
                <Row gutter={16} type='flex' style={{ marginBottom: 8 }}>
                  <Col span={1} />
                  <Col span={4}>
                    <ComponentWithLabelSmall title={{ text: 'วันที่สร้าง' }}>
                      <DatePicker
                        value={variables.createDate}
                        onChange={(value) => setVariables({ ...variables, createDate: value })}
                        format='DD/MM/YYYY'
                      />
                    </ComponentWithLabelSmall>
                  </Col>
                  {
                    customer.paymentType === PAYMENT_TYPE.CREDIT
                      && <Col span={4}>
                        <SmallTextWithLabel title={{ text: 'จำนวนเครดิต' }} content={{ text: customer.creditTerm ? `${customer.creditTerm} วัน` : '-' }} />
                      </Col>
                  }
                  <Col span={4}>
                    <ComponentWithLabelSmall title={{ text: 'วันที่กำหนดชำระ' }}>
                      <DatePicker
                        value={variables.paymentDueDate}
                        onChange={(value) => setVariables({ ...variables, paymentDueDate: value })}
                        format='DD/MM/YYYY'
                      />
                    </ComponentWithLabelSmall>
                  </Col>
                </Row>
                <Div>
                  <Row gutter={16} type='flex' style={{ margin: '16px 0 8px 0' }}>
                    <Col span={1} />
                    <Col span={5}>
                      <Label bold>Invoice</Label>
                    </Col>
                    <Col span={5}>
                      <Label bold>เลขที่ SO</Label>
                    </Col>
                    <Col span={5}>
                      <Label bold>เลขที่ PT</Label>
                    </Col>
                    <Col span={5}>
                      <Label bold>ราคารวม</Label>
                    </Col>
                  </Row>
                  {
                    !_.isEmpty(selectedLot)
                      && selectedLot.map((v) => {
                        count += 1
                        return (
                          <Row key={v.id} gutter={16} type='flex' style={{ marginBottom: 8 }}>
                            <Col span={1}>
                              <SmallText>{count}.</SmallText>
                            </Col>
                            <Col span={5}>
                              <SmallText>#{v.invoiceNumber}</SmallText>
                            </Col>
                            <Col span={5}>
                              <SmallText>{v.soNumber}</SmallText>
                            </Col>
                            <Col span={5}>
                              <SmallText>{v.ptNumber}</SmallText>
                            </Col>
                            <Col span={5}>
                              <SmallText>{numeral(v.subtotalAfterDiscount).format('0,0.00')} บาท</SmallText>
                            </Col>
                          </Row>
                        )
                      })
                  }
                  {
                    !_.isEmpty(selectedInvoice)
                      && selectedInvoice.map((v) => {
                        count += 1
                        return (
                          <Row key={v.id} gutter={16} type='flex' style={{ marginBottom: 8 }}>
                            <Col span={1}>
                              <SmallText>{count}.</SmallText>
                            </Col>
                            <Col span={5}>
                              <SmallText color={v.type === 'DN' ? theme.color.success : theme.color.error}>#{v.number}</SmallText>
                            </Col>
                            <Col span={5}>
                              <SmallText>-</SmallText>
                            </Col>
                            <Col span={5}>
                              <SmallText>-</SmallText>
                            </Col>
                            <Col span={5}>
                              <SmallText>{numeral(v.totalPriceAfter - v.totalPriceBefore).format('0,0.00')} บาท</SmallText>
                            </Col>
                          </Row>
                        )
                      })
                  }
                </Div>
                <Divider />
                <Row gutter={16} type='flex'>
                  <Col span={24} style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <P bold margin='0 16px 0 0'>ยอดชำระ</P>
                    <P bold>{`${numeral(sumTotal).format('0,0.00')} บาท`}</P>
                  </Col>
                </Row>
                <TextareaWithLabel
                  margin='24px 0 0'
                  width='100%'
                  value={variables.remark}
                  onChange={(e) => setVariables({ ...variables, remark: e.target.value })}
                  title={{
                    text: 'หมายเหตุ',
                    margin: '0 0 8px',
                  }} textarea={{
                    style: { width: '100%' },
                    rows: 8,
                    margin: '16px 0 0',
                  }}
                />
                <Div margin='24px 0 0' width='100%' display='flex' justifyContent='space-between' alignItems='flex-start'>
                  <SmallText bold>แนบเอกสาร</SmallText>
                  <Uploader
                    isMultiple
                    buttonText='คลิกเพื่อเลือกไฟล์'
                    inverse={false}
                    icon='fal fa-paperclip'
                    onChange={(e) => setVariables({ ...variables, files: e })}
                    data={variables.files}
                  />
                </Div>
                <Div width='100%' display='flex' justifyContent='center' margin='16px 0'>
                  <Link to='/account'>
                    <Button text='ยกเลิก' icon='far fa-arrow-left' margin='0 16px 0 0' ghost disabled={creating}/>
                  </Link>
                  <Button
                    text='สร้างใบวางบิล'
                    icon='far fa-check'
                    disabled={creating || sumTotal <= 0}
                    loading={creating}
                    inverse
                    color={theme.color.success}
                    onClick={() => doCreate({
                      variables: {
                        ...variables,
                        list: _.concat(selectedLot.map((invoice) => ({ id: invoice.id, type: 'INVOICE' })), selectedInvoice.map((invoice) => ({ id: invoice.id, type: 'DEBT' }))),
                        createDate: moment(variables.createDate).format('YYYY-MM-DD'),
                        paymentDueDate: moment(variables.paymentDueDate).format('YYYY-MM-DD'),
                      },
                    }).then((res) => {
                      if (res.data.createBillingNote.success) {
                        message.success(res.data.createBillingNote.message)
                        history.push('/account/bill')
                      } else message.error(res.data.createBillingNote.message)
                    })}
                  />
                </Div>
              </Div>
          }
        </Div>
      </Tab>
    </Div>
  )
}

export default () => (<Form />)
