import { theme } from '../../styles/_variables'

export const PO_STATUS = [
  {
    key: 'WAITING_SUPPLIER',
    text: 'รอยืนยัน',
    color: theme.color.gray50,
  }, {
    key: 'SUPPLIER_CF',
    text: 'รอรับของ',
    color: theme.color.info,
  }, {
    key: 'PARTIAL_RECEIVED',
    text: 'รับของบางส่วน',
    color: theme.color.primaryColor,
  }, {
    key: 'COMPLETED',
    text: 'เสร็จสิ้น',
    color: theme.color.success,
  }, {
    key: 'CANCELLED',
    text: 'ยกเลิก',
    color: theme.color.error,
  }, {
    key: 'SHIPMENT_OVERDUE',
    text: 'เกินกำหนดการจัดส่ง',
    color: theme.color.error,
  }, {
    key: 'INACTIVE',
    text: 'inactive',
    color: theme.color.gray10,
  },
]

export const PO_STATUS_CONST = {
  WAITING_SUPPLIER: 'WAITING_SUPPLIER',
  SUPPLIER_CF: 'SUPPLIER_CF',
  PARTIAL_RECEIVED: 'PARTIAL_RECEIVED',
  COMPLETED: 'COMPLETED',
  CANCELLED: 'CANCELLED',
  SHIPMENT_OVERDUE: 'SHIPMENT_OVERDUE',
  INACTIVE: 'INACTIVE',
}

export default PO_STATUS
