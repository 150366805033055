import React, { cloneElement } from 'react'

// import providers
import {
  AccountantRemainingJobsProvider, AutorizationProvider, RemainingJobsProvider, RoleProvider, TaxRemainingJobsProvider,
} from './StoreProvider'

function ProviderComposer({ contexts, children }) {
  return contexts.reduce(
    (kids, parent) => cloneElement(parent, {
      children: kids,
    }),
    children,
  )
}
export default ({ children }) => (
  <ProviderComposer
    // add providers to array of contexts
    contexts={
      [
        <AccountantRemainingJobsProvider key='account-remaining-jobs-store' />,
        <AutorizationProvider key='autorization-store' />,
        <RemainingJobsProvider key='remaining-jobs-provider'/>,
        <RoleProvider key='role-provider'/>,
        <TaxRemainingJobsProvider key='tax-remaining-jobs-store' />,
      ]
    }
  >
    {children}
  </ProviderComposer>
)
