import { useMutation, useQuery, useSubscription } from '@apollo/client'
import { message, Modal } from 'antd'
import { Button, Div, Icon, Input, P, Checkbox } from '@konsys-ui-custom'
import _ from 'lodash'
import React, { useEffect, useRef, useState, useContext } from 'react'
import {
  ListWithoutTab,
  Loading,
  Select,
  ModalTrackingNumber,
} from '../../components'
import {
  PAYMENT_TYPE,
  TAX_INVOICE_STATUS,
  TAX_INVOICE_STATUS_ENUM,
} from '../../constants/enum'
import {
  UPDATE_IV,
  REJECT_INVOICE,
  CONFIRM_RECEIVE,
  UPDATE_EDIT_IV_NUMBER,
  TAX_UPDATE_TRACKING_NUMBER,
  CONFIRM_IV_NUMBER,
} from '../../api/mutation'
import {
  TAX_INVOICE,
  TAX_INVOICES,
  TAX_INVOICE_STATUS_COUNT_LIST,
} from '../../api/query'
import { TAX_SUBSCRIPTION } from '../../api/subscription'
import { INV_STATUS } from '../../constants/status/invoice'
import { theme } from '../../styles/_variables'
import { Detail as SCDetail } from '../account/debt/index'
import CCDetail from '../account/receipt/quick-view'
import { AuthorizationContext } from '../../store/StoreProvider'

const QueryDetail = ({
  id,
  modalVisible,
  setModalVisible,
  refetch,
  isEditModal,
  isConfirmModal,
}) => {
  const [ivNumber, setIvNumber] = useState('')
  const [rejectReason, setRejectReason] = useState('')
  const {
    data: invoiceInfoData,
    loading: invoiceInfoLoading,
    refetch: invoiceInfoRefetch,
  } = useQuery(TAX_INVOICE, { variables: { id }, skip: _.isUndefined(id) })
  const getData = invoiceInfoData?.taxInvoiceInfo?.data || {}
  const [updateIv, { loading }] = useMutation(UPDATE_IV)
  const [doRejectInvoice, { loading: rejectInvoiceLoading }] = useMutation(REJECT_INVOICE)
  const [doUpdateEditIv, { loading: updateEditIvLoading }] = useMutation(
    UPDATE_EDIT_IV_NUMBER,
  )
  const [doConfirmIv, { loading: confirmIvLoading }] = useMutation(CONFIRM_IV_NUMBER)

  useEffect(() => {
    if (!_.isEmpty(invoiceInfoData?.taxInvoiceInfo?.data)) {
      setIvNumber(invoiceInfoData?.taxInvoiceInfo?.data?.ivNumber)
    }
  }, [invoiceInfoData?.taxInvoiceInfo?.data])

  if (invoiceInfoLoading) {
    return (
      <Div textAlign="center" margin="32px 0 0">
        <Loading size="small" />
      </Div>
    )
  }

  const getResponse = (resp) => {
    if (!resp.success) message.error(resp.message)
    else {
      message.success(resp.message)
      setModalVisible({ ...modalVisible, ivNumber: false })
      invoiceInfoRefetch()
      refetch()
    }
  }

  const mutationVariables = isConfirmModal
    ? { id, ivNumber }
    : { id, number: ivNumber }
  const displayData = getData?.invoice || getData?.receipt
  const paymentType = _.find(
    PAYMENT_TYPE,
    (o) => o.value === displayData?.customer?.paymentType,
  )
  const scStatus = _.find(INV_STATUS, (o) => o.key === getData?.status)

  const {
    taxInvoiceType,
    ivNumber: getIvNumber,
    trackingNumber,
    providerName,
    pickingTicket,
    sales,
  } = invoiceInfoData?.taxInvoiceInfo?.data

  return (
    <Div>
      <Modal
        title={
          isConfirmModal
            ? 'ยืนยันเลข IV'
            : isEditModal
              ? 'แก้ไขเลข IV'
              : 'บันทึกเลข IV'
        }
        onCancel={() => setModalVisible({ ...modalVisible, ivNumber: false })}
        footer={[
          <Button
            small
            key="back"
            onClick={() => setModalVisible({ ...modalVisible, ivNumber: false })
            }
            text="ยกเลิก"
          />,
          <Button
            small
            loading={loading || updateEditIvLoading || confirmIvLoading}
            disabled={
              _.isEmpty(ivNumber)
              || loading
              || updateEditIvLoading
              || confirmIvLoading
            }
            key="submit"
            inverse
            onClick={() => {
              if (isConfirmModal) {
                doConfirmIv({
                  variables: mutationVariables,
                }).then((resp) => getResponse(resp.data.confirmIVNumber))
              } else if (isEditModal) {
                doUpdateEditIv({
                  variables: mutationVariables,
                }).then((resp) => getResponse(resp.data.updateNewIVNumber))
              } else {
                updateIv({
                  variables: mutationVariables,
                }).then((resp) => getResponse(resp.data.updateIVNumber))
              }
            }}
            text="ยืนยัน"
          />,
        ]}
        visible={modalVisible?.ivNumber}
      >
        <Input
          type="text"
          placeholder="กรอกเลข IV"
          value={ivNumber}
          onChange={(e) => setIvNumber(e.target.value)}
        />
      </Modal>
      <Modal
        title="ไม่อนุมัติ"
        onCancel={() => setModalVisible({ ...modalVisible, reject: false })}
        footer={[
          <Button
            small
            key="back"
            onClick={() => setModalVisible({ ...modalVisible, reject: false })}
            text="ยกเลิก"
          />,
          <Button
            small
            loading={loading}
            disabled={_.isEmpty(rejectReason) || rejectInvoiceLoading}
            key="submit"
            inverse
            onClick={() => {
              doRejectInvoice({
                variables: {
                  id,
                  reason: rejectReason,
                },
              }).then((resp) => {
                if (!resp.data.rejectTaxInvoice.success) { message.error(resp.data.rejectTaxInvoice.message) } else {
                  message.success(resp.data.rejectTaxInvoice.message)
                  setModalVisible({ ...modalVisible, reject: false })
                  refetch()
                }
              })
            }}
            text="ยืนยัน"
          />,
        ]}
        visible={modalVisible?.reject}
      >
        <Input
          type="text"
          placeholder="กรอกเหตุผล"
          value={rejectReason}
          onChange={(e) => setRejectReason(e.target.value)}
        />
      </Modal>
      {getData?.invoice ? (
        <SCDetail
          invoiceDetail={{
            invoiceDetail: {
              data: {
                taxInvoiceType,
                ivNumber: getIvNumber,
                trackingNumber,
                providerName,
                pickupSale: sales,
                ...displayData,
                pickingTicket,
              },
            },
          }}
          status={scStatus}
          customer={displayData?.customer}
          customerPaymentType={paymentType}
          isTaxInvoice={true}
        />
      ) : (
        <CCDetail
          receiptData={{
            receiptInfo: {
              data: {
                taxInvoiceType,
                ivNumber: getIvNumber,
                trackingNumber,
                providerName,
                pickupSale: sales,
                ...displayData,
                pickingTicket,
              },
            },
          }}
        />
      )}
    </Div>
  )
}

const orderBy = [
  { text: 'เรียงจาก: ล่าสุด ไป อดีต', value: 'DESC' },
  { text: 'เรียงจาก: อดีต ไป ล่าสุด', value: 'ASC' },
]

const orderField = [
  { text: 'กรองจาก: วันที่สร้าง', value: 'createDate' },
  { text: 'กรองจาก: วันที่กำหนดชำระ', value: 'paymentDueDate' },
]

const filterStatusList = [
  { text: 'ทั้งหมด', value: undefined, count: 0 },
  {
    text: TAX_INVOICE_STATUS.WAITING_TAXINVOICE,
    value: TAX_INVOICE_STATUS_ENUM.WAITING_TAXINVOICE,
    count: 0,
  },
  {
    text: TAX_INVOICE_STATUS.TAXINVOICED,
    value: TAX_INVOICE_STATUS_ENUM.TAXINVOICED,
    count: 0,
  },
  {
    text: TAX_INVOICE_STATUS.RECEIVED,
    value: TAX_INVOICE_STATUS_ENUM.RECEIVED,
    count: 0,
  },
  {
    text: TAX_INVOICE_STATUS.WAITING_DELIVER,
    value: TAX_INVOICE_STATUS_ENUM.WAITING_DELIVER,
    count: 0,
  },
  {
    text: TAX_INVOICE_STATUS.WAITING_NEXT_DELIVER,
    value: TAX_INVOICE_STATUS_ENUM.WAITING_NEXT_DELIVER,
    count: 0,
  },
  {
    text: TAX_INVOICE_STATUS.DELIVERED,
    value: TAX_INVOICE_STATUS_ENUM.DELIVERED,
    count: 0,
  },
  {
    text: TAX_INVOICE_STATUS.WAITING_IV_CONFIRM,
    value: TAX_INVOICE_STATUS_ENUM.WAITING_IV_CONFIRM,
    count: 0,
  },
  {
    text: TAX_INVOICE_STATUS.REJECTED,
    value: TAX_INVOICE_STATUS_ENUM.REJECTED,
    count: 0,
  },
]
/*
  props
  - status: object contains default status value for filter and isFixed
  - focusId: it's a tax-invoice id that have to selected when this list rendered
*/
export const TaxInvoices = ({ status, isSaleRole }) => {
  const [list, setList] = useState([])
  const { authorization } = useContext(AuthorizationContext)
  const [modalVisible, setModalVisible] = useState({
    ivNumber: false,
    reject: false,
    confirm: false,
  })
  const [filterLoading, setFilterLoading] = useState(false)
  const [firstLoad, setFirstLoad] = useState(true)
  const [statusList, setStatusList] = useState(filterStatusList)
  const filterInitialValue = {
    searchText: '',
    selectedOrderBy: orderBy[1].value,
    selectedOrderField: orderField[0].value,
    status: status?.value,
  }
  const [filterVariables, setFilterVariables] = useState(filterInitialValue)
  const [currentCard, setCurrentCard] = useState(0)
  const [selectedComfirmList, setSelectedConfirmList] = useState([])
  const [isEditModal, setIsEditModal] = useState(false)
  const [isConfirmModal, setIsConfirmModal] = useState(false)
  const isInitialMount = useRef(true)
  const { data: taxInvoiceStatus, refetch: refetchStatus } = useQuery(
    TAX_INVOICE_STATUS_COUNT_LIST,
  )
  const {
    data: taxListData,
    fetchMore,
    refetch,
  } = useQuery(TAX_INVOICES, {
    variables: {
      limit: 30,
      offset: 0,
      status: filterVariables?.status,
    },
  })
  const [delivery, setDelivery] = useState([
    { id: 1, trackingNumber: '', deliveryProvider: null },
  ])
  const [doUpdateTrackingNumber, { loading: updateTrackingNumberLoading }] = useMutation(TAX_UPDATE_TRACKING_NUMBER)
  const [doConfirmReceive, { loading: confirmReceiveLoading }] = useMutation(CONFIRM_RECEIVE)

  const { data: taxInvoiceData } = useSubscription(TAX_SUBSCRIPTION)

  const confirmReceive = (isMultiple, variable) => {
    doConfirmReceive({
      variables: {
        taxInvoiceIds: isMultiple ? selectedComfirmList : variable,
      },
    }).then((resp) => {
      if (!resp.data.confirmReceive.success) { message.error(resp.data.confirmReceive.message) } else {
        message.success(resp.data.confirmReceive.message)
        if (isMultiple) setModalVisible({ ...modalVisible, confirm: false })
        refetch()
      }
    })
  }

  const getButtonList = (idVariable, statusVariable) => {
    let defaultButton = []
    if (
      _.includes(
        [
          TAX_INVOICE_STATUS_ENUM.WAITING_TAXINVOICE,
          TAX_INVOICE_STATUS_ENUM.WAITING_IV_CONFIRM,
        ],
        statusVariable,
      )
    ) {
      defaultButton = [
        ...defaultButton,
        <Button
          inverse
          color={theme.color.success}
          key="iv number"
          small
          icon="fal fa-edit"
          margin="4px"
          text={
            statusVariable === TAX_INVOICE_STATUS_ENUM.WAITING_IV_CONFIRM
              ? 'กรอกเลข IV เพื่อยืนยัน'
              : 'บันทึกเลข IV'
          }
          onClick={() => {
            setModalVisible({ ...modalVisible, ivNumber: true })
            if (statusVariable === TAX_INVOICE_STATUS_ENUM.WAITING_IV_CONFIRM) {
              setIsConfirmModal(true)
            }
            setIsEditModal(false)
          }}
        />,
      ]
    }
    if (isSaleRole && statusVariable === TAX_INVOICE_STATUS_ENUM.TAXINVOICED) {
      if (statusVariable === TAX_INVOICE_STATUS_ENUM.TAXINVOICED) {
        defaultButton = [
          ...defaultButton,
          <Button
            margin="4px"
            key="confirm"
            color={theme.color.success}
            inverse
            small
            icon="fal fa-check"
            text="รับใบกำกับภาษีแล้ว"
            disabled={confirmReceiveLoading}
            loading={confirmReceiveLoading}
            onClick={() => confirmReceive(false, [idVariable])}
          />,
        ]
      }
    }
    if (
      authorization?.taxInvoice?.editIVNumber
      && statusVariable !== TAX_INVOICE_STATUS_ENUM.WAITING_TAXINVOICE
      && statusVariable !== TAX_INVOICE_STATUS_ENUM.WAITING_IV_CONFIRM
      && statusVariable !== TAX_INVOICE_STATUS_ENUM.REJECTED
    ) {
      defaultButton = [
        <Button
          margin="4px"
          key="edit-iv"
          small
          icon="fal fa-check"
          text="แก้ไข IV Number"
          onClick={() => {
            setModalVisible({ ...modalVisible, ivNumber: true })
            setIsEditModal(true)
            setIsConfirmModal(false)
          }}
        />,
        ...defaultButton,
      ]
    }
    if (statusVariable === TAX_INVOICE_STATUS_ENUM.WAITING_DELIVER) {
      defaultButton = [
        ...defaultButton,
        <Div key="tracking-number">
          <ModalTrackingNumber
            title="กรอก Tracking Number"
            taxInvoice
            showModal={modalVisible?.trackingNumber}
            onCancel={() => setModalVisible({ ...modalVisible, trackingNumber: false })
            }
            delivery={delivery}
            setDelivery={(value) => setDelivery(value)}
            doRecordDelivery={() => {
              doUpdateTrackingNumber({
                variables: {
                  id: idVariable,
                  providerName:
                    delivery[0]?.deliveryProvider
                    || delivery[0]?.newDeliveryProvider,
                  trackingNumber: delivery[0]?.trackingNumber,
                },
              }).then((resp) => {
                if (resp.data?.updateTrackingNumber?.success) {
                  setModalVisible({ ...modalVisible, trackingNumber: false })
                  message.success(resp.data?.updateTrackingNumber?.message)
                  refetch({ status: filterVariables?.status })
                } else {
                  message.error(resp.data?.updateTrackingNumber?.message)
                }
              })
            }}
            recordDeliveryLoading={updateTrackingNumberLoading}
            id={idVariable}
          />
          <Button
            margin="4px"
            color={theme.color.success}
            inverse
            small
            icon="fal fa-parcel"
            text="กรอก Tracking Number"
            onClick={() => setModalVisible({ ...modalVisible, trackingNumber: true })
            }
          />
        </Div>,
      ]
    }
    if (statusVariable === TAX_INVOICE_STATUS_ENUM.WAITING_TAXINVOICE) {
      defaultButton = [
        ...defaultButton,
        <Button
          margin="4px"
          color={theme.color.error}
          key="ไม่อนุมัติ"
          small
          icon="fal fa-times"
          text="ไม่อนุมัติ"
          onClick={() => setModalVisible({ ...modalVisible, reject: true })}
        />,
      ]
    }
    return defaultButton
  }

  const getRenderList = (mappedList) => mappedList?.map((v) => ({
    title: v.number,
    status: {
      key: v?.status,
      text: TAX_INVOICE_STATUS[v?.status],
      color: _.includes(
        [
          TAX_INVOICE_STATUS_ENUM.WAITING_DELIVER,
          TAX_INVOICE_STATUS_ENUM.WAITING_IV_CONFIRM,
          TAX_INVOICE_STATUS_ENUM.WAITING_TAXINVOICE,
          TAX_INVOICE_STATUS_ENUM.REJECTED,
        ],
        v?.status,
      )
        ? theme.color.error
        : _.includes(
          [
            TAX_INVOICE_STATUS_ENUM.WAITING_NEXT_DELIVER,
            TAX_INVOICE_STATUS_ENUM.WAITING_NEXT_DELIVER,
          ],
          v?.status,
        )
          ? theme.color.info
          : _.includes(
            [
              TAX_INVOICE_STATUS_ENUM.RECEIVED,
              TAX_INVOICE_STATUS_ENUM.DELIVERED,
            ],
            v?.status,
          ) && theme.color.success,
    },
    newCard: v?.isDataFormSubscription,
    detail: {
      title: [
        {
          id: 'view',
          text: 'รายละเอียด',
          icon: 'fal fa-info-square',
        },
      ],
      content: [
        <QueryDetail
          key="invoice-detail"
          isConfirmModal={isConfirmModal}
          isEditModal={isEditModal}
          id={v.id}
          modalVisible={modalVisible}
          setModalVisible={(value) => setModalVisible(value)}
          refetch={() => {
            refetch()
            refetchStatus()
          }}
        />,
      ],
      buttonList: getButtonList(v?.id, v?.status),
    },
  })) || []

  useEffect(() => {
    setList(taxListData?.taxInvoiceList?.data?.list || [])
  }, [taxListData])

  const isApplySubscription = taxInvoiceData?.taxInvoiceChangeSub?.data?.status
    === filterVariables?.status
  useEffect(() => {
    if (
      !_.isEmpty(taxInvoiceData?.taxInvoiceChangeSub?.data)
      && isApplySubscription
    ) {
      const subscripList = _.isEmpty(taxInvoiceData?.taxInvoiceChangeSub?.data)
        ? []
        : [
          {
            ...taxInvoiceData?.taxInvoiceChangeSub?.data,
            isDataFormSubscription: true,
          },
        ]
      setCurrentCard(currentCard + 1)
      setList([...subscripList, ...list])
    }
  }, [taxInvoiceData])

  useEffect(() => {
    if (
      !_.isEmpty(taxInvoiceData?.taxInvoiceChangeSub?.data)
      && currentCard > 0
    ) {
      setCurrentCard(currentCard - 1)
    }
  }, [filterVariables?.status])

  useEffect(() => {
    setFilterVariables({
      ...filterVariables,
      status: status?.value,
    })
  }, [status])

  useEffect(() => {
    if (!_.isEmpty(taxInvoiceStatus?.taxInvoiceStatusList?.data)) {
      setStatusList(
        filterStatusList.map((taxInvoiceFilterStatus) => ({
          ...taxInvoiceFilterStatus,
          count: _.find(
            taxInvoiceStatus?.taxInvoiceStatusList?.data,
            (taxInvoiceStatusFromAPI) => taxInvoiceStatusFromAPI.statusName
              === taxInvoiceFilterStatus.value,
          )?.count,
          text: (
            <P
              color={
                taxInvoiceFilterStatus.value
                  === TAX_INVOICE_STATUS_ENUM.WAITING_TAXINVOICE
                && theme.color.error
              }
            >
              {`${taxInvoiceFilterStatus?.text} ${
                !_.isNil(taxInvoiceFilterStatus.value)
                  ? `(${
                      _.find(
                        taxInvoiceStatus?.taxInvoiceStatusList?.data,
                        (taxInvoiceStatusFromAPI) => taxInvoiceStatusFromAPI.statusName
                          === taxInvoiceFilterStatus.value,
                      )?.count
                    })`
                  : ''
              }`}
            </P>
          ),
        })),
      )
    }
  }, [taxInvoiceStatus])

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false
    } else {
      setFirstLoad(false)
      setFilterLoading(true)
      refetch({
        search: filterVariables?.searchText,
        orderField: filterVariables?.selectedOrderField,
        orderBy: filterVariables?.selectedOrderBy,
      }).then(() => {
        setFilterLoading(false)
      })
    }
  }, [
    filterVariables?.searchText,
    filterVariables?.selectedOrderField,
    filterVariables?.selectedOrderBy,
    filterVariables?.status,
  ])

  return (
    <ListWithoutTab
      listLoading={
        filterLoading
        || (firstLoad && _.isEmpty(taxInvoiceStatus?.taxInvoiceStatusList?.data))
      }
      currentCard={currentCard}
      setCurrentCard={(value) => setCurrentCard(value)}
      list={getRenderList(list)}
      filterHeight={100}
      filter={[
        <Div key={0} margin="0 0 24px">
          <Div display="flex" alignItems="flex-start">
            <Div moreStyle={{ flexGrow: 1 }} margin="0 16px 0 0">
              <Input
                type="text"
                width="100%"
                prefix={<Icon icon="prefix fal fa-search" />}
                placeholder="ค้นหาจาก IV number, ชื่อลูกค้า, SO number, ชื่อสินค้า, รหัสสินค้า, ใบสั่งซื้อ, GR number"
                value={filterVariables?.searchText}
                onChange={(e) => setFilterVariables({
                  ...filterVariables,
                  searchText: e.target.value,
                })
                }
              />
            </Div>
            {!status?.isFixed && (
              <Select
                style={{ width: '100%', flexBasis: '22%' }}
                suffixIcon={<Icon icon="fal fa-chevron-down" />}
                placeholder="กรองสถานะ"
                options={statusList}
                value={filterVariables?.status}
                onChange={(value) => setFilterVariables({ ...filterVariables, status: value })
                }
              />
            )}
          </Div>
          <Div display="flex" margin="8px 0 0" justifyContent="flex-end">
            <Div display="flex" moreStyle={{ flexGrow: 1 }}>
              <Select
                value={filterVariables?.selectedOrderField}
                style={{
                  width: '30%',
                  minWidth: '100px',
                  margin: '0 16px 0 0',
                }}
                options={orderField}
                onChange={(value) => setFilterVariables({
                  ...filterVariables,
                  selectedOrderField: value,
                })
                }
              />
              <Select
                value={filterVariables?.selectedOrderBy}
                style={{ width: '30%', minWidth: '100px' }}
                options={orderBy}
                onChange={(value) => setFilterVariables({
                  ...filterVariables,
                  selectedOrderBy: value,
                })
                }
              />
              {isSaleRole && (
                <Button
                  text="ล้าง"
                  icon="fal fa-sync"
                  small
                  ghost
                  margin="0 0 0 32px"
                  onClick={() => {
                    setFilterVariables(filterInitialValue)
                    setFilterLoading(true)
                  }}
                />
              )}
            </Div>
            {!isSaleRole ? (
              <Button
                text="ล้าง"
                icon="fal fa-sync"
                small
                ghost
                onClick={() => {
                  setFilterVariables(filterInitialValue)
                  setFilterLoading(true)
                }}
              />
            ) : (
              !_.isEmpty(taxListData?.taxInvoiceList?.data?.list) && (
                <Div>
                  <Modal
                    title="เลือกใบกำกับภาษี ที่รับแล้ว"
                    onCancel={() => setModalVisible({ ...modalVisible, confirm: false })
                    }
                    footer={[
                      <Button
                        small
                        key="back"
                        onClick={() => setModalVisible({ ...modalVisible, confirm: false })
                        }
                        text="ยกเลิก"
                      />,
                      <Button
                        small
                        key="submit"
                        loading={confirmReceiveLoading}
                        disabled={
                          _.isEmpty(selectedComfirmList)
                          || confirmReceiveLoading
                        }
                        inverse
                        onClick={() => confirmReceive(true)}
                        text="ยืนยันรับใบกำกับภาษีทั้งหมดที่เลือก"
                      />,
                    ]}
                    visible={modalVisible?.confirm}
                  >
                    <Div
                      display="flex"
                      flexDirection="column"
                      moreStyle={{ maxHeight: '270px', overflow: 'scroll' }}
                    >
                      {taxListData?.taxInvoiceList?.data?.list?.map((obj) => (
                        <Checkbox
                          key={obj?.id}
                          data={{ value: obj?.id, text: obj?.number }}
                          margin="0 0 8px"
                          onChange={(e) => setSelectedConfirmList([
                            ...selectedComfirmList,
                            parseInt(e.target.value),
                          ])
                          }
                        />
                      ))}
                    </Div>
                  </Modal>
                  <Button
                    disabled={modalVisible?.confirm || confirmReceiveLoading}
                    text="ยืนยันใบกำกับภาษีที่รับแล้ว"
                    icon="fal fa-check"
                    small
                    inverse
                    onClick={() => setModalVisible({ ...modalVisible, confirm: true })
                    }
                  />
                </Div>
              )
            )}
          </Div>
        </Div>,
      ]}
      onInfiniteScroll={() => {
        fetchMore({
          variables: {
            offset: taxListData?.taxInvoiceList?.data?.list?.length,
          },
          updateQuery: (prev, { fetchMoreResult }) => {
            if (!fetchMoreResult) return prev
            return { ...prev,
              taxInvoiceList: {
                ...prev.taxInvoiceList,
                data: {
                  ...prev.taxInvoiceList.data,
                  total: prev.taxInvoiceList.data.total,
                  list: [
                    ...prev.taxInvoiceList.data.list,
                    ...fetchMoreResult.taxInvoiceList.data.list,
                  ],
                },
              } }
          },
        })
      }}
    />
  )
}

export default TaxInvoices
