import React, {
  useState, useEffect, useRef, useContext,
} from 'react'
import moment from 'moment-timezone'
import {
  Upload, message, Modal,
} from 'antd'
import numeral from 'numeral'
import axios from 'axios'
import FileDownload from 'js-file-download'
import { Link } from 'react-router-dom'
import { useQuery, useMutation } from '@apollo/client'
import _ from 'lodash'
import {
  Div, Button, Icon, Input, SmallText, P,
} from '@konsys-ui-custom'
import { Helmet } from 'react-helmet'
import {
  SUPPLIERS, INVENTORIES,
} from '../../api/query'
import { IMPORT_INVENTORY } from '../../api/mutation'
import { ListContainer, Select, LargeTextWithLabel } from '../../components'
import QuickView from './quick-view'
import History from './history'
import AdjustStock from './adjust-stock'
import TransferStock from './transfer-stock'
import Convert from './convert'
import { theme } from '../../styles/_variables'
import { AuthorizationContext } from '../../store/StoreProvider'
import { getAccessToken } from '../../utils/local-data-service'
import URL from '../../constants/environment'
import { delayInput } from '../../utils'

const KEY = 'inventoryList'

const statusOption = [{ value: 'ACTIVE', text: 'เปิดการใช้งาน' }, { value: 'INACTIVE', text: 'ปิดการใช้งาน' }]

export default (props) => {
  const [searchText, setSearchText] = useState('')
  const [supplierId, setSupplierId] = useState()
  const [status, setStatus] = useState()
  const [filterLoading, setFilterLoading] = useState(false)
  const [fetchMoreLoading, setFetchMoreLoading] = useState(false)
  const [currentTab, setCurrentTab] = useState(0)
  const isInitialMount = useRef(true)
  const { authorization } = useContext(AuthorizationContext)
  const {
    loading: supLoading, data: supData, refetch: supRefetch,
  } = useQuery(SUPPLIERS)
  const {
    data, loading, fetchMore, refetch,
  } = useQuery(INVENTORIES, { variables: { limit: 10, offset: 0 } })
  const [orderField, setOrderField] = useState('createdAt')
  const [orderBy, setOrderby] = useState('DESC')
  const [downloadLoading, setDownloadLoading] = useState(false)
  const [doImportFile] = useMutation(IMPORT_INVENTORY)
  const [isOpenModal, setIsOpenModal] = useState(false)
  const [importData, setImportData] = useState({})
  const [importFile, setImportFile] = useState()
  const [confirmLoading, setConfirmLoading] = useState(false)

  const mapped = (
    (!loading || !_.isEmpty(data)) && data[KEY]?.data?.list?.map((v) => ({
      id: v.id,
      title: v.sku,
      content: {
        one: {
          title: {
            text: 'ชื่อสินค้า',
          },
          content: {
            text: v.productName || 'ไม่ระบุ',
            bold: false,
          },
        },
        three: {
          title: {
            text: 'จำนวนที่พร้อมขาย',
          },
          content: {
            text: `${(v.totalStock || 0) - (v.soldStock || 0)} (${v.toBeStocked >= 0 ? '+' : ''}${v.toBeStocked || 0})`,
          },
        },
      },
      detail: {
        title: _.filter([
          {
            id: 'view',
            text: 'รายละเอียด',
            icon: 'fad fa-info-square',
          }, {
            id: 'adjustStock',
            text: 'แก้ไขสต๊อก',
            icon: 'fad fa-cubes',
          }, {
            id: 'transfer',
            text: 'ย้ายคลัง',
            icon: 'fad fa-exchange',
          }, {
            id: 'pairProduct',
            text: 'แปลงหน่วย',
            icon: 'fad fa-retweet-alt',
          }, {
            id: 'viewHistoryLog',
            text: 'ประวัติสินค้า',
            icon: 'fad fa-history',
          },
        ], (o) => authorization.inventory[o.id] || (o.id === 'viewHistoryLog' && (authorization.inventory.viewHistoryLog || authorization.inventory.viewCostChangeLog || authorization.inventory.viewStockLog))),
        buttonList: [
          authorization.inventory.edit && <Link key='edit' to={`${props.match.url}/edit/${v.id}`}>
            <Button small ghost margin='4px 8px 4px 0' text='แก้ไขสินค้า' icon='far fa-edit' />
          </Link>,
        ],
        content: _.filter([
          <QuickView key='view' data={v} />,
          <AdjustStock key='adjustStock' data={v} refetch={refetch} />,
          <TransferStock key='transfer' data={v} refetch={refetch} />,
          <Convert key='pairProduct' data={v} limit={data[KEY].data.list.length} refetch={refetch} />,
          <History
            key='viewHistoryLog'
            data={v}
          />,
        ], (o) => authorization.inventory[o.key] || (o.key === 'viewHistoryLog' && (authorization.inventory.viewHistoryLog || authorization.inventory.viewCostChangeLog || authorization.inventory.viewStockLog))),
      },
    }))
  ) || []

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false
    } else {
      setFilterLoading(true)
      refetch({
        search: searchText,
        supplierId,
        status,
        orderBy,
        orderField,
      }).then(() => {
        setFilterLoading(false)
      })
    }
  }, [searchText, supplierId, status, orderField, orderBy])

  const download = () => {
    setDownloadLoading(true)
    axios({
      method: 'GET',
      responseType: 'blob',
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
      },
      url: `${URL.FILE_SERVER}/export/inventory`,
    }).then((resp) => {
      FileDownload(resp.data, `สต็อคสินค้า ณ วันที่ ${moment().format('DD/MM/YYYY')}.xlsx`)
      setDownloadLoading(false)
    })
  }

  return (
    <Div>
      <Helmet>
        <meta name="viewport" content="width=1280" />
      </Helmet>
      <ListContainer
        onInfiniteScroll={() => {
          setFetchMoreLoading(true)
          fetchMore({
            variables: {
              offset: data[KEY].data.list.length,
            },
            updateQuery: (prev, { fetchMoreResult }) => {
              setFetchMoreLoading(false)
              if (!fetchMoreResult) return prev
              return { ...prev,
                [KEY]: {
                  ...fetchMoreResult[KEY],
                  data: {
                    ...fetchMoreResult[KEY].data,
                    list: [...prev[KEY].data.list, ...fetchMoreResult[KEY].data.list],
                  },
                } }
            },
          })
        }}
        currentTab={currentTab}
        setCurrentTab={setCurrentTab}
        tabLoading={fetchMoreLoading}
        listLoading={filterLoading || (loading && _.isEmpty(data))}
        title={[
          {
            text: 'สต๊อกสินค้า',
            icon: 'fad fa-warehouse',
          },
        ]}
        filterHeight={100}
        filter={[
          <Div margin='0 0 24px' key={0}>
            <Div display='flex' alignItems='flex-start' moreStyle={{ flexGrow: 1 }}>
              <Input
                type='text'
                width='100%'
                prefix={<Icon icon='prefix fal fa-search' />}
                placeholder='ค้นหาชื่อสินค้า, รหัสสินค้า, รหัสอ้างอิง, Barcode'
                onChange={(e) => {
                  const searchValue = e.target.value
                  delayInput(() => {
                    setSearchText(searchValue)
                  })
                }}
                margin='0 16px 0 0'
                suffix={(!loading && !_.isEmpty(data?.inventoryList?.data) && !_.isEmpty(searchText)) && <Div position='absolute' right='16px' top='50%' moreStyle={{ transform: 'translateY(-50%)' }}><SmallText>{numeral(data?.inventoryList?.data?.total || 0).format('0,0')} รายการ</SmallText></Div>}
              />
              <Select
                showSearch={true}
                style={{ width: '50%', margin: '0 16px 0 0' }}
                loading={supLoading}
                placeholder='Supplier'
                onSearch={(value) => supRefetch({ variables: { name: value } })}
                options={supLoading && !supData ? [] : supData.supplierList.data.list.map((v) => ({ value: v.id, text: v.name }))}
                onChange={(value) => setSupplierId(value)}
                value={supplierId}
                showArrow={false}
                filterOption={false}
              />
              <Select
                style={{ width: '50%', margin: '0 0 0 0' }}
                placeholder='สถานะสินค้า'
                options={statusOption}
                onChange={(value) => setStatus(value)}
                value={status}
              />
            </Div>
            <Div display='flex' margin='8px 0 0'>
              <Div display='flex' moreStyle={{ flexGrow: 1 }} margin='0 56px 0 0'>
                <Select
                  defaultValue='createdAt'
                  onChange={(value) => setOrderField(value)}
                  value={orderField}
                  style={{ width: '41.65%', minWidth: '200px', margin: '0 16px 0 0' }}
                  options={[{ text: 'เรียงตามวันที่สร้าง', value: 'createdAt' }, { text: 'เรียงตามวันที่อัพเดท', value: 'updatedAt' }]}
                />
                <Select
                  suffixIcon={<Icon icon={orderBy === 'ASC' ? 'fal fa-sort-amount-down-alt' : 'fal fa-sort-amount-up-alt'} />}
                  defaultValue='DESC'
                  value={orderBy}
                  onChange={(value) => setOrderby(value)}
                  options={[{ text: 'น้อยไปมาก', value: 'ASC' }, { text: 'มากไปน้อย', value: 'DESC' }]}
                />
              </Div>
              <Div display='flex' alignItems='center'>
                <Button text='ล้าง' icon='fal fa-sync' small ghost onClick={() => {
                  setSearchText('')
                  setSupplierId()
                  setStatus()
                  setOrderField('createdAt')
                  setOrderby('DESC')
                  setFilterLoading(true)
                  refetch({
                    search: '',
                    supplierId: undefined,
                    productGroup: undefined,
                    category: undefined,
                    brand: undefined,
                    status: undefined,
                    orderBy: 'DESC',
                    orderField: 'createdAt',
                  }).then(() => {
                    setFilterLoading(false)
                  })
                }} />
              </Div>
            </Div>
          </Div>,
        ]}
        list={mapped}
        buttonList={[
          authorization.report.all && <Div key='import' display='inline-block'>
            <Modal
              title='สินค้าที่นำเข้า'
              visible={isOpenModal}
              okText='ยืนยัน เพิ่มสินค้าใหม่'
              cancelText='ยกเลิกการนำเข้า'
              okButtonProps={{
                color: theme.color.success,
                loading: confirmLoading,
              }}
              cancelButtonProps={{
                disabled: confirmLoading,
              }}
              onOk={() => {
                setConfirmLoading(true)
                doImportFile({
                  variables: {
                    file: importFile,
                    isConfirm: true,
                  },
                }).then((response) => {
                  setConfirmLoading(false)
                  if (response.data.importExcelInventory.success) {
                    setIsOpenModal(false)
                    message.success(response.data.importExcelInventory.message)
                  } else {
                    message.error(response.data.importExcelInventory.message)
                  }
                })
              }}
              onCancel={() => {
                setIsOpenModal(false)
              }}
            >
              <Div display='flex'>
                <Div margin='0 32px 0 0'>
                  <P display='block' bold margin='0 0 8px'>Variant</P>
                  <Div display='flex' alignItems='flex-start'>
                    <LargeTextWithLabel title={{ text: 'จำนวนที่เพิ่ม' }} margin='0 16px 0 0' content={{ text: importData?.variant?.create || '-' }} />
                    <LargeTextWithLabel title={{ text: 'จำนวนที่อัพเดต' }} content={{ text: importData?.variant?.update || '-' }} />
                  </Div>
                </Div>
                <Div>
                  <P display='block' bold margin='0 0 8px'>Pack</P>
                  <Div display='flex' alignItems='flex-start'>
                    <LargeTextWithLabel title={{ text: 'จำนวนที่เพิ่ม' }} margin='0 16px 0 0' content={{ text: importData?.pack?.create || '-' }} />
                    <LargeTextWithLabel title={{ text: 'จำนวนที่อัพเดต' }} content={{ text: importData?.pack?.update || '-' }} />
                  </Div>
                </Div>
              </Div>
            </Modal>
            <Upload accept='.xlsx' customRequest={() => null} showUploadList={false} onChange={(info) => {
              setImportFile(info.file.originFileObj)
              setDownloadLoading(true)
              doImportFile({
                variables: {
                  file: info.file.originFileObj,
                },
              }).then((resp) => {
                setDownloadLoading(false)
                if (resp.data.importExcelInventory.success) {
                  setIsOpenModal(true)
                  setImportData(resp.data.importExcelInventory.data)
                } else {
                  message.error(resp.data.importExcelInventory.message)
                }
              })
            }}>
              <Button disabled={downloadLoading} loading={downloadLoading} small ghost margin='4px 8px 4px 0' text='Import' icon='fal fa-upload' />
            </Upload>
          </Div>,
          authorization.report.all && <Button key='export' disabled={downloadLoading} loading={downloadLoading} small ghost margin='4px 8px 4px 0' text='Export' icon='fal fa-download' onClick={() => download()} />,
          authorization.inventory.createBasic && <Link key='create' to={`${props.match.url}/create`}>
            <Button small ghost inverse color={theme.color.success} margin='4px 8px 4px 0' text='เพิ่มสินค้าใหม่' icon='far fa-plus' />
          </Link>,
        ]}
      >
      </ListContainer>
    </Div>
  )
}
