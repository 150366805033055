import { PlusOutlined } from '@ant-design/icons'
import { useLazyQuery, useMutation, useQuery } from '@apollo/client'
import { Button as ButtonAntd, Drawer, Input as InputAntd } from 'antd'
import {
  Button,
  Div,
  Icon,
  Input,
  SmallText,
} from '@konsys-ui-custom'
import _, { isEmpty, isNil, some, toNumber } from 'lodash'
import { nanoid } from 'nanoid'
import numeral from 'numeral'
import React, {
  useContext,
  useEffect, useRef,
  useState,
} from 'react'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'
import { UPDATE_TEAM_LIST } from '../../api/mutation'
import { ROLES, TEAM_LIST, USERS } from '../../api/query'
import { ListContainer, Loading, Select } from '../../components'
import History from '../../components/common/history'
import { AuthorizationContext } from '../../store/StoreProvider'
import { theme } from '../../styles/_variables'
import { delayInput, showMessageMutation } from '../../utils'
import { STATUS } from './constants'
import QuickView from './quick-view'

const KEY = 'userList'
const statusOption = [{ value: undefined, text: 'ทั้งหมด' }, { value: STATUS.ACTIVE, text: 'เปิดการใช้งาน' }, { value: STATUS.INACTIVE, text: 'ปิดการใช้งาน' }]

export default (props) => {
  const [name, setName] = useState('')
  const [status, setStatus] = useState()
  const [openManageTeam, setOpenManageTeam] = useState(false)
  const [manageTeams, setManageTeams] = useState([])
  const [role, setRole] = useState()
  const [filterLoading, setFilterLoading] = useState(false)
  const [orderField, setOrderField] = useState('createdAt')
  const [orderBy, setOrderby] = useState('DESC')
  const [fetchMoreLoading, setFetchMoreLoading] = useState(false)
  const isInitialMount = useRef(true)
  const { authorization } = useContext(AuthorizationContext)
  const { data, loading, fetchMore, refetch } = useQuery(USERS, {
    variables: {
      limit: 10, offset: 0,
    },
  })
  const { data: rolesData, loading: rolesLoading } = useQuery(ROLES)
  const [getTeam, { data: teams, loading: teamsLoading }] = useLazyQuery(TEAM_LIST)
  const [doUpdate, { loading: updating }] = useMutation(UPDATE_TEAM_LIST)

  useEffect(() => {
    if (teams) {
      setManageTeams(teams.teamList?.data?.list)
    }
  }, [teams])

  const mapped = (!loading || !_.isEmpty(data)) && data[KEY].data.list.map((v) => ({
    id: v.id,
    title: `${v.firstName} ${v.lastName}`,
    content: {
      one: {
        title: {
          text: 'Phone Number',
        },
        content: {
          text: v.phoneNumber,
        },
      },
      two: {
        title: {
          text: 'ตำแหน่ง',
        },
        content: {
          text: !_.isEmpty(v.roles) && v.roles[0].description,
        },
      },
    },
    detail: {
      title: _.filter([
        {
          id: 'view',
          text: 'รายละเอียด',
          icon: 'fad fa-info-square',
        },
        {
          id: 'viewHistoryLog',
          text: 'ประวัติการใช้งาน',
          icon: 'fad fa-history',
        },
      ], (o) => authorization.user[o.id]),
      buttonList: [
        authorization.user.edit && <Link key='create' to={`${props.match.url}/edit/${v.id}`}>
          <Button small ghost margin='4px 8px 4px 0' text='แก้ไข' icon='far fa-edit' />
        </Link>,
      ],
      content: [
        <QuickView key={v.id} data={v} />,
        authorization.user.viewHistoryLog && <History
          key={1}
          data={v}
          moduleName='USER'
        />,
      ],
    },
  }))

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false
    } else {
      setFilterLoading(true)
      refetch({
        name, status: status !== '0' ? status : undefined, department: role, orderBy, orderField,
      }).then(() => {
        setFilterLoading(false)
      })
    }
  }, [name, status, role, orderField, orderBy])

  return (
    <Div>
      <Helmet>
        <meta name="viewport" content="width=1280" />
      </Helmet>
      <Drawer
        title="จัดการทีม"
        placement="right"
        onClose={() => setOpenManageTeam(false)}
        visible={openManageTeam}
      >
        {
          teamsLoading
            ? <Div width='100%' margin='20px 0 0' display='flex' justifyContent='center'><Loading /></Div>
            : <Div
              display="flex"
              flexDirection="column"
              width="100%"
              height="100%"
              justifyContent="space-between"
            >
              <Div display="flex" flexDirection="column" width="100%">
                <SmallText color={theme.color.gray70} margin="0 0 4px 0">
                ชื่อทีม
                </SmallText>
                {manageTeams.map((v) => (
                  <Div
                    key={v.id}
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    width="100%"
                    margin="4px 0"
                  >
                    <InputAntd placeholder="ชื่อทีม" style={{ width: '100%' }} value={v.teamName} onChange={(e) => {
                      setManageTeams(manageTeams.map((o) => {
                        if (o.id === v.id) {
                          return ({
                            ...o,
                            teamName: e.target.value,
                          })
                        } return o
                      }))
                    }}/>
                  </Div>
                ))}
                <ButtonAntd
                  type="dashed"
                  icon={<PlusOutlined />}
                  block
                  onClick={() => setManageTeams([...manageTeams, { id: nanoid() }])}
                  style={{ margin: '4px 0' }}
                >
                เพิ่มทีม
                </ButtonAntd>
              </Div>
              <Div display="flex" width="100%" justifyContent="center">
                <Button
                  inverse
                  color={theme.color.gray20}
                  text="ยกเลิก"
                  margin="0 16px 0 0"
                  onClick={() => setOpenManageTeam(false)}
                  disabled={updating}
                />
                <Button
                  inverse
                  onClick={() => doUpdate({
                    variables: {
                      input: {
                        teamList: manageTeams.map((o) => ({
                          id: toNumber(o.id) ? o.id : undefined,
                          teamName: o.teamName,
                        })),
                      },
                    },
                  }).then((resp) => {
                    showMessageMutation(resp.data.updateTeamList)
                    setOpenManageTeam(false)
                    refetch()
                  })}
                  color={theme.color.success}
                  loading={updating}
                  disabled={some(manageTeams, (o) => isNil(o.teamName) || isEmpty(o.teamName))}
                  text="บันทึก"
                  width="180px"
                />
              </Div>
            </Div>
        }
      </Drawer>
      <ListContainer
        tabLoading={fetchMoreLoading}
        listLoading={filterLoading || (loading && _.isEmpty(data))}
        onInfiniteScroll={() => {
          setFetchMoreLoading(true)
          fetchMore({
            variables: {
              offset: data[KEY].data.list.length,
            },
            updateQuery: (prev, { fetchMoreResult }) => {
              setFetchMoreLoading(false)
              if (!fetchMoreResult) return prev
              return { ...prev,
                [KEY]: {
                  ...fetchMoreResult[KEY],
                  data: {
                    ...fetchMoreResult[KEY].data,
                    list: [...prev[KEY].data.list, ...fetchMoreResult[KEY].data.list],
                  },
                } }
            },
          })
        }}
        filterHeight={100}
        filter={[
          <Div margin='0 0 24px' key={0}>
            <Div display='flex' alignItems='flex-start' moreStyle={{ flexGrow: 1 }}>
              <Input
                type='text'
                width='100%'
                prefix={<Icon icon='prefix fal fa-search' />}
                placeholder='ค้นหาชื่อ, Email, Username'
                // value={name}
                onChange={(e) => {
                  const searchValue = e.target.value
                  delayInput(() => {
                    setName(searchValue)
                  })
                }}
                margin='0 16px 0 0'
                suffix={(!loading && !_.isEmpty(data?.userList?.data) && !_.isEmpty(name)) && <Div position='absolute' right='16px' top='50%' moreStyle={{ transform: 'translateY(-50%)' }}><SmallText>{numeral(data?.userList?.data?.total || 0).format('0,0')} รายการ</SmallText></Div>}
              />
              <Select
                loading={rolesLoading}
                style={{ width: '70%', margin: '0 16px 0 0' }}
                placeholder='เลือกตำแหน่งงาน'
                options={rolesLoading ? [] : rolesData.roleList.data.list.map((v) => ({ value: v.name, text: v.description }))}
                onChange={(value) => setRole(value)}
                value={role}
              />
              <Select
                style={{ width: '70%' }}
                placeholder='เลือกตัวกรองสถานะผู้ใช้'
                options={statusOption}
                onChange={(value) => setStatus(value)}
                value={status}
              />
            </Div>
            <Div display='flex' margin='8px 0 0'>
              <Div display='flex' moreStyle={{ flexGrow: 1 }} margin='0 56px 0 0'>
                <Select
                  defaultValue='createdAt'
                  onChange={(value) => setOrderField(value)}
                  style={{ width: '41.65%', minWidth: '200px', margin: '0 16px 0 0' }}
                  options={[{ text: 'เรียงตามวันที่สร้าง', value: 'createdAt' }, { text: 'เรียงตามวันที่อัพเดท', value: 'updatedAt' }]}
                />
                <Select
                  suffixIcon={<Icon icon={orderBy === 'ASC' ? 'fal fa-sort-amount-down-alt' : 'fal fa-sort-amount-up-alt'} />}
                  defaultValue='DESC'
                  onChange={(value) => setOrderby(value)}
                  options={[{ text: 'น้อยไปมาก', value: 'ASC' }, { text: 'มากไปน้อย', value: 'DESC' }]}
                />
              </Div>
              <Div display='flex' alignItems='center'>
                <Button text='ล้าง' icon='fal fa-sync' small ghost onClick={() => {
                  setName('')
                  setStatus()
                  setRole()
                  setOrderField('createdAt')
                  setOrderby('DESC')
                  setFilterLoading(true)
                  refetch({
                    name: '',
                    status: undefined,
                    department: undefined,
                    orderBy: 'DESC',
                    orderField: 'createdAt',
                  }).then(() => {
                    setFilterLoading(false)
                  })
                }} />
              </Div>
            </Div>
          </Div>,
        ]}
        title={[
          {
            text: 'รายชื่อผู้ใช้',
            icon: 'fad fa-users',
          },
        ]}
        list={mapped}
        buttonList={[
          authorization.user.create
            && <Button key='manage-team' small ghost color={theme.color.success} margin='4px 8px 4px 0' text='จัดการทีม' icon='far fa-users' onClick={() => {
              setOpenManageTeam(true)
              getTeam()
            }}/>,
          authorization.user.create && <Link key='create' to={`${props.match.url}/create`}>
            <Button small ghost inverse color={theme.color.success} margin='4px 8px 4px 0' text='สร้างผู้ใช้ใหม่' icon='far fa-plus' />
          </Link>,
        ]}
      >
        <Div />
      </ListContainer>
    </Div>
  )
}
