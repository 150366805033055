import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { Radio, Space } from 'antd'
import { ResponsiveBar } from '@nivo/bar'
import { keys } from 'lodash'
import {
  P, Div,
} from '@konsys-ui-custom'
import { useLazyQuery } from '@apollo/client'
import { DASHBOARD_CHAT_DAILY, DASHBOARD_CHAT_HOURLY, DASHBOARD_SALES_DAILY, DASHBOARD_SALES_HOURLY } from '../../../api/query'

const TIME_TYPE = {
  DATE: {
    key: 'DATE',
    text: 'รายวัน',
  },
  HOUR: {
    key: 'HOUR',
    text: 'รายชั่วโมง',
  },
}

const REPORT_TYPE = {
  CHAT: {
    key: 'CHAT',
    text: 'จำนวนข้อความ',
  },
  SALES: {
    key: 'SALES',
    text: 'ยอดขาย',
  },
}

export default ({ teamId, userId, provider, date, setHourly }) => {
  const [vars, setVars] = useState({
    time: TIME_TYPE?.DATE?.key,
    report: REPORT_TYPE?.SALES?.key,
  })

  const [getChatDaily, { data: chatDailyData }] = useLazyQuery(DASHBOARD_CHAT_DAILY)
  const [getChatHourly, { data: chatHourlyData }] = useLazyQuery(DASHBOARD_CHAT_HOURLY)
  const [getSalesDaily, { data: salesDailyData }] = useLazyQuery(DASHBOARD_SALES_DAILY)
  const [getSalesHourly, { data: salesHourlyData }] = useLazyQuery(DASHBOARD_SALES_HOURLY)

  const callQuery = (type) => {
    const variablesInput = {
      variables: {
        input: {
          teamId: teamId === 'all' ? undefined : teamId,
          sellerId: userId === 'all' ? undefined : userId,
          chatProviderId: provider === 'all' ? undefined : provider,
          period: vars?.time === TIME_TYPE?.DATE?.key ? date : undefined,
          date: vars?.time === TIME_TYPE?.DATE?.key ? undefined : date,
        },
      },
    }
    switch (type) {
      case 'chatDaily':
        getChatDaily(variablesInput)
        break
      case 'chatHourly':
        getChatHourly(variablesInput)
        break
      case 'salesDaily':
        getSalesDaily(variablesInput)
        break
      case 'salesHourly':
        getSalesHourly(variablesInput)
        break
      default:
        break
    }
  }

  const getType = () => {
    const isDate = vars?.time === TIME_TYPE?.DATE?.key
    const isHour = vars?.time === TIME_TYPE?.HOUR?.key
    const isChat = vars?.report === REPORT_TYPE?.CHAT?.key
    const isSales = vars?.report === REPORT_TYPE?.SALES?.key
    if (isChat && isDate) return 'chatDaily'
    if (isChat && isHour) return 'chatHourly'
    if (isSales && isDate) return 'salesDaily'
    if (isSales && isHour) return 'salesHourly'
    return ''
  }

  useEffect(() => {
    callQuery(getType())
  }, [])

  useEffect(() => {
    setHourly(vars?.time !== TIME_TYPE?.DATE?.key)
  }, [vars?.time])

  useEffect(() => {
    callQuery(getType())
  }, [vars, provider, date, userId, teamId])

  const getData = () => {
    const type = getType()
    const dataList = type === 'chatDaily'
      ? chatDailyData?.dashboardChatDaily?.data
      : type === 'chatHourly'
        ? chatHourlyData?.dashboardChatHourly?.data
        : type === 'salesDaily'
          ? salesDailyData?.dashboardSalesDaily?.data
          : type === 'salesHourly'
            ? salesHourlyData?.dashboardSalesHourly?.data
            : []

    const list = dataList?.map((detail) => (
      {
        keyIndex: (type === 'chatDaily' || type === 'salesDaily') ? moment(detail?.key).format('DD/MM') : detail?.key,
        data: detail?.value,
      }
    )) || []
    return list
  }

  const barColors = ['#3ECB98']
  const commonProps = {
    data: getData(),
    keys: ['data'],
    indexBy: 'keyIndex',
    margin: { top: 10, right: 10, bottom: 50, left: 60 },
    padding: 0.2,
    enableLabel: false,
  }

  return (
    <Div display='flex' margin='0 -16px'>
      <Div moreStyle={{ maxWidth: 'calc(100% - calc((100vw * 200)/1440))', height: '500px', flexGrow: 1 }}>
        <ResponsiveBar {...commonProps} groupMode='grouped' colors={barColors} />
      </Div>
      <Div padding='8px 16px 0 32px' width='calc((100vw * 200)/1440)'>
        <P bold display='block' margin='0 0 16px'>ประเภทเวลา</P>
        <Radio.Group vertical onChange={(e) => setVars({ ...vars, time: e.target.value })} value={vars?.time}>
          <Space direction="vertical">
            {
              keys(TIME_TYPE)?.map((timeKey) => (
                <Radio key={TIME_TYPE?.[timeKey]?.key} value={TIME_TYPE?.[timeKey]?.key}><P>{TIME_TYPE?.[timeKey]?.text}</P></Radio>
              ))
            }
          </Space>
        </Radio.Group>
        <P bold display='block' margin='24px 0 16px'>ประเภทรายงาน</P>
        <Radio.Group onChange={(e) => setVars({ ...vars, report: e.target.value })} value={vars?.report}>
          <Space direction="vertical">
            {
              keys(REPORT_TYPE)?.map((reportKey) => (
                <Radio key={REPORT_TYPE?.[reportKey]?.key} value={REPORT_TYPE?.[reportKey]?.key}><P>{REPORT_TYPE?.[reportKey]?.text}</P></Radio>
              ))
            }
          </Space>
        </Radio.Group>
      </Div>
    </Div>
  )
}
