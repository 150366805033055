export const SALE_TYPE = {
  VARIANT: 'VARIANT',
  PACK: 'PACK',
}

export const PRODUCT_GROUP = {
  AC: 'AC',
  PARTS: 'PARTS',
  OTHERS: 'OTHERS',
}

export const ADDRESS_LABEL_STATUS = {
  ENUM: {
    WAITING_PRINT: 'WAITING_PRINT',
    PRINTING: 'PRINTING',
    PRINTED: 'PRINTED',
  },
  TEXT: {
    WAITING_PRINT: 'รอพิมพ์',
    PRINTING: 'กำลังพิมพ์',
    PRINTED: 'พิมพ์แล้ว',
  },
}

export const STOCK_TYPE = {
  STOCK: 'STOCK',
  NONSTOCK: 'NONSTOCK',
  SERVICE: 'SERVICE',
}

export const VAT_TYPE = {
  NO_VAT: 'NO_VAT',
  VAT0: 'VAT0',
  VAT7: 'VAT7',
}

export const PAYMENT_TYPE = {
  // CREDIT: 'CREDIT',
  CASH: 'CASH',
  COD: 'COD',
  // TRANSFER: 'TRANSFER',
}

export const ORDER_CLAIM_TYPE = {
  SELLER: {
    ENUM: 'SELLER',
    TEXT: 'ไม่หักเงินแอดมิน',
  },
  SELLER_CUT: {
    ENUM: 'SELLER_CUT',
    TEXT: 'หักเงินแอดมิน',
  },
  PT: {
    ENUM: 'PT',
    TEXT: 'หักเงินแพคกิ้ง',
  },
}

export const SO_STATUS_TEXT = {
  WAITCASHPAYMENT: 'กำลังดำเนินการ',
  WAITCASHAPPROVE: 'กำลังดำเนินการ',
  WAITCREDITAPPROVE: 'กำลังดำเนินการ',
  WAITBILLING: 'กำลังดำเนินการ',
  WAITING_PO: 'กำลังดำเนินการ',
  WAITING_PT: 'กำลังดำเนินการ',
  PURCHASING: 'กำลังดำเนินการ',
  PREPARING: 'กำลังดำเนินการ',
  WAITSHIPMENT: 'กำลังดำเนินการ',
  COMPLETED: 'เสร็จสิ้น',
  WAITCREDITTERM: 'รอกำหนดชำระ',
  BILLED: 'วางบิลแล้ว',
  CANCELLED: 'ยกเลิก',
  OVERDUESHIPMENT: 'เลยกำหนดการจัดส่ง',
  RETURNED: 'ลูกค้าคืนสินค้า',
  REFUNDED: 'คืนเงินเรียบร้อย',
  WAITING_CN: 'รอสร้างใบตีกลับของใบรับมัดจำ',
}

export const SO_STATUS = {
  WAITCASHPAYMENT: 'WAITCASHPAYMENT',
  WAITCASHAPPROVE: 'WAITCASHAPPROVE',
  WAITBILLING: 'WAITBILLING',
  WAITCREDITAPPROVE: 'WAITCREDITAPPROVE',
  WAITING_PO: 'WAITING_PO',
  WAITING_PT: 'WAITING_PT',
  PURCHASING: 'PURCHASING',
  PREPARING: 'PREPARING',
  WAITSHIPMENT: 'WAITSHIPMENT',
  COMPLETED: 'COMPLETED',
  WAITCREDITTERM: 'WAITCREDITTERM',
  BILLED: 'BILLED',
  CANCELLED: 'CANCELLED',
  OVERDUESHIPMENT: 'OVERDUESHIPMENT',
  RETURNED: 'RETURNED',
  REFUNDED: 'REFUNDED',
  WAITING_CN: 'WAITING_CN',
}

export const SO_STATUS_GROUP_TEXT = {
  INPROGRESS: 'กำลังดำเนินการ',
  COMPLETED: 'เสร็จสิ้น',
  WAITPAYMENT: 'รอชำระเงิน',
  CANCELLED: 'ยกเลิก',
  OVERDUESHIPMENT: 'เลยกำหนดการจัดส่ง',
  RETURNED: 'ลูกค้าคืนสินค้า',
  REFUNDED: 'คืนเงินเรียบร้อย',
  WAITING_CN: 'รอสร้างใบตีกลับของใบรับมัดจำ',
}

export const SO_STATUS_GROUP = {
  INPROGRESS: 'INPROGRESS',
  COMPLETED: 'COMPLETED',
  WAITPAYMENT: 'WAITPAYMENT',
  CANCELLED: 'CANCELLED',
  OVERDUESHIPMENT: 'OVERDUESHIPMENT',
  RETURNED: 'RETURNED',
  REFUNDED: 'REFUNDED',
  WAITING_CN: 'WAITING_CN',
}

export const DELIVERY_TYPE = {
  DELIVERY: 'DELIVERY',
  PICKUP: 'PICKUP',
  PICKUPSUPPLIER: 'PICKUPSUPPLIER',
}

export const DELIVERY_CHANNEL = {
  EMS: {
    KEY: 'EMS',
    LABEL: 'EMS',
  },
  KERRY: {
    KEY: 'KERRY',
    LABEL: 'Kerry',
  },
  OTHER: {
    KEY: 'OTHER',
    LABEL: 'Other',
  },
}

export const RECEIVE_TYPE = {
  MANUAL: 'MANUAL',
  TRANSFER: 'TRANSFER',
  MANUAL_SUPPLIER: 'MANUAL_SUPPLIER',
}

export const ITEM_SOURCE = {
  STOCK: 'คลัง',
  INCOMING: 'รอของเข้า',
  PREORDER: 'เปิด PO',
}

export const ORDER_ITEM_STATUS = {
  WAITING_PAYMENT: 'รอชำระเงิน',
  WAITING_PO: 'รอเปิดSupplier',
  WAITING_PT: 'รอเปิดการจัดส่ง',
  WAITING_SUPPLIER: 'รอการยืนยัน',
  SUPPLIER_CF: 'รอรับของ',
  PARTIAL_RECEIVED: 'รับสินค้าแล้วบางส่วน',
  RECEIVED: 'ของเข้าคลังแล้ว',
  RECEIVE_OVERDUE: 'เลยกำหนดการรับของ',
  CANCELLED_PO: 'ยกเลิกSupplier',
  PREPARING: 'เตรียมสินค้า',
  WAITING_2ND_PAYMENT: 'รอชำระงวด 2',
  WAITING_SHIPMENT: 'รอรับ / จัดส่ง',
  COMPLETED: 'เสร็จสิ้น',
  CANCELLED: 'ยกเลิก',
  SHIPMENT_OVERDUE: 'เลยกำหนดการจัดส่ง',
  RETURNED: 'ลูกค้าคืนสินค้า',
  REFUNDED: 'คืนเงินเรียบร้อย',
  ERROR: 'เกิดข้อผิดพลาด',
  INACTIVE: 'ไม่ได้ใช้งาน',
  WAITING_CN: 'รอสร้างใบตีกลับของใบรับมัดจำ',
}

export const PERSON_TYPE = {
  INDIVIDUAL: 'INDIVIDUAL',
  CORPORATION: 'CORPORATION',
}

export const ORDER_TYPE = {
  SET: 'SET',
  PACK: 'PACK',
}
