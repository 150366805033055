export const DELIVERY_TYPE = [
  { text: 'จัดส่ง', value: 'DELIVERY' },
  { text: 'รับเอง', value: 'PICKUP' },
  { text: 'รับเองจากผู้ผลิต', value: 'PICKUPSUPPLIER' },
]

export const PAYMENT_TYPE = [
  { text: 'โอนเงิน', value: 'CASH' },
  { text: 'ชำระเงินปลายทาง', value: 'COD' },
  // { text: 'เครดิต', value: 'CREDIT' },
]

export const RECORD_PAYMENT = [
  // { text: 'โอนเงิน', value: 'TRANSFER' },
  // { text: 'เช็ค', value: 'CHEQUE' },
  { text: 'โอนเงิน', value: 'CASH' },
  { text: 'ชำระเงินปลายทาง', value: 'COD' },
  // { text: 'บัตรเครดิต', value: 'CREDIT' },
]

export const GENDER = [
  { text: 'ชาย', value: 'MALE' },
  { text: 'หญิง', value: 'FEMALE' },
  { text: 'อื่นๆ', value: 'OTHER' },
]

export const SO_TYPE = [
  { text: 'แบบPOS', value: 'POS' },
  { text: 'แบบปกติ', value: 'NORMAL' },
  { text: 'แบบCRM', value: 'CRM' },
]

export const ROLE = [
  'superAdmin',
  'admin',
  'warehouseManager',
]

export const TAX_INVOICE_STATUS_ENUM = {
  WAITING_TAXINVOICE: 'WAITING_TAXINVOICE',
  TAXINVOICED: 'TAXINVOICED',
  RECEIVED: 'RECEIVED',
  WAITING_DELIVER: 'WAITING_DELIVER',
  WAITING_NEXT_DELIVER: 'WAITING_NEXT_DELIVER',
  DELIVERED: 'DELIVERED',
  WAITING_IV_CONFIRM: 'WAITING_IV_CONFIRM',
  REJECTED: 'REJECTED',
}

export const TAX_INVOICE_STATUS = {
  WAITING_TAXINVOICE: 'รอเปิดใบกำกับภาษี',
  TAXINVOICED: 'รอรับ',
  RECEIVED: 'รับแล้ว',
  WAITING_DELIVER: 'รอจัดส่ง',
  WAITING_NEXT_DELIVER: 'รอจัดส่งรอบถัดไป',
  DELIVERED: 'จัดส่งแล้ว',
  WAITING_IV_CONFIRM: 'รอยืนยันเลข IV',
  REJECTED: 'ปฏิเสธสร้างใบกำกับภาษี',
}

export const TAX_INVOICE_TYPE = [
  { text: 'ทั่วไป (Sale มารับเอง)', value: 'RECEIVE' },
  { text: 'จัดส่ง (ให้บัญชีจัดส่งให้)', value: 'DELIVER' },
  { text: 'จัดส่งรอบหน้า', value: 'POSTPONE' },
]

export const BILLING_TYPE = [
  { text: 'นิติบุคคล', value: 'CORPORATION' },
  { text: 'บุคคลธรรมดา', value: 'INDIVIDUAL' },
]

export const LIST_LIMIT = 10

export default DELIVERY_TYPE
