import React, { useContext, useEffect } from 'react'
import { useQuery } from '@apollo/client'
import {
  Tab,
} from '../../components'
import { TAX_INVOICE_STATUS, TAX_INVOICE_STATUS_ENUM } from '../../constants/enum'
import { REMAINING_TAX_JOB } from '../../api/query'
import List from './list'
import { RoleContext, TaxRemainingJobsContext } from '../../store/StoreProvider'

const AccountList = [
  <List key={TAX_INVOICE_STATUS_ENUM.WAITING_TAXINVOICE} status={{ value: TAX_INVOICE_STATUS_ENUM.WAITING_TAXINVOICE, isFixed: false }} isSaleRole />,
  <List key={TAX_INVOICE_STATUS_ENUM.WAITING_DELIVER} status={{ value: TAX_INVOICE_STATUS_ENUM.WAITING_DELIVER, isFixed: true }} />,
  <List key={TAX_INVOICE_STATUS_ENUM.WAITING_IV_CONFIRM} status={{ value: TAX_INVOICE_STATUS_ENUM.WAITING_IV_CONFIRM, isFixed: true }} />,
  <List key={TAX_INVOICE_STATUS_ENUM.WAITING_TAXINVOICE} status={{ value: TAX_INVOICE_STATUS_ENUM.TAXINVOICED, isFixed: true }} isSaleRole />,
]

const SalesList = [
  <List key={TAX_INVOICE_STATUS_ENUM.WAITING_TAXINVOICE} status={{ value: TAX_INVOICE_STATUS_ENUM.TAXINVOICED, isFixed: false }} isSaleRole />,
]

const TaxInvoice = () => {
  const { data, loading } = useQuery(REMAINING_TAX_JOB)
  const { role } = useContext(RoleContext)
  const { taxRemainingJobs, updateTaxJobs } = useContext(TaxRemainingJobsContext)

  useEffect(() => {
    if (!loading && data?.remainingTaxInvoice?.data) {
      updateTaxJobs(data?.remainingTaxInvoice?.data)
    }
  }, [data])

  const getTitleTab = (isSale) => {
    if (isSale) {
      return (
        [
          {
            id: TAX_INVOICE_STATUS_ENUM.TAXINVOICED,
            text: `${TAX_INVOICE_STATUS.TAXINVOICED} ${taxRemainingJobs?.taxInvoiced > 0 ? `(${taxRemainingJobs?.taxInvoiced})` : ''}`,
            icon: 'fal fa-hand-holding-box',
          },
        ]
      )
    }
    return (
      [
        {
          id: TAX_INVOICE_STATUS_ENUM.WAITING_TAXINVOICE,
          text: `${TAX_INVOICE_STATUS.WAITING_TAXINVOICE} ${taxRemainingJobs?.waitingTaxInvoice > 0 ? `(${taxRemainingJobs?.waitingTaxInvoice})` : ''}`,
          icon: 'fal fa-file-alt',
        }, {
          id: TAX_INVOICE_STATUS_ENUM.WAITING_DELIVER,
          text: `${TAX_INVOICE_STATUS.WAITING_DELIVER} ${taxRemainingJobs?.waitingDeliver > 0 ? `(${taxRemainingJobs?.waitingDeliver})` : ''}`,
          icon: 'fal fa-mailbox',
        }, {
          id: TAX_INVOICE_STATUS_ENUM.WAITING_IV_CONFIRM,
          text: `${TAX_INVOICE_STATUS.WAITING_IV_CONFIRM} ${taxRemainingJobs?.waitingIVConfirm > 0 ? `(${taxRemainingJobs?.waitingIVConfirm})` : ''}`,
          icon: 'fal fa-check-circle',
        }, {
          id: TAX_INVOICE_STATUS_ENUM.TAXINVOICED,
          text: `${TAX_INVOICE_STATUS.TAXINVOICED} ${taxRemainingJobs?.taxInvoiced > 0 ? `(${taxRemainingJobs?.taxInvoiced})` : ''}`,
          icon: 'fal fa-hand-holding-box',
        },
      ]
    )
  }

  const checkIsRoleCanView = role === 'sales' || role === 'salesManager'

  return (
    <Tab
      fullHeight
      title={getTitleTab(checkIsRoleCanView)}
    >
      {
        checkIsRoleCanView ? SalesList : AccountList
      }
    </Tab>
  )
}

export default TaxInvoice
