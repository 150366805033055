import { BANK_TYPE } from './constants'

export const mapBankTypetoText = (val) => {
  switch (val) {
    case BANK_TYPE.SAVING:
      return 'ออมทรัพย์'
    case BANK_TYPE.FIXED:
      return 'ฝากประจำ'
    case BANK_TYPE.CURRENT:
      return 'กระแสรายวัน'
    case BANK_TYPE.FOREIGN:
      return 'สกุลเงินตราต่างประเทศ'
    case BANK_TYPE.BILL:
      return 'ตั๋วแลกเงิน'
    case BANK_TYPE.LOTTERY:
      return 'สลากออมทรัพย์'
    case BANK_TYPE.BOND:
      return 'พันธบัตรรัฐบาล'
    default:
      return 'ไม่ระบุ'
  }
}

export default mapBankTypetoText
