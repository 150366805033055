import { useLazyQuery, useMutation, useQuery } from '@apollo/client'
import {
  Button,
  Checkbox,
  Div,
  Icon,
  Input,
  Label,
  P,
  SmallText,
} from '@konsys-ui-custom'
import { Col, DatePicker, Row, message } from 'antd'
import _, { isEmpty, round, sum } from 'lodash'
import moment from 'moment-timezone'
import numeral from 'numeral'
import React, { useContext, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useHistory } from 'react-router-dom'
import { CREATE_PRODUCT_SET, UPDATE_PRODUCT_SET } from '../../api/mutation'
import {
  GET_ITEMS_FOR_SET,
  PRICING_TYPES_LISTS,
  PRODUCT_SET_INFO,
} from '../../api/query'
import {
  ComponentWithLabelLarge,
  DashedButton,
  Divider,
  FullPageContainer,
  InputWithLabel,
  Select,
  TextareaWithLabel,
  Uploader,
} from '../../components'
import { theme } from '../../styles/_variables'
import { validateData } from '../../utils/util-services'
import { AuthorizationContext } from '../../store/StoreProvider'

const ItemRenderer = ({ updateItems, isCreateMode, item, items, index }) => {
  const [search, { loading: productLoading, data: productData }] = useLazyQuery(GET_ITEMS_FOR_SET)
  const { authorization } = useContext(AuthorizationContext)

  return (
    <Div>
      <Row type="flex" align="middle" gutter={16} style={{ marginTop: 16 }}>
        <Col span={11}>
          {isCreateMode ? (
            <Select
              tooltip
              width="100%"
              margin="0 16px 0 0"
              showSearch
              loading={productLoading}
              disabled={!isCreateMode}
              style={{ width: '100%' }}
              placeholder="เลือกสินค้า"
              notFoundContent={null}
              filterOption={false}
              defaultValue={item.variantName}
              onSelect={(e) => {
                const product = _.find(
                  productData.variantListOnlyPack.data,
                  (o) => o.id === e,
                )
                updateItems(
                  items.map((val) => {
                    if (val.id === item.id) {
                      return {
                        ...val,
                        packId: e,
                        productObject: product,
                      }
                    }
                    return val
                  }),
                )
              }}
              onSearch={(value) => search({
                variables: { search: value, limit: 10 },
              })
              }
              options={
                productData && productData.variantListOnlyPack
                  ? productData.variantListOnlyPack.data.map((val) => ({
                    value: val.id,
                    text: val.variantName,
                  }))
                  : []
              }
              className={
                ((_.isEmpty(item.packId) && !_.isNumber(item.packId))
                  || _.isNaN(item.packId))
                && 'state-error'
              }
            />
          ) : (
            <P>{item.Pack?.variantName}</P>
          )}
        </Col>
        <Col span={3}>
          {isCreateMode ? (
            <Input
              rule={{ type: 'float' }}
              state={
                (_.isEmpty(item.quantity) && !_.isNumber(item.quantity))
                || _.isNaN(item.quantity)
                  ? 'error'
                  : 'default'
              }
              width="100%"
              value={item.quantity || 0}
              onChange={(e) => {
                updateItems(
                  items.map((val) => {
                    if (val.id === item.id) {
                      return {
                        ...val,
                        quantity: parseFloat(e.target.value),
                      }
                    }
                    return val
                  }),
                )
              }}
            />
          ) : (
            <P>{numeral(item.quantity).format('0,0.00')}</P>
          )}
        </Col>
        <Col span={3}>
          <Input
            rule={{ type: 'float' }}
            state={
              (_.isEmpty(item.salesPrice) && !_.isNumber(item.salesPrice))
              || _.isNaN(item.salesPrice)
                ? 'error'
                : 'default'
            }
            width="100%"
            value={item.salesPrice || 0}
            onBlur={() => {
              updateItems(
                items.map((val) => {
                  if (val.id === item.id) {
                    return {
                      ...val,
                      salesPrice: round(val.salesPrice, 2),
                    }
                  }
                  return val
                }),
              )
            }}
            onChange={(e) => {
              updateItems(
                items.map((val) => {
                  if (val.id === item.id) {
                    return {
                      ...val,
                      salesPrice: e.target.value,
                    }
                  }
                  return val
                }),
              )
            }}
          />
        </Col>

        {
          authorization.productSet.showCost
              && <Col span={3}>
                <P>
                  {numeral(
                    item[isCreateMode ? 'productObject' : 'Pack']?.inventoryOption
                      ?.avgCost,
                  ).format('0,0.00')}
                </P>
              </Col>
        }
        {
          authorization.productSet.showCost
              && <Col span={3}>
                <P>
                  {numeral(
                    item.salesPrice
                - item[isCreateMode ? 'productObject' : 'Pack']?.inventoryOption
                  ?.avgCost,
                  ).format('0,0.00')}
                </P>
              </Col>
        }
        <Col span={1}>
          {isCreateMode && index > 0
          && (
            <Icon
              icon="fal fa-trash-alt"
              style={{ cursor: 'pointer' }}
              onClick={() => updateItems(
                _.remove(_.cloneDeep(items), (o) => o.id !== item.id),
              )}
            />
          )}
        </Col>
      </Row>
    </Div>
  )
}

const ItemsInputRenderer = ({ items, updateItems, isCreateMode }) => items.map((v, index) => (
  <ItemRenderer
    key={v.id}
    index={index}
    item={v}
    updateItems={updateItems}
    isCreateMode={isCreateMode}
    items={items}
  />
))

export const Form = ({
  data,
  loading,
  isCreateMode,
  setLoading,
  callback,
  prefillCreate,
}) => {
  const history = useHistory()
  const { authorization } = useContext(AuthorizationContext)
  const [doAction, { loading: mutationLoading }] = useMutation(
    isCreateMode ? CREATE_PRODUCT_SET : UPDATE_PRODUCT_SET,
    { refetchQueries: ['remainingJob'] },
  )

  const [group, setGroup] = useState({
    files: [],
    ProductSetItems: [{ id: 1 }],
    availableDate: [],
    // sku: prefillCreate?.sku,
    name: prefillCreate?.name,
    productUrl: prefillCreate?.image,
  })

  useEffect(() => {
    if (isCreateMode && !loading) {
      setGroup(group)
    }
    if (!isCreateMode && !setLoading) {
      setGroup({
        ...data,
        availableDate:
          _.isNil(data.availableStart) || _.isNil(data.availableEnd)
            ? undefined
            : [moment(data.availableStart), moment(data.availableEnd)],
        files: data.files
          ? data.files.map((file) => ({
            id: file.id,
            name: file.fileName,
            url: file.url,
            uid: file.fileName,
          }))
          : [],
      })
    }
  }, [loading, data, setLoading])

  const menu = isCreateMode
    ? [
      {
        text: 'สร้างเซตสินค้า',
        icon: 'far fa-plus',
      },
    ]
    : [
      {
        text: 'แก้ไขเซตสินค้า',
        icon: 'far fa-edit',
      },
    ]

  return (
    <FullPageContainer
      loading={loading}
      title={[
        {
          text: 'เซตสินค้า',
          icon: 'fad fa-boxes',
        },
      ]}
      detail={{
        title: menu,
      }}
    >
      {((!isCreateMode && data && !setLoading)
        || (isCreateMode && !loading)) && (
        <Div display="flex" flexDirection="column">
          <Div
            display="flex"
            alignItems="flex-start"
            justifyContent="space-between"
            width="100%"
          >
            <Div width="50%">
              <InputWithLabel
                width="30%"
                margin="0 16px 0 0"
                input={{
                  width: '100%',
                  value: group.sku,
                  state:
                    (_.isEmpty(group.sku) || _.isNil(group.sku)) && 'error',
                }}
                onChange={(e) => setGroup({ ...group, sku: e.target.value })}
                title={{
                  text: (
                    <SmallText bold>
                      SKU <Label color={theme.color.error}>*</Label>
                    </SmallText>
                  ),
                }}
              />
              <InputWithLabel
                width="30%"
                margin="0 16px 0 0"
                input={{
                  width: '100%',
                  value: group.reference,
                  state:
                    (_.isEmpty(group.reference) || _.isNil(group.reference)) && 'error',
                }}
                onChange={(e) => setGroup({ ...group, reference: e.target.value })}
                title={{
                  text: (
                    <SmallText bold>
                      ชื่อย่อ <Label color={theme.color.error}>*</Label>
                    </SmallText>
                  ),
                }}
              />
            </Div>
            {
              group?.productUrl
                ? <img src={group.productUrl} alt='' style={{ width: 100, height: 100, objectFit: 'cover' }}/>
                : <Uploader
                  onChange={(e) => setGroup({ ...group, files: e })}
                  data={group.files ? group.files : []}
                  accept="image/*"
                  buttonText="อัพโหลดรูปเซต"
                  isMultiple={true}
                  fileLength={5}
                />
            }
          </Div>
          <InputWithLabel
            width="100%"
            input={{
              width: '100%',
              value: group.name,
              disabled: prefillCreate?.name,
              state:
                (_.isEmpty(group.name) || _.isNil(group.name)) && 'error',
            }}
            onChange={(e) => setGroup({ ...group, name: e.target.value })}
            title={{
              text: (
                <SmallText bold>
                  ชื่อเซตสินค้า <Label color={theme.color.error}>*</Label>
                </SmallText>
              ),
            }}
          />
          {/* CHp178hbvjAV8HPWf5nIwIjRdTlHhHuQDklX4MWzXxHJ7ezjsnUWg9JmJvYig2RO5yaVkWXniqvcRI68wg8y41CfgVJyscGhROCGSBBbqA6VxCnxYPhvm0Uiy6IfXu0T9VfdPutcIwHswT7odAKGk2Abzia73hGmyzb7ROWxcFc3EMUZPq2SA3vVWN1MDAP525Sy53dMSVWbOg6Bto8CPBXM0ugOFjHXPRp8EmFN1uRCFO0udm93ZEEKTAM7rNg */}
          <Div
            style={{
              padding: 20,
              border: `1px solid ${theme.color.lightBlue}`,
              borderRadius: 5,
            }}
            margin="10px 0"
          >
            <Row
              type="flex"
              align="middle"
              gutter={16}
              justifyContent="space-between"
            >
              <Col span={8}>
                <Div display="flex" alignItems="flex-end">
                  <Checkbox
                    margin="0 0 13px 0"
                    data={{ value: 'useDate' }}
                    checked={group.useDate}
                    onChange={(e) => setGroup({
                      ...group,
                      useDate: e.target.checked,
                    })
                    }
                  />
                  <ComponentWithLabelLarge
                    width="100%"
                    margin="8px 16px 8px 0"
                    title={{
                      text: (
                        <SmallText bold>
                          วันที่กำหนด <Label color={theme.color.error}>*</Label>
                        </SmallText>
                      ),
                    }}
                  >
                    <DatePicker.RangePicker
                      disabled={!group.useDate}
                      style={{ width: '100%' }}
                      onChange={(e) => setGroup({
                        ...group,
                        availableDate: e,
                      })
                      }
                      className={
                        _.isEmpty(group.availableDate)
                        && group.useDate
                        && 'state-error'
                      }
                      value={group.availableDate}
                    />
                  </ComponentWithLabelLarge>
                </Div>
              </Col>
            </Row>
            <Row
              type="flex"
              align="middle"
              gutter={16}
              style={{ marginTop: 28 }}
            >
              <Col span={11}>
                <SmallText bold>สินค้า *</SmallText>
              </Col>
              <Col span={3}>
                <SmallText bold>จำนวน *</SmallText>
              </Col>
              <Col span={3}>
                <SmallText bold>ราคาขายต่อชิ้น *</SmallText>
              </Col>
              {
                authorization.productSet.showCost
              && <Col span={3}>
                <SmallText bold>ต้นทุนต่อชิ้น</SmallText>
              </Col>
              }
              {
                authorization.productSet.showCost
              && <Col span={3}>
                <SmallText bold>กำไรต่อชิ้น</SmallText>
              </Col>
              }
              <Col span={1} />
            </Row>
            <Divider />
            <ItemsInputRenderer
              isCreateMode={isCreateMode}
              items={group.ProductSetItems}
              updateItems={(e) => setGroup({ ...group, ProductSetItems: e })}
            />
            {isCreateMode && (
              <DashedButton
                margin="20px 0"
                text="เพิ่มสินค้า/บริการใหม่"
                icon="fal fa-plus"
                width="100%"
                onClick={() => setGroup({
                  ...group,
                  ProductSetItems: [
                    ...group.ProductSetItems,
                    {
                      id:
                          _.size(group.ProductSetItems) > 0
                            ? _.maxBy(group.ProductSetItems, 'id').id + 1
                            : 1,
                    },
                  ],
                })
                }
              />
            )}
          </Div>
          <Divider />
          <Div display="flex" justifyContent="space-between">
            <Div width="50%" margin="0 16px 0 0">
              <TextareaWithLabel
                onChange={(e) => setGroup({
                  ...group,
                  remark: e.target.value,
                })
                }
                width="100%"
                title={{
                  text: 'หมายเหตุเพิ่มเติม',
                  margin: '0 0 8px',
                }}
                textarea={{
                  value: group.remark,
                  rows: 5,
                  margin: '16px 0 0',
                  style: { width: '100%' },
                }}
              />
            </Div>
            <Div width="25%" margin="8px 0 0 16px">

              {
                authorization.productSet.showCost
              && <Div
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                margin="0 0 8px"
              >
                <P bold>ต้นทุนรวม</P>
                <P>
                  {numeral(
                    sum(
                      group.ProductSetItems.map(
                        (item) => item[isCreateMode ? 'productObject' : 'Pack']
                          ?.inventoryOption?.avgCost * item.quantity,
                      ),
                    ),
                  ).format('0,0.00')}
                </P>
              </Div>
              }
              <Div
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                margin="0 0 8px"
              >
                <P bold>ราคาขายรวม</P>
                <P>
                  {numeral(
                    sum(
                      group.ProductSetItems.map(
                        (item) => item.salesPrice * item.quantity,
                      ),
                    ),
                  ).format('0,0.00')}
                </P>
              </Div>

              {
                authorization.productSet.showCost
              && <Div
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                margin="0 0 8px"
              >
                <P bold>กำไรรวม</P>
                <P>
                  {numeral(
                    sum(
                      group.ProductSetItems.map(
                        (item) => (item.salesPrice
                            - item[isCreateMode ? 'productObject' : 'Pack']
                              ?.inventoryOption?.avgCost)
                          * item.quantity,
                      ),
                    ),
                  ).format('0,0.00')}
                </P>
              </Div>
              }
            </Div>
          </Div>
          <Div
            display="flex"
            flexDirection="row"
            justifyContent="center"
            alignItems="center"
            width="100%"
            margin="20px 0"
          >
            <Button
              margin="0 4px 0 0"
              text="ยกเลิก"
              icon="fal fa-times"
              color={theme.color.error}
            />
            <Button
              loading={mutationLoading}
              disabled={
                validateData(
                  true,
                  isCreateMode
                    ? [
                      group.sku,
                      group.name,
                      group.reference,
                      group.useDate && group.availableDate
                        ? moment.isMoment(group.availableDate[0]) || null
                        : 'noCheck',
                      group.useDate && group.availableDate
                        ? moment.isMoment(group.availableDate[1]) || null
                        : 'noCheck',
                      ..._.flatten(
                        group.ProductSetItems.map((item) => [
                          item.packId,
                          item.quantity,
                          item.salesPrice,
                        ]),
                      ),
                    ]
                    : [
                      group.sku,
                      group.name,
                      group.reference,
                      group.useDate && group.availableDate
                        ? moment.isMoment(group.availableDate[0]) || null
                        : 'noCheck',
                      group.useDate && group.availableDate
                        ? moment.isMoment(group.availableDate[1]) || null
                        : 'noCheck',
                    ],
                  {},
                ) || mutationLoading
              }
              inverse
              color={theme.color.success}
              text={isCreateMode ? 'สร้างเซตสินค้า' : 'แก้ไขเซตสินค้า'}
              icon="far fa-check"
              style={{ alignItems: 'center' }}
              width="fit-content"
              margin="0 4px"
              onClick={() => {
                if (isCreateMode) {
                  doAction({
                    variables: _.omit(
                      {
                        ...group,
                        files:
                          _.size(group.files) > 0 ? group.files : undefined,
                        availableStart: _.isNil(group.availableDate)
                          ? undefined
                          : group.availableDate[0],
                        availableEnd: _.isNil(group.availableDate)
                          ? undefined
                          : group.availableDate[1],
                        ProductSetItems: group.ProductSetItems.map((item) => _.omit({
                          ...item,
                          salesPrice: parseFloat(item.salesPrice),
                        }, ['productObject', 'id'])),
                        ProductSetPricingTypes: group.ProductSetPricingTypes ? group.ProductSetPricingTypes.map((item) => _.omit(item, ['__typename', 'pricingType'])) : undefined,
                      },
                      [],
                    ),
                  }).then((res) => {
                    if (res.data.CreateProductSet.success) {
                      if (callback) {
                        callback(`_set#${res.data.CreateProductSet?.data?.id}`, `${res.data.CreateProductSet?.data.sku} - ${res.data.CreateProductSet?.data.name}`)
                      } else {
                        history.push('/product-groups')
                      }
                      message.success(res.data.CreateProductSet.message)
                    } else message.error(res.data.CreateProductSet.message)
                  })
                } else {
                  doAction({
                    variables: _.omit(
                      {
                        ...group,
                        files:
                          _.size(group.files) > 0 ? group.files : undefined,
                        availableStart: _.isNil(group.availableDate)
                          ? undefined
                          : group.availableDate[0],
                        availableEnd: _.isNil(group.availableDate)
                          ? undefined
                          : group.availableDate[1],
                        ProductSetItems: group.ProductSetItems.map((item) => ({
                          salesPrice: parseFloat(item.salesPrice),
                          id: item.id,
                        })),
                        ProductSetPricingTypes: group.ProductSetPricingTypes ? group.ProductSetPricingTypes.map((item) => _.omit(item, ['__typename', 'pricingType'])) : undefined,
                      },
                      [],
                    ),
                  }).then((res) => {
                    if (res.data.UpdateProductSet.success) {
                      message.success(res.data.UpdateProductSet.message)
                      history.push('/product-groups')
                    } else message.error(res.data.UpdateProductSet.message)
                  })
                }
              }}
            />
          </Div>
        </Div>
      )}
    </FullPageContainer>
  )
}

export default (props) => {
  const isCreateMode = _.includes(props.match.url, 'create')
  const pricing = useQuery(PRICING_TYPES_LISTS)
  const { data, loading } = useQuery(PRODUCT_SET_INFO, {
    variables: { id: parseInt(props.match.params.id) },
    skip: isCreateMode,
  })

  return (
    <Div>
      <Helmet>
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>
      <Form
        data={data?.ProductSetInfo?.data}
        loading={pricing.loading}
        setLoading={loading}
        pricingTiers={pricing.data}
        isCreateMode={isCreateMode}
      />
    </Div>
  )
}
