/* eslint-disable no-underscore-dangle */
import { Pagination, Button, Col, Divider, Row, Modal as ModalAntd, notification } from 'antd'
import { Div, P } from 'konsys-ui'
import { useEffect, useState } from 'react'
import { useLazyQuery, useMutation, useQuery } from '@apollo/client'
import { compact, concat, isEmpty, isNaN, isNil, isNumber, set, some } from 'lodash'
import numeral from 'numeral'
import { GET_ITEMS, PRICING_TYPES_LISTS, TOTAL_MATCH, UNMATCHED_ORDER_LIST, VARIANT_INFO } from '../../api/query'
import { delayInput } from '../../utils'
import { Select, Loading, Modal } from '../../components'
import { Form as FormInventory } from '../inventory/form'
import { Form as FormSet } from '../product-group/form'
import { theme } from '../../styles/_variables'
import { MATCH_ORDER } from '../../api/mutation'

const AddProductModal = (props) => (
  <Modal
    title='เพิ่มสินค้าใหม่'
    width='1280px'
    visible={props.addProduct}
    footer={null}
    onAction={(type) => {
      if (type === 'back') {
        props.setAddProduct(false)
      }
    }}
  >
    <FormInventory
      callback={(itemId, name) => {
        props.setNewProduct(itemId, name)
        props.setAddProduct(false)
      }}
      isCreateMode={true}
      prefillCreate={props.item}
    />
  </Modal>
)

const AddSetModal = (props) => {
  const pricing = useQuery(PRICING_TYPES_LISTS)
  return (
    <Modal
      title='เพิ่มเซตสินค้าใหม่'
      width='1280px'
      visible={props.addSet}
      footer={null}
      onAction={(type) => {
        if (type === 'back') {
          props.setAddSet(false)
        }
      }}
    >
      <FormSet
        callback={(itemId, name) => {
          console.info(itemId, name)
          props.setNewSet(itemId, name)
          props.setAddSet(false)
        }}
        loading={pricing.loading}
        pricingTiers={pricing.data}
        isCreateMode={true}
        prefillCreate={props.item}
      />
    </Modal>
  )
}

const ItemsInput = ({ item, onChange }) => {
  const [search, setSearch] = useState('')
  const {
    loading: productLoading,
    data: productData,
  } = useQuery(GET_ITEMS, { variables: { search, limit: 10 }, skip: !search })
  // const [getItemInfo, { loading: itemInfoLoading, data: itemInfoData }] = useLazyQuery(VARIANT_INFO)
  const [addProduct, setAddProduct] = useState(false)
  const [addSet, setAddSet] = useState(false)

  return (
    <>
      <AddProductModal
        setAddProduct={setAddProduct}
        addProduct={addProduct}
        setNewProduct={(itemId, name) => {
          onChange({ itemId, name })
        }}
        item={item}
        // refetch={refetch}
      />
      <AddSetModal
        setAddSet={setAddSet}
        addSet={addSet}
        setNewSet={(itemId, name) => {
          onChange({ itemId, name })
        }}
        item={item}
        // refetch={refetch}
      />
      <Select
        tooltip
        width="100%"
        margin="0 16px 0 0"
        showSearch
        loading={productLoading}
        style={{ width: '100%' }}
        placeholder="เลือกสินค้า"
        notFoundContent={null}
        filterOption={false}
        value={item.itemWW.name}
        onSelect={(e, other) => {
          if (e === 'add-product') {
            setAddProduct(true)
          } else if (e === 'add-set') {
            setAddSet(true)
          } else {
            console.info(e, other)
            onChange({ itemId: e, name: other.text })
          }
        }}
        onSearch={(value) => {
          const searchValue = value
          delayInput(() => {
            setSearch(searchValue)
          })
        }}
        options={
          compact(
            concat(
              { value: 'add-set', text: 'เพิ่มเซตสินค้า' },
              { value: 'add-product', text: 'เพิ่มสินค้า' },
              (productData && productData.variantList)
                ? productData?.variantList?.data?.map((val) => ({
                  value: val.id,
                  text: val.variantName,
                }))
                : null,
            ),
          )
        // productData && productData.variantList
        //   ?
        //   : []
        }
        // className={
        //   ((isEmpty(item.packId) && !isNumber(item.packId))
        //     || isNaN(item.packId))
        //   && 'state-error'
        // }
      />
    </>
  )
}

const OrderItems = ({ order, refetch }) => {
  const [items, setItems] = useState([])
  useEffect(() => {
    setItems(order.unmatchedOrderItems.map((item) => ({
      id: item.id,
      name: item.productName,
      ordersn: order.ordersn,
      image: item.productUrl,
      channel: order.chatProvider.name,
      customerName: order.customerName,
      itemWW: {},
    })))
  }, [order])

  const [matchOrder, { loading: matching }] = useMutation(MATCH_ORDER)
  const doMatch = (isMatchAll) => {
    matchOrder({
      variables: {
        input: {
          isMatchAll,
          unmatchedOrderId: order.id,
          unmatchedOrderItems: items.map((i) => ({
            id: i.id,
            productName: i.name,
            matchedId: i.itemWW.itemId,
          })),
        },
      },
    }).then(({ data }) => {
      if (data?.matchOrder?.success) {
        notification.success({
          message: 'ทำการทดแทนสำเร็จ',
        })
        refetch()
      } else {
        notification.error({
          message: 'ไม่สามารถทดแทนได้',
          description: data?.matchOrder?.message,
        })
      }
    })
  }

  const [getMatchedOrder, { loading: checking }] = useLazyQuery(TOTAL_MATCH, {
    onCompleted: (resp) => {
      if (resp?.totalMatch?.data > 1) {
        ModalAntd.confirm({
          title: `ต้องการทดแทนอีก ${numeral(resp?.totalMatch?.data - 1).format('0,0')} ออเดอร์ด้วยเลยหรือไม่?`,
          onCancelText: 'ทดแทนเฉพาะออเดอร์นี้',
          onOkText: 'ทดแทนทั้งหมด',
          onCancel: () => {
            doMatch(false)
          },
          onOk: () => {
            doMatch(true)
          },
        })
      } else {
        doMatch(false)
      }
    },
  })

  return (
    <Div width='100%' display='flex' flexDirection='column' margin='24px 0' padding='16px' style={{ border: `1px solid ${theme.color.gray30}`, borderRadius: 8 }}>
      {
        items.map((item) => (
          <Row key={item.id} gutter={6} style={{ width: '100%', margin: '8px 0' }}>
            <Col span={8}>
              <Div width='100%' display='flex'>
                <img
                  src={item.image}
                  style={{ width: 56, height: 56, marginRight: 16 }}
                  alt={item.name}
                />
                <P style={{ whiteSpace: 'pre-line' }}>{item.name}</P>
              </Div>
            </Col>
            <Col span={3}>
              <P style={{ wordBreak: 'break-all' }}>{item.ordersn}</P>
            </Col>
            <Col span={3}>
              <P style={{ whiteSpace: 'pre-line' }}>{item.channel}</P>
            </Col>
            <Col span={3}>
              <P style={{ whiteSpace: 'pre-line' }}>{item.customerName}</P>
            </Col>
            <Col span={7}>
              <ItemsInput item={item} onChange={({ itemId, name }) => setItems(
                items.map((i) => (i.id === item.id ? { ...i, itemWW: { itemId, name } } : i)),
              )}/>
            </Col>
          </Row>
        ))
      }
      <Divider />
      <Button
        type='primary'
        style={{ alignSelf: 'flex-end' }}
        disabled={some(items.map((o) => isNil(o.itemWW.itemId)))}
        loading={checking || matching}
        onClick={() => getMatchedOrder({
          variables: {
            totalMatchId: order.id,
          },
        })}
      >
        ยืนยันออเดอร์
      </Button>
    </Div>
  )
}

export default () => {
  const LIMIT = 10
  const [currentPage, setCurrentPage] = useState(1)
  const [getUnmatchedOrder, { data, loading, refetch }] = useLazyQuery(UNMATCHED_ORDER_LIST)

  useEffect(() => {
    getUnmatchedOrder({
      variables: {
        input: {
          limit: LIMIT,
          offset: (currentPage - 1) * LIMIT,
        },
      },
    })
  }, [])

  if (loading && !data) {
    return (
      <Div bgColor={theme.color.offWhite} width='100%' height='100%' display='flex' flexDirection='column' justifyContent='center' alignItems='center' padding='40px 0'>
        <Loading />
      </Div>
    )
  } return (
    <Div bgColor={theme.color.offWhite} width='100%' height='100%' display='flex' flexDirection='column' padding='40px 0' style={{ overflow: 'auto' }}>
      <Div padding='64px 24px' width='100%'>
        <Row gutter={6} style={{ width: '100%', padding: '0 16px', margin: '8px 0' }}>
          <Col span={8}>
            <P bold>รายละเอียดสินค้า</P>
          </Col>
          <Col span={3}>
            <P bold>Order Number</P>
          </Col>
          <Col span={3}>
            <P bold>ชื่อช่องทาง</P>
          </Col>
          <Col span={3}>
            <P bold>ชื่อลูกค้า</P>
          </Col>
          <Col span={7}>
            <P bold>จัดการสินค้า</P>
          </Col>
        </Row>
        {
          data?.unmatchedOrderList?.data?.list?.map((order) => (
            <OrderItems key={order.id} order={order} refetch={refetch} />
          ))
        }
      </Div>
      <Pagination
        style={{ marginTop: '16px', alignSelf: 'center' }}
        defaultCurrent={1}
        current={currentPage}
        showSizeChanger={false}
        total={data?.unmatchedOrderList?.data?.total}
        onChange={(e) => {
          setCurrentPage(e)
          getUnmatchedOrder({
            variables: {
              input: {
                limit: LIMIT,
                offset: (e - 1) * 10,
              },
            },
          })
        }}
      />
    </Div>
  )
}
