import React, { useState, useEffect, useContext } from 'react'
import _, { sum } from 'lodash'
import { useQuery } from '@apollo/client'
import {
  Div, P,
} from '@konsys-ui-custom'
import { Row, Col } from 'antd'
import numeral from 'numeral'
import moment from 'moment-timezone'
import { theme } from '../../styles/_variables'
import {
  Remark, LargeTextWithLabel, Collapse, Divider, Loading, TextBetween,
} from '../../components'
import {
  DELIVERY_TYPE,
} from './constants'
import { Image, ThumbnailImage } from '../inventory/styled'
import imageNotAvailable from '../../assets/image-not-available.png'
import { PRODUCT_SET_INFO } from '../../api/query'
import { AuthorizationContext } from '../../store/StoreProvider'

export const renderDeliveryType = (value) => {
  switch (value) {
    case DELIVERY_TYPE.DELIVERY:
      return 'จัดส่ง'
    case DELIVERY_TYPE.PICKUP:
      return 'รับเอง'
    case DELIVERY_TYPE.PICKUPSUPPLIER:
      return 'รับเองจากผู้ผลิต'
    default:
      return 'ไม่ระบุ'
  }
}

export const tableStyled = {
  0: {
    width: '40%',
  },
  1: {
    width: '15%',
    margin: '0 8px 0 0',
  },
  2: {
    width: '15%',
  },
  3: {
    width: '15%',
  },
  4: {
    width: '15%',
  },
}

export const headerData = [
  {
    text: 'สินค้า',
  },
  {
    text: 'จำนวน',
    textAlign: 'right',
  }, {
    text: 'ราคา / หน่วย',
    textAlign: 'right',
  }, {
    text: 'ส่วนลด / หน่วย',
    textAlign: 'right',
  }, {
    text: 'ราคารวม',
    textAlign: 'right',
  },
]

export default (props) => {
  const [isOpenProduct, setIsOpenProduct] = useState(true)
  const [data, setData] = useState()
  const [imageIndex, setImageIndex] = useState(0)
  const { loading: pdsLoading, data: pdsDetail } = useQuery(PRODUCT_SET_INFO, { variables: { id: props.data.id } })
  const { authorization } = useContext(AuthorizationContext)

  useEffect(() => {
    setData(pdsDetail?.ProductSetInfo?.data)
  }, [pdsDetail?.ProductSetInfo?.data])

  if (pdsLoading) {
    return (
      <Div textAlign='center' margin='32px 0 0'>
        <Loading size='small' />
      </Div>
    )
  }
  if (_.isEmpty(data)) return null

  return (
    <Div>
      <Div display='flex' justifyContent='space-between' width='100%' margin='0 0 20px 0'>
        <Div display='flex' flexDirection='column'>
          <LargeTextWithLabel margin='0 0 16px 0' title={{ text: 'SKU' }} content={{ text: data.sku }} />
          <LargeTextWithLabel margin='0 0 16px 0' title={{ text: 'ชื่อย่อ' }} content={{ text: data.reference }} />
          <LargeTextWithLabel margin='0 0 16px 0' title={{ text: 'ชื่อเซตสินค้า' }} content={{ text: data.name }} />
          <LargeTextWithLabel margin='0 0 16px 0' title={{ text: 'วันที่กำหนดใช้ได้' }} content={{ text: data.useDate ? `${moment(data.availableStart).format('DD/MM/YYYY')} - ${moment(data.availableEnd).format('DD/MM/YYYY')}` : 'ไม่มีกำหนด' }} />
        </Div>
        <Div display='flex' flexDirection='column' alignItems='center'>
          <Image size='174px' src={data?.files[imageIndex]?.url || imageNotAvailable} alt='' />
          {
            data.files?.length > 1
            && <Div display='flex' flexDirection='row' width='250px' justifyContent='center'>
              {
                data.files.map((file, i) => (
                  <Div key={i} onClick={() => setImageIndex(i)}>
                    <ThumbnailImage src={file.url} size='30px'/>
                  </Div>
                ))
              }
            </Div>
          }
        </Div>
      </Div>
      <Remark showIcon={true} message='หมายเหตุ' description={data.remark || '-'}/>
      <Collapse style={{ margin: '48px 0' }} fullHeight={true} text='รายละเอียด' open={isOpenProduct} onClick={() => setIsOpenProduct(!isOpenProduct)}>
        <Div display='flex' flexDirection='column' padding='16px' margin='16px 0' style={{ border: `1px solid ${theme.color.lightBlue}`, borderRadius: 5 }}>
          <Row type='flex' style={{ marginBottom: 8 }}>
            <Col span={1} />
            <Col span={11}>
              <P bold>ชื่อสินค้า</P>
            </Col>
            <Col span={3}>
              <P bold>จำนวน</P>
            </Col>
            <Col span={3}>
              <P bold>ราคาขาย</P>
            </Col>
            {
              authorization.productSet.showCost
              && <Col span={3}>
                <P bold>ต้นทุน</P>
              </Col>
            }
            {
              authorization.productSet.showCost
              && <Col span={3}>
                <P bold>กำไร</P>
              </Col>
            }
          </Row>
          {
            data?.ProductSetItems?.map((item, index) => (
              <Row key={item.id} type='flex' style={{ marginBottom: 8 }}>
                <Col span={1}>
                  <P>{index + 1}</P>
                </Col>
                <Col span={11}>
                  <P>{item.Pack.variantName}</P>
                </Col>
                <Col span={3}>
                  <P>{numeral(item.quantity).format('0,0.00')}</P>
                </Col>
                <Col span={3}>
                  <P>{numeral(item.salesPrice).format('0,0.00')}</P>
                </Col>
                {
                  authorization.productSet.showCost
                  && <Col span={3}>
                    <P>{numeral(item.Pack.inventoryOption?.avgCost).format('0,0.00')}</P>
                  </Col>
                }
                {
                  authorization.productSet.showCost
                  && <Col span={3}>
                    <P>{numeral(item.salesPrice - item.Pack.inventoryOption?.avgCost).format('0,0.00')}</P>
                  </Col>
                }
              </Row>
            ))
          }
          <Divider />
          <TextBetween title={{ text: 'ราคาขายรวม' }} content={{ text: `${numeral(sum(data?.ProductSetItems.map((item) => (item.salesPrice * item.quantity)))).format('0,0.00')} บาท` }} />
          {
            authorization.productSet.showCost
            && <TextBetween title={{ text: 'ต้นทุนรวม' }} content={{ text: `${numeral(sum(data?.ProductSetItems.map((item) => (item.Pack?.inventoryOption?.avgCost * item.quantity)))).format('0,0.00')} บาท` }} />
          }
          {
            authorization.productSet.showCost
            && <TextBetween title={{ text: 'กำไรรวม' }} content={{ text: `${numeral(sum(data?.ProductSetItems.map((item) => ((item.salesPrice - item.Pack?.inventoryOption?.avgCost) * item.quantity)))).format('0,0.00')} บาท` }} />
          }
        </Div>
      </Collapse>
    </Div>
  )
}
