import { ExclamationCircleOutlined } from '@ant-design/icons'
import { useLazyQuery, useMutation, useQuery } from '@apollo/client'
import {
  Button,
  Checkbox,
  Div,
  H3,
  Label,
  P,
  SmallText,
} from '@konsys-ui-custom'
import {
  Checkbox as CheckboxAntd,
  Col, DatePicker,
  InputNumber,
  Modal as ModalAntd,
  Popconfirm,
  Radio,
  Row,
  Select,
  message,
} from 'antd'
import _, { filter, find, first, includes, isEmpty, last, some, sortBy, sumBy } from 'lodash'
import moment from 'moment-timezone'
import { nanoid } from 'nanoid'
import numeral from 'numeral'
import React, { useContext, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { Link, useHistory } from 'react-router-dom'
import { CANCEL_SO, CREATE_CHAT_PROVIDER, CREATE_SO, UPDATE_SALE_ORDER } from '../../api/mutation'
import {
  CHAT_PROVIDERS,
  CUSTOMERS,
  DEFAULT_DELIVERY_CHANNEL,
  GET_NEXT_SO_NUMBER,
  ORDER_BONUS_BY_ROLE,
  QUOTATION_INFO,
  SALE_ORDER,
  TAX_INVOICE_BY_CUSTOMER,
  USER,
  USERS,
} from '../../api/query'
import {
  BillingNewAddress,
  ComponentWithLabelLarge,
  DashedButton,
  Divider,
  FullPageContainer,
  InputWithLabel,
  Modal,
  NewAddress,
  Remark,
  SelectWithLabel,
  SummaryPrice,
  TextareaWithLabel,
  Uploader,
} from '../../components'
import { SelectTaxInvoiceModal } from '../../components/sections'
import { SO_TYPE } from '../../constants/enum'
import { AuthorizationContext } from '../../store/StoreProvider'
import { theme } from '../../styles/_variables'
import { delayInput, isAlphaNumeric } from '../../utils'
import { getLocalStorage, getTokenData } from '../../utils/local-data-service'
import {
  getRequireBillingAddressField, renderAddress,
  validateData,
} from '../../utils/util-services'
import CustomerForm from '../customers/form'
import { ItemsInputRenderer } from './components'
import {
  DELIVERY_CHANNEL,
  DELIVERY_TYPE,
  ORDER_CLAIM_TYPE,
  ORDER_TYPE,
  PAYMENT_TYPE,
  RECEIVE_TYPE,
} from './constants'
import { CancelSoButton } from './styled'

message.config({
  maxCount: 1,
})

const receiveToDeliveryType = (type) => {
  switch (type) {
    case RECEIVE_TYPE.TRANSFER:
      return DELIVERY_TYPE.DELIVERY
    case RECEIVE_TYPE.MANUAL:
      return DELIVERY_TYPE.PICKUP
    case RECEIVE_TYPE.MANUAL_SUPPLIER:
      return DELIVERY_TYPE.PICKUPSUPPLIER
    default: return null
  }
}

const AddCustomerModal = (props) => (
  <Modal
    title='เพิ่มลูกค้าใหม่'
    width='850px'
    visible={props.addCustomer}
    footer={null}
    onAction={(type) => {
      if (type === 'back') {
        props.setAddCustomer(false)
      }
    }}
  >
    <CustomerForm quickAdd={true} createMode onCreateSuccess={(newCustomer) => {
      props.setAddCustomer(false)
      props.setNewCustomer(newCustomer)
      props.refetch()
    }} />
  </Modal>
)

const AddChatProviderModal = (props) => {
  const [name, setName] = useState('')
  const [code, setCode] = useState('')
  const [doCreateChatProvider, { loading: creating }] = useMutation(CREATE_CHAT_PROVIDER)
  return (
    <Modal
      title='เพิ่มช่องทางใหม่'
      width='850px'
      visible={props.addChatProvider}
      footer={[
        <Button
          key='submit'
          text='ยืนยันเพิ่มช่องทางใหม่'
          color={theme.color.primaryColor}
          disabled={isEmpty(name) || isEmpty(code) || !isAlphaNumeric(code)}
          inverse
          loading={creating}
          onClick={() => doCreateChatProvider({
            variables: {
              input: {
                name,
                code,
              },
            },
          }).then((res) => {
            if (res.data.createChatProvider.success) {
              setName('')
              setCode('')
              props.setAddChatProvider(false)
              props.setNewChatProvider(res.data.createChatProvider.data)
              props.refetch()
            } else message.error(res.data.createChatProvider.message)
          })}
        />,
      ]}
      onAction={(type) => {
        if (type === 'back') {
          setName('')
          setCode('')
          props.setAddChatProvider(false)
        }
      }}
    >
      <Row type='flex' gutter={16}>
        <Col span={12}>
          <InputWithLabel
            width='100%'
            input={{
              value: name,
              width: '100%',
              state: isEmpty(name) ? 'error' : 'default',
            }}
            onChange={(e) => setName(e.target.value)}
            title={{ text: <SmallText bold>ชื่อ <Label color={theme.color.error}>*</Label></SmallText> }}
          />
        </Col>
        <Col span={12}>
          <InputWithLabel
            width='100%'
            input={{
              value: code,
              width: '100%',
              state: isEmpty(code) ? 'error' : 'default',
            }}
            onChange={(e) => {
              setCode(e.target.value)
            }}
            title={{ text: <SmallText bold>Code <Label color={theme.color.error}>*</Label></SmallText> }}
          />
        </Col>
      </Row>
    </Modal>
  )
}

export default (props) => {
  const history = useHistory()
  const isCreateMode = _.includes(props.match.url, 'create')
  const isUpsellMode = _.includes(props.match.url, 'upsell')
  const [isLoading, setIsLoading] = useState(false)
  const [remarkMessage] = useState('')
  const { loading, data } = useQuery(SALE_ORDER, { skip: isCreateMode, variables: { id: parseInt(props.match.params.id) } })
  const { loading: chatProvidersLoading, data: chatProvidersData, refetch: refetchChatProvider } = useQuery(CHAT_PROVIDERS, { variables: { isWithoutPage: true } })
  const [customerSearch, setCustomerSearch] = useState('')
  const { data: defaultDeliveryChannelData } = useQuery(DEFAULT_DELIVERY_CHANNEL)
  const [getOrderBonusAdmin, { data: orderBonusAdmin }] = useLazyQuery(ORDER_BONUS_BY_ROLE)
  const [getOrderBonusUpsell, { data: orderBonusUpsell }] = useLazyQuery(ORDER_BONUS_BY_ROLE)
  const {
    loading: customersLoading, data: customersData, refetch,
  } = useQuery(CUSTOMERS, { variables: { name: customerSearch, limit: 10, offset: 0 }, skip: !customerSearch && _.isUndefined(props.match.params?.quotationId) })
  const [getSoNumber, { data: SONumberData, loading: SONumberLoading }] = useLazyQuery(GET_NEXT_SO_NUMBER)
  const [doAction, { loading: mutationLoading }] = useMutation(isCreateMode ? CREATE_SO : UPDATE_SALE_ORDER, { refetchQueries: ['remainingJob', 'remainingAccountant'] })

  const [mutationVariables, setMutationVariables] = useState({ type: PAYMENT_TYPE.CASH, files: [], isCOD: 'cod', createdAt: moment() })
  const [customer, setCustomer] = useState()
  const [lots, setLots] = useState([{
    id: 1, items: [{ id: nanoid(), discount: 0, warehouseId: 1 }], deliveryType: DELIVERY_TYPE.DELIVERY, useNewAddress: false, useNewBillingAddress: false, pickingTicket: null,
  }])
  const [addCustomer, setAddCustomer] = useState(false)
  const [addChatProvider, setAddChatProvider] = useState(false)
  const [selectedTaxInvoiceList, setSelectedTaxInvoiceList] = useState([])
  const [modalVisible, setModalVisible] = useState(false)
  const { authorization } = useContext(AuthorizationContext)

  const [getQuotationInfo, { loading: quotationInfoLoading, data: quotationInfoData }] = useLazyQuery(QUOTATION_INFO)
  const [doCancelSo, { loading: canceling }] = useMutation(CANCEL_SO)
  const [getTaxInvoiceByCustomer, { data: taxInvoiceData, loading: taxInvoiceLoading }] = useLazyQuery(TAX_INVOICE_BY_CUSTOMER)
  const [getUsers, {
    data: userData, loading: userLoading,
  }] = useLazyQuery(USERS)
  const [getUpsell, {
    data: upsellData,
  }] = useLazyQuery(USER)
  const [getOrderClaimUsers, {
    data: orderClaimUserData, loading: orderClaimUserLoading,
  }] = useLazyQuery(USERS)

  const soData = data?.salesOrderDetail?.data

  useEffect(() => {
    setIsLoading(true)
    if (!isCreateMode && data && !loading) {
      if ((soData.salesOrderType === SO_TYPE[0].value && !authorization.salesOrder.createUpdatePOS)
      ) {
        history.push('/sale-orders')
      }
      const seller = find(first(soData.lots)?.items, (item) => !item.isUpsell)?.seller
      getUsers({ variables: {
        limit: 10,
        offset: 0,
        name: `${seller?.firstName} ${seller?.lastName}`,
      } })

      if (soData.claimType === ORDER_CLAIM_TYPE.SELLER_CUT.ENUM || soData.claimType === ORDER_CLAIM_TYPE.PT.ENUM) {
        getOrderClaimUsers({ variables: {
          id: soData.claimUser.id,
        } })
      }
      if (seller?.roles) {
        getOrderBonusAdmin({ variables: { roleId: first(seller?.roles).id } })
      }
      if (isUpsellMode || some(first(soData.lots)?.items, (item) => item.isUpsell)) {
        getUpsell({ variables: {
          id: getTokenData().userId,
        } })
      }

      setMutationVariables({
        id: soData.id,
        chatProviderId: soData.chatProvider?.id,
        customerId: soData.customer.id,
        type: soData.type,
        claimType: soData.claimType,
        claimUserId: soData.claimUser?.id,
        claimOrderAmount: soData.claimOrderAmount,
        createdAt: moment(soData.createdAt),
        contactAddressId: soData.contactAddress?.id,
        contactAddressObj: soData.contactAddress,
        files: soData.salesOrderFiles,
        remarks: soData.remarks,
        discount: first(soData.lots).discount,
        quotationId: _.isNaN(parseInt(soData?.quotation?.id)) ? '' : parseInt(soData?.quotation?.id),
        isPos: soData.salesOrderType === SO_TYPE[0].value, // to change
        seller,
        sellerId: seller?.id,
      })
      setCustomer(soData.customer)
      setLots(soData.lots.map((lot) => ({
        id: lot.id,
        lotId: lot.id,
        isTax: lot.requireTaxInvoice,
        items: lot.items.map((item) => ({
          id: item.id,
          name: item.type === ORDER_TYPE.SET ? item.productSet.name : item.pack.variantName,
          quantity: item.quantity,
          salesPrice: item.salesPrice,
          avgCost: item.avgCost,
          warehouseName: 'TODO', // TODO
          itemSource: item.itemSource,
          commission: item.commission,
          supplierName: item?.supplier?.name || '-',
          set: item?.productSet?.ProductSetItems || [],
          receivedQuantity: item?.receivedQuantity || 2,
          seller: item.seller,
          isUpsellItem: item.isUpsell,
          discount: item.discount,
          isFree: item.isFree,
          supplierList: item?.pack?.inventoryOption?.inventory?.supplier?.map((o) => ({ text: o?.name, value: o?.id })) || [],
        })),
        pickingTicket: lot.pickingTicket,
        newAddress: { ...lot.deliveryAddress },
        newBillingAddress: { ...lot.billingAddress },
        billingAddressId: !_.isNull(lot.billingAddress?.customerId) ? lot.billingAddress?.id : undefined,
        deliveryAddressId: !_.isNull(lot.deliveryAddress?.customerId) ? lot.deliveryAddress?.id : undefined,
        deliveryDate: lot.deliveryDate ? moment(lot.deliveryDate) : undefined,
        deliveryType: lot.deliveryType,
        deliveryAddress: lot.deliveryAddress,
        deliveryCost: lot.deliveryCost,
        discount: lot.discount,
        warehouseId: 1,
        deliveryAddressObj: lot.deliveryAddress,
        billingAddressObj: lot.billingAddress,
        useNewAddress: _.isNull(lot.deliveryAddress?.customerId),
        useNewBillingAddress: _.isNull(lot.billingAddress?.customerId),
      })))
    }
  }, [loading, data])

  useEffect(() => {
    if ((upsellData && first(upsellData?.userDetail?.data?.roles)?.id) || getTokenData().roles[0].id) {
      getOrderBonusUpsell({ variables: { roleId: first(upsellData?.userDetail?.data?.roles)?.id || getTokenData().roles[0].id } })
    }
  }, [upsellData])

  useEffect(() => {
    if (props.match.params?.quotationId) {
      getQuotationInfo({
        variables: {
          id: parseInt(props.match.params?.quotationId),
        },
      })
    }
    if (isCreateMode) {
      getUsers({ variables: {
        limit: 10,
        offset: 0,
        name: getLocalStorage('fullname'),
      } })
    }
  }, [])

  useEffect(() => {
    if (userData && !userLoading && first(upsellData?.userDetail?.data?.roles)?.id) {
      getOrderBonusAdmin({ variables: { roleId: first(upsellData?.userDetail?.data?.roles)?.id } })
      setMutationVariables({
        ...mutationVariables,
        seller: first(userData?.userList?.data?.list),
        sellerId: first(userData?.userList?.data?.list)?.id,
      })
    }
  }, [userData])

  useEffect(() => {
    if (defaultDeliveryChannelData) {
      setMutationVariables({
        ...mutationVariables,
        deliveryChannel: find(defaultDeliveryChannelData?.defaultDeliveryChannel?.data, (o) => o.isSet)?.deliveryChannel,
      })
    }
  }, [defaultDeliveryChannelData])

  useEffect(() => {
    if (!quotationInfoLoading && !_.isEmpty(quotationInfoData?.quotationInfo?.data)) {
      const selectedQuotation = quotationInfoData.quotationInfo.data
      setMutationVariables({
        ...mutationVariables,
        quotationId: selectedQuotation.id,
        customerId: selectedQuotation.customerId,
        type: selectedQuotation.paymentType,
        contactAddressId: selectedQuotation.contactAddress?.id,
        contactAddressObj: selectedQuotation.contactAddress,
        createdAt: moment(soData.createdAt),
      })
      setLots(_.map(selectedQuotation?.quotationLots, (lot, i) => (
        {
          id: i + 1,
          isTax: lot.isTax,
          items: lot?.quotationItems?.map((v, index) => (
            {
              id: index + 1,
              packId: !_.isEmpty(v?.pack) ? v?.pack?.id : v?.set?.id,
              supplierId: !_.isEmpty(v?.pack) ? v?.pack?.inventoryOption?.inventory?.supplier[0]?.id : v?.set?.suppliers[0]?.id,
              productObject: {
                id: !_.isEmpty(v?.pack) ? `${v?.pack?.id}` : `${v?.set?.id}`,
                type: !_.isEmpty(v?.pack) ? 'PACK' : 'SET',
                variantName: !_.isEmpty(v?.pack) ? v?.pack?.variantName : v?.set?.name,
                quantity: v?.quantity,
                inventoryOption: v?.pack?.inventoryOption || v?.set?.inventoryOption || {},
              },
              itemSource: 'STOCK',
              items: [],
              discount: v?.discount,
              quantity: v?.quantity,
              salesPrice: v?.price,
              quotationPrice: v?.price,
              supplierList: !_.isEmpty(v?.pack) ? v?.pack?.inventoryOption?.inventory?.supplier : v?.set?.suppliers,
              inventoryOption: v?.pack?.inventoryOption || v?.set?.inventoryOption || {},
            }
          )) || [
            {
              id: 1,
              discount: 0,
            },
          ],
          deliveryType: receiveToDeliveryType(lot?.receiveType),
          deliveryCost: lot?.deliveryCost,
          deliveryAddressObj: lot?.lotDeliveryAddress,
          deliveryAddressId: lot?.deliveryAddressId,
          deliveryDate: _.isEmpty(lot?.deliveryDate) ? null : moment(lot?.deliveryDate),
          billingAddressId: lot.billingAddressId,
          billingAddressObj: lot.lotBillingAddress,
        }
      )))
      setCustomer(selectedQuotation?.customer)
    }
  }, [quotationInfoData?.quotationInfo?.data])

  useEffect(() => {
    if (!_.isNil(mutationVariables?.customerId) && mutationVariables?.isPos) {
      getTaxInvoiceByCustomer({
        variables: {
          customerId: mutationVariables.customerId,
        },
      })
    }
  }, [mutationVariables?.customerId, mutationVariables?.isPos])

  const menu = isCreateMode ? [
    {
      text: 'สร้างใบออเดอร์',
      icon: 'far fa-plus',
    },
  ] : isUpsellMode ? [
    {
      text: 'Upsell',
      icon: 'far fa-chart-line',
    },
  ] : [
    {
      text: 'แก้ไขใบออเดอร์',
      icon: 'far fa-edit',
    },
  ]

  const customerShippingAddressList = (customer && _.isArray(customer.shippingAddress) && customer.shippingAddress.length > 0) ? _.map(customer.shippingAddress, (address) => (
    { value: address.id, text: renderAddress(address), addessData: address }
  )) : []

  const customerBillingAddressList = (customer && _.isArray(customer.billingAddress) && customer.billingAddress.length > 0) ? _.map(customer.billingAddress, (address) => (
    { value: address.id, text: renderAddress(address, 'billing'), addessData: address }
  )) : []

  const isFromQuotation = !_.isUndefined(mutationVariables.quotationId)

  const isShowCancelButton = !isCreateMode && (soData?.type === 'CASH' ? _.isEmpty(_.compact(_.chain(soData?.lots).map('receipt').flatten().value())) : _.isEmpty(_.compact(_.chain(soData?.lots).map('invoice').flatten().value())))

  const customerHaveTaxInvoice = taxInvoiceData?.taxInvoiceListForPickingTicket?.data?.total > 0

  const createSO = () => {
    if (isCreateMode) {
      doAction({
        variables: _.omit({
          ..._.omit(mutationVariables, ['isPos']),
          isPOS: mutationVariables.isPos || false,
          isCOD: mutationVariables.isCOD === 'cod',
          claimOrderAmount: parseFloat(mutationVariables.claimOrderAmount),
          lots: lots.map((v, idx) => _.omit({
            ...v,
            taxInvoiceIds: (!customerHaveTaxInvoice || _.isEmpty(selectedTaxInvoiceList) || !mutationVariables.isPos || idx > 0) ? undefined : selectedTaxInvoiceList,
            requireTaxInvoice: v.isTax || false,
            newAddress: { ..._.omit(v.newAddress, ['zipcode', 'subdistrict']) },
            newBillingAddress: { ..._.omit(v.newBillingAddress, ['zipcode', 'subdistrict']) },
            items: v.items.map((item) => (
              {
                ..._.pick(item, ['packId', 'warehouseId', 'supplierId', 'quantity', 'salesPrice', 'discount', 'itemSource', 'isFree']), quantity: parseFloat(item.quantity), discount: parseFloat(item.discount), salesPrice: parseFloat(item.salesPrice),
              }
            )),
          }, [
            'id', 'useNewAddress', 'useNewBillingAddress', 'contactAddressObj', 'billingAddressObj', 'deliveryAddressObj', 'warehouseId', 'pickingTicket', 'isTax', 'chatProviderCode', 'seller',
          ])),
        }, ['contactAddressObj']),
      }).then((res) => {
        if (res.data.createSalesOrder.success) {
          message.success(res.data.createSalesOrder.message)
          history.push({
            pathname: '/sale-orders',
            state: { search: res.data.createSalesOrder.data.soNumber },
          })
        } else message.error(res.data.createSalesOrder.message)
      })
    } else {
      doAction({
        variables: _.omit({
          soId: parseInt(props.match.params.id),
          ...mutationVariables,
          lots: lots.map((v) => (
            _.pick({
              ...v,
              items: v.items.map((item) => _.pick({
                ...item,
                id: _.toNumber(item.id) ? item.id : undefined,
                quantity: parseFloat(item.quantity),
                salesPrice: parseFloat(item.salesPrice),
                discount: parseFloat(item.discount),
              }, ['id', 'quantity', 'salesPrice', 'packId', 'isUpsellItem', 'discount', 'isFree'])),
              newAddress: v.useNewAddress ? _.pick(v.newAddress, ['type', 'addressNo', 'province', 'district', 'subDistrict', 'postcode', 'contactName', 'contactPhoneNumber']) : undefined,
              newBillingAddress: v.useNewBillingAddress ? _.pick(v.newBillingAddress, ['type', 'addressNo', 'province', 'district', 'subDistrict', 'postcode', 'contactName', 'taxId']) : undefined,
            }, ['lotId', 'billingAddressId', 'deliveryAddressId', 'deliveryDate', 'deliveryType', 'items', 'newAddress', 'newBillingAddress', 'deliveryCost'])
          )),
        }, ['contactAddressObj', 'seller']),
      }).then((res) => {
        if (res.data.updateSalesOrder.success) {
          message.success(res.data.updateSalesOrder.message)
          history.push({
            pathname: '/sale-orders',
            state: { search: res.data.updateSalesOrder.data.soNumber },
          })
        } else message.error(res.data.updateSalesOrder.message)
      })
    }
  }

  const onReadyCreateSO = () => {
    if (customerHaveTaxInvoice && mutationVariables.isPos) {
      setModalVisible(true)
    } else if (some(first(lots)?.items, (o) => o.noStock)) {
      ModalAntd.confirm({
        title: 'มีสินค้าบางชิ้นไม่พอ?',
        icon: <ExclamationCircleOutlined />,
        content: 'ต้องการยืนยันคำสั่งซื้อหรือไม่',
        okText: 'ยืนยันคำสั่ง',
        okType: 'danger',
        cancelText: 'ยกเลิก',
        onOk() {
          createSO()
        },
      })
    } else {
      createSO()
    }
  }
  const adminSalesTotal = _.sum(filter(_.flatten(lots.map((lot) => lot.items)), (item) => !item.isUpsellItem).map((item) => item.quantity * (item.salesPrice - item.discount)))
  const bonusCommissionAdmin = last(sortBy(filter(orderBonusAdmin?.orderBonusByRole?.data || [], (bonus) => bonus.tierPrice <= adminSalesTotal), 'tierPrice'))?.bonus
  const upsellSalesTotal = _.sum(filter(_.flatten(lots.map((lot) => lot.items)), (item) => item.isUpsellItem).map((item) => item.quantity * (item.salesPrice - item.discount)))
  const bonusCommissionUpsell = last(sortBy(filter(orderBonusUpsell?.orderBonusByRole?.data || [], (bonus) => bonus.tierPrice <= upsellSalesTotal), 'tierPrice'))?.bonus

  return (
    <Div>
      <Helmet>
        <meta name="viewport" content="width=1280" />
      </Helmet>
      <FullPageContainer
        loading={(!isLoading && customersLoading)}
        title={[
          {
            text: 'Sale Order',
            icon: 'fad fa-receipt',
          },
        ]}
        detail={{
          title: menu,
        }}
      >
        <Div display='flex' flexDirection='column'>
          <H3 textAlign='right' color={theme.color.primaryColor} bold>#{isCreateMode ? !SONumberLoading && `${SONumberData?.nextSONumber || ''}` : soData?.soNumber}</H3>
          <Div display='flex' alignItems='flex-end'>
            <ComponentWithLabelLarge width='20%' margin='0 16px 0 0' title={{ text: <SmallText bold>วันที่สร้าง <Label color={theme.color.error}>*</Label></SmallText> }}>
              <DatePicker allowClear={false} style={{ width: '100%' }}
                value={mutationVariables.createdAt}
                disabled={!isCreateMode}
                // disabledDate={
                //   (current) => current && current < moment().startOf('day')
                // }
                onChange={(e) => {
                  setMutationVariables({ ...mutationVariables, createdAt: e })
                  if (mutationVariables.chatProviderId) {
                    getSoNumber({ variables: {
                      chatProviderId: mutationVariables.chatProviderId,
                      createdAt: e,
                    } })
                  }
                }}
                // className={_.isEmpty(v.deliveryDate) && 'state-error'} value={v.deliveryDate}
              />
            </ComponentWithLabelLarge>
            <SelectWithLabel
              width='20%'
              margin='0 16px 0 0'
              title={{
                text: <SmallText bold>ลูกค้า <Label color={theme.color.error}>*</Label></SmallText>,
              }}
              select={{
                value: (!_.isEmpty(customersData?.customerList?.data?.list) && _.find(customersData?.customerList?.data?.list, (v) => v.id === mutationVariables.customerId)?.name) || quotationInfoData?.quotationInfo?.data?.customer?.name || customer?.name || undefined,
                showSearch: true,
                loading: customersLoading,
                notFoundContent: null,
                filterOption: false,
                disabled: !isCreateMode || (!_.isNull(mutationVariables.quotationId) && mutationVariables.quotationId > 0),
                style: { width: '100%' },
                placeholder: 'เลือกลูกค้า',
                onSelect: (e, other) => {
                  if (e === 'add') {
                    setAddCustomer(true)
                  } else {
                    setLots(lots.map((v) => ({
                      ...v,
                      billingAddressId: undefined,
                      deliveryAddressId: undefined,
                      contactAddressId: undefined,
                      billingAddressObj: undefined,
                      deliveryAddressObj: undefined,
                      contactAddressObj: undefined,
                    })))
                    setMutationVariables({
                      ...mutationVariables,
                      customerId: e,
                      contactAddressId: other?.contactAddressId,
                      contactAddressObj: null,
                    })
                    setCustomer(_.find(customersData?.customerList?.data?.list, (o) => o.id === e))
                  }
                },
                onSearch: (value) => {
                  const searchValue = value
                  delayInput(() => {
                    setCustomerSearch(searchValue)
                  })
                },
                options: customersLoading
                  ? []
                  : authorization.client.create
                    ? _.concat({ value: 'add', text: 'เพิ่มลูกค้าใหม่' }, customersData?.customerList?.data?.list?.map((v) => ({ value: v.id, text: v.name, contactAddressId: first(v.contactAddress)?.id })) || [])
                    : (customersData?.customerList?.data?.list?.map((v) => ({ value: v.id, text: v.name, contactAddressId: first(v.contactAddress)?.id })) || []),
                className: ((_.isEmpty(mutationVariables.customerId) && !_.isNumber(mutationVariables.customerId)) || _.isNaN(mutationVariables.customerId)) && 'state-error',
              }}
            />
            <AddCustomerModal
              setAddCustomer={setAddCustomer}
              addCustomer={addCustomer}
              setNewCustomer={(resp) => {
                setMutationVariables({
                  ...mutationVariables,
                  customerId: resp.id,
                  contactAddressId: first(resp?.contactAddress)?.id,
                  contactAddressObj: null,
                })
                setCustomer(resp)
              }}
              refetch={refetch}
            />
            <SelectWithLabel
              width='20%'
              margin='0 16px 0 0'
              title={{
                text: <SmallText bold>ช่องทางการขาย <Label color={theme.color.error}>*</Label></SmallText>,
              }}
              select={{
                loading: chatProvidersLoading,
                disabled: !isCreateMode,
                style: { width: '100%' },
                placeholder: 'เลือกช่องทางการขาย',
                value: mutationVariables.chatProviderId,
                onSelect: (e, other) => {
                  if (e === 'add') {
                    setAddChatProvider(true)
                  } else {
                    setMutationVariables({ ...mutationVariables, chatProviderId: e, chatProviderCode: other.code })
                    getSoNumber({ variables: {
                      chatProviderId: e,
                      createdAt: mutationVariables.createdAt,
                    } })
                  }
                },
                options: chatProvidersData
                  ? _.concat({ value: 'add', text: 'เพิ่มช่องทางใหม่' }, chatProvidersData?.chatProviders?.data.map((v) => ({
                    id: v.id, value: v.id, text: v.name, code: v.code,
                  })))
                  : [],
              }}
            />
            <AddChatProviderModal
              setAddChatProvider={setAddChatProvider}
              addChatProvider={addChatProvider}
              setNewChatProvider={(resp) => {
                setMutationVariables({ ...mutationVariables, chatProviderId: resp.id, chatProviderCode: resp.code })
                getSoNumber({ variables: {
                  chatProviderId: resp.id,
                } })
              }}
              refetch={refetchChatProvider}
            />
            <SelectWithLabel
              width='20%'
              margin='0 16px 0 0'
              title={{
                text: <SmallText bold>Seller <Label color={theme.color.error}>*</Label></SmallText>,
              }}
              select={{
                value: (!_.isEmpty(userData?.userList?.data?.list) && mutationVariables.sellerId && `${_.find(userData?.userList?.data?.list, (v) => v.id === mutationVariables.sellerId)?.firstName} ${_.find(userData?.userList?.data?.list, (v) => v.id === mutationVariables.sellerId)?.lastName}`) || undefined,
                showSearch: true,
                loading: userLoading,
                notFoundContent: null,
                filterOption: false,
                style: { width: '100%' },
                placeholder: 'เลือก Seller',
                onSelect: (e, other) => {
                  if (first(other.user?.roles)?.id) {
                    getOrderBonusAdmin({ variables: { roleId: first(other.user?.roles)?.id } })
                    setMutationVariables({ ...mutationVariables, sellerId: e, seller: other.user })
                  }
                },
                onSearch: (value) => {
                  const searchValue = value
                  delayInput(() => {
                    getUsers({ variables: {
                      limit: 10,
                      offset: 0,
                      name: searchValue,
                    } })
                  })
                },
                options: userLoading
                  ? []
                  : (userData?.userList?.data?.list?.map((v) => ({ value: v.id, text: `${v.firstName} ${v.lastName}`, user: v })) || []),
                className: ((_.isEmpty(mutationVariables.sellerId) && !_.isNumber(mutationVariables.sellerId)) || _.isNaN(mutationVariables.sellerId)) && 'state-error',
              }}
            />
            {
              customer && <Div display='flex' flexDirection='column' margin='0 32px 0 0'>
                <P>ระดับ</P>
                <P bold>{customer?.pricingType?.name}</P>
              </Div>
            }
            {
              customer && <Div display='flex' flexDirection='column'>
                <P>ยอดซื้อรวม</P>
                <P bold>{numeral(customer?.subtotalAccount).format('0,0.00')}</P>
              </Div>
            }
          </Div>
          {
            lots.map((v) => (
              <Div key={v.id} style={{ padding: 20, border: `1px solid ${theme.color.lightBlue}`, borderRadius: 5 }} margin='10px 0'>
                {
                  !v.isUpsellLot && <Row type='flex' align='middle' gutter={16} >
                    <Col span={6}>
                      <ComponentWithLabelLarge width='100%' margin='8px 16px 8px 0' title={{ text: <SmallText bold>วันที่ส่งสินค้า <Label color={theme.color.error}>*</Label></SmallText> }}>
                        <DatePicker disabled={!_.isNil(v.pickingTicket)} allowClear={false} style={{ width: '100%' }} onChange={(e) => setLots(lots.map((val) => {
                          if (val.id === v.id) {
                            return { ...val, deliveryDate: e }
                          } return val
                        }))} className={_.isEmpty(v.deliveryDate) && 'state-error'} value={v.deliveryDate}/>
                      </ComponentWithLabelLarge>
                    </Col>
                    <Col span={6}>
                      <Div width='100%' margin='16px 0 0 32px'>
                        <CheckboxAntd disabled={!isCreateMode} checked={v.isTax} onChange={(e) => {
                          setLots(lots.map((val) => {
                            if (val.id === v.id) {
                              return { ...val, isTax: e.target.checked, taxInvoiceType: 'DELIVER' }
                            } return val
                          }))
                        }}><P>ต้องการรับใบกำกับภาษี</P></CheckboxAntd>
                      </Div>
                    </Col>
                  </Row>
                }
                <Row type='flex' align='middle' gutter={16} style={{ marginTop: 28 }}>
                  <Col span={6}>
                    <SmallText bold>สินค้า *</SmallText>
                  </Col>
                  <Col span={2}>
                    <SmallText bold>จำนวน *</SmallText>
                  </Col>
                  <Col span={2}>
                    <SmallText bold>ราคา *</SmallText>
                  </Col>
                  {
                    authorization.salesOrder.showCost
                    && <Col span={2}>
                      <SmallText textAlign='center' bold display='block'>ต้นทุนรวม</SmallText>
                    </Col>
                  }
                  {
                    authorization.salesOrder.showCost
                    && <Col span={2}>
                      <SmallText textAlign='center' bold display='block'>กำไรรวม</SmallText>
                    </Col>
                  }
                  {
                    authorization.salesOrder.showCost
                    && <Col span={2}>
                      <SmallText textAlign='center' bold display='block'>ค่าคอมฯรวม</SmallText>
                    </Col>
                  }
                  <Col span={2}>
                    <SmallText textAlign='center' bold display='block'>ราคารวม</SmallText>
                  </Col>
                  <Col span={2}>
                    <SmallText textAlign='center' bold display='block'>Seller</SmallText>
                  </Col>
                  <Col span={2}>
                    <SmallText bold>ส่วนลด</SmallText>
                  </Col>
                  <Col span={1}>
                    <SmallText bold>แถม</SmallText>
                  </Col>
                  <Col span={1} />
                </Row>
                <Divider />
                <ItemsInputRenderer
                  authorization={authorization}
                  customer={customer}
                  isCreateMode={isCreateMode}
                  isUpsellMode={isUpsellMode}
                  lotId={v.id}
                  items={v.items.map((item) => ({ ...item }))}
                  mutationVariables={mutationVariables}
                  updateItems={(e, type = v.deliveryType) => setLots(lots.map((val) => {
                    if (val.id === v.id) {
                      return { ...val, items: e, deliveryType: type }
                    } return val
                  }))}
                  lots={lots}
                  lot={v}
                />
                {
                  <DashedButton margin='20px 0' text='เพิ่มสินค้าใหม่' icon='fal fa-plus' width='100%' onClick={() => {
                    setLots(lots.map((val) => {
                      if (val.id === v.id) {
                        return {
                          ...val,
                          items: [...val.items, {
                            id: nanoid(), discount: 0, warehouseId: 1, isUpsellItem: isUpsellMode,
                          }],
                        }
                      }
                      return val
                    }))
                  }}/>
                }
                {
                  !v.isUpsellLot && <Div margin={isCreateMode && !isFromQuotation ? '0' : '16px 0 0'}>
                    <Row>
                      <Col span={18}>
                        <Div margin='0 24px 0 0'>
                          {
                            remarkMessage && <Remark message={remarkMessage} style={{ margin: '8px 0', whiteSpace: 'break-spaces' }} />
                          }
                          <Row gutter={24}>
                            <Col span={8}>
                              <SelectWithLabel
                                width='100%'
                                margin='8px 16px 8px 0'
                                title={{
                                  text: <SmallText bold>ช่องทางการจัดส่ง <Label color={theme.color.error}>*</Label></SmallText>,
                                }}
                                select={{
                                  value: v.deliveryType,
                                  disabled: !_.isNil(v.pickingTicket) || isUpsellMode,
                                  style: { width: '100%' },
                                  placeholder: 'เลือกช่องทางการจัดส่ง',
                                  onSelect: (e) => setLots(lots.map((val) => {
                                    if (val.id === v.id) {
                                      return { ...val, deliveryType: e, deliveryCost: 0 }
                                    } return val
                                  })),
                                  options: [
                                    { value: DELIVERY_TYPE.DELIVERY, text: 'จัดส่ง', disabled: mutationVariables.isPos },
                                    { value: DELIVERY_TYPE.PICKUP, text: 'รับเอง', disabled: _.size(_.uniq(v?.items?.map((item) => item.warehouseId))) > 1 },
                                  ],
                                }}
                              />
                            </Col>
                            <Col span={8}>
                              {
                                v.deliveryType === DELIVERY_TYPE.DELIVERY
                              && <InputWithLabel
                                width='100%'
                                input={{
                                  value: v.deliveryCost,
                                  disabled: isUpsellMode,
                                  width: '100%',
                                  rule: { type: 'float' },
                                  state: ((_.isEmpty(v.deliveryCost) && !_.isNumber(v.deliveryCost)) || _.isNaN(v.deliveryCost)) ? 'error' : 'default',
                                  defaultValue: v.deliveryCost,
                                }}
                                onChange={(e) => setLots(lots.map((val) => {
                                  if (val.id === v.id) {
                                    return { ...val, deliveryCost: parseFloat(e.target.value) || 0 }
                                  } return val
                                }))}
                                title={{ text: <SmallText bold>ค่าขนส่ง <Label color={theme.color.error}>*</Label></SmallText> }}
                              />
                              }
                            </Col>
                            {
                              isCreateMode
                              && <Col span={8}>
                                <SelectWithLabel
                                  width='100%'
                                  margin='8px 16px 8px 0'
                                  title={{
                                    text: <SmallText bold>บริษัทขนส่ง <Label color={theme.color.error}>*</Label></SmallText>,
                                  }}
                                  select={{
                                    value: mutationVariables.deliveryChannel,
                                    disabled: !_.isNil(v.pickingTicket) || isUpsellMode,
                                    style: { width: '100%' },
                                    placeholder: 'เลือกบริษัทขนส่ง',
                                    onSelect: (e) => setMutationVariables({
                                      ...mutationVariables,
                                      deliveryChannel: e,
                                    }),
                                    options: [
                                      { value: DELIVERY_CHANNEL.EMS.KEY, text: DELIVERY_CHANNEL.EMS.LABEL },
                                      { value: DELIVERY_CHANNEL.KERRY.KEY, text: DELIVERY_CHANNEL.KERRY.LABEL },
                                      { value: DELIVERY_CHANNEL.OTHER.KEY, text: DELIVERY_CHANNEL.OTHER.LABEL },
                                    ],
                                  }}
                                />
                              </Col>
                            }
                          </Row>
                        </Div>
                        {
                          v.deliveryType === DELIVERY_TYPE.DELIVERY
                          && <Div margin='0 24px 0 0'>
                            <Div>
                              <SelectWithLabel
                                width='100%'
                                margin='8px 16px 8px 0'
                                title={{
                                  text: <SmallText bold margin='0 0 6px'>ที่อยู่จัดส่งสินค้า <Label color={theme.color.error}>*</Label></SmallText>,
                                }}
                                select={{
                                  value: !_.isNil(v.deliveryAddressObj) ? renderAddress(v.deliveryAddressObj) : undefined,
                                  disabled: v.useNewAddress || !_.isNil(v.pickingTicket) || isUpsellMode,
                                  style: { width: '100%' },
                                  placeholder: 'เลือกที่อยู่จัดส่งสินค้า',
                                  options: customerShippingAddressList,
                                  onSelect: (e) => {
                                    setLots(lots.map((val) => {
                                      if (val.id === v.id) {
                                        return { ...val, deliveryAddressId: e, deliveryAddressObj: _.find(customerShippingAddressList, (o) => o.value === e).addessData }
                                      } return val
                                    }))
                                  },
                                  className: ((_.isEmpty(v.deliveryAddressId) && !_.isNumber(v.deliveryAddressId)) || _.isNaN(v.deliveryAddressId)) && !v.useNewAddress && 'state-error',
                                }}
                              />
                              <Checkbox
                                style={{ width: '100%' }}
                                margin='8px 0 0'
                                disabled={!_.isNil(v.pickingTicket) || isUpsellMode}
                                checked={v.useNewAddress}
                                data={{ text: 'กรอกที่อยู่จัดส่งเอง', value: `address${v.id}${v.lotId}` }}
                                onChange={(e) => setLots(lots.map((val) => {
                                  if (isCreateMode ? val.id === v.id : val.lotId === v.lotId) {
                                    return { ...val, useNewAddress: e.target.checked }
                                  } return val
                                }))}
                              />
                            </Div>
                            {
                              v.useNewAddress
                              && <NewAddress
                                data={{
                                  ..._.find(lots, (o) => o.id === v.id)?.newAddress,
                                  zipcode: _.find(lots, (o) => o.id === v.id)?.newAddress?.postcode,
                                  subdistrict: _.find(lots, (o) => o.id === v.id)?.newAddress?.subDistrict,
                                }}
                                onChange={(key, value) => {
                                  setLots(lots.map((val) => {
                                    if (val.id === v.id) {
                                      return { ...val, newAddress: { ...val.newAddress, type: 'SHIPPING', [key]: value } }
                                    } return val
                                  }))
                                }}
                                onSelect={(obj) => {
                                  setLots(lots.map((val) => {
                                    if (val.id === v.id) {
                                      return {
                                        ...val,
                                        newAddress: {
                                          ...val.newAddress,
                                          ...obj,
                                          postcode: obj.zipcode,
                                          subDistrict: obj.subdistrict,
                                        },
                                      }
                                    } return val
                                  }))
                                }}
                              />
                            }
                          </Div>
                        }
                        {
                          v.isTax
                          && <BillingNewAddress
                            margin='24px 0 0'
                            billingAddressList={customerBillingAddressList}
                            lots={lots}
                            lotData={v}
                            setLots={(value) => setLots(value)}
                            isNewAddress={v?.useNewBillingAddress}
                          />
                        }
                      </Col>
                    </Row>
                  </Div>
                }
              </Div>
            ))
          }
          <Div display='flex'>
            <Div width='50%' margin='0 16px 0 0'>
              <TextareaWithLabel
                onChange={(e) => setMutationVariables({ ...mutationVariables, remarks: e.target.value })}
                width='100%' title={{
                  text: 'หมายเหตุเพิ่มเติม',
                  margin: '0 0 8px',
                }} textarea={{
                  value: mutationVariables.remarks,
                  rows: 2,
                  margin: '16px 0 0',
                  style: { width: '100%' },
                }} />
              <Uploader onChange={(e) => setMutationVariables({ ...mutationVariables, files: e })} data={mutationVariables.files.map((file) => {
                if (file.url) {
                  return ({
                    name: file.fileName,
                    url: file.url,
                    uid: file.fileName,
                  })
                }
                return file
              }) || []} accept='image/*' isMultiple={true}/>
              <Div width='100%' display='flex'>
                <Div margin='16px 0 0' width='100%'>
                  <SmallText bold display='block' margin='0 0 8px' >เลือกประเภทการรับชำระเงิน <Label color={theme.color.error}>*</Label></SmallText>
                  {
                    !isCreateMode
                      ? <Div>
                        {
                          !soData?.isCOD
                            ? <P>ชำระเต็มจำนวน</P>
                            : <P>ชำระเงินปลายทาง</P>
                        }
                      </Div>
                      : <Div>
                        <Radio.Group onChange={(e) => setMutationVariables({ ...mutationVariables, isCOD: e.target.value })} value={mutationVariables.isCOD}>
                          <Radio value='full'><P>ชำระเต็มจำนวน</P></Radio>
                          <Radio value='cod'><P>ชำระเงินปลายทาง</P></Radio>
                        </Radio.Group>
                      </Div>
                  }
                  <SmallText bold display='block' margin='8px 0' >เลือกประเภท Order Claim</SmallText>
                  <Div display='flex' alignItems='flex-start' width='100%'>
                    <Div display='flex' flexDirection='column' width='calc(49% - 16px)'>
                      <Select placeholder='เลือกประเภท Order Claim' disabled={!isCreateMode} value={mutationVariables.claimType} style={{ marginRight: 16 }} allowClear onChange={(e) => {
                        setMutationVariables({ ...mutationVariables, claimType: e, claimUserId: undefined, claimOrderAmount: undefined })
                        if (e === ORDER_CLAIM_TYPE.SELLER_CUT.ENUM) {
                          getOrderClaimUsers({
                            variables: {
                              department: 'admin',
                              limit: 10,
                              offset: 0,
                            },
                          })
                        } else if (e === ORDER_CLAIM_TYPE.PT.ENUM) {
                          getOrderClaimUsers({
                            variables: {
                              department: 'packing',
                              limit: 10,
                              offset: 0,
                            },
                          })
                        }
                      }}>
                        <Select.Option value={ORDER_CLAIM_TYPE.SELLER.ENUM}>{ORDER_CLAIM_TYPE.SELLER.TEXT}</Select.Option>
                        <Select.Option value={ORDER_CLAIM_TYPE.SELLER_CUT.ENUM}>{ORDER_CLAIM_TYPE.SELLER_CUT.TEXT}</Select.Option>
                        <Select.Option value={ORDER_CLAIM_TYPE.PT.ENUM}>{ORDER_CLAIM_TYPE.PT.TEXT}</Select.Option>
                      </Select>
                      {
                        (mutationVariables.claimType === ORDER_CLAIM_TYPE.SELLER_CUT.ENUM || mutationVariables.claimType === ORDER_CLAIM_TYPE.PT.ENUM)
                        && <Div display='flex' alignItems='center' margin='8px 0 0 0'>
                          <InputNumber
                            controls={false}
                            style={{ width: 200, marginRight: 8 }}
                            disabled={!isCreateMode}
                            onChange={(e) => setMutationVariables({ ...mutationVariables, claimOrderAmount: e })}
                            value={mutationVariables.claimOrderAmount}
                          />
                          <P>บาท</P>
                        </Div>
                      }
                    </Div>
                    <Select
                      style={{
                        width: '50%',
                      }}
                      showSearch
                      value={mutationVariables.claimUserId}
                      disabled={!includes([ORDER_CLAIM_TYPE.SELLER_CUT.ENUM, ORDER_CLAIM_TYPE.PT.ENUM], mutationVariables.claimType) || isUpsellMode}
                      notFoundContent={null}
                      filterOption={false}
                      loading={orderClaimUserLoading}
                      onSelect={(e) => {
                        setMutationVariables({ ...mutationVariables, claimUserId: e })
                      }}
                      onSearch={(value) => {
                        getOrderClaimUsers({ variables: {
                          limit: 10,
                          offset: 0,
                          department: mutationVariables.claimType === ORDER_CLAIM_TYPE.SELLER_CUT.ENUM
                            ? 'admin'
                            : mutationVariables.claimType === ORDER_CLAIM_TYPE.PT.ENUM
                              ? 'packing'
                              : '',
                          name: value,
                        } })
                      }}
                    >
                      {
                        (orderClaimUserData?.userList?.data?.list || []).map((v) => (
                          <Select.Option key={v.id} value={v.id}>{v.firstName} {v.lastName}</Select.Option>
                        ))
                      }
                    </Select>
                  </Div>
                </Div>
              </Div>
            </Div>
            <Div width='50%' margin='8px 0 0 16px'>
              <SummaryPrice
                bonusCommissionAdmin={bonusCommissionAdmin}
                bonusCommissionUpsell={bonusCommissionUpsell}
                so
                showCost={authorization.salesOrder.showCost}
                isWithShipping
                finalDiscount={{
                  disabled: mutationVariables.quotationId,
                  value: mutationVariables?.discount || 0,
                  onChange: (value) => { setMutationVariables({ ...mutationVariables, discount: parseFloat(value) }) },
                }}
                margin='8px 0'
                isCreateMode={isCreateMode}
                isUpsellMode={isUpsellMode}
                data={{
                  mutationVariables,
                  oldCommission: sumBy(filter(first(soData?.lots)?.items, (o) => !o.isUpsell), (o) => o.commission * o.quantity),
                  lots,
                  total: _.sum(_.flatten(lots.map((lot) => lot.items)).map((item) => item.quantity * item.salesPrice)),
                  totalCost: _.sum(_.flatten(lots.map((lot) => lot.items)).map((item) => item.quantity * item?.avgCost)),
                  totalProfit: _.sum(_.flatten(lots.map((lot) => lot.items)).map((item) => (item.isFree ? (item?.avgCost * item.quantity) * -1 : (item.salesPrice - item?.avgCost) * item.quantity))),
                  discount: {
                    type: 'Baht',
                    value: _.sum(_.flatten(lots.map((lot) => lot.items)).map((item) => item.discount * item.quantity)) || 0,
                  },
                  shipping: {
                    type: 'Baht',
                    value: _.sum(lots.map((lot) => lot.deliveryCost || 0)),
                    disabled: true,
                  },
                }}
              />
            </Div>
          </Div>
          <Div display='flex' flexDirection='row' justifyContent='center' alignItems='center' width='100%' margin='20px 0' moreStyle={{ position: 'relative' }}>
            {
              isShowCancelButton && !isUpsellMode
              && <Popconfirm
                key="submit"
                title="ยืนยันจะลบใบ SO นี้หรือไม่ เมื่อลบแล้วจะไม่สามารถแก้ไขได้ ?"
                onConfirm={() => {
                  doCancelSo({
                    variables: { soId: parseInt(props.match.params.id) },
                  }).then((resp) => {
                    if (resp?.data?.cancelSalesOrder?.success) {
                      message.success(resp?.data?.cancelSalesOrder?.message)
                      history.push('/sale-orders')
                    } else {
                      message.error(resp?.data?.cancelSalesOrder?.message)
                    }
                  })
                }}
                okText="ยืนยันลบใบ SO นี้"
                cancelText="ยกเลิกการลบใบ SO นี้"
                okButtonProps={{ disabled: canceling }}
              >
                <CancelSoButton loading={canceling} disabled={canceling} text='ยกเลิกใบ SO นี้' inverse color={theme.color.error} />
              </Popconfirm>
            }
            <Link to='/sale-orders'><Button margin='0 4px 0 0' text={isCreateMode ? 'ยกเลิก' : 'ยกเลิกการแก้ไข'} icon='fal fa-times' color={theme.color.error} /></Link>
            {
              customerHaveTaxInvoice
              && <SelectTaxInvoiceModal
                setModalVisible={setModalVisible}
                modalVisible={modalVisible}
                taxInvoiceLoading={taxInvoiceLoading}
                taxInvoiceData={taxInvoiceData}
                callbackLoading={mutationLoading}
                customer={customer}
                selectedTaxInvoiceList={selectedTaxInvoiceList}
                setSelectedTaxInvoiceList={(e) => {
                  if (e.target.checked) {
                    setSelectedTaxInvoiceList([...selectedTaxInvoiceList, parseInt(e.target.value)])
                  } else {
                    setSelectedTaxInvoiceList(_.compact(selectedTaxInvoiceList?.map((taxInvoiceId) => {
                      if (taxInvoiceId !== parseInt(e.target.value)) {
                        return taxInvoiceId
                      }
                      return null
                    })))
                  }
                }}
                callback={() => createSO()}
                moduleName='ใบออเดอร์'
              />
            }
            <Button
              loading={mutationLoading}
              disabled={
                validateData(
                  true,
                  isCreateMode
                    ? [
                      mutationVariables.customerId,
                      mutationVariables.chatProviderId,
                      mutationVariables.sellerId,
                      (
                        (mutationVariables.claimType === ORDER_CLAIM_TYPE.SELLER_CUT.ENUM || mutationVariables.claimType === ORDER_CLAIM_TYPE.PT.ENUM)
                          ? [mutationVariables.claimOrderAmount, mutationVariables.claimUserId]
                          : 'noCheck'
                      ),
                      _.flatten(lots.map((lot) => ({
                        deliveryType: lot.deliveryType,
                        billingAddressId: lot.isTax && !lot.useNewBillingAddress ? lot.billingAddressId : 'noCheck',
                        deliveryAddressId: lot.deliveryType === DELIVERY_TYPE.DELIVERY && !lot.useNewAddress ? lot.deliveryAddressId : 'noCheck',
                        deliveryCost: lot.deliveryType === DELIVERY_TYPE.DELIVERY ? lot.deliveryCost : 'noCheck',
                        deliveryDate: lot.deliveryDate,
                        items: lot.items,
                      }))),
                      _.flatten(lots.map((lot) => lot.items)).map((item) => ({
                        packId: item.packId,
                        warehouseId: item.warehouseId,
                        quantity: item.quantity,
                        salesPrice: item.salesPrice,
                      })),
                      _.flatten(lots.map((lot) => (
                        lot.deliveryType === DELIVERY_TYPE.DELIVERY && lot.useNewAddress
                          ? [
                            lot.newAddress?.type,
                            lot.newAddress?.addressNo,
                            lot.newAddress?.province,
                            lot.newAddress?.district,
                            lot.newAddress?.subDistrict,
                            lot.newAddress?.postcode,
                            lot.newAddress?.contactName,
                            lot.newAddress?.contactPhoneNumber,
                          ]
                          : 'noCheck'))),
                      _.flatten(lots.map((lot) => (
                        lot.isTax
                          ? lot.useNewBillingAddress
                            ? _.isEmpty(lot?.newBillingAddress?.billingType)
                              ? [null]
                              : [lot.taxInvoiceType, ...getRequireBillingAddressField(lot.newBillingAddress)]
                            : [
                              lot.taxInvoiceType,
                              lot.billingAddressId,
                            ]
                          : 'noCheck'
                      ))),
                    ]
                    : isUpsellMode ? [
                      _.flatten(lots.map((lot) => lot.items)).map((item) => ({
                        id: item.isUpsellItem ? item.id : 'noCheck',
                        quantity: item.quantity,
                        salesPrice: item.salesPrice,
                      })),
                    ] : [
                      ((mutationVariables.claimType === ORDER_CLAIM_TYPE.SELLER_CUT.ENUM || mutationVariables.claimType === ORDER_CLAIM_TYPE.PT.ENUM) ? mutationVariables.claimUserId : 'noCheck'),
                      ..._.flattenDepth(lots.map((lot) => (_.flattenDepth([
                        lot.deliveryType === DELIVERY_TYPE.DELIVERY && !lot.useNewAddress ? lot.deliveryAddressId : 'noCheck',
                        lot.isTax && !lot.newBillingAddress ? lot.billingAddressId : 'noCheck',
                        lot.deliveryType === DELIVERY_TYPE.DELIVERY && lot.useNewAddress ? _.values(_.pick(lot.newAddress, ['type', 'addressNo', 'province', 'district', 'subDistrict', 'postcode', 'contactName', 'contactPhoneNumber'])) : 'noCheck',
                        lot.isTax && lot.useNewBillingAddress ? _.values(_.pick(lot.newBillingAddress, ['type', 'addressNo', 'province', 'district', 'subDistrict', 'postcode', 'contactName', 'taxId'])) : 'noCheck',
                      ])))),
                    ],
                  { a: 1 },
                )
                || mutationLoading
              }
              inverse
              color={theme.color.success}
              text={isCreateMode ? 'สร้างใบออเดอร์' : 'แก้ไขใบออเดอร์'}
              icon='far fa-check'
              style={{ alignItems: 'center' }}
              width='fit-content'
              margin='0 4px'
              onClick={() => onReadyCreateSO()}
            />
          </Div>
        </Div>
      </FullPageContainer>
    </Div>
  )
}
