import React, { useContext } from 'react'
import { Switch, Route } from 'react-router-dom'
import Users from './pages/users/list'
import UserForm from './pages/users/form'
import Suppliers from './pages/suppliers/list'
import SupplierForm from './pages/suppliers/form'
import Warehouses from './pages/warehouses/list'
import WarehouseForm from './pages/warehouses/form'
import Inventory from './pages/inventory/list'
import InventoryForm from './pages/inventory/form'
import Customers from './pages/customers/list'
import CustomerForm from './pages/customers/form'
import POList from './pages/po'
import POForm from './pages/po/form'
import SaleOrderForm from './pages/sale-orders/form'
import SaleOrders from './pages/sale-orders/list'
import { PaymentPage } from './pages/sale-orders/sub-pages'
import ProductGroups from './pages/product-group/list'
import ProductGroupForm from './pages/product-group/form'
import GrList from './pages/good-receives'
import GrForm from './pages/good-receives/form'
import PTList from './pages/pt'
import PtForm from './pages/pt/form'
import Role from './pages/role/list'
import AccList from './pages/account'
import TaxInvoiceList from './pages/tax-invoice'
import { PATH } from './constants/path'
import { AuthorizationContext } from './store/StoreProvider'

export const UsersRoute = ({ match }) => {
  const { authorization } = useContext(AuthorizationContext)
  return (
    <Switch>
      { authorization.user.create && <Route path={`${match.url}/create`} component={UserForm}/> }
      { authorization.user.edit && <Route path={`${match.url}/edit/:id`} component={UserForm}/> }
      { authorization.user.view && <Route path={`${match.url}/`} component={Users}/> }
    </Switch>
  )
}

export const SuppliersRoute = ({ match }) => {
  const { authorization } = useContext(AuthorizationContext)
  return (
    <Switch>
      { authorization.supplier.create && <Route path={`${match.url}/create`} component={SupplierForm}/> }
      { authorization.supplier.editBasic && <Route path={`${match.url}/edit/:id`} component={SupplierForm}/> }
      { authorization.supplier.view && <Route path={`${match.url}/`} component={Suppliers}/> }
    </Switch>
  )
}

export const WarehousesRoute = ({ match }) => {
  const { authorization } = useContext(AuthorizationContext)
  return (
    <Switch>
      { authorization.warehouse.create && <Route path={`${match.url}/create`} component={WarehouseForm}/> }
      { authorization.warehouse.edit && <Route path={`${match.url}/edit/:id`} component={WarehouseForm}/> }
      { authorization.warehouse.view && <Route path={`${match.url}/`} component={Warehouses}/> }
    </Switch>
  )
}

export const InventoryRoute = ({ match }) => {
  const { authorization } = useContext(AuthorizationContext)
  return (
    <Switch>
      { authorization.inventory.createBasic && <Route path={`${match.url}/create`} component={InventoryForm}/> }
      { authorization.inventory.edit && <Route path={`${match.url}/edit/:id`} component={InventoryForm}/> }
      { authorization.inventory.view && <Route path={`${match.url}/`} component={Inventory}/> }
    </Switch>
  )
}

export const RolesRoute = ({ match }) => {
  const { authorization } = useContext(AuthorizationContext)
  return (
    <Switch>
      { authorization.permission.view && <Route path={`${match.url}/`} component={Role}/> }
    </Switch>
  )
}

export const CustomersRoute = ({ match }) => {
  const { authorization } = useContext(AuthorizationContext)
  return (
    <Switch>
      { authorization.client.create && <Route path={`${match.url}/create`} component={CustomerForm}/> }
      { authorization.client.editBasic && <Route path={`${match.url}/edit/:id`} component={CustomerForm}/> }
      { authorization.client.view && <Route path={`${match.url}/`} component={Customers}/> }
    </Switch>
  )
}

export const PurchaseOrderRoute = ({ match }) => {
  const { authorization } = useContext(AuthorizationContext)
  return (
    <Switch>
      { authorization.purchaseOrder.create && <Route path={`${match.url}/create`} component={POForm}/> }
      { authorization.purchaseOrder.edit && <Route path={`${match.url}/edit/:id`} component={POForm}/> }
      { authorization.purchaseOrder.view && <Route path={`${match.url}/`} component={POList} /> }
    </Switch>
  )
}

export const SaleOrdersRoute = ({ match, refetch }) => {
  const { authorization } = useContext(AuthorizationContext)
  return (
    <Switch>
      { authorization.salesOrder.create && <Route path={`${match.url}/create/:quotationId`} component={SaleOrderForm}/> }
      { authorization.salesOrder.create && <Route path={`${match.url}/create`} component={SaleOrderForm}/> }
      { authorization.salesOrder.edit && <Route path={`${match.url}/edit/:id`} component={SaleOrderForm}/> }
      <Route path={`${match.url}/upsell/:id`} component={SaleOrderForm}/>
      { authorization.salesOrder.edit && <Route path={`${match.url}/payment/edit/:id/:pid`} component={PaymentPage}/> }
      { authorization.salesOrder.edit && <Route path={`${match.url}/payment/:id`} component={PaymentPage}/> }
      { authorization.salesOrder.view && <Route path={`${match.url}/`} render={(props) => <SaleOrders {...props} refetch={() => refetch()} />} /> }
    </Switch>
  )
}

export const ProductGroupRoute = ({ match, refetch }) => {
  const { authorization } = useContext(AuthorizationContext)
  return (
    <Switch>
      { authorization.productSet.create && <Route path={`${match.url}/create`} component={ProductGroupForm}/> }
      { authorization.productSet.edit && <Route path={`${match.url}/edit/:id`} component={ProductGroupForm}/> }
      { authorization.productSet.view && <Route path={`${match.url}/`} render={(props) => <ProductGroups {...props} refetch={() => refetch()} />} /> }
    </Switch>
  )
}

export const PickingTicketsRoute = ({ match }) => {
  const { authorization } = useContext(AuthorizationContext)
  return (
    <Switch>
      { authorization.pickingTicket.create && <Route path={`${match.url}/create`} component={PtForm}/> }
      { authorization.pickingTicket.view && <Route path={`${match.url}/`} component={PTList}/> }
    </Switch>
  )
}

export const GoodReceiveRoute = ({ match }) => {
  const { authorization } = useContext(AuthorizationContext)
  return (
    <Switch>
      { (authorization.goodsReceive.create || authorization.goodsReceive.createDraft) && <Route path={`${match.url}${PATH.create}`} component={GrForm}/> }
      { authorization.goodsReceive.edit && <Route path={`${match.url}${PATH.edit}/:id`} component={GrForm}/> }
      { authorization.goodsReceive.view && <Route path={`${match.url}/`} component={GrList}/> }
    </Switch>
  )
}

export const AccountRoute = ({ match }) => (
  <Switch>
    <Route path={`${match.url}${PATH.receipts.create}`} component={(props) => <AccList form {...props} />}/>
    <Route path={`${match.url}${PATH.receipts.edit}/:id`} component={(props) => <AccList form isEdit {...props} />}/>
    <Route path={`${match.url}${PATH.receipts.creditNote}/:id`} component={(props) => <AccList creditNote {...props} />} />
    <Route path={`${match.url}${PATH.receipts.debitNote}/:id`} component={(props) => <AccList debitNote {...props} />} />
    <Route path={PATH.receipts.name} component={AccList}/>
  </Switch>
)

export const TaxInvoiceRoute = ({ match }) => (
  <Switch>
    <Route exact path={`${match.url}/`} component={TaxInvoiceList}/>
  </Switch>
)
