import React from 'react'
import { Icon } from '@konsys-ui-custom'

export const IconSelector = (id) => {
  switch (id) {
    case 1:
      return <Icon icon='far fa-user-crown' margin='0 0 16px 0' fontSize='20px'/>
    case 2:
      return <Icon icon='far fa-user-tie' margin='0 0 16px 0' fontSize='20px'/>
    case 3:
      return <Icon icon='far fa-user' margin='0 0 16px 0' fontSize='20px'/>
    case 4:
      return <Icon icon='far fa-user' margin='0 0 16px 0' fontSize='20px'/>
    default:
      return <Icon icon='far fa-user' margin='0 0 16px 0' fontSize='20px'/>
  }
}

export const roleTitle = {
  SalesOrder: 'ใบออเดอร์',
  PurchaseOrder: 'Supplier - ใบสั่งซื้อ',
  GoodsReceive: 'ใบรับสินค้า',
  PickingTicket: 'การจัดส่ง',
  // DepositReceipt: 'Deposit Receipt',
  // Invoice: 'Invoice',
  // Bill: 'Bill',
  CashReceipt: 'ใบกำกับภาษี',
  // CreditReceipt: 'Credit Receipt',
  // PaymentNote: 'Payment Note',
  Inventory: 'สต็อกสินค้า',
  ProductSet: 'เซตสินค้า',
  // ProductStructure: 'Product Structure',
  Client: 'ลูกค้า',
  Supplier: 'ผู้ผลิต',
  Warehouse: 'คลังสินค้า',
  User: 'ผู้ใช้',
  Permission: 'หน้าที่และการเข้าถึง',
  UserPagePermissions: 'การเข้าถึงเพจ',
  // Setting: 'Setting',
  Report: 'รายงาน / Dashboard',
  Tag: 'จัดการแท็กสินค้า',
  Commission: 'จัดการค่าคอมมิชชั่น',
  Facebook: 'จัดการช่องทาง Facebook',
  UnMatchedSalesOrder: 'Third Party - ออเดอร์ตกค้าง',
}

export default IconSelector
