import {
  Div, H4,
  Icon,
  P,
} from '@konsys-ui-custom'
import _ from 'lodash'
import moment from 'moment-timezone'
import React, { useState } from 'react'
import {
  Collapse,
  Divider,
  MessagePage,
  Remark,
  TextBetween,
} from '../../components'
import MESSAGE from '../../constants/message'
import { theme } from '../../styles/_variables'
import { renderText } from '../../utils/util-services'
import { PERSON_TYPE } from './constants'
import { mapBankTypetoText } from './utils'

const Address = (props) => (
  <Div margin={props.margin}>
    <Div display='flex' alignItems='center' margin='0 0 16px'>
      <P bold display='block'>{props.data.contactName || '-'}</P>
      {
        props.data.isPrimary
        && <Icon fontSize='12px' margin='0 0 0 8px' icon='fas fa-star' />
      }
    </Div>
    {
      props.data.shopName
      && <P>{props.data.shopName}</P>
    }
    {
      props.data.contactPhoneNumber
        ? <P display='block' margin='0 0 8px'>{`${props.data.contactName} (${props.data.contactPhoneNumber})`}</P>
        : <P display='block' margin='0 0 8px'>{props.data.contactName}</P>
    }
    <P display='block'>{`${props.data.addressNo || ''} ${props.data.subDistrict || ''} ${props.data.district || ''} ${props.data.province || ''} ${props.data.postcode || ''}`}</P>
    {
      props.withTaxId
      && <P margin='8px 0 0' display='block'>หมายเลขผู้เสียภาษี: {props.data.taxId}</P>
    }
  </Div>
)

export const DeliveryDetail = (props) => (
  <Div>
    <H4 bold color={theme.color.primaryColor} margin='0 0 16px'>ข้อมูลการจัดส่งสินค้า</H4>
    {
      (_.isArray(props.data) && !_.isEmpty(props.data))
        ? props.data.map((v, i) => (
          <Address data={v} key={i} margin={i !== (props.data.length - 1) ? '0 0 32px' : '0'} />
        ))
        : <MessagePage
          margin='48px 0 0'
          icon={{
            icon: 'fal fa-times', fontSize: theme.fonts.size.title, color: theme.color.lightBlue, margin: '0 0 16px',
          }}
          text={MESSAGE.empty.list}
          color={theme.color.lightBlue}
          bold
        />
    }
  </Div>
)

export const TaxDetail = (props) => (
  <Div>
    <H4 bold color={theme.color.primaryColor} margin='0 0 16px'>ข้อมูลใบกำกับภาษี</H4>
    {
      (_.isArray(props.data) && !_.isEmpty(props.data))
        ? props.data.map((v, i) => (
          <Address withTaxId data={v} key={i} margin={i !== (props.data.length - 1) ? '0 0 32px' : '0'} />
        ))
        : <MessagePage
          margin='48px 0 0'
          icon={{
            icon: 'fal fa-times', fontSize: theme.fonts.size.title, color: theme.color.lightBlue, margin: '0 0 16px',
          }}
          text={MESSAGE.empty.list}
          color={theme.color.lightBlue}
          bold
        />
    }
  </Div>
)

export const ContactDetail = (props) => (
  <Div>
    <TextBetween
      title={{
        margin: '8px 0',
        text: 'ชื่อ',
        bold: true,
      }}
      content={{
        margin: '8px 0',
        text: props.data.contactName || '-',
      }}
    />
    {
      props.data.position
      && <TextBetween
        title={{
          margin: '8px 0',
          text: 'ตำแหน่ง',
          bold: true,
        }}
        content={{
          margin: '8px 0',
          text: props.data.position,
        }}
      />
    }
    <TextBetween
      title={{
        margin: '8px 0',
        text: 'Line',
        bold: true,
      }}
      content={{
        margin: '8px 0',
        text: props.data.contactLineId || '-',
        color: '#00B900',
      }}
    />
    <TextBetween
      title={{
        margin: '8px 0',
        text: 'Facebook',
        bold: true,
      }}
      content={{
        margin: '8px 0',
        text: props.data.contactFacebook || '-',
        color: '#4267B2',
      }}
    />
    <Div>
      <TextBetween
        title={{
          margin: '8px 0',
          text: 'เบอร์โทรศัพท์',
          bold: true,
        }}
      />
      <TextBetween
        title={{
          margin: '8px 0 0 16px',
          text: 'เบอร์ออฟฟิศ',
        }}
        content={{
          margin: '8px 0',
          text: props.data.contactOfficeTel || '-',
        }}
      />
      <TextBetween
        title={{
          margin: '8px 0 0 16px',
          text: 'เบอร์มือถือ',
        }}
        content={{
          margin: '8px 0',
          text: props.data.contactPhoneNumber || '-',
        }}
      />
      <TextBetween
        title={{
          margin: '8px 0 0 16px',
          text: 'เบอร์Fax',
        }}
        content={{
          margin: '8px 0',
          text: props.data.contactFaxNumber || '-',
        }}
      />
    </Div>
    <TextBetween
      title={{
        margin: '8px 0',
        text: 'อีเมล์',
        bold: true,
      }}
      content={{
        margin: '8px 0',
        text: props.data.contactEmail || '-',
      }}
    />
  </Div>
)

export default ({ data }) => {
  const [isOpenCollapse, setIsOpenCollapse] = useState({ otherData: false, creditData: false, contactData: false })
  const primaryContactAddress = _.find(data.contactAddress, (o) => o.isPrimary)
  return (
    <Div margin='0 0 24px 0'>
      <H4 bold color={theme.color.primaryColor} margin='0 0 16px'>ข้อมูลผู้ผลิต</H4>
      <TextBetween
        title={{
          margin: '8px 0',
          text: 'รหัสผู้ผลิต',
          bold: true,
        }}
        content={{
          margin: '8px 0',
          text: data.number,
        }}
      />
      {
        !_.isEmpty(primaryContactAddress)
        && <ContactDetail data={primaryContactAddress} />
      }
      <Collapse margin='32px 0 0' fullHeight text='ข้อมูลเพิ่มเติม' open={isOpenCollapse.otherData} onClick={() => setIsOpenCollapse({ ...isOpenCollapse, otherData: !isOpenCollapse.otherData })}>
        <Div>
          {
            !_.isEmpty(data.contactPhoneNumber) && _.isArray(data.contactPhoneNumber)
            && _.compact(data.contactPhoneNumber.map((v, i) => {
              if (_.isEmpty(v.phoneNumber)) return null
              return (
                <TextBetween
                  key={i}
                  title={{
                    margin: '8px 0',
                    text: v.isPrimary
                      ? <Div display='flex' alignItems='center'>
                        <P bold>{v.name}</P>
                        <Icon fontSize='12px' margin='0 0 0 8px' icon='fas fa-star' />
                      </Div>
                      : v.name,
                    bold: true,
                  }}
                  content={{
                    margin: '8px 0',
                    text: v.phoneNumber,
                  }}
                />
              )
            }))
          }
          {
            !_.isEmpty(data.contactEmail)
            && _.compact(data.contactEmail.map((v, i) => {
              if (_.isEmpty(v.email)) return null
              return (
                <TextBetween
                  key={i}
                  title={{
                    margin: '8px 0',
                    text: v.isPrimary
                      ? <Div display='flex' alignItems='center'>
                        <P bold>อีเมล</P>
                        <Icon fontSize='12px' margin='0 0 0 8px' icon='fas fa-star' />
                      </Div>
                      : 'อีเมล',
                    bold: true,
                  }}
                  content={{
                    margin: '8px 0',
                    text: v.email,
                  }}
                />
              )
            }))
          }
          {
            !_.isEmpty(data.birthDate)
            && <TextBetween
              title={{
                margin: '8px 0',
                text: 'วันเกิด',
                bold: true,
              }}
              content={{
                margin: '8px 0',
                text: moment(data.birthDate).format('DD/MM/YYYY'),
              }}
            />
          }
          {
            !_.isEmpty(data.gender)
            && <TextBetween
              title={{
                margin: '8px 0',
                text: 'เพศ',
                bold: true,
              }}
              content={{
                margin: '8px 0',
                text: data.gender === 'MALE' ? 'ชาย' : 'หญิง',
              }}
            />
          }
          {
            !_.isEmpty(data.idCardNumber)
            && <TextBetween
              title={{
                margin: '8px 0',
                text: 'เลขประจำตัวประชาชน',
                bold: true,
              }}
              content={{
                margin: '8px 0',
                text: data.idCardNumber,
              }}
            />
          }
          {
            !_.isEmpty(data.taxId)
            && <TextBetween
              title={{
                margin: '8px 0',
                text: 'เลขที่เสียภาษี',
                bold: true,
              }}
              content={{
                margin: '8px 0',
                text: data.taxId,
              }}
            />
          }
          <TextBetween
            title={{
              margin: '8px 0',
              text: 'ประเภทลูกค้า',
              bold: true,
            }}
            content={{
              margin: '8px 0',
              text: data.personType === PERSON_TYPE.INDIVIDUAL ? 'บุคคลธรรมดา' : 'นิติบุคคล',
            }}
          />
          <TextBetween
            title={{
              margin: '8px 0',
              text: 'VAT',
              bold: true,
            }}
            content={{
              margin: '8px 0',
              text: data.isVat ? 'เก็บ' : 'ไม่เก็บ',
            }}
          />
          <TextBetween
            title={{
              margin: '8px 0',
              text: 'สถานะข้อมูล',
              bold: true,
            }}
            content={{
              margin: '8px 0',
              text: data.status === 'ACTIVE' ? 'ใช้งาน' : 'ไม่ใช้งาน',
              color: data.status === 'ACTIVE' ? theme.color.success : theme.color.error,
            }}
          />
          {
            !_.isEmpty(data.paymentInfo)
            && <Div display='flex' flexDirection='column' padding='8px' margin='8px 0' style={{ border: `1px solid ${theme.color.lightBlue}`, borderRadius: 5, background: theme.color.primaryColor10 }}>
              <P bold margin='0 0 16px 0'>ข้อมูลธนาคาร</P>
              {
                data && data.paymentInfo && data.paymentInfo.map((v, i) => (
                  <Div key={i}>
                    <TextBetween
                      width='100%'
                      title={{
                        margin: '8px 0',
                        text: 'ชื่อบัญชี',
                      }}
                      content={{
                        margin: '8px 0',
                        text: renderText(v.bankAccountName),
                      }}
                    />
                    <TextBetween
                      width='100%'
                      title={{
                        margin: '8px 0',
                        text: 'เลขบัญชี',
                      }}
                      content={{
                        margin: '8px 0',
                        text: renderText(v.bankAccountNumber),
                      }}
                    />
                    <TextBetween
                      width='100%'
                      title={{
                        margin: '8px 0',
                        text: 'ประเภทบัญชี',
                      }}
                      content={{
                        margin: '8px 0',
                        text: renderText(mapBankTypetoText(v.bankAccountType)),
                      }}
                    />
                    <TextBetween
                      width='100%'
                      title={{
                        margin: '8px 0',
                        text: 'ธนาคาร',
                      }}
                      content={{
                        margin: '8px 0',
                        text: renderText(v.bank && v.bank.name),
                      }}
                    />
                    {i < _.size(data.paymentInfo) - 1 && <Divider color={theme.color.lightBlue} />}
                  </Div>
                ))
              }
            </Div>
          }
        </Div>
      </Collapse>
      <Collapse fullHeight text='ข้อมูลผู้ติดต่อ' open={isOpenCollapse.contactData} onClick={() => setIsOpenCollapse({ ...isOpenCollapse, contactData: !isOpenCollapse.contactData })}>
        <Div>
          {
            !_.isEmpty(data.contactAddress) && _.map(data.contactAddress, (v) => (
              <Div display='flex' flexDirection='column' padding='8px' margin='8px 0' style={{ border: `1px solid ${theme.color.lightBlue}`, borderRadius: 5, background: theme.color.primaryColor10 }}>
                <Div display='flex' alignItems='center' margin='0 0 16px'>
                  <P bold display='block'>ผู้ติดต่อ</P>
                  {
                    v.isPrimary
                    && <Icon fontSize='12px' margin='0 0 0 8px' icon='fas fa-star' />
                  }
                </Div>
                <ContactDetail data={v} />
              </Div>
            ))
          }
        </Div>
      </Collapse>
      <Remark showIcon message='หมายเหตุ' description={data.contactRemark || '-'} />
    </Div>
  )
}
