import React from 'react'
import { Div, H3, H4 } from '@konsys-ui-custom'
import moment from 'moment-timezone'
import { theme } from '../../styles/_variables'
import { TextBetween, Remark } from '../../components'

export default ({ data }) => (
  <Div>
    <Div display='flex' alignItems='center' width='100%' justifyContent='space-between' margin='0 0 16px 0'>
      <H3 color={theme.color.primaryColor}>ข้อมูลคลังสินค้า</H3>
      <H4>ID: {data.warehouseCode}</H4>
    </Div>
    <TextBetween
      title={{
        margin: '8px 0',
        text: 'ชื่อคลังสินค้า',
      }}
      content={{
        margin: '8px 0',
        text: data.warehouseName,
        color: '#968595',
      }}
    />
    <TextBetween
      title={{
        margin: '8px 0',
        text: 'เลขที่',
      }}
      content={{
        margin: '8px 0',
        text: data.address.addressNo,
        color: '#968595',
      }}
    />
    <TextBetween
      title={{
        margin: '8px 0',
        text: 'แขวง/ตำบล',
      }}
      content={{
        margin: '8px 0',
        text: data.address.subDistrict,
        color: '#968595',
      }}
    />
    <TextBetween
      title={{
        margin: '8px 0',
        text: 'เขต/อำเภอ',
      }}
      content={{
        margin: '8px 0',
        text: data.address.district,
        color: '#968595',
      }}
    />
    <TextBetween
      title={{
        margin: '8px 0',
        text: 'จังหวัด',
      }}
      content={{
        margin: '8px 0',
        text: data.address.province,
        color: '#968595',
      }}
    />
    <TextBetween
      title={{
        margin: '8px 0',
        text: 'รหัสไปรษณีย์',
      }}
      content={{
        margin: '8px 0',
        text: data.address.postcode,
        color: '#968595',
      }}
    />
    <TextBetween
      title={{
        margin: '8px 0',
        text: 'วันที่สร้าง',
      }}
      content={{
        margin: '8px 0',
        text: moment.tz(data.createdAt, 'Asia/Bangkok').format('DD MMMM YYYY'),
        color: '#968595',
      }}
    />
    <TextBetween
      title={{
        margin: '8px 0',
        text: 'แก้ไขล่าสุด',
      }}
      content={{
        margin: '8px 0',
        text: moment.tz(data.updatedAt, 'Asia/Bangkok').format('DD MMMM YYYY'),
        color: '#968595',
      }}
    />
    <Remark message='หมายเหตุ' description={data.address.contactRemark || '-'}/>
  </Div>
)
