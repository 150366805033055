import React from 'react'
import _ from 'lodash'
import moment from 'moment-timezone'
import { useQuery } from '@apollo/client'
import { HISTORY } from '../../api/query'
import { HistoryList } from '../index'

const History = (props) => {
  const { data, loading } = useQuery(HISTORY, {
    variables: {
      moduleName: props.moduleName,
      refId: !_.isNumber(props.data.id)
        ? parseInt(props.data.id)
        : props.data.id,
    },
  })

  const list = !loading && !_.isEmpty(data)
    ? data.historyLogByModuleName.data.list.map((v) => ({
      name: `${v.actor.firstName} ${v.actor.lastName}`,
      createdDate: moment
        .tz(v.createdAt, 'Asia/Bangkok')
        .format('DD/MM/YYYY HH:mm'),
      message: v.message,
    }))
    : []

  return <HistoryList loading={loading} list={list} />
}

export default History
