import { theme } from '../../styles/_variables'

export const ITEM_KEYS = {
  waiting: 'WAITING_SUPPLIER',
  supplierConfirm: 'SUPPLIER_CF',
  watingPayment: 'WAITING_PAYMENT',
  watingPO: 'WAITING_PO',
  watingSupplier: 'WAITING_SUPPLIER',
  supplierCf: 'SUPPLIER_CF',
  partialReceived: 'PARTIAL_RECEIVED',
  received: 'RECEIVED',
  receiveOverdue: 'RECEIVE_OVERDUE',
  cancelledPo: 'CANCELLED_PO',
  preparing: 'PREPARING',
  watingShipment: 'WAITING_SHIPMENT',
  complete: 'COMPLETED',
  cancelled: 'CANCELLED',
  shipmentOverdue: 'SHIPMENT_OVERDUE',
  returned: 'RETURNED',
  refunded: 'REFUNDED',
  error: 'ERROR',
  inactive: 'INACTIVE',
  watingPT: 'WAITING_PT',
}

const ITEM_STATUS = [
  {
    key: ITEM_KEYS.waiting,
    text: 'รอเลขพัสดุ',
    color: theme.color.gray50,
    icon: 'fal fa-pause-circle',
  }, {
    key: ITEM_KEYS.supplierConfirm,
    text: 'ยืนยัน',
    color: theme.color.success,
    icon: 'fal fa-check',
  },
  {
    key: ITEM_KEYS.watingPayment,
    text: 'รอชำระเงิน',
    color: theme.color.gray50,
    icon: 'fal fa-pause-circle',
  },
  {
    key: ITEM_KEYS.watingPO,
    text: 'รอสร้าง PO',
    color: theme.color.gray50,
    icon: 'fal fa-pause-circle',
  },
  {
    key: ITEM_KEYS.watingSupplier,
    text: 'รอ Supplier ยืนยัน',
    color: theme.color.gray50,
    icon: 'fal fa-pause-circle',
  },
  {
    key: ITEM_KEYS.supplierCf,
    text: 'Supplier ยืนยัน',
    color: theme.color.gray50,
    icon: 'fal fa-check',
  },
  {
    key: ITEM_KEYS.partialReceived,
    text: 'รับของบางส่วน',
    color: theme.color.gray50,
    icon: 'fal fa-pause-circle',
  },
  {
    key: ITEM_KEYS.received,
    text: 'รับสินค้าทั้งหมด',
    color: theme.color.gray50,
    icon: 'fal fa-check',
  },
  {
    key: ITEM_KEYS.receiveOverdue,
    text: 'เกินกำหนดรับสินค้า',
    color: theme.color.warning,
    icon: 'fal fa-pause-exclamation-circle',
  },
  {
    key: ITEM_KEYS.cancelledPo,
    text: 'ยกเลิก PO',
    color: theme.color.error,
    icon: 'fal fa-pause-print-slash',
  },
  {
    key: ITEM_KEYS.preparing,
    text: 'รอเลขพัสดุ',
    color: theme.color.gray50,
    icon: 'fal fa-pause-circle',
  },
  {
    key: ITEM_KEYS.watingShipment,
    text: 'รอจัดส่ง',
    color: theme.color.gray50,
    icon: 'fal fa-pause-circle',
  },
  {
    key: ITEM_KEYS.complete,
    text: 'จัดส่งแล้ว',
    color: theme.color.success,
    icon: 'fal fa-pause-check',
  },
  {
    key: ITEM_KEYS.cancelled,
    text: 'ยกเลิก',
    color: theme.color.error,
    icon: 'fal fa-pause-print-slash',
  },
  {
    key: ITEM_KEYS.shipmentOverdue,
    text: 'เลยกำหนดจัดส่ง',
    color: theme.color.error,
    icon: 'fal fa-pause-circle',
  },
  {
    key: ITEM_KEYS.returned,
    text: 'คืนสินค้า',
    color: theme.color.gray50,
    icon: 'fal fa-pause-circle',
  },
  {
    key: ITEM_KEYS.refunded,
    text: 'คืนเงิน',
    color: theme.color.gray50,
    icon: 'fal fa-pause-circle',
  },
  {
    key: ITEM_KEYS.error,
    text: 'เกิดข้อผิดพลาด',
    color: theme.color.error,
    icon: 'fal fa-pause-circle',
  },
  {
    key: ITEM_KEYS.inactive,
    text: 'ใช้งานไม่ได้',
    color: theme.color.error,
    icon: 'fal fa-pause-circle',
  },
  {
    key: ITEM_KEYS.watingPT,
    text: 'รอเปิดการจัดส่ง',
    color: theme.color.gray50,
    icon: 'fal fa-pause-circle',
  },
]

export default ITEM_STATUS
