import { useMutation } from '@apollo/client'
import {
  Div, P,
} from '@konsys-ui-custom'
import { message } from 'antd'
import jwt from 'jwt-decode'
import { isEmpty } from 'lodash'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { LOGIN } from '../../api/mutation'
import BG from '../../assets/login-background.png'
import URL from '../../constants/environment'
import {
  saveLocalStorage,
  setAccessToken, setRefreshToken,
} from '../../utils/local-data-service'
import {
  CustomButton,
  CustomInput, LoginWrapper,
} from './styled'

export const Container = () => {
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [doLogin, { loading }] = useMutation(LOGIN)

  const callDoLogin = () => {
    doLogin({
      variables: {
        username,
        password,
        grant_type: process.env.REACT_APP_GRANT_TYPE,
        client_id: process.env.REACT_APP_CLIENT_ID,
        client_secret: process.env.REACT_APP_CLIENT_SECRET,
      },
    }).then((resp) => {
      if (resp.data && resp.data.login.success) {
        setAccessToken(resp.data.login.createdAccessToken.access_token)
        setRefreshToken(resp.data.login.createdAccessToken.refresh_token)
        saveLocalStorage('fullname', jwt(resp.data.login.createdAccessToken.access_token).fullname)
        window.location = URL.WEB_SERVER
      } else if (!resp.loading && !resp.data.login.success) {
        message.error(`ไม่สามารถเข้าสู่ระบบได้: ${resp.data.login.message || 'เกิดข้อผิดพลาด'}`)
      }
    })
  }

  const onEnter = (e) => {
    if (e.keyCode === 13 && (!isEmpty(username) && !isEmpty(password))) {
      callDoLogin()
    }
  }

  const isMobile = window.innerWidth <= 640

  return (
    <LoginWrapper>
      <Div>
        <img src={BG} />
        <Div>
          <Div>
            <h4>Log in</h4>
            <CustomInput placeholder='Email' onChange={(e) => setUsername(e.target.value)} onKeyDown={onEnter} />
            <CustomInput placeholder='Password' type='password' onChange={(e) => setPassword(e.target.value)} onKeyDown={onEnter} />
            <Div display='flex' alignItems='center' justifyContent='space-between'>
              <h4>Sign in</h4>
              <CustomButton
                loading={loading}
                disabled={loading || isEmpty(username) || isEmpty(password)}
                inverse
                isMobile={isMobile}
                text={!isMobile && 'Log in'}
                color={isMobile ? '#4C4B4B' : '#5BC6B2'}
                icon={isMobile && 'far fa-chevron-right'}
                onlyIcon={isMobile}
                buttonStyle='round'
                onClick={callDoLogin}
              />
            </Div>
            <Link to='forgot-password' style={{ alignSelf: 'flex-end', cursor: 'pointer' }} >
              <P display='block'>Forgot password?</P>
            </Link>
          </Div>
        </Div>
      </Div>
    </LoginWrapper>
  )
}

export default Container
