import styled from 'styled-components'
import { Checkbox } from '@konsys-ui-custom'

export const ActionCheckBox = styled(Checkbox)`
  > label {
    > span {
      ::before {
        font-family: 'Font Awesome 5 Pro'; 
      }
    }
  }
`

export default ActionCheckBox
