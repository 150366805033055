import { useMutation, useQuery } from '@apollo/client'
import { Button, Div, H4, Icon, Input, Label, P, SmallText } from '@konsys-ui-custom'
import { Col, DatePicker, Modal, Row, message } from 'antd'
import _, { sumBy } from 'lodash'
import moment from 'moment-timezone'
import numeral from 'numeral'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import { ADD_SERIAL, RECORD_DELIVERY } from '../../api/mutation'
import {
  CHAT_PROVIDERS,
  PICKING_TICKETS, PT_INFO,
} from '../../api/query'
import {
  Collapse, LargeTextWithLabel, ListContainer, Loading, ModalTrackingNumber, Remark, Select, TableList,
} from '../../components'
import History from '../../components/common/history'
import { DELIVERY_TYPE, LIST_LIMIT } from '../../constants/enum'
import URL from '../../constants/environment'
import { PATH } from '../../constants/path'
import ITEM_STATUS from '../../constants/status/item'
import PT_STATUS, { PT_KEYS } from '../../constants/status/pt'
import { AuthorizationContext } from '../../store/StoreProvider'
import { theme } from '../../styles/_variables'
import { delayInput } from '../../utils'
import { getAccessToken } from '../../utils/local-data-service'
import { permissionRedirect, renderText } from '../../utils/util-services'

const orderBy = [
  { text: 'เรียงจาก: ล่าสุด ไป อดีต', value: 'DESC' },
  { text: 'เรียงจาก: อดีต ไป ล่าสุด', value: 'ASC' },
]

const orderField = [
  { text: 'กรองจาก: วันที่แก้ไข', value: 'updatedAt' },
  { text: 'กรองจาก: วันที่สร้าง', value: 'createdAt' },
]

const Detail = (props) => {
  const [openCollapse, setOpenCollapse] = useState(true)
  const [data, setData] = useState()
  const [orderItems, setOrderItems] = useState([])
  const { loading: ptLoading, data: ptDetail } = useQuery(PT_INFO, { variables: { id: props.data.id } })
  const [doAddSerial, { loading: addSerialLoading, data: addSerialData }] = useMutation(ADD_SERIAL)

  useEffect(() => {
    setData(ptDetail?.pickingTicketDetail?.data)
    if (ptDetail?.pickingTicketDetail?.data?.lot?.salesOrder?.salesOrderType === 'POS') {
      setOrderItems(_.filter(ptDetail?.pickingTicketDetail?.data?.lot?.items, ['isSerialTracking', true])?.map((v) => (
        {
          id: v.id,
          serials: [],
        }
      )))
    }
  }, [ptDetail?.pickingTicketDetail?.data])

  useEffect(() => {
    if (!_.isEmpty(addSerialData)) {
      if (addSerialData?.addSerialNumber?.success) {
        message.success(addSerialData?.addSerialNumber?.message)
        if (props.setShowModalSerial) {
          props.setShowModalSerial(false)
        }
      }
      if (!addSerialData?.addSerialNumber?.success) message.error(addSerialData?.addSerialNumber?.message)
    }
  }, [addSerialData?.addSerialNumber])

  const checkIsEmptySerials = () => (
    _.some(orderItems.map((v) => {
      if (v.serials.length < _.find(data?.lot?.items, (o) => o.id === v.id)?.quantity) {
        return null
      }
      return false
    }), _.isNull)
  )

  const renderInput = (name, id, quantity) => {
    const inputList = []
    for (let i = 1; i <= quantity; i += 1) {
      inputList.push(
        <Div key={`${name} - ${i}`} margin='4px 0' display='flex' alignItems='center'>
          <SmallText margin='0 8px 0 0'>{i}.</SmallText>
          <Input small onChange={(e) => {
            const newData = { ..._.find(orderItems, (o) => o.id === id) }
            newData.serials[i - 1] = { serial: e.target.value }
            setOrderItems(_.uniqBy([...orderItems, newData], 'id'))
          }}/>
        </Div>,
      )
    }
    return inputList
  }

  const tableStyled = {
    0: {},
    1: {
      width: '100px',
    },
    2: {
      width: '100px',
    },
  }

  const headerData = [
    {
      text: 'สินค้า',
    }, {
      text: 'จำนวน',
      textAlign: 'center',
    },
  ]

  const tableListData = data?.lot?.items?.map((v) => (
    [
      {
        text: v.type === 'PACK' ? v.pack.inventoryOption.variantName : v.productSet.name,
        label: {
          text: <Div>
            <SmallText display='block'><Label margin='0 4px 0 0'>Sku: </Label>{v.type === 'PACK' && v.pack ? v.pack.inventoryOption?.inventory?.sku : v.productSet?.sku}</SmallText>
            <SmallText display='block'><Label margin='0 4px 0 0'>Brand: </Label>{v.type === 'PACK' && v.pack ? v.pack.inventoryOption?.inventory?.brand?.name : v.productSet?.sku}</SmallText>
            {
              v.replaceTo
              && <SmallText color={theme.color.gray50}>แทนสินค้า {v.replaceTo}</SmallText>
            }
            {
              v.replacedBy
              && <SmallText color={theme.color.gray50}>ถูกแทนที่ด้วย {v.replacedBy}</SmallText>
            }
          </Div>,
        },
        status: _.find(ITEM_STATUS, (o) => o.key === v.status),
        collapseData: v.isSerialTracking && !_.isEmpty(v.serialNumbers) ? <Div margin='8px 0 0' padding='16px 20px' bgColor={theme.color.background}>
          <SmallText display='block' bold margin='0 0 8px'>Serial Number</SmallText>
          {
            v.serialNumbers.map((o, i) => (
              <P key={o.id} display='block' margin='0 0 4px'>{i + 1}. {o.serial}</P>
            ))
          }
        </Div> : null,
      },
      { text: v.quantity, textAlign: 'center' },
    ]
  )) || []

  const deliveryDate = moment.tz(data?.lot?.deliveryDate, 'Asia/Bangkok')
  const deliveryType = _.find(DELIVERY_TYPE, (o) => o.value === data?.lot?.deliveryType)
  const deliveryAddress = data?.lot?.deliveryAddress

  if (ptLoading) {
    return (
      <Div textAlign='center' margin='32px 0 0'>
        <Loading size='small' />
      </Div>
    )
  }
  if (_.isEmpty(data)) return null

  return (
    <Div>
      <Modal
        title="บันทึก Serial Number"
        visible={props.showModalSerial}
        onOk={() => {
          doAddSerial({
            variables: {
              pickingTicketId: data.id,
              orderItems,
            },
          })
        }}
        onCancel={() => props.setShowModalSerial && props.setShowModalSerial(false)}
        okButtonProps={{ disabled: checkIsEmptySerials() || addSerialLoading }}
        cancelText="ยกเลิก"
        okText='ยืนยัน'
      >
        <Div moreStyle={{ maxHeight: '315px', overflow: 'auto' }}>
          {
            data?.lot?.items?.map((v, i) => {
              if (!v.isSerialTracking) return null
              return (
                <Div key={i} padding={i === 0 ? '0 0 16px' : '16px 0'} moreStyle={{ borderBottom: i !== data?.lot?.items?.length - 1 && `1px solid ${theme.color.lightBlue}` }}>
                  <SmallText bold margin='0 0 8px'>{v?.pack?.inventoryOption?.variantName} ({v.quantity} ชิ้น)</SmallText>
                  {
                    renderInput(v?.pack?.inventoryOption?.variantName, v.id, v.quantity)
                  }
                </Div>
              )
            })
          }
        </Div>
      </Modal>
      <ModalTrackingNumber
        multiple
        title='กรอก Tracking Number'
        showModal={props.showModal}
        onCancel={() => props.onCancel && props.onCancel()}
        delivery={props.delivery}
        setDelivery={(value) => props.setDelivery(value)}
        setNewDeliveryChannel={(value) => props.setNewDeliveryChannel(value)}
        doRecordDelivery={() => props.doRecordDelivery && props.doRecordDelivery(data.id)}
        recordDeliveryLoading={props.recordDeliveryLoading}
        id={data?.id}
      />
      <Div display='flex' justifyContent='space-between' alignItems='center'>
        <H4 bold color={theme.color.primaryColor}>{data.number}</H4>
        {
          !_.isEmpty(props.status)
          && <P bold color={props.status.color}>{props.status.text}</P>
        }
      </Div>
      <Div margin='32px 0'>
        <Div display='flex' alignItems='flex-start' moreStyle={{ flexWrap: 'wrap' }}>
          <LargeTextWithLabel margin='0 24px 16px 0' title={{ text: 'วันที่สร้าง PT' }} content={{ text: moment.tz(data.createdAt, 'Asia/Bangkok').format('DD/MM/YY HH:mm') }} />
          <LargeTextWithLabel margin='0 24px 16px 0' title={{ text: 'ลูกค้า' }} content={{ text: data.lot.salesOrder.customer.name }} />
          <LargeTextWithLabel margin='0 24px 16px 0' title={{ text: 'วันที่กำหนดส่ง' }} content={{ text: _.isEmpty(data.lot) ? '-' : deliveryDate.format('DD/MM/YYYY'), color: deliveryDate.diff(moment().tz('Asia/Bangkok'), 'days') <= 0 ? theme.color.error : theme.color.gray80 }} />
          <LargeTextWithLabel margin='0 24px 16px 0' title={{ text: 'คลังสินค้า' }} content={{ text: data.warehouse ? data.warehouse.warehouseName : '-' }} />
          <LargeTextWithLabel margin='0 24px 16px 0' title={{ text: 'เบอร์ผู้ติดต่อ' }} content={{ text: _.find(data.lot.salesOrder.customer.contactPhoneNumber, (o) => o.isPrimary)?.phoneNumber || '-' }} />
        </Div>
        <Div display='flex' alignItems='flex-start' margin='0 0 16px'>
          <LargeTextWithLabel title={{ text: 'ช่องทางจัดส่ง' }} content={{ text: _.isEmpty(deliveryType) ? '-' : deliveryType.text }} />
          <LargeTextWithLabel
            margin='0 0 0 24px'
            title={{ text: 'ที่อยู่จัดส่ง' }}
            content={{
              style: { whiteSpace: 'pre-wrap' },
              text: _.isEmpty(deliveryAddress) ? '-' : <SmallText>{deliveryAddress?.thirdPartyAddress || ''} {deliveryAddress.addressNo ? `${deliveryAddress.addressNo} ${deliveryAddress.subDistrict} ${deliveryAddress.district} ${deliveryAddress.province} ${deliveryAddress.postcode}\n${deliveryAddress.contactName} เบอร์โทรศัพท์ ${deliveryAddress.contactPhoneNumber}` : ''}</SmallText>,
            }}
          />
        </Div>
        {
          !_.isEmpty(data.deliveryChannels)
          && <Div margin='0 0 24px'>
            <SmallText bold margin='0 0 8px'>Tracking Number</SmallText>
            <Div>
              {
                data.deliveryChannels.map((o, i) => (
                  <Div display='flex' key={i} margin={i !== 0 ? '8px 0 0' : '0'}>
                    <LargeTextWithLabel style={{ width: '25%' }} title={{ text: 'บริษัทขนส่ง' }} content={{ text: o.name || '-' }} />
                    <LargeTextWithLabel margin='0 0 0 24px' title={{ text: 'Tracking Number' }} content={{ text: o.trackingNumber || '-' }} />
                  </Div>
                ))
              }
            </Div>
          </Div>
        }
        <Div margin='0 0 24px'>
          <SmallText bold margin='0 0 8px'>กล่อง</SmallText>
          <Div>
            <Row gutter={16}>
              <Col span={4}><SmallText bold>ขนาดกล่อง</SmallText></Col>
              <Col span={4} style={{ textAlign: 'right' }}><SmallText bold>จำนวน</SmallText></Col>
              <Col span={4} style={{ textAlign: 'right' }}><SmallText bold>ราคา</SmallText></Col>
            </Row>
            {
              data.pickingTicketBoxes.map((o, i) => (
                <Row gutter={16} key={i}>
                  <Col span={4}><P>{o.box.name}</P></Col>
                  <Col span={4} style={{ textAlign: 'right' }}><P>{numeral(o.quantity).format('0,0')}</P></Col>
                  <Col span={4} style={{ textAlign: 'right' }}><P>{numeral(o.box.price * o.quantity).format('0,0')}</P></Col>
                </Row>
              ))
            }
            <Row gutter={16} style={{ marginTop: 8 }}>
              <Col span={4}><P bold>รวมทั้งหมด</P></Col>
              <Col span={4} style={{ textAlign: 'right' }}><P bold>{numeral(sumBy(data.pickingTicketBoxes, (o) => o.quantity)).format('0,0')}</P></Col>
              <Col span={4} style={{ textAlign: 'right' }}><P bold>{numeral(sumBy(data.pickingTicketBoxes, (o) => o.quantity * o.box.price)).format('0,0')}</P></Col>
            </Row>
          </Div>
        </Div>
        <Div display='flex' alignItems='flex-start' margin='0 0 16px'>
          <Div margin='0 24px 0 0' padding='16px' bgColor={theme.color.gray10}>
            <SmallText bold margin='0 0 8px'>อ้างอิง</SmallText>
            {
              !_.isEmpty(data.lot.salesOrder)
              && <Div margin='0 0 0 16px'>
                <P display='block' margin='0 0 4px'>{data.lot.salesOrder.soNumber}</P>
                <SmallText margin='0 0 0 16px'>ล็อตที่ {data.lot.lotIndex || '-'}</SmallText>
              </Div>
            }
          </Div>
          {
            !_.isEmpty(data.files)
          && <LargeTextWithLabel
            style={{ maxWidth: '100%', wordBreak: 'break-all' }}
            title={{ text: 'เอกสาร' }}
            content={data.files.map((v, i) => ({
              text: v.fileName,
              type: 'a',
              link: v.url,
              margin: i === data.files.length - 1 ? 0 : '0 0 4px',
              target: '_blank',
            }))}
          />
          }
        </Div>
        <Remark description={renderText(data.remark)} />
        { _.size(data.taxInvoices) > 0
          && <Remark style={{ marginTop: 16 }} message='Tax invoices' description={_.join(data.taxInvoices.map((taxInvoice) => `${taxInvoice.invoice ? taxInvoice.invoice.number : taxInvoice.receipt.number} (${taxInvoice.ivNumber})`), ', ')} />
        }
      </Div>
      <Collapse text='รายละเอียดสินค้า' fullHeight open={openCollapse} onClick={() => setOpenCollapse(!openCollapse)}>
        <Div>
          <TableList isDataCollapse data={tableListData} headerData={headerData} columnStyled={tableStyled} />
        </Div>
      </Collapse>
    </Div>
  )
}

const PTList = () => {
  const [searchText, setSearchText] = useState('')
  const [selectedDate, setSelectedDate] = useState([moment().subtract(1, 'M'), moment()])
  const [filterStatus, setFilterStatus] = useState()
  const [filterChatProviderId, setFilterChatProviderId] = useState()
  const [filterLoading, setFilterLoading] = useState(false)
  const [firstLoad, setFirstLoad] = useState(true)
  const [fetchMoreLoading, setFetchMoreLoading] = useState(false)
  const [selectedOrderField, setSelectedOrderField] = useState(orderField[0].value)
  const [selectedOrderBy, setSelectedOrderBy] = useState(orderBy[0].value)
  const [showModal, setShowModal] = useState(false)
  const [showModalSerial, setShowModalSerial] = useState(false)
  const [delivery, setDelivery] = useState([
    { id: 1, trackingNumber: '', deliveryProvider: null },
  ])
  const [newDeliveryChannel, setNewDeliveryChannel] = useState('')
  const isInitialMount = useRef(true)
  const [isLoading, setIsLoading] = useState(false)
  const { loading: chatProvidersLoading, data: chatProvidersData } = useQuery(CHAT_PROVIDERS)
  const [doRecordDelivery, { loading: recordDeliveryLoading }] = useMutation(RECORD_DELIVERY, { refetchQueries: ['remainingJob', 'remainingAccountant'] })
  const {
    data, loading, refetch, fetchMore,
  } = useQuery(PICKING_TICKETS, {
    variables: {
      limit: LIST_LIMIT,
      offset: 0,
      createdDate: selectedDate,
    },
  })

  const authorization = useContext(AuthorizationContext)
  const pickingTicketAutho = authorization.authorization?.pickingTicket || {}
  const ptList = (!loading || !_.isEmpty(data)) && data.pickingTicketList.data?.list.map((v) => {
    const deliveryDate = moment.tz(v.deliveryDate, 'Asia/Bangkok')
    const deliveryType = _.find(DELIVERY_TYPE, (o) => o.value === v.deliveryType)
    const status = _.find(PT_STATUS, (o) => o.key === v.status)

    return (
      {
        title: v.number,
        status: _.isEmpty(status) ? { text: '-' } : status,
        content: {
          one: {
            title: {
              text: v.customer.name,
            },
            content: {
              text: _.isEmpty(deliveryType) ? '-' : deliveryType.text,
            },
          },
          three: {
            title: { text: 'วันที่กำหนดส่ง' },
            content: {
              text: deliveryDate.format('DD/MM/YYYY'),
              color: deliveryDate.diff(moment().tz('Asia/Bangkok'), 'days') <= 0 ? theme.color.error : theme.color.gray80,
            },
          },
        },
        detail: {
          title: [
            {
              text: 'รายละเอียด',
              icon: 'fal fa-info-square',
            },
            pickingTicketAutho.viewHistoryLog && {
              text: 'ประวัติการใช้งาน',
              icon: 'fad fa-history',
            },
          ],
          content: [
            <Detail
              key={0}
              data={v}
              status={status}
              showModal={showModal}
              showModalSerial={showModalSerial}
              newDeliveryChannel={newDeliveryChannel}
              setNewDeliveryChannel={(value) => setNewDeliveryChannel(value)}
              setShowModalSerial={(value) => setShowModalSerial(value)}
              setDelivery={(value) => setDelivery(value)}
              delivery={delivery}
              onCancel={() => setShowModal(false)}
              recordDeliveryLoading={recordDeliveryLoading}
              doRecordDelivery={(ptId) => {
                doRecordDelivery({
                  variables: {
                    pickingId: ptId,
                    deliveryDetails: _.filter(delivery, (obj) => !_.isEmpty(obj.trackingNumber)).map((o) => (
                      {
                        name: o.name,
                        trackingNumber: o.trackingNumber,
                      }
                    )),
                  },
                }).then((resp) => {
                  if (resp.data?.recordDelivery?.success) {
                    setShowModal(false)
                    message.success(resp.data?.recordDelivery?.message)
                    refetch({ limit: data.pickingTicketList?.data?.list?.length })
                  } else {
                    message.error(resp.data?.recordDelivery?.message)
                  }
                })
              }}
            />,
            <History
              key={1}
              data={v}
              moduleName='PICKING_TICKET'
            />,
          ],
          buttonList: v.salesOrderType === 'POS' && !v.isSerialed
            ? [
              <Button small key={0} icon='fal fa-pencil' text='บันทึก Serial Number' onClick={() => setShowModalSerial(true)} />,
            ]
            : v.deliveryType === 'DELIVERY'
              ? [
                v.status === PT_KEYS.waiting && <Button small icon='fal fa-print' margin='4px 8px 4px 0' key={0} text={'พิมพ์ที่อยู่จัดส่ง'} loading={isLoading} onClick={() => {
                  setIsLoading(true)
                  window.open(
                    `${URL.API_HOST}/print/picking-tickets/delivery-address/${v.id}?t=${getAccessToken()}`,
                    '_blank',
                    'toolbar=false,scrollbars=yes,resizable=false',
                  )
                  setIsLoading(false)
                }} />,
                <Button small icon='fal fa-box-alt' margin='4px 8px 4px 0' key={0} text={'กรอก Tracking Number'} onClick={() => setShowModal(true)} />,
              ]
              : [],
        },
      }
    )
  })

  useEffect(() => {
    if (!pickingTicketAutho.view) {
      permissionRedirect()
    }
  }, [])

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false
    } else {
      setFilterLoading(true)
      setFirstLoad(false)
      refetch({
        search: searchText,
        createdDate: selectedDate,
        orderField: selectedOrderField,
        orderBy: selectedOrderBy,
        chatProviderId: filterChatProviderId,
        status: filterStatus === 'ALL' ? undefined : filterStatus,
      }).then(() => {
        setFilterLoading(false)
      })
    }
  }, [searchText, selectedDate, selectedOrderField, selectedOrderBy, filterStatus, filterChatProviderId])

  return (
    <ListContainer
      tabLoading={fetchMoreLoading}
      listLoading={filterLoading || (firstLoad && _.isEmpty(data))}
      title={[
        {
          text: 'การจัดส่ง',
          icon: 'fal fa-truck-loading',
        },
      ]}
      buttonList={pickingTicketAutho.create ? [
        <Button small icon='fal fa-print' margin='0 8px 0 0' key={0} text={'พิมพ์ที่อยู่จัดส่งทั้งหมด'} loading={isLoading} onClick={() => {
          setIsLoading(true)
          window.open(
            `${URL.API_HOST}/print/picking-tickets/delivery-addresses?t=${getAccessToken()}`,
            '_blank',
            'toolbar=false,scrollbars=yes,resizable=false',
          )
          setIsLoading(false)
        }} />,
        <Link key={0} to={`${PATH.pt}${PATH.create}`}>
          <Button small ghost inverse color={theme.color.success} text='เพิ่มใบจัดส่งใหม่' icon='fal fa-plus' />
        </Link>,
      ] : [<Button small icon='fal fa-print' key={0} text={'พิมพ์ที่อยู่จัดส่งทั้งหมด'} loading={isLoading} onClick={() => {
        setIsLoading(true)
        window.open(
          `${URL.API_HOST}/print/picking-tickets/delivery-addresses?t=${getAccessToken()}`,
          '_blank',
          'toolbar=false,scrollbars=yes,resizable=false',
        )
        setIsLoading(false)
      }} />]}
      filterHeight={100}
      filter={[
        <Div key={0} margin='0 0 24px'>
          <Div display='flex' alignItems='flex-start' moreStyle={{ flexGrow: 1 }}>
            <Div margin='0 16px 0 0' moreStyle={{ flexGrow: 1 }}>
              <Input
                type='text'
                width='100%'
                prefix={<Icon icon='prefix fal fa-search' />}
                placeholder='Client Name, SO Number, ชื่อสินค้า, รหัสสินค้า'
                // value={searchText}
                onChange={(e) => {
                  const searchValue = e.target.value
                  delayInput(() => {
                    setSearchText(searchValue)
                  })
                }}
                suffix={(!loading && !_.isEmpty(data?.pickingTicketList?.data) && !_.isEmpty(searchText)) && <Div position='absolute' right='16px' top='50%' moreStyle={{ transform: 'translateY(-50%)' }}><SmallText>{numeral(data?.pickingTicketList?.data?.total || 0).format('0,0')} รายการ</SmallText></Div>}
              />
            </Div>
            <Select
              style={{ width: '20%', margin: '0 16px 0 0' }}
              placeholder='ช่องทางการขาย'
              options={chatProvidersData ? chatProvidersData?.chatProviders?.data.map((v) => ({
                id: v.id, value: v.id, text: v.name, code: v.code,
              })) : []}
              onChange={(value) => setFilterChatProviderId(value)}
              value={filterChatProviderId}
              loading={chatProvidersLoading}
            />
            <Select
              style={{ width: '20%', margin: '0 16px 0 0' }}
              placeholder='สถานะการจัดส่ง'
              options={[{ text: 'ทั้งหมด', value: 'ALL' }, { text: 'รอยืนยัน', value: 'WAITING' }, { text: 'จัดส่งแล้ว', value: 'DELIVERED' }, { text: 'ยกเลิก', value: 'CANCELLED' }]}
              onChange={(value) => setFilterStatus(value)}
              value={filterStatus}
            />
            <DatePicker.RangePicker
              style={{ flexBasis: '28%' }}
              format='DD/MM/YYYY'
              suffixIcon={<Icon color={theme.color.gray50} icon='fal fa-calendar' />}
              value={selectedDate}
              onChange={(v) => setSelectedDate(v)}
            />
          </Div>
          <Div display='flex' margin='8px 0 0' justifyContent='flex-end'>
            <Div display='flex' moreStyle={{ flexGrow: 1 }} margin='0 56px 0 0'>
              <Select
                value={selectedOrderField}
                style={{ width: '41.65%', minWidth: '200px', margin: '0 16px 0 0' }}
                options={orderField}
                onChange={(value) => setSelectedOrderField(value)}
              />
              <Select
                value={selectedOrderBy}
                style={{ width: '41.65%', minWidth: '200px' }}
                options={orderBy}
                onChange={(value) => setSelectedOrderBy(value)}
              />
            </Div>
            <Div display='flex' alignItems='center'>
              <Button text='ล้าง' icon='fal fa-sync' small ghost onClick={() => {
                setSearchText('')
                setSelectedDate(null)
                setSelectedOrderField(orderField[0].value)
                setSelectedOrderBy(orderBy[0].value)
                setFilterLoading(true)
                setFilterStatus()
                setFilterChatProviderId()
                refetch({
                  search: '',
                  createdDate: undefined,
                  orderField: undefined,
                  orderBy: undefined,
                  filterChatProviderId: undefined,
                  status: undefined,
                }).then(() => {
                  setFilterLoading(false)
                })
              }} />
            </Div>
          </Div>
        </Div>,
      ]}
      list={ptList}
      onInfiniteScroll={() => {
        setFetchMoreLoading(true)
        fetchMore({
          variables: {
            offset: data.pickingTicketList.data.list.length,
          },
          updateQuery: (prev, { fetchMoreResult }) => {
            setFetchMoreLoading(false)
            if (!fetchMoreResult) return prev
            return { ...prev,
              pickingTicketList: {
                ...prev.pickingTicketList,
                data: {
                  ...prev.pickingTicketList.data,
                  total: prev.pickingTicketList.data.total,
                  list: [...prev.pickingTicketList.data.list, ...fetchMoreResult.pickingTicketList.data.list],
                },
              } }
          },
        })
      }}
    />
  )
}

export default PTList
