import { useQuery } from '@apollo/client'
import {
  Button,
  Div,
  Icon,
  Label,
  SmallText,
} from '@konsys-ui-custom'
import { Input } from 'antd'
import _, { compact } from 'lodash'
import moment from 'moment-timezone'
import numeral from 'numeral'
import React, {
  useEffect, useRef,
  useState,
} from 'react'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'
import { GET_RECEIPTS } from '../../../api/query'
import {
  DebtDetail,
  ListWithoutTab, Select,
} from '../../../components'
import { PATH } from '../../../constants/path'
import { theme } from '../../../styles/_variables'
import Detail from './quick-view'
import { delayInput } from '../../../utils'

export default (props) => {
  const searchOrderInputRef = useRef()
  const [filterLoading, setFilterLoading] = useState(false)
  const [firstLoad, setFirstLoad] = useState(true)
  const [currentCard, setCurrentCard] = useState(0)
  const [searchText, setSearchText] = useState()
  const [orderField, setOrderField] = useState('createdAt')
  const [orderBy, setOrderby] = useState('DESC')
  const isInitialMount = useRef(true)
  const {
    data, loading, refetch, fetchMore,
  } = useQuery(GET_RECEIPTS, {
    variables: {
      limit: 10, offset: 0, paymentType: props.type,
    },
  })

  useEffect(() => {
    if (!searchText || searchText === '') {
      searchOrderInputRef?.current?.focus()
    }
  })

  const receiptList = (!loading || !_.isEmpty(data)) && data.receiptList?.data?.list.map((v) => ({
    title: v.isReturnSalesOrder ? <Div>
      <SmallText margin='0 8px 0 0' bold>{v.number}</SmallText>
      <Div display='inline-block'>
        <Icon color={theme.color.error} fontSize={theme.fonts.size.label} icon='fas fa-triangle-exclamation' />
        <Label margin='0 0 0 4px'>มีใบตีกลับ</Label>
      </Div>
    </Div> : v.number,
    status: {
      text: `วันที่สร้าง ${moment(v.createdAt).format('DD/MM/YYYY')}`,
    },
    content: props.type === 'CASH'
      ? {
        one: {
          title: {
            text: 'ชื่อลูกค้า',
          },
          content: {
            text: `${v?.customer?.prefix ? `${v?.customer?.prefix} ` : ''}${v?.customer?.name}`,
            width: '110px',
          },
        },
        two: {
          title: {
            text: 'ยอดชำระ',
          },
          content: {
            text: `${numeral(v.subtotalAfterDiscount).format('0,0.00')} บาท`,
          },
        },
      }
      : {
        one: {
          title: {
            text: 'ชื่อลูกค้า',
          },
          content: {
            text: `${v?.customer?.prefix ? `${v?.customer?.prefix} ` : ''}${v?.customer?.name}`,
            width: '110px',
          },
        },
        two: {
          title: {
            text: 'ยอดชำระ',
          },
          content: {
            text: `${numeral(v.subtotalAfterDiscount).format('0,0.00')} บาท`,
          },
        },
      },
    detail: {
      title: compact([
        {
          id: 'view',
          text: 'รายละเอียด',
          icon: 'fal fa-info-square',
        },
        v.isReturnSalesOrder
          ? {
            id: 'CNDN',
            text: 'ใบตีกลับ',
            icon: 'fal fa-money-check-alt',
          }
          : null,
      ]),
      content: compact([
        <Detail
          key={0}
          data={v}
        />,
        v.isReturnSalesOrder
          ? <Div key='returnSo' padding='16px 0 0'>
            <Div display='flex' margin='0 0 32px 0'>
              <DebtDetail
                block
                number={v.number}
                receiptId={v.id}
              />
            </Div>
          </Div>
          : null,
      ]),
      buttonList: [
        !v.isReturnSalesOrder && <Link key={1} to={`${PATH.receipts.name}${PATH.receipts.creditNote}/${v.id}`}>
          <Button small margin='4px 8px 4px 0' text='สร้างใบตีกลับ' icon='far fa-receipt' />
        </Link>,
      ],
    },
  }))

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false
    } else {
      setFirstLoad(false)
      setFilterLoading(true)
      refetch({
        search: searchText,
        orderField,
        orderBy,
      }).then(() => {
        setFilterLoading(false)
      })
    }
  }, [searchText, orderField, orderBy])

  return (
    <Div height='100%'>
      <Helmet>
        <meta name="viewport" content="width=1280" />
      </Helmet>
      <ListWithoutTab
        currentCard={currentCard}
        setCurrentCard={(value) => setCurrentCard(value)}
        listLoading={filterLoading || (firstLoad && _.isEmpty(data))}
        list={receiptList}
        filterHeight={100}
        filter={[
          <Div key={0} margin='0 0 24px'>
            <Div display='flex' alignItems='flex-start'>
              <Div moreStyle={{ flexGrow: 1 }}>
                <Input
                  ref={searchOrderInputRef}
                  prefix={<Icon icon='prefix fal fa-search' />}
                  placeholder='ค้นหาเลขใบเสร็จ, เลขใบแจ้งหนี้, ชื่อลูกค้า'
                  onChange={(e) => {
                    const searchValue = e.target.value
                    delayInput(() => {
                      setSearchText(searchValue)
                    })
                  }}
                  suffix={(!loading && !_.isEmpty(data?.receiptList?.data) && !_.isEmpty(searchText)) && <Div position='absolute' right='16px' top='50%' moreStyle={{ transform: 'translateY(-50%)' }}><SmallText>{numeral(data?.receiptList?.data?.total || 0).format('0,0')} รายการ</SmallText></Div>}
                />
              </Div>
            </Div>
            <Div display='flex' margin='8px 0 0' justifyContent='flex-end'>
              <Div display='flex' moreStyle={{ flexGrow: 1 }} margin='0 56px 0 0'>
                <Select
                  defaultValue='createdAt'
                  onChange={(value) => setOrderField(value)}
                  value={orderField}
                  style={{ width: '41.65%', minWidth: '200px', margin: '0 16px 0 0' }}
                  options={[{ text: 'เรียงตามวันที่สร้าง', value: 'createdAt' }, { text: 'เรียงตามวันที่ชำระ', value: 'paidDate' }]}
                />
                <Select
                  suffixIcon={<Icon icon={orderBy === 'ASC' ? 'fal fa-sort-amount-down-alt' : 'fal fa-sort-amount-up-alt'} />}
                  defaultValue='DESC'
                  value={orderBy}
                  onChange={(value) => setOrderby(value)}
                  options={[{ text: 'น้อยไปมาก', value: 'ASC' }, { text: 'มากไปน้อย', value: 'DESC' }]}
                />
              </Div>
              <Div display='flex' alignItems='center'>
                <Button text='ล้าง' icon='fal fa-sync' small ghost onClick={() => {
                  setSearchText('')
                  setOrderField('createdAt')
                  setFilterLoading(true)
                  refetch({
                    search: '',
                    status: 'ALL',
                    orderBy: 'DESC',
                    orderField: 'createdAt',
                  }).then(() => {
                    setFilterLoading(false)
                  })
                }} />
              </Div>
            </Div>
          </Div>,
        ]}
        onInfiniteScroll={() => {
          if (props.setFetchMoreLoading) {
            props.setFetchMoreLoading(true)
          }
          fetchMore({
            variables: {
              offset: data.receiptList.data.list.length,
            },
            updateQuery: (prev, { fetchMoreResult }) => {
              if (props.setFetchMoreLoading) {
                props.setFetchMoreLoading(false)
              }
              if (!fetchMoreResult) return prev
              return { ...prev,
                receiptList: {
                  ...prev.receiptList,
                  data: {
                    ...prev.receiptList.data,
                    total: prev.receiptList.data.total,
                    list: [...prev.receiptList.data.list, ...fetchMoreResult.receiptList.data.list],
                  },
                } }
            },
          })
        }}
      />
    </Div>
  )
}
