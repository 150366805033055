import { useQuery } from '@apollo/client'
import {
  Div,
  Icon,
  Input,
  Label,
  P, SmallText,
  TextLink,
} from '@konsys-ui-custom'
import {
  DatePicker, Divider,
  Popover,
  Skeleton,
} from 'antd'
import _ from 'lodash'
import numeral from 'numeral'
import React, { useEffect } from 'react'
import {
  ITEM_LIST_BY_SUPPLIER,
} from '../../api/query'
import imageNotAvailable from '../../assets/image-not-available.png'
import {
  TextBetween,
  TextareaWithLabel,
  Uploader,
} from '../../components'
import { theme } from '../../styles/_variables'
import { delayInput } from '../../utils'
import { CheckboxRow, CollapseBox } from './component'

export const StepWithFilter = ({
  currentStep, selectedList, setSelectedList, setDisabledButton, filterVariables, setFilterVariables, isEmptyAllSelectedList,
}) => {
  const supplierId = filterVariables?.supplierId === 'All' ? undefined : filterVariables?.supplierId
  const { data: itemListData, loading: itemListLoading } = useQuery(ITEM_LIST_BY_SUPPLIER, { variables: { supplierId: 1, search: filterVariables?.search } })

  useEffect(() => {
    if ((_.isNil(supplierId) || _.isEmpty(filterVariables?.dueDate)) || (isEmptyAllSelectedList)) setDisabledButton(true)
    else setDisabledButton(false)
  }, [filterVariables])

  useEffect(() => {
    if (isEmptyAllSelectedList) setDisabledButton(true)
    else setDisabledButton(false)
  }, [isEmptyAllSelectedList])

  useEffect(() => {
    setFilterVariables({ ...filterVariables, search: '' })
  }, [currentStep])

  const itemList = _.compact(_.keys(_.omit(itemListData?.itemListBySupplier?.data, ['__typename']))?.map((itemCategory) => (
    {
      id: itemCategory,
      contactName: itemCategory === 'itemListBelowMinimum' ? 'สินค้าใกล้หมดในคลัง' : 'สินค้าอื่นๆในคลังสินค้า',
      soDetail: '',
      itemList: itemListData?.itemListBySupplier?.data[itemCategory]?.map((obj) => {
        const inventoryOption = obj?.inventoryOption
        return (
          {
            id: inventoryOption?.id || obj?._id, // eslint-disable-line
            src: !_.isEmpty(inventoryOption?.files) ? inventoryOption?.files[0]?.url : imageNotAvailable,
            name: obj?.productName || '-',
            brand: obj?.brand?.name || '',
            sku: obj?.sku || '-',
            waitingAmount: '',
            warehouse: '',
            amount: _.isEmpty(inventoryOption) ? '-' : `${numeral(inventoryOption?.totalStock).format('0,0')} (${inventoryOption?.toBeStocked + inventoryOption?.preordered >= 0 ? '+' : ''}${numeral(inventoryOption?.toBeStocked + inventoryOption?.preordered).format('0,0')}) ชิ้น`,
            sellAmount: _.isEmpty(inventoryOption) ? '-' : `${numeral(inventoryOption?.totalStock - inventoryOption?.soldStock).format('0,0')} (${inventoryOption?.toBeStocked >= 0 ? '+' : ''}${numeral(inventoryOption?.toBeStocked).format('0,0')}) ชิ้น`,
            minimumStock: _.isEmpty(inventoryOption) ? '-' : `${numeral(inventoryOption?.minimumStock).format('0,0')} ชิ้น`,
            quantity: 1,
            buyPrice: obj?.buyPrice || 0,
            discount: 0,
            isVat: inventoryOption?.isVat,
            vat: inventoryOption?.vat,
            packId: obj.type === 'VARIANT' && !_.isEmpty(inventoryOption?.pack) ? inventoryOption?.pack[0]?.id : undefined,
            setId: obj.type === 'VARIANT' ? undefined : obj?._id, // eslint-disable-line
            supplier: obj.supplier,
          }
        )
      }),
    }
  )))

  return (
    <Div>
      <Div margin='0 0 16px'>
        <Div display='flex' alignItems='center'>
          <Div margin='0 16px 0 0'>
            <SmallText display='block' bold margin='0 0 8px'>วันที่กำหนดส่ง <Label color={theme.color.error}>*</Label></SmallText>
            <DatePicker className={_.isEmpty(filterVariables?.dueDate) && 'state-error'} format='DD/MM/YY' value={filterVariables?.dueDate} onChange={(date) => setFilterVariables({ ...filterVariables, dueDate: date })} />
          </Div>
        </Div>
        <Div margin='24px 0 0' display='flex'>
          <Input
            width={'calc(100%)'}
            placeholder='ค้นหาสินค้าจากรหัสสินค้า'
            onChange={(e) => {
              const searchValue = e.target.value
              delayInput(() => {
                setFilterVariables({ ...filterVariables, search: searchValue })
              })
            }}
          />
        </Div>
      </Div>
      {
        itemListLoading
          ? <Div>
            <Skeleton active />
          </Div>
          : itemList?.map((collapseData, index) => {
            let isActive = true
            const checkIsCollapseHasSelectedItem = !_.isEmpty(_.intersection(collapseData?.itemList?.map((obj) => obj.orderId), selectedList.map((obj) => obj.orderId)))
            if (checkIsCollapseHasSelectedItem) {
              isActive = true
            }
            return (
              <CollapseBox isOpenCollapse={isActive} key={collapseData?.id} margin={index !== 0 ? '16px 0 0' : '0'} contactName={collapseData?.contactName} soDetail={collapseData?.soDetail}>
                {
                  _.isEmpty(collapseData?.itemList)
                    ? <SmallText color={theme.color.gray50} display='block' textAlign='center'>ไม่มี{collapseData?.contactName}</SmallText>
                    : collapseData?.itemList?.map((data, i) => (
                      <CheckboxRow
                        type={!_.isEmpty(collapseData?.soDetail) ? 'so' : 'item'}
                        key={data?.orderId || data?.id}
                        id={data?.orderId || data?.id}
                        active={!_.isEmpty(_.find(selectedList, (obj) => (obj.id === data?.id)))}
                        data={data}
                        margin={i !== 0 ? '16px 0 0' : '0'}
                        onChange={(checked) => {
                          if (checked) {
                            setSelectedList(_.uniqBy([...selectedList, data], 'id'))
                          } else {
                            setSelectedList(_.filter([...selectedList], (obj) => obj?.id !== data?.id))
                          }
                        }} />
                    ))
                }
              </CollapseBox>
            )
          })
      }
    </Div>
  )
}

const InputRow = (props) => {
  const totalPrice = _.isEmpty(props.data) ? 0 : (props.data.quantity * props.data.buyPrice) - (props.data.quantity * props.data.discount)
  return (
    <Div display='flex' alignItems='center' justifyContent='space-between' {...props}>
      <Icon
        cursor='pointer'
        color={theme.color.lightBlue}
        margin='0 24px 0 0'
        icon='fal fa-trash-alt'
        onClick={() => props.onDelete && props.onDelete()}
      />
      <Div width='100%' display='flex' alignItems='center'>
        <P style={{ width: '38%' }} margin='0 40px 0 0'>{props.data.sku} {props.data.name} {props.data.brand?.name}</P>
        <Input
          defaultValue={_.isNaN(props.data.quantity) ? '0' : `${props.data.quantity}`}
          disabled={props.disabledInputQuantity}
          onChange={(e) => props.onChange(e.target.value, 'quantity')}
          width='7%'
          margin='0 24px 0 0'
          rule={{ type: 'float' }}
          state={(_.isNaN(props.data.quantity) || (_.isEmpty(props.data.quantity) && !_.isNumber(props.data.quantity)) || props.data.quantity <= 0) ? 'error' : 'default'}
        />
        {
          !_.isUndefined(props.data.salesPrice)
            ? <Popover content={`ไม่เกินราคาซื้อ: ${props.data.salesPrice}`}>
              <Input
                value={props.data.buyPrice}
                width='16%'
                margin='0 24px 0 0'
                rule={{ type: 'float' }}
                state={(_.isNaN(props.data.buyPrice) || (_.isEmpty(props.data.buyPrice) && !_.isNumber(props.data.buyPrice))) ? 'error' : 'default'}
                onChange={(e) => props.onChange(e.target.value, 'buyPrice')}
              />
            </Popover>
            : <Input
              value={props.data.buyPrice}
              width='16%'
              margin='0 24px 0 0'
              rule={{ type: 'float' }}
              state={(_.isNaN(props.data.buyPrice) || (_.isEmpty(props.data.buyPrice) && !_.isNumber(props.data.buyPrice))) ? 'error' : 'default'}
              onChange={(e) => props.onChange(e.target.value, 'buyPrice')}
            />
        }
        <Input
          defaultValue={props.data.discount}
          width='14%'
          margin='0 24px 0 0'
          rule={{ type: 'float' }}
          state='default'
          onChange={(e) => props.onChange(e.target.value, 'discount')}
        />
        <P textAlign='right' style={{ flexGrow: 1 }}>{numeral(totalPrice).format('0,0.00')}</P>
      </Div>
    </Div>
  )
}

export const Step3 = ({
  selectedList, setSelectedList, mutationVariables, setMutationVariables,
}) => (
  <Div margin='64px 0 0'>
    <Div margin='0 0 0 38px' display='flex' alignItems='center'>
      <SmallText style={{ width: '38%' }} bold margin='0 40px 0 0'>สินค้า</SmallText>
      <SmallText style={{ width: '7%' }} bold margin='0 24px 0 0'>จำนวน <Label color={theme.color.error}>*</Label></SmallText>
      <SmallText style={{ width: '16%' }} bold margin='0 24px 0 0'>ราคา / ชิ้น <Label color={theme.color.error}>*</Label></SmallText>
      <SmallText style={{ width: '14%' }} bold margin='0 24px 0 0'>ส่วนลด / หน่วย</SmallText>
      <SmallText textAlign='right' style={{ flexGrow: 1 }} bold dmargin='0 24px 0 0'>ราคาทั้งหมด</SmallText>
    </Div>
    <Divider margin='8px 0 24px' />
    {
      !_.isEmpty(selectedList?.so)
      && <Div margin='0 0 8px'>
        <SmallText bold margin='0 0 16px'>สรุปสินค้าจากใบ SO</SmallText>
        {
          selectedList?.so?.map((selectedSo, i) => (
            <Div key={selectedSo?.orderId}>
              <SmallText bold>{selectedSo?.soNumber}</SmallText>
              <InputRow
                margin={i !== selectedList?.so.length - 1 ? '0 0 16px' : '0'}
                data={selectedSo}
                disabledInputQuantity
                onChange={(value, keyChange) => {
                  if (!_.isNil(value) && !_.isNil(keyChange)) {
                    setSelectedList({
                      ...selectedList,
                      so: selectedList?.so?.map((obj) => {
                        if (obj.orderId === selectedSo?.orderId) {
                          return (
                            { ...obj, [keyChange]: value }
                          )
                        }
                        return obj
                      }),
                    })
                  }
                }}
                onDelete={() => setSelectedList({ ...selectedList, so: _.filter([...selectedList?.so], (obj) => obj.orderId !== selectedSo?.orderId) })}
              />
            </Div>
          ))
        }
        {
          !_.isEmpty(selectedList?.item)
          && <Divider />
        }
      </Div>
    }
    {
      !_.isEmpty(selectedList?.item)
      && <Div>
        <SmallText bold>สรุปสินค้าอื่นๆที่เลือก</SmallText>
        {
          selectedList?.item.map((selectedOtherItem, i) => <InputRow
            margin={i !== selectedOtherItem.length - 1 ? '0 0 16px' : '0'}
            key={selectedOtherItem?.id}
            data={selectedOtherItem}
            onChange={(value, keyChange) => {
              if (!_.isNil(value) && !_.isNil(keyChange)) {
                setSelectedList({
                  ...selectedList,
                  item: selectedList?.item?.map((obj) => {
                    if (obj.id === selectedOtherItem?.id) {
                      return (
                        { ...obj, [keyChange]: value }
                      )
                    }
                    return obj
                  }),
                })
              }
            }}
            onDelete={() => setSelectedList({ ...selectedList, item: _.filter([...selectedList?.item], (obj) => obj.id !== selectedOtherItem?.id) })}
          />)
        }
      </Div>
    }
    <Divider margin='32px 0' />
    <Div display='flex'>
      <Div moreStyle={{ flexBasis: '40%' }} margin='0 40px 0 0'>
        <TextareaWithLabel
          style={{ width: '100%' }}
          title={{ text: 'หมายเหตุเพิ่มเติม', margin: '0 0 8px' }}
          textarea={{
            rows: 5,
            maxlength: 255,
            style: { width: '100%' },
            value: mutationVariables?.remark,
            onChange: (e) => setMutationVariables({ ...mutationVariables, remark: e.target.value }),
          }}
        />
        <Div margin='24px 0 0' width='100%' display='flex' justifyContent='space-between' alignItems='flex-start'>
          <SmallText bold>แนบเอกสาร</SmallText>
          <Uploader
            isMultiple
            sltye={{ border: '1px solid red' }}
            buttonText='คลิกเพื่อเลือกไฟล์'
            inverse={false}
            icon='fal fa-paperclip'
            onChange={(e) => setMutationVariables({ ...mutationVariables, fileList: e })}
            data={mutationVariables?.fileList || []}
          />
        </Div>
        {
          !_.isEmpty(mutationVariables?.existFileList)
          && <Div margin='18px 0 0'>
            <SmallText bold margin='0 0 8px'>เอกสารที่อัพโหลดแล้ว</SmallText>
            {
              mutationVariables?.existFileList.map((v, i) => (
                <Div key={v.id} display='flex' alignItems='flex-start' justifyContent='space-between' margin={i !== mutationVariables?.existFileList.length - 1 && '0 0 8px 0'}>
                  <TextLink href={v.url} target='_blank' style={{ width: '100%', maxWidth: '90%' }}>
                    <SmallText display='block' style={{ width: '100%', wordBreak: 'break-all' }}>{v.fileName}</SmallText>
                  </TextLink>
                  <Icon onClick={() => setMutationVariables({ ...mutationVariables, existFileList: _.filter(mutationVariables?.existFileList, (obj) => obj.id !== v.id) })} margin='4px 0 0' icon='fal fa-times' cursor='pointer' />
                </Div>
              ))
            }
          </Div>
        }
      </Div>
      <Div moreStyle={{ flexGrow: 1 }}>
        <TextBetween
          margin="0 0 8px"
          title={{ text: 'ยอดชำระ', bold: true }}
          content={{
            text: `${numeral(_.sum(_.keys(selectedList)?.map((keyName) => (
              _.reduce(selectedList[keyName], (sum, n) => sum + (parseFloat(n.quantity) * (parseFloat(n.buyPrice) - parseFloat(n.discount))), 0)
            )))).format('0,0.00')} บาท`,
            bold: true,
          }}
        />
      </Div>
    </Div>
  </Div>
)

export default StepWithFilter
