import React, { useState, useEffect } from 'react'
import _ from 'lodash'
import moment from 'moment-timezone'
import { Link } from 'react-router-dom'
import numeral from 'numeral'
import { useQuery, useMutation } from '@apollo/client'
import { message } from 'antd'
import {
  Div, SmallText, Input, Label, Button,
} from '@konsys-ui-custom'
import history from '../../../history'
import {
  Tab, MessagePage, RowSelect, Loading, Divider, SmallTextWithLabel,
  TextareaWithLabel, Uploader, Select, PaymentMethodFormInAccount, TableList,
} from '../../../components'
import MESSAGE from '../../../constants/message'
import { theme } from '../../../styles/_variables'
import {
  FINNIC_BANKS, CUSTOMERS, GET_RECEIPTABLE, GET_RECEIPTABLE_SO, GET_DEBT_FOR_OPEN_RECEIPT,
} from '../../../api/query'
import { CREATE_RECEIPT } from '../../../api/mutation'
import { RowWrapper } from '../debt/styled'
import { PAYMENT_TYPE, SO_STATUS_TEXT } from '../../sale-orders/constants'
import { INV_STATUS } from '../../../constants/status/invoice'
import { billingStatus } from '../billing/constants'
import { validateData } from '../../../utils/util-services'
import { DebtDisplay } from '../billing/form'
import { PAYMENT_TYPE as PAYMENTLIST } from '../../../constants/enum'

const Form = ({ type }) => {
  const [search, setSearch] = useState('')
  const [customerId, setCustomerId] = useState()
  const [customer, setCustomer] = useState({})
  const [name, setName] = useState()
  const [selectedLot, setSelectedLot] = useState([])
  const [selectedInvoice, setSelectedInvoice] = useState([])
  const [paymentType, setPaymentType] = useState(type)
  const [disabledButton, setDisabledButton] = useState(false)
  const [mutationVariables, setMutationVariables] = useState({
    paymentMethod: null,
    paidDate: null,
    paidTime: null,
    finnicBankId: null,
    files: [],
  })
  const { data, loading } = useQuery(GET_RECEIPTABLE, { variables: { search, customerId } })
  const { data: finnicBankData, loading: finnicBankLoading } = useQuery(FINNIC_BANKS)
  const { data: customerData, loading: customerLoading } = useQuery(CUSTOMERS, { variables: { name } })
  const [doCreate, { loading: creating }] = useMutation(CREATE_RECEIPT, { refetchQueries: ['remainingJob', 'remainingAccountant'] })
  const { data: receiptableSoData, loading: receiptableSoLoading } = useQuery(GET_RECEIPTABLE_SO, { variables: { search, customerId } })
  const { data: debtData, loading: debtLoading } = useQuery(GET_DEBT_FOR_OPEN_RECEIPT, { variables: { customerId } })
  const LotDisplay = (props) => (
    <RowWrapper>
      <RowSelect header align='flex-start' list={[
        {
          value: props.value,
          onChange: (e) => props.onChange && props.onChange(e),
          checked: props.checked,
          disabled: !props.credit && props.subtotalAfterDiscount - props.paidTotal > 0,
          data: !props.credit ? [
            {
              title: {
                text: `ล็อต ${props.data.lotIndex}`,
                style: {
                  width: '173px',
                  wordBreak: 'break-all',
                },
              },
              content: {
                text: `สินค้า ${props.data.totalItems} รายการ`,
                style: {
                  width: '173px',
                  wordBreak: 'break-all',
                },
              },
            },
            {
              title: {
                text: 'เลขการจัดส่ง',
              },
              content: {
                text: props.data.ptNumber || '-',
              },
            },
          ] : [
            {
              title: {
                text: 'วันที่สร้าง',
              },
              content: {
                text: `${moment(props.data.createdAt).format('DD/MM/YYYY')}`,
              },
            }, {
              title: {
                text: 'Invoice',
              },
              content: {
                text: `#${props.data.invoiceNumber}`,
              },
            }, {
              title: {
                text: 'SO ID',
              },
              content: {
                text: `#${props.data.soNumber}`,
              },
            }, {
              title: {
                text: 'ยอดชำระ',
              },
              content: {
                text: numeral(props.data.subtotal).format('0,0.00'),
              },
            }, {
              title: {
                text: 'วันที่',
              },
              content: {
                text: moment(props.data.createDate).format('DD/MM/YYYY'),
              },
            }, {
              title: {
                text: 'วันที่กำหนดชำระ',
              },
              content: {
                text: moment(props.data.paymentDueDate).format('DD/MM/YYYY'),
              },
            }, {
              title: {
                text: 'สถานะ',
              },
              content: {
                text: _.find(INV_STATUS, (o) => o.key === props.data.status).text,
              },
            },
          ],
        },
      ]} />
    </RowWrapper>
  )

  const RowDetail = (props) => {
    const list = props.credit ? props.data.invoices : props.data.lots
    return (
      <Div>
        <Div display='flex' justifyContent='space-between' padding='8px 16px' bgColor='RGBA(99, 167, 224, 0.35)'>
          <Div moreStyle={{ flexBasis: '14%', overflow: 'hidden', wordBreak: 'break-all' }}>
            <Label display='block' bold>{props.credit ? 'Bill' : 'SO ID'}</Label>
            <SmallText bold>#{props.credit ? props.data.billNumber : props.data.soNumber}</SmallText>
          </Div>
          <Div moreStyle={{ flexBasis: '13%', overflow: 'hidden', wordBreak: 'break-all' }}>
            <Label display='block' bold>{props.credit ? 'จำนวนใบแจ้งหนี้' : 'เลขใบรับมัดจำ'}</Label>
            <SmallText bold>{props.credit ? `${numeral(props.data?.invoicesAmount).format('0,0')} รายการ` : props.data?.depositReceipt?.number}</SmallText>
          </Div>
          <Div moreStyle={{ flexGrow: 1 }}>
            <Label display='block' bold>ชื่อลูกค้า</Label>
            <SmallText bold>{`${props.data?.customer?.prefix ? `${props.data?.customer?.prefix} ` : ''}${props.data?.customer?.name}`}</SmallText>
          </Div>
          {
            !props.credit
            && <Div moreStyle={{ flexBasis: '18%', overflow: 'hidden', wordBreak: 'break-all' }} display='flex' alignItems='center'>
              <Div moreStyle={{ flexGrow: 1 }}>
                <Label display='block' bold>ยอดชำระ</Label>
                <SmallText bold>{numeral(props.data.subtotalAfterDiscount).format('0,0.00')}</SmallText>
              </Div>
              <Div moreStyle={{ flexGrow: 1 }}>
                <Label display='block' bold>ยอดค้างชำระ</Label>
                <SmallText bold color={props.data.subtotalAfterDiscount - props.data.paidTotal > 0 && theme.color.error}>{numeral(props.data.subtotalAfterDiscount - props.data.paidTotal).format('0,0.00')}</SmallText>
              </Div>
            </Div>
          }
          <Div moreStyle={{ flexBasis: '20%', overflow: 'hidden', wordBreak: 'break-all' }}>
            <Label display='block' bold>สถานะ</Label>
            <SmallText bold color={props.data.status ? props.data.status.text : theme.color.gray80}>{props.data.status && props.data.status.text}</SmallText>
          </Div>
        </Div>
        {
          list.map((v, i) => (
            <LotDisplay
              credit={props.credit}
              subtotalAfterDiscount={props.data.subtotalAfterDiscount}
              paidTotal={props.data.paidTotal}
              key={i}
              data={v}
              value={v.id}
              checked={_.includes(props.selectedLot, v.id)}
              onChange={(e) => props.onChangeLot(e.target.checked, v.id)}
            />
          ))
        }
      </Div>
    )
  }

  const billList = (loading
    || _.isEmpty(data)
    || _.isEmpty(data.getReceiptableBillingNote)
    || !data.getReceiptableBillingNote.success
    || _.isEmpty(data.getReceiptableBillingNote.data)) ? [] : data.getReceiptableBillingNote.data.map((v) => (
      {
        id: v.id,
        billNumber: v.number,
        invoicesAmount: _.size(v.invoices),
        status: {
          text: billingStatus[v.status].text,
          color: billingStatus[v.status].color,
        },
        customer: v.customer,
        createDate: v.createDate,
        paymentDueDate: v.paymentDueDate,
        invoices: _.isEmpty(v.invoices) ? [] : v.invoices.map((invoice) => (
          {
            id: invoice.id,
            createDate: invoice.createDate,
            paymentDueDate: invoice.paymentDueDate,
            status: invoice.status,
            soNumber: invoice.lot.salesOrder.soNumber,
            contactAddress: invoice.lot.salesOrder.contactAddress,
            type: invoice.lot.salesOrder.type,
            ptNumber: invoice.lot.pickingTicket.number,
            invoiceNumber: invoice.number,
            subtotal: invoice.subtotal,
            subtotalAfterDiscount: invoice.subtotalAfterDiscount || 0,
          }
        )),
      }
    ))

  const soList = (_.isEmpty(receiptableSoData) || receiptableSoLoading) ? [] : receiptableSoData.getReceiptableSalesOrder.data.map((v) => (
    {
      ...v,
      status: {
        text: SO_STATUS_TEXT[v.status],
        color: 'pink',
      },
      lots: v.lots.map((o) => (
        {
          id: o.id,
          lotIndex: o.lotIndex,
          totalItems: o.items.length,
          ptNumber: o.pickingTicket && o.pickingTicket.number,
          subtotal: o.subtotal,
          subtotalAfterDiscount: o.subtotalAfterDiscount,
          discountAmount: o.discountAmount,
          subtotalWithDelivery: o.subtotalWithDelivery,
          deliveryCost: o.deliveryCost,
        }
      )),
    }
  ))

  const validateCreateData = () => {
    const list = [mutationVariables.paidDate ? moment.tz(mutationVariables.paidDate, 'Asia/Bangkok').format('YYYY-MM-DD') : null, mutationVariables.paymentMethod]
    if (mutationVariables.paymentMethod === 'TRANSFER' || mutationVariables.paymentMethod === 'CHEQUE') {
      list.push(mutationVariables.finnicBankId)
    }
    if (mutationVariables.paymentMethod === 'TRANSFER') {
      list.push(mutationVariables.paidTime)
    }
    if (mutationVariables.paymentMethod === 'CHEQUE') {
      list.push(mutationVariables.chequeDate)
      list.push(mutationVariables.chequeNumber)
    }
    if (paymentType === PAYMENT_TYPE.CREDIT) {
      return _.flatten([list, !_.isEmpty(customer) ? customer.id : null, _.reduce(selectedLot, (arr, n) => [...arr, n.id], []), _.reduce(selectedInvoice, (arr, n) => [...arr, n.id], [])])
    }
    return _.flatten([list, !_.isEmpty(customer) ? customer.id : null, _.reduce(selectedLot, (arr, n) => [...arr, n.id], [])])
  }

  const filteredBillList = paymentType === PAYMENT_TYPE.CREDIT ? _.isEmpty(selectedLot) ? billList : _.filter(billList, (o) => o.customer.id === customer.id) : []
  const filteredSOList = paymentType === PAYMENT_TYPE.CASH ? _.isEmpty(selectedLot) ? soList : _.filter(soList, (o) => o.customer.id === customer.id && _.includes(o.lots.map((lot) => lot.id), selectedLot[0].id)) : []
  const finnicBankList = (finnicBankLoading || _.isEmpty(finnicBankData)) ? [] : finnicBankData.finnicBankList.data.map((v) => (
    { text: `${v.bank?.name ? v.bank.name : '-'} ${v.bankAccountNumber ? `(${v.bankAccountNumber})` : '(-)'}`, value: v.id, number: v.bankAccountNumber }
  ))
  let count = 0
  const sumTotal = _.sum(selectedLot.map((v) => v.subtotal)) + _.sum(selectedInvoice.map((v) => {
    const total = v.totalPriceBefore - v.totalPriceAfter
    if (v.type === 'CN') {
      return parseFloat(`-${total}`)
    }
    return Math.abs(total)
  }))

  const itemListInSelectedLot = !_.isEmpty(selectedLot) ? _.find(receiptableSoData?.getReceiptableSalesOrder.data, (v) => _.find(v.lots, (o) => o.id === selectedLot[0].id))?.lots[0].items : []

  useEffect(() => {
    if (_.isEmpty(mutationVariables?.paidDate)) setMutationVariables({ ...mutationVariables, paidDate: moment() })
  }, [selectedLot, selectedInvoice])

  return (
    <Div>
      <Tab white title={[{
        text: 'สร้างใบเสร็จ',
        icon: 'far fa-plus',
      }]}>
        <Div margin='0 0 24px'>
          <Div>
            <SmallText bold display='block' margin='0 0 8px'>รายการใบแจ้งหนี้ที่ยังไม่สร้างใบเสร็จ</SmallText>
            <Div margin='0 0 8px 0' display='flex' justifyContent='space-between'>
              <Select
                value={type === 'CASH' ? 'โอนเงิน' : 'ชำระเงินปลายทาง'}
                disabled={true}
                onSelect={(e) => {
                  setPaymentType(e)
                  setSelectedLot([])
                }}
                style={{ width: '20%', margin: '0 16px 0 0' }}
                options={[{ value: PAYMENT_TYPE.COD, text: 'ชำระเงินปลายทาง' }, { value: PAYMENT_TYPE.CASH, text: 'โอนเงิน' }]}
              />
              <Select
                loading={customerLoading}
                placeholder='เลือกลูกค้า'
                onSelect={(e) => {
                  setCustomerId(e)
                  setCustomer(_.find(customerData.customerList.data.list, (v) => v.id === e))
                  setSelectedLot([])
                }}
                onSearch={(e) => setName(e)}
                showSearch={true}
                notFoundContent={null}
                showArrow={false}
                filterOption={false}
                style={{ width: '20%', margin: '0 16px 0 0' }}
                value={_.isEmpty(customer) ? undefined : customer.id}
                options={customerLoading ? [] : customerData.customerList.data.list.map((c) => ({ text: c.name, value: c.id }))}
              />
              <Input
                width='60%'
                placeholder='ค้นหาเลข SO, Bill Invoice, PT'
                value={search}
                onChange={(e) => {
                  setSearch(e.target.value)
                }}
              />
            </Div>
          </Div>
          <Div>
            {
              loading || receiptableSoLoading
                ? <Div margin='16px 0' textAlign='center'>
                  <Loading size='extraSmall' />
                </Div>
                : paymentType === PAYMENT_TYPE.CREDIT
                  ? _.isEmpty(billList)
                    ? <MessagePage
                      margin='16px 0'
                      icon={{
                        icon: 'fal fa-times', fontSize: theme.fonts.size.title, color: theme.color.lightBlue, margin: '0 0 16px',
                      }}
                      text={MESSAGE.empty.list}
                      color={theme.color.lightBlue}
                      bold
                    />
                    : filteredBillList.map((v) => <RowDetail
                      credit={true}
                      key={v.id}
                      data={v}
                      selectedLot={selectedLot.map((lot) => lot.id)}
                      onChangeLot={(isCheck, value) => {
                        if (isCheck) {
                          const invoiceData = _.find(v.invoices, (o) => o.id === value)
                          setSelectedLot([...selectedLot, { ...invoiceData, billNumber: v.billNumber }])
                        } else {
                          const newSelectedLot = _.remove(_.cloneDeep(selectedLot), (o) => o.id !== value)
                          setSelectedLot(newSelectedLot)
                          if (_.isEmpty(selectedInvoice)) {
                            setCustomer()
                            setCustomerId()
                          }
                        }
                        if (_.isEmpty(selectedLot)) {
                          setCustomer(v.customer)
                          setCustomerId(v.customer.id)
                        }
                      }}
                    />)
                  : _.isEmpty(soList)
                    ? <MessagePage
                      margin='16px 0'
                      icon={{
                        icon: 'fal fa-times', fontSize: theme.fonts.size.title, color: theme.color.lightBlue, margin: '0 0 16px',
                      }}
                      text={MESSAGE.empty.list}
                      color={theme.color.lightBlue}
                      bold
                    />
                    : filteredSOList.map((v) => <RowDetail
                      credit={false}
                      key={v.id}
                      data={v}
                      selectedLot={selectedLot.map((lot) => lot.id)}
                      onChangeLot={(isCheck, value) => {
                        const newList = [
                          {
                            ...v,
                            id: value,
                            lots: [_.find(v.lots, (lot) => lot.id === value)],
                            subtotal: _.find(v.lots, (lot) => lot.id === value)?.subtotal,
                            subtotalAfterDiscount: _.find(v.lots, (lot) => lot.id === value)?.subtotalAfterDiscount,
                            discountAmount: _.find(v.lots, (lot) => lot.id === value)?.discountAmount,
                            subtotalWithDelivery: _.find(v.lots, (lot) => lot.id === value)?.subtotalWithDelivery,
                            deliveryCost: _.find(v.lots, (lot) => lot.id === value)?.deliveryCost,
                          },
                        ]
                        if (isCheck) {
                          setSelectedLot(newList)
                          if (v.subtotalAfterDiscount === v.depositAmount && v.subtotalAfterDiscount === v.paymentHistories[0]?.subtotal) {
                            const {
                              chequeDate, chequeNumber, creditCard, finnicBankId, date, time, reference, referenceNO,
                            } = v.paymentHistories[0]
                            setMutationVariables({
                              ...mutationVariables,
                              chequeDate,
                              chequeNumber,
                              creditCard,
                              finnicBankId,
                              paidDate: date,
                              paidTime: time,
                              paymentMethod: v.paymentHistories[0].type,
                              reference,
                              referenceNO,
                            })
                          }
                        } else {
                          setSelectedLot(_.remove(_.cloneDeep(selectedLot), (o) => o.id !== value))
                        }
                        setCustomer(v.customer)
                        setCustomerId(v.customer?.id)
                      }}
                    />)
            }
            {
              paymentType === PAYMENT_TYPE.CREDIT
              && <Div margin='24px 0 0'>
                <SmallText bold display='block' margin='0 0 8px'>รายการใบแจ้งหนี้ที่ยังไม่สร้างใบวางบิล</SmallText>
                <Div height='285px' moreStyle={{ overflow: 'auto' }}>
                  {
                    debtLoading
                      ? <Div margin='16px 0' textAlign='center'>
                        <Loading size='extraSmall' />
                      </Div>
                      : _.isEmpty(debtData.getReceiptableCreditNoteAndDebitNote?.data)
                        ? <MessagePage
                          margin='16px 0'
                          icon={{
                            icon: 'fal fa-times', fontSize: theme.fonts.size.title, color: theme.color.lightBlue, margin: '0 0 16px',
                          }}
                          text={MESSAGE.empty.list}
                          color={theme.color.lightBlue}
                          bold
                        />
                        : debtData.getReceiptableCreditNoteAndDebitNote?.data.map((v, i) => (
                          <DebtDisplay
                            key={i}
                            data={v}
                            checked={!_.isEmpty(_.find(selectedInvoice, (o) => o.id === v.id))}
                            onChange={(isChecked, id) => {
                              const newList = [...selectedInvoice]
                              if (isChecked) {
                                newList.push({
                                  ...v,
                                })
                                setCustomer(v.customer)
                                setCustomerId(v.customer?.id)
                              } else {
                                _.remove(newList, (o) => o.id === id)
                              }
                              setSelectedInvoice(newList)
                              if (_.isEmpty(newList) && _.isEmpty(selectedLot)) {
                                setCustomer()
                                setCustomerId()
                              }
                            }}
                          />
                        ))
                  }
                </Div>
              </Div>
            }
          </Div>
          {
            (!_.isEmpty(selectedLot) || !_.isEmpty(selectedInvoice))
            && <Div>
              <Divider margin='0 0 24px' />
              <SmallText bold margin='0 0 16px'>สรุปรายละเอียด</SmallText>
              <Div margin='0 0 0 48px' display='flex' alignItems='flex-start'>
                <SmallTextWithLabel margin='0 32px 0 0' title={{ text: 'ชื่อลูกค้า' }} content={{ text: customer?.name || '-' }} />
                <SmallTextWithLabel margin='0 32px 0 0' title={{ text: 'ประเภทการชำระเงิน' }} content={{ text: !_.isEmpty(selectedLot) ? _.find(PAYMENTLIST, (o) => o.value === selectedLot[0].type)?.text : '-' }} />
                <SmallTextWithLabel margin='0 32px 0 0' title={{ text: 'ชื่อผู้ติดต่อ' }} content={{ text: customer?.contactName || '-' }} />
                <SmallTextWithLabel margin='0 32px 0 0' title={{ text: 'เบอร์ผู้ติดต่อ' }} content={{ text: customer?.contactPhoneNumber?.phoneNumber || '-' }} />
                <SmallTextWithLabel margin='0 32px 0 0' title={{ text: 'เบอร์สำนักงาน' }} content={{ text: customer?.contactOfficeTel || '-' }} />
              </Div>
              <PaymentMethodFormInAccount
                paymentDate={{
                  value: mutationVariables.paidDate,
                  onChange: (value) => setMutationVariables({ ...mutationVariables, paidDate: value }),
                }}
                paymentTime={{
                  value: mutationVariables.paidTime,
                  onChange: (value) => setMutationVariables({ ...mutationVariables, paidTime: value }),
                }}
                reference={{
                  value: mutationVariables.reference,
                  onChange: (value) => setMutationVariables({ ...mutationVariables, reference: value }),
                }}
                paymentMethod={{
                  value: mutationVariables.paymentMethod,
                  onChange: (value) => setMutationVariables({ ...mutationVariables, paymentMethod: value }),
                }}
                account={{
                  list: finnicBankList,
                  value: mutationVariables.finnicBankId,
                  onChange: (value) => setMutationVariables({ ...mutationVariables, finnicBankId: value }),
                }}
                cheque={{
                  date: {
                    value: mutationVariables.chequeDate,
                    onChange: (value) => setMutationVariables({ ...mutationVariables, chequeDate: value }),
                  },
                  number: {
                    value: mutationVariables.chequeNumber,
                    onChange: (value) => setMutationVariables({ ...mutationVariables, chequeNumber: value }),
                  },
                }}
                creditCard={{
                  number: {
                    value: mutationVariables.creditCard,
                    onChange: (value) => setMutationVariables({ ...mutationVariables, creditCard: value }),
                  },
                  ref: {
                    value: mutationVariables.referenceNO,
                    onChange: (value) => setMutationVariables({ ...mutationVariables, referenceNO: value }),
                  },
                }}
              />
              {
                (!_.isEmpty(selectedLot) || !_.isEmpty(selectedInvoice))
                && <Div margin='32px 0'>
                  {
                    paymentType !== PAYMENT_TYPE.CREDIT
                      ? <TableList
                        data={itemListInSelectedLot.map((v) => (
                          [
                            { text: v.variantName || '-' },
                            { text: v.quantity },
                            { text: numeral(v.salesPrice).format('0,0.00') },
                            { text: numeral(v.discount).format('0,0.00') },
                            { text: numeral((v.salesPrice - v.discount) * v.quantity).format('0,0.00'), textAlign: 'right' },
                          ]
                        ))}
                        headerData={[
                          { text: 'ชื่อสินค้า' },
                          { text: 'จำนวน' },
                          { text: 'ราคา/หน่วย' },
                          { text: 'ส่วนลด/หน่วย' },
                          { text: 'ราคารวม', textAlign: 'right' },
                        ]}
                        columnStyled={{
                          0: { fontSize: theme.fonts.size.small },
                          1: { width: '80px', fontSize: theme.fonts.size.small },
                          2: { width: '100px', fontSize: theme.fonts.size.small },
                          3: { width: '100px', fontSize: theme.fonts.size.small },
                          4: { width: '130px', fontSize: theme.fonts.size.small },
                        }}
                      />
                      : <Div>
                        <Div display='flex' padding='0 0 8px' margin='0 0 8px' moreStyle={{ borderBottom: `1px solid ${theme.color.primaryColor10}` }}>
                          {paymentType === PAYMENT_TYPE.CREDIT && <Label margin='0 16px 0 40px' bold style={{ flexBasis: '12%' }}>Invoice</Label>}
                          <Label margin={paymentType === PAYMENT_TYPE.CREDIT ? '0 16px 0 0px' : '0 16px 0 40px'} bold style={{ flexBasis: '12%' }}>วันที่</Label>
                          <Label margin='0 16px 0 0' bold style={{ flexBasis: '12%' }}>วันที่กำหนดชำระ</Label>
                          {paymentType === PAYMENT_TYPE.CREDIT && <Label margin='0 16px 0 0' bold style={{ flexBasis: '12%' }}>เลขที่ Bill</Label>}
                          <Label margin='0 16px 0 0' bold style={{ flexBasis: '12%' }}>เลขที่ SO</Label>
                          <Label margin='0 16px 0 0' bold style={{ flexBasis: '12%' }}>เลขที่ PT</Label>
                          <Label bold textAlign='right' style={{ flexBasis: '16%' }}>ราคารวม</Label>
                        </Div>
                        {
                          selectedLot.map((v) => {
                            count += 1
                            return (
                              <Div key={v.id} display='flex'>
                                <SmallText margin='0 16px 0 0' style={{ width: '24px' }}>{count}.</SmallText>
                                {paymentType === PAYMENT_TYPE.CREDIT && <SmallText margin='0 16px 0 0' style={{ flexBasis: '12%' }}>#{v.invoiceNumber || '-'}</SmallText>}
                                <SmallText margin='0 16px 0 0' style={{ flexBasis: '12%' }}>{moment.tz(v.createDate, 'Asia/Bangkok').format('DD/MM/YYYY') || '-'}</SmallText>
                                <SmallText margin='0 16px 0 0' style={{ flexBasis: '12%' }}>{moment.tz(v.paymentDueDate, 'Asia/Bangkok').format('DD/MM/YYYY') || '-'}</SmallText>
                                {paymentType === PAYMENT_TYPE.CREDIT && <SmallText margin='0 16px 0 0' style={{ flexBasis: '12%' }}>{!_.isEmpty(v.billNumber) ? `#${v.billNumber}` : '-'}</SmallText>}
                                <SmallText margin='0 16px 0 0' style={{ flexBasis: '12%' }}>{v.soNumber || '-'}</SmallText>
                                <SmallText margin='0 16px 0 0' style={{ flexBasis: '12%' }}>{type === 'CASH' ? v.lots[0].ptNumber || '-' : v.ptNumber || '-'}</SmallText>
                                <SmallText textAlign='right' style={{ flexBasis: '16%' }}>{numeral(v.subtotal).format('0,0.00')}</SmallText>
                              </Div>
                            )
                          })
                        }
                        {
                          !_.isEmpty(selectedInvoice)
                          && selectedInvoice.map((v) => {
                            count += 1
                            return (
                              <Div key={v.id} display='flex'>
                                <SmallText margin='0 16px 0 0' style={{ width: '24px' }}>{count}.</SmallText>
                                {paymentType === PAYMENT_TYPE.CREDIT && <SmallText margin='0 16px 0 0' color={v.type === 'DN' ? theme.color.success : theme.color.error} style={{ flexBasis: '12%' }}>#{v.number || '-'}</SmallText>}
                                <SmallText margin='0 16px 0 0' style={{ flexBasis: '12%' }}>{moment.tz(v.createDate, 'Asia/Bangkok').format('DD/MM/YYYY') || '-'}</SmallText>
                                <SmallText margin='0 16px 0 0' style={{ flexBasis: '12%' }}>-</SmallText>
                                {paymentType === PAYMENT_TYPE.CREDIT && <SmallText margin='0 16px 0 0' style={{ flexBasis: '12%' }}>#{v.billingNote.number}</SmallText>}
                                <SmallText margin='0 16px 0 0' style={{ flexBasis: '12%' }}>-</SmallText>
                                <SmallText margin='0 16px 0 0' style={{ flexBasis: '12%' }}>-</SmallText>
                                <SmallText textAlign='right' style={{ flexBasis: '16%' }}>{numeral(v.totalPriceAfter - v.totalPriceBefore).format('0,0.00')}</SmallText>
                              </Div>
                            )
                          })
                        }
                      </Div>
                  }
                  <Divider margin='16px 0' color={theme.color.gray80} />
                  <Div display='flex' alignItems='center' justifyContent='space-between'>
                    <SmallText bold>รวมเงิน</SmallText>
                    {
                      paymentType === PAYMENT_TYPE.CREDIT
                        ? <SmallText bold>{numeral(sumTotal).format('0,0.00')} บาท</SmallText>
                        : <SmallText bold>{numeral(_.reduce(selectedLot, (sum, n) => sum + n.subtotal, 0)).format('0,0.00')} บาท</SmallText>
                    }
                  </Div>
                  {
                    paymentType === PAYMENT_TYPE.CASH
                    && <Div>
                      <Div display='flex' alignItems='center' justifyContent='space-between'>
                        <SmallText bold>ค่าขนส่ง</SmallText>
                        <SmallText bold>{numeral(_.reduce(selectedLot, (sum, n) => sum + n.deliveryCost, 0)).format('0,0.00')} บาท</SmallText>
                      </Div>
                      <Div display='flex' alignItems='center' justifyContent='space-between'>
                        <SmallText bold>ส่วนลด</SmallText>
                        <SmallText bold>{numeral(_.reduce(selectedLot, (sum, n) => sum + n.discountAmount, 0)).format('0,0.00')} บาท</SmallText>
                      </Div>
                      <Div display='flex' alignItems='center' justifyContent='space-between'>
                        <SmallText bold>เงินหลังหักส่วนลด</SmallText>
                        <SmallText bold>{numeral(_.reduce(selectedLot, (sum, n) => sum + n.subtotalWithDelivery - n.discountAmount, 0)).format('0,0.00')} บาท</SmallText>
                      </Div>
                      <Div display='flex' alignItems='center' justifyContent='space-between'>
                        <SmallText bold>ยอดชำระ</SmallText>
                        <SmallText bold>{numeral(_.reduce(selectedLot, (sum, n) => sum + n.subtotalAfterDiscount, 0)).format('0,0.00')} บาท</SmallText>
                      </Div>
                      <Div margin='8px 0 0' display='flex' alignItems='center' justifyContent='space-between'>
                        <SmallText bold>ค่าธรรมเนียมธนาคาร</SmallText>
                        <Div display='flex' alignItems='center'>
                          <Input
                            value={mutationVariables.fee}
                            state='default'
                            rule={{ type: 'float' }}
                            margin='0 8px 0 0'
                            onChange={(e) => setMutationVariables({ ...mutationVariables, fee: parseFloat(e.target.value) })}
                          />
                          <SmallText bold>บาท</SmallText>
                        </Div>
                      </Div>
                    </Div>
                  }
                </Div>
              }
              <TextareaWithLabel
                width='100%'
                margin='24px 0 0'
                title={{ text: 'หมายเหตุ', margin: '0 0 8px' }}
                textarea={{
                  style: { width: '100%' },
                  value: mutationVariables.remark,
                  onChange: (e) => setMutationVariables({ ...mutationVariables, remark: e.target.value }),
                }}
              />
              <Div margin='24px 0 0' width='100%' display='flex' justifyContent='space-between' alignItems='flex-start'>
                <SmallText bold>แนบเอกสาร</SmallText>
                <Uploader
                  isMultiple
                  buttonText='คลิกเพื่อเลือกไฟล์'
                  inverse={false}
                  icon='fal fa-paperclip'
                  onChange={(e) => setMutationVariables({ ...mutationVariables, files: e })}
                  data={mutationVariables.files}
                />
              </Div>
              <Div margin='40px 0' textAlign='center'>
                <Link to='' key={1}>
                  <Button
                    margin='0 4px'
                    color={theme.color.error}
                    text='ยกเลิก'
                    icon='fal fa-times'
                  />
                </Link>
                <Button
                  margin='0 4px'
                  inverse
                  color={theme.color.success}
                  text='สร้างใบเสร็จ'
                  icon='fal fa-check'
                  loading={creating}
                  disabled={disabledButton || validateData(
                    true,
                    validateCreateData(),
                    [],
                  ) || (paymentType === PAYMENT_TYPE.CREDIT && sumTotal <= 0)}
                  onClick={() => {
                    const vars = {
                      ...mutationVariables,
                      paymentType,
                      paidDate: mutationVariables.paidDate ? moment.tz(mutationVariables.paidDate, 'Asia/Bangkok').format('YYYY-MM-DD') : null,
                      chequeDate: mutationVariables.chequeDate ? moment.tz(mutationVariables.chequeDate, 'Asia/Bangkok').format('YYYY-MM-DD') : null,
                      customerId: customer && customer.id,
                    }
                    const variables = paymentType === PAYMENT_TYPE.CREDIT
                      ? { ...vars, list: _.concat(_.reduce(selectedLot, (arr, n) => [...arr, { id: n.id, type: 'INVOICE' }], []), _.reduce(selectedInvoice, (arr, n) => [...arr, { id: n.id, type: 'DEBT' }], [])) }
                      : { ...vars, lotList: _.reduce(selectedLot, (arr, n) => [...arr, { lotId: n.id }], []) }
                    setDisabledButton(true)
                    doCreate({ variables }).then((resp) => {
                      setDisabledButton(false)
                      if (resp.data.createReceipt.success) {
                        message.success(resp.data.createReceipt.message)
                        history.push(paymentType === PAYMENT_TYPE.CREDIT ? '/account/receipt-credit' : '/account/receipt-cash')
                      } else {
                        message.error(resp.data.createReceipt.message)
                      }
                    })
                  }}
                />
              </Div>
            </Div>
          }
        </Div>
      </Tab>
    </Div>
  )
}

export default ({ type }) => (<Form type={type} />)
