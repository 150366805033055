import { useMutation, useQuery } from '@apollo/client'
import {
  Button, Div,
  H4, Icon, Input, P, RadioGroup, SmallText,
} from '@konsys-ui-custom'
import { Col, Modal, Row, message } from 'antd'
import _, { compact, filter, find, first, sumBy } from 'lodash'
import moment from 'moment-timezone'
import numeral from 'numeral'
import React, { useContext, useEffect, useState } from 'react'
import {
  GENERATE_QR,
} from '../../api/mutation'
import {
  EXPENSE,
  SALE_ORDER,
} from '../../api/query'
import {
  Collapse, Divider, LargeTextWithLabel, Loading, PaymentEvidenceList, Remark,
  TableList,
} from '../../components'
import {
  SO_TYPE,
  TAX_INVOICE_TYPE,
} from '../../constants/enum'
import { AuthorizationContext } from '../../store/StoreProvider'
import { theme } from '../../styles/_variables'
import { roundNumber } from '../../utils'
import {
  renderText,
} from '../../utils/util-services'
import {
  DELIVERY_TYPE, ORDER_TYPE, PAYMENT_TYPE, SO_STATUS,
} from './constants'
import { DownLoadLink } from './styled'
import { soStatusMaptoColor, soStatusMaptoText } from './utils'

export const renderDeliveryType = (value) => {
  switch (value) {
    case DELIVERY_TYPE.DELIVERY:
      return 'จัดส่ง'
    case DELIVERY_TYPE.PICKUP:
      return 'รับเอง'
    case DELIVERY_TYPE.PICKUPSUPPLIER:
      return 'รับเองจากผู้ผลิต'
    default:
      return 'ไม่ระบุ'
  }
}

export const tableStyled = {
  0: {
    width: '20%',
  },
  1: {
    width: '5%',
    margin: '0 8px 0 0',
  },
  2: {
    width: '10%',
  },
  3: {
    width: '10%',
  },
  4: {
    width: '10%',
  },
  5: {
    width: '10%',
  },
  6: {
    width: '10%',
  },
  7: {
    width: '10%',
  },
  8: {
    width: '10%',
  },
  9: {
    width: '5%',
  },
}

export const headerData = [
  {
    text: 'สินค้า',
  },
  {
    text: 'จำนวน',
    textAlign: 'right',
  },
  {
    text: 'ราคา / หน่วย',
    textAlign: 'right',
  },
  {
    text: 'ต้นทุนรวม',
    textAlign: 'right',
  },
  {
    text: 'กำไรรวม',
    textAlign: 'right',
  },
  {
    text: 'ค่าคอมฯรวม',
    textAlign: 'right',
  },
  {
    text: 'ราคารวม',
    textAlign: 'right',
  },
  {
    text: 'Seller',
    textAlign: 'center',
  },
  {
    text: 'ส่วนลด / หน่วย',
    textAlign: 'right',
  },
  {
    text: 'แถม',
    textAlign: 'center',
  },
]

const LotDetail = ({
  lot,
  authorization,
  isTypeCash,
}) => {
  const creditData = {}
  const itemChanges = _.filter(creditData?.itemChanges, (o) => _.isEmpty(o?.remarks))

  return (
    <Div
      display="flex"
      flexDirection="column"
      padding="16px"
      margin="16px 0"
      style={{ border: `1px solid ${theme.color.lightBlue}`, borderRadius: 5 }}
    >
      <Div display="flex" justifyContent="flex-end" alignItems="flex-start">
      </Div>
      <Div>
        <LargeTextWithLabel
          margin="0 16px 0 0"
          title={{ text: 'วันที่จัดส่ง' }}
          content={{
            text: lot.deliveryDate
              ? moment.tz(lot.deliveryDate, 'Asia/Bangkok').format('DD/MM/YYYY')
              : 'ไม่ระบุ',
          }}
        />
        <LargeTextWithLabel
          margin="0 16px 0 0"
          title={{ text: 'ประเภทการจัดส่ง' }}
          content={{
            text: lot.deliveryType
              ? renderDeliveryType(lot.deliveryType)
              : 'ไม่ระบุ',
          }}
        />
        <LargeTextWithLabel
          margin="0 16px 0 0"
          title={{ text: 'การจัดส่ง' }}
          content={{ text: lot?.pickingTicket?.number || '-' }}
        />
        <LargeTextWithLabel
          margin="0 16px 0 0"
          title={{ text: 'ใบกำกับภาษี' }}
          content={{
            text:
              (isTypeCash ? lot?.receipt?.number : lot?.invoice?.number) || '-',
          }}
        />
      </Div>
      {!_.isEmpty(lot.deliveryDetails) && (
        <Div margin="16px 0">
          <SmallText bold margin="0 0 6px">
            Tracking Number ทั้งหมด
          </SmallText>
          <Div>
            {lot.deliveryDetails?.map((o, i) => (
              <Div display="flex" key={i} margin={i !== 0 ? '8px 0 0' : '0'}>
                <Div width="20px" margin="0 6px 0 0">
                  <SmallText bold>{i + 1}.</SmallText>
                </Div>
                <LargeTextWithLabel
                  style={{ width: '25%' }}
                  title={{ text: 'บริษัทขนส่ง' }}
                  content={{ text: o.name || '-' }}
                />
                <LargeTextWithLabel
                  margin="0 0 0 24px"
                  title={{ text: 'Tracking Number' }}
                  content={{ text: o.trackingNumber || '-' }}
                />
              </Div>
            ))}
          </Div>
        </Div>
      )}
      {lot.billingAddress && (
        <Div display="flex" margin="8px 0">
          {/* <LargeTextWithLabel
            style={{ width: '45%' }}
            title={{ text: 'ประเภทใบกำกับภาษี' }}
            content={{
              text:
                _.find(
                  TAX_INVOICE_TYPE,
                  (v) => v?.value === lot?.taxInvoiceType,
                )?.text || '-',
            }}
          /> */}
          <LargeTextWithLabel
            title={{ text: 'ที่อยู่ออกใบกำกับภาษี' }}
            content={{
              text: `${renderText(
                lot.billingAddress.contactName,
                true,
              )} ${renderText(lot.billingAddress.taxId, true)} ${renderText(
                lot.billingAddress.addressNo,
                true,
              )} ${renderText(
                lot.billingAddress.subDistrict,
                true,
              )} ${renderText(lot.billingAddress.district, true)} ${renderText(
                lot.billingAddress.province,
                true,
              )} ${renderText(lot.billingAddress.postcode, true)} ${renderText(
                lot.billingAddress.contactPhoneNumber,
                true,
              )}`,
            }}
          />
        </Div>
      )}
      {lot.deliveryType === DELIVERY_TYPE.DELIVERY && lot.deliveryAddress && (
        <LargeTextWithLabel
          margin="8px 0"
          title={{ text: 'ที่อยู่จัดส่ง' }}
          content={{
            text: `${renderText(
              lot.deliveryAddress.contactName,
              true,
            )} (${renderText(lot.deliveryAddress.thirdPartyAddress, true)}${renderText(lot.deliveryAddress.addressNo, true)} ${renderText(
              lot.deliveryAddress.subDistrict,
              true,
            )} ${renderText(lot.deliveryAddress.district, true)} ${renderText(
              lot.deliveryAddress.province,
              true,
            )} ${renderText(lot.deliveryAddress.postcode, true)} เบอร์โทรศัพท์ ${renderText(
              lot.deliveryAddress.contactPhoneNumber,
              true,
            )})`,
          }}
        />
      )}
      <Divider margin="16px 56px" color={theme.color.lightBlue} />
      <TableList
        data={lot.items.map((v) => compact([
          {
            text:
              v.type === ORDER_TYPE.PACK
                ? v.pack?.variantName
                : v.productSet?.name,
            requireTaxInvoice: v.requireTaxInvoice,
          },
          {
            text: numeral(v.quantity).format('0,0.00'),
            textAlign: 'right',
          },
          {
            text: numeral(v.salesPrice).format('0,0.00'),
            textAlign: 'right',
          },
          authorization.showCost && {
            text: numeral(
              ((v.avgCost || (v.productSet || v.pack)?.inventoryOption?.avgCost) * v.quantity),
            ).format('0,0.00'),
            textAlign: 'right',
          },
          authorization.showCost && {
            text: numeral(
              roundNumber(((v.salesPrice
                - (v.avgCost || (v.productSet || v.pack)?.inventoryOption?.avgCost))
                * v.quantity) - (((v.salesPrice - (v?.discount || 0)) * v.commission * 0.01) * v.quantity) - (v.discount * v.quantity)),
            ).format('0,0.00'),
            textAlign: 'right',
          },
          authorization.showCost && {
            text: numeral(((v.salesPrice - (v?.discount || 0)) * v.commission * 0.01) * v.quantity).format('0,0.00'),
            textAlign: 'right',
          },
          {
            text: numeral((v.salesPrice - v.discount) * v.quantity).format(
              '0,0.00',
            ),
            textAlign: 'right',
          },
          {
            text: `${v.seller?.firstName} ${v.seller?.lastName}`,
            textAlign: 'right',
          },
          {
            text: numeral(v.discount).format('0,0.00'),
            textAlign: 'right',
          },
          {
            text: v.isFree ? <Icon icon="far fa-check" /> : null,
            textAlign: 'center',
          },
        ]))}
        headerData={authorization.showCost ? headerData : filter(headerData, (o) => o.text !== 'กำไรรวม' && o.text !== 'ต้นทุนรวม' && o.text !== 'ค่าคอมฯรวม')}
        columnStyled={tableStyled}
      />
      {!_.isEmpty(creditData) && (
        <Div padding="16px" margin="16px 0 0" bgColor={theme.color.remark}>
          <Div display="flex" justifyContent="space-between">
            <SmallText bold>ใบลดหนี้ (แบบร่าง)</SmallText>
            <P bold color={theme.color.primaryColor}>
              {creditData?.number}
            </P>
          </Div>
          <Divider margin="8px 0 16px" />
          {creditData?.itemChanges && (
            <Div margin="0 0 24px">
              <Div display="flex" margin="0 0 4px">
                <SmallText bold style={{ flexGrow: 1 }}>
                  สินค้า
                </SmallText>
                <SmallText bold style={{ width: '25%', textAlign: 'right' }}>
                  {itemChanges[0]?.price === 0 || _.isNil(itemChanges[0]?.price)
                    ? 'จำนวนที่ลด'
                    : 'ราคา/หน่วย ที่ลด'}
                </SmallText>
                <SmallText bold style={{ width: '25%', textAlign: 'right' }}>
                  ราคารวมที่ลด
                </SmallText>
              </Div>
              {itemChanges?.map((item) => (
                <Div key={item?.id} display="flex">
                  <P style={{ flexGrow: 1 }}>{item?.orderItem?.variantName}</P>
                  <P style={{ width: '25%', textAlign: 'right' }}>
                    {item?.price === 0 || _.isNil(item?.price)
                      ? item?.orderItem?.quantity - item?.quantity
                      : item?.price}
                  </P>
                  <P style={{ width: '25%', textAlign: 'right' }}>
                    {numeral(
                      item?.price === 0 || _.isNil(item?.price)
                        ? (item?.orderItem?.salesPrice - item?.price)
                            * (item?.orderItem?.quantity - item?.quantity
                              || item?.orderItem?.quantity)
                        : item?.price * item?.orderItem?.quantity,
                    ).format('0,0.00')}
                  </P>
                </Div>
              ))}
            </Div>
          )}
          <Div
            display="flex"
            justifyContent="space-between"
            alignItems="flex-end"
          >
            <Div width="40%">
              <LargeTextWithLabel
                style={{ display: 'block' }}
                title={{ text: 'วันที่ที่ออกใบ' }}
                content={{
                  text: moment(creditData?.createDate).format('DD/MM/YYYY'),
                }}
              />
              <LargeTextWithLabel
                margin="8px 0 0"
                style={{ display: 'block' }}
                title={{ text: 'หมายเหตุ' }}
                content={{
                  text: creditData?.remarks,
                  style: { wordBreak: 'break-all' },
                }}
              />
              <Div margin="8px 0 0">
                <SmallText display="block" bold>
                  ไฟล์
                </SmallText>
                {creditData?.files?.map((file) => (
                  <a
                    style={{ display: 'block' }}
                    key={file?.id}
                    href={file?.url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    คลิกเพื่อดาวน์โหลด {file?.fileName}
                  </a>
                ))}
              </Div>
            </Div>
            <Div width="55%" margin="0 0 0 32px">
              {creditData?.deliveryCost && (
                <Div
                  display="flex"
                  justifyContent="space-between"
                  margin="0 0 6px"
                >
                  <SmallText bold>ลดค่าขนส่ง</SmallText>
                  <SmallText>
                    {numeral(creditData?.deliveryCost).format('0,0.00')} บาท
                  </SmallText>
                </Div>
              )}
              <Div display="flex" justifyContent="space-between">
                <SmallText bold>มูลค่าเดิม</SmallText>
                <SmallText>
                  {numeral(creditData?.totalPriceBefore).format('0,0.00')} บาท
                </SmallText>
              </Div>
              <Div display="flex" justifyContent="space-between" margin="6px 0">
                <SmallText bold>มูลค่าใหม่</SmallText>
                <SmallText>
                  {numeral(creditData?.totalPriceAfter).format('0,0.00')} บาท
                </SmallText>
              </Div>
              <Div display="flex" justifyContent="space-between">
                <SmallText bold>ผลต่าง</SmallText>
                <SmallText bold>
                  {numeral(
                    creditData?.totalPriceBefore - creditData?.totalPriceAfter,
                  ).format('0,0.00')}{' '}
                  บาท
                </SmallText>
              </Div>
            </Div>
          </Div>
        </Div>
      )}
    </Div>
  )
}

export default (props) => {
  const [modalVisible, setModalVisible] = useState(false)
  const [lotVariables, setLotVariables] = useState()
  const {
    loading: soDetailLoading,
    data: soDetailData,
    refetch,
  } = useQuery(SALE_ORDER, { variables: { id: props.data.id } })
  const [isOpenProduct, setIsOpenProduct] = useState(true)
  const data = soDetailData?.salesOrderDetail?.data
  const { authorization } = useContext(AuthorizationContext)

  useEffect(() => {
    setLotVariables(
      soDetailData?.salesOrderDetail?.data?.lots?.map((obj) => _.omit(obj, ['pickingTicket'])),
    )
  }, [soDetailData?.salesOrderDetail?.data])

  const totalSaleAdmin = (soDetailData?.salesOrderDetail?.data?.subtotal || 0)
  const totalCommissionAdmin = (sumBy(filter(first(soDetailData?.salesOrderDetail?.data?.lots)?.items, (o) => !o.isUpsell), (o) => ((o.salesPrice - (o?.discount || 0)) * o.commission * 0.01) * o.quantity) || 0)
  const bonusCommissionAdmin = (soDetailData?.salesOrderDetail?.data?.totalBonusSale || 0)
  const totalSaleUpsell = (soDetailData?.salesOrderDetail?.data?.subtotalUpsell || 0)
  const totalCommissionUpsell = (sumBy(filter(first(soDetailData?.salesOrderDetail?.data?.lots)?.items, (o) => o.isUpsell), (o) => ((o.salesPrice - (o?.discount || 0)) * o.commission * 0.01) * o.quantity) || 0)
  const bonusCommissionUpsell = (soDetailData?.salesOrderDetail?.data?.totalBonusUpsell || 0)

  if (soDetailLoading && _.isEmpty(data)) {
    return (
      <Div textAlign="center" margin="32px 0 0">
        <Loading size="small" />
      </Div>
    )
  }

  if (!soDetailLoading && _.isEmpty(data)) return null

  const isTypeCash = PAYMENT_TYPE.CASH === data.type

  return (
    <Div>
      <Div
        display="flex"
        alignItems="flex-start"
        width="100%"
        justifyContent="space-between"
        margin="0 0 16px 0"
      >
        <H4 color={theme.color.primaryColor} bold>
          {data.soNumber}
        </H4>
        <P bold color={soStatusMaptoColor(data.status)}>
          {soStatusMaptoText(data.status)}
        </P>
      </Div>
      <Row type="flex" style={{ margin: '16px 0' }}>
        <Col span={6}>
          <LargeTextWithLabel
            title={{ text: 'ประเภทใบ SO' }}
            content={{
              text: _.find(SO_TYPE, (v) => v.value === data.salesOrderType)
                ?.text,
            }}
          />
        </Col>
        {data?.quotation && (
          <Col span={6}>
            <LargeTextWithLabel
              title={{ text: 'เลขใบ Quotation' }}
              content={{ text: data?.quotation?.refId }}
            />
          </Col>
        )}
        <Col span={6}>
          <LargeTextWithLabel
            title={{ text: 'สถานะใบปะหน้า' }}
            content={{
              text: data.addressLabelStatus === 'PRINTING'
                ? 'กำลังพิมพ์'
                : data.addressLabelStatus === 'PRINTED'
                  ? 'พิมพ์แล้ว'
                  : 'รอพิมพ์',
            }}
          />
        </Col>
        {
          data?.upsellAssignee
          && <Col span={6}>
            <LargeTextWithLabel
              title={{ text: 'แจกงาน Upsell ให้' }}
              content={{ text: `${data?.upsellAssignee?.firstName} ${data?.upsellAssignee?.lastName}` }}
            />
          </Col>
        }
        {
          data.ordersn
          && <Col span={6}>
            <LargeTextWithLabel
              title={{ text: 'หมายเลขคำสั่งซื้อ' }}
              content={{
                text: data.ordersn,
              }}
            />
          </Col>
        }
      </Row>
      <Row type="flex" style={{ margin: '16px 0' }}>
        <Col span={6}>
          <LargeTextWithLabel
            title={{ text: 'วันที่สร้างใบ So' }}
            content={{
              text: moment
                .tz(data.createdAt, 'Asia/Bangkok')
                .format('DD/MM/YYYY HH:mm'),
            }}
          />
        </Col>
        <Col span={6}>
          <LargeTextWithLabel
            title={{ text: 'ลูกค้า' }}
            content={{
              text: `${
                !_.isNil(data.customer.prefix) ? `${data.customer.prefix} ` : ''
              }${data.customer.name}`,
            }}
          />
        </Col>
        <Col span={6}>
          <LargeTextWithLabel
            title={{ text: 'ประเภทการรับชำระเงิน' }}
            content={{
              text:
                    data?.isCOD
                      ? 'ชำระเงินปลายทาง'
                      : 'ชำระเต็มจำนวน',
            }}
          />
        </Col>
        <Col span={6}>
          <LargeTextWithLabel
            title={{ text: 'ช่องทางการขาย' }}
            content={{
              text: data.chatProvider?.name || '-',
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col span={6}>
          <LargeTextWithLabel
            title={{ text: 'ชื่อผู้ติดต่อ' }}
            content={{
              text: renderText(data.contactAddress?.contactName || '-'),
            }}
          />
        </Col>
        <Col span={6}>
          <LargeTextWithLabel
            title={{ text: 'เบอร์ผู้ติดต่อ' }}
            content={{
              text: renderText(data.contactAddress?.contactPhoneNumber || '-'),
            }}
          />
        </Col>
        <Col span={6}>
          <LargeTextWithLabel
            title={{ text: 'เบอร์สำนักงาน' }}
            content={{
              text: renderText(data.contactAddress?.contactOfficeTel || '-'),
            }}
          />
        </Col>
        <Col span={6}>
          <LargeTextWithLabel
            title={{ text: 'ระดับลูกค้า' }}
            content={{ text: data?.customer?.pricingType?.name }}
          />
        </Col>
      </Row>
      <Row type="flex" style={{ margin: '16px 0' }}>
        <Col span={6} style={{ display: 'flex', flexDirection: 'column' }}>
          <SmallText bold>เอกสารแนบ</SmallText>
          {_.size(data.salesOrderFiles) > 0 ? (
            data.salesOrderFiles.map((file) => (
              <a key={file.id} href={file.url}>
                {file.fileName}
              </a>
            ))
          ) : (
            <P>-</P>
          )}
        </Col>
      </Row>
      {!_.isEmpty(soDetailData?.salesOrderDetail?.data?.qrCodeUrl) && (
        <DownLoadLink margin="0 0 24px">
          <SmallText bold display="block" margin="0 0 4px">
            QR code ล่าสุด
          </SmallText>
          <a
            href={soDetailData?.salesOrderDetail?.data?.qrCodeUrl}
            target="_blank"
            rel="noopener noreferrer"
          >
            คลิกเพื่อดาวน์โหลด
          </a>
          <img src={soDetailData?.salesOrderDetail?.data?.qrCodeUrl} />
        </DownLoadLink>
      )}
      <Remark
        showIcon={true}
        message="หมายเหตุ"
        description={data.remarks || '-'}
      />
      <Collapse
        style={{ margin: '48px 0' }}
        fullHeight={true}
        text="รายละเอียดสินค้า"
        open={isOpenProduct}
        onClick={() => setIsOpenProduct(!isOpenProduct)}
      >
        {data.lots.map((lot, index) => (
          <LotDetail
            key={lot?.id}
            lot={lot}
            data={data}
            authorization={props.authorization}
            refetch={() => refetch()}
            index={index}
            soDetailData={soDetailData}
            modalVisible={modalVisible}
            setModalVisible={(value) => setModalVisible(value)}
            lotVariables={lotVariables}
            setLotVariables={(value) => setLotVariables(value)}
            isTypeCash={isTypeCash}
          />
        ))}
        <Div
          display="flex"
          flexDirection="column"
          padding="16px"
          margin="16px 0"
          style={{
            border: `1px solid ${theme.color.secondaryColor}`,
            borderRadius: 5,
            background: theme.color.primaryColor10,
          }}
        >
          <H4 bold color={theme.color.primaryColor} margin="0 0 16px 0">
            ทั้งหมด
          </H4>
          {
            authorization.salesOrder.showCost
            && <Div width='100%' margin='4px 0' display='flex' justifyContent='space-between'>
              <P>ต้นทุนทั้งหมด</P>
              <P>{
                numeral(_.sum(
                  _.flatten(
                    soDetailData?.salesOrderDetail?.data?.lots.map(
                      (lot) => lot.items,
                    ),
                  ).map(
                    (item) => item?.quantity
                    * (item?.avgCost),
                  ),
                )).format('0,0.00')
              } บาท</P>
            </Div>
          }
          {
            authorization.salesOrder.showCost
            && <Div width='100%' margin='4px 0' display='flex' justifyContent='space-between'>
              <P>กำไรทั้งหมด</P>
              <P>{
                numeral(
                  roundNumber(_.sum(
                    _.flatten(
                      soDetailData?.salesOrderDetail?.data?.lots.map(
                        (lot) => lot.items,
                      ),
                    ).map(
                      (item) => (item.salesPrice
                        - (item?.avgCost))
                      * item.quantity,
                    ),
                  )
                - data.discountAmount
                - sumBy(first(soDetailData?.salesOrderDetail?.data?.lots)?.items, (o) => ((o.salesPrice - (o?.discount || 0)) * o.commission * 0.01) * o.quantity))
                - bonusCommissionAdmin
                - bonusCommissionUpsell,
                ).format('0,0.00')
              } บาท</P>
            </Div>
          }
          <Div width='100%' margin='4px 0' display='flex' justifyContent='space-between'>
            <P>ยอดขาย (Admin: {find(first(soDetailData?.salesOrderDetail?.data?.lots)?.items, (o) => !o.isUpsell)?.seller?.firstName} {find(first(soDetailData?.salesOrderDetail?.data?.lots)?.items, (o) => !o.isUpsell)?.seller?.lastName})</P>
            <P>{numeral(totalSaleAdmin).format('0,0.00')} บาท</P>
          </Div>
          {
            authorization.salesOrder.showCost
            && <Div width='100%' margin='4px 0' display='flex' justifyContent='space-between'>
              <P>ค่าคอมฯทั้งหมด (Admin: {find(first(soDetailData?.salesOrderDetail?.data?.lots)?.items, (o) => !o.isUpsell)?.seller?.firstName} {find(first(soDetailData?.salesOrderDetail?.data?.lots)?.items, (o) => !o.isUpsell)?.seller?.lastName})</P>
              <P>{numeral(totalCommissionAdmin).format('0,0.00')} บาท</P>
            </Div>
          }
          {
            authorization.salesOrder.showCost
            && <Div width='100%' margin='4px 0' display='flex' justifyContent='space-between'>
              <P>ค่าคอมฯโบนัส (Admin: {find(first(soDetailData?.salesOrderDetail?.data?.lots)?.items, (o) => !o.isUpsell)?.seller?.firstName} {find(first(soDetailData?.salesOrderDetail?.data?.lots)?.items, (o) => !o.isUpsell)?.seller?.lastName})</P>
              <P>{numeral(bonusCommissionAdmin).format('0,0.00')} บาท</P>
            </Div>
          }
          <Div width='100%' margin='4px 0' display='flex' justifyContent='space-between'>
            <P>ยอดขาย (Upsell: {find(first(soDetailData?.salesOrderDetail?.data?.lots)?.items, (o) => o.isUpsell)?.seller?.firstName} {find(first(soDetailData?.salesOrderDetail?.data?.lots)?.items, (o) => o.isUpsell)?.seller?.lastName})</P>
            <P>{numeral(totalSaleUpsell).format('0,0.00')} บาท</P>
          </Div>
          {
            (find(first(soDetailData?.salesOrderDetail?.data?.lots)?.items, (o) => o.isUpsell) && authorization.salesOrder.showCost)
            && <Div width='100%' margin='4px 0' display='flex' justifyContent='space-between'>
              <P>ค่าคอมฯทั้งหมด (Upsell: {find(first(soDetailData?.salesOrderDetail?.data?.lots)?.items, (o) => o.isUpsell)?.seller?.firstName} {find(first(soDetailData?.salesOrderDetail?.data?.lots)?.items, (o) => o.isUpsell)?.seller?.lastName})</P>
              <P>{numeral(totalCommissionUpsell).format('0,0.00')} บาท</P>
            </Div>
          }
          {
            (find(first(soDetailData?.salesOrderDetail?.data?.lots)?.items, (o) => o.isUpsell) && authorization.salesOrder.showCost)
            && <Div width='100%' margin='4px 0' display='flex' justifyContent='space-between'>
              <P>ค่าคอมฯโบนัส (Upsell: {find(first(soDetailData?.salesOrderDetail?.data?.lots)?.items, (o) => o.isUpsell)?.seller?.firstName} {find(first(soDetailData?.salesOrderDetail?.data?.lots)?.items, (o) => o.isUpsell)?.seller?.lastName})</P>
              <P>{numeral(bonusCommissionUpsell).format('0,0.00')} บาท</P>
            </Div>
          }
          <Div width='100%' margin='4px 0' display='flex' justifyContent='space-between'>
            <P>ยอดรวมทั้งหมด</P>
            <P>{numeral(sumBy(first(data?.lots).items, (order) => order.salesPrice * order.quantity)).format('0,0.00')} บาท</P>
          </Div>
          <Div width='100%' margin='4px 0' display='flex' justifyContent='space-between'>
            <P>ค่าขนส่ง</P>
            <P>{numeral(data.deliveryCost || 0).format('0,0.00')} บาท</P>
          </Div>
          <Div width='100%' margin='4px 0' display='flex' justifyContent='space-between'>
            <P>ยอดรวมค่าขนส่ง</P>
            <P>{numeral(sumBy(first(data?.lots).items, (order) => order.salesPrice * order.quantity) + data.deliveryCost || 0).format('0,0.00')} บาท</P>
          </Div>
          <Div width='100%' margin='4px 0' display='flex' justifyContent='space-between'>
            <P>ส่วนลด</P>
            <P>-{numeral(data.discountAmount).format('0,0.00')} บาท</P>
          </Div>
          <Divider color={theme.color.gray50} margin="8px 0" />
          <Div width='100%' margin='4px 0' display='flex' justifyContent='space-between'>
            <P bold>ยอดชำระ</P>
            <P bold>{numeral(data.subtotalAfterDiscount).format('0,0.00')} บาท</P>
          </Div>
          <Divider color={theme.color.gray50} margin="8px 0" />
          <Div width='100%' margin='4px 0' display='flex' justifyContent='space-between'>
            <P bold color={theme.color.info}>ยอดที่ชำระแล้ว</P>
            <P bold color={theme.color.info}>{numeral(sumBy(data.paymentHistories, (o) => o.subtotal)).format('0,0.00')} บาท</P>
          </Div>
          <Div width='100%' margin='4px 0' display='flex' justifyContent='space-between'>
            <P bold color={data.subtotalAfterDiscount - sumBy(data.paymentHistories, (o) => o.subtotal) > 0 ? theme.color.error : theme.color.success}>ยอดค้างชำระ</P>
            <P bold color={data.subtotalAfterDiscount - sumBy(data.paymentHistories, (o) => o.subtotal) > 0 ? theme.color.error : theme.color.success}>{numeral(data.subtotalAfterDiscount - sumBy(data.paymentHistories, (o) => o.subtotal)).format('0,0.00')} บาท</P>
          </Div>
        </Div>
      </Collapse>
    </Div>
  )
}

export const PaymentTabDetail = (props) => {
  const [isOpenPaymentEvidence, setIsOpenPaymentEvidence] = useState(true)
  const [isOpenModal, setIsOpenModal] = useState(false)
  const [paymentInput, setPaymentInput] = useState('')
  const [paymentAmountType, setPaymentAmountType] = useState()
  const [latestInput, setLatestInput] = useState('')
  const { loading: soDetailLoading, data: soDetailData } = useQuery(
    SALE_ORDER,
    { variables: { id: props.data.id } },
  )

  const data = soDetailData?.salesOrderDetail?.data
  const { loading: expenseLoading, data: expenseData } = useQuery(EXPENSE, {
    variables: { id: parseInt(props.data.id) },
  })
  const [doGenerateQr, { loading: generateQrLoading, data: generateQrData }] = useMutation(GENERATE_QR)

  const debtHistoryList = _.isEmpty(expenseData?.expenseDetail?.data?.invoices)
    && _.isEmpty(expenseData?.expenseDetail?.data?.receipts)
    ? []
    : _.concat(
      expenseData?.expenseDetail?.data?.invoices?.map((v) => ({
        list: v.debtHistories,
        parentNumber: v.number,
      })),
      expenseData?.expenseDetail?.data?.receipts?.map((v) => ({
        list: v.debtHistories,
        parentNumber: v.number,
      })),
    )

  if (
    (soDetailLoading && _.isEmpty(data))
    || (expenseLoading && _.isEmpty(expenseData))
  ) {
    return (
      <Div textAlign="center" margin="32px 0 0">
        <Loading size="small" />
      </Div>
    )
  }
  if (!soDetailLoading && _.isEmpty(data)) return null

  const subTotal = data?.subtotalAfterDiscount || 0
  const paidTotal = _.sum(data?.paymentHistories?.map((v) => v.subtotal)) || 0
  const remainTotal = subTotal - paidTotal

  return (
    <Div>
      {remainTotal > 0 && (
        <Div textAlign="right" margin="0 0 8px">
          <Modal
            title="กรอกจำนวนรับชำระ"
            visible={isOpenModal}
            footer={[
              <Button
                key="cancel"
                text="ยกเลิก"
                small
                style={{ marginRight: 8 }}
                onClick={() => setIsOpenModal(false)}
              />,
              <Button
                key="ok"
                text="ยืนยัน"
                small
                inverse
                loading={generateQrLoading}
                disabled={generateQrLoading || paymentInput === ''}
                onClick={() => {
                  doGenerateQr({
                    variables: {
                      amount: parseFloat(paymentInput),
                      salesOrderId: props?.data?.id,
                    },
                  }).then((resp) => {
                    if (resp?.data?.generateQR?.success) {
                      message.success(resp?.data?.generateQR?.message)
                      setIsOpenModal(false)
                      setLatestInput(paymentInput)
                      setPaymentInput('')
                    } else {
                      message.error(resp?.data?.generateQR?.message)
                    }
                  })
                }}
              />,
            ]}
            onCancel={() => setIsOpenModal(false)}
          >
            <Div>
              <P display="block" margin="0 0 16px">
                เลือกจำนวนที่ต้องการรับชำระ
              </P>
              <RadioGroup
                value={paymentAmountType}
                list={[
                  {
                    value: 'FULL_AMOUNT',
                    text: <P>ชำระจำนวนที่เหลือทั้งหมด</P>,
                    margin: '0 24px 0 0',
                  },
                  {
                    value: 'DEPOSIT',
                    text: <P>ชำระมัดจำ/ชำระเพิ่มบางส่วน</P>,
                  },
                ]}
                onChange={(e) => {
                  setPaymentAmountType(e.target.value)
                  if (e.target.value === 'FULL_AMOUNT') {
                    setPaymentInput(remainTotal)
                  } else {
                    setPaymentInput('')
                  }
                }}
              />
              <Div margin="8px 0 0 241px">
                <Input
                  disabled={paymentAmountType !== 'DEPOSIT'}
                  state="default"
                  rule={{ type: 'float' }}
                  onChange={(e) => {
                    if (
                      parseFloat(e.target.value) <= remainTotal
                      || _.isEmpty(e.target.value)
                    ) {
                      setPaymentInput(e.target.value)
                    }
                  }}
                />
              </Div>
            </Div>
          </Modal>
          {!(data.salesOrderType === 'POS') && (
            <Button
              small
              disabled={isOpenModal}
              icon="fal fa-qrcode"
              text="สร้าง QR code รับชำระเงิน"
              onClick={() => setIsOpenModal(true)}
            />
          )}
        </Div>
      )}
      {(!_.isEmpty(soDetailData?.salesOrderDetail?.data?.qrCodeUrl)
        || !_.isEmpty(generateQrData?.generateQR?.data?.url)) && (
        <DownLoadLink margin="0 0 24px">
          <SmallText bold display="block" margin="0 0 4px">
            QR code ล่าสุด (รับชำระ{' '}
            {numeral(
              soDetailData?.salesOrderDetail?.data?.transferAmount
                || latestInput,
            ).format('0,0.00')}{' '}
            บาท)
          </SmallText>
          <a
            href={
              _.isEmpty(soDetailData?.salesOrderDetail?.data?.qrCodeUrl)
                ? generateQrData?.generateQR?.data?.url
                : soDetailData?.salesOrderDetail?.data?.qrCodeUrl
            }
            target="_blank"
            rel="noopener noreferrer"
          >
            คลิกเพื่อดาวน์โหลด
          </a>
          <img
            src={
              _.isEmpty(soDetailData?.salesOrderDetail?.data?.qrCodeUrl)
                ? generateQrData?.generateQR?.data?.url
                : soDetailData?.salesOrderDetail?.data?.qrCodeUrl
            }
          />
        </DownLoadLink>
      )}
      <PaymentEvidenceList
        isEdit
        margin="0 0 48px"
        soId={props.data.id}
        fullHeight={true}
        isOpen={isOpenPaymentEvidence}
        setIsOpen={setIsOpenPaymentEvidence}
        block
        title="ประวัติการแจ้งชำระเงิน/ใบเพิ่มลดหนี้"
        subtotal={subTotal}
        paid={paidTotal}
        depositAmount={data.depositAmount}
        list={
          data?.paymentHistories?.map((pay, i) => ({
            ...pay,
            index: i + 1,
            src: pay?.attachment,
            info: pay?.remarks ? pay?.remarks : '-',
            date: pay?.dateTime,
            subtotal: pay?.subtotal,
            type: pay?.type,
            isEdit:
              data?.status !== SO_STATUS.WAITSHIPMENT
              && data?.status !== SO_STATUS.COMPLETED
              && _.isNil(pay?.transactionId),
          })) || []
        }
        debtHistories={debtHistoryList || []}
        deliveryCost={data.deliveryCost}
        history={props.history}
        pushState={props.pushState}
      />
    </Div>
  )
}
