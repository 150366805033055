import React, { useState, useEffect } from 'react'
import { useMutation, useQuery } from '@apollo/client'
import numeral from 'numeral'
import { Link } from 'react-router-dom'
import _ from 'lodash'
import { Row, Col, message } from 'antd'
import {
  Div, H4, Button, P,
} from '@konsys-ui-custom'
import {
  InputWithLabel, LargeTextWithLabel, TextareaWithLabel, Divider, Loading,
} from '../../components'
import { theme } from '../../styles/_variables'
import { ADJUST_STOCK } from '../../api/mutation'
import { INVENTORY } from '../../api/query'

export default (props) => {
  const [doUpdate, { loading }] = useMutation(ADJUST_STOCK, { refetchQueries: ['remainingJob', 'remainingAccountant'] })
  const [adjustStockList, setAdjustStockList] = useState([])
  const [data, setData] = useState()
  const { loading: inventoryLoading, data: inventoryDetail } = useQuery(INVENTORY, { variables: { id: props.data.id } })

  useEffect(() => {
    setData(inventoryDetail?.inventoryInfo?.data)
    if (inventoryDetail?.inventoryInfo?.data) {
      setAdjustStockList([{
        itemId: inventoryDetail?.inventoryInfo?.data?.inventoryOption?.id,
        variantName: inventoryDetail?.inventoryInfo?.data?.inventoryOption?.variantName || '-',
        adjustment: inventoryDetail?.inventoryInfo?.data?.inventoryOption?.stockLocation?.map((location) => ({
          warehouseId: location.warehouse.id, warehouseName: location.warehouse.warehouseName, quantityChange: location.quantity, quantity: location.quantity, reason: undefined,
        })) || [],
      }])
    }
  }, [inventoryDetail?.inventoryInfo?.data])

  if (inventoryLoading) {
    return (
      <Div textAlign='center' margin='32px 0 0'>
        <Loading size='small' />
      </Div>
    )
  }
  if (_.isEmpty(data)) return null

  return (
    <Div>
      <Div margin='16px 0'>
        <H4 bold>แก้ไขสต๊อก</H4>
        <Div>
          {
            adjustStockList.map((v) => (
              <Div key={v.itemId} style={{ border: `1px solid ${theme.color.lightBlue}`, borderRadius: 5, background: theme.color.primaryColor10 }} margin='16px 0' padding='16px'>
                <P bold>{v.variantName}</P>
                {
                  v.adjustment?.map((stock, indexStock) => (
                    <Div key={stock.warehouseId}>
                      <Row gutter={16} type='flex'>
                        <Col span={6}>
                          <LargeTextWithLabel margin='8px 0' content={{ text: stock.warehouseName }} title={{ text: 'คลังสินค้า', margin: '0 0 8px 0' }} />
                        </Col>
                        <Col span={6}>
                          <LargeTextWithLabel margin='8px 0' content={{ text: stock.quantity, textAlign: 'right' }} title={{ text: 'จำนวนเดิม', margin: '0 0 8px 0' }} />
                        </Col>
                        <Col span={6}>
                          <InputWithLabel
                            input={{
                              defaultValue: stock.quantity,
                              width: '100%',
                              placeholder: 'กรอกจำนวนใหม่',
                              state: 'default',
                              rule: { type: 'float' },
                            }}
                            onChange={(e) => setAdjustStockList(adjustStockList.map((val) => {
                              if (val.itemId === v.itemId) {
                                return ({
                                  ...val,
                                  adjustment: val.adjustment.map((location) => {
                                    if (location.warehouseId === stock.warehouseId) {
                                      return ({ ...location, quantityChange: parseFloat(e.target.value) })
                                    } return location
                                  }),
                                })
                              } return val
                            }))}
                            title={{ text: 'จำนวนใหม่' }}
                          />
                        </Col>
                        <Col span={6}>
                          <LargeTextWithLabel
                            margin='8px 0'
                            content={{
                              text: numeral(_.toSafeInteger(stock.quantityChange - stock.quantity)).format('+0'),
                              textAlign: 'right',
                            }}
                            title={{ text: 'ส่วนต่าง', margin: '0 0 8px 0' }}
                          />
                        </Col>
                      </Row>
                      <TextareaWithLabel
                        width='100%'
                        title={{
                          text: 'เหตุผล',
                        }}
                        textarea={{
                          style: { width: '100%' },
                          rows: 5,
                          onChange: (e) => setAdjustStockList(adjustStockList.map((val) => {
                            if (val.itemId === v.itemId) {
                              return ({
                                ...val,
                                adjustment: val.adjustment.map((location) => {
                                  if (location.warehouseId === stock.warehouseId) {
                                    return ({ ...location, reason: e.target.value })
                                  } return location
                                }),
                              })
                            } return val
                          })),
                        }}
                      />
                      {
                        _.size(v.adjustment) > indexStock + 1
                          && <Divider
                            color={theme.color.blue}
                            margin='16px 0'
                          />
                      }
                    </Div>
                  ))
                }
              </Div>
            ))
          }
        </Div>
        <Div display='flex' justifyContent='center' margin='20px 0 0 0'>
          <Link to='/inventory'>
            <Button
              color={theme.color.error}
              disabled={loading}
              text='ยกเลิก'
              icon='far fa-times'
              style={{ alignItems: 'center' }}
              width='fit-content'
              margin='0 20px 0 0'
            />
          </Link>
          <Button
            inverse
            color={theme.color.success}
            text='ยืนยัน'
            disabled={loading}
            loading={loading}
            onClick={() => {
              doUpdate({
                variables: {
                  adjustStockList: adjustStockList.map((v) => _.omit(({ ...v, adjustment: v.adjustment.map((val) => _.omit(val, ['warehouseName', 'quantity'])) }), ['variantName'])),
                },
              }).then((resp) => {
                if (resp.data.adjustStock.success) {
                  message.success(resp.data.adjustStock.message)
                  props.refetch()
                } else message.error(resp.data.adjustStock.message)
              })
            }}
          />
        </Div>
      </Div>
    </Div>
  )
}
