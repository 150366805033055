import { useLazyQuery, useQuery } from '@apollo/client'
import {
  Div,
  H3,
  Icon,
  P,
} from '@konsys-ui-custom'
import { Col, DatePicker, Row, TimePicker } from 'antd'
import { isEmpty } from 'lodash'
import moment from 'moment'
import numeral from 'numeral'
import React, { useEffect, useState } from 'react'
import { nanoid } from 'nanoid'
import { CHAT_PROVIDERS, DASHBOARD_COMMON_INFO, TEAM_LIST, USERS } from '../../api/query'
import {
  RangePicker, Select,
  Tab,
} from '../../components'
import { theme } from '../../styles/_variables'
import { delayInput } from '../../utils'
import ChatAndSalesReport from './components/chat-sales-report'
import PieChart from './components/pie-chart'
import StackedBarChart from './components/stacked-bar-chart'

const SummaryCard = ({ width, margin, icon, title, total, unit, filter }) => (
  <Div width={width} margin={margin} display='flex' alignItems='flex-start' justifyContent='space-between' padding='16px 24px' bgColor={theme?.color?.offWhite} moreStyle={{ borderRadius: '6px', boxShadow: 'rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px' }}>
    <Div display='flex' flexDirection='column'>
      <Div display='flex' alignItems='center' margin='0 0 4px'>
        {
          icon && <Icon color={theme?.color?.gray50} icon={icon} margin='0 8px 0 0' />
        }
        <P color={theme?.color?.gray50}>{title}</P>
      </Div>
      <Div display='flex' alignItems='center'>
        <H3 bold color={theme?.color?.secondaryColor}>{total}</H3>
        <P margin='10px 0 0 16px' bold color={theme?.color?.secondaryColor}>{unit}</P>
      </Div>
    </Div>
    { filter }
  </Div>
)

export default () => {
  const [vars, setVars] = useState({
    provider: 'all',
    userId: 'all',
    date: [moment(), moment()],
    timePeriod: [moment().startOf('d'), moment().endOf('d')],
    teamId: 'all',
  })
  const [isHourly, setIsHourly] = useState(false)
  const [currentTab, setCurrentTab] = useState()
  const [getUsers, { data: usersData, loading: getUsersLoading }] = useLazyQuery(USERS)
  const { data: teamsData, loading: teamsLoading } = useQuery(TEAM_LIST)
  const [getChatProvider, { data: chatProviderData }] = useLazyQuery(CHAT_PROVIDERS)
  const [getDashboardCommonInfo, { data: dashboardData }] = useLazyQuery(DASHBOARD_COMMON_INFO)

  const setHourly = (value) => {
    setIsHourly(value)
  }

  const input = {
    teamId: vars?.teamId === 'all' ? undefined : vars?.teamId,
    sellerId: vars?.userId === 'all' ? undefined : vars?.userId,
    chatProviderId: vars?.provider === 'all' ? undefined : vars?.provider,
    period: isHourly ? vars?.date[0] : vars?.date,
    timePeriod: vars?.timePeriod ? vars?.timePeriod.map((time) => time.format('HH:mm:ss')) : undefined,
  }
  const callGetDashboardCommon = () => {
    if (!isEmpty(vars?.date)) {
      getDashboardCommonInfo({
        variables: {
          input,
        },
      })
    }
  }
  const callGetUsers = (searchValue, teamId) => {
    getUsers({
      variables: {
        limit: 10,
        name: searchValue,
        teamId: (teamId || vars.teamId) === 'all' ? undefined : (teamId || vars.teamId),
      },
    })
  }

  useEffect(() => {
    callGetUsers()
    getChatProvider()
    callGetDashboardCommon()
  }, [])

  useEffect(() => {
    callGetDashboardCommon()
  }, [vars])

  const userList = [
    { text: 'ทั้งหมด', value: 'all' },
    ...(usersData?.userList?.data?.list?.map((userDetail) => (
      { text: `${userDetail?.firstName} ${userDetail?.lastName}`, value: userDetail?.id }
    )) || []),
  ]
  const providerList = [
    { text: 'ทั้งหมด', value: 'all' },
    ...(chatProviderData?.chatProviders?.data?.map((providerDetail) => (
      { text: providerDetail?.name, value: providerDetail?.id }
    )) || []),
  ]

  const getCommonData = dashboardData?.dashboardCommonInfo?.data

  const summaryList = [
    {
      icon: 'fas fa-hand-holding-usd',
      title: 'ยอดขาย',
      total: numeral(getCommonData?.saleAmounts).format('0,0.00'),
      unit: 'บาท',
    },
    {
      icon: 'fas fa-users',
      title: 'ลูกค้า',
      total: numeral(getCommonData?.customerCount).format('0,0'),
      unit: 'คน',
    },
    {
      icon: 'fas fa-comment-alt-dots',
      title: 'แชตใหม่',
      total: numeral(getCommonData?.chatCount).format('0,0'),
      unit: 'แชต',
    },
    {
      icon: 'fas fa-comment-alt-dots',
      title: 'รอเกิน 15 นาที',
      total: numeral(getCommonData?.waitingThan15Mins).format('0,0'),
      unit: 'คน',
    },
    {
      icon: 'fas fa-users',
      title: 'ลูกค้าใหม่ที่ปิดยอด',
      total: numeral(getCommonData?.sameDaySoCount).format('0,0'),
      unit: 'คน',
    },
    {
      icon: 'fas fa-comment-alt-dots',
      title: 'จำนวนแชตที่ทัก',
      total: numeral(getCommonData?.chatAfterReminder).format('0,0'),
      unit: 'แชต',
    },
    {
      icon: 'fas fa-users',
      title: 'ลูกค้าที่ CRM ปิดยอด',
      total: numeral(getCommonData?.chatAfterSalesOrder).format('0,0'),
      unit: 'คน',
    },
  ]

  return (
    <Tab
      fullHeight
      title={[
        {
          text: 'Dashboard',
          icon: 'fad fa-analytics',
        },
      ]}
    >
      <Div bgColor={theme?.color?.background}>
        <Div display='flex' justifyContent='flex-end' margin='0 0 16px' align-items='center'>
          <Select
            placeholder='เลือกทีม'
            showSearch
            onChange={(e, other) => {
              setVars({ ...vars, teamId: other.id, userId: 'all' })
              callGetUsers('', other.id)
            }}
            loading={teamsLoading}
            defaultValue={vars?.teamId}
            options={
              [{ value: 'all', text: 'ทั้งหมด' }, ...(teamsData?.teamList?.data?.list?.map((v) => ({ id: v.id, value: v.teamName, text: v.teamName })) || [])]
            }
            style={{ width: '17%', margin: '0 16px 0 0' }}
          />
          <Select
            showSearch
            filterOption={false}
            loading={getUsersLoading}
            style={{ width: '17%', margin: '0 16px 0 0' }}
            placeholder='แอดมิน'
            options={userList}
            value={vars?.userId}
            onChange={(value) => setVars({ ...vars, userId: value })}
            onSearch={(value) => delayInput(() => callGetUsers(value))}
          />
          <Select
            style={{ width: '17%', margin: '0 16px 0 0' }}
            placeholder='ช่องทาง'
            options={providerList}
            onChange={(value) => setVars({ ...vars, provider: value })}
            value={vars?.provider}
          />
          {
            isHourly
              ? <DatePicker
                format='DD/MM/YYYY'
                style={{ width: '22%', margin: '0 16px 0 0' }}
                onChange={(selectedDate) => setVars({ ...vars, date: [selectedDate, selectedDate] })}
                value={vars?.date?.[0]}
                allowClear={false}
              />
              : currentTab === 6
                ? <>
                  <DatePicker
                    format='DD/MM/YYYY'
                    style={{ width: '10%', margin: '0 16px 0 0' }}
                    onChange={(selectedDate) => setVars({ ...vars, date: [selectedDate, undefined] })}
                    value={vars?.date?.[0]}
                    allowClear={false}
                  />
                  <P margin='0 8px'>-</P>
                  <DatePicker
                    format='DD/MM/YYYY'
                    style={{ width: '10%', margin: '0 16px 0 0' }}
                    onChange={(selectedDate) => setVars({ ...vars, date: [vars?.date?.[0], selectedDate] })}
                    value={vars?.date?.[1]}
                    allowClear={false}
                    disabledDate={(current) => current && (current > moment(vars?.date?.[0]).add(7, 'd').endOf('day') || current < moment(vars?.date?.[0]).subtract(1, 'd').endOf('day'))}
                  />
                </>
                : <RangePicker
                  format='DD/MM/YYYY'
                  style={{ width: '22%', margin: '0 16px 0 0' }}
                  onChange={(selectedDate) => setVars({ ...vars, date: selectedDate })}
                  value={vars?.date}
                  allowClear={false}
                />
          }
          <TimePicker.RangePicker
            format='HH'
            value={vars?.timePeriod}
            onChange={(e) => {
              setVars({ ...vars, timePeriod: e })
              getDashboardCommonInfo({
                variables: {
                  input: {
                    ...input,
                    timePeriod: e.map((time) => moment(time).format('HH:mm:ss')),
                  },
                },
              })
            }}/>
        </Div>
        <Row gutter={[16, 16]} style={{ marginBottom: 16 }}>
          {
            summaryList?.map((summaryDetail) => (
              <Col span={6} key={nanoid()}>
                <SummaryCard width='100%' key={summaryDetail?.title} {...summaryDetail} />
              </Col>
            ))
          }
        </Row>
        <Tab
          fullHeight
          title={[
            { text: 'Daily / Hourly', icon: 'fad fa-chart-bar' },
            { text: 'Top 5', icon: 'fad fa-chart-pie' },
            { text: 'Top Product', icon: 'fad fa-chart-pie' },
            { text: 'Sales by Province', icon: 'fad fa-chart-pie' },
            { text: 'Order Summary', icon: 'fad fa-file-check' },
            { text: 'Box Summary', icon: 'fad fa-box' },
            { text: 'Time spent to respond to chat', icon: 'fab fa-facebook-messenger' },
          ]}
          noDefaultTab={true}
          onChangeTab={(key) => {
            setCurrentTab(key)
            setHourly(false)
            if (key === 6) {
              setVars({
                provider: 'all',
                userId: 'all',
                date: [moment(), moment()],
              })
            }
          }}
        >
          <ChatAndSalesReport {...vars} setHourly={setHourly} />
          <PieChart {...vars} />
          <PieChart {...vars} type='product' />
          <PieChart {...vars} type='province' />
          <StackedBarChart {...vars} type='ORDER_SUMMARY' setHourly={setHourly} />
          <StackedBarChart {...vars} type='BOX_SUMMARY' />
          <StackedBarChart {...vars} type='TIME_SPENT' />
        </Tab>
      </Div>
    </Tab>
  )
}
