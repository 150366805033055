import { Helmet } from 'react-helmet'
import React, { useState, useEffect, useContext } from 'react'
import _ from 'lodash'
import { useHistory } from 'react-router-dom'
import {
  Div,
  Icon,
  Button,
  RadioGroup,
  H4,
  P,
  SmallText,
  Input,
  Label,
  Checkbox,
} from '@konsys-ui-custom'
import { message, AutoComplete, Row, Col, notification } from 'antd'
import { useQuery, useMutation } from '@apollo/client'
import { BANKS, SUPPLIER, GET_BANK_TYPE, GET_PREFIX } from '../../api/query'
import { CREATE_SUPPLIER, UPDATE_SUPPLIER } from '../../api/mutation'
import {
  SelectWithLabel,
  TextareaWithLabel,
  InputWithLabel,
  FullPageContainer,
  Select,
  ComponentWithLabelLarge,
  Collapse,
  Divider,
  NewAddress,
} from '../../components'
import { PAYMENT_TYPE, PERSON_TYPE, PREFIX } from './constants'
import { theme } from '../../styles/_variables'
import { validateData, permissionRedirect } from '../../utils/util-services'
import { mapBankTypetoText } from './utils'
import { AuthorizationContext } from '../../store/StoreProvider'

const Render = ({
  loading,
  isCreateMode,
  infoData,
  data,
  bankTypeLoading,
  bankTypeData,
  mutationLoading,
  doAction,
  props,
  supplierAutho,
}) => {
  const history = useHistory()
  const { data: prefixData, loading: prefixLoading } = useQuery(GET_PREFIX)
  const [mutationVariables, setMutationVariables] = useState({
    type: PERSON_TYPE.INDIVIDUAL,
    paymentType: PAYMENT_TYPE.CASH,
  })
  const [emails, setEmails] = useState([{ id: 1, email: '', isPrimary: true }])
  const [phones, setPhones] = useState([
    {
      id: 1,
      name: '',
      phoneNumber: '',
      isPrimary: true,
    },
  ])
  const [deliveryAddresses, setDeliveryAddresses] = useState([
    {
      id: 1,
      isPrimary: true,
    },
  ])
  const [contactAddresses, setContactAddresses] = useState([
    {
      id: 1,
      isPrimary: true,
    },
  ])
  const [openCollapse, setOpenCollapse] = useState({
    deliveryAddress: false,
    contactAddress: false,
  })
  let prepare
  if (!isCreateMode) {
    prepare = infoData.supplierDetail.data.paymentInfo.map((v, i) => ({
      id: i + 1,
      bankAccountNumber: v.bankAccountNumber,
      bankAccountName: v.bankAccountName,
      bankAccountType: v.bankAccountType,
      bankId: v.bank && v.bank.id,
    }))
  }
  const [paymentInfo, setPaymentInfo] = useState(
    isCreateMode
      ? [
        {
          id: 1,
          bankAccountNumber: undefined,
          bankAccountName: undefined,
          bankAccountType: undefined,
          bankId: undefined,
        },
      ]
      : prepare,
  )
  const allFields = isCreateMode || _.isEqual(paymentInfo, prepare)
    ? { ...mutationVariables }
    : {
      ...mutationVariables,
      paymentInfo: paymentInfo.map((v) => _.omit(v, ['id'])),
    }
  // const baseRequiredFields = [mutationVariables.name, mutationVariables.type, mutationVariables.paymentType, mutationVariables.isVat]
  const addressMutationData = (addressDataList, addressType) => _.compact(
    _.map(addressDataList, (v) => {
      const addressData = !_.isUndefined(v.type)
        ? v.isDefault
          ? { id: v.id }
          : _.omit(v, ['isPrimary', 'isDefault'])
        : _.omit(v, ['isPrimary', 'id', 'isDefault'])
      if (_.isEmpty(addressData)) return null
      return {
        isPrimary: v.isPrimary,
        address: addressType === 'shipping' ? _.omit(addressData, ['zipcode', 'subdistrict']) : _.omit(addressData, ['zipcode', 'subdistrict', 'addressNo', 'province', 'district', 'subDistrict', 'postcode']),
      }
    }),
  )

  const baseRequiredFields = _.concat(
    [mutationVariables.type, mutationVariables.isVat, mutationVariables.name],
    _.isEmpty(addressMutationData(contactAddresses, 'contact'))
      ? null
      : [
        addressMutationData(contactAddresses, 'contact')[0].address.contactName,
        addressMutationData(contactAddresses, 'contact')[0].address.contactOfficeTel,
        addressMutationData(contactAddresses, 'contact')[0].address.contactPhoneNumber,
        addressMutationData(contactAddresses, 'contact')[0].address.contactEmail,
        // addressMutationData(contactAddresses)[0].address.addressNo,
        // addressMutationData(contactAddresses)[0].address.subDistrict,
        // addressMutationData(contactAddresses)[0].address.district,
        // addressMutationData(contactAddresses)[0].address.postcode,
      ],
  )

  useEffect(() => {
    if (prefixData && mutationVariables.type === PERSON_TYPE.CORPORATION) {
      setMutationVariables({
        ...mutationVariables,
        prefix: prefixData.supplierPrefix.data[0],
      })
    }
    if (infoData) {
      if (!_.isEmpty(infoData.supplierDetail.data.shippingAddress)) {
        setDeliveryAddresses(
          _.map(infoData.supplierDetail.data.shippingAddress, (v) => ({
            ..._.omit(v, ['supplierId', 'customerId', '__typename']),
            isDefault: true,
          })),
        )
      }
      if (!_.isEmpty(infoData.supplierDetail.data.contactAddress)) {
        setContactAddresses(
          _.map(infoData.supplierDetail.data.contactAddress, (v) => ({
            ..._.omit(v, ['supplierId', 'customerId', '__typename']),
            isDefault: true,
          })),
        )
      }
      if (!_.isEmpty(infoData.supplierDetail.data.contactPhoneNumber)) {
        setPhones(
          _.map(infoData.supplierDetail.data.contactPhoneNumber, (v) => ({
            ..._.omit(v, ['__typename']),
          })),
        )
      }
      if (!_.isEmpty(infoData.supplierDetail.data.contactEmail)) {
        setEmails(
          _.map(infoData.supplierDetail.data.contactEmail, (v) => ({
            ..._.omit(v, ['__typename']),
          })),
        )
      }
    }
  }, [prefixData, infoData])

  const isCanEditAdvanceContent = isCreateMode
    ? false
    : !supplierAutho.editAdvance

  return (
    <Div display="flex" flexDirection="column" padding="0 60px">
      {/* <Log data={{
        paymentInfo, baseRequiredFields, mutationVariables, all: { ...mutationVariables, paymentInfo: paymentInfo.map(v => _.omit(v, ['id'])) },
      }}/> */}
      <SelectWithLabel
        margin="8px 0"
        loading={loading}
        title={{
          text: (
            <SmallText bold>
              ประเภท <Label color={theme.color.error}>*</Label>
            </SmallText>
          ),
        }}
        select={{
          defaultValue: !isCreateMode
            ? infoData.supplierDetail.data.type
            : mutationVariables.type,
          placeholder: 'เลือกประเภท',
          onChange: (e) => setMutationVariables({
            ...mutationVariables,
            type: e,
            prefix:
                e === PERSON_TYPE.INDIVIDUAL
                  ? PREFIX[0]
                  : prefixData.supplierPrefix.data[0],
          }),
          options: [
            { value: PERSON_TYPE.INDIVIDUAL, text: 'บุคคลธรรมดา' },
            { value: PERSON_TYPE.CORPORATION, text: 'นิติบุคคล' },
          ],
        }}
        className={
          ((_.isEmpty(
            !isCreateMode
              ? infoData.supplierDetail.data.type
              : mutationVariables.type,
          )
            && !_.isNumber(
              !isCreateMode
                ? infoData.supplierDetail.data.type
                : mutationVariables.type,
            ))
            || _.isNaN(
              !isCreateMode
                ? infoData.supplierDetail.data.type
                : mutationVariables.type,
            ))
          && 'state-error'
        }
      />
      <Row type="flex" gutter={16}>
        <Col span={5}>
          {mutationVariables.type === PERSON_TYPE.CORPORATION
          || (!isCreateMode
            && infoData.supplierDetail.data.type === PERSON_TYPE.CORPORATION
            && !mutationVariables.type) ? (
              <SelectWithLabel
                width="100%"
                margin="8px 0"
                title={{
                  text: 'คำนำหน้า',
                  margin: '0 0 4px',
                }}
                select={{
                  value: mutationVariables.prefix,
                  loading: prefixLoading,
                  style: { width: '100%' },
                  defaultValue: !isCreateMode
                    ? infoData.supplierDetail.data.prefix
                    : undefined,
                  placeholder: 'เลือกคำนำหน้า',
                  onChange: (e) => setMutationVariables({ ...mutationVariables, prefix: e }),
                  options: prefixLoading
                    ? []
                    : prefixData.supplierPrefix.data.map((v) => ({
                      value: v,
                      text: v,
                    })),
                  disabled: isCanEditAdvanceContent,
                }}
              />
            ) : (
              <ComponentWithLabelLarge
                title={{ text: 'คำนำหน้า', margin: '0 0 4px' }}
                width="100%"
              >
                <AutoComplete
                  value={mutationVariables.prefix}
                  dataSource={PREFIX}
                  style={{ width: '100%', margin: '8px 0' }}
                  onSelect={(e) => setMutationVariables({ ...mutationVariables, prefix: e })
                  }
                  defaultValue={
                    !isCreateMode
                      ? infoData.supplierDetail.data.prefix
                      : undefined
                  }
                  onSearch={(e) => setMutationVariables({ ...mutationVariables, prefix: e })
                  }
                  filterOption={(inputValue, option) => option.props.children
                    .toUpperCase()
                    .indexOf(inputValue.toUpperCase()) !== -1
                  }
                  placeholder="ใส่"
                  disabled={isCanEditAdvanceContent}
                />
              </ComponentWithLabelLarge>
            )}
        </Col>
        <Col span={10}>
          <InputWithLabel
            width="100%"
            input={{
              defaultValue: !isCreateMode
                ? infoData.supplierDetail.data.name
                : undefined,
              width: '100%',
              prefix: <Icon icon="prefix far fa-signature" />,
              state:
                _.isEmpty(
                  !isCreateMode
                    ? infoData.supplierDetail.data.name
                    : mutationVariables.name,
                ) && 'error',
              disabled: isCanEditAdvanceContent,
            }}
            onChange={(e) => setMutationVariables({
              ...mutationVariables,
              name: e.target.value,
            })
            }
            title={{
              text: (
                <SmallText bold margin="0 0 4px">
                  ชื่อ <Label color={theme.color.error}>*</Label>
                </SmallText>
              ),
            }}
          />
        </Col>
        <Col span={9}>
          <InputWithLabel
            width="100%"
            input={{
              defaultValue: !isCreateMode
                ? infoData.supplierDetail.data.taxId
                : undefined,
              width: '100%',
              disabled: isCanEditAdvanceContent,
              value: mutationVariables?.taxId,
              state:
                mutationVariables?.taxId?.length < 13 ? 'error' : 'default',
            }}
            onChange={(e) => {
              if (e.target.value.length <= 13) {
                setMutationVariables({
                  ...mutationVariables,
                  taxId: e.target.value,
                })
              }
            }}
            title={{ text: 'เลขที่เสียภาษี', margin: '0 0 4px' }}
          />
        </Col>
      </Row>
      <Row type="flex" gutter={16}>
        <Col span={10}>
          <ComponentWithLabelLarge
            title={{
              text: (
                <SmallText bold>
                  VAT <Label color={theme.color.error}>*</Label>
                </SmallText>
              ),
              margin: '8px 0 0',
            }}
          >
            <RadioGroup
              style={{ margin: '8px 0' }}
              vertical
              defaultCheckedValue={
                !isCreateMode
                  ? infoData.supplierDetail.data.isVat
                    ? 'VAT'
                    : 'NOVAT'
                  : undefined
              }
              name="vat"
              onChange={(e) => setMutationVariables({
                ...mutationVariables,
                isVat: e.target.value === 'VAT',
              })
              }
              list={[
                { value: 'VAT', text: 'เก็บ VAT', margin: '0 0 6px' },
                { value: 'NOVAT', text: 'ไม่เก็บ VAT' },
              ]}
            />
          </ComponentWithLabelLarge>
        </Col>
      </Row>
      <Div
        display="flex"
        flexDirection="column"
        padding="8px"
        margin="8px 0"
        style={{
          border: `1px solid ${theme.color.lightBlue}`,
          borderRadius: 5,
          background: theme.color.primaryColor10,
        }}
      >
        <SmallText bold>เบอร์โทรศัพท์</SmallText>
        {phones.map((phone) => (
          <Div
            key={phone.id}
            width="100%"
            margin="8px 0"
            display="flex"
            alignItems="center"
          >
            <Checkbox
              margin="0 16px 0 0"
              checked={phone.isPrimary}
              data={{ value: `phone${phone.id}` }}
              onChange={(e) => setPhones(
                phones.map((v) => {
                  if (!phone.isPrimary) {
                    if (v.id === phone.id) { return { ...v, isPrimary: e.target.checked } }
                    return { ...v, isPrimary: false }
                  }
                  return v
                }),
              )
              }
            />
            <Input
              width="100%"
              margin="0 16px 0 0"
              placeholder="ชื่อ"
              defaultValue={!isCreateMode ? phone.name : undefined}
              onChange={(e) => setPhones(
                phones.map((v) => {
                  if (v.id === phone.id) return { ...v, name: e.target.value }
                  return v
                }),
              )
              }
            />
            <Input
              width="100%"
              margin="0 16px 0 0"
              placeholder="หมายเลข"
              defaultValue={!isCreateMode ? phone.phoneNumber : undefined}
              onChange={(e) => setPhones(
                phones.map((v) => {
                  if (v.id === phone.id) { return { ...v, phoneNumber: e.target.value } }
                  return v
                }),
              )
              }
              rule={{ type: 'int' }}
              state="default"
            />
            <Icon
              margin="0 0 0 0"
              icon="fal fa-trash-alt"
              color={theme.color.gray80}
              style={{ cursor: 'pointer' }}
              onClick={() => {
                if (phone.isDefault) {
                  notification.error({
                    message: 'การลบล้มเหลว',
                    description: 'กรุณาเปลี่ยนค่าค่าเริ่มต้นก่อน',
                  })
                } else {
                  setPhones(
                    _.remove(_.cloneDeep(phones), (o) => o.id !== phone.id),
                  )
                }
              }}
            />
          </Div>
        ))}
        <Button
          onlyIcon
          icon="fal fa-plus"
          margin="16px auto"
          inverse
          onClick={() => {
            setPhones([
              ...phones,
              {
                id: _.size(phones) > 0 ? _.maxBy(phones, 'id').id + 1 : 1,
                name: '',
                phoneNumber: '',
                isPrimary: false,
              },
            ])
          }}
        />
      </Div>
      <Div
        display="flex"
        flexDirection="column"
        padding="8px"
        margin="8px 0"
        style={{
          border: `1px solid ${theme.color.lightBlue}`,
          borderRadius: 5,
          background: theme.color.primaryColor10,
        }}
      >
        <SmallText bold>Email</SmallText>
        {emails.map((email) => (
          <Div
            key={email.id}
            width="100%"
            margin="8px 0"
            display="flex"
            alignItems="center"
          >
            <Checkbox
              margin="0 16px 0 0"
              checked={email.isPrimary}
              data={{ value: `email${email.id}` }}
              onChange={(e) => setEmails(
                emails.map((v) => {
                  if (!email.isPrimary) {
                    if (v.id === email.id) { return { ...v, isPrimary: e.target.checked } }
                    return { ...v, isPrimary: false }
                  }
                  return v
                }),
              )
              }
            />
            <Input
              width="100%"
              margin="0 16px 0 0"
              defaultValue={!isCreateMode ? email.email : undefined}
              onChange={(e) => setEmails(
                emails.map((v) => {
                  if (v.id === email.id) { return { ...v, email: e.target.value } }
                  return v
                }),
              )
              }
            />
            <Icon
              margin="0 0 0 0"
              icon="fal fa-trash-alt"
              color={theme.color.gray80}
              style={{ cursor: 'pointer' }}
              onClick={() => {
                if (email.isDefault) {
                  notification.error({
                    message: 'การลบล้มเหลว',
                    description: 'กรุณาเปลี่ยนค่าค่าเริ่มต้นก่อน',
                  })
                } else {
                  setEmails(
                    _.remove(_.cloneDeep(emails), (o) => o.id !== email.id),
                  )
                }
              }}
            />
          </Div>
        ))}
        <Button
          onlyIcon
          icon="fal fa-plus"
          margin="16px auto"
          inverse
          onClick={() => {
            setEmails([
              ...emails,
              {
                id: _.size(emails) > 0 ? _.maxBy(emails, 'id').id + 1 : 1,
                email: '',
                isPrimary: false,
              },
            ])
          }}
        />
      </Div>
      <Div
        display="flex"
        flexDirection="column"
        padding="8px"
        margin="8px 0"
        style={{
          border: `1px solid ${theme.color.lightBlue}`,
          borderRadius: 5,
          background: theme.color.primaryColor10,
        }}
      >
        <P bold margin="0 0 16px 0">
          ข้อมูลธนาคาร
        </P>
        <Div display="flex">
          <SmallText bold style={{ width: '100%' }} margin="0 16px 0 0">
            ธนาคาร
          </SmallText>
          <SmallText bold style={{ width: '100%' }} margin="0 16px 0 0">
            ประเภทบัญชี
          </SmallText>
          <SmallText bold style={{ width: '70%' }} margin="0 16px 0 0">
            ชื่อบัญชี
          </SmallText>
          <SmallText bold style={{ width: '70%' }} margin="0 16px 0 0">
            เลขที่บัญชี
          </SmallText>
          <Div width="31.25px" />
        </Div>
        {paymentInfo.map((bank) => (
          <Div
            key={bank.id}
            display="flex"
            alignItems="center"
            margin="0 0 16px 0"
          >
            <Select
              loading={loading}
              style={{ width: '100%', margin: '0 16px 0 0' }}
              defaultValue={!isCreateMode ? bank.bankId : undefined}
              placeholder="เลือกธนาคาร"
              onChange={(e) => setPaymentInfo(
                paymentInfo.map((v) => {
                  if (v.id === bank.id) {
                    return { ...v, bankId: e }
                  }
                  return v
                }),
              )
              }
              options={
                loading
                  ? []
                  : data.bankList.data.list.map((v) => ({
                    text: v.name,
                    value: v.id,
                  }))
              }
            />
            <Select
              loading={bankTypeLoading}
              style={{ width: '100%', margin: '0 16px 0 0' }}
              defaultValue={!isCreateMode ? bank.bankAccountType : undefined}
              placeholder="เลือกประเภทบัญชี"
              onChange={(e) => setPaymentInfo(
                paymentInfo.map((v) => {
                  if (v.id === bank.id) {
                    return { ...v, bankAccountType: e }
                  }
                  return v
                }),
              )
              }
              options={
                bankTypeLoading
                  ? []
                  : bankTypeData.bankAccountTypeList.data.map((v) => ({
                    text: mapBankTypetoText(v),
                    value: v,
                  }))
              }
            />
            <Input
              width="70%"
              margin="0 16px 0 0"
              defaultValue={!isCreateMode ? bank.bankAccountName : undefined}
              prefix={<Icon icon="prefix far fa-money-check-edit-alt" />}
              onChange={(e) => setPaymentInfo(
                paymentInfo.map((v) => {
                  if (v.id === bank.id) {
                    return { ...v, bankAccountName: e.target.value }
                  }
                  return v
                }),
              )
              }
              title={{ text: 'ชื่อบัญชี' }}
            />
            <Input
              width="70%"
              margin="0 16px 0 0"
              defaultValue={!isCreateMode ? bank.bankAccountNumber : undefined}
              prefix={<Icon icon="prefix far fa-envelope" />}
              onChange={(e) => setPaymentInfo(
                paymentInfo.map((v) => {
                  if (v.id === bank.id) {
                    return { ...v, bankAccountNumber: e.target.value }
                  }
                  return v
                }),
              )
              }
              title={{ text: 'เลขที่บัญชี' }}
            />
            <Icon
              margin="0 0 0 0"
              icon="fal fa-trash-alt"
              color={theme.color.gray80}
              style={{ cursor: 'pointer' }}
              onClick={() => setPaymentInfo(
                _.remove(_.cloneDeep(paymentInfo), (o) => o.id !== bank.id),
              )
              }
            />
          </Div>
        ))}
        <Button
          onlyIcon
          icon="fal fa-plus"
          margin="16px auto"
          inverse
          onClick={() => {
            setPaymentInfo([
              ...paymentInfo,
              {
                id:
                  _.size(paymentInfo) > 0
                    ? _.maxBy(paymentInfo, 'id').id + 1
                    : 1,
                bankAccountNumber: undefined,
                bankAccountName: undefined,
                bankAccountType: undefined,
                bankId: undefined,
              },
            ])
          }}
        />
      </Div>
      <TextareaWithLabel
        width="100%"
        onChange={(e) => setMutationVariables({
          ...mutationVariables,
          contactRemark: e.target.value,
        })
        }
        title={{
          text: 'หมายเหตุ',
          margin: '0 0 8px',
        }}
        textarea={{
          defaultValue: !isCreateMode
            ? infoData.supplierDetail.data.contactRemark
            : undefined,
          style: { width: '100%' },
          rows: 8,
          margin: '16px 0 0',
        }}
      />
      <Collapse
        text="ที่อยู่"
        margin="16px 0"
        fullHeight
        open={openCollapse.deliveryAddress}
        onClick={() => setOpenCollapse({
          ...openCollapse,
          deliveryAddress: !openCollapse.deliveryAddress,
        })
        }
      >
        <Div display="flex" flexDirection="column" padding="8px" margin="8px 0">
          {deliveryAddresses.map((address) => (
            <Div
              key={address.id}
              width="100%"
              margin="8px 0"
              display="flex"
              alignItems="flex-start"
            >
              <Checkbox
                margin="8px 16px 0 0"
                checked={address.isPrimary}
                data={{ value: `shippingAddress${address.id}` }}
                onChange={(e) => setDeliveryAddresses(
                  deliveryAddresses.map((v) => {
                    if (!address.isPrimary) {
                      if (v.id === address.id) {
                        return {
                          ...v,
                          isPrimary: e.target.checked,
                          isDefault: false,
                        }
                      }
                      return { ...v, isPrimary: false, isDefault: false }
                    }
                    return v
                  }),
                )
                }
              />
              <NewAddress
                type="shipping-not-require"
                noTitle
                data={{
                  ..._.find(deliveryAddresses, (o) => o.id === address.id),
                  zipcode: _.find(deliveryAddresses, (o) => o.id === address.id)
                    .postcode,
                  subdistrict: _.find(
                    deliveryAddresses,
                    (o) => o.id === address.id,
                  ).subDistrict,
                }}
                onChange={(key, value) => {
                  setDeliveryAddresses(
                    deliveryAddresses.map((val) => {
                      if (val.id === address.id) {
                        return { ...val, [key]: value, isDefault: false }
                      }
                      return val
                    }),
                  )
                }}
                onSelect={(obj) => {
                  setDeliveryAddresses(
                    deliveryAddresses.map((val) => {
                      if (val.id === address.id) {
                        return {
                          ...val,
                          ...obj,
                          postcode: obj.zipcode,
                          subDistrict: obj.subdistrict,
                          isDefault: false,
                        }
                      }
                      return val
                    }),
                  )
                }}
              />
              <Icon
                margin="8px 0 0 16px"
                icon="fal fa-trash-alt"
                color={theme.color.gray80}
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  if (address.isPrimary) {
                    notification.error({
                      message: 'การลบล้มเหลว',
                      description: 'กรุณาเปลี่ยนค่าค่าเริ่มต้นก่อน',
                    })
                  } else {
                    setDeliveryAddresses(
                      _.remove(
                        _.cloneDeep(deliveryAddresses),
                        (o) => o.id !== address.id,
                      ),
                    )
                  }
                }}
              />
            </Div>
          ))}
          <Button
            onlyIcon
            icon="fal fa-plus"
            margin="16px auto"
            inverse
            onClick={() => {
              setDeliveryAddresses([
                ...deliveryAddresses,
                {
                  id:
                    _.size(deliveryAddresses) > 0
                      ? _.maxBy(deliveryAddresses, 'id').id + 1
                      : 1,
                  isPrimary: false,
                },
              ])
            }}
          />
        </Div>
      </Collapse>
      <Collapse
        text={{
          render: (
            <P bold margin="0 0 4px">
              ผู้ติดต่อ <SmallText color={theme.color.error}>*</SmallText>
            </P>
          ),
        }}
        margin="16px 0"
        fullHeight
        open={openCollapse.contactAddress}
        onClick={() => setOpenCollapse({
          ...openCollapse,
          contactAddress: !openCollapse.contactAddress,
        })
        }
      >
        <Div display="flex" flexDirection="column" padding="8px" margin="8px 0">
          {contactAddresses.map((address, i) => (
            <Div
              key={address.id}
              width="100%"
              margin="8px 0"
              display="flex"
              alignItems="flex-start"
            >
              <Checkbox
                margin="8px 16px 0 0"
                checked={address.isPrimary}
                data={{ value: `contactAddress${address.id}` }}
                onChange={(e) => setContactAddresses(
                  contactAddresses.map((v) => {
                    if (!address.isPrimary) {
                      if (v.id === address.id) {
                        return {
                          ...v,
                          isPrimary: e.target.checked,
                          isDefault: false,
                        }
                      }
                      return { ...v, isPrimary: false, isDefault: false }
                    }
                    return v
                  }),
                )
                }
              />
              <Div display="flex" flexDirection="column" width="100%">
                <InputWithLabel
                  width="80%"
                  margin="8px auto"
                  key="name"
                  input={{
                    width: '100%',
                    value: address.contactName,
                    state: i === 0 && _.isEmpty(address.contactName) && 'error',
                  }}
                  onChange={(e) => setContactAddresses(
                    contactAddresses.map((val) => {
                      if (val.id === address.id) {
                        return {
                          ...val,
                          contactName: e.target.value,
                          isDefault: false,
                        }
                      }
                      return val
                    }),
                  )
                  }
                  title={{
                    text: (
                      <SmallText bold margin="0 0 4px">
                        ชื่อ-นามสกุล{' '}
                        {i === 0 && <Label color={theme.color.error}>*</Label>}
                      </SmallText>
                    ),
                  }}
                />
                <InputWithLabel
                  width="80%"
                  margin="8px auto"
                  key="officePhoneNumber"
                  input={{
                    width: '100%',
                    rule: { type: 'int' },
                    value: address.contactOfficeTel,
                    state:
                      i === 0 && _.isEmpty(address.contactOfficeTel) && 'error',
                  }}
                  onChange={(e) => setContactAddresses(
                    contactAddresses.map((val) => {
                      if (val.id === address.id) {
                        return {
                          ...val,
                          contactOfficeTel: e.target.value,
                          isDefault: false,
                        }
                      }
                      return val
                    }),
                  )
                  }
                  title={{
                    text: (
                      <SmallText bold margin="0 0 4px">
                        เบอร์ออฟฟิศ{' '}
                        {i === 0 && <Label color={theme.color.error}>*</Label>}
                      </SmallText>
                    ),
                  }}
                />
                <InputWithLabel
                  width="80%"
                  margin="8px auto"
                  key="phoneNumber"
                  input={{
                    width: '100%',
                    rule: { type: 'int' },
                    value: address.contactPhoneNumber,
                    state:
                      i === 0
                      && _.isEmpty(address.contactPhoneNumber)
                      && 'error',
                  }}
                  onChange={(e) => setContactAddresses(
                    contactAddresses.map((val) => {
                      if (val.id === address.id) {
                        return {
                          ...val,
                          contactPhoneNumber: e.target.value,
                          isDefault: false,
                        }
                      }
                      return val
                    }),
                  )
                  }
                  title={{
                    text: (
                      <SmallText bold margin="0 0 4px">
                        เบอร์มือถือ{' '}
                        {i === 0 && <Label color={theme.color.error}>*</Label>}
                      </SmallText>
                    ),
                  }}
                />
                <InputWithLabel
                  width="80%"
                  margin="8px auto"
                  key="faxNumber"
                  input={{
                    width: '100%',
                    value: address.contactFaxNumber,
                    rule: { type: 'int' },
                    state: 'default',
                  }}
                  onChange={(e) => setContactAddresses(
                    contactAddresses.map((val) => {
                      if (val.id === address.id) {
                        return {
                          ...val,
                          contactFaxNumber: e.target.value,
                          isDefault: false,
                        }
                      }
                      return val
                    }),
                  )
                  }
                  title={{
                    text: (
                      <SmallText bold margin="0 0 4px">
                        Fax
                      </SmallText>
                    ),
                  }}
                />
                <InputWithLabel
                  width="80%"
                  margin="8px auto"
                  key="email"
                  input={{
                    width: '100%',
                    value: address.contactEmail,
                    state:
                      i === 0 && _.isEmpty(address.contactEmail) && 'error',
                  }}
                  onChange={(e) => setContactAddresses(
                    contactAddresses.map((val) => {
                      if (val.id === address.id) {
                        return {
                          ...val,
                          contactEmail: e.target.value,
                          isDefault: false,
                        }
                      }
                      return val
                    }),
                  )
                  }
                  title={{
                    text: (
                      <SmallText bold margin="0 0 4px">
                        อีเมล์{' '}
                        {i === 0 && <Label color={theme.color.error}>*</Label>}
                      </SmallText>
                    ),
                  }}
                />
                <InputWithLabel
                  width="80%"
                  margin="8px auto"
                  key="line"
                  input={{ width: '100%', value: address.contactLineId }}
                  onChange={(e) => setContactAddresses(
                    contactAddresses.map((val) => {
                      if (val.id === address.id) {
                        return {
                          ...val,
                          contactLineId: e.target.value,
                          isDefault: false,
                        }
                      }
                      return val
                    }),
                  )
                  }
                  title={{ text: 'Line Id' }}
                />
                <InputWithLabel
                  width="80%"
                  margin="8px auto"
                  key="facebook"
                  input={{ width: '100%', value: address.contactFacebook }}
                  onChange={(e) => setContactAddresses(
                    contactAddresses.map((val) => {
                      if (val.id === address.id) {
                        return {
                          ...val,
                          contactFacebook: e.target.value,
                          isDefault: false,
                        }
                      }
                      return val
                    }),
                  )
                  }
                  title={{ text: 'Facebook' }}
                />
                <Divider />
              </Div>
              <Icon
                margin="8px 0 0 16px"
                icon="fal fa-trash-alt"
                color={theme.color.gray80}
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  if (address.isPrimary) {
                    notification.error({
                      message: 'การลบล้มเหลว',
                      description: 'กรุณาเปลี่ยนค่าค่าเริ่มต้นก่อน',
                    })
                  } else {
                    setContactAddresses(
                      _.remove(
                        _.cloneDeep(contactAddresses),
                        (o) => o.id !== address.id,
                      ),
                    )
                  }
                }}
              />
            </Div>
          ))}
          <Button
            onlyIcon
            icon="fal fa-plus"
            margin="16px auto"
            inverse
            onClick={() => {
              setContactAddresses([
                ...contactAddresses,
                {
                  id:
                    _.size(contactAddresses) > 0
                      ? _.maxBy(contactAddresses, 'id').id + 1
                      : 1,
                  isPrimary: false,
                },
              ])
            }}
          />
        </Div>
      </Collapse>
      <Button
        disabled={
          mutationLoading
          || validateData(isCreateMode, baseRequiredFields, allFields)
          // || (isCreateMode && _.some(paymentInfo, o => _.some(_.values(o), v => _.isUndefined(v))))
        }
        loading={mutationLoading}
        inverse
        color={theme.color.success}
        text={isCreateMode ? 'สร้างผู้ผลิต' : 'แก้ไขผู้ผลิต'}
        icon={isCreateMode ? 'far fa-plus' : 'far fa-edit'}
        style={{ alignItems: 'center' }}
        width="150px"
        margin="20px auto"
        onClick={() => {
          const shippingAddressData = addressMutationData(deliveryAddresses, 'shipping')
          const contactAddressData = addressMutationData(contactAddresses, 'contact')
          const createVariables = _.omitBy(
            {
              ...mutationVariables,
              paymentInfo: paymentInfo.map((v) => _.omit(v, ['id'])),
              contactPhoneNumber: phones.map((v) => _.omit(v, ['id'])),
              contactEmail: emails.map((v) => _.omit(v, ['id'])),
              shippingAddress: _.isEmpty(shippingAddressData)
                ? null
                : shippingAddressData,
              contactAddress: _.isEmpty(contactAddressData)
                ? null
                : contactAddressData,
            },
            _.isNull,
          )
          doAction({
            variables: isCreateMode
              ? createVariables
              : {
                ...createVariables,
                paymentInfo: paymentInfo.map((v) => _.omit(v, ['id'])),
                id: parseInt(props.match.params.id),
              },
          }).then((resp) => {
            const KEY = isCreateMode ? 'createSupplier' : 'updateSupplier'
            if (resp.data[KEY].success) {
              message.success(resp.data[KEY].message)
              history.push('/suppliers')
            } else message.error(resp.data[KEY].message)
          })
        }}
      />
    </Div>
  )
}

export default (props) => {
  const isCreateMode = _.includes(props.match.url, 'create')
  const { data, loading } = useQuery(BANKS)
  const { data: bankTypeData, loading: bankTypeLoading } = useQuery(GET_BANK_TYPE)
  const [doAction, { loading: mutationLoading }] = useMutation(
    isCreateMode ? CREATE_SUPPLIER : UPDATE_SUPPLIER,
  )
  const { loading: infoLoading, data: infoData } = useQuery(SUPPLIER, {
    skip: isCreateMode,
    variables: { id: parseInt(props.match.params.id) },
  })
  // const requiredFields = mutationVariables.paymentType === PAYMENT_TYPE.CASH ? baseRequiredFields : [...baseRequiredFields, mutationVariables.creditTerm, mutationVariables.creditTerm, mutationVariables.creditTerm]
  const authorization = useContext(AuthorizationContext)
  const supplierAutho = authorization.authorization?.supplier || {}
  useEffect(() => {
    if (
      (isCreateMode && !supplierAutho.create)
      || (!isCreateMode && !supplierAutho.editBasic)
    ) {
      permissionRedirect()
    }
  }, [])
  return (
    <Div>
      <Helmet>
        <meta name="viewport" content="width=1280" />
      </Helmet>
      <FullPageContainer
        loading={infoLoading}
        title={[
          {
            text: 'Suppliers',
            icon: 'fad fa-user-tie',
          },
        ]}
        detail={{
          title: [
            {
              text: isCreateMode ? 'สร้างผู้ผลิตใหม่' : 'แก้ไขผู้ผลิตใหม่',
              icon: isCreateMode ? 'far fa-plus' : 'far fa-edit',
            },
          ],
        }}
      >
        {!infoLoading ? (
          !isCreateMode && _.isNull(infoData.supplierDetail.data) ? (
            <H4>ไม่พบ</H4>
          ) : (
            <Render
              loading={loading}
              isCreateMode={isCreateMode}
              infoData={infoData}
              data={data}
              bankTypeLoading={bankTypeLoading}
              bankTypeData={bankTypeData}
              mutationLoading={mutationLoading}
              doAction={doAction}
              props={props}
              supplierAutho={supplierAutho}
            />
          )
        ) : null}
      </FullPageContainer>
    </Div>
  )
}
