import React from 'react'
import _ from 'lodash'
import { Div, H3, H4 } from '@konsys-ui-custom'
import moment from 'moment-timezone'
import { theme } from '../../styles/_variables'
import { TextBetween } from '../../components'
import { STATUS } from './constants'

export default ({ data }) => (
  <Div>
    <Div display='flex' alignItems='center' width='100%' justifyContent='space-between' margin='0 0 16px 0'>
      <H3 color={theme.color.primaryColor}>ข้อมูลผู้ใช้</H3>
      <H4>ID: {data.id}</H4>
    </Div>
    <Div display='flex' alignItems='center' width='100%' justifyContent='flex-end' margin='0 0 16px 0'>
      <Div>
        <H4 margin='0 8px 0 0'>สถานะ:</H4>
        {/* <Log data={{ data }}/> */}
        <H4 bold color={data.status === STATUS.ACTIVE ? theme.color.success : theme.color.error}>{data.status === STATUS.ACTIVE ? 'เปิดการใช้งาน' : 'ปิดการใช้งาน'}</H4>
      </Div>
    </Div>
    <TextBetween
      title={{
        margin: '8px 0',
        text: 'ชื่อ',
        // icon: 'far fa-acorn',
        // color: '#DAAF71',
      }}
      content={{
        margin: '8px 0',
        text: data.firstName,
        color: '#968595',
        // icon: 'far fa-alicorn',
        // bold: true,
      }}
    />
    <TextBetween
      title={{
        margin: '8px 0',
        text: 'นามสกุล',
      }}
      content={{
        margin: '8px 0',
        text: data.lastName,
        color: '#968595',
      }}
    />
    <TextBetween
      title={{
        margin: '8px 0',
        text: 'ทีม',
      }}
      content={{
        margin: '8px 0',
        text: data.team?.teamName || '-',
        color: '#968595',
      }}
    />
    <TextBetween
      title={{
        margin: '8px 0',
        text: 'Email',
      }}
      content={{
        margin: '8px 0',
        text: data.email,
        color: '#968595',
      }}
    />
    <TextBetween
      title={{
        margin: '8px 0',
        text: 'Phone Number',
      }}
      content={{
        margin: '8px 0',
        text: data.phoneNumber,
        color: '#968595',
      }}
    />
    <TextBetween
      title={{
        margin: '8px 0',
        text: 'ตำแหน่ง',
      }}
      content={{
        margin: '8px 0',
        text: !_.isEmpty(data.roles) && data.roles[0].description,
        color: '#968595',
      }}
    />
    {
      data && !_.isEmpty(data.roles) && !_.isEmpty(data.warehouse) && (data.roles[0].id === 5 || data.roles[0].id === 3)
      && <TextBetween
        title={{
          margin: '8px 0',
          text: 'คลังสินค้าที่ดูแล',
        }}
        content={{
          margin: '8px 0',
          text: data.warehouse.warehouseName,
          color: '#968595',
        }}
      />
    }
    <TextBetween
      title={{
        margin: '8px 0',
        text: 'วันที่สร้าง',
      }}
      content={{
        margin: '8px 0',
        text: moment.tz(data.createdAt, 'Asia/Bangkok').format('DD MMMM YYYY'),
        color: '#968595',
      }}
    />
    <TextBetween
      title={{
        margin: '8px 0',
        text: 'แก้ไขล่าสุด',
      }}
      content={{
        margin: '8px 0',
        text: moment.tz(data.updatedAt, 'Asia/Bangkok').format('DD MMMM YYYY'),
        color: '#968595',
      }}
    />
  </Div>
)
