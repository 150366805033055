import React, { useState } from 'react'
import _ from 'lodash'
import {
  Div, P, SmallText, Label, Input, Icon, Button,
} from '@konsys-ui-custom'
import { theme } from '../../styles/_variables'
import {
  CheckboxRowContainer, CheckboxImage, CollapseDetailContainer, StepContainer, StepTitleContainer, BadgeContainer,
} from './styled'

export const SmallTextWithTitle = ({
  title, text, style,
}) => (
  <Div moreStyle={{ ...style, wordBreak: 'break-all' }}>
    <Label display='block' fontSize='10px' color={theme.color.gray50} moreStyle={{ lineHeight: '14px' }}>{title}</Label>
    <Label display='block' bold>{text}</Label>
  </Div>
)

export const CheckboxRow = ({
  data, margin, active, onChange, id, type,
}) => (
  <Div margin={margin}>
    <Input style={{ display: 'none' }} type='checkbox' id={id} onChange={(e) => onChange(e.target.checked)} />
    <CheckboxRowContainer active={active} htmlFor={id}>
      <CheckboxImage src={data?.src} />
      <Div margin='0 24px 0 24px' moreStyle={{ flexBasis: '20%' }}>
        <P bold display='block'>{data?.name}</P>
        <SmallText>{data?.brand}</SmallText>
      </Div>
      <SmallTextWithTitle title='รหัสสินค้า' text={data?.sku} style={{ margin: _.isEmpty(data?.waitingAmount) ? '0 24px 0 0' : '0 8px 0 0', width: _.isEmpty(data?.waitingAmount) ? !_.isEmpty(data?.supplier) ? '70px' : '100px' : '62px' }} />
      {
        !_.isEmpty(data?.waitingAmount) && <SmallTextWithTitle title='จำนวนรอสั่งซื้อ' text={data?.waitingAmount} style={{ margin: '0 32px 0 0', width: '73px' }} />
      }
      {
        !_.isEmpty(data?.warehouse) && <SmallTextWithTitle title='คลังสินค้า' text={data?.warehouse} style={{ margin: '0 16px 0 0', width: '62px' }} />
      }
      <SmallTextWithTitle title='จำนวนสินค้า' text={data?.amount} style={{ margin: type === 'so' ? '0 8px 0 0' : '0 16px 0 0', width: '80px' }} />
      <SmallTextWithTitle title='จำนวนสินค้าที่ขายได้' text={data?.sellAmount} style={{ margin: !_.isEmpty(data?.minimumStock) ? type === 'so' ? '0 8px 0 0' : '0 16px 0 0' : '0', width: '100px' }} />
      {
        !_.isEmpty(data?.minimumStock) && <SmallTextWithTitle title='สินค้าขั้นต่ำในคลัง' text={data?.minimumStock} style={{ width: '100px' }} />
      }
      {
        !_.isEmpty(data?.supplier) && <SmallTextWithTitle title='Supplier' text={!_.isArray(data?.supplier) ? data?.supplier?.name : _.reduce(data?.supplier, (allName, supplierData) => {
          if (_.isEmpty(allName)) return supplierData.name
          return `${allName}, ${supplierData.name}`
        }, '')} style={{ width: type === 'so' ? '55px' : '80px', margin: '0 0 0 16px' }} />
      }
    </CheckboxRowContainer>
  </Div>
)

export const CollapseBox = ({
  contactName, children, soDetail, margin, isOpenCollapse,
}) => {
  const [openCollapse, setOpenCollapse] = useState(isOpenCollapse)
  return (
    <Div margin={margin}>
      <Div bgColor={theme.color.primaryColor10} padding='16px 24px' display='flex' justifyContent='space-between' alignItems='center' moreStyle={{ cursor: 'pointer' }} onClick={() => setOpenCollapse(!openCollapse)}>
        <Div>
          <SmallText bold>{contactName}</SmallText>
          {
            !_.isEmpty(soDetail) && <SmallText bold color={theme.color.primaryColor} margin='0 0 0 16px'>{soDetail}</SmallText>
          }
        </Div>
        <Icon icon='fal fa-chevron-up' style={{ transform: openCollapse ? 'rotate(0deg)' : 'rotate(180deg)', transition: 'all 0.4s ease-in-out' }} />
      </Div>
      <CollapseDetailContainer open={openCollapse}>
        {
          children
        }
      </CollapseDetailContainer>
    </Div>
  )
}

const StepTitle = ({
  data, active, passed, margin,
}) => (
  <StepTitleContainer margin={margin} moreStyle={active ? { transition: 'all 0.3s ease-in-out', flexGrow: 1 } : { transition: 'all 0.3s ease-in-out' }}>
    <Div bgColor={passed ? theme.color.primaryColor10 : active ? theme.color.greyBlue : theme.color.offWhite} border={`2px solid ${passed ? theme.color.primaryColor10 : active ? theme.color.greyBlue : theme.color.lightBlue}`} borderRadius='30px' moreStyle={{ width: '50px', height: '50px' }} display='flex' alignItems='center' justifyContent='center'>
      <Icon fontSize='20px' icon={passed ? 'far fa-check' : data?.icon} color={active ? theme.color.primaryColor10 : theme.color.lightBlue} />
    </Div>
    {
      active && <P style={{ backgroundColor: theme.color.offWhite }} bold padding='0 16px' color={theme.color.greyBlue}>{data?.text}</P>
    }
  </StepTitleContainer>
)

const Badge = ({ text }) => (
  <BadgeContainer>
    <Icon color={theme.color.offWhite} fontSize={theme.fonts.size.small} icon='fal fa-shopping-basket' />
    <Label margin='0 0 0 6px' color={theme.color.offWhite}>{text}</Label>
  </BadgeContainer>
)

export const ButtonWithBadge = ({ margin, button, badge }) => (
  <Div display='inline-block' position='relative' margin={margin}>
    <Button {...button} />
    {
      !_.isEmpty(badge) && <Badge {...badge} />
    }
  </Div>
)

export const Step = ({ list, currentStep }) => (
  <StepContainer>
    {
      list?.map((data, i) => (
        <StepTitle margin={i !== 0 ? '0 0 0 32px' : '0'} key={data?.id} data={data} active={currentStep === i + 1} passed={currentStep > i + 1} />
      ))
    }
  </StepContainer>
)

export default CheckboxRow
