import React, { useEffect, useState } from 'react'
import numeral from 'numeral'
import { isEmpty, keys } from 'lodash'
import { ResponsivePie } from '@nivo/pie'
import { Div, P, SmallText } from '@konsys-ui-custom'
import { useLazyQuery } from '@apollo/client'
import { Radio, Space } from 'antd'
import { TOP_CHATS, TOP_CUSTOMERS, TOP_PRODUCTS, SALES_BY_PROVINCE } from '../../../api/query'
import { Loading } from '../../../components'
import { theme } from '../../../styles/_variables'

const FILTER_TYPE = {
  SALES_PRICE: {
    key: 'SALES_PRICE',
    text: 'บาท',
  },
  QUANTITY: {
    key: 'QUANTITY',
    text: 'ชิ้น',
  },
}

export default ({ type, date, provider, userId, teamId }) => {
  console.info({ type, date, provider, userId, teamId })
  const [vars, setVars] = useState({
    productFilterType: FILTER_TYPE?.SALES_PRICE?.key,
  })
  const [getTopChats, { data: topChatsData, loading: getting }] = useLazyQuery(TOP_CHATS)
  const [getTopCustomers, { data: topCustomersData, loading: customerLoading }] = useLazyQuery(TOP_CUSTOMERS)
  const [getTopProducts, { data: topProductsData, loading: productLoading }] = useLazyQuery(TOP_PRODUCTS)
  const [getSalesByProvince, { data: salesProvinceData, loading: provinceLoading }] = useLazyQuery(SALES_BY_PROVINCE)

  const getNormalVariables = () => (
    {
      chatProviderId: provider === 'all' ? undefined : provider,
      period: date,
      teamId: teamId === 'all' ? undefined : teamId,
    }
  )

  const callGetSalesByProvince = () => {
    getSalesByProvince({
      variables: {
        input: {
          ...getNormalVariables(),
          sellerId: userId === 'all' ? undefined : userId,
        },
      },
    })
  }

  const callGetTopChats = () => {
    getTopChats({
      variables: {
        input: getNormalVariables(),
      },
    })
  }

  const callGetTopCustomers = () => {
    getTopCustomers({
      variables: {
        input: {
          ...getNormalVariables(),
          sellerId: userId === 'all' ? undefined : userId,
        },
      },
    })
  }

  const callGetTopProducts = () => {
    getTopProducts({
      variables: {
        input: {
          ...getNormalVariables(),
          sellerId: userId === 'all' ? undefined : userId,
          type: vars?.productFilterType,
        },
      },
    })
  }

  const isGetCustomer = userId !== 'all'
  const isProduct = type === 'product'
  const isProvince = type === 'province'

  useEffect(() => {
    if (isProduct) {
      callGetTopProducts()
    } else if (isGetCustomer) {
      callGetTopCustomers()
    } else if (isProvince) {
      callGetSalesByProvince()
    } else {
      callGetTopChats()
    }
  }, [type, userId, provider, date, vars?.productFilterType, teamId])

  const list = (isProvince ? salesProvinceData?.salesByProvince?.data : isProduct ? topProductsData?.TopProducts?.data : isGetCustomer ? topCustomersData?.TopCustomers?.data : topChatsData?.TopChats?.data) || []
  const data = list?.map((detail) => (
    {
      id: `${detail?.key} ${isGetCustomer ? 'ยอดซื้อ ' : ''}${numeral(detail?.value).format('0,0')} ${(isProduct && vars?.productFilterType === FILTER_TYPE?.QUANTITY?.key) ? 'ชิ้น' : (isProvince || isGetCustomer || (isProduct && vars?.productFilterType === FILTER_TYPE?.SALES_PRICE?.key)) ? 'บาท' : 'แชต'}`,
      value: detail?.value,
    }
  ))

  const pieColors = ['#CD77EB', '#3677EA', '#60C7E6', '#F14459', '#3ECB98', '#FFBF31']
  const commonProps = {
    data,
    colors: pieColors,
    margin: { top: 40, bottom: 40 },
    innerRadius: 0.7,
    arcLinkLabelsSkipAngle: 10,
    arcLinkLabelsTextColor: '#333333',
    arcLinkLabelsThickness: 2,
    arcLinkLabelsColor: { from: 'color' },
    enableArcLabels: false,
    arcLinkLabelsDiagonalLength: 6,
    tooltip: ({ datum: { id, color } }) => (
      <Div display='flex' alignItems='center' bgColor={theme?.color?.offWhite} padding='4px 8px' moreStyle={{ boxShadow: `0 1px 4px ${theme?.color?.gray50}` }}>
        <Div width='12px' height='12px' bgColor={color}></Div>
        <SmallText margin='0 0 0 8px'>{id}</SmallText>
      </Div>
    ),
  }
  return (
    <Div>
      <P bold display='block' margin='0 0 16px'>{isProvince ? 'ยอดขายตามจังหวัด 5 อันดับ' : isProduct ? 'สินค้า 5 อันดับ' : isGetCustomer ? 'ยอดซื้อ 5 อันดับ' : 'แอดมิน 5 อันดับ'}</P>
      <Div display='flex'>
        <Div margin='0 auto' moreStyle={{
          maxWidth: isProduct ? 'calc(100% - calc((100vw * 200)/1440))' : '100%', textAlign: 'center', height: '400px', flexGrow: 1,
        }}>
          {
            (getting || customerLoading || productLoading || provinceLoading)
              ? <Loading margin='80px 0' />
              : isEmpty(data)
                ? <P margin='80px 0' color={theme?.color?.gray50}>ไม่มีข้อมูล</P>
                : <ResponsivePie {...commonProps} />
          }
        </Div>
        {
          isProduct
          && <Div padding='8px 16px 0 32px' width='calc((100vw * 200)/1440)'>
            <P bold display='block' margin='0 0 16px'>กรองตาม</P>
            <Radio.Group vertical onChange={(e) => setVars({ ...vars, productFilterType: e.target.value })} value={vars?.productFilterType}>
              <Space direction="vertical">
                {
                  keys(FILTER_TYPE)?.map((filterKey) => (
                    <Radio key={FILTER_TYPE?.[filterKey]?.key} value={FILTER_TYPE?.[filterKey]?.key}><P>{FILTER_TYPE?.[filterKey]?.text}</P></Radio>
                  ))
                }
              </Space>
            </Radio.Group>
          </Div>
        }
      </Div>
    </Div>
  )
}
