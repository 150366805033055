import { ApolloProvider, useQuery } from '@apollo/client'
import _ from 'lodash'
import React, { useContext, useEffect } from 'react'
import {
  Redirect,
  Route,
  BrowserRouter as Router,
  Switch,
} from 'react-router-dom'
import client from './apollo-client'
import { MainContainer } from './components/container'
import { ContentWrapper } from './components/container/styled'
import { ForgotPasswordSection, ResetSection } from './components/login/reset-password'
import Menu from './components/menu'
import history from './history'
import LandingPage from './pages/landing'
import Login from './pages/login'
import { GET_PERMISSIONS } from './api/query'
import { LoadingPage } from './components'
import { PATH } from './constants/path'
import Commissions from './pages/commissions'
import Dashbaord from './pages/dashboard'
import Facebook from './pages/facebook'
import PricingTiers from './pages/pricing-tiers/form'
import Reports from './pages/reports/form'
import Tag from './pages/tag'
import UnMatchedSalesOrder from './pages/unmatched-sale-orders'
import UserPagePermissions from './pages/user-page-permissions'
import {
  AccountRoute,
  CustomersRoute,
  GoodReceiveRoute,
  InventoryRoute,
  PickingTicketsRoute,
  ProductGroupRoute,
  PurchaseOrderRoute,
  RolesRoute,
  SaleOrdersRoute,
  SuppliersRoute,
  TaxInvoiceRoute,
  UsersRoute,
  WarehousesRoute,
} from './routes'
import { AuthorizationContext, RoleContext } from './store/StoreProvider'
import { getAccessToken } from './utils/local-data-service'

history.listen(() => {
  window.scrollTo(0, 0)
})

const SwitchPath = ({ refetch, authorization }) => (
  <Switch>
    <Route path='/' exact component={LandingPage} />
    <Route path='/home' exact component={LandingPage} />
    {authorization?.user.view && <Route path='/users' component={UsersRoute} />}
    {authorization?.supplier.view && <Route path='/suppliers' component={SuppliersRoute} />}
    {authorization?.warehouse.view && <Route path='/warehouses' component={WarehousesRoute} />}
    {authorization?.inventory.view && <Route path='/inventory' component={InventoryRoute} />}
    {authorization?.client.view && <Route path='/customers' component={CustomersRoute} />}
    {authorization?.salesOrder.view && <Route path='/sale-orders' render={(propsData) => <SaleOrdersRoute {...propsData} refetch={() => refetch && refetch()} />} />}
    {authorization?.unMatchedSalesOrder.all && <Route path='/unmatched-sale-orders' render={(propsData) => <UnMatchedSalesOrder {...propsData} refetch={() => refetch && refetch()} />} />}
    {authorization?.purchaseOrder.view && <Route path={PATH.poList} component={PurchaseOrderRoute} />}
    {authorization?.goodsReceive.view && <Route path={PATH.gr} component={GoodReceiveRoute} />}
    {authorization?.client.createPricingTier && <Route path='/pricing-tiers' component={PricingTiers} />}
    {authorization?.taxInvoice.all && <Route path={PATH.taxInvoice} component={TaxInvoiceRoute} />}
    {authorization?.pickingTicket.view && <Route path={PATH.pt} component={PickingTicketsRoute} />}
    {authorization?.commission.all && <Route path={PATH.COMMISSIONS} component={Commissions} />}
    {(authorization?.taxInvoice.all || authorization?.cashReceipt.view) && <Route path={PATH.receipts.name} component={AccountRoute} />}
    {authorization?.permission.view && <Route path={PATH.roles} component={RolesRoute} />}
    {authorization?.facebook.all && <Route path={PATH.USER_PAGE_PERMISSIONS} component={UserPagePermissions} />}
    {authorization?.productSet.view && <Route path='/product-groups' render={(propsData) => <ProductGroupRoute {...propsData} refetch={() => refetch()} />} />}
    {authorization?.report.all && <Route path='/reports' component={Reports} />}
    {authorization?.tag.all && <Route path={PATH.TAG} component={Tag} />}
    {authorization?.report.all && <Route path='/dashboard' component={Dashbaord} />}
    {authorization?.facebook.all && <Route path={PATH.FACEBOOK} component={Facebook}/>}
    <Redirect from='*' to='/' />
  </Switch>
)

const Landing = (props) => {
  const { updateAuthorization } = useContext(AuthorizationContext)
  const { updateRole } = useContext(RoleContext)
  const getPermissions = useQuery(GET_PERMISSIONS, { skip: !getAccessToken() })
  useEffect(() => {
    if (getAccessToken() && !getPermissions.loading) {
      updateAuthorization(getPermissions?.data?.myRole?.data?.permissions)
      updateRole(getPermissions?.data?.myRole?.data?.name)
    }
  }, [getPermissions.loading])
  if (!getAccessToken()) return props.children
  if (getPermissions.loading) return <LoadingPage />
  return props.children
}

const App = () => {
  const { authorization } = useContext(AuthorizationContext)
  const permissions = _.filter(
    [
      'home', 'users', 'suppliers', 'purchase-orders', 'warehouses', 'inventory', 'customers', 'sale-orders', 'picking-tickets', 'components', 'pricing-tiers', 'good-receives',
      'account', 'inventoryStructure', 'roles', 'product-groups', 'reports', 'tax-invoice', 'commissions', 'dashboard', 'tag', 'facebook', 'userPagePermissions', 'unmatched-sale-orders',
    ],
    (o) => {
      switch (o) {
        case 'home': return true
        case 'users': return authorization?.user.view
        case 'suppliers': return authorization?.supplier.view
        case 'purchase-orders': return authorization?.purchaseOrder.view
        case 'warehouses': return authorization?.warehouse.view
        case 'inventory': return authorization?.inventory.view
        case 'customers': return authorization?.client.view
        case 'sale-orders': return authorization?.salesOrder.view
        case 'unmatched-sale-orders': return authorization?.unMatchedSalesOrder.all
        case 'picking-tickets': return authorization?.pickingTicket.view
        case 'components': return true
        case 'pricing-tiers': return authorization?.client.createPricingTier
        case 'good-receives': return authorization?.goodsReceive.view
        case 'account': return authorization?.depositReceipt.view || authorization?.invoice.view || authorization?.bill.view || authorization?.cashReceipt.view || authorization?.creditReceipt.view || authorization?.paymentNote.view
        case 'inventoryStructure': return authorization?.productStructure.view
        case 'roles': return authorization?.permission.view
        case 'product-groups': return authorization?.productSet.view
        // case 'reports': return false
        case 'reports': return authorization?.report.all
        case 'tax-invoice': return authorization?.taxInvoice.all || authorization?.cashReceipt.view
        case 'commissions': return authorization?.commission.all
        case 'userPagePermissions': return authorization?.facebook.all
        // case 'dashboard': return false
        case 'dashboard': return authorization?.report.all
        case 'tag': return authorization?.tag.all
        case 'facebook': return authorization?.facebook.all
        default: return false
      }
    },
  )
  return (
    <ApolloProvider client={client}>
      <Landing>
        <Router history={history}>
          {
            getAccessToken()
              ? <MainContainer>
                <Menu menu={permissions}>
                  <ContentWrapper>
                    <SwitchPath authorization={authorization} />
                  </ContentWrapper>
                </Menu>
              </MainContainer>
              : <Switch>
                <Route path={PATH.login} exact component={Login} />
                <Route path={PATH.forgotPassword} exact component={ForgotPasswordSection} />
                <Route path={`${PATH.resetPassword}/:token`} exact component={ResetSection} />
                <Redirect from='*' to={PATH.login} />
              </Switch>
          }
        </Router>
      </Landing>
    </ApolloProvider>
  )
}

export default App
