import React from 'react'
import numeral from 'numeral'
import moment from 'moment'
import {
  Div, SmallText, P, H4, Icon, Label,
} from 'konsys-ui'
import _ from 'lodash'
import { theme } from '../../../styles/_variables'
import URL from '../../../constants/environment'

const QUOTATION_STATUS = [
  {
    value: 'WAITING_ADDRESS',
    text: 'รอยืนยันที่อยู่',
  },
  {
    value: 'WAITING_PAYMENT',
    text: 'รอชำระเงิน',
  },
  {
    value: 'WAITING_PAYMENT_CONFIRM',
    text: 'รอยืนยันการชำระเงิน',
  },
  {
    value: 'WAITING_CREATE_SO',
    text: 'รอส่งออเดอร์',
  },
  {
    value: 'WAITING_DELIVERED',
    text: 'สร้างออเดอร์แล้ว',
  },
  {
    value: 'DELIVERED',
    text: 'สำเร็จแล้ว',
  },
  {
    value: 'SO_CANCELLED',
    text: 'ยกเลิก SO',
  },
]

export const SendMessage = ({
  msg, type,
}) => (
  <Div margin='8px 0' flexDirection='column' display='flex' width='100%' justifyContent='flex-end'>
    <Div flexDirection='row' display='flex' width='100%' justifyContent='flex-end'>
      <Div width='100%' display='flex' alignItems='flex-end' justifyContent='flex-end'>
        {
          msg?.sendedMessage
            ? msg?.sendedMessage?.text
              ? <Div padding='16px 24px' moreStyle={{ boxShadow: '0px 4px 24px 0px rgba(0, 0, 0, 0.06)', borderRadius: '12px 12px 0 12px', maxWidth: '60%' }}>
                <P style={{ wordBreak: 'break-all', whiteSpace: 'pre-line' }}>{msg?.sendedMessage?.text}</P>
              </Div>
              : msg?.sendedMessage?.type === 'audio'
                ? <Div padding='16px 24px' moreStyle={{ boxShadow: '0px 4px 24px 0px rgba(0, 0, 0, 0.06)', borderRadius: '12px 12px 0 12px', maxWidth: '60%' }}>
                  <P style={{ wordBreak: 'break-all', whiteSpace: 'pre-line' }}>Send Audio.</P>
                </Div>
                : <img src={msg?.sendedMessage?.url} style={{
                  maxWidth: '75%', maxHeight: '500px', alignSelf: 'flex-end', cursor: 'pointer',
                }}
                />
            : type === 'MESSENGER' && msg?.sendedFlexMessage
              ? <Div display='flex' flexDirection='column'>
                <Div bgColor={msg?.sendedFlexMessage.isNewVersion && '#F0F2F580'} moreStyle={{
                  boxShadow: '0px 4px 24px 0px rgba(0, 0, 0, 0.06)', borderRadius: '18px', overflow: 'hidden', background: theme?.color?.offWhite,
                }}>
                  {
                    (_.isNull(msg?.sendedFlexMessage.isNewVersion) || !msg?.sendedFlexMessage.isNewVersion) && !_.isEmpty(msg?.sendedFlexMessage?.imageUrl) && <img src={msg?.sendedFlexMessage?.imageUrl} style={{ width: '100%', maxWidth: '230px' }} />
                  }
                  <Div padding='8px 14px 10px' moreStyle={{ borderBottom: '1px solid #EEEEEE' }}>
                    <P display='block' bold margin='0 0 4px'>{msg?.sendedFlexMessage?.refId}</P>
                    <SmallText display='block' color='#8C8C8D'>item: {msg?.sendedFlexMessage?.quantity} items</SmallText>
                    <SmallText display='block' color='#8C8C8D'>total: {numeral(msg?.sendedFlexMessage?.totalPrice).format('0,0.00')} Baht</SmallText>
                    {
                      msg?.sendedFlexMessage?.itemList?.map((v, i) => (
                        <SmallText key={i} display='block' color='#8C8C8D'>{i + 1}.{v.itemName}: ฿{numeral(v.totalPrice).format('0,0.00')}</SmallText>
                      ))
                    }
                    <SmallText display='block' color='#8C8C8D'>ค่าขนส่ง: ฿{numeral(msg?.sendedFlexMessage?.deliveryCost).format('0,0.00')}</SmallText>
                  </Div>
                  <SmallText style={msg?.sendedFlexMessage.isNewVersion && { backgroundColor: '#FFFFFF', borderRadius: '8px', padding: '8px 0' }} bold color='#26AAF9' margin='10.5px 14px 12px' display='block' textAlign='center'>View Details</SmallText>
                </Div>
              </Div>
              : msg.message
                ? <Div padding='16px 24px' moreStyle={{ boxShadow: '0px 4px 24px 0px rgba(0, 0, 0, 0.06)', borderRadius: '12px 12px 0 12px', maxWidth: '60%' }}>
                  <P style={{ wordBreak: 'break-all', whiteSpace: 'pre-line' }}>{msg?.message}</P>
                </Div>
                : msg.url
                  ? <img src={msg.url} style={{
                    maxWidth: '75%', maxHeight: '500px', alignSelf: 'flex-end', cursor: 'pointer',
                  }}
                  />
                  : !msg?.sendedFlexMessage.isNewVersion
                    ? <Div display='flex' flexDirection='column'>
                      <Div padding='16px 80px 24px 24px' display='flex' flexDirection='column' moreStyle={{ backgroundColor: theme.color.primaryColor, borderRadius: '12px 12px 0 0' }}>
                        <H4 margin='0 0 8px' color={theme.color.offWhite}>{msg?.sendedFlexMessage?.refId}</H4>
                        <P margin='0 0 4px' color={theme.color.offWhite}>สินค้า {msg?.sendedFlexMessage?.quantity} รายการ</P>
                        <P margin={msg.sendedFlexMessage?.paymentType === 'CASH' ? '0' : '2px 0'} color={theme.color.offWhite}>{`${msg.sendedFlexMessage?.paymentType === 'CASH' ? 'ยอดชำระ' : 'ยอดทั้งหมด'} ${numeral(msg?.sendedFlexMessage?.totalPrice).format('0,0.00')} บาท`}</P>
                        {
                          !(msg.sendedFlexMessage?.paymentType === 'CASH') && <SmallText color={theme.color.offWhite}>(ชำระด้วยเครดิต)</SmallText>
                        }
                      </Div>
                      <Div padding='16px 24px 24px' display='flex' flexDirection='column' moreStyle={{ border: `solid 1px ${theme.color.secondaryColor}`, borderRadius: '0 0 0 12px' }}>
                        {
                          msg?.sendedFlexMessage?.isActive
                            ? <Div display='flex' flexDirection='column'>
                              <Div flexDirection='row' display='flex' alignItems='center'>
                                {/* <StatusDot active={true}/> */}
                                <SmallText bold margin='0 0 0 8px' color={theme.color.success} >{msg.sendedFlexMessage.paymentType === 'CASH' ? 'รอชำระเงิน' : 'รอยืนยันที่อยู่'}</SmallText>
                              </Div>
                              {/* <StatusLine active={_.findIndex(QUOTATION_STATUS, (v) => v.value === msg?.sendedFlexMessage?.status) > 1}/> */}
                              <Div flexDirection='row' display='flex' alignItems='center'>
                                {/* <StatusDot active={_.findIndex(QUOTATION_STATUS, (v) => v.value === msg?.sendedFlexMessage?.status) > 1}/> */}
                                <SmallText bold margin='0 0 0 8px' color={_.findIndex(QUOTATION_STATUS, (v) => v.value === msg?.sendedFlexMessage?.status) > 1 ? theme.color.success : theme.color.gray70}>กำลังดำเนินการ</SmallText>
                              </Div>
                              {/* <StatusLine active={_.findIndex(QUOTATION_STATUS, (v) => v.value === msg?.sendedFlexMessage?.status) === 5}/> */}
                              <Div flexDirection='row' display='flex' alignItems='center'>
                                {/* <StatusDot active={_.findIndex(QUOTATION_STATUS, (v) => v.value === msg?.sendedFlexMessage?.status) === 5}/> */}
                                <SmallText bold margin='0 0 0 8px' color={_.findIndex(QUOTATION_STATUS, (v) => v.value === msg?.sendedFlexMessage?.status) === 5 ? theme.color.success : theme.color.gray70}>จัดส่งแล้ว</SmallText>
                              </Div>
                            </Div>
                            : <P bold color={theme.color.error}>ยกเลิก</P>
                        }
                      </Div>
                    </Div>
                    : <Div padding='16px' borderRadius='12px' moreStyle={{ boxShadow: '0px 4px 24px 0px rgba(0, 0, 0, 0.06)', minWidth: '250px' }}>
                      <H4 bold>{msg?.sendedFlexMessage?.refId}</H4>
                      <hr style={{ margin: '20px 0', border: 'none', borderTop: `1px solid ${theme.color.gray20}` }} />
                      <Div>
                        {
                          msg?.sendedFlexMessage?.itemList?.map((v) => (
                            <Div key={v.itemName} margin='0 0 2px' display='flex' justifyContent='space-between'>
                              <P margin='0 24px 0 0' style={{
                                maxWidth: '200px',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                              }}>{v.itemName}</P>
                              <P>{numeral(v.totalPrice).format('0,0.00')}</P>
                            </Div>
                          ))
                        }
                        <Div margin='0 0 2px' display='flex' justifyContent='space-between'>
                          <P margin='0 24px 0 0'>ค่าขนส่ง</P>
                          <P>{numeral(msg?.sendedFlexMessage?.deliveryCost).format('0,0.00')}</P>
                        </Div>
                      </Div>
                      <hr style={{ margin: '8px 0', border: 'none', borderTop: `1px solid ${theme.color.gray20}` }} />
                      <Div margin='0 0 2px' display='flex' justifyContent='space-between'>
                        <P margin='0 24px 0 0' color={theme.color.gray80}>สินค้า</P>
                        <P>{msg?.sendedFlexMessage?.quantity} รายการ</P>
                      </Div>
                      <hr style={{ margin: '8px 0 20px', border: 'none', borderTop: `1px solid ${theme.color.gray20}` }} />
                      <Div margin='0 0 2px' display='flex' justifyContent='space-between'>
                        <P margin='0 24px 0 0' color={theme.color.gray80}>ยอดทั้งหมด</P>
                        <P>{numeral(msg?.sendedFlexMessage?.totalPrice).format('0,0.00')} บาท</P>
                      </Div>
                    </Div>
        }
        <Icon icon='far fa-check-circle' margin='0 0 0 6px' color={theme?.color?.gray50} />
      </Div>
    </Div>
    <Label style={{ alignSelf: 'flex-end' }} display='block' color={theme.color.gray70} margin='4px 0 0 40px'>Send by {msg?.user?.firstName} {msg?.user?.lastName}</Label>
    <Label style={{ alignSelf: 'flex-end' }} display='block' color={theme.color.gray70} margin='4px 0 0 40px'>{moment(msg?.createdAt).format('DD/MM/YYYY HH:mm:ss')}</Label>
  </Div>
)

export const RecieveMessage = ({
  msg,
}) => {
  const messageRender = (type) => {
    switch (type) {
      case 'TEXT':
        return <Div width='100%' overflow='hidden' margin='0 0 0 8px' padding='16px 24px' moreStyle={{
          boxShadow: '0px 4px 24px 0px rgba(0, 0, 0, 0.06)', borderRadius: '12px 12px 12px 0', backgroundColor: theme.color.primaryColor,
        }}>
          <P color={theme.color.offWhite} style={{ wordBreak: 'break-all', whiteSpace: 'pre-line' }}>{msg?.receivedMessage?.text || msg?.receivedFacebookText?.text || msg?.message}</P>
        </Div>
      case 'STICKER':
        return <Div width='100%' overflow='hidden' margin='0 0 0 8px' padding='16px 24px' moreStyle={{
          boxShadow: '0px 4px 24px 0px rgba(0, 0, 0, 0.06)', borderRadius: '12px 12px 12px 0', backgroundColor: theme.color.primaryColor,
        }}>
          <P color={theme.color.offWhite} style={{ wordBreak: 'break-all', whiteSpace: 'pre-line' }}>Send Sticker.</P>
        </Div>
      case 'IMAGE':
        if (!_.isEmpty(msg?.receivedFacebookMessageWithAttachment)) {
          return msg?.receivedFacebookMessageWithAttachment?.attachments.map((v, i) => (
            <img key={i} src={msg?.receivedFacebookMessageWithAttachment?.attachments[i]?.payload?.url} style={{
              maxWidth: '75%', maxHeight: '500px', margin: i < msg?.receivedFacebookMessageWithAttachment?.attachments.length - 1 ? '0 0 8px 8px' : '0 0 0 8px', cursor: 'pointer',
            }}
            />
          ))
        }
        return <img src={msg?.receivedImage?.url.includes('http') ? msg?.receivedImage?.url : `${URL.FILE_SERVER}/${msg?.receivedImage?.url}`} style={{
          maxWidth: '75%', maxHeight: '500px', margin: '0 0 0 8px', cursor: 'pointer',
        }}
        />
      case 'FILE':
        return <Div width='100%' overflow='hidden' margin='0 0 0 8px' padding='16px 24px' moreStyle={{
          boxShadow: '0px 4px 24px 0px rgba(0, 0, 0, 0.06)', borderRadius: '12px 12px 12px 0', backgroundColor: theme.color.primaryColor,
        }}>
          <P color={theme.color.offWhite} style={{ wordBreak: 'break-all', whiteSpace: 'pre-line' }}>Send File.</P>
        </Div>
      case 'LOCATION':
        return <Div width='100%' overflow='hidden' margin='0 0 0 8px' padding='16px 24px' moreStyle={{
          boxShadow: '0px 4px 24px 0px rgba(0, 0, 0, 0.06)', borderRadius: '12px 12px 12px 0', backgroundColor: theme.color.primaryColor,
        }}>
          <P color={theme.color.offWhite} style={{ wordBreak: 'break-all', whiteSpace: 'pre-line' }}>Send Location.</P>
        </Div>
      case 'VIDEO':
        return <Div width='100%' overflow='hidden' margin='0 0 0 8px' padding='16px 24px' moreStyle={{
          boxShadow: '0px 4px 24px 0px rgba(0, 0, 0, 0.06)', borderRadius: '12px 12px 12px 0', backgroundColor: theme.color.primaryColor,
        }}>
          <P color={theme.color.offWhite} style={{ wordBreak: 'break-all', whiteSpace: 'pre-line' }}>Send Video.</P>
        </Div>
      case 'AUDIO':
        return <Div width='100%' overflow='hidden' margin='0 0 0 8px' padding='16px 24px' moreStyle={{
          boxShadow: '0px 4px 24px 0px rgba(0, 0, 0, 0.06)', borderRadius: '12px 12px 12px 0', backgroundColor: theme.color.primaryColor,
        }}>
          <P color={theme.color.offWhite} style={{ wordBreak: 'break-all', whiteSpace: 'pre-line' }}>Send Audio.</P>
        </Div>
      default:
        return ''
    }
  }
  return (
    <Div margin='4px 0'>
      <Div display='flex' alignItems='flex-end'>
        <Div moreStyle={{ maxWidth: '60%' }}>
          {
            messageRender(msg?.messageResponseType)
          }
        </Div>
      </Div>
      <Label display='block' color={theme.color.gray70} margin='4px 0 0 40px'>{moment(msg?.createdAt).format('DD/MM/YYYY HH:mm:ss')}</Label>
    </Div>
  )
}
