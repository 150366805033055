import React from 'react'
import { Modal } from 'antd'
import numeral from 'numeral'
import _ from 'lodash'
import {
  Button, Div, P, SmallText, Checkbox,
} from '@konsys-ui-custom'
import { theme } from '../../styles/_variables'

export const SelectTaxInvoiceModal = ({
  setModalVisible, modalVisible, taxInvoiceLoading, taxInvoiceData, callbackLoading, customer, selectedTaxInvoiceList, setSelectedTaxInvoiceList, callback, moduleName,
}) => (
  <Modal
    title="เลือกใบกำกับภาษี ที่จัดส่งพร้อมรอบนี้"
    taxInvoiceIds
    onCancel={() => setModalVisible(false)}
    footer={[
      <Button small key="back" text={`ยังไม่สร้าง ${moduleName}`} onClick={() => setModalVisible(false)}/>,
      <Button small key="submit" loading={taxInvoiceLoading || callbackLoading} disabled={taxInvoiceLoading || callbackLoading} color={theme.color.success} inverse text={selectedTaxInvoiceList?.length <= 0 ? `ยืนยันสร้าง ${moduleName}` : `ยืนยันสร้าง ${moduleName} พร้อมใบกำกับภาษี ${selectedTaxInvoiceList?.length} ใบ`} onClick={() => callback()} />,
    ]}
    visible={modalVisible}
  >
    <Div>
      <P bold display='block'>ลูกค้ารายนี้ {!_.isEmpty(customer?.name) ? `(${customer?.name})` : ''}</P>
      <P bold display='block' margin='6px 0 24px'>มีใบกำกับภาษีที่รอจัดส่งรอบหน้าอยู่ {taxInvoiceData?.taxInvoiceListForPickingTicket?.data?.list?.length} ใบ</P>
      <Div>
        <Div display='flex' margin='0 0 8px'>
          <SmallText color={theme.color.greyBlue} margin='0 0 0 32px' style={{ width: 'calc(40% - 80px)' }}>เลขที่เอกสาร</SmallText>
          <SmallText color={theme.color.greyBlue} margin='0 24px' style={{ width: '35%' }}>เลข IV</SmallText>
          <SmallText color={theme.color.greyBlue} style={{ width: '25%' }}>ยอดเงิน</SmallText>
        </Div>
        {
          taxInvoiceData?.taxInvoiceListForPickingTicket?.data?.list?.map((obj, i) => (
            <Div key={obj?.id} display='flex' margin={i !== taxInvoiceData?.taxInvoiceListForPickingTicket?.data?.list?.length - 1 ? '0 0 8px' : '0'}>
              <Checkbox data={{ value: obj?.id, text: obj?.number }} style={{ width: 'calc(40% - 48px)' }} onChange={(e) => {
                setSelectedTaxInvoiceList(e)
              }} />
              <P style={{ width: '35%' }} width='50%' margin='0 24px'>{obj?.ivNumber}</P>
              <P style={{ width: '25%' }} width='30%'>{numeral(obj?.totalCost).format('0,0.00')}</P>
            </Div>
          ))
        }
      </Div>
    </Div>
  </Modal>
)

export default SelectTaxInvoiceModal
