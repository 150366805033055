import React, { useState, useEffect, useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import _, { isNil } from 'lodash'
import numeral from 'numeral'
import moment from 'moment-timezone'
import { Popconfirm, message } from 'antd'
import { useQuery, useLazyQuery, useMutation } from '@apollo/client'
import { Button, Div, H3 } from '@konsys-ui-custom'
import { FullPageContainer } from '../../components'
import { Step, ButtonWithBadge } from './component'
import { theme } from '../../styles/_variables'
import MESSAGE from '../../constants/message'
import { PATH } from '../../constants/path'
import { PURCHASEORDER_DETAIL, GET_NEXT_PO_NUMBER } from '../../api/query'
import {
  CREATE_PURCHASE_ORDER,
  UPDATE_PURCHASE_ORDER,
} from '../../api/mutation'
import { validateData, permissionRedirect } from '../../utils/util-services'
import { AuthorizationContext } from '../../store/StoreProvider'
import { StepWithFilter, Step3 } from './step'

const stepList = [
  {
    id: 'inventory',
    icon: 'far fa-inventory',
    text: 'สินค้าสั่งเข้าคลัง',
  },
  {
    id: 'conclude',
    icon: 'far fa-file-invoice',
    text: 'สรุปรายการ และสั่งซื้อ',
  },
]

const StepForm = ({
  children,
  currentStep,
  setCurrentStep,
  disabledButton,
  setDisabledButton,
  data,
  isEdit,
}) => {
  const history = useHistory()
  const [doCreatePurchaseOrder, { loading: createPurchaseOrderLoading }] = useMutation(CREATE_PURCHASE_ORDER)
  const [doUpdatePurchaseOrder, { loading: updatePurchaseOrderLoading }] = useMutation(UPDATE_PURCHASE_ORDER)

  const textNextStep = () => {
    switch (currentStep) {
      case 2:
        return 'ถัดไป - สรุปรายการและสั่งซื้อ'
      case 3:
        return 'สร้างใบ PO'
      default:
        return 'ถัดไป'
    }
  }
  const { mutationVariables, selectedList, filterVariables } = data
  const sumTotalSelectedItem = _.sum([
    selectedList?.so?.length || 0,
    selectedList?.item?.length || 0,
  ])
  const validateDataButton = () => {
    const itemList = _.flattenDeep(
      _.concat(
        selectedList?.item.map((v) => [
          v.quantity,
          v.packId || v.setId,
          v.buyPrice,
        ]),
        selectedList?.so.map((v) => [v.quantity, v.orderId, v.buyPrice]),
      ),
    )
    return _.concat(itemList, mutationVariables?.isIncludeVat)
  }
  const respMutation = (isError, respObj) => {
    setDisabledButton(false)
    if (_.isEmpty(isError)) {
      if (respObj?.success) {
        message.success(respObj?.message)
        history.push({
          pathname: PATH.poList,
          state: { search: respObj?.data?.number },
        })
      } else {
        message.error(respObj?.message)
      }
    }
  }

  return (
    <Div>
      <Step list={stepList} currentStep={currentStep} />
      <Div margin="32px 0 48px">{children}</Div>
      <Div display="flex" justifyContent="center" style={{ position: 'sticky', bottom: 0 }} bgColor={theme.color.offWhite} padding='8px 0 0 0'>
        {currentStep === 0 || currentStep === 1 ? (
          <Popconfirm
            title={MESSAGE.po.create.cancel}
            onConfirm={() => {
              history.push(PATH.poList)
            }}
            okText="ยืนยันยกเลิกการสร้าง"
            cancelText="ไม่ยกเลิกการสร้าง"
          >
            <Button
              text="ยกเลิก"
              icon="fal fa-times"
              color={theme.color.error}
            />
          </Popconfirm>
        ) : (
          <Button
            text="ย้อนกลับ"
            icon="fal fa-arrow-left"
            color={theme.color.gray50}
            onClick={() => setCurrentStep(currentStep - 1)}
          />
        )}
        {currentStep === 2 ? (
          <Button
            margin="0 0 0 8px"
            text={textNextStep()}
            icon="fal fa-check"
            inverse
            color={theme.color.success}
            loading={createPurchaseOrderLoading || updatePurchaseOrderLoading}
            disabled={
              disabledButton || validateData(true, validateDataButton(), []) || isNil(filterVariables?.dueDate)
            }
            onClick={() => {
              setDisabledButton(true)
              const orderItemList = _.reduce(
                _.concat(selectedList?.so, selectedList?.item),
                (arr, n) => [
                  ...arr,
                  {
                    quantity: parseFloat(n.quantity),
                    buyPrice: parseFloat(n.buyPrice),
                    isVat: n.isVat,
                    vat: n.vat,
                    id: n.orderId,
                    setId: n.setId,
                    packId: n.packId,
                    discount: parseFloat(n.discount),
                    vatInclude: mutationVariables?.isIncludeVat,
                  },
                ],
                [],
              )
              const variables = {
                orderItemList,
                paymentType: filterVariables?.paymentType,
                discountType: 'BAHT',
                remark: mutationVariables?.remark,
                dueDate: filterVariables?.dueDate,
                warehouseId: filterVariables?.warehouseId,
                deliveryCost: parseFloat(
                  numeral(mutationVariables?.shippingCost),
                ),
              }
              if (isEdit) {
                doUpdatePurchaseOrder({
                  variables: {
                    ...variables,
                    id: mutationVariables.id,
                    files: _.concat(
                      mutationVariables?.existFileList,
                      mutationVariables?.fileList,
                    ),
                  },
                }).then((resp) => respMutation(resp.errors, resp.data.updatePurchaseOrder))
              } else {
                doCreatePurchaseOrder({
                  variables: {
                    ...variables,
                    supplierId: 1,
                    warehouseId: 1,
                    paymentType: 'CASH',
                    files: mutationVariables?.fileList,
                  },
                }).then((resp) => respMutation(resp.errors, resp.data.createPurchaseOrder))
              }
            }}
          />
        ) : (
          <ButtonWithBadge
            margin="0 0 0 8px"
            button={{
              icon: 'fal fa-check',
              text: textNextStep(),
              onClick: () => setCurrentStep(currentStep + 1),
              disabled: disabledButton,
            }}
            badge={
              sumTotalSelectedItem > 0 && {
                text: `${numeral(sumTotalSelectedItem).format('0,0')} ชิ้น`,
              }
            }
          />
        )}
      </Div>
    </Div>
  )
}

const StepContainer = ({ poData }) => {
  const [currentStep, setCurrentStep] = useState(1)
  const [mutationVariables, setMutationVariables] = useState({
    fileList: [],
    supplierId: 1,
    warehouseId: 1,
    paymentType: 'CASH',
    isIncludeVat: true,
  })
  const [filterVariables, setFilterVariables] = useState({
    dueDate: moment(),
    search: '',
    supplierId: 1,
  })
  const [selectedList, setSelectedList] = useState({
    so: [],
    item: [],
  })
  const [disabledButton, setDisabledButton] = useState(true)

  useEffect(() => {
    setCurrentStep(1)
  }, [])

  useEffect(() => {
    if (
      _.isEmpty(selectedList?.so)
      && _.isEmpty(selectedList?.item)
      && currentStep === 2
    ) {
      setCurrentStep(1)
    }
  }, [selectedList])

  useEffect(() => {
    if (!_.isEmpty(poData)) {
      setCurrentStep(2)
      setDisabledButton(false)
      setFilterVariables({
        ...filterVariables,
        supplierId: poData?.supplier?.id,
        warehouseId: poData?.warehouse?.id,
        paymentType: poData?.paymentType,
        dueDate: moment(poData?.dueDate),
      })
      setMutationVariables({
        ...mutationVariables,
        id: poData?.id,
        remark: poData?.remark,
        existFileList: poData?.purchaseOrderFiles,
      })
      setSelectedList({
        item: poData?.orderItemWithOutSaleOrder?.map((obj) => {
          const inventoryOption = obj?.pack?.inventoryOption
          return {
            id: inventoryOption?.id,
            name:
              obj?.pack?.variantName
              || inventoryOption?.inventory?.productName
              || '-',
            sku: inventoryOption?.inventory?.sku,
            quantity: obj?.quantity,
            buyPrice: obj?.buyPrice || 0,
            discount: obj?.discount || 0,
            isVat: inventoryOption?.isVat,
            vat: inventoryOption?.vat,
            packId: obj.type === 'PACK' ? obj?.pack?.id : undefined,
            setId: obj.type === 'PACK' ? undefined : obj?.productSet?.id,
          }
        }),
        so: _.flatten(
          poData?.orderItemWithSaleOrder?.map((soData) => soData?.items?.map((obj) => {
            const inventoryOption = obj?.pack?.inventoryOption
            return {
              id: inventoryOption?.id,
              orderId: obj?.id,
              quantity: obj?.quantity,
              buyPrice: obj?.buyPrice || 0,
              isVat: obj?.isVat,
              vat: obj?.vat,
              packId: obj.pack?.id,
              setId: obj?.productSet?.id,
              discount: obj?.discount,
              name:
                  obj?.pack?.variantName
                  || inventoryOption?.inventory?.productName
                  || '-',
              sku: inventoryOption?.inventory?.sku,
              salesPrice: obj?.salesPrice || 0,
              soNumber: soData?.soNumber,
            }
          })),
        ),
      })
    }
  }, [poData])

  return (
    <StepForm
      isEdit={!_.isEmpty(poData)}
      disabledButton={disabledButton}
      setDisabledButton={(value) => setDisabledButton(value)}
      data={{
        mutationVariables,
        selectedList,
        filterVariables,
      }}
      currentStep={currentStep}
      setCurrentStep={(value) => setCurrentStep(value)}
    >
      {currentStep === 1 ? (
        <StepWithFilter
          isEdit={!_.isEmpty(poData)}
          filterVariables={filterVariables}
          setFilterVariables={(value) => setFilterVariables(value)}
          setDisabledButton={(value) => setDisabledButton(value)}
          currentStep={currentStep}
          selectedList={selectedList.item}
          setSelectedList={(value) => setSelectedList({ ...selectedList, item: value })
          }
          isEmptyAllSelectedList={
            _.isEmpty(selectedList?.so) && _.isEmpty(selectedList?.item)
          }
        />
      ) : (
        <Step3
          mutationVariables={mutationVariables}
          setMutationVariables={(value) => setMutationVariables(value)}
          selectedList={selectedList}
          setSelectedList={(value) => setSelectedList(value)}
        />
      )}
    </StepForm>
  )
}

export const Form = (props) => {
  const { data: nextPoNumberData, loading: nextPoNumberLoading } = useQuery(
    GET_NEXT_PO_NUMBER,
    { skip: !_.isEmpty(props?.match?.params?.id) },
  )
  const [
    getPODetail,
    { data: purchaseOrderDetailData, loading: purchaseOrderDetailLoading },
  ] = useLazyQuery(PURCHASEORDER_DETAIL)

  const isEditPo = !_.isEmpty(
    purchaseOrderDetailData?.purchaseOrderDetail?.data,
  )
  const authorization = useContext(AuthorizationContext)
  const purchaseOrderAutho = authorization.authorization?.purchaseOrder || {}

  useEffect(() => {
    if (isEditPo ? !purchaseOrderAutho?.edit : !purchaseOrderAutho?.create) { permissionRedirect() }
  }, [])

  useEffect(() => {
    if (!_.isEmpty(props?.match?.params?.id) && purchaseOrderAutho?.edit) { getPODetail({ variables: { id: parseInt(props?.match?.params?.id) } }) }
  }, [props?.match?.params?.id])

  return (
    <Div>
      <Helmet>
        <meta name="viewport" content="width=1280" />
      </Helmet>
      <FullPageContainer
        title={[{ text: 'Supplier', icon: 'fal fa-envelope-open-dollar' }]}
        detail={{
          title: [
            {
              text: isEditPo ? 'แก้ไขใบ PO' : 'สร้างใบ PO',
              icon: isEditPo ? 'fal fa-pencil' : 'fal fa-plus',
            },
          ],
        }}
        loading={nextPoNumberLoading || purchaseOrderDetailLoading}
      >
        <Div>
          <Div
            margin="8px 0 24px"
            display="flex"
            alignItems="flex-end"
            justifyContent="flex-end"
          >
            {(!_.isEmpty(nextPoNumberData)
            || !_.isEmpty(purchaseOrderDetailData?.purchaseOrderDetail?.data)) && (
              <H3
                margin="8px 0 24px"
                display="block"
                bold
                textAlign="right"
                color={theme.color.primaryColor}
              >
                {nextPoNumberData?.nextPONumber
                || purchaseOrderDetailData?.purchaseOrderDetail?.data?.number}
              </H3>
            )}
          </Div>
          {!_.isEmpty(props?.match?.params?.id)
        && !_.isEmpty(purchaseOrderDetailData?.purchaseOrderDetail?.data) ? (
              <StepContainer
                poData={purchaseOrderDetailData?.purchaseOrderDetail?.data}
              />
            ) : (
              <StepContainer />
            )}
        </Div>
      </FullPageContainer>
    </Div>
  )
}
export default Form
