import _, { isNull } from 'lodash'
import { Modal } from 'antd'
import moment from 'moment-timezone'
import { getTokenData } from './local-data-service'
import { ROLE, BILLING_TYPE } from '../constants/enum'
import MESSAGE from '../constants/message'

export const isEveryIndex = (arr, value) => (
  arr.every((v) => v === value)
)

export const passwordValidator = (password) => (password || true)

export default isEveryIndex

export const checkInt = (input) => {
  const acceptedKeyCode = [48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 8, 37, 38, 39, 40, 9]
  return _.includes(acceptedKeyCode, input.keyCode)
}

export const checkFloat = (input) => {
  const acceptedKeyCode = [48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 110, 190, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 8, 37, 38, 39, 40, 9]
  return _.includes(acceptedKeyCode, input.keyCode)
}

export const checkNotNumber = (input) => {
  const acceptedKeyCode = [69, 107, 109, 187, 189]
  return _.includes(acceptedKeyCode, input.keyCode)
}

export const checkSpecial = (input) => /[!@#$%^&*)(]/g.test(input)

export const monthConverter = (input) => {
  switch (input) {
    case '01': return 'มกราคม'
    case '02': return 'กุมภาพันธ์'
    case '03': return 'มีนาคม'
    case '04': return 'เมษายน'
    case '05': return 'พฤษภาคม'
    case '06': return 'มิถุนายน'
    case '07': return 'กรกฎาคม'
    case '08': return 'สิงหาคม'
    case '09': return 'กันยายน'
    case '10': return 'ตุลาคม'
    case '11': return 'พฤศจิกายน'
    case '12': return 'ธันวาคม'
    default: return 'none'
  }
}

export const toSafeFloat = (value) => (_.isNaN(parseFloat(value)) ? 0 : parseFloat(value))

export const renderText = (value, isHideNilValue) => (value || (!isHideNilValue ? 'ไม่ระบุ' : ''))

const isInvalidValue = (value) => (
  _.isNaN(value) || _.isNil(value) || value === '' || (_.isArray(value) && _.isEmpty(value))
)

const checkValue = (requiredFields) => (
  _.some(requiredFields, (itemInRequiredFields) => {
    if (!_.isUndefined(itemInRequiredFields) && moment.isMoment(itemInRequiredFields)) {
      return false
    }
    // Item is array
    if (_.isArray(itemInRequiredFields)) {
      return _.size(itemInRequiredFields) === 0 || _.some(itemInRequiredFields, (valueInArrayItem) => {
        if (_.isObject(valueInArrayItem)) {
          return _.some(valueInArrayItem, (valueInObject) => isInvalidValue(valueInObject))
        } return isInvalidValue(valueInArrayItem)
      })
    }
    // Item is not array
    return isInvalidValue(itemInRequiredFields) || (_.isEmpty(itemInRequiredFields) && _.isObject(itemInRequiredFields)) || _.some(itemInRequiredFields, (valueInItem) => {
      if (_.isObject(valueInItem)) {
        return _.some(valueInItem, (valueInObject) => isInvalidValue(valueInObject))
      }
      return isInvalidValue(valueInItem)
    })
  })
)

// ---------------****** value of object can not be array ******--------------- //
// eslint-disable-next-line arrow-body-style
export const validateData = (isCreate, requiredFields, allFields) => (
  isCreate
    ? _.isEmpty(requiredFields) || checkValue(requiredFields)
    : _.isEmpty(allFields)
    || _.every(_.values(allFields), (o) => (_.isArray(o) && _.isEmpty(o)))
    || _.some(_.flatten(_.values(allFields)), (o) => _.some(_.values(o), (v) => _.isUndefined(v)))
    || _.some(requiredFields, (o) => isInvalidValue(o) && !_.isUndefined(o))
)

export const getVariantName = (variant) => _.join(_.dropRightWhile([variant.option1 && variant.option1.optionName, variant.option2 && variant.option2.optionName, variant.option3 && variant.option3.optionName], (o) => _.isNull(o)), ' / ')
export const renderVariantNameFromInventoryOption = (data) => {
  const name = _.join(_.compact(['option1', 'option2', 'option3'].map(((o) => (!_.isEmpty(data[o]) ? data[o].optionName : '')))), ' / ')
  return _.isEmpty(name) ? '-' : name
}

export const isSuperAdmin = () => !_.isEmpty(getTokenData()) && getTokenData().roles && getTokenData().roles[0].name === ROLE[0]
export const isAdmin = () => !_.isEmpty(getTokenData()) && getTokenData().roles && getTokenData().roles[0].name === ROLE[1]

export const permissionRedirect = () => (
  Modal.error({
    title: MESSAGE.permission.denied,
    onOk: () => {
      window.location = '/'
    },
  })
)

export const getRequireBillingAddressField = (data) => {
  let requireField = []
  if (data?.billingType === BILLING_TYPE[0]?.value) {
    requireField = [
      data?.billingType || data?.type,
      data?.contactName || null,
      !_.isEmpty(data?.taxId) && data?.taxId?.length === 13 ? data?.taxId : null,
    ]
  } else if (data?.billingType === BILLING_TYPE[1]?.value) {
    requireField = [
      data?.billingType || data?.type,
      data?.addressNo || null,
      data?.province || null,
      data?.district || null,
      data?.subDistrict || null,
      data?.postcode || null,
      data?.contactName || null,
      !_.isEmpty(data?.taxId) && data?.taxId?.length === 13 ? data?.taxId : null,
    ]
  }
  return requireField
}

export const renderAddress = (address, type) => {
  if (type === 'billing') {
    return `${address?.contactName || '(ชื่อที่ใช้ในการออกใบกำกับภาษี)'} ${address?.taxId || '(ไม่มีเลขประจำตัวผู้เสียภาษี)'} ${address?.addressNo || ''} ${address?.subDistrict || ''} ${address?.district || ''} ${address?.province || ''} ${address?.postcode || ''} ${address?.contactPhoneNumber || ''}`
  }
  return `${address?.contactName || ''} (${address?.thirdPartyAddress || ''}${address?.addressNo || ''} ${address?.subDistrict || ''} ${address?.district || ''} ${address?.province || ''} ${address?.postcode || ''} ${address?.contactPhoneNumber || ''})`
}

export const isAlphanumeric = (inputtxt) => {
  // eslint-disable-next-line no-misleading-character-class
  const letterNumber = /^[0-9a-zA-Zก-ฮฯะัาำิีึืฺุูเแโใไๅๆ็่้๊๋์]+$/
  return !isNull(inputtxt.match(letterNumber))
}
