export default {
  CLIENT_ID: process.env.REACT_APP_CLIENT_ID,
  CLIENT_SECRET: process.env.REACT_APP_CLIENT_SECRET,
  GRANT_TYPE: process.env.REACT_APP_GRANT_TYPE,
  GRAPH_PRIVATE: process.env.REACT_APP_GRAPH_PRIVATE,
  GRAPH_PUBLIC: process.env.REACT_APP_GRAPH_PUBLIC,
  WEB_SERVER: process.env.REACT_APP_WEB_SERVER,
  FILE_SERVER: process.env.REACT_APP_FILE_SERVER,
  API_HOST: process.env.REACT_APP_API,
}
