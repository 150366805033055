import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
  padding: 40px;
  background: #fff;
`

export const dd = styled.div`

`
