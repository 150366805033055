import { MinusOutlined, PlusOutlined } from '@ant-design/icons'
import { useLazyQuery, useMutation, useQuery } from '@apollo/client'
import { Button, Checkbox, Div, H4, Icon, Label, P, SmallText } from '@konsys-ui-custom'
import { Button as ButtonAntd, Col, Drawer, Input as InputAntd, InputNumber, Row, Skeleton, message } from 'antd'
import dayjs from 'dayjs'
import _, { filter, find, first, flatten, isEmpty, isNil, size, some, sumBy, toNumber, uniqBy } from 'lodash'
import { nanoid } from 'nanoid'
import numeral from 'numeral'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { CREATE_PICKING_TICKET, MANAGE_BOXES } from '../../api/mutation'
import { BOX_LIST, SALES_ORDER_BY_STATUS, WAREHOUSES } from '../../api/query'
import noImg from '../../assets/image-not-available.png'
import { FullPageContainer, SelectWithLabel, Uploader } from '../../components'
import { DELIVERY_TYPE } from '../../constants/enum'
import { menuItems } from '../../constants/menu-config'
import { PATH } from '../../constants/path'
import { AuthorizationContext } from '../../store/StoreProvider'
import { theme } from '../../styles/_variables'
import { showMessageMutation } from '../../utils'
import { permissionRedirect } from '../../utils/util-services'

const ptMenu = !_.isEmpty(_.find(menuItems, (v) => v.id === 'picking-tickets'))
  ? _.find(menuItems, (v) => v.id === 'picking-tickets')
  : null
const ptName = ptMenu.name

const ItemCard = ({ item, count, removeItem }) => {
  const [isShown, setIsShown] = useState(false)
  return (
    <Div display="flex" width="100%" height="97px" margin='0 0 16px 0'>
      {window.innerWidth > 768 && (
        <img
          style={{ width: 97, objectFit: 'cover', objectPosition: 'center' }}
          src={first((item.pack || item.productSet)?.inventoryOption?.files)?.url || noImg}
        />
      )}
      <Div
        width={`calc(100% ${window.innerWidth > 768 ? '- 97px' : ''} - 95px)`}
        bgColor={theme.color.gray10}
        display="flex"
        flexDirection="column"
        padding="16px"
      >
        <P bold margin="0 0 8px 0">
          {
            (item.pack || item.productSet)?.inventoryOption?.inventory
              ?.productName
          }
        </P>
        <SmallText>
        SKU: {(item.pack || item.productSet)?.inventoryOption?.inventory?.sku}
        </SmallText>
        <SmallText>
        Barcode:{' '}
          {(item.pack || item.productSet)?.inventoryOption?.inventory?.barcode}
        </SmallText>
      </Div>
      <Div
        width="95px"
        bgColor={
          item?.quantity === count ? theme.color.success : theme.color.info
        }
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
      >
        <Div display='flex' alignItems='center'>
          <H4 bold color={theme.color.offWhite} onMouseEnter={() => setIsShown(true)} onMouseLeave={() => setIsShown(false)} style={{ display: 'flex', alignItems: 'center' }}>
            {
              isShown && count > 0
                ? <ButtonAntd
                  danger
                  icon={<MinusOutlined />}
                  type="text"
                  onClick={() => removeItem((item.pack || item.productSet)?.inventoryOption?.inventory?.barcode)}
                  size='small'
                  style={{ borderRadius: 8, background: '#ffe0e0', marginRight: 2 }}
                />
                : count
            }
          </H4>
          <H4 color={theme.color.offWhite}>/{item?.quantity}</H4>
        </Div>
        <SmallText color={theme.color.offWhite}>จำนวนที่แพ็ค</SmallText>
      </Div>
    </Div>
  )
}

const limit = 10

const PtForm = () => {
  const history = useHistory()
  const searchOrderInputRef = useRef()
  const searchItemInputRef = useRef()
  const [itemCounts, setItemCounts] = useState([])
  const [boxes, setBoxes] = useState([])
  const [manageBoxes, setManageBoxes] = useState([])
  const [openManageBox, setOpenManageBox] = useState(false)
  const [search, setSearch] = useState('')
  const [fileList, setFileList] = useState([])
  const [remark, setRemark] = useState('')
  const [selectedWarehouse, setSelectedWarehouse] = useState()
  const [getSO, { data, loading, variables }] = useLazyQuery(SALES_ORDER_BY_STATUS)
  const { data: warehouseData, loading: warehouseLoading } = useQuery(WAREHOUSES)
  const [doUpdate, { loading: updating }] = useMutation(MANAGE_BOXES)
  const { data: boxListData, refetch } = useQuery(BOX_LIST, { onCompleted: () => {
    setBoxes(boxListData.boxList?.data?.map((v) => (
      { id: v.id, name: v.name, price: v.price, amount: 0 }
    )))
    setManageBoxes(boxListData.boxList?.data?.map((v) => (
      { id: v.id, name: v.name, price: v.price }
    )))
  } })
  const [doCreatePT, { loading: createPtloading }] = useMutation(
    CREATE_PICKING_TICKET,
    { refetchQueries: ['remainingJob', 'remainingAccountant'] },
  )
  const authorization = useContext(AuthorizationContext)
  const pickingTicketAutho = authorization.authorization?.pickingTicket || {}

  // const result = find(data?.getPreparingSOByCustomer?.data?.list, (so) => so.soNumber === variables?.search) || first(data?.getPreparingSOByCustomer?.data?.list)
  const result = search === variables?.search ? (find(data?.getPreparingSOByCustomer?.data?.list, (so) => so.soNumber === variables?.search) || first(data?.getPreparingSOByCustomer?.data?.list)) : null

  useEffect(() => {
    if (!search || search === '') {
      searchOrderInputRef?.current?.focus()
    }
  })

  const warehouseList = !warehouseLoading
    && !_.isEmpty(warehouseData)
    && !_.isEmpty(warehouseData.warehouseList)
    ? warehouseData.warehouseList.data.list.map((v) => ({
      text: v.warehouseName,
      value: v.id,
    }))
    : []

  const mergedItems = [...flatten(filter(first(result?.lots)?.items, (o) => o.type === 'SET')?.map((o) => o.childOrderItems)), ...filter(first(result?.lots)?.items, (o) => o.type !== 'SET')]
  const combinedMergedItemsQuantity = uniqBy(mergedItems, (o) => o.pack.id).map((o) => ({
    ...o,
    quantity: sumBy(filter(mergedItems, (m) => m.pack.id === o.pack.id), (s) => s.quantity),
  }))
  useEffect(() => {
    if (data) {
      setItemCounts(
        combinedMergedItemsQuantity.map((v) => ({
          id: v.id,
          barcode: (v.pack || v.productSet)?.inventoryOption?.inventory?.barcode,
          count: 0,
          quantity: v.quantity,
        })),
      )
      searchItemInputRef?.current?.focus()
    }
  }, [data])

  useEffect(() => {
    searchOrderInputRef?.current?.focus()
    if (!pickingTicketAutho.create) {
      permissionRedirect()
    }
  }, [])

  message.config({
    maxCount: 1,
  })
  return (
    <FullPageContainer
      title={[{ text: ptName, icon: 'fal fa-truck-loading' }]}
      detail={{ title: [{ text: `สร้าง${ptName}`, icon: 'fal fa-plus' }] }}
    >
      <Div style={{ textAlign: 'center' }}>
        <Drawer
          title="จัดการขนาดกล่อง"
          placement="right"
          onClose={() => setOpenManageBox(false)}
          visible={openManageBox}
        >
          <Div
            display="flex"
            flexDirection="column"
            width="100%"
            height="100%"
            justifyContent="space-between"
          >
            <Div display="flex" flexDirection="column" width="100%">
              <SmallText color={theme.color.gray70} margin="0 0 4px 0">
                ชื่อกล่อง
              </SmallText>
              {manageBoxes.map((v) => (
                <Div
                  key={v.id}
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  width="100%"
                  margin="4px 0"
                >
                  <InputAntd placeholder="ชื่อกล่อง" style={{ width: '55%' }} value={v.name} onChange={(e) => {
                    setManageBoxes(manageBoxes.map((o) => {
                      if (o.id === v.id) {
                        return ({
                          ...o,
                          name: e.target.value,
                        })
                      } return o
                    }))
                  }}/>
                  <InputNumber placeholder="ราคา" style={{ width: '40%' }} value={v.price} onChange={(e) => {
                    setManageBoxes(manageBoxes.map((o) => {
                      if (o.id === v.id) {
                        return ({
                          ...o,
                          price: e,
                        })
                      } return o
                    }))
                  }}/>
                </Div>
              ))}
              <ButtonAntd
                type="dashed"
                icon={<PlusOutlined />}
                block
                onClick={() => setManageBoxes([...manageBoxes, { id: nanoid() }])}
                style={{ margin: '4px 0' }}
              >
                เพิ่มรายการ
              </ButtonAntd>
            </Div>
            <Div display="flex" width="100%" justifyContent="center">
              <Button
                inverse
                color={theme.color.gray20}
                text="ยกเลิก"
                margin="0 16px 0 0"
                onClick={() => setOpenManageBox(false)}
                disabled={updating}
              />
              <Button
                inverse
                onClick={() => doUpdate({
                  variables: {
                    boxes: manageBoxes.map((o) => ({
                      ...o,
                      id: toNumber(o.id) ? o.id : undefined,
                    })),
                  },
                }).then((resp) => {
                  showMessageMutation(resp.data.manageBoxes)
                  setOpenManageBox(false)
                  refetch()
                })}
                color={theme.color.success}
                loading={updating}
                disabled={some(manageBoxes, (o) => isNil(o.name) || isNil(o.price) || isEmpty(o.name))}
                text="บันทึก"
                width="180px"
              />
            </Div>
          </Div>
        </Drawer>
        <Row>
          <Col xs={24} sm={{ offset: 18, span: 6 }}>
            <InputAntd
              style={{ width: '100%', marginBottom: 16 }}
              ref={searchOrderInputRef}
              onKeyDown={(e) => {
                if (e.keyCode === 13) {
                  getSO({
                    variables: {
                      limit,
                      offset: 0,
                      status: 'ALL',
                      search,
                    },
                  })
                }
              }}
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Div
              width="100%"
              style={{
                border: `dashed 5px ${theme.color.gray10}`,
                cursor: 'pointer',
              }}
              display="flex"
              alignItems="center"
              justifyContent="center"
              flexDirection="column"
              padding="24px"
              margin="0 0 16px 0"
              onClick={() => searchOrderInputRef?.current?.focus()}
            >
              <Icon
                icon="far fa-barcode-read"
                color={theme.color.gray20}
                style={{ fontSize: '55px', padding: 24 }}
              />
              <H4 color={theme.color.gray20}>Scan Barcode เพื่อค้นหา Order</H4>
            </Div>
          </Col>
        </Row>
        {search
          && (loading ? (
            [
              <Skeleton key={nanoid()} />,
              <Skeleton key={nanoid()} />,
              <Skeleton key={nanoid()} />,
            ]
          ) : (
            result
              ? <Row gutter={16}>
                <Col xs={24} sm={{ span: 14 }}>
                  <Div width="100%" display="flex" flexDirection="column">
                    <H4 color={theme.color.primaryColor} bold>
                    Order {result?.soNumber}{' '}
                      <SmallText bold color={theme.color.gray20}>
                      ({size(combinedMergedItemsQuantity)} รายการ)
                      </SmallText>
                    </H4>
                    <Div
                      width="100%"
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <P bold margin="16px 0">
                      รายการสินค้า
                      </P>
                      <input
                        placeholder="คลิกเพื่อแสกน"
                        ref={searchItemInputRef}
                        style={{
                          cursor: 'pointer',
                          width: 190,
                          border: 'none',
                          outline: 'unset',
                          textAlign: 'right',
                        }}
                        onKeyDown={(e) => {
                          if (e.keyCode === 13) {
                            setItemCounts(
                              itemCounts.map((v) => {
                                if (v.barcode === e.target.value) {
                                  return {
                                    ...v,
                                    count: v.count + 1,
                                  }
                                }
                                return v
                              }),
                            )
                            searchItemInputRef.current.value = ''
                          }
                        }}
                      />
                    </Div>
                    {combinedMergedItemsQuantity.map((item) => (
                      <ItemCard
                        key={nanoid()}
                        count={find(itemCounts, (o) => o.id === item.id)?.count}
                        item={item}
                        removeItem={(barcode) => setItemCounts(
                          itemCounts.map((v) => {
                            if (v.barcode === barcode) {
                              return {
                                ...v,
                                count: v.count - 1,
                              }
                            }
                            return v
                          }),
                        )}
                      />
                    ))}
                    <Div width="100%" margin="16px 0">
                      <Div
                        width="100%"
                        display="flex"
                        justifyContent="space-between"
                        margin="16px 0"
                      >
                        <P bold>เลือกกล่อง</P>
                        <P
                          bold
                          color={theme.color.info}
                          style={{ cursor: 'pointer' }}
                          onClick={() => setOpenManageBox(true)}
                        >
                        จัดการขนาดกล่อง
                        </P>
                      </Div>
                      {boxes.map((v) => (
                        <Div
                          key={v.id}
                          width="100%"
                          display="flex"
                          justifyContent="space-between"
                          alignItems="center"
                          margin="16px 0"
                        >
                          <Div display="flex" alignItems="center">
                            <Checkbox
                              data={{ value: v.id }}
                              checked={v.amount > 0}
                            />
                            <P bold style={{ marginRight: 8 }}>
                            ไซส์ {v.name}
                            </P>
                            <SmallText>
                            ราคา {numeral(v.price).format('0,0.00')} บาท
                            </SmallText>
                          </Div>
                          <Div
                            display="flex"
                            alignItems="center"
                            bgColor={theme.color.gray10}
                            padding="8px"
                          >
                            <MinusOutlined
                              style={{ cursor: 'pointer' }}
                              onClick={() => setBoxes(
                                boxes.map((b) => {
                                  if (v.id === b.id) {
                                    return {
                                      ...v,
                                      amount:
                                        parseInt(b.amount) - 1 < 0
                                          ? 0
                                          : parseInt(b.amount) - 1,
                                    }
                                  }
                                  return b
                                }),
                              )
                              }
                            />
                            <InputNumber
                              className="box"
                              min={0}
                              style={{ width: 50, background: 'none' }}
                              bordered={false}
                              controls={false}
                              size="middle"
                              value={v.amount}
                              margin="0 4px"
                              onChange={(e) => {
                                setBoxes(
                                  boxes.map((b) => {
                                    if (v.id === b.id) {
                                      return { ...v, amount: e }
                                    }
                                    return b
                                  }),
                                )
                              }}
                            />
                            <PlusOutlined
                              style={{ cursor: 'pointer' }}
                              onClick={() => setBoxes(
                                boxes.map((b) => {
                                  if (v.id === b.id) {
                                    return {
                                      ...v,
                                      amount: parseInt(b.amount) + 1,
                                    }
                                  }
                                  return b
                                }),
                              )
                              }
                            />
                          </Div>
                        </Div>
                      ))}
                    </Div>
                  </Div>
                </Col>
                <Col xs={24} sm={{ span: 10 }}>
                  <Div width="100%" display="flex" flexDirection="column" alignItems='flex-start' margin='0 0 16px 0'>
                    <SelectWithLabel
                      title={{
                        text: (
                          <SmallText bold>
                          คลังสินค้า <Label color={theme.color.error}>*</Label>
                          </SmallText>
                        ),
                        margin: '0 0 4px',
                      }}
                      select={{
                        placeholder: 'คลิกเลือกคลังสินค้า',
                        value: selectedWarehouse,
                        onChange: (value) => {
                          setSelectedWarehouse(value)
                        },
                        style: {
                          width: '200px',
                        },
                        options: warehouseList,
                      }}
                    />
                  </Div>
                  <Div width="100%" display="flex" flexDirection="column">
                    <P bold>รายละเอียด</P>
                    <Div
                      bgColor={theme.color.gray10}
                      margin="16px 0"
                      padding="16px"
                    >
                      <Div
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                        margin="8px 0"
                      >
                        <SmallText color={theme.color.gray70}>
                        วันที่กำหนดส่ง
                        </SmallText>
                        <SmallText>{dayjs(first(result?.lots)?.deliveryDate).format('DD/MM/YYYY')}</SmallText>
                      </Div>
                      <Div
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                        margin="8px 0"
                      >
                        <SmallText color={theme.color.gray70}>
                        ชื่อลูกค้า
                        </SmallText>
                        <SmallText>{result?.customer?.name}</SmallText>
                      </Div>
                      <Div
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                        margin="8px 0"
                      >
                        <SmallText color={theme.color.gray70}>
                        เบอร์ติดต่อ
                        </SmallText>
                        <SmallText>{find(result?.customer?.contactPhoneNumber, (v) => v.isPrimary)?.phoneNumber}</SmallText>
                      </Div>
                    </Div>
                    <Div
                      bgColor={theme.color.gray10}
                      margin="16px 0"
                      padding="16px"
                    >
                      <Div
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                        margin="8px 0"
                      >
                        <SmallText color={theme.color.gray70}>
                        หมายเลข Tracking
                        </SmallText>
                        <SmallText>{result.deliveryChannel?.trackingNumber || '-'}</SmallText>
                      </Div>
                      <Div
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                        margin="8px 0"
                      >
                        <SmallText color={theme.color.gray70}>
                        ช่องทางการจัดส่ง
                        </SmallText>
                        <SmallText>{find(DELIVERY_TYPE, (v) => v.value === first(result?.lots)?.deliveryType)?.text}</SmallText>
                      </Div>
                      {
                        first(result?.lots)?.deliveryType === 'DELIVERY'
                        && <Div
                          display="flex"
                          alignItems="center"
                          justifyContent="space-between"
                          margin="8px 0"
                        >
                          <SmallText color={theme.color.gray70}>
                          ที่อยู่จัดส่ง
                          </SmallText>
                          <SmallText style={{ whiteSpace: 'pre-wrap' }}>
                            {`${first(result?.lots)?.deliveryAddress?.thirdPartyAddress || ''} ${first(result?.lots)?.deliveryAddress?.addressNo || ''} ${first(result?.lots)?.deliveryAddress?.subDistrict || ''} ${first(result?.lots)?.deliveryAddress?.district || ''} ${first(result?.lots)?.deliveryAddress?.province || ''} ${first(result?.lots)?.deliveryAddress?.postcode || ''}`}
                          </SmallText>
                        </Div>
                      }
                    </Div>
                    <Div
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                      margin="8px 0"
                    >
                      <P bold>หมายเหตุ</P>
                      <Uploader
                        isMultiple
                        buttonText="แนบเอกสาร"
                        inverse={false}
                        icon="fal fa-paperclip"
                        onChange={(e) => {
                          setFileList(e)
                        }}
                        data={fileList.map((file) => {
                          if (file.url) {
                            return {
                              name: file.name,
                              url: file.url,
                              uid: file.uid,
                            }
                          }
                          return file
                        })}
                      />
                    </Div>
                    <InputAntd.TextArea
                      onChange={(e) => setRemark(e.target.value)}
                      style={{
                        backgroundColor: theme.color.gray10,
                        margin: '16px 0',
                        padding: '16px',
                        border: 'none',
                      }}
                    />
                  </Div>
                </Col>
              </Row>
              : null
          ))}
        {
          result
          && <Div style={{ position: 'sticky', bottom: 0 }} bgColor={theme.color.offWhite} display='flex' justifyContent='space-between' alignItems='center'>
            <Div margin='16px 0 0 0' display='flex' alignItems='center'>
              <P>รวมค่ากล่อง:</P>
              <H4 bold color={theme.color.primaryColor} margin='0 0 0 8px'>{numeral(sumBy(filter(boxes, (o) => o.amount > 0), (o) => o.amount * o.price)).format('0,0.[00]')} บาท</H4>
            </Div>
            <Div margin='16px 0 0 0' display='flex' alignItems='center'>
              <Button
                width='100px'
                margin='0 8px 0 0'
                disabled={createPtloading}
                onClick={() => history.push(PATH.pt)}
                inverse
                text="ยกเลิก"
                color={theme.color.gray50}
              />
              <Button
                width='200px'
                loading={createPtloading}
                disabled={
                  createPtloading
                  || some(itemCounts, (o) => o.quantity !== o.count) || isEmpty(filter(boxes.map((v) => ({ boxId: v.id, quantity: v.amount })), (o) => o.quantity > 0))
                  || isNil(selectedWarehouse)
                }
                onClick={() => doCreatePT({
                  variables: {
                    warehouseId: selectedWarehouse,
                    lot: {
                      id: first(result?.lots)?.id,
                      orderItems: combinedMergedItemsQuantity?.map((v) => ({
                        id: v.id,
                      })),
                    },
                    remark,
                    files: fileList,
                    box: filter(boxes.map((v) => ({ boxId: v.id, quantity: v.amount })), (o) => o.quantity > 0),
                  } }).then((res) => {
                  showMessageMutation(res.data?.createPickingTicket, () => {
                    setItemCounts([])
                    setOpenManageBox(false)
                    setSearch('')
                    setFileList([])
                    setBoxes(boxes.map((v) => ({ ...v, amount: 0 })))
                    setRemark('')
                    setSelectedWarehouse()
                  })
                })}
                inverse
                text="บันทึก"
                color={theme.color.success}
              />
            </Div>
          </Div>
        }
      </Div>
    </FullPageContainer>
  )
}

export default PtForm
