import React from 'react'
import { Link } from 'react-router-dom'
import logo from '../assets/logo-circle-new.png'
import Header from '../components/menu/header'
import { PATH } from './path'

export default {
  navTheme: 'light',
  primaryColor: '#E83836',
  layout: 'sidemenu',
  contentWidth: 'Fluid',
  fixedHeader: false,
  autoHideHeader: false,
  fixSiderbar: false,
  colorWeak: false,
  menu: {
    locale: true,
  },
  rightContentRender: () => <Header />,
  title: 'Winkwhite ERP',
  pwa: false,
  iconfontUrl: '',
  logo,
  footerRender: () => <div></div>,
  menuItemRender: (menuItemProps, defaultDom) => {
    if (menuItemProps.isUrl) {
      return defaultDom
    }

    return <Link to={menuItemProps.path}>{defaultDom}</Link>
  },
}

export const menuItems = [
  {
    id: 'home',
    path: '/home',
    name: 'หน้าแรก',
    icon: ' far fa-home',
  }, {
    id: 'tax-invoice',
    path: PATH.receipts.name,
    name: 'ใบกำกับภาษี',
    icon: ' far fa-file-invoice-dollar',
  }, {
    id: 'sale-orders',
    path: '/sale-orders',
    name: 'ใบออเดอร์',
    icon: ' far fa-receipt',
  }, {
    id: 'unmatched-sale-orders',
    path: '/unmatched-sale-orders',
    name: 'ออเดอร์ตกค้าง',
    icon: ' far fa-receipt',
  }, {
    id: 'purchase-orders',
    path: PATH.poList,
    name: 'Supplier',
    icon: ' far fa-envelope-open-dollar',
  }, {
    id: 'good-receives',
    path: PATH.gr,
    name: 'ใบรับสินค้า',
    icon: ' far fa-dolly-flatbed-alt',
  }, {
    id: 'picking-tickets',
    path: PATH.pt,
    name: 'การจัดส่ง',
    icon: ' far fa-truck-loading',
  }, {
    id: 'inventory',
    path: '/inventory',
    name: 'สต๊อกสินค้า',
    icon: ' far fa-warehouse',
  }, {
    id: 'product-groups',
    path: '/product-groups',
    name: 'เซตสินค้า',
    icon: ' far fa-boxes',
  }, {
    id: 'customers',
    path: '/customers',
    name: 'ลูกค้า',
    icon: ' far fa-user-tie',
  }, {
    id: 'suppliers',
    path: '/suppliers',
    name: 'ผู้ผลิต',
    icon: ' far fa-user-tie',
  }, {
    id: 'warehouses',
    path: '/warehouses',
    name: 'คลังสินค้า',
    icon: ' far fa-map-marked-alt',
  }, {
    id: 'users',
    path: '/users',
    name: 'ผู้ใช้',
    icon: ' far fa-users',
  }, {
    id: 'pricing-tiers',
    path: '/pricing-tiers',
    name: 'ระดับลูกค้า',
    icon: ' far fa-address-card',
  }, {
    id: 'tag',
    path: PATH.TAG,
    name: 'จัดการแท็ค',
    icon: ' far fa-hashtag',
  }, {
    id: 'commissions',
    path: PATH.COMMISSIONS,
    name: 'จัดการค่าคอมมิชชั่น',
    icon: ' far fa-badge-percent',
  }, {
    id: 'roles',
    path: PATH.roles,
    name: 'หน้าที่และการเข้าถึง',
    icon: ' far fa-fingerprint',
  }, {
    id: 'userPagePermissions',
    path: PATH.USER_PAGE_PERMISSIONS,
    name: 'การเข้าถึงเพจ',
    icon: 'fab fa-facebook-messenger',
  }, {
    id: 'reports',
    path: '/reports',
    name: 'รายงาน',
    icon: 'far fa-file-spreadsheet',
  }, {
    id: 'dashboard',
    path: '/dashboard',
    name: 'Dashboard',
    icon: 'far fa-analytics',
  }, {
    id: 'facebook',
    path: '/facebook',
    name: 'Facebook Page',
    icon: 'fab fa-facebook',
  },
]
