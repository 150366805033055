/* eslint-disable no-underscore-dangle */
import { ApolloClient, ApolloLink, split } from '@apollo/client'
import { InMemoryCache } from '@apollo/client/cache'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'
import { WebSocketLink } from '@apollo/client/link/ws'
import { getMainDefinition } from '@apollo/client/utilities'
import { Modal } from 'antd'
import { createUploadLink } from 'apollo-upload-client'
import { includes } from 'lodash'
import URL from './constants/environment'
import { getAccessToken, removeAccessToken, removeRefreshToken } from './utils/local-data-service'

const token = getAccessToken()
const NETWORK_INTERFACE_URL = token ? URL.GRAPH_PRIVATE : URL.GRAPH_PUBLIC

console.info('////////////')
console.info(NETWORK_INTERFACE_URL)
console.info('/////////////')

const SUBSCRIPTION_CLIENT_URL = 'wss://subscriptions.graph.cool/v1/API_KEY'

const authLink = setContext((_, { headers }) => ({
  headers: {
    ...headers,
    authorization: token ? `Bearer ${token}` : '',
  },
}))

const uploadLink = createUploadLink({
  uri: NETWORK_INTERFACE_URL,
})

// Create a WebSocket link:
const wsLink = new WebSocketLink({
  uri: SUBSCRIPTION_CLIENT_URL,
})

// using the ability to split links, you can send data to each link
// depending on what kind of operation is being sent
const link = split(
  // split based on operation type
  ({ query }) => {
    const { kind, operation } = getMainDefinition(query)
    return kind === 'OperationDefinition' && operation === 'subscription'
  },
  wsLink,
  authLink.concat(uploadLink),
)

const client = new ApolloClient({
  link: ApolloLink.from([
    onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors?.[0]?.extensions?.exception.code !== '2003') {
        if (includes(graphQLErrors?.[0]?.message, 'jwt expired')) {
          console.error('Token Expired')
          removeAccessToken()
          removeRefreshToken()
          window.location = `${URL.WEB_SERVER}/login`
        } else {
          Modal.error({
            title: graphQLErrors?.[0]?.message,
            content: `Network error: ${networkError?.statusCode}`,
            onOk() {
              window.location = `${URL.WEB_SERVER}/`
            },
          })
        }
      }
    }),
    // authLink.concat(uploadLink),
    link,
  ]),
  cache: new InMemoryCache({
    dataIdFromObject: (o) => (o._id ? `${o.__typename}:${o._id}` : null),
  }),
  defaultOptions: {
    watchQuery: {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only',
      errorPolicy: 'ignore',
      nextFetchPolicy: 'cache-first',
    },
    query: {
      fetchPolicy: 'network-only',
      errorPolicy: 'all',
      nextFetchPolicy: 'cache-first',
    },
    mutate: {
      errorPolicy: 'all',
    },
  },
})

export default client
