import styled, { css } from 'styled-components'
import { Div } from '@konsys-ui-custom'
import { Tabs } from 'antd'
import { theme } from '../../styles/_variables'
import Img from '../../components/common/img'

export const Image = styled(Img)`
  width: ${(props) => props.size || '100px'};
  height: ${(props) => props.size || '100px'};
  object-fit: cover;
  border-radius: 5px;
  border: 1px ${theme.color.primaryColor10} solid;
`

export const PartImage = styled(Img)`
  width: 250px;
  height: 250px;
  object-fit: cover;
  border-radius: 5px;
  border: 1px ${theme.color.primaryColor10} solid;
`

export const ThumbnailImage = styled(Img)`
width: ${(props) => props.size || '45px'};
height: ${(props) => props.size || '45px'};
  object-fit: contain;
  object-position: center;
  border-radius: 5px;
  border: 1px ${theme.color.primaryColor10} solid;
  margin: 5px 3px;
  &:hover {
    cursor: pointer;
  }
`

export const TabWrapper = styled(Tabs)`

`

export const TabPane = styled(Tabs.TabPane)`

`

export const RadioWrapper = styled(Div)`
  border: 1px solid ${theme.color.lightBlue};
  padding: 16px;
  border-radius: 2px;
  > div {
    &:last-child {
      transition: max-height 0.5s ease-in-out;
      max-height: 0;
      overflow: hidden;
    }
  }
  ${(props) => props.open && css`
    > div {
      &:last-child {
        max-height: 5000px;
        margin-top: ${!props.isNone && '24px'};
      }
    }
  `}
`

export default TabWrapper
