import React, { useEffect } from 'react'
import { getAccessToken } from '../../utils/local-data-service'
import history from '../../history'
import { Container } from '../../components/login'

const LoginContainer = () => {
  useEffect(() => {
    if (getAccessToken()) {
      history.push('/')
    }
  })

  return (
    <Container />
  )
}

export default LoginContainer
