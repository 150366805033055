import { Button, Div, P } from '@konsys-ui-custom'
import { Tabs } from 'antd'
import styled, { css } from 'styled-components'
import { theme } from '../../styles/_variables'

export const TabWrapper = styled(Tabs)`

`

export const TabPane = styled(Tabs.TabPane)`

`

export const Link = styled(P)`
  cursor: pointer;
  color: ${(props) => (props.color ? props.color : theme.color.secondaryColor)};
  &:hover {
    color: ${(props) => (props.color ? `${props.color}CC` : `${theme.color.secondaryColor}CC`)};
  }
`

export const RadioWrapper = styled(Div)`
  border: 1px solid ${theme.color.lightBlue};
  padding: 16px;
  border-radius: 2px;
  > div {
    &:last-child {
      transition: max-height 0.5s ease-in-out;
      max-height: 0;
      overflow: hidden;
    }
  }
  ${(props) => props.open && css`
    > div {
      &:last-child {
        max-height: 2000px;
        margin-top: 24px;
      }
    }
  `}
`

export const DownLoadLink = styled(Div)`
  > a {
    color: ${theme.color.primaryColor};
    display: block;
    text-decoration: underline !important;
  }
  > img {
    width: 200px;
    border: 1px solid ${theme.color.primaryColor};
    border-radius: 2px;
    overflow: hidden;
  }
`

export const CancelSoButton = styled(Button)`
  position: absolute;
  left: 0;
`

export const RadioLabel = styled.div`
  min-width: 16px;
  width: 16px;
  height: 16px;
  border: 1px solid ${(props) => (props.disabled ? theme?.color?.gray20 : theme?.color?.primaryColor)};
  border-radius: 16px;
  background-color: ${(props) => (props.disabled ? theme?.color?.gray10 : theme?.color?.offWhite)};
  position: relative;
  margin: ${(props) => props.margin || '2px 0 0'};
  > div,
  > i {
    position: absolute;
    left: 3px;
    bottom: 3px;
    display: none;
  }
`

export const RadioTitleBoxWrapper = styled(Div)`
  > input {
    display: none;
    &:checked {
      & + label > div > div > ${RadioLabel} > div,
      & + label > div > ${RadioLabel} > i {
        display: block;
      }
    }
  }
`

export default TabWrapper
