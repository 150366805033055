import styled from 'styled-components'
import { theme } from '../../styles/_variables'

export const ShowDataWrapper = styled.div`
  margin: 32px 0 0;
  p,
  span {
    font-family: ${theme.fonts.family.normal};
    &.bold {
      font-weight: ${theme.fonts.style.medium};
    }
  }
  .small-text-with-label {
    > span {
      font-weight: ${theme.fonts.style.medium};
    }
  }
`

export default ShowDataWrapper
