/* eslint-disable import/no-cycle */
import styled, { css } from 'styled-components'
import React from 'react'
import { Div } from '@konsys-ui-custom'
import { Modal, Alert } from 'antd'
import { theme } from '../../styles/_variables'

export const DividerStyled = styled.hr`
  border: none;
  border-top: 1px solid ${(props) => props.color || theme.color.primaryColor10};
  margin: ${(props) => props.margin || '16px 0'};
`

export const TagWrapper = styled.div`
  cursor: pointer;
  display: inline-block;
  border-radius: 30px;
  padding: 2px 8px;
  background-color: ${theme.color.primaryColor10};
  ${(props) => props.margin && css`
    margin: ${props.margin};
  `}
`

export const SummaryCardWrapper = styled.div`
  background-color: ${theme.color.offWhite};
  border-radius: 2px;
  box-shadow: 0 0 3px ${theme.color.gray10};
  padding: 24px;
`

export const CollapseContentWrapper = styled.div`
  min-height: 0px;
  height: 0;
  transition: min-height ${theme.animate.time.maxHeight} ease-in-out;
  overflow: hidden;
  margin: 16px;
`

export const CollapseWrapper = styled((props) => <Div {...props} />)`
  > div {
    > i {
      transition: ${theme.animate.transition};
    }
  }
  ${(props) => props.open && css`
    > div {
      > i {
        transform: rotate(-180deg);
      }
    }
    ${CollapseContentWrapper} {
      min-height: ${props.minHeight};
      overflow: auto;
    }
    ${props.fullHeight && css`
      ${CollapseContentWrapper} {
        height: auto;
        overflow: hidden;
      }
    `}
  `}
`

export const CardListWrapper = styled((props) => <Div {...props} />)`
  background-color: ${theme.color.offWhite};
  padding: 16px;
  border-bottom: 2px solid ${theme.color.primaryColor10};
  cursor: pointer;
  ${(props) => props.active && css`
    background-color: ${theme.color.primaryColor10};
  `}
  ${(props) => props.newCard && css`
    background-color: ${theme.color.remark};
  `}
`

export const CardListHeaderWrapper = styled((props) => <Div {...props} />)`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const CardListContentWrapper = styled((props) => <Div {...props} />)`
  display: flex;
  align-items: flex-start;
  margin: 16px 0 0;
  > div {
    &:first-child {
      flex-basis: ${(props) => (props.fullWidth ? '100%' : '65%')};
    }
  }
`

export const ListWrapper = styled((props) => <Div {...props} />)`
  > ${CardListWrapper} {
    &:last-child {
      border-bottom: 0;
    }
  }
`

export const TabHeaderWrapper = styled((props) => <Div {...props} />)`
  border-bottom: 1px solid ${theme.color.primaryColor10};
  display: flex;
  justify-content: space-between;
`

export const TabContentWrapper = styled((props) => <Div {...props} />)`
  padding: 16px 16px 0;
  overflow: auto;
  background-color: ${(props) => (props.white ? theme.color.offWhite : theme.color.background)};
  height: calc(100% - 60px);
  ${(props) => props.filterHeight && css`
    position: absolute;
    left: 0;
    right: 0;
    overflow: auto;
  `}
  ${(props) => props.contentHeight && css`
    height: ${props.contentHeight};
  `}
`

export const TabWrapper = styled((props) => <Div {...props} />)`
  background-color: ${(props) => (props.white ? theme.color.offWhite : theme.color.background)};
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
`

export const TabTitleWrapper = styled((props) => <Div {...props} />)`
  display: inline-block;
  padding: 8px 16px;
  height: 100%;
  display: inline-flex;
  align-items: center;
  span {
    cursor: pointer;
  }
  ${(props) => props.active && css`
    background-color: ${theme.color.secondaryColor};
  `}
`

export const ModalCustomized = styled(Modal)`
  .ant-modal-header {
    border-style: none;
    padding: 24px 24px !important;
    .ant-modal-title {
      font-size: 22px;
    }
  }
  .ant-modal-footer {
    border-style: none;
    padding: 24px 24px !important;
    padding-top: 0 !important;
  }
  .ant-modal-body {
    padding-bottom: 8px !important;
  }
`

export const AlertCustomized = styled(Alert)`
  background-color: ${theme.color.remark};
  border: none;
  .ant-alert-message {
    font-weight: ${theme.fonts.style.regular};
    font-size: ${theme.fonts.size.small};
    margin: 0;
    color: ${theme.color.gray80};
  }
  .ant-alert-description {
    font-size: ${theme.fonts.size.normal};
    color: ${theme.color.gray80};
    word-break: break-all;
  }
  .ant-alert-icon {
    color: #faad14;
  }
`

export const RowSelectChildWrapper = styled((props) => <Div {...props} />)`
  ${(props) => props.header && css`
    background-color: ${theme.color.primaryColor10};
    padding: 8px 16px;
  `}
  > div {
    &:last-child {
      border: 0;
    }
  }
  ${(props) => props.style && props.style};
`

export const TableRowCollapseWrapper = styled.div`
  > div {
    > div {
      align-items: flex-start;
      width: 100%;
      > i {
        font-size: ${theme.fonts.size.normal};
        margin-top: 2px;
      }
      > p {
        width: 100%;
      }
    }
  }
`
export const RadioWrapper = styled.div`
  border: 1px solid ${theme.color.lightBlue};
  padding: 16px;
  border-radius: 2px;
  margin: 24px 0 0;
  > div {
    &:last-child {
      transition: max-height 0.5s ease-in-out;
      max-height: 0;
      overflow: hidden;
    }
  }
  ${(props) => props.open && css`
    > div {
      &:last-child {
        max-height: 5000px;
        /* overflow: scroll; */
        margin-top: ${!props.isNone && '24px'};
      }
    }
  `}
`

export default DividerStyled
