import { useState } from 'react'
import { message } from 'antd'
import { isEmpty, isNil } from 'lodash'

export const isErrorRequired = (errorType) => errorType === 'required'

let timeout = null

export const delayInput = (callback, timer = 1000) => {
  clearTimeout(timeout)
  timeout = setTimeout(() => {
    callback()
  }, timer)
}

export const toNumber = (string) => parseInt(string)

export const showMessageMutation = (data, callback) => {
  if (data?.success) {
    message.success(data?.message)
    if (callback) callback()
  } else {
    message.error(data?.message ?? 'Something went wrong')
  }
}

export const useForceUpdate = () => {
  const [value, setValue] = useState(0) // integer state
  return () => setValue(value + 1) // update the state to force render
}

export const roundNumber = (number) => Math.round(number * 100) / 100

export const isAlphaNumeric = (str) => {
  const regex = new RegExp(/^[a-zA-Z0-9]*$/)
  return regex.test(str) && !isNil(str) && !isEmpty(str)
}
