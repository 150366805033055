import { useMutation } from '@apollo/client'
import {
  Div, P,
} from '@konsys-ui-custom'
import { message } from 'antd'
import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { REQ_RESET_PASSWORD_TOKEN, RESET_PASSWORD } from '../../api/mutation'
import BG from '../../assets/login-background.png'
import { PATH } from '../../constants/path'
import history from '../../history'
import { getAccessToken } from '../../utils/local-data-service'
import { isEveryIndex, passwordValidator } from '../../utils/util-services'
import {
  CustomButton,
  CustomInput,
  LoginWrapper,
} from './styled'

const ForgotPasswordSection = () => {
  const [email, setEmail] = useState('')
  const [doReq, { loading }] = useMutation(REQ_RESET_PASSWORD_TOKEN)

  useEffect(() => {
    if (getAccessToken()) {
      history.push('/')
    }
  })

  const callDoReq = () => {
    doReq({
      variables: { email },
    }).then((resp) => {
      if (!resp.loading && resp.data.forgetPassword.success) {
        message.success(resp.data.forgetPassword.message)
        history.push(PATH.login)
      } else message.error(resp.data.forgetPassword.message)
    })
  }

  const onEnter = (e) => {
    if (e.keyCode === 13) {
      callDoReq()
    }
  }

  const isMobile = window.innerWidth <= 640

  return (
    <LoginWrapper>
      <Div>
        <img src={BG} />
        <Div className='forgot-password'>
          <Div>
            <h4>Forgot Password</h4>
            <CustomInput placeholder='Email' onChange={(e) => setEmail(e.target.value)} onKeyDown={onEnter} />
            <Div display='flex' alignItems='center' justifyContent='space-between'>
              <h4>Send Link</h4>
              <CustomButton
                loading={loading}
                disabled={loading || _.isEmpty(email)}
                inverse
                isMobile={isMobile}
                text={!isMobile && 'Send Link'}
                color={isMobile ? '#4C4B4B' : '#5BC6B2'}
                icon={isMobile && 'far fa-chevron-right'}
                onlyIcon={isMobile}
                buttonStyle='round'
                onClick={callDoReq}
              />
            </Div>
            <Link to='login' to2={PATH.Login} style={{ display: 'inline-block', alignSelf: 'flex-end', cursor: 'pointer' }} >
              <P display='block'>Back to login</P>
            </Link>
          </Div>
        </Div>
      </Div>
    </LoginWrapper>
  )
}

const ResetSection = (props) => {
  const [password, setPassword] = useState('')
  const [passwordConfirm, setPasswordConfirm] = useState('')

  const [doResetpassword, { loading }] = useMutation(RESET_PASSWORD)
  const validatePassword = passwordValidator(password) ? 'success' : password.length > 0 && 'error'
  const validateConfirmPassword = passwordConfirm.length > 0 && passwordConfirm === password ? 'success' : passwordConfirm.length > 0 && 'error'

  useEffect(() => {
    if (getAccessToken()) {
      history.push('/')
    }
  })

  const callDoResetPassword = () => {
    doResetpassword({
      variables: {
        token: props.match.params.token,
        newPassword: password,
      },
    }).then((resp) => {
      if (!resp.loading && resp.data.resetPassword.success) {
        message.success(resp.data.resetPassword.message)
        history.push('/')
      } else message.error(resp.data.resetPassword.message)
    })
  }

  const onEnter = (e) => {
    if (e.keyCode === 13 && validateConfirmPassword === 'success' && !loading) {
      callDoResetPassword()
    }
  }

  const isMobile = window.innerWidth <= 640

  return (
    <LoginWrapper>
      <Div>
        <img src={BG} />
        <Div className='reset-password'>
          <Div>
            <h4>Reset Password</h4>
            <CustomInput placeholder='New password' type='password' onChange={(e) => setPassword(e.target.value)} onKeyDown={onEnter} />
            <CustomInput placeholder='Confirm new password' type='password' onChange={(e) => setPasswordConfirm(e.target.value)} onKeyDown={onEnter} />
            <Div display='flex' alignItems='center' justifyContent='space-between'>
              <h4>Reset Password</h4>
              <CustomButton
                loading={loading}
                disabled={!isEveryIndex([validatePassword, validateConfirmPassword], 'success') || loading}
                inverse
                isMobile={isMobile}
                text={!isMobile && 'Reset Password'}
                color={isMobile ? '#4C4B4B' : '#5BC6B2'}
                icon={isMobile && 'far fa-chevron-right'}
                onlyIcon={isMobile}
                buttonStyle='round'
                onClick={callDoResetPassword}
              />
            </Div>
          </Div>
        </Div>
      </Div>
    </LoginWrapper>
  )
}
export { ForgotPasswordSection, ResetSection }
