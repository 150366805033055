import { useLazyQuery } from '@apollo/client'
import { ResponsiveBar } from '@nivo/bar'
import { Col, Divider, Input, Radio, Row, Skeleton, Space, TimePicker } from 'antd'
import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'
import relativeTime from 'dayjs/plugin/relativeTime'
import { Div, SmallText } from 'konsys-ui'
import { every, find, first, includes, isEmpty, isNil, keys, omit } from 'lodash'
import numeral from 'numeral'
import React, { useEffect, useState } from 'react'
import { BOX_SUMMARY, ORDER_SUMMARY, TIME_SPENT_REPORT } from '../../../api/query'
import { Loading, Select } from '../../../components'
import Img from '../../../components/common/img'
import { theme } from '../../../styles/_variables'
import { soStatusGroupMaptoText } from '../../sale-orders/utils'
import { RecieveMessage, SendMessage } from './messages'

require('dayjs/locale/th')

dayjs.extend(duration)
dayjs.extend(relativeTime)

const FILTER_TYPE = {
  ALL: {
    key: 'ALL',
    text: 'ทั้งหมด',
  },
  DAILY: {
    key: 'DAILY',
    text: 'รายวัน',
  },
  HOURLY: {
    key: 'HOURLY',
    text: 'รายชั่วโมง',
  },
}

const LABELS = {
  orders: 'ใบออเดอร์',
  addressLabelPrinted: 'พิมพ์ใบปะหน้าแล้วกำลังแพ็ค',
  pickingTicketCreated: 'เตรียมการจัดส่ง',
  pickingTicketWithTrackingNumber: 'ได้เลข tracking แล้ว',
  lessThanTen: 'ไม่เกิน 10 นาที',
  tenToFifteen: '10 - 15 นาที',
  fifteenToThirty: '15 - 30 นาที',
  thirtyToSixty: '30 - 60 นาที',
  moreThanSixty: 'มากกว่า 60 นาที',
}

const ChatCard = ({ data, isActive, onClick }) => (
  <Row onClick={() => onClick()} align='middle' justify='space-between' style={{
    backgroundColor: isActive ? theme.color.primaryColor10 : theme.color.offWhite,
    boxShadow: isActive ? 'rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px' : 'none',
    padding: 8,
    height: 100,
    margin: '8px 0',
    borderRadius: 4,
    cursor: 'pointer',
  }}>
    <Col span={12}>
      <Div>
        <Img
          src={data.chatContact.profileUrl}
          style={{ width: '50px', height: '50px', objectFit: 'cover', borderRadius: '50%', marginRight: 4 }}
        />
        <SmallText>{data.chatContact.displayName}</SmallText>
      </Div>
    </Col>
    <Col span={6}>
      <SmallText>({numeral(data?.chatMessages.length / 2).format('0,0')} ข้อความ)</SmallText>
    </Col>
    <Col span={6}>
      <SmallText color={theme.color.error}>นานสุด {dayjs.duration(data?.maximumTimeGap, 'minutes').locale('th').humanize()}</SmallText>
    </Col>
  </Row>
)

const MessagePair = ({ message, type }) => {
  if (type === 'RECEIVE') {
    return (
      <>
        <Divider />
        <RecieveMessage msg={message} />
      </>
    )
  } return (
    <SendMessage msg={message} type='LINE'/>
  )
}

const ChatDetail = ({ data }) => (
  <Div bgColor={theme.color.offWhite} padding='16px' margin='8px 0' style={{ borderRadius: 4, boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px' }}>
    <Row align='middle' justify='space-between'>
      <Col span={8}>
        <Div>
          <Img
            src={data?.chatContact?.profileUrl}
            style={{ width: '50px', height: '50px', objectFit: 'cover', borderRadius: '50%', marginRight: 4 }}
          />
          <SmallText>{data?.chatContact?.displayName}</SmallText>
        </Div>
      </Col>
      <Col span={8} style={{ textAlign: 'right' }}>
        <SmallText color={theme.color.error}>นานสุด {dayjs.duration(data?.maximumTimeGap, 'minutes').locale('th').humanize()}</SmallText>
      </Col>
    </Row>
    {
      data?.chatMessages?.map((message, idx) => <MessagePair key={`${message} + ${idx}`} message={message} type={idx % 2 === 0 ? 'RECEIVE' : 'SEND'}/>)
    }
  </Div>
)

export default ({ type, date, provider, userId, setHourly, teamId }) => {
  const [orderSummaryType, setOrderSummaryType] = useState('ALL')
  const [selectedStatus, setSelectedStatus] = useState(undefined)
  const [selectedChatId, setSelectedChatId] = useState(undefined)
  const [searchName, setSearchName] = useState(undefined)
  const [searchMessage, setSearchMessage] = useState(undefined)
  const [timePeriod, setTimePeriod] = useState([])
  const [getOrderSummary, { data: orderSummaryData, loading: orderSummaryLoading }] = useLazyQuery(ORDER_SUMMARY, { fetchPolicy: 'cache-and-network' })
  const [getBoxSummary, { data: boxSummaryData, loading: boxSummaryLoading }] = useLazyQuery(BOX_SUMMARY, { fetchPolicy: 'cache-and-network' })
  const [getTimeSpentReport, { data: timeSpentReportData, loading: timeSpentReportLoading }] = useLazyQuery(TIME_SPENT_REPORT, { fetchPolicy: 'cache-and-network' })

  const statusOption = ['INPROGRESS', 'COMPLETED', 'CANCELLED', 'OVERDUESHIPMENT', 'RETURNED'].map((v) => ({
    value: v,
    text: soStatusGroupMaptoText(v),
  })) || []
  const getNormalVariables = () => (
    {
      teamId: teamId === 'all' || includes(['ORDER_SUMMARY', 'BOX_SUMMARY'], type) ? undefined : teamId,
      chatProviderId: provider === 'all' ? undefined : provider,
      period: orderSummaryType === 'HOURLY' ? [date, date] : date,
      type: type === 'ORDER_SUMMARY' ? orderSummaryType : undefined,
      salesOrderStatus: selectedStatus !== 'ALL' ? selectedStatus : undefined,
    }
  )

  useEffect(() => {
    if (timeSpentReportData) {
      setSelectedChatId(first(timeSpentReportData?.timeSpentReport?.data?.chatList?.list)?.id)
    }
  }, [timeSpentReportData])

  useEffect(() => {
    switch (type) {
      case 'ORDER_SUMMARY':
        getOrderSummary({ variables: {
          input: getNormalVariables(),
        } })
        break
      case 'BOX_SUMMARY':
        getBoxSummary({ variables: {
          input: getNormalVariables(),
        } })
        break
      case 'TIME_SPENT':
        if (every(date, (o) => !isNil(o))) {
          getTimeSpentReport({ variables: {
            input: {
              ...getNormalVariables(),
              teamId: teamId === 'all' ? undefined : teamId,
              timePeriod: isEmpty(timePeriod) ? undefined : [dayjs(timePeriod[0]).format('HH:mm:ss'), dayjs(timePeriod[1]).format('HH:mm:ss')],
              adminId: parseInt(userId) || undefined,
              searchName,
              searchMessage,
            },
          } })
        }
        break
      default:
        break
    }
  }, [type, userId, provider, date, orderSummaryType, selectedStatus, timePeriod, searchName, searchMessage, teamId])

  useEffect(() => {
    if (type === 'ORDER_SUMMARY') {
      setHourly(orderSummaryType === 'HOURLY')
    }
  }, [orderSummaryType])

  const list = (
    type === 'ORDER_SUMMARY'
      ? orderSummaryData?.orderSummary?.data
      : type === 'BOX_SUMMARY'
        ? boxSummaryData?.boxSummary?.data
        : timeSpentReportData?.timeSpentReport?.data?.chart
  ) || []
  const chatList = timeSpentReportData?.timeSpentReport?.data?.chatList
  const data = type === 'TIME_SPENT'
    ? keys(omit(list, '__typename'))?.map((key) => (
      {
        id: LABELS[key] || key,
        เวลาตอบแชต: list[key],
      }
    ))
    : list?.map((detail) => (
      {
        id: LABELS[detail?.key] || detail?.key,
        TRANSFER: detail?.TRANSFER,
        COD: detail?.COD,
      }
    ))

  const pieColors = ['#CD77EB', '#3677EA', '#60C7E6', '#F14459', '#3ECB98', '#FFBF31']
  const commonProps = {
    data,
    keys: type === 'TIME_SPENT'
      ? [
        'เวลาตอบแชต',
      ]
      : [
        'TRANSFER',
        'COD',
      ],
    indexBy: 'id',
    margin: { top: 10, right: 10, bottom: 50, left: 60 },
    padding: 0.2,
    enableLabel: false,
    colors: pieColors,
  }
  return (
    <Div>
      <Div display='flex'>
        <Div margin='0 auto' moreStyle={{
          maxWidth: '100%', textAlign: 'center', height: '400px', flexGrow: 1,
        }}>
          {
            (orderSummaryLoading || boxSummaryLoading || timeSpentReportLoading) && isEmpty(data)
              ? <Loading margin='80px 0' />
              : isEmpty(data)
                ? <SmallText margin='80px 0' color={theme?.color?.gray50}>ไม่มีข้อมูล</SmallText>
                : <ResponsiveBar
                  {...commonProps}
                />
          }
        </Div>
        {
          type === 'ORDER_SUMMARY'
          && <Div padding='8px 16px 0 32px' width='calc((100vw * 200)/1440)' display='flex' flexDirection='column'>
            <SmallText bold display='block' margin='0 0 16px'>กรองตาม</SmallText>
            <Radio.Group vertical onChange={(e) => setOrderSummaryType(e.target.value)} value={orderSummaryType}>
              <Space direction="vertical">
                {
                  keys(FILTER_TYPE)?.map((filterKey) => (
                    <Radio key={FILTER_TYPE?.[filterKey]?.key} value={FILTER_TYPE?.[filterKey]?.key}><SmallText>{FILTER_TYPE?.[filterKey]?.text}</SmallText></Radio>
                  ))
                }
              </Space>
            </Radio.Group>
            <Select
              style={{ marginTop: 16 }}
              placeholder="สถานะใบออเดอร์"
              options={[{ value: 'ALL', text: 'ทั้งหมด' }, ...statusOption]}
              onChange={(value) => setSelectedStatus(value)}
              value={selectedStatus}
            />
          </Div>
        }
        {
          type === 'TIME_SPENT'
          && <Div padding='8px 16px 0 32px' width='calc((100vw * 200)/1440)' display='flex' flexDirection='column'>
            <SmallText margin='0 0 4px 0' color={theme?.color?.gray50}>กรองช่วงเวลาตอบแชต</SmallText>
            <TimePicker.RangePicker format='HH:mm' onChange={(e) => setTimePeriod(e)}/>
          </Div>
        }
      </Div>
      {
        type === 'TIME_SPENT'
        && <Div>
          <Div display='flex' flexDirection='column'>
            <Div display='flex'>
              <Input style={{ width: 200, marginRight: 16 }} placeholder='ค้นหาผู้ใช้' onChange={(e) => setSearchName(e.target.value)} />
              <Input style={{ width: 200 }} placeholder='ค้นหาข้อความ' onChange={(e) => setSearchMessage(e.target.value)}/>
            </Div>
            <SmallText margin='4px 0'>จำนวนแชตทั้งหมด {numeral(chatList?.count).format('0,0')} แชต</SmallText>
          </Div>
          {
            timeSpentReportLoading
              ? <Skeleton active />
              : isEmpty(chatList?.list)
                ? <Div display='flex' flexDirection='column' alignItems='center'>
                  <SmallText margin='80px 0' color={theme?.color?.gray50}>ไม่มีข้อมูล</SmallText>
                </Div>
                : <Row gutter={16}>
                  <Col span={6}>
                    {
                      chatList?.list?.map((chat) => (
                        <ChatCard key={chat?.id} isActive={chat?.id === selectedChatId} data={chat} onClick={() => setSelectedChatId(chat.id)}/>
                      ))
                    }
                  </Col>
                  <Col span={18}>
                    <ChatDetail data={find(chatList?.list, (chat) => chat.id === selectedChatId)}/>
                  </Col>
                </Row>
          }
        </Div>
      }
    </Div>
  )
}
