import styled, { css } from 'styled-components'
import { Div, Icon } from '@konsys-ui-custom'
import { theme } from '../../styles/_variables'

export const Card = styled(Div)`
  background-color: white;
  padding: 41px 24px 24px;
  position: relative;
  width: calc((100% - 48px) / 3);
  border-radius: 2px;
  margin: 40px 8px 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  > div > span {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    border-radius: 100%;
    position: absolute;
    left: 24px;
    top: -25px;
    ${(props) => props.iconBgColor && css`
      background-color: ${props.iconBgColor};
    `}
  }
  button {
    &:disabled {
      > p {
        color: #63A7E066 !important;
      }
    }
  }
`

export const IconCustomed = styled(Icon)`
  background: ${`-webkit-gradient(linear, left top, left bottom, from(${theme.color.blue}), to(#fff))`};
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`

export default Card
