import React, { useState } from 'react'
import _ from 'lodash'
import { Div, Icon, Button, H4, SmallText, Label } from '@konsys-ui-custom'
import { message, Radio } from 'antd'
import { useHistory } from 'react-router-dom'
import { useQuery, useMutation } from '@apollo/client'
import { Helmet } from 'react-helmet'
import { ROLES, TEAM_LIST, USER, WAREHOUSES } from '../../api/query'
import { CREATE_USER, UPDATE_USER } from '../../api/mutation'
import {
  SelectWithLabel,
  InputWithLabel,
  FullPageContainer,
} from '../../components'
import { STATUS } from './constants'
import { validateData } from '../../utils/util-services'
import { theme } from '../../styles/_variables'

export default (props) => {
  const isCreateMode = _.includes(props.match.url, 'create')
  const { data, loading } = useQuery(ROLES)
  const history = useHistory()
  const [doAction, { loading: mutationLoading }] = useMutation(
    isCreateMode ? CREATE_USER : UPDATE_USER,
  )
  const { loading: infoLoading, data: infoData } = useQuery(USER, {
    skip: isCreateMode,
    variables: { id: parseInt(props.match.params.id) },
  })
  const [mutationVariables, setMutationVariables] = useState({})
  const { data: warehouseData, loading: warehouseLoading } = useQuery(WAREHOUSES)
  const { data: teamData, loading: teamLoading } = useQuery(TEAM_LIST)

  const warehouseList = !warehouseLoading && warehouseData && warehouseData.warehouseList
    ? warehouseData.warehouseList.data.list.map((v) => ({
      text: v.warehouseName,
      value: v.id,
    }))
    : []

  return (
    <Div>
      <Helmet>
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>
      <FullPageContainer
        loading={infoLoading}
        title={[
          {
            text: 'Users',
            icon: 'fad fa-users',
          },
        ]}
        detail={{
          title: [
            {
              text: isCreateMode ? 'สร้างผู้ใช้ใหม่' : 'แก้ไขผู้ใช้ใหม่',
              icon: isCreateMode ? 'far fa-plus' : 'far fa-edit',
            },
          ],
        }}
      >
        {!infoLoading ? (
          !isCreateMode && _.isNull(infoData.userDetail.data) ? (
            <H4>ไม่พบ</H4>
          ) : (
            <Div display="flex" flexDirection="column" padding="0 60px">
              <InputWithLabel
                input={{
                  prefix: <Icon icon="prefix far fa-signature" />,
                  width: '100%',
                  defaultValue: !isCreateMode
                    ? infoData.userDetail.data.firstName
                    : undefined,
                  state:
                    _.isEmpty(
                      !isCreateMode
                        ? infoData.userDetail.data.firstName
                        : mutationVariables.firstName,
                    ) && 'error',
                }}
                onChange={(e) => setMutationVariables({
                  ...mutationVariables,
                  firstName: e.target.value,
                })
                }
                title={{
                  text: (
                    <SmallText bold>
                      ชื่อจริง <Label color={theme.color.error}>*</Label>
                    </SmallText>
                  ),
                }}
              />
              <InputWithLabel
                input={{
                  prefix: <Icon icon="prefix far fa-signature" />,
                  width: '100%',
                  defaultValue: !isCreateMode
                    ? infoData.userDetail.data.lastName
                    : undefined,
                  state:
                    _.isEmpty(
                      !isCreateMode
                        ? infoData.userDetail.data.lastName
                        : mutationVariables.lastName,
                    ) && 'error',
                }}
                onChange={(e) => setMutationVariables({
                  ...mutationVariables,
                  lastName: e.target.value,
                })
                }
                title={{
                  text: (
                    <SmallText bold>
                      นามสกุล <Label color={theme.color.error}>*</Label>
                    </SmallText>
                  ),
                }}
              />
              <Div display="flex">
                <InputWithLabel
                  width="50%"
                  margin="8px 20px 8px 0"
                  input={{
                    prefix: <Icon icon="prefix far fa-envelope" />,
                    width: '100%',
                    defaultValue: !isCreateMode
                      ? infoData.userDetail.data.email
                      : undefined,
                    state:
                      _.isEmpty(
                        !isCreateMode
                          ? infoData.userDetail.data.email
                          : mutationVariables.email,
                      ) && 'error',
                  }}
                  onChange={(e) => setMutationVariables({
                    ...mutationVariables,
                    email: e.target.value,
                  })
                  }
                  title={{
                    text: (
                      <SmallText bold>
                        Email <Label color={theme.color.error}>*</Label>
                      </SmallText>
                    ),
                  }}
                />
                {isCreateMode && (
                  <InputWithLabel
                    width="100%"
                    input={{
                      prefix: <Icon icon="prefix far fa-lock" />,
                      width: '100%',
                      defaultValue: !isCreateMode
                        ? infoData.userDetail.data.password
                        : undefined,
                      type: 'password',
                      state:
                        _.isEmpty(
                          !isCreateMode
                            ? infoData.userDetail.data.password
                            : mutationVariables.password,
                        ) && 'error',
                    }}
                    onChange={(e) => setMutationVariables({
                      ...mutationVariables,
                      password: e.target.value,
                    })
                    }
                    title={{
                      text: (
                        <SmallText bold>
                          Password <Label color={theme.color.error}>*</Label>
                        </SmallText>
                      ),
                    }}
                  />
                )}
              </Div>
              <InputWithLabel
                width="100%"
                margin="8px 20px 8px 0"
                input={{
                  prefix: <Icon icon="prefix far fa-phone" />,
                  width: '100%',
                  defaultValue: !isCreateMode
                    ? infoData.userDetail.data.phoneNumber
                    : undefined,
                  state:
                      _.isEmpty(
                        !isCreateMode
                          ? infoData.userDetail.data.phoneNumber
                          : mutationVariables.phoneNumber,
                      ) && 'error',
                }}
                onChange={(e) => setMutationVariables({
                  ...mutationVariables,
                  phoneNumber: e.target.value,
                })
                }
                title={{
                  text: (
                    <SmallText bold>
                        Phone Number <Label color={theme.color.error}>*</Label>
                    </SmallText>
                  ),
                }}
              />
              <Div margin="8px 0" display="flex" alignItems="center">
                <SelectWithLabel
                  loading={loading}
                  style={{ marginRight: 16 }}
                  title={{
                    text: (
                      <SmallText bold>
                        ตำแหน่งงาน <Label color={theme.color.error}>*</Label>
                      </SmallText>
                    ),
                  }}
                  select={{
                    defaultValue: !isCreateMode
                      ? infoData.userDetail.data.roles[0].id
                      : undefined,
                    placeholder: 'เลือกตำแหน่งงาน',
                    onChange: (e) => setMutationVariables({ ...mutationVariables, roles: [e] }),
                    options: loading
                      ? []
                      : data.roleList.data.list.map((v) => ({
                        text: v.description,
                        value: v.id,
                      })),
                    className: !isCreateMode
                      ? _.isNaN(infoData.userDetail.data.roles[0].id)
                      : (_.isNaN(mutationVariables.roles)
                          || _.isUndefined(mutationVariables.roles))
                        && 'state-error',
                  }}
                />
                <SelectWithLabel
                  loading={teamLoading}
                  style={{ marginRight: 16 }}
                  title={{
                    text: (
                      <SmallText bold>
                        ทีม <Label color={theme.color.error}>*</Label>
                      </SmallText>
                    ),
                  }}
                  select={{
                    showSearch: true,
                    // notFoundContent: null,
                    // showArrow: false,
                    // filterOption: false,
                    defaultValue: !isCreateMode
                      ? infoData.userDetail.data.team.teamName
                      : undefined,
                    placeholder: 'เลือกทีม',
                    onChange: (e, other) => setMutationVariables({ ...mutationVariables, teamId: other.id }),
                    options: teamLoading
                      ? []
                      : teamData.teamList.data.list.map((v) => ({
                        id: v.id,
                        text: v.teamName,
                        value: v.teamName,
                      })),
                  }}
                />
                {(!_.isEmpty(mutationVariables) && mutationVariables.roles
                  ? mutationVariables.roles[0] === 5
                    || mutationVariables.roles[0] === 3
                    || mutationVariables.roles[0] === 7
                  : !_.isEmpty(infoData)
                    && (infoData.userDetail.data.roles[0].id === 5
                      || infoData.userDetail.data.roles[0].id === 3
                      || infoData.userDetail.data.roles[0].id === 7)) && ( // warehouse manager
                  <SelectWithLabel
                    margin="0 0 0 16px"
                    title={{
                      text: (
                        <SmallText bold>
                          คลังสินค้า <Label color={theme.color.error}>*</Label>
                        </SmallText>
                      ),
                    }}
                    select={{
                      defaultValue: !isCreateMode
                        ? infoData?.userDetail?.data?.warehouse?.id
                        : undefined,
                      placeholder: 'เลือกคลังสินค้า',
                      onChange: (e) => setMutationVariables({
                        ...mutationVariables,
                        warehouseId: e,
                      }),
                      options: warehouseList,
                      className: !isCreateMode
                        ? _.isNaN(infoData?.userDetail?.data?.warehouse?.id)
                        : (_.isNaN(mutationVariables.warehouseId)
                            || _.isUndefined(mutationVariables.warehouseId))
                          && 'state-error',
                    }}
                  />
                )}
              </Div>
              {!isCreateMode && (
                <Radio.Group
                  style={{ margin: '16px 0', alignSelf: 'center' }}
                  defaultValue={infoData.userDetail.data.status}
                  onChange={(e) => setMutationVariables({
                    ...mutationVariables,
                    status: e.target.value,
                  })
                  }
                >
                  <Radio.Button value={STATUS.INACTIVE}>
                    ปิดการใช้งาน
                  </Radio.Button>
                  <Radio.Button value={STATUS.ACTIVE}>เปิดการใช้งาน</Radio.Button>
                </Radio.Group>
              )}
              {/* {
                  !isCreateMode
                    && <Radio.Group
                          style={{ margin: '16px 0', alignSelf: 'center' }}
                          value={infoData.userDetail.data.status}
                          onChange={e => setMutationVariables({ ...mutationVariables, status: e.target.value })}
                        >
                          <Radio.Button value={STATUS.INACTIVE} style={{ background: mutationVariables.status === STATUS.INACTIVE && theme.color.error, border: mutationVariables.status === STATUS.INACTIVE && `1px solid ${theme.color.error}` }}>
                            <P bold color={mutationVariables.status === STATUS.INACTIVE ? theme.color.offWhite : theme.color.gray80}>ปิดการใช้งาน</P>
                          </Radio.Button>
                          <Radio.Button value={STATUS.ACTIVE} style={{ background: mutationVariables.status === STATUS.ACTIVE && theme.color.success, border: mutationVariables.status === STATUS.ACTIVE && `1px solid ${theme.color.success}` }}>
                            <P bold color={mutationVariables.status === STATUS.ACTIVE ? theme.color.offWhite : theme.color.gray80}>เปิดการใช้งาน</P>
                          </Radio.Button>
                        </Radio.Group>
                } */}
              <Button
                loading={mutationLoading}
                text={isCreateMode ? 'สร้างผู้ใช้' : 'แก้ไขผู้ใช้'}
                inverse
                color={theme.color.success}
                icon={isCreateMode ? 'far fa-plus' : 'far fa-edit'}
                style={{ alignItems: 'center' }}
                disabled={
                  mutationLoading
                  || validateData(
                    isCreateMode,
                    [
                      mutationVariables.firstName,
                      mutationVariables.lastName,
                      mutationVariables.password,
                      mutationVariables.email,
                      mutationVariables.roles,
                      mutationVariables.teamId,
                      mutationVariables?.roles
                      && (mutationVariables?.roles[0] === 5
                        || mutationVariables?.roles[0] === 3
                        || mutationVariables?.roles[0] === 7)
                        ? mutationVariables.warehouseId || null
                        : 'noCheck',
                    ],
                    { ...mutationVariables },
                  )
                }
                width="150px"
                margin="20px auto"
                onClick={() => {
                  doAction({
                    variables: isCreateMode
                      ? _.omit({ ...mutationVariables }, [
                        mutationVariables.roles[0] !== 5
                          && mutationVariables.roles[0] !== 3
                          && mutationVariables.roles[0] !== 7
                          ? 'warehouseId'
                          : undefined,
                      ])
                      : {
                        ...mutationVariables,
                        warehouseId:
                            infoData.userDetail.data.roles[0].id === 5
                            || infoData.userDetail.data.roles[0].id === 3
                            || infoData.userDetail.data.roles[0].id === 7
                              ? _.isUndefined(mutationVariables.warehouseId)
                                ? infoData?.userDetail?.data?.warehouse?.id
                                : mutationVariables.warehouseId
                              : undefined,
                        id: parseInt(props.match.params.id),
                      },
                  }).then((resp) => {
                    const KEY = isCreateMode ? 'createUser' : 'updateUser'
                    if (resp.data[KEY].success) {
                      message.success(resp.data[KEY].message)
                      history.push('/users')
                    } else message.error(resp.data[KEY].message)
                  })
                }}
              />
            </Div>
          )
        ) : null}
      </FullPageContainer>
    </Div>
  )
}
