import { useLazyQuery, useMutation } from '@apollo/client'
import { Div, H4, Label, SmallText } from '@konsys-ui-custom'
import { Skeleton } from 'antd'
import { first } from 'lodash'
import React from 'react'
import styled from 'styled-components'
import { UPDATE_USER_PROVIDER } from '../../api/mutation'
import { USERS, USER_PROVIDERS } from '../../api/query'
import { SelectWithLabel, Tab } from '../../components'
import Img from '../../components/common/img'
import { theme } from '../../styles/_variables'
import { delayInput, showMessageMutation } from '../../utils'
import { ActionCheckBox } from './styled'

export const Image = styled(Img)`
  border-radius: 50%;
  width: 50px;
  height: 50px;
  margin-right: 8px;
`

const Box = styled(Div)`
  display: flex;
  justify-content: ${(props) => props.justifyContent || 'center'};
  align-items: ${(props) => props.alignItems || 'center'};
  width: 200px;
  height: auto;
  min-height: 82px;
  padding: 8px 16px;
  border-right: 1px solid ${theme.color.gray10};
`

export default () => {
  const [getPerms, { data, loading }] = useLazyQuery(USER_PROVIDERS)
  const [getUsers, { data: usersData, loading: usersLoading }] = useLazyQuery(USERS)
  const [doUpdate] = useMutation(UPDATE_USER_PROVIDER)
  return (
    <Tab
      fullHeight
      title={[
        {
          text: 'การเข้าถึงเพจ',
          icon: 'fab fa-facebook-messenger',
        },
      ]}
    >
      <Div display='flex' flexDirection='column'>
        <Div width='100%' display='flex' alignItems='center' justifyContent='space-between'>
          <H4 bold>การเข้าถึงเพจ</H4>
          <SelectWithLabel
            width='25%'
            margin='0 16px 0 0'
            title={{
              text: <SmallText bold>Seller <Label color={theme.color.error}>*</Label></SmallText>,
            }}
            select={{
              showSearch: true,
              loading: usersLoading,
              notFoundContent: null,
              filterOption: false,
              style: { width: '100%' },
              placeholder: 'เลือก Seller',
              onSelect: (e) => {
                getPerms({ variables: { userId: e } })
              },
              onSearch: (value) => {
                const searchValue = value
                delayInput(() => {
                  getUsers({ variables: {
                    limit: 10,
                    offset: 0,
                    name: searchValue,
                  } })
                })
              },
              options: usersLoading
                ? []
                : (usersData?.userList?.data?.list?.map((v) => ({ value: v.id, text: `${v.firstName} ${v.lastName}`, user: v })) || []),
            }}
          />
        </Div>
        {
          loading
            ? <Skeleton />
            : data
              ? <Div width='100%' height='calc(100vh - 248px)' margin='16px 0' style={{ overflow: 'auto', position: 'relative' }}>
                <Div height='100px' display='flex' width='max-content' style={{
                  borderBottom: `1px solid ${theme.color.gray10}`, overflow: 'auto', position: 'sticky', top: 0, background: theme.color.background, zIndex: 2,
                }}>
                  <Box />
                  {
                    first(data?.userProviders?.data)?.chatProviders?.map((v) => (
                      <Box
                        alignItems='center'
                        key={v.chatProvider.id}
                      >
                        <Div flexDirection='column'>
                          <SmallText bold>{v.chatProvider.name}</SmallText>
                        </Div>
                      </Box>
                    ))
                  }
                </Div>
                {
                  data?.userProviders?.data.map((v) => (
                    <Div key={v.user.id} height='100px' width='max-content' display='flex' style={{ borderBottom: `1px solid ${theme.color.gray10}` }}>
                      <Box justifyContent='flex-start' style={{ position: 'sticky', left: 0, zIndex: 1, background: theme.color.background }}><SmallText bold>{v.user.firstName} {v.user.lastName}</SmallText></Box>
                      {
                        v?.chatProviders.map((p) => <Box key={p.chatProvider.id}>
                          <ActionCheckBox
                            defaultChecked={p.status}
                            data={{ value: `${v.user.id}_${p.chatProvider.id}`, text: '' }}
                            onChange={() => {
                              doUpdate({
                                variables: {
                                  input: {
                                    userId: v.user.id,
                                    providerId: p.chatProvider.id,
                                  },
                                },
                              }).then((resp) => {
                                showMessageMutation(resp.data.updateUserProvider)
                              })
                            }}
                          />
                        </Box>)
                      }
                    </Div>
                  ))
                }
              </Div>
              : null
        }
      </Div>
    </Tab>
  )
}
