export const PAYMENT_TYPE = {
  CREDIT: 'CREDIT',
  CASH: 'CASH',
}

export const PERSON_TYPE = {
  INDIVIDUAL: 'INDIVIDUAL',
  CORPORATION: 'CORPORATION',
}

export const BANK_TYPE = {
  SAVING: 'SAVING',
  FIXED: 'FIXED',
  CURRENT: 'CURRENT',
  FOREIGN: 'FOREIGN',
  BILL: 'BILL',
  LOTTERY: 'LOTTERY',
  BOND: 'BOND',
}

export const PREFIX = [
  'คุณ', 'ช่าง', 'เฮีย', 'นาย', 'นาง', 'นางสาว', 'หม่อมหลวง', 'หม่อมราชวงศ์', 'หม่อมเจ้า', 'คุณหญิง', 'ท่านผู้หญิง', 'ผศ.', 'รศ.', 'ศ.',
]
