import styled from 'styled-components'
import {
  Div, Checkbox,
} from '@konsys-ui-custom'
import { media } from '../../../styles/_variables'

export const RoleList = styled(Div)`
  display: flex;
  justify-content: space-between;
  padding: 0 0 16px 300px;
  margin: 0 0 24px 0;
  > div:first-child {
    width: 0%;
  }
  > div {
    display: flex;
    flex-direction: row;
    width: auto;
  }
  /* ${media.mobileL`
    margin: 0 0 24px;
    > div {
      padding: 6px;
    }
  `} */
`

export const ActionCheckBoxContainer = styled(Div)`
  display: flex;
  padding: 0 0 16px 32px;
  align-items: center;
  justify-content: space-between;
  > div:first-child {
    width: 0%;
  }
  > div {
    display: flex;
    flex-direction: row;
    width: auto;
  }
  /* ${media.mobileL`
    flex-direction: column;
    align-items: flex-start;
    padding: 0;
    > div {
      display: flex;
      flex-direction: row;
      width: 100%;
      margin: 0 0 8px;
    }
  `} */
`

export const ActionCheckBox = styled(Checkbox)`
  > label {
    > span {
      ::before {
        font-family: 'Font Awesome 5 Pro'; 
      }
    }
  }
`

export default RoleList
