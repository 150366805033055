import { useQuery } from '@apollo/client'
import {
  Button,
  Div,
  Icon, Input, SmallText,
} from '@konsys-ui-custom'
import _ from 'lodash'
import numeral from 'numeral'
import React, {
  useContext,
  useEffect, useRef,
  useState,
} from 'react'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'
import { SUPPLIERS } from '../../api/query'
import { ListContainer, Select } from '../../components'
import History from '../../components/common/history'
import { AuthorizationContext } from '../../store/StoreProvider'
import { permissionRedirect } from '../../utils/util-services'
import QuickView, { DeliveryDetail } from './quick-view'
import { delayInput } from '../../utils'

const KEY = 'supplierList'

export default (props) => {
  const [searchText, setSearchText] = useState('')
  const [filterLoading, setFilterLoading] = useState(false)
  const [fetchMoreLoading, setFetchMoreLoading] = useState(false)
  const [orderField, setOrderField] = useState('createdAt')
  const [orderBy, setOrderby] = useState('DESC')
  const isInitialMount = useRef(true)
  const {
    data, loading, fetchMore, refetch,
  } = useQuery(SUPPLIERS, { variables: { limit: 10, offset: 0 } })

  const authorization = useContext(AuthorizationContext)
  const supplierAutho = authorization.authorization?.supplier || {}
  const mapped = (!loading || !_.isEmpty(data)) && data[KEY].data.list.map((v) => {
    const primaryContactAddress = _.find(v.contactAddress, (o) => o.isPrimary)
    return (
      ({
        id: v.id,
        title: `${v.prefix} ${v.name}`,
        status: {
          text: v.number,
        },
        content: {
          one: {
            title: {
              text: <Div display='flex' justifyContent='space-between'>
                <Div>
                  <SmallText bold color='#00B900'>{primaryContactAddress?.contactLineId}</SmallText>
                  {
                    !_.isEmpty(primaryContactAddress?.contactLineId) && !_.isEmpty(primaryContactAddress?.contactFacebook)
                    && <SmallText margin='0 4px'>/</SmallText>
                  }
                  <SmallText bold color='#4267B2'>{primaryContactAddress?.contactFacebook}</SmallText>
                </Div>
                {/* <SmallText textAlign='right'>{_.find(PAYMENT_TYPE, o => o.value === v.paymentType)?.text}</SmallText> */}
              </Div>,
            },
            content: {
              text: <Div>
                {/* <SmallText display='block'>{primaryContactAddress ? `${primaryContactAddress.addressNo} ${primaryContactAddress.subDistrict} ${primaryContactAddress.district} ${primaryContactAddress.province} ${primaryContactAddress.postcode}` : '-'}</SmallText> */}
                <SmallText display='block'>{primaryContactAddress?.contactOfficeTel || '-'}</SmallText>
              </Div>,
            },
            margin: '0',
            style: { width: '100%' },
          },
        },
        detail: {
          title: [
            {
              text: 'รายละเอียด',
              icon: 'fal fa-info-square',
            },
            {
              text: 'ข้อมูลการจัดส่งสินค้า',
              icon: 'fal fa-truck',
            },
            supplierAutho.viewHistoryLog && {
              text: 'ประวัติการใช้งาน',
              icon: 'fal fa-history',
            },
          ],
          buttonList: [
            supplierAutho.editBasic && <Link key='create' to={`${props.match.url}/edit/${v.id}`}>
              <Button small ghost margin='4px 8px 4px 0' text='แก้ไข' icon='far fa-edit' />
            </Link>,
          ],
          content: [
            <QuickView key={v.id} data={v} />,
            <DeliveryDetail key={0} data={v.shippingAddress} />,
            <History
              key={2}
              data={v}
              moduleName='SUPPLIER'
            />,
          ],
        },
      })
    )
  })

  useEffect(() => {
    if (!supplierAutho.view) {
      permissionRedirect()
    }
  }, [])

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false
    } else {
      setFilterLoading(true)
      refetch({
        name: searchText, orderBy, orderField,
      }).then(() => {
        setFilterLoading(false)
      })
    }
  }, [searchText, orderField, orderBy])

  return (
    <Div>
      <Helmet>
        <meta name="viewport" content="width=1280" />
      </Helmet>
      <ListContainer
        onInfiniteScroll={() => {
          setFetchMoreLoading(true)
          fetchMore({
            variables: {
              offset: data[KEY].data.list.length,
            },
            updateQuery: (prev, { fetchMoreResult }) => {
              setFetchMoreLoading(false)
              if (!fetchMoreResult) return prev
              return { ...prev,
                [KEY]: {
                  ...fetchMoreResult[KEY],
                  data: {
                    ...fetchMoreResult[KEY].data,
                    list: [...prev[KEY].data.list, ...fetchMoreResult[KEY].data.list],
                  },
                } }
            },
          })
        }}
        filterHeight={100}
        filter={[
          <Div margin='0 0 24px' key={0}>
            <Div display='flex' alignItems='flex-start' moreStyle={{ flexGrow: 1 }}>
              <Input
                type='text'
                width='100%'
                prefix={<Icon icon='prefix fal fa-search' />}
                placeholder='ค้นหาชื่อผู้จำหน่าย, ชื่อผู้ติดต่อ'
                onChange={(e) => {
                  const searchValue = e.target.value
                  delayInput(() => {
                    setSearchText(searchValue)
                  })
                }}
                suffix={(!loading && !_.isEmpty(data?.supplierList?.data) && !_.isEmpty(searchText)) && <Div position='absolute' right='16px' top='50%' moreStyle={{ transform: 'translateY(-50%)' }}><SmallText>{numeral(data?.supplierList?.data?.total || 0).format('0,0')} รายการ</SmallText></Div>}
              />
            </Div>
            <Div display='flex' margin='8px 0 0'>
              <Div display='flex' moreStyle={{ flexGrow: 1 }} margin='0 56px 0 0'>
                <Select
                  defaultValue='createdAt'
                  onChange={(value) => setOrderField(value)}
                  value={orderField}
                  style={{ width: '41.65%', minWidth: '200px', margin: '0 16px 0 0' }}
                  options={[{ text: 'เรียงตามวันที่สร้าง', value: 'createdAt' }, { text: 'เรียงตามวันที่อัพเดท', value: 'updatedAt' }]}
                />
                <Select
                  suffixIcon={<Icon icon={orderBy === 'ASC' ? 'fal fa-sort-amount-down-alt' : 'fal fa-sort-amount-up-alt'} />}
                  defaultValue='DESC'
                  value={orderBy}
                  onChange={(value) => setOrderby(value)}
                  options={[{ text: 'น้อยไปมาก', value: 'ASC' }, { text: 'มากไปน้อย', value: 'DESC' }]}
                />
              </Div>
              <Div display='flex' alignItems='center'>
                <Button text='ล้าง' icon='fal fa-sync' small ghost onClick={() => {
                  setSearchText('')
                  setFilterLoading(true)
                  setOrderField('createdAt')
                  setOrderby('DESC')
                  refetch({
                    name: '',
                    orderBy: 'DESC',
                    orderField: 'createdAt',
                  }).then(() => {
                    setFilterLoading(false)
                  })
                }} />
              </Div>
            </Div>
          </Div>,
        ]}
        tabLoading={fetchMoreLoading}
        listLoading={filterLoading || (loading && _.isEmpty(data))}
        title={[
          {
            text: 'รายชื่อผู้ผลิต',
            icon: 'fad fa-user-tie',
          },
        ]}
        list={mapped}
        buttonList={[]}
      >
      </ListContainer>
    </Div>
  )
}
