import React from 'react'
import ReactDOM from 'react-dom'
import { ConfigProvider } from 'antd'
import thTH from 'antd/lib/locale-provider/th_TH'
import { ThemeProvider } from 'styled-components'
import 'react-table/react-table.css'
import './styles/css/all.css'
import './index.css'
import App from './App'
import './styles/antd-pro-layout.css'
import 'antd/dist/antd.less'
import './styles/_override.css'
import { theme } from './styles/_variables'
import 'moment/locale/th'
import ContextProvider from './store/providerComposer'

ReactDOM.render(
  <ContextProvider>
    <ConfigProvider locale={thTH}>
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
    </ConfigProvider>
  </ContextProvider>, document.getElementById('root'),
)
