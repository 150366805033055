import { theme } from '../../styles/_variables'

export const PT_KEYS = {
  confirm: 'CONFIRMED',
  waiting: 'WAITING',
  delivered: 'DELIVERED',
  received: 'RECEIVED',
  cancelled: 'CANCELLED',
}

const PT_STATUS = [
  {
    key: PT_KEYS.confirm,
    text: 'ยืนยัน',
    color: theme.color.success,
    icon: 'fal fa-check-circle',
  }, {
    key: PT_KEYS.waiting,
    text: 'รอเลขพัสดุ',
    color: theme.color.gray50,
    icon: 'fal fa-check-circle',
  }, {
    key: PT_KEYS.delivered,
    text: 'จัดส่งแล้ว',
    color: theme.color.success,
    icon: 'fal fa-check-circle',
  }, {
    key: PT_KEYS.received,
    text: 'รับของแล้ว',
    color: theme.color.primaryColor,
    icon: 'fal fa-check-circle',
  }, {
    key: PT_KEYS.cancelled,
    text: 'ยกเลิก',
    color: theme.color.error,
    icon: 'fal fa-check-circle',
  },
]

export default PT_STATUS
