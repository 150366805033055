/* eslint-disable no-control-regex */
import { useMutation, useQuery } from '@apollo/client'
import {
  Button,
  Div,
  H4,
  Icon,
  Input,
  InputWithLabel,
  Label,
  SmallText,
} from '@konsys-ui-custom'
import { Col, Row, Select as Tag, message } from 'antd'
import _, { flattenDeep, includes, isEmpty, last, pick, sumBy } from 'lodash'
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useHistory } from 'react-router-dom'
import { CREATE_INVENTORY, UPDATE_INVENTORY } from '../../api/mutation'
import {
  GET_TAGS,
  INVENTORY,
  PRICING_TYPES_LISTS,
  WAREHOUSES,
} from '../../api/query'
import {
  DashedButton,
  Divider,
  FullPageContainer,
  Select as KonsysSelect,
  Uploader,
} from '../../components'
import { theme } from '../../styles/_variables'
import { validateData } from '../../utils/util-services'
import { SALE_TYPE, STOCK_TYPE } from './constants'

const initialNewVariantData = {
  buyPrice: undefined,
  initialCost: undefined,
  initialStock: undefined,
  isVat: true,
  vat: 7,
  minimumStock: undefined,
  maximumStock: undefined,
}

const initialNewLocationData = {
  quantity: undefined,
  shelf: undefined,
  warehouseId: undefined,
  isNew: true,
}

const VariantOptionForm = ({
  inventoryOption,
  setInventoryOption,
  isCreateMode,
  warehousesData,
}) => (
  <Div>
    <Div>
      <Row
        style={{ margin: '8px 0' }}
        justify="space-between"
        align="bottom"
        type="flex"
      >
        <Col span={5}>
          {
            inventoryOption.productUrl
              ? <img src={inventoryOption.productUrl} alt='' style={{ width: 100, height: 100, objectFit: 'cover' }}/>
              : <Uploader
                buttonStyle={{ margin: '8px 0' }}
                buttonText="อัพโหลดรูปประจำรุ่นสินค้า"
                onChange={(e) => setInventoryOption({
                  ...inventoryOption,
                  files: e,
                })
                }
                data={
                  isCreateMode || inventoryOption.files ? inventoryOption.files : []
                }
                isMultiple={true}
                fileLength={5}
                accept="image/*"
              />
          }
        </Col>
      </Row>
      <Row type="flex" gutter={16} align="middle">
        <Col xs={24} md={12} lg={6}>
          <InputWithLabel
            width="100%"
            input={{
              width: '100%',
              disabled: !isCreateMode,
              defaultValue: isCreateMode
                ? undefined
                : inventoryOption.initialCost,
              placeholder: 'กรอกต้นทุน / หน่วย',
              rule: { type: 'float' },
              state:
                (_.isEmpty(inventoryOption.initialCost)
                  && !_.isNumber(inventoryOption.initialCost))
                || _.isNaN(inventoryOption.initialCost)
                  ? 'error'
                  : 'default',
            }}
            onChange={(e) => setInventoryOption({
              ...inventoryOption,
              initialCost: parseFloat(e.target.value),
            })
            }
            title={{
              text: (
                <SmallText bold>
                  ต้นทุน / หน่วย <Label color={theme.color.error}>*</Label>
                </SmallText>
              ),
            }}
          />
        </Col>
        <Col xs={24} md={12} lg={6}>
          <InputWithLabel
            width="100%"
            input={{
              width: '100%',
              disabled: !isCreateMode,
              defaultValue: isCreateMode
                ? undefined
                : inventoryOption.initialStock,
              placeholder: 'กรอกจำนวนสต๊อกเริ่มต้น',
              rule: { type: 'float' },
              state:
                (_.isEmpty(inventoryOption.initialStock)
                  && !_.isNumber(inventoryOption.initialStock))
                || _.isNaN(inventoryOption.initialStock)
                  ? 'error'
                  : 'default',
            }}
            onChange={(e) => setInventoryOption({
              ...inventoryOption,
              initialStock: parseFloat(e.target.value),
            })
            }
            title={{
              text: (
                <SmallText bold>
                  จำนวนสต๊อกเริ่มต้น <Label color={theme.color.error}>*</Label>
                </SmallText>
              ),
            }}
          />
        </Col>
        <Col xs={24} md={12} lg={6}>
          <InputWithLabel
            width="100%"
            input={{
              width: '100%',
              defaultValue: isCreateMode
                ? undefined
                : inventoryOption.minimumStock,
              placeholder: 'กรอกจำนวนสต๊อกขั้นต่ำ',
              state: 'default',
              rule: { type: 'float' },
            }}
            onChange={(e) => setInventoryOption({
              ...inventoryOption,
              minimumStock: parseFloat(e.target.value),
            })
            }
            title={{ text: 'จำนวนสต๊อกขั้นต่ำ' }}
          />
        </Col>
        <Col xs={24} md={12} lg={6}>
          <InputWithLabel
            width="100%"
            input={{
              width: '100%',
              defaultValue: isCreateMode
                ? undefined
                : inventoryOption.maximumStock,
              placeholder: 'กรอกจำนวนสต๊อกแนะนำ',
              state: 'default',
              rule: { type: 'float' },
            }}
            onChange={(e) => setInventoryOption({
              ...inventoryOption,
              maximumStock: parseFloat(e.target.value),
            })
            }
            title={{ text: 'จำนวนสต๊อกแนะนำ' }}
          />
        </Col>
      </Row>
      <Row type="flex" gutter={16} align="middle">
        <Col xs={24} md={12} lg={6}>
          <InputWithLabel
            width="100%"
            input={{
              width: '100%',
              defaultValue: isCreateMode ? undefined : inventoryOption.buyPrice,
              placeholder: 'กรอกราคาซื้อ (ต่อหน่วย)',
              rule: { type: 'float' },
              state:
                (_.isEmpty(inventoryOption.buyPrice)
                  && !_.isNumber(inventoryOption.buyPrice))
                || _.isNaN(inventoryOption.buyPrice)
                  ? 'error'
                  : 'default',
            }}
            onChange={(e) => setInventoryOption({
              ...inventoryOption,
              buyPrice: parseFloat(e.target.value),
            })
            }
            title={{
              text: (
                <SmallText bold>
                  ราคาซื้อ (ต่อหน่วย) <Label color={theme.color.error}>*</Label>
                </SmallText>
              ),
            }}
          />
        </Col>
        <Col xs={24} md={12} lg={6}>
          <InputWithLabel
            width="100%"
            input={{
              width: '100%',
              defaultValue: isCreateMode
                ? undefined
                : inventoryOption.pack[0].salesPrice,
              placeholder: 'กรอกราคาขาย',
              state: 'default',
              rule: { type: 'float' },
            }}
            onChange={(e) => setInventoryOption({
              ...inventoryOption,
              pack: [
                {
                  ...inventoryOption.pack[0],
                  salesPrice: parseFloat(e.target.value),
                },
              ],
            })
            }
            title={{ text: 'ราคาขายขั้นต่ำ' }}
          />
        </Col>
      </Row>
    </Div>

    <Row type="flex" gutter={8} align="middle" style={{ marginTop: 8 }}>
      <Col span={18}>
        <SmallText bold>
            สถานที่จัดเก็บ <Label color={theme.color.error}>*</Label>
        </SmallText>
      </Col>
      <Col span={5}>
        <SmallText bold>
            จำนวน <Label color={theme.color.error}>*</Label>
        </SmallText>
      </Col>
      <Col span={1} />
    </Row>
    {inventoryOption.location.map((v) => (
      <Row
        key={v.id}
        type="flex"
        gutter={8}
        align="middle"
        style={{ margin: '8px -4px' }}
      >
        <Col span={18}>
          <KonsysSelect
            style={{ width: '100%' }}
            onChange={(e) => {
              const newLocations = inventoryOption.location.map((val) => {
                if (val.id === v.id) return { ...v, warehouseId: e }
                return val
              })
              setInventoryOption({
                ...inventoryOption,
                location: newLocations,
              })
            }}
            disabled={_.includes(_.keys(v), '__typename')}
            placeholder="เลือกสถานที่จัดเก็บ"
            value={_.find(warehousesData.warehouseList.data.list, (o) => o.id === v.warehouseId)?.warehouseName}
            defaultValue={
              isCreateMode || !v.warehouse
                ? undefined
                : v.warehouse.warehouseName
            }
            options={_.remove(
              _.cloneDeep(warehousesData.warehouseList.data.list),
              (o) => !_.includes(
                inventoryOption.location.map(
                  (location) => location.warehouseId,
                ),
                o.id,
              ),
            ).map((value) => ({
              value: value.id,
              text: value.warehouseName,
            }))}
            className={
              ((_.isEmpty(v?.warehouseId) && !_.isNumber(v?.warehouseId))
                  || _.isNaN(v?.warehouseId))
                && 'state-error'
            }
          />
        </Col>
        <Col span={5}>
          <Input
            width="100%"
            defaultValue={isCreateMode ? undefined : v.quantity}
            disabled={!isCreateMode && !inventoryOption.isNew}
            placeholder="กรอกจำนวน"
            rule={{ type: 'int' }}
            onChange={(e) => {
              const newLocations = inventoryOption.location.map((val) => {
                if (val.id === v.id) { return { ...v, quantity: parseInt(e.target.value) } }
                return val
              })
              setInventoryOption({
                ...inventoryOption,
                location: newLocations,
              })
            }}
            state={
              (_.isEmpty(v.quantity) && !_.isNumber(v.quantity))
                || _.isNaN(v.quantity)
                ? 'error'
                : 'default'
            }
          />
        </Col>
        <Col span={1}>
          {_.size(inventoryOption.location) > 1
              && !_.includes(_.keys(v), '__typename') && (
            <Icon
              icon="fal fa-trash-alt"
              color={theme.color.blue}
              style={{ cursor: 'pointer' }}
              onClick={() => {
                const newLocations = _.remove(
                  _.cloneDeep(inventoryOption.location),
                  (o) => o.id !== v.id,
                )
                setInventoryOption({
                  ...inventoryOption,
                  location: newLocations,
                })
              }}
            />
          )}
        </Col>
      </Row>
    ))}
    {(isCreateMode || inventoryOption.isNew) && (
      <DashedButton
        width="100%"
        margin="16px 0 0 0"
        text="เพิ่มสถานที่จัดเก็บ"
        icon="fal fa-plus"
        onClick={() => setInventoryOption({
          ...inventoryOption,
          location: [
            ...inventoryOption.location,
            {
              id: inventoryOption.location.length + 1,
              ...initialNewLocationData,
            },
          ],
        })
        }
      />
    )}

  </Div>
)

export const Form = ({ loading, isCreateMode, data, callback, prefillCreate }) => {
  const history = useHistory()
  const { loading: warehousesLoading, data: warehousesData } = useQuery(WAREHOUSES)
  const { loading: pricingTypesLoading, data: pricingTypesData } = useQuery(PRICING_TYPES_LISTS)
  const { loading: tagsLoading, data: tagsData } = useQuery(GET_TAGS)
  const [doAction, { loading: mutationLoading }] = useMutation(
    isCreateMode ? CREATE_INVENTORY : UPDATE_INVENTORY,
    { refetchQueries: ['remainingJob', 'remainingAccountant'] },
  )

  const [mutationVariables, setMutationVariables] = useState({
    productName: prefillCreate?.name,
    type: SALE_TYPE.VARIANT,
    tag: [],
    sellable: true,
    purchasable: true,
    isLossable: true,
    serialTracking: false,
    stockType: STOCK_TYPE.STOCK,
    inventoryOptions: [
      {
        id: 1,
        ...initialNewVariantData,
        location: [
          {
            id: 1,
            ...initialNewLocationData,
          },
        ],
        pack: [
          {
            name: 'แพ็ค 1',
            quantity: 1,
          },
        ],
        productUrl: prefillCreate?.image,
      },
    ],
  })
  const [inventoryOption, setInventoryOption] = useState({
    pack: [
      {
        name: 'แพ็ค 1',
        quantity: 1,
        itemPricingTypes: [],
      },
    ],
    location: [
      {
        id: 1,
        ...initialNewLocationData,
      },
    ],
    files: [],
  })

  useEffect(() => {
    if (!loading && !pricingTypesLoading) {
      if (!isCreateMode) {
        setMutationVariables({
          ...data.inventoryInfo.data,
          productGroup: data.inventoryInfo?.data?.productGroup?.id,
          brand: data.inventoryInfo?.data?.brand?.id,
          category: data.inventoryInfo?.data?.productCategory?.id,
        })
        setInventoryOption({
          ...data.inventoryInfo.data.inventoryOption,
          location: data.inventoryInfo.data.inventoryOption.stockLocation.map(
            (val) => ({ ...val, warehouseId: val.warehouse.id }),
          ),
          pack: data.inventoryInfo.data.inventoryOption.pack.map((pack) => ({
            ...pack,
            middlePrice: pack.middlePrice,
            itemPricingTypes: pack.itemPricingTypes.map((tier) => ({
              id: tier.pricingType.id,
              salesPrice: tier.salesPrice,
              name: tier.pricingType.name,
            })),
          })),
          files: data.inventoryInfo.data.inventoryOption.files?.map((file) => ({
            id: file.id,
            name: _.flatten(_.takeRight(_.split(file.url, '/'))),
            uid: file.url,
            url: file.url,
          })),
        })
      } else {
        setMutationVariables({
          type: SALE_TYPE.VARIANT,
          tag: [],
          sellable: true,
          isLossable: false,
          purchasable: true,
          serialTracking: false,
          stockType: STOCK_TYPE.STOCK,
          productName: prefillCreate?.name,
        })
        setInventoryOption({
          id: 1,
          ...initialNewVariantData,
          location: [
            {
              id: 1,
              ...initialNewLocationData,
            },
          ],
          pack: [
            {
              id: 1,
              name: 'แพ็ค 1',
              quantity: 1,
              itemPricingTypes: pricingTypesData.pricingTypeList.data.list.map(
                (tier) => ({
                  id: tier.id,
                  salesPrice: undefined,
                  name: tier.name,
                }),
              ),
            },
          ],
          productUrl: prefillCreate?.image,
          files: [],
        })
      }
    }
  }, [loading, pricingTypesLoading])

  return (
    <FullPageContainer
      loading={loading || warehousesLoading}
      title={[
        {
          text: 'Inventory',
          icon: 'fad fa-warehouse',
        },
      ]}
      detail={{
        title: [
          {
            text: isCreateMode ? 'สร้างสินค้าใหม่' : 'แก้ไขสต๊อกสินค้า',
            icon: isCreateMode ? 'fal fa-plus' : 'fal fa-edit',
          },
        ],
      }}
    >
      {(!loading && !_.isNil(mutationVariables.id) && !isCreateMode)
      || isCreateMode ? (
          !isCreateMode && _.isNull(data.inventoryInfo.data) ? (
            <H4>ไม่พบ</H4>
          ) : (
            <Div display="flex" flexDirection="column">
              <Row type="flex" gutter={16} align="middle">
                <Col xs={24} md={18}>
                  <InputWithLabel
                    width="100%"
                    input={{
                      width: '100%',
                      disabled: prefillCreate?.name,
                      value: mutationVariables.productName,
                      state: _.isEmpty(mutationVariables.productName) && 'error',
                    }}
                    onChange={(e) => setMutationVariables({
                      ...mutationVariables,
                      productName: e.target.value,
                    })
                    }
                    title={{
                      text: (
                        <SmallText bold>
                        ชื่อสินค้า <Label color={theme.color.error}>*</Label>
                        </SmallText>
                      ),
                    }}
                  />
                </Col>
                <Col xs={24} md={6}>
                  <Div>
                    <SmallText bold>
                    รหัสสินค้า <Label color={theme.color.error}>*</Label>
                    </SmallText>
                    <Div display="flex" alignItems="center">
                      <Input
                        width="100%"
                        value={mutationVariables.sku}
                        onChange={(e) => setMutationVariables({
                          ...mutationVariables,
                          sku: e.target.value,
                        })
                        }
                        state={_.isEmpty(mutationVariables.sku) && 'error'}
                      />
                    </Div>
                  </Div>
                </Col>
              </Row>
              <Row type="flex" gutter={16} align="middle">
                <Col xs={24} md={12}>
                  <InputWithLabel
                    width="100%"
                    input={{
                      value: mutationVariables.reference,
                      width: '100%',
                    }}
                    onChange={(e) => setMutationVariables({
                      ...mutationVariables,
                      reference: e.target.value,
                    })
                    }
                    title={{ text: (
                      <SmallText bold>
                      ชื่อย่อ <Label color={theme.color.error}>*</Label>
                      </SmallText>
                    ) }}
                  />
                </Col>
                <Col xs={24} md={12}>
                  <InputWithLabel
                    width="100%"
                    input={{
                      width: '100%',
                      value: mutationVariables.barcode,
                    }}
                    onChange={(e) => {
                      if (/^[\x00-\x7F]+$/.test(e.target.value) || e.target.value === '') {
                        setMutationVariables({
                          ...mutationVariables,
                          barcode: e.target.value,
                        })
                      }
                    }}
                    title={{ text: 'Barcode' }}
                  />
                </Col>
              </Row>
              <SmallText bold margin="8px 0">
              แท็กของสินค้า
              </SmallText>
              <Tag
                value={mutationVariables.tag.map((v) => v.name)}
                mode="tags"
                style={{ width: '100%' }}
                placeholder="กรอกแท็กของสินค้า"
                onChange={(e) => {
                  if (includes(tagsData.getTagList.data, last(e)) || isEmpty(e)) {
                    setMutationVariables({
                      ...mutationVariables,
                      tag: e.map((v) => ({ name: v })),
                    })
                  }
                }}
              >
                {tagsLoading
                  ? []
                  : tagsData.getTagList.data.map((v) => (
                    <Tag.Option key={v} value={v}>
                      {v}
                    </Tag.Option>
                  ))}
              </Tag>
              <Divider margin="40px 0" />
              <Div>
                <VariantOptionForm
                  pricingTypesData={pricingTypesData}
                  inventoryOption={inventoryOption}
                  setInventoryOption={setInventoryOption}
                  isCreateMode={isCreateMode}
                  data={data}
                  warehousesData={warehousesData}
                />
                <Divider margin="40px 0" />
              </Div>
              <Div display="flex" flexDirection="column">
                <Div
                  display="flex"
                  flexDirection="row"
                  justifyContent="center"
                  alignItems="center"
                  width="100%"
                  margin="20px 0"
                >
                  <Button
                    color={theme.color.success}
                    loading={mutationLoading}
                    inverse
                    disabled={
                      mutationLoading
                      || validateData(
                        true,
                        [
                          mutationVariables.productName,
                          mutationVariables.sku,
                          mutationVariables.reference,
                          inventoryOption.initialCost,
                          inventoryOption.initialStock,
                          inventoryOption.buyPrice,
                          flattenDeep(inventoryOption.location.map((i) => [i.warehouseId, i.quantity])),
                        ],
                        {
                          ...mutationVariables,
                          ...inventoryOption,
                          ...inventoryOption.pack,
                          ...inventoryOption.location,
                        },
                      )
                      || (sumBy(inventoryOption.location, 'quantity') !== inventoryOption.initialStock && isCreateMode)
                    }
                    text={isCreateMode ? 'สร้างสินค้าใหม่' : 'แก้ไขสต๊อกสินค้า'}
                    rightIcon={true}
                    icon={isCreateMode ? 'fal fa-plus' : 'fal fa-edit'}
                    style={{ alignItems: 'center' }}
                    width="fit-content"
                    margin="0 4px"
                    onClick={() => {
                      doAction({
                        variables: isCreateMode
                          ? {
                            ...mutationVariables,
                            brandId: 1,
                            productGroupId: 1,
                            productCategoryId: 1,
                            supplierId: [1],
                            type: SALE_TYPE.VARIANT,
                            inventoryOption: _.omit(
                              {
                                ...inventoryOption,
                                isVat: false,
                                files: inventoryOption.files
                                  ? inventoryOption.files
                                  : undefined,
                                location: inventoryOption.location.map((location) => pick(location, 'warehouseId', 'quantity')),
                                pack: inventoryOption.pack.map((p) => _.omit(
                                  {
                                    ...p,
                                  },
                                  ['id', 'isNew', 'itemPricingTypes'],
                                )),
                              },
                              ['id', 'quantity'],
                            ),
                          }
                          : _.omit(
                            {
                              ...mutationVariables,
                              id: parseInt(mutationVariables.id),
                              tag: mutationVariables.tag.map((v) => ({
                                name: v.name,
                              })),
                              supplierId: [1],
                              brandId: 1,
                              productGroupId: 1,
                              productCategoryId: 1,
                              inventoryOption: {
                                id: inventoryOption.isNew
                                  ? undefined
                                  : parseInt(inventoryOption.id),
                                minimumStock: inventoryOption.minimumStock,
                                maximumStock: inventoryOption.maximumStock,
                                initialStock: inventoryOption.initialStock,
                                buyPrice: inventoryOption.buyPrice,
                                isVat: false,
                                files: inventoryOption.files?.map((file) => (file?.url
                                  ? { id: file.id, url: file.url }
                                  : file)),
                                pack: inventoryOption.pack.map((pack) => ({
                                  id: !pack.isNew ? pack.id : undefined,
                                  quantity: 1,
                                  salesPrice: parseFloat(pack?.salesPrice),
                                })),
                              },
                            },
                            [
                              'initialCost',
                              'inventoryOptions',
                              'supplier',
                              'type',
                              '__typename',
                            ],
                          ),
                      }).then((resp) => {
                        const KEY = isCreateMode
                          ? 'createInventory'
                          : 'updateInventory'
                        if (resp.data[KEY].success) {
                          if (callback) {
                            callback(resp.data[KEY].data?.packId, `${mutationVariables.sku} - ${mutationVariables.productName}`)
                          } else {
                            history.push('/inventory')
                          }
                          message.success(resp.data[KEY].message)
                        } else message.error(resp.data[KEY].message)
                      })
                    }}
                  />
                </Div>
              </Div>
            </Div>
          )
        ) : null}
    </FullPageContainer>
  )
}

export default (props) => {
  const isCreateMode = _.includes(props.match.url, 'create')
  const { loading, data } = useQuery(INVENTORY, {
    skip: isCreateMode,
    variables: { id: parseInt(props.match.params.id) },
  })

  return (
    <Div>
      <Helmet>
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>
      <Form
        data={data}
        loading={loading}
        isCreateMode={isCreateMode}
        id={parseInt(props.match.params.id)}
      />
    </Div>
  )
}
