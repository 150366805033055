import { Button, Div, H4, Icon, Input, Label, P, SmallText } from '@konsys-ui-custom'
import axios from 'axios'
import React, { useState } from 'react'
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import { Result, Select, message } from 'antd'
import { find, isEmpty, isNil } from 'lodash'
import styled from 'styled-components'
import URL from '../../constants/environment'
import { theme } from '../../styles/_variables'
import { isAlphaNumeric } from '../../utils'

const StepBar = styled(Div)`
  transition: width 0.5s;
  width: ${(props) => (props.step === 1 ? '50%' : '100%')};
  height: 5px;
  background: ${theme.color.primaryColor};
`

const FacebookLoginSection = ({ responseFacebook, loading }) => (
  <Div width='100%' display='flex' flexDirection='column' alignItems='center' padding='0 40px'>
    <H4 bold margin='0 0 32px 0'>เชื่อมบัญชี Facebook</H4>
    <Div display='flex' alignItems='center'>
      <Div width='50px' height='50px' bgColor='#F7F7FC' display='flex' alignItems='center' justifyContent='center' style={{ borderRadius: 5 }}>
        <P bold color={theme.color.primaryColor}>CRM</P>
      </Div>
      <Icon icon='far fa-arrow-right-arrow-left' margin='0 16px'/>
      <Div width='50px' height='50px' bgColor='#F7F7FC' display='flex' alignItems='center' justifyContent='center' style={{ borderRadius: 5 }}>
        <Icon icon='fab fa-facebook-square' margin='0 16px' fontSize='56px' color='#1877F2'/>
      </Div>
    </Div>
    <Div display='flex' flexDirection='column' justifyContent='flex-start' margin='32px 0'>
      <Div display='flex' margin='0 0 8px 0'>
        <Icon margin='4px 6px 0 0' icon='fas fa-check-circle' color={theme.color.success}/>
        <P>ตอบแชทจาก Facebook Page และ Line ได้ในที่เดียว (เชื่อม Line ได้หลังสร้างบัญชีธุรกิจ)</P>
      </Div>
      <Div display='flex' margin='0 0 8px 0'>
        <Icon margin='4px 6px 0 0' icon='fas fa-check-circle' color={theme.color.success}/>
        <P>จัดการรายชื่อลูกค้าอัตโนมัติ</P>
      </Div>
      <Div display='flex' >
        <Icon margin='4px 6px 0 0' icon='fas fa-check-circle' color={theme.color.success}/>
        <P>ช่วยจัดการแชทอัตโนมัติ 24 ชั่วโมง</P>
      </Div>
      <SmallText margin='32px 0 0 0' color={theme.color.gray20}>เมื่อเชื่อมต่อแล้ว เพจจะยังคงทำงานอยู่ ข้อความแชททั้งหมดจะถูกดึงเข้ามาอยู่ใน CRM ทันที สามารถยกเลิกหรือเปลี่ยนแปลงการเชื่อมต่อได้ตลอด</SmallText>
    </Div>
    <FacebookLogin
      appId={process.env.REACT_APP_FACEBOOK_APP_ID}
      fields="name,email,picture"
      callback={(e) => responseFacebook(e)}
      scope="email,pages_show_list,pages_messaging,pages_read_engagement,pages_manage_posts,public_profile,pages_manage_metadata"
      render={(renderProps) => (
        <Button loading={loading} disabled={loading} onClick={() => renderProps.onClick()} icon='prefix fab fa-facebook' text='Login to Facebook' color='#1877F2' inverse width='100%' height='100px'/>
      )}
    />
  </Div>
)

const FacebookPagesSelection = ({ pages, setLoading, loading, setSelectedPage, selectedPage, code, setCode, setStep }) => (
  <Div width='100%' display='flex' flexDirection='column' alignItems='center' padding='0 40px'>
    <H4 bold margin='0 0 32px 0'>เลือกเพจ และยืนยันรหัสนำหน้า Order</H4>
    <Div display='flex' alignItems='center'>
      <Div width='50px' height='50px' bgColor='#F7F7FC' display='flex' alignItems='center' justifyContent='center' style={{ borderRadius: 5 }}>
        <P bold color={theme.color.primaryColor}>CRM</P>
      </Div>
      <Icon icon='far fa-arrow-right-arrow-left' margin='0 16px'/>
      <Div width='50px' height='50px' bgColor='#F7F7FC' display='flex' alignItems='center' justifyContent='center' style={{ borderRadius: 5 }}>
        {
          selectedPage
            ? <img src={selectedPage.photo} style={{ borderRadius: 5 }}/>
            : <Icon icon='fab fa-facebook-square' margin='0 16px' fontSize='56px' color='#1877F2'/>
        }
      </Div>
    </Div>
    <Div display='flex' flexDirection='column' justifyContent='flex-start' margin='32px 0'>
      <P bold>โปรดยืนยันการเชื่อมต่อ CRM กับ Facebook Page</P>
      <P bold>ที่เลือกไว้และระบุรหัสนำหน้า Order เพื่อใช้ในการสร้าง Order ตามช่องทาง</P>
      <Select placeholder='เลือกเพจ' value={selectedPage?.id} style={{ width: '100%', margin: '32px 0' }} onChange={(e) => {
        const p = find(pages, (o) => o.id === `${e}`)
        setSelectedPage(p)
      }}>
        {
          pages.map((v) => <Select.Option key={v.id} value={v.id}>{v.name}</Select.Option>)
        }
      </Select>
      <Label color={theme.color.gray50}>ระบุเพจ Code</Label>
      <Input width='100%' placeholder='กรอกเพจ Code' margin='8px 0' value={code} onChange={(e) => {
        setCode(e.target.value)
      }}/>
      <Label>สำหรับนำหน้า Order เช่น L สำหรับ L65-01-31-0001</Label>
    </Div>
    <Button loading={loading} disabled={isNil(code) || isEmpty(code) || !isAlphaNumeric(code) || isNil(selectedPage?.id)} icon='far fa-check' text='Connect' color='#1877F2' inverse height='100px' onClick={async () => {
      setLoading(true)
      try {
        await axios({
          method: 'POST',
          url: `${URL.API_HOST}/connectPage`,
          data: {
            id: selectedPage?.id,
            code,
          },
        }).then((resp) => {
          if (resp.status === 200) {
            setLoading(false)
            setStep(3)
          }
        })
      } catch (err) {
        setLoading(false)
        message.error(err.response.data)
      }
    }}/>
  </Div>
)

export default () => {
  const [step, setStep] = useState(1)
  const [loading, setLoading] = useState(false)
  const [selectedPage, setSelectedPage] = useState()
  const [code, setCode] = useState()
  const [token, setToken] = useState()
  const [pages, setPages] = useState([])
  const responseFacebook = (response) => {
    setLoading(true)
    setToken(response.accessToken)
    axios({
      method: 'POST',
      url: `${URL.API_HOST}/getFacebookPages`,
      data: {
        shortLivedToken: response.accessToken,
      },
    }).then((resp) => {
      setPages(resp.data)
      setLoading(false)
      setStep(2)
    })
  }
  return (
    <Div bgColor='#F7F7FC' width='100%' height='100%' display='flex' alignItems='center' justifyContent='center'>
      <Div width='700px' padding='0 0 40px 0' bgColor={theme.color.offWhite} style={{
        boxShadow: '0px 4px 24px rgba(0, 0, 0, 0.06)',
        borderRadius: 8,
      }}>
        {
          step === 3
            ? <Result
              status="success"
              title={`เชื่อมต่อเพจ ${selectedPage.name} สำเร็จ`}
              subTitle={`Page code: ${code}`}
              extra={[
                <Button color={theme.color.primaryColor} inverse key="ok" text='ตกลง' onClick={() => {
                  setStep(1)
                  setSelectedPage()
                  setCode()
                  setPages([])
                }} />,
              ]}
            />
            : <>
              <StepBar step={step}/>
              <Div margin='4px 0 32px 0' display='flex' justifyContent='space-between'>
                <Div display='flex' width='50%' justifyContent='center' alignItems='center'>
                  <Icon margin='0 6px 0 0' icon={step === 1 ? 'fas fa-circle-dot' : step > 1 ? 'fas fa-circle-check' : 'fa-regular fa-circle'} color={step >= 1 ? theme.color.primaryColor : theme.color.gray50}/>
                  <P color={step >= 1 ? theme.color.gray80 : theme.color.gray50}>เลือกบัญชี Facebook</P>
                </Div>
                <Div display='flex' width='50%' justifyContent='center' alignItems='center'>
                  <Icon margin='0 6px 0 0' icon={step === 2 ? 'fas fa-circle-dot' : step > 2 ? 'fas fa-circle-check' : 'fa-regular fa-circle'} color={step >= 2 ? theme.color.primaryColor : theme.color.gray50}/>
                  <P color={step >= 2 ? theme.color.gray80 : theme.color.gray50}>เลือก Facebook Page</P>
                </Div>
              </Div>
              {
                step === 1
                  ? <FacebookLoginSection loading={loading} responseFacebook={responseFacebook}/>
                  : <FacebookPagesSelection token={token} setLoading={setLoading} setStep={setStep} loading={loading} pages={pages} setSelectedPage={setSelectedPage} selectedPage={selectedPage} code={code} setCode={setCode}/>
              }</>
        }
      </Div>
    </Div>
  )
}
