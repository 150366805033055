import React from 'react'
import Desktop from './desktop'
import './index.css'
import Mobile from './mobile'

export const Index = () => {
  const isMobile = window.innerWidth <= 820

  if (isMobile) {
    return <Mobile />
  } return <Desktop />
}

export default Index
