import React from 'react'
import { Container, Main } from './styled'

export const ContentWrapper = (props) => (
  <Container>
    { props.children }
  </Container>
)

export const MainContainer = (props) => {
// useEffect(() => {
//   if (!getAccessToken()) {
//     history.push('/login')
//   }
// })
  console.info('Main Container Rendered')
  return (
    <Main>
      { props.children }
    </Main>
  )
}

export default ContentWrapper
