import React, {
  useState, useEffect, useRef, useContext,
} from 'react'
import { useQuery, useMutation } from '@apollo/client'
import moment from 'moment-timezone'
import { Link } from 'react-router-dom'
import numeral from 'numeral'
import _ from 'lodash'
import {
  DatePicker, Radio, message, Badge,
} from 'antd'
import {
  P, Div, Button, Icon, Input, H4, SmallText, Label,
} from '@konsys-ui-custom'
import { Helmet } from 'react-helmet'
import {
  ListContainer, LargeTextWithLabel, Remark, Collapse, TableList, Select, Loading,
} from '../../components'
import { theme } from '../../styles/_variables'
import { PATH } from '../../constants/path'
import GR_STATUS, { GR_KEYS } from '../../constants/status/gr'
import { menuItems } from '../../constants/menu-config'
import { LIST_LIMIT } from '../../constants/enum'
import { GOOD_RECEIVES, GOOD_RECEIVE_INFO, GR_STATUS_LIST } from '../../api/query'
import { GOOD_RECEIVE_CONF } from '../../api/mutation'
import History from '../../components/common/history'
import { permissionRedirect } from '../../utils/util-services'
import { AuthorizationContext, RemainingJobsContext } from '../../store/StoreProvider'
import { delayInput } from '../../utils'

const pageMenuData = _.isEmpty(_.find(menuItems, (v) => v.path === PATH.gr)) ? '-' : _.find(menuItems, (v) => v.path === PATH.gr)
const pageName = _.isEmpty(pageMenuData) ? '' : pageMenuData.name

const dateType = [
  { text: 'วันที่สร้าง', value: 'createdDate' },
  { text: 'วันที่กำหนดชำระ', value: 'paymentDate' },
]
const Detail = (props) => {
  const [openCollapse, setOpenCollapse] = useState(true)
  const [data, setData] = useState()
  const { loading: grLoading, data: grDetail } = useQuery(GOOD_RECEIVE_INFO, { variables: { id: props.data.id } })

  useEffect(() => {
    setData(grDetail?.goodsReceiveInfo?.data)
  }, [grDetail?.goodsReceiveInfo?.data])

  const tableStyled = {
    0: {},
    1: {
      width: '22%',
    },
    2: {
      width: '30%',
    },
  }

  const headerData = [
    {
      text: 'สินค้า',
    }, {
      text: 'ราคา / ชิ้น',
      textAlign: 'right',
    }, {
      text: 'จำนวนที่รับเข้า',
      textAlign: 'right',
    },
  ]

  const TableListData = data?.orderItemGR?.map((v) => {
    const isReplace = !_.isEmpty(v.replace)
    const orderData = isReplace ? v.replace : v.orderItem
    const renderItemName = (dataList) => dataList.pack?.inventoryOption?.variantName
    const poDetail = v.isExtra
      ? !_.isEmpty(v.orderItem) && !_.isEmpty(v.orderItem.purchaseOrder)
        ? `เป็นของแถมใน ${v.orderItem.purchaseOrder.number}`
        : 'เป็นของแถม'
      : isReplace
        && `แทนสินค้า ${renderItemName(orderData)} (${v.replace.purchaseOrder.number})`
    console.info(v)
    return (
      [
        {
          text: <Div>
            <P display='block'>{renderItemName(v.orderItem) || '-'}</P>
            {
              !v.isExtra && !isReplace
                ? <Div>
                  <SmallText display='block' color={theme.color.gray50}><SmallText bold color={theme.color.gray50}>Po Number:</SmallText> {_.isEmpty(orderData) || _.isEmpty(orderData.purchaseOrder) || _.isEmpty(orderData.purchaseOrder.number) ? '-' : orderData.purchaseOrder.number}</SmallText>
                  <SmallText display='block' color={theme.color.gray50}><SmallText bold color={theme.color.gray50}>ราคา/ชิ้น:</SmallText> {numeral(v.orderItem.buyPrice).format('0,0.00')} บาท</SmallText>
                  <SmallText display='block' color={theme.color.gray50}><SmallText bold color={theme.color.gray50}>ส่วนลด:</SmallText> {numeral(v.discount).format('0,0.00')} บาท</SmallText>
                </Div>
                : <SmallText display='block' bold color={theme.color.gray50}>{poDetail}</SmallText>
            }
          </Div>,
          label: {
            text: <Div>
              <SmallText display='block'><Label margin='0 4px 0 0'>Sku: </Label>{v.orderItem.pack?.inventoryOption?.inventory?.sku || '-'}</SmallText>
              <SmallText display='block'><Label margin='0 4px 0 0'>Brand: </Label>{v.orderItem.pack?.inventoryOption?.inventory?.brand?.name || '-'}</SmallText>
            </Div>,
          },
        },
        { text: numeral(v.price).format('0,0.00'), textAlign: 'right' },
        { text: <P textAlign='right'>{numeral(v.quantity).format('0,0.00')} <SmallText display='block'>(จากจำนวนทั้งหมด {numeral(v.orderItem.totalQuantity).format('0,0.00')})</SmallText></P>, textAlign: 'right' },
      ]
    )
  }) || []

  const poRef = _.uniqBy(_.compact(_.reduce(data?.orderItemGR, (array, n) => [...array, _.isEmpty(n.orderItem) || _.isEmpty(n.orderItem.purchaseOrder) || _.isEmpty(n.orderItem.purchaseOrder.number) ? null : { text: n.orderItem.purchaseOrder.number }], [])), 'text')

  if (grLoading) {
    return (
      <Div textAlign='center' margin='32px 0 0'>
        <Loading size='small' />
      </Div>
    )
  }
  if (_.isEmpty(data)) return null

  return (
    <Div>
      <Div display='flex' justifyContent='space-between' alignItems='center'>
        <H4 bold color={theme.color.primaryColor}>{data.number}</H4>
        <P bold color={props.status.color}>{props.status.text}</P>
      </Div>
      <Div margin='24px 0'>
        <Div margin='0 0 16px' display='flex' alignItems='flex-start' moreStyle={{ flexWrap: 'wrap' }}>
          <LargeTextWithLabel margin='8px 24px 0 0' title={{ text: 'วันที่สร้างใบ GR' }} content={{ text: moment.tz(data.createdAt, 'Asia/Bangkok').format('DD/MM/YY') }} />
          {
            !_.isEmpty(data.refId)
            && <LargeTextWithLabel margin='8px 24px 0 0' title={{ text: 'Invoice Reference' }} content={{ text: data.refId }} />
          }
          <LargeTextWithLabel margin='8px 24px 0 0' title={{ text: dateType[1].text }} content={{ text: _.isEmpty(data.paymentDueDate) ? '-' : moment.tz(data.paymentDueDate, 'Asia/Bangkok').format('DD/MM/YY') }} />
          {
            !_.isEmpty(data.supplier)
            && <LargeTextWithLabel margin='8px 24px 0 0' title={{ text: 'Supplier' }} content={{ text: data.supplier.name }} />
          }
          <LargeTextWithLabel margin='8px 24px 0 0' title={{ text: 'คลังสินค้า' }} content={{ text: data.warehouse ? data.warehouse.warehouseName : '-' }} />
          <LargeTextWithLabel
            margin='8px 24px 0 0'
            title={{ text: 'ใบสั่งซื้อที่เกี่ยวข้อง' }}
            content={_.isEmpty(poRef) ? { text: '-' } : poRef}
          />
        </Div>
        {
          !_.isEmpty(data.goodsReceiveFiles)
          && <LargeTextWithLabel
            style={{ maxWidth: '100%', wordBreak: 'break-all' }}
            title={{ text: 'เอกสาร' }}
            margin='0 0 16px'
            content={data.goodsReceiveFiles.map((v, i) => ({
              text: v.fileName,
              type: 'a',
              link: v.url,
              margin: i === data.goodsReceiveFiles.length - 1 ? 0 : '0 0 4px',
              target: '_blank',
            }))}
          />
        }
        <Remark description={_.isEmpty(data.remarks) ? '-' : data.remarks} />
      </Div>
      {
        <Collapse text='รายละเอียดสินค้า' fullHeight open={openCollapse} onClick={() => setOpenCollapse(!openCollapse)}>
          <Div>
            <TableList data={TableListData} headerData={headerData} columnStyled={tableStyled} />
          </Div>
        </Collapse>
      }
    </Div>
  )
}

const GrList = (props) => {
  const [searchText, setSearchText] = useState('')
  const [selectedDateType, setSelectedDateType] = useState(dateType[0].value)
  const [selectedDate, setSelectedDate] = useState(null)
  const [selectedStatus, setSelectedStatus] = useState(undefined)
  const [firstLoad, setFirstLoad] = useState(true)
  const [filterLoading, setFilterLoading] = useState(false)
  const [fetchMoreLoading, setFetchMoreLoading] = useState(false)
  const [disabledButton, setDisabledButton] = useState(false)
  const isInitialMount = useRef(true)
  const {
    data, loading, fetchMore, refetch,
  } = useQuery(GOOD_RECEIVES, {
    variables: {
      limit: LIST_LIMIT,
      offset: 0,
    },
  })
  const { data: statusListData } = useQuery(GR_STATUS_LIST)
  const [doConfirmGr] = useMutation(GOOD_RECEIVE_CONF, { refetchQueries: ['remainingJob'] })

  const authorization = useContext(AuthorizationContext)
  const goodsReceiveAutho = authorization.authorization?.goodsReceive || {}
  const { remainingJobs } = useContext(RemainingJobsContext)

  const statusOption = statusListData?.goodsReceiveStatusList?.data.map((v) => (
    { text: `${GR_STATUS.find((o) => o.key === v.status).text} (${v.count})`, value: v.status }
  )) || []
  statusOption.unshift({ text: statusListData?.goodsReceiveStatusList?.data ? `ทั้งหมด (${_.reduce(statusListData.goodsReceiveStatusList.data, (sum, n) => sum + n.count, 0)})` : 'ทั้งหมด', value: 'all' })

  const grList = data?.goodsReceiveList?.data?.list?.map((v) => (
    {
      title: v.number,
      status: _.find(GR_STATUS, (o) => o.key === v.status),
      content: {
        one: {
          title: { text: 'Supplier' },
          content: { text: _.isEmpty(v.supplier) ? '-' : v.supplier.name },
        },
        three: {
          title: { text: dateType[1].text },
          content: { text: _.isEmpty(v.paymentDueDate) ? '-' : moment.tz(v.paymentDueDate, 'Asia/Bangkok').format('DD/MM/YY') },
        },
      },
      detail: {
        title: [
          {
            text: 'รายละเอียด',
            icon: 'fal fa-info-square',
          },
          goodsReceiveAutho.viewHistoryLog && {
            text: 'ประวัติการใช้งาน',
            icon: 'fad fa-history',
          },
        ],
        buttonList: [
          v.status === GR_KEYS.draft && goodsReceiveAutho.edit && <Link key={0} to={`${PATH.gr}${PATH.edit}/${v.id}`}>
            <Button small ghost text='แก้ไขบันทึกร่าง' icon='fal fa-edit' margin='4px 8px 4px 0' />
          </Link>,
          v.status === GR_KEYS.confirm && goodsReceiveAutho.confirmGR && <Button key={1} disabled={disabledButton} inverse color={theme.color.success} small ghost text='ยืนยัน' icon='fal fa-check' margin='4px 8px 4px 0' onClick={() => {
            setDisabledButton(true)
            doConfirmGr({
              variables: {
                id: v.id,
              },
            }).then((resp) => {
              setDisabledButton(true)
              if (resp.data.confirmGoodsReceive.success) {
                message.success(resp.data.confirmGoodsReceive.message)
                refetch()
              } else {
                message.error(resp.data.confirmGoodsReceive.message)
              }
            })
          }}/>,
          (v.status === GR_KEYS.create || v.status === GR_KEYS.confirm) && goodsReceiveAutho.edit && <Link key={3} to={`${PATH.gr}${PATH.edit}/${v.id}`}>
            <Button small ghost text='แก้ไขใบ GR' icon='fal fa-edit' margin='4px 8px 4px 0' />
          </Link>,
        ],
        content: [
          <Detail
            key={0}
            status={_.find(GR_STATUS, (o) => o.key === v.status)}
            data={v}
          />,
          <History
            key={1}
            data={v}
            moduleName='GOODS_RECEIVE'
          />,
        ],
      },
    }
  )) || []

  useEffect(() => {
    if (!goodsReceiveAutho.view) {
      permissionRedirect()
    }
  }, [])

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false
    } else {
      setFilterLoading(true)
      setFirstLoad(false)
      refetch({
        search: searchText,
        status: selectedStatus === 'all' ? undefined : selectedStatus,
        createdDate: selectedDateType === 'createdDate' ? selectedDate : undefined,
        dueDate: selectedDateType !== 'createdDate' ? selectedDate : undefined,
      }).then(() => {
        setFilterLoading(false)
      })
    }
  }, [searchText, selectedStatus, selectedDateType, selectedDate])

  useEffect(() => {
    if (!_.isEmpty(props.location?.state?.search)) {
      setSearchText(props.location?.state?.search)
    }
  }, [props.location?.state?.search])

  const waitingPoConfirmCount = _.find(statusListData?.goodsReceiveStatusList?.data, (o) => o.status === GR_KEYS.confirm)?.count

  return (
    <Div>
      <Helmet>
        <meta name="viewport" content="width=1280" />
      </Helmet>
      <ListContainer
        tabLoading={fetchMoreLoading}
        listLoading={filterLoading || (firstLoad && _.isEmpty(data))}
        title={[
          {
            text: pageName,
            icon: 'fal fa-dolly-flatbed-alt',
          },
        ]}
        isFullPage
        buttonList={goodsReceiveAutho.create ? [
          <Link key={0} to={`${PATH.gr}${PATH.create}`}>
            <Badge count={remainingJobs.gr}>
              <Button small ghost inverse color={theme.color.success} text='เพิ่มใบ GR ใหม่' icon='fal fa-plus' />
            </Badge>
          </Link>,
        ] : []}
        filter={[
          <Div margin='0 0 24px' key={0}>
            <Div display='flex' alignItems='flex-start' moreStyle={{ flexGrow: 1 }}>
              <Div margin='0 16px 0 0' moreStyle={{ flexGrow: 1 }}>
                <Input
                  type='text'
                  width='100%'
                  prefix={<Icon icon='prefix fal fa-search' />}
                  placeholder='Supplier Name, ใบสั่งซื้อ, GR Number, Reference Number, ชื่อสินค้า, รหัสสินค้า'
                  // value={searchText}
                  onChange={(e) => {
                    const searchValue = e.target.value
                    delayInput(() => {
                      setSearchText(searchValue)
                    })
                  }}
                  suffix={(!loading && !_.isEmpty(data?.goodsReceiveList?.data) && !_.isEmpty(searchText)) && <Div position='absolute' right='16px' top='50%' moreStyle={{ transform: 'translateY(-50%)' }}><SmallText>{numeral(data?.goodsReceiveList?.data?.total || 0).format('0,0')} รายการ</SmallText></Div>}
                />
              </Div>
              <Select
                style={{ width: '100%', flexBasis: '13%', margin: '0 16px 0 0' }}
                suffixIcon={<Icon icon='fal fa-chevron-down' />}
                placeholder='กรองสถานะ'
                options={statusOption}
                value={selectedStatus}
                onChange={(value) => setSelectedStatus(value)}
              />
              <DatePicker.RangePicker
                style={{ flexBasis: '22%' }}
                format='DD/MM/YYYY'
                suffixIcon={<Icon color={theme.color.gray50} icon='fal fa-calendar' />}
                value={selectedDate}
                onChange={(value) => setSelectedDate(value)}
              />
              <Radio.Group value={selectedDateType} style={{ margin: '0 0 0 8px' }}>
                {
                  dateType.map((v) => (
                    <Radio.Button key={v.value} value={v.value} onChange={(e) => setSelectedDateType(e.target.value)}>{v.text}</Radio.Button>
                  ))
                }
              </Radio.Group>
            </Div>
            <Div display='flex' justifyContent='space-between' alignItems='flex-start' margin='8px 0 0'>
              <Div>
                <SmallText>จำนวนทั้งหมด: {numeral(data?.goodsReceiveList?.data?.total || 0).format('0,0')}</SmallText>
                <SmallText display='block' margin='2px 0 0' color={waitingPoConfirmCount > 1 && theme.color.error} bold={waitingPoConfirmCount > 1 && theme.color.error}>จำนวนที่รอใบสั่งซื้ออนุมัติ: {numeral(waitingPoConfirmCount || 0).format('0,0')}</SmallText>
              </Div>
              <Button text='ล้าง' icon='fal fa-sync' small ghost onClick={() => {
                setSearchText('')
                setSelectedStatus(undefined)
                setSelectedDate(null)
                setSelectedDateType(dateType[0].value)
                refetch({
                  search: '',
                  status: undefined,
                  createdDate: undefined,
                  dueDate: undefined,
                }).then(() => {
                  setFilterLoading(false)
                })
              }} />
            </Div>
          </Div>,
        ]}
        filterHeight={100}
        onInfiniteScroll={() => {
          setFetchMoreLoading(true)
          fetchMore({
            variables: {
              offset: data.goodsReceiveList.data.list.length,
            },
            updateQuery: (prev, { fetchMoreResult }) => {
              setFetchMoreLoading(false)
              if (!fetchMoreResult) return prev
              return { ...prev,
                goodsReceiveList: {
                  ...prev.goodsReceiveList,
                  data: {
                    ...prev.goodsReceiveList.data,
                    total: prev.goodsReceiveList.data.total,
                    list: [...prev.goodsReceiveList.data.list, ...fetchMoreResult.goodsReceiveList.data.list],
                  },
                } }
            },
          })
        }}
        list={grList}
      />
    </Div>
  )
}

export default GrList
