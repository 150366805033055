import { theme } from '../../../styles/_variables'

export const billingStatus = {
  WAITING_PAYMENT: {
    text: 'รอชำระเงิน',
    color: theme.color.gray70,
  },
  PAYMENT_OVERDUE: {
    text: 'เลยกำหนดการ',
    color: theme.color.error,
  },
  PARTIAL_PAYMENT: {
    text: 'ชำระเงินแล้วบางส่วน',
    color: theme.color.warning,
  },
  PAID: {
    text: 'ชำระเงินแล้ว',
    color: theme.color.success,
  },
}

export default billingStatus
