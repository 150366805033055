const MESSAGE = {
  permission: {
    denied: 'คุณไม่มีสิทธิการเข้าถึง',
  },
  empty: {
    list: 'ไม่มีข้อมูล',
  },
  pt: {
    create: {
      cancel: 'ยืนยันจะยกเลิกการสร้างการจัดส่งใช่หรือไม่ ?',
    },
  },
  gr: {
    create: {
      inputEmpty: 'กรุณาเลือกคลังสินค้า ก่อน',
      cancel: 'ยืนยันยกเลิกการสร้างใบ GR หรือไม่ ?',
      modal: {
        title: 'ยืนยันสร้างใบ GR หรือไม่ ?',
        content: 'ยืนยันรับสินค้า ดังนี้',
        warningText: '* หากกดสร้างแล้วจะไม่สามารถเพิ่มหรือลบสินค้าได้',
      },
    },
    edit: {
      cancel: 'ยืนยันยกเลิกการแก้ไขใบ GR หรือไม่ ?',
    },
  },
  po: {
    create: {
      inputEmpty: 'กรุณาเลือก Supplier ก่อน',
      cancel: 'แน่ใจหรือไม่ว่าต้องการยกเลิก?',
      confirmable: 'ไม่สามารถแก้ไขได้ เนื่องจาก Supplier คอนเฟริมแล้ว',
    },
    edit: {
      dataNull: 'ไม่มีข้อมูลใบสั่งซื้อนี้ในระบบ',
      uneditable: 'ไม่สามารถแก้ไขได้ เนื่องจาก Status เป็นเสร็จสิ้น หรือ ยกเลิก',
    },
    inventory: {
      preordered: 'สินค้าที่กำลังเข้าคลัง และมีการสั่งซื้อแล้ว',
      soldStock: 'สินค้าที่ขายแล้ว',
      tobeStocked: 'สินค้าที่กำลังเข้าคลัง',
      totalStock: 'สินค้าทั้งหมดในคลัง',
      minimumStock: 'สินค้าขั้นต่ำในคลัง',
    },
  },
  inventory: {
    preordered: 'สินค้าที่กำลังเข้าคลัง และมีการสั่งซื้อแล้ว',
    soldStock: 'สินค้าที่ขายแล้ว',
    tobeStocked: 'สินค้าที่กำลังเข้าคลัง',
    totalStock: 'สินค้าทั้งหมดในคลัง',
    minimumStock: 'สินค้าขั้นต่ำในคลัง',
  },
}

export default MESSAGE
