import { theme } from '../../styles/_variables'

export const GR_KEYS = {
  create: 'CREATED',
  billed: 'BILLED',
  paid: 'PAID',
  confirm: 'WAITING_CONFIRM',
  draft: 'DRAFT',
}

const GR_STATUS = [
  {
    key: GR_KEYS.create,
    text: 'สร้างใบ GR สำเร็จ',
    color: theme.color.gray50,
    icon: 'fal fa-check-circle',
  }, {
    key: GR_KEYS.billed,
    text: 'สร้างบิลสำเร็จ',
    color: theme.color.warning,
    icon: 'fal fa-check-circle',
  }, {
    key: GR_KEYS.paid,
    text: 'ชำระเงินสำเร็จ',
    color: theme.color.success,
    icon: 'fal fa-check-circle',
  }, {
    key: GR_KEYS.confirm,
    text: 'รอยืนยัน',
    color: theme.color.gray50,
    icon: 'fal fa-check-circle',
  }, {
    key: GR_KEYS.draft,
    text: 'บันทึกร่าง',
    color: theme.color.error,
    icon: 'fal fa-check-circle',
  },
]

export default GR_STATUS
