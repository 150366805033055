export const SALE_TYPE = {
  VARIANT: 'VARIANT',
  PACK: 'PACK',
  NONE: 'NONE',
}

export const PRODUCT_GROUP = {
  AC: 'AC',
  PARTS: 'PARTS',
  OTHERS: 'OTHERS',
}

export const STOCK_TYPE = {
  STOCK: 'STOCK',
  NONSTOCK: 'NONSTOCK',
  SERVICE: 'SERVICE',
}

export const VAT_TYPE = {
  NO_VAT: 'NO_VAT',
  VAT0: 'VAT0',
  VAT7: 'VAT7',
}

export const CATEGORIES = {
  WALL: 'ติดผนัง',
  CEILLING: 'แขวน',
  PACKAGE: 'ตู้ตั้ง',
  BUILD_IN: 'ฝังเพดาน',
  WINDOW: 'หน้าต่าง',
  MOVABLE: 'เคลื่อนที่',
  DUCT: 'ท่อลม',
}

export const BRANDS = {
  HITACHI: 'Hitachi',
  MITSUBISHI: 'Mitsubishi',
  SHARP: 'Sharp',
  DAIKIN: 'Daikin',
  LG: 'LG',
  PANASONIC: 'Panasonic',
  FUJITSU: 'Fujitsu',
  SAMSUNG: 'Samsung',
  HAIER: 'Haier',
}
