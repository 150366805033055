import React, {
  useState, useEffect, useRef, useContext,
} from 'react'
import numeral from 'numeral'
import { Link } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import _ from 'lodash'
import {
  Div, Button, Icon, Input, SmallText,
} from '@konsys-ui-custom'
import { Helmet } from 'react-helmet'
import { WAREHOUSES } from '../../api/query'
import { ListContainer, Select } from '../../components'
import QuickView from './quick-view'
import { AuthorizationContext } from '../../store/StoreProvider'
import { theme } from '../../styles/_variables'
import { delayInput } from '../../utils'

const KEY = 'warehouseList'

export default (props) => {
  const [searchText, setSearchText] = useState('')
  const [filterLoading, setFilterLoading] = useState(false)
  const [fetchMoreLoading, setFetchMoreLoading] = useState(false)
  const [orderField, setOrderField] = useState('createdAt')
  const [orderBy, setOrderby] = useState('DESC')
  const isInitialMount = useRef(true)
  const { authorization } = useContext(AuthorizationContext)
  const {
    data, loading, fetchMore, refetch,
  } = useQuery(WAREHOUSES, { variables: { limit: 10, offset: 0 } })

  const mapped = (!loading || !_.isEmpty(data)) && data[KEY].data.list.map((v) => ({
    id: v.warehouseCode,
    title: v.warehouseName,
    content: {
      one: {
        title: {
          text: 'รหัสคลังสินค้า',
        },
        content: {
          text: v.warehouseCode,
        },
      },
      two: {
        title: {
          text: 'จังหวัด',
        },
        content: {
          text: v.address.province || 'ไม่ระบุ',
        },
      },
    },
    detail: {
      title: [
        {
          text: 'รายละเอียด',
          icon: 'fad fa-info-square',
        },
      ],
      buttonList: [
        authorization.warehouse.edit && <Link key='edit' to={`${props.match.url}/edit/${v.id}`}>
          <Button small ghost margin='4px 8px 4px 0' text='แก้ไข' icon='far fa-edit' />
        </Link>,
        authorization.warehouse.create && <Link key='create' to={`${props.match.url}/create`}>
          <Button small ghost inverse color={theme.color.success} margin='4px 8px 4px 0' text='สร้างคลังใหม่' icon='far fa-plus' />
        </Link>,
      ],
      content: [
        <QuickView key={v.id} data={v}/>,
      ],
    },
  }))

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false
    } else {
      setFilterLoading(true)
      refetch({
        warehouseName: searchText,
        orderBy,
        orderField,
      }).then(() => {
        setFilterLoading(false)
      })
    }
  }, [searchText, orderField, orderBy])

  return (
    <Div>
      <Helmet>
        <meta name="viewport" content="width=1280" />
      </Helmet>
      <ListContainer
        onInfiniteScroll={() => {
          setFetchMoreLoading(true)
          fetchMore({
            variables: {
              offset: data[KEY].data.list.length,
            },
            updateQuery: (prev, { fetchMoreResult }) => {
              setFetchMoreLoading(false)
              if (!fetchMoreResult) return prev
              return { ...prev,
                [KEY]: {
                  ...fetchMoreResult[KEY],
                  data: {
                    ...fetchMoreResult[KEY].data,
                    list: [...prev[KEY].data.list, ...fetchMoreResult[KEY].data.list],
                  },
                } }
            },
          })
        }}
        tabLoading={fetchMoreLoading}
        listLoading={filterLoading || (loading && _.isEmpty(data))}
        title={[
          {
            text: 'รายชื่อคลังสินค้า',
            icon: 'fad fa-map-marked-alt',
          },
        ]}
        filterHeight={100}
        filter={[
          <Div margin='0 0 24px' key={0}>
            <Div display='flex' alignItems='flex-start' moreStyle={{ flexGrow: 1 }}>
              <Input
                type='text'
                width='100%'
                prefix={<Icon icon='prefix fal fa-search' />}
                placeholder='ค้นหาชื่อ'
                // value={searchText}
                onChange={(e) => {
                  const searchValue = e.target.value
                  delayInput(() => {
                    setSearchText(searchValue)
                  })
                }}
                suffix={(!loading && !_.isEmpty(data?.warehouseList?.data) && !_.isEmpty(searchText)) && <Div position='absolute' right='16px' top='50%' moreStyle={{ transform: 'translateY(-50%)' }}><SmallText>{numeral(data?.warehouseList?.data?.total || 0).format('0,0')} รายการ</SmallText></Div>}
              />
            </Div>
            <Div display='flex' margin='8px 0 0'>
              <Div display='flex' moreStyle={{ flexGrow: 1 }} margin='0 56px 0 0'>
                <Select
                  defaultValue='createdAt'
                  onChange={(value) => setOrderField(value)}
                  value={orderField}
                  style={{ width: '41.65%', minWidth: '200px', margin: '0 16px 0 0' }}
                  options={[{ text: 'เรียงตามวันที่สร้าง', value: 'createdAt' }, { text: 'เรียงตามวันที่อัพเดท', value: 'updatedAt' }]}
                />
                <Select
                  suffixIcon={<Icon icon={orderBy === 'ASC' ? 'fal fa-sort-amount-down-alt' : 'fal fa-sort-amount-up-alt'} />}
                  defaultValue='DESC'
                  value={orderBy}
                  onChange={(value) => setOrderby(value)}
                  options={[{ text: 'น้อยไปมาก', value: 'ASC' }, { text: 'มากไปน้อย', value: 'DESC' }]}
                />
              </Div>
              <Div display='flex' alignItems='center'>
                <Button text='ล้าง' icon='fal fa-sync' small ghost onClick={() => {
                  setSearchText('')
                  setFilterLoading(true)
                  setOrderField('createdAt')
                  setOrderby('ASC')
                  refetch({
                    warehouseName: '',
                  }).then(() => {
                    setFilterLoading(false)
                  })
                }} />
              </Div>
            </Div>
          </Div>,
        ]}
        list={mapped}
      >
      </ListContainer>
    </Div>
  )
}
