import { useMutation, useQuery } from '@apollo/client'
import {
  Button,
  Div,
  Icon,
  P, SmallText,
} from '@konsys-ui-custom'
import { Collapse, message } from 'antd'
import _ from 'lodash'
import React, { useContext, useState } from 'react'
import {
  CREATE_ROLE, UPDATE_ROLE, UPDATE_ROLE_PERMISSION,
} from '../../../api/mutation'
import { GET_ROLES } from '../../../api/query'
import { FullPageContainer, InputWithLabel, Modal } from '../../../components'
import { AuthorizationContext } from '../../../store/StoreProvider'
import { theme } from '../../../styles/_variables'
import { validateData } from '../../../utils/util-services'
import { IconSelector, roleTitle } from '../util-functions'
import './index.css'
import { ActionCheckBox, ActionCheckBoxContainer, RoleList } from './styled'

const { color } = theme

const HeaderItem = (props) => {
  const [modal, setModal] = useState(false)
  const [roleName, setRoleName] = useState()
  const [loading, setLoading] = useState(false)
  const [doUpdateRole] = useMutation(UPDATE_ROLE)
  return (
    <Div
      padding='8px' width='100px' height='80px'
      alignItems='center' justifyContent='flex-start' display='flex' flexDirection='column'
      style={{ position: 'relative', flexShrink: '0' }}
    >
      <Modal
        title='เปลี่ยนชื่อกลุ่มผู้ใช้'
        visible={modal}
        okButton={{
          text: 'ยืนยันเปลี่ยนชื่อกลุ่มผู้ใช้',
          color: theme.color.success,
          loading,
          disabled: validateData(true, [roleName], {}) || loading,
        }}
        cancelButton={{
          text: 'ยกเลิก',
          disabled: loading,
        }}
        onAction={(e) => {
          if (e === 'back') {
            setModal(false)
          } else {
            setLoading(true)
            doUpdateRole({
              variables: {
                id: props.id,
                description: roleName,
              },
            }).then((resp) => {
              setLoading(false)
              if (resp.data.updateRole.success) {
                message.success(resp.data.updateRole.message)
                setModal(false)
                props.refetch()
              } else {
                message.error(resp.data.updateRole.message)
              }
            })
          }
        }}
      >
        <Div>
          <InputWithLabel
            width='100%'
            input={{
              width: '100%',
              defaultValue: props.title,
            }}
            onChange={(e) => setRoleName(e.target.value)}
            title={{ text: 'ชื่อกลุ่มผู้ใช้ *' }}
          />
        </Div>
      </Modal>
      <Div>
        {
          IconSelector(props.id)
        }
        {
          props.authorization.permission.edit && <Icon icon='fal fa-pencil' onClick={() => setModal(true)} style={{
            position: 'absolute', top: 0, right: 12, cursor: 'pointer',
          }} color={theme.color.gray50} />
        }
      </Div>
      <Div width='100%' textAlign='center'>
        <SmallText bold color={color.gray20} textAlign='center'>
          {props.title}
        </SmallText>
      </Div>
    </Div>
  )
}

const Item = (props) => {
  const [doUpdateRolePermission] = useMutation(UPDATE_ROLE_PERMISSION)
  return (
    <Div width='100px' textAlign='center' padding='0 0 0 8px'>
      <ActionCheckBox
        disabled={!props.authorization.permission.edit}
        defaultChecked={props.value}
        data={{ value: `${props.id}${props.permissionId}`, text: '' }}
        onChange={() => {
          doUpdateRolePermission({
            variables: {
              roleId: props.id,
              permissionId: props.permissionId,
            },
          }).then((resp) => {
            if (resp.data.updateRolePermission.success) {
              message.success(resp.data.updateRolePermission.message)
            } else {
              message.error(resp.data.updateRolePermission.message)
            }
          })
        }}
      />
    </Div>
  )
}

const HeaderRow = ({ contents, authorization, refetch }) => (
  <RoleList style={{ paddingLeft: '48px', position: 'sticky', top: 0, zIndex: 1 }} bgColor={theme.color.offWhite}>
    <Div style={{ marginRight: '150px' }}></Div>
    <Div padding='0 16px 0 0'>
      {
        contents.map((v) => <HeaderItem key={v.id} title={v.name} id={v.id} authorization={authorization} refetch={() => refetch()} />)
      }
    </Div>
  </RoleList>
)

const Row = ({ title, contents, authorization }) => (
  <ActionCheckBoxContainer>
    <Div margin='0 150px 0 0' textAlign='left'>
      <P style={{ whiteSpace: 'nowrap' }}>{title}</P>
    </Div>
    <Div>
      {
        contents.map((v) => (
          <Item description={v.description} id={v.id} authorization={authorization} permissionId={v.permissionId} key={`${v.id}${v.permissionId}`} value={v.active} name={v.name} width='150px' />
        ))
      }
    </Div>
  </ActionCheckBoxContainer>
)

export const Index = () => {
  const { Panel } = Collapse
  const [modal, setModal] = useState(false)
  const [roleName, setRoleName] = useState()
  const roles = useQuery(GET_ROLES)
  const { authorization } = useContext(AuthorizationContext)
  const [doCreateRole] = useMutation(CREATE_ROLE)
  const [loading, setLoading] = useState(false)

  const mapped = roles.loading ? [] : roles.data.rolePermissionList.data.map((role) => (
    {
      id: role.role.id,
      name: role.role.name,
      description: role.role.description,
      allowModules: role.moduleList.map((module) => ({
        id: module.moduleName,
        name: module.moduleName,
        permissions: module.permissionList.map((permission) => ({
          permissionId: permission.id, name: permission.name, description: permission.description, active: permission.status,
        })),
      })),
    }
  ))
  return (
    <FullPageContainer
      loading={roles.loading}
      title={[
        {
          text: 'จัดการหน้าที่และการเข้าถึง',
          icon: 'fad fa-fingerprint',
        },
      ]}
      detail={{
        buttonList: [
          authorization.permission.edit && <Button small ghost inverse color={theme.color.success} text='สร้างกลุ่มผู้ใช้ใหม่' key={0} onClick={() => setModal(true)} />,
        ],
        title: [
          {
            text: 'แก้ไขข้อมูล',
            icon: 'far fa-edit',
          },
        ],
      }}
    >
      {
        !roles.loading
          ? <Div padding='0 0 16px 0' width='auto' height='calc(100vh - 200px)' moreStyle={{ overflow: 'auto' }}>
            <Modal
              title='เพิ่มกลุ่มผู้ใช้ใหม่'
              visible={modal}
              okButton={{
                text: 'ยืนยันเพิ่มกลุ่มผู้ใช้ใหม่',
                color: theme.color.success,
                loading,
                disabled: validateData(true, [roleName], {}) || loading,
              }}
              cancelButton={{
                text: 'ยกเลิก',
                disabled: loading,
              }}
              onAction={(e) => {
                if (e === 'back') {
                  setModal(false)
                } else {
                  setLoading(true)
                  doCreateRole({
                    variables: {
                      description: roleName,
                    },
                  }).then((resp) => {
                    setLoading(false)
                    if (resp.data.createRole.success) {
                      message.success(resp.data.createRole.message)
                      setModal(false)
                      roles.refetch()
                    } else {
                      message.error(resp.data.createRole.message)
                    }
                  })
                }
              }}
            >
              <Div>
                <InputWithLabel
                  width='100%'
                  input={{
                    width: '100%',
                  }}
                  onChange={(e) => setRoleName(e.target.value)}
                  title={{ text: 'ชื่อกลุ่มผู้ใช้ *' }}
                />
              </Div>
            </Modal>
            <HeaderRow authorization={authorization} refetch={() => roles.refetch()} contents={mapped.map((v) => ({ name: v.description, id: v.id }))} />
            <Collapse bordered={false}>
              {
                mapped[0].allowModules.map((v) => (
                  <Panel header={roleTitle[v.name]} key={v.name} style={{
                    fontWeight: 400, fontSize: 16, overflow: 'visible',
                  }}>
                    {
                      v.permissions.map((pe) => (
                        <Row
                          authorization={authorization}
                          key={pe.name}
                          title={pe.description}
                          contents={mapped.map((i) => ({ ..._.find(_.find(i.allowModules, (o) => o.name === v.name).permissions, (mappedP) => mappedP.name === pe.name), id: i.id }))}
                        />
                      ))
                    }
                  </Panel>
                ))
              }
            </Collapse>
          </Div>
          : null
      }
    </FullPageContainer>
  )
}

export default Index
