import React, { useState } from 'react'
import numeral from 'numeral'
import _ from 'lodash'
import { Div, H4 } from '@konsys-ui-custom'
import moment from 'moment-timezone'
import { theme } from '../../styles/_variables'
import { TextBetween, Remark, Collapse } from '../../components'
import { PAYMENT_TYPE } from '../../constants/enum'

export default ({ data }) => {
  const [isOpenCollapse, setIsOpenCollapse] = useState({ creditData: false })
  return (
    <Div margin='0 0 24px 0'>
      <H4 bold color={theme.color.primaryColor} margin='0 0 16px'>ข้อมูลลูกค้า</H4>
      <TextBetween
        title={{
          margin: '8px 0',
          text: 'รหัสลูกค้า',
          bold: true,
        }}
        content={{
          margin: '8px 0',
          text: data.number,
        }}
      />
      <TextBetween
        title={{
          margin: '8px 0',
          text: 'ระดับราคา',
          bold: true,
        }}
        content={{
          margin: '8px 0',
          text: data.pricingType?.name || '-',
        }}
      />
      {
        data.paymentType === PAYMENT_TYPE[1].value
        && <Collapse margin='8px 0' fullHeight text='ข้อมูลเครดิต' open={isOpenCollapse.creditData} onClick={() => setIsOpenCollapse({ ...isOpenCollapse, creditData: !isOpenCollapse.creditData })}>
          <Div>
            <TextBetween
              title={{
                margin: '8px 0',
                text: 'วงเงิน',
              }}
              content={{
                margin: '8px 0',
                text: numeral(data.creditLimit).format('0,0.00'),
              }}
            />
            <TextBetween
              title={{
                margin: '8px 0',
                text: 'วงเงินที่ใช้ไปแล้ว',
              }}
              content={{
                margin: '8px 0',
                text: numeral(data.creditUsage).format('0,0.00'),
              }}
            />
            <TextBetween
              title={{
                margin: '8px 0',
                text: 'วงเงินที่เหลือ',
              }}
              content={{
                margin: '8px 0',
                text: numeral(data.creditLimit - data.creditUsage).format('0,0.00'),
              }}
            />
          </Div>
        </Collapse>
      }
      <TextBetween
        title={{
          margin: '8px 0',
          text: 'เบอร์มือถือ',
          bold: true,
        }}
        content={{
          margin: '8px 0',
          text: _.find(data.contactPhoneNumber, (o) => o.isPrimary)?.phoneNumber || '-',
        }}
      />
      <TextBetween
        title={{
          margin: '8px 0',
          text: 'เบอร์บ้าน',
          bold: true,
        }}
        content={{
          margin: '8px 0',
          text: data.contactPhoneNumber?.[1]?.phoneNumber || '-',
        }}
      />
      <TextBetween
        title={{
          margin: '8px 0',
          text: 'วันเกิด',
          bold: true,
        }}
        content={{
          margin: '8px 0',
          text: data.birthDate ? moment.tz(data.birthDate, 'asia/bangkok').format('DD/MM/YYYY') : '-',
        }}
      />
      <TextBetween
        title={{
          margin: '8px 0',
          text: 'สถานะข้อมูล',
          bold: true,
        }}
        content={{
          margin: '8px 0',
          text: data.status === 'ACTIVE' ? 'ใช้งาน' : 'ไม่ใช้งาน',
          color: data.status === 'ACTIVE' ? theme.color.success : theme.color.error,
        }}
      />
      <TextBetween
        title={{
          margin: '8px 0',
          text: 'Line',
          bold: true,
        }}
        content={{
          margin: '8px 0',
          text: _.find(data.contactAddress, (o) => o.isPrimary)?.contactLineId || '-',
          color: '#00B900',
        }}
      />
      <TextBetween
        title={{
          margin: '8px 0',
          text: 'Facebook',
          bold: true,
        }}
        content={{
          margin: '8px 0',
          text: _.find(data.contactAddress, (o) => o.isPrimary)?.contactFacebook || '-',
          color: '#4267B2',
        }}
      />
      <Remark showIcon message='หมายเหตุ' description={data.contactRemark || '-'} />
    </Div>
  )
}
