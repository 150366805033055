import React, { useState, useEffect } from 'react'
import { useMutation, useQuery } from '@apollo/client'
import { message } from 'antd'
import _ from 'lodash'
import { Div, H4, Button, P, Icon } from '@konsys-ui-custom'
import {
  InputWithLabel,
  Select,
  ComponentWithLabelLarge,
  Divider,
  Loading,
} from '../../components'
import { theme } from '../../styles/_variables'
import { CONVERT_SKU, PAIR_ITEMS } from '../../api/mutation'
import { INVENTORIES, INVENTORY } from '../../api/query'

const InventoryOption = ({ option, refetch, id, inventoryOptionId }) => {
  const [doUpdate, { loading: loadingConvert }] = useMutation(CONVERT_SKU, {
    refetchQueries: ['remainingJob', 'remainingAccountant'],
  })
  const [doPair, { loading: loadingPair }] = useMutation(PAIR_ITEMS, {
    refetchQueries: ['remainingJob', 'remainingAccountant'],
  })
  const [warehouseId, setWarehouseId] = useState()
  const [oldQuantity, setOldQuantity] = useState()
  const [newQuantity, setNewQuantity] = useState()
  const [item, setItem] = useState({
    id: option.pair?.id,
    variantName: option.pair?.variantName,
  })
  const [search, setSearch] = useState()
  const { data: invData, loading: invLoading } = useQuery(INVENTORIES, {
    variables: { limit: 10, offset: 0, search },
  })

  useEffect(() => {
    setItem({ id: option.pair?.id, variantName: option.pair?.variantName })
  }, [option])
  return (
    <Div
      style={{
        border: `1px solid ${theme.color.lightBlue}`,
        borderRadius: 5,
        background: theme.color.primaryColor10,
      }}
      margin="16px 0"
      padding="16px"
      display="flex"
      flexDirection="column"
    >
      <P bold>สินค้าต้นทางก่อนแปลง {option.variantName}</P>
      <Divider color={theme.color.lightBlue} />
      <Div display="flex" flexDirection="row" alignItems="flex-end">
        <ComponentWithLabelLarge
          width="100%"
          margin="0 16px 0 0"
          title={{ text: 'จับคู่สินค้า' }}
        >
          <Select
            tooltip
            showSearch
            loading={invLoading}
            // disabled={!isCreateMode}
            style={{ width: '100%' }}
            placeholder="เลือกสินค้า"
            notFoundContent={null}
            showArrow={false}
            filterOption={false}
            value={item.variantName}
            onSelect={(e) => {
              const product = _.find(
                invData.inventoryList.data.list,
                (o) => o.inventoryOption.id === e,
              )
              setItem({
                ...product,
                variantName: product.productName,
              })
            }}
            onSearch={(value) => setSearch(value)}
            options={
              invData
                ? _.remove(
                  _.cloneDeep(
                    invData.inventoryList.data.list.map((val) => ({
                      value: val.inventoryOption.id,
                      text: val.productName,
                      itSelfId: val.id,
                    })),
                  ),
                  (o) => o.itSelfId !== id,
                )
                : []
            }
          />
        </ComponentWithLabelLarge>
        <Button
          text="จับคู่"
          small
          inverse
          width="120px"
          loading={loadingPair}
          onClick={() => doPair({
            variables: {
              thisItemId: parseInt(inventoryOptionId),
              pairItemId: item.inventoryOption.id,
            },
          }).then((resp) => {
            if (resp.data.addItemPair.success) {
              message.success(resp.data.addItemPair.message)
              refetch()
            } else message.error(resp.data.addItemPair.message)
          })
          }
        />
      </Div>
      <Divider color={theme.color.lightBlue} />
      <Div display="flex" alignItems="center" justifyContent="center">
        <ComponentWithLabelLarge
          margin="8px 0"
          width="100%"
          title={{ text: 'คลังสินค้า' }}
        >
          <Select
            disabled={!option.pair}
            style={{ width: '100%' }}
            onChange={(value) => {
              // setSourceQuantity(_.find(option.stockLocation, o => o.warehouse.id === value).totalStock)
              setWarehouseId(value)
            }}
            options={option.stockLocation.map((v) => ({
              text: v.warehouse.warehouseName,
              value: v.warehouse.id,
            }))}
          />
        </ComponentWithLabelLarge>
      </Div>
      <Div display="flex" alignItems="center" justifyContent="center">
        <InputWithLabel
          width="100%"
          margin="8px 0"
          input={{
            disabled: !option.pair,
            width: '100%',
            placeholder: 'กรอกจำนวน',
            state: 'default',
            rule: { type: 'float' },
          }}
          onChange={(e) => setOldQuantity(e.target.value)}
          title={{ text: 'จำนวนสินค้าต้นทางที่ใช้ไป' }}
        />
        <Icon
          icon="fas fa-chevron-double-right"
          width="100%"
          margin="16px 16px 0"
        />
        <InputWithLabel
          width="100%"
          margin="8px 0"
          input={{
            disabled: !option.pair,
            width: '100%',
            placeholder: 'กรอกจำนวน',
            state: 'default',
            rule: { type: 'float' },
          }}
          onChange={(e) => setNewQuantity(e.target.value)}
          title={{ text: 'จำนวนสินค้าปลายทางที่เพิ่มขึ้น' }}
        />
      </Div>
      {/* <TextareaWithLabel
        width='100%'
        title={{
          text: 'เหตุผล',
        }}
        textarea={{
          style: { width: '100%' },
          rows: 5,
          onChange: e => setReason(e.target.value),
        }}
      /> */}
      <Button
        style={{ alignSelf: 'flex-end' }}
        rightIcon
        small
        icon="fal fa-angle-right"
        text="ยืนยัน"
        inverse
        color={theme.color.success}
        disabled={
          loadingConvert
          // || validateData(
          //   true,
          //   [
          //     sourceId,
          //     destinationId,
          //     itemId,
          //     parseFloat(quantityChange),
          //   ],
          //   {},
          // )
        }
        loading={loadingConvert}
        onClick={() => {
          doUpdate({
            variables: {
              warehouseId,
              oldId: parseInt(inventoryOptionId),
              // newId: parseInt(item.inventoryOption.id),
              oldQuantity: parseFloat(oldQuantity),
              newQuantity: parseFloat(newQuantity),
              // reason,
            },
          }).then((resp) => {
            if (resp.data.convertSku.success) {
              message.success(resp.data.convertSku.message)
              refetch()
            } else message.error(resp.data.convertSku.message)
          })
        }}
      />
    </Div>
  )
}

export default (props) => {
  const [inventoryData, setInventoryData] = useState()
  const { loading: inventoryLoading, data: inventoryDetail } = useQuery(
    INVENTORY,
    { variables: { id: props.data.id } },
  )

  useEffect(() => {
    setInventoryData(inventoryDetail?.inventoryInfo?.data)
  }, [inventoryDetail?.inventoryInfo?.data])

  if (inventoryLoading) {
    return (
      <Div textAlign="center" margin="32px 0 0">
        <Loading size="small" />
      </Div>
    )
  }
  if (_.isEmpty(inventoryData)) return null

  return (
    <Div>
      <Div margin="16px 0">
        <H4 bold>แปลงหน่วยสินค้า</H4>
        <Div>
          <InventoryOption
            option={inventoryData.inventoryOption}
            inventoryOptionId={inventoryData.inventoryOption.id}
            id={props.data.id}
            refetch={props.refetch}
          />
        </Div>
      </Div>
    </Div>
  )
}
