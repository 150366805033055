import React, { useState, useEffect } from 'react'
import { useMutation, useQuery } from '@apollo/client'
import { message } from 'antd'
import _ from 'lodash'
import {
  Div, H4, Button, P, Icon,
} from '@konsys-ui-custom'
import {
  InputWithLabel, TextareaWithLabel, Select, ComponentWithLabelLarge, Loading,
} from '../../components'
import { theme } from '../../styles/_variables'
import { TRANSFER_STOCK } from '../../api/mutation'
import { WAREHOUSES, INVENTORY } from '../../api/query'
import { validateData } from '../../utils/util-services'

const InventoryOption = ({ option, refetch }) => {
  const [doUpdate, { loading }] = useMutation(TRANSFER_STOCK, { refetchQueries: ['remainingJob', 'remainingAccountant'] })
  const { data, loading: warehousesLoading } = useQuery(WAREHOUSES)

  const [sourceId, setSourceId] = useState()
  const [destinationId, setDestinationId] = useState()
  const [sourceQuantity, setSourceQuantity] = useState()
  const [destinationQuantity, setDestinationQuantity] = useState()
  const [itemId] = useState(option.id)
  const [quantityChange, setQuantityChange] = useState()
  const [reason, setReason] = useState()

  return (
    <Div style={{ border: `1px solid ${theme.color.lightBlue}`, borderRadius: 5, background: theme.color.primaryColor10 }} margin='16px 0' padding='16px' display='flex' flexDirection='column'>
      <P bold>{option.variantName}</P>
      <Div display='flex' alignItems='center' justifyContent='center'>
        <ComponentWithLabelLarge margin='8px 0' width='80%' title={{ text: `คลังต้นทาง ${!_.isUndefined(sourceId) ? `(${sourceQuantity} ชิ้น)` : ''}` }}>
          <Select
            style={{ width: '100%' }}
            onChange={(value) => {
              setSourceQuantity(_.find(option.stockLocation, (o) => o.warehouse.id === value).totalStock)
              setSourceId(value)
            }}
            options={
              _.remove(_.cloneDeep(option.stockLocation), (o) => o.warehouse.id !== destinationId).map((v) => ({ text: v.warehouse.warehouseName, value: v.warehouse.id }))
            }
          />
        </ComponentWithLabelLarge>
        <Icon icon='fas fa-chevron-double-right' width='100%' margin='16px 16px 0'/>
        <ComponentWithLabelLarge margin='8px 0' width='80%' title={{ text: `คลังปลายทาง ${!_.isUndefined(destinationId) ? `(${destinationQuantity} ชิ้น)` : ''}` }}>
          <Select
            style={{ width: '100%' }}
            onChange={(value) => {
              setDestinationQuantity(_.find(option.stockLocation, (o) => o.warehouse.id === value) ? _.find(option.stockLocation, (o) => o.warehouse.id === value).totalStock : 0)
              setDestinationId(value)
            }}
            loading={warehousesLoading}
            options={
              warehousesLoading ? [] : _.remove(_.cloneDeep(data.warehouseList.data.list), (o) => o.id !== sourceId).map((v) => ({ text: v.warehouseName, value: v.id }))
            }
          />
        </ComponentWithLabelLarge>
        <InputWithLabel
          width='50%'
          margin='8px 0 8px 16px'
          input={{
            width: '100%',
            placeholder: 'กรอกจำนวนใหม่',
            state: 'default',
            rule: { type: 'float' },
          }}
          onChange={(e) => setQuantityChange(e.target.value)}
          title={{ text: 'จำนวนที่ย้าย' }}
        />
      </Div>
      <TextareaWithLabel
        width='100%'
        title={{
          text: 'เหตุผล',
        }}
        textarea={{
          style: { width: '100%' },
          rows: 5,
          onChange: (e) => setReason(e.target.value),
        }}
      />
      <Button
        style={{ alignSelf: 'flex-end' }}
        rightIcon
        small
        icon='fal fa-angle-right'
        inverse
        color={theme.color.success}
        text='ยืนยัน'
        disabled={
          loading
          || validateData(
            true,
            [
              sourceId,
              destinationId,
              itemId,
              parseFloat(quantityChange),
            ],
            {},
          )
        }
        loading={loading}
        onClick={() => {
          doUpdate({
            variables: {
              sourceId,
              destinationId,
              itemId,
              quantityChange: parseFloat(quantityChange),
              reason,
            },
          }).then((resp) => {
            if (resp.data.transferStock.success) {
              message.success(resp.data.transferStock.message)
              refetch()
            } else message.error(resp.data.transferStock.message)
          })
        }}
      />
    </Div>
  )
}

export default (props) => {
  const [inventoryData, setInventoryData] = useState()
  const { loading: inventoryLoading, data: inventoryDetail } = useQuery(INVENTORY, { variables: { id: props.data.id } })

  useEffect(() => {
    setInventoryData(inventoryDetail?.inventoryInfo?.data)
  }, [inventoryDetail?.inventoryInfo?.data])

  if (inventoryLoading) {
    return (
      <Div textAlign='center' margin='32px 0 0'>
        <Loading size='small' />
      </Div>
    )
  }
  if (_.isEmpty(inventoryData)) return null

  return (
    <Div>
      <Div margin='16px 0'>
        <H4 bold>ย้ายคลัง</H4>
        <Div>
          <InventoryOption option={inventoryData.inventoryOption} refetch={props.refetch}/>
        </Div>
      </Div>
    </Div>
  )
}
