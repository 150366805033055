import {
  SO_STATUS, SO_STATUS_TEXT, SO_STATUS_GROUP, SO_STATUS_GROUP_TEXT,
} from './constants'
import { theme } from '../../styles/_variables'

export const soStatusMaptoColor = (value) => {
  switch (value) {
    case SO_STATUS.WAITCASHPAYMENT:
      return theme.color.greyBlue
    case SO_STATUS.WAITCREDITAPPROVE:
      return theme.color.greyBlue
    case SO_STATUS.WAITCASHAPPROVE:
      return theme.color.greyBlue
    case SO_STATUS.WAITBILLING:
      return theme.color.greyBlue
    case SO_STATUS.WAITING_PO:
      return theme.color.greyBlue
    case SO_STATUS.WAITING_PT:
      return theme.color.greyBlue
    case SO_STATUS.PURCHASING:
      return theme.color.info
    case SO_STATUS.PREPARING:
      return theme.color.warning
    case SO_STATUS.WAITSHIPMENT:
      return theme.color.greyBlue
    case SO_STATUS.COMPLETED:
      return theme.color.success
    case SO_STATUS.WAITCREDITTERM:
      return theme.color.greyBlue
    case SO_STATUS.BILLED:
      return theme.color.info
    case SO_STATUS.CANCELLED:
      return theme.color.error
    case SO_STATUS.OVERDUESHIPMENT:
      return theme.color.error
    case SO_STATUS.RETURNED:
      return theme.color.warning
    case SO_STATUS.REFUNDED:
      return theme.color.error
    case SO_STATUS.WAITING_CN:
      return theme.color.error
    default: return theme.color.greyBlue
  }
}

export const soStatusMaptoText = (value) => {
  switch (value) {
    case SO_STATUS.WAITCASHPAYMENT:
      return SO_STATUS_TEXT.WAITCASHPAYMENT
    case SO_STATUS.WAITBILLING:
      return SO_STATUS_TEXT.WAITBILLING
    case SO_STATUS.WAITCREDITAPPROVE:
      return SO_STATUS_TEXT.WAITCREDITAPPROVE
    case SO_STATUS.WAITCASHAPPROVE:
      return SO_STATUS_TEXT.WAITCASHAPPROVE
    case SO_STATUS.WAITING_PO:
      return SO_STATUS_TEXT.WAITING_PO
    case SO_STATUS.WAITING_PT:
      return SO_STATUS_TEXT.WAITING_PT
    case SO_STATUS.PURCHASING:
      return SO_STATUS_TEXT.PURCHASING
    case SO_STATUS.PREPARING:
      return SO_STATUS_TEXT.PREPARING
    case SO_STATUS.WAITSHIPMENT:
      return SO_STATUS_TEXT.WAITSHIPMENT
    case SO_STATUS.COMPLETED:
      return SO_STATUS_TEXT.COMPLETED
    case SO_STATUS.WAITCREDITTERM:
      return SO_STATUS_TEXT.WAITCREDITTERM
    case SO_STATUS.BILLED:
      return SO_STATUS_TEXT.BILLED
    case SO_STATUS.CANCELLED:
      return SO_STATUS_TEXT.CANCELLED
    case SO_STATUS.OVERDUESHIPMENT:
      return SO_STATUS_TEXT.OVERDUESHIPMENT
    case SO_STATUS.RETURNED:
      return SO_STATUS_TEXT.RETURNED
    case SO_STATUS.REFUNDED:
      return SO_STATUS_TEXT.REFUNDED
    case SO_STATUS.WAITING_CN:
      return SO_STATUS_TEXT.WAITING_CN
    default: return 'ไม่ระบุ'
  }
}

export const soStatusGroupMaptoText = (value) => {
  switch (value) {
    case SO_STATUS_GROUP.INPROGRESS:
      return SO_STATUS_GROUP_TEXT.INPROGRESS
    case SO_STATUS_GROUP.COMPLETED:
      return SO_STATUS_GROUP_TEXT.COMPLETED
    case SO_STATUS_GROUP.WAITPAYMENT:
      return SO_STATUS_GROUP_TEXT.WAITPAYMENT
    case SO_STATUS_GROUP.CANCELLED:
      return SO_STATUS_GROUP_TEXT.CANCELLED
    case SO_STATUS_GROUP.OVERDUESHIPMENT:
      return SO_STATUS_GROUP_TEXT.OVERDUESHIPMENT
    case SO_STATUS_GROUP.RETURNED:
      return SO_STATUS_GROUP_TEXT.RETURNED
    case SO_STATUS_GROUP.REFUNDED:
      return SO_STATUS_GROUP_TEXT.REFUNDED
    case SO_STATUS_GROUP.WAITING_CN:
      return SO_STATUS_GROUP_TEXT.WAITING_CN
    default: return 'ไม่ระบุ'
  }
}

export default soStatusMaptoColor
