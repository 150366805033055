import React, { useContext, useState, useEffect } from 'react'
import { useLazyQuery, useQuery } from '@apollo/client'
import moment from 'moment-timezone'
import { Row, Col, Pagination } from 'antd'
import {
  Div, P, H4, Label,
} from '@konsys-ui-custom'
import _, { isNil } from 'lodash'
import numeral from 'numeral'
import { Collapse, Tab, Loading } from '../../components'
import { theme } from '../../styles/_variables'
import History from '../../components/common/history'
import { COST_CHANGE_LIST, INVENTORY, INVENTORY_LOGS, WAREHOUSES } from '../../api/query'
import { AuthorizationContext } from '../../store/StoreProvider'
import { LIST_LIMIT } from '../../constants/enum'

const typeHandler = (type) => {
  switch (type) {
    case 'GR':
    case 'GOODRECEIVE':
      return theme.color.info
    case 'PT':
      return theme.color.softPurple
    case 'ADJUST':
    case 'ADJUST_STOCK':
      return theme.color.error
    case 'CONVERT':
    case 'CREDIT_NOTE':
      return theme.color.greyBlue
    case 'TRANSFER':
      return theme.color.warning
    case 'CN':
      return theme.color.orange
    default:
      return theme.color.gray80
  }
}

const WarehouseHistory = ({ warehouse, inventoryOptionId }) => {
  const [isOpen, setIsOpen] = useState(true)
  const [currentPage, setCurrentPage] = useState(1)
  const [getLogs, { data: inventoryLog, loading }] = useLazyQuery(INVENTORY_LOGS, {
    variables: {
      input: {
        id: inventoryOptionId,
        limit: LIST_LIMIT,
        offset: 0,
        warehouseId: warehouse.id,
      },
    },
  })

  useEffect(() => {
    getLogs({
      variables: {
        input: {
          id: inventoryOptionId,
          limit: LIST_LIMIT,
          offset: 0,
          warehouseId: warehouse.id,
        },
      },
    })
  }, [])

  return (
    <Div key={warehouse.id} margin='24px 0'>
      <Collapse text={`คลังสินค้า ${warehouse.warehouseName}`} fullHeight={true} open={isOpen} onClick={() => setIsOpen(!isOpen)}>
        {
          loading || isNil(inventoryLog)
            ? <Div textAlign='center' margin='48px 0 0'>
              <Loading size='small' />
            </Div>
            : _.size(inventoryLog.inventoryLogs.data?.logs) > 0
              ? <Div display='flex' flexDirection='column'>
                <Row style={{ padding: '8px 0 8px 8px' }}>
                  <Col span={1} />
                  <Col span={4}>
                    <P textAlign='center' display='block'>วันที่</P>
                  </Col>
                  <Col span={4}>
                    <P>อ้างอิง</P>
                  </Col>
                  <Col span={3}>
                    <P textAlign='center' display='block'>จำนวนสต๊อกเดิม</P>
                  </Col>
                  <Col span={3}>
                    <P textAlign='center' display='block'>ส่วนต่าง</P>
                  </Col>
                  <Col span={3}>
                    <P textAlign='center' display='block'>จำนวนสต๊อกใหม่</P>
                  </Col>
                  <Col span={6}>
                    <P display='block'>หมายเหตุ</P>
                  </Col>
                </Row>
                {
                  inventoryLog.inventoryLogs.data?.logs?.map((log, i) => (
                    <Div key={log.id}>
                      <Row style={{ margin: '16px 0 8px 0', borderLeft: `3px ${typeHandler(log.type)} solid`, padding: '8px 0 8px 8px' }}>
                        <Col span={1}>{i + 1}.</Col>
                        <Col span={4}>
                          <P bold textAlign='center' display='block'>{moment(log.createdAt).format('DD/MM/YYYY')}</P>
                        </Col>
                        <Col span={4}>
                          <P bold>{log.refId || '-'}</P>
                        </Col>
                        <Col span={3}>
                          <P bold textAlign='center' display='block'>{log.oldQuantity}</P>
                        </Col>
                        <Col span={3}>
                          <P bold textAlign='center' display='block' color={log.quantity < 0 ? theme.color.error : theme.color.success}>{log.quantity}</P>
                        </Col>
                        <Col span={3}>
                          <P bold textAlign='center' display='block'>{log.newQuantity}</P>
                        </Col>
                        <Col span={6}>
                          <P display='block' bold>{log.remark || '-'}</P>
                        </Col>
                      </Row>
                    </Div>
                  ))
                }
                <Pagination
                  style={{ marginTop: '16px', alignSelf: 'center' }}
                  defaultCurrent={1}
                  current={currentPage}
                  showSizeChanger={false}
                  total={inventoryLog.inventoryLogs.data?.total}
                  onChange={(e) => {
                    setCurrentPage(e)
                    getLogs({
                      variables: {
                        input: {
                          id: inventoryOptionId,
                          limit: LIST_LIMIT,
                          offset: (e - 1) * 10,
                          warehouseId: warehouse.id,
                        },
                      },
                    })
                  }}
                />
              </Div>
              : <H4 display='block' textAlign='center' bold color={theme.color.lightBlue}>ไม่มีประวัติ</H4>
        }
      </Collapse>
    </Div>
  )
}

const HistoryByWarehouses = (props) => {
  const { data: warehouses, loading: warehouseLoading } = useQuery(WAREHOUSES)

  if (warehouseLoading) {
    return (
      <Div textAlign='center' margin='32px 0 0'>
        <Loading size='small' />
      </Div>
    )
  }
  return (
    <Div>
      <Div key={props.data.inventoryOption.id}>
        <H4 margin='0 0 8px 0'>{props.data.inventoryOption.sku} {props.data.inventoryOption.variantName}</H4>
        {
          warehouses.warehouseList.data.list.map((warehouse) => (
            <WarehouseHistory key={warehouse.id} warehouse={warehouse} inventoryOptionId={props.data.inventoryOption.id} />
          ))
        }
      </Div>
    </Div>
  )
}

const CostChangeHistory = (props) => {
  const { data, loading } = useQuery(COST_CHANGE_LIST, { variables: { inventoryOptionId: props.data.inventoryOption.id } })
  const getCostActionText = (arg, ref) => {
    switch (arg) {
      case 'GOODRECEIVE':
        return `จากใบรับสินค้า ${ref || ''}`
      case 'ADJUST_STOCK':
        return `จากการแก้ไขสต๊อก ${ref || ''}`
      case 'CREDIT_NOTE':
        return `จากใบตีกลับ ${ref || ''}`
      default: return '-'
    }
  }

  if (loading) {
    return (
      <Div margin='48px 0 0' textAlign='center'>
        <Loading size='small' />
      </Div>
    )
  } return (
    <Div>
      {
        _.size(data.CostChangeLogLists.data.list) > 0
          ? <Div>
            <Row style={{ padding: '8px 0 8px 8px' }}>
              <Col span={1} />
              <Col span={3}>
                <P textAlign='center' display='block'>วันที่</P>
              </Col>
              <Col span={3}>
                <P textAlign='center' display='block'>ทุนเก่า</P>
              </Col>
              <Col span={5}>
                <P textAlign='center' display='block'>ทุนใหม่</P>
              </Col>
              <Col span={3}>
                <P textAlign='center' display='block'>จำนวนสต๊อกเก่า</P>
              </Col>
              <Col span={5}>
                <P textAlign='center' display='block'>จำนวนสต๊อกใหม่</P>
              </Col>
              <Col span={4}>
                <P textAlign='right' display='block'>อ้างอิง</P>
              </Col>
            </Row>
            {
              data.CostChangeLogLists.data.list.map((log, i) => (
                <Div key={log.id}>
                  <Row style={{ margin: '16px 0 8px 0', borderLeft: `3px ${typeHandler(log.actionName)} solid`, padding: '8px 0 8px 8px' }}>
                    <Col span={1}>{i + 1}.</Col>
                    <Col span={3}>
                      <P bold textAlign='center' display='block'>{moment(log.createdAt).format('DD/MM/YYYY')}</P>
                    </Col>
                    <Col span={3}>
                      <P bold display='block' textAlign='right'>{numeral(log.oldCost).format('0,0.00')}</P>
                    </Col>
                    <Col span={5}>
                      <Div display='flex' justifyContent='flex-end'>
                        <P bold>{numeral(log.currentCost).format('0,0.00')} <Label bold color={log.differenceCost < 0 ? theme.color.error : theme.color.success}>({numeral(log.differenceCost).format('0,0.00')})</Label></P>
                      </Div>
                    </Col>
                    <Col span={3}>
                      <P bold display='block' textAlign='right'>{numeral(log.oldQuantity).format('0,0.00')}</P>
                    </Col>
                    <Col span={5}>
                      <Div display='flex' justifyContent='flex-end'>
                        <P bold >{numeral(log.newQuantity).format('0,0.00')} <Label bold color={log.differenceQuantity < 0 ? theme.color.error : theme.color.success}>({numeral(log.differenceQuantity).format('0,0.00')})</Label></P>
                      </Div>
                    </Col>
                    <Col span={4}>
                      <Label display='block' textAlign='right' bold>{getCostActionText(log.actionName, log.reference)}</Label>
                    </Col>
                  </Row>
                </Div>
              ))
            }
          </Div>
          : <H4 display='block' textAlign='center' bold color={theme.color.lightBlue}>ไม่มีประวัติ</H4>
      }
    </Div>
  )
}

export default (props) => {
  const { authorization } = useContext(AuthorizationContext)
  const [inventoryData, setInventoryData] = useState()
  const { loading: inventoryLoading, data: inventoryDetail } = useQuery(INVENTORY, { variables: { id: props.data.id } })

  useEffect(() => {
    setInventoryData(inventoryDetail?.inventoryInfo?.data)
  }, [inventoryDetail?.inventoryInfo?.data])

  if (inventoryLoading) {
    return (
      <Div textAlign='center' margin='32px 0 0'>
        <Loading size='small' />
      </Div>
    )
  }

  if (_.isEmpty(inventoryData)) return null

  return (
    <Tab white title={_.filter([
      {
        id: 'viewStockLog',
        text: 'ประวัติคลังสินค้า',
        icon: 'fad fa-history',
      }, {
        id: 'viewHistoryLog',
        text: 'ประวัติการใช้งาน',
        icon: 'fad fa-history',
      }, {
        id: 'viewCostChangeLog',
        text: 'ประวัติการเปลี่ยนต้นทุน',
        icon: 'fad fa-history',
      },
    ], (o) => authorization.inventory[o.id])}>
      {
        _.filter([
          <HistoryByWarehouses key='viewStockLog' {...props} data={inventoryData} />,
          <History
            key='viewHistoryLog'
            data={inventoryData}
            moduleName='INVENTORY'
          />,
          <CostChangeHistory
            key='viewCostChangeLog'
            data={inventoryData}
            moduleName='INVENTORY'
          />,
        ], (o) => authorization.inventory[o.key])
      }
    </Tab>
  )
}
