import gql from 'graphql-tag'

export const SALES_ORDER_FIELDS = gql`
  fragment SalesOrderFields on SalesOrder {
    id
    soNumber
    status
    dueDate
    type
    remarks
    ordersn
    subtotal
    subtotalUpsell
    discountAmount
    vatAmount
    subtotalBeforeVat
    deliveryCost
    addressLabelStatus
    claimType
    claimOrderAmount
    totalBonusSale
    totalBonusUpsell
    claimUser {
      id
      firstName
      lastName
    }
    chatProvider {
      id
      name
    }
    upsellAssignee {
      firstName
      id
      lastName
    }
    subtotalWithDelivery
    subtotalAfterDiscount
    qrCodeUrl
    transferAmount
    isCOD
    quotation {
      id
      refId
    }
    depositReceipt {
      id
    }
    contactAddress {
      id
      supplierId
      customerId
      type
      addressNo
      province
      district
      subDistrict
      postcode
      contactName
      contactOfficeTel
      contactPhoneNumber
      contactFaxNumber
      contactEmail
      contactRemark
    }
    paymentHistories {
      id
      type
      dateTime
      subtotal
      attachment
      remarks
      date
      time
      chequeDate
      chequeNumber
      creditCard
      referenceNO
      finnicBankId
      reference
      fee
      transactionId
      bankCode
      receiverThName
      receiverEnName
      finnicBankCode
      finnicBankName
      finnicBankFullName
      bankAccount
    }
    debtHistories {
      id
      totalPriceBefore
      totalPriceAfter
      remarks
      createdAt
    }
    invoices {
      id
      number
      debtHistories {
        id
        number
        type
        totalPriceBefore
        totalPriceAfter
        createDate
        itemChanges {
          id
          price
          remarks
          quantity
          orderItem {
            id
            quantity
            salesPrice
            variantName
            type
            productSet {
              id
              name
              sku
            }
          }
        }
      }
    }
    receipts {
      id
      number
      debtHistories {
        id
        number
        type
        totalPriceBefore
        totalPriceAfter
        createDate
        itemChanges {
          id
          price
          remarks
          quantity
          orderItem {
            id
            quantity
            salesPrice
            variantName
            type
            productSet {
              id
              name
              sku
            }
          }
        }
      }
    }
    salesOrderFiles {
      id
      fileName
      url
      createdAt
      updatedAt
    }
    lots {
      id
      taxInvoiceType
      requireTaxInvoice
      deliveryDate
      deliveryType
      discount
      discountAmount
      vatAmount
      lotIndex
      subtotal
      receipt {
        id
        taxInvoice {
          id
          status
        }
      }
      invoice {
        id
        taxInvoice {
          id
          status
        }
      }
      subtotalWithDelivery
      subtotalAfterDiscount
      deliveryDetails {
        id
        name
        trackingNumber
      }
      items {
        id
        status
        buyPrice
        avgCost
        salesPrice
        commission
        itemSource
        discount
        isUpsell
        quantity
        seller {
          id
          lastName
          firstName
          roles {
            id
          }
        }
        expectedReceivedDate
        requireTaxInvoice
        overdueDate
        receivedQuantity
        isVat
        vat
        isFree
        warehouse {
          id
        }
        isSerialTracking
        supplier {
          id
          name
        }
        type
        pack {
          id
          variantName
          quantity
          inventoryOption {
            id
            avgCost
            inventory {
              id
              supplier {
                id
                name
              }
            }
          }
        }
        childOrderItems {
          id
          variantName
          replaceTo
          replacedBy
          quantity
          pack {
            id
            name
            variantName
            quantity
            inventoryOption {
              id
              isVat
              vat
            }
          }
        }
        productSet {
          id
          name
          sku
          availableStart
          availableEnd
          useDate
          subtotal
          subtotalAfterDiscount
          discount
          remark
          files {
            id
            fileName
            url
          }
          ProductSetItems {
            id
            Pack {
              id
              variantName
              quantity
            }
          }
        }
        # purchaseOrder
        createdAt
        updatedAt
      }
      pickingTicket {
        id
        number
      }
      invoice {
        id
        number
      }
      receipt {
        id
        number
      }
      deliveryCost
      deliveryAddress {
        id
        supplierId
        customerId
        type
        addressNo
        province
        district
        subDistrict
        postcode
        contactName
        contactOfficeTel
        contactPhoneNumber
        contactFaxNumber
        contactEmail
        contactRemark
        thirdPartyAddress
      }
      billingAddress {
        id
        supplierId
        customerId
        type
        addressNo
        province
        district
        subDistrict
        postcode
        taxId
        contactName
        contactOfficeTel
        contactPhoneNumber
        contactFaxNumber
        contactEmail
        contactRemark
      }
    }
    customer {
      id
      name
      creditTerm
      creditLimit
      creditUsage
      subtotalAccount
      pricingType {
        id
        name
      }
      billingAddress {
        id
        supplierId
        customerId
        type
        addressNo
        province
        district
        subDistrict
        postcode
        taxId
        contactName
        contactOfficeTel
        contactPhoneNumber
        contactFaxNumber
        contactEmail
        contactRemark
      }
      contactAddress {
        id
        supplierId
        customerId
        type
        addressNo
        province
        district
        subDistrict
        postcode
        contactName
        contactOfficeTel
        contactPhoneNumber
        contactFaxNumber
        contactEmail
        contactRemark
      }
      shippingAddress {
        id
        supplierId
        customerId
        type
        addressNo
        province
        district
        subDistrict
        postcode
        contactName
        contactOfficeTel
        contactPhoneNumber
        contactFaxNumber
        contactEmail
        contactRemark
        thirdPartyAddress
      }
    }
    salesOrderType
    createdAt
    updatedAt
  }
`

export const VARIANT_FIELDS = gql`
  fragment VariantFields on Varient {
    id
    variantName
    commission
    type
    name
    quantity
    salesPrice
    avgCost
    suppliers {
      id
      name
    }
    itemPricingTypes {
      id
      salesPrice
      pricingType {
        id
        name
      }
    }
    productSetItems {
      id
      quantity
      Pack {
        id
        variantName
      }
    }
    inventoryOption {
      id
      totalStock
      soldStock
      toBeStocked
      avgCost
      stockLocation {
        id
        quantity
        warehouse {
          id
          warehouseName
        }
        totalStock
        toBeStocked
        preordered
        soldStock
      }
      inventory {
        id
        supplier {
          id
          name
        }
      }
    }
  }
`

export const INVENTORY_FIELDS = gql`
  fragment InventoryFields on InventoryWithQuantity {
    id
    sku
    type
    reference
    barcode
    productName
    stockable
    stockType
    productGroup {
      id
      name
    }
    productCategory {
      id
      name
    }
    brand {
      id
      name
    }
    sellable
    purchasable
    taxable
    serialTracking
    status
    isLossable
    supplier {
      id
      name
    }
    tag {
      id
      name
    }
    inventoryOption {
      id
      buyPrice
      initialCost
      isVat
      vat
      variantName
      initialStock
      minimumStock
      maximumStock
      pair {
        id
        variantName
      }
      pack {
        id
        name
        quantity
        middlePrice
        salesPrice
        itemPricingTypes {
          id
          salesPrice
          pricingType {
            id
            name
          }
        }
      }
      soldStock
      totalStock
      toBeStocked
      preordered
      stockLocation {
        id
        row
        shelf
        quantity
        totalStock
        toBeStocked
        preordered
        warehouse {
          id
          warehouseCode
          warehouseName
        }
      }
      files {
        id
        url
      }
      # historyLog {
      #   warehouse {
      #     warehouseCode
      #     warehouseName
      #     id
      #   }
      #   logs {
      #     id
      #     refId
      #     remark
      #     type
      #     oldQuantity
      #     newQuantity
      #     quantity
      #     createdAt
      #     updatedAt
      #   }
      # }
    }
  }
`
