import React, {
  useState, useEffect, useRef, useContext,
} from 'react'
import { Link } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import _ from 'lodash'
import numeral from 'numeral'
import moment from 'moment-timezone'
import {
  Div, P, Button, Input, Icon, H4, SmallText, TextLink, Label,
} from '@konsys-ui-custom'
import {
  ListWithoutTab, Remark, LargeTextWithLabel, Collapse, TableList, SummaryPrice, Select, DebtDetail,
  MessagePage, Loading,
} from '../../../components'
import { INVOICES, INVOICE_STATUS_COUNT_LIST, INVOICE_DETAIL } from '../../../api/query'
import { INV_STATUS } from '../../../constants/status/invoice'
import { PAYMENT_TYPE, LIST_LIMIT, TAX_INVOICE_TYPE } from '../../../constants/enum'
import { theme } from '../../../styles/_variables'
import { PATH } from '../../../constants/path'
import { getAccessToken } from '../../../utils/local-data-service'
import History from '../../../components/common/history'
import MESSAGE from '../../../constants/message'
import { renderText } from '../../../utils/util-services'
import { AuthorizationContext } from '../../../store/StoreProvider'
import { DELIVERY_TYPE } from '../../sale-orders/constants'
import history from '../../../history'
import URL from '../../../constants/environment'

const orderBy = [
  { text: 'เรียงจาก: ล่าสุด ไป อดีต', value: 'DESC' },
  { text: 'เรียงจาก: อดีต ไป ล่าสุด', value: 'ASC' },
]

const orderField = [
  { text: 'กรองจาก: วันที่สร้าง', value: 'createDate' },
  { text: 'กรองจาก: วันที่กำหนดชำระ', value: 'paymentDueDate' },
]

export const Detail = (props) => {
  const [openCollapse, setOpenCollapse] = useState(true)

  const [data, setData] = useState()
  const { loading: invoiceLoading, data: invoiceDataDetail } = useQuery(INVOICE_DETAIL, { variables: { id: props.data?.id }, skip: !_.isEmpty(props.invoiceDetail) })

  const invoiceDetail = invoiceDataDetail || props.invoiceDetail
  const isShowInTaxInvoice = !_.isEmpty(props.invoiceDetail)

  useEffect(() => {
    if (!_.isEmpty(invoiceDetail?.invoiceDetail?.data)) {
      setData(invoiceDetail?.invoiceDetail?.data)
    }
  }, [invoiceDetail?.invoiceDetail?.data])

  const tableStyled = {
    0: {
      width: '120px',
    },
    1: {
      width: '80px',
    },
    2: {
      width: '80px',
    },
    3: {
      width: '90px',
    },
    4: {
      width: '90px',
    },
    5: {
      width: isShowInTaxInvoice ? '90px' : '0px',
    },
  }

  const headerData = _.compact([
    {
      text: 'สินค้า',
    }, {
      text: 'จำนวน',
      textAlign: 'center',
    }, {
      text: 'ราคา/หน่วย',
      textAlign: 'center',
    }, {
      text: 'ส่วนลด/หน่วย',
      textAlign: 'center',
    }, {
      text: 'ราคารวม',
      textAlign: 'right',
    }, isShowInTaxInvoice ? {
      text: 'GR Ref.',
      textAlign: 'right',
    } : null,
  ])
  const tableListData = data && data.lot ? data.lot.items.map((v) => (
    _.compact([
      {
        text: (v.type === 'SET' ? v.productSet.name : v.pack.inventoryOption?.variantName) || '-',
        subText: <Div>
          {!props.isTaxInvoice && <SmallText display='block'><Label margin='0 4px 0 0'>Sku: </Label>{(v.type === 'SET' ? v.productSet.sku : v.pack.inventoryOption?.inventory?.sku) || '-'}</SmallText>}
          {!props.isTaxInvoice && <SmallText display='block'><Label margin='0 4px 0 0'>Brand: </Label>{v.type === 'SET' ? '-' : v.pack.inventoryOption?.inventory?.brand?.name || '-'}</SmallText>}
          {
            v.replaceTo
            && <SmallText color={theme.color.gray50}>แทนสินค้า {v.replaceTo}</SmallText>
          }
          {
            v.replacedBy
            && <SmallText color={theme.color.gray50}>ถูกแทนที่ด้วย {v.replacedBy}</SmallText>
          }
        </Div>,
      },
      { text: v.quantity || 0, textAlign: 'center' },
      { text: v.salesPrice ? numeral(v.salesPrice).format('0,0.00') : 0, textAlign: 'center' },
      { text: v.discount || 0, textAlign: 'center' },
      { text: numeral(((v.quantity * v.salesPrice) - (v.quantity * v.discount))).format('0,0.00'), textAlign: 'right' },
      isShowInTaxInvoice ? {
        text: <Div>
          {
            _.isEmpty(v?.goodsReceiveList)
              ? <SmallText textAlign='right' display='block'>สต๊อกยกมา</SmallText>
              : v?.goodsReceiveList?.map((obj) => (
                <SmallText key={obj?.id} textAlign='right' display='block'>{obj?.refId}</SmallText>
              ))
          }
        </Div>,
      } : null,
    ])
  )) : []

  if (invoiceLoading) {
    return (
      <Div textAlign='center' margin='32px 0 0'>
        <Loading size='small' />
      </Div>
    )
  }
  if (_.isEmpty(data)) return null

  const taxInvoiceType = data?.taxInvoiceType || data?.taxInvoice?.taxInvoiceType

  return (
    <Div margin='0 0 40px'>
      <Div display='flex' justifyContent='space-between' alignItems='flex-start'>
        <Div>
          <H4 bold color={theme.color.primaryColor}>{data ? data.number : '-'}</H4>
          {
            data.ivNumber
            && <Div margin='8px 0 0'>
              <SmallText display='block'>IV number</SmallText>
              <H4 bold color={theme.color.error}>{data.ivNumber || '-'}</H4>
            </Div>
          }
        </Div>
        {
          !_.isEmpty(props.status)
          && <P bold color={props.status.color}>{props.status.text}</P>
        }
      </Div>
      <Div margin='32px 0'>
        <Div display='flex' alignItems='flex-start' moreStyle={{ flexWrap: 'wrap' }}>
          <LargeTextWithLabel margin='0 24px 16px 0' title={{ text: 'วันที่สร้าง' }} content={{ text: moment.tz(data.createDate, 'Asia/Bangkok').format('DD/MM/YYYY') }} />
          <LargeTextWithLabel margin='0 24px 16px 0' title={{ text: 'ลูกค้า' }} content={{ text: props.customer ? props.customer.name : '-' }} />
          <LargeTextWithLabel margin='0 24px 16px 0' title={{ text: 'ประเภทการชำระเงิน' }} content={{ text: props.customerPaymentType.text }} />
          <LargeTextWithLabel margin='0 24px 16px 0' title={{ text: 'วันที่กำหนดชำระ' }} content={{ text: moment.tz(data.paymentDueDate, 'Asia/Bangkok').format('DD/MM/YYYY') }} />
        </Div>
        <Div display='flex' alignItems='flex-start'>
          <Div width='100%' margin='0 0 16px' display='flex'>
            {
              data && data.lot && (!_.isEmpty(data.lot.salesOrder) || !_.isEmpty(data.lot.pickingTicket))
            && <Div margin='0 24px 0 0' padding='16px' bgColor={theme.color.gray10}>
              <SmallText bold margin='0 0 8px'>อ้างอิง</SmallText>
              {
                !_.isEmpty(data.lot.salesOrder)
                && <Div margin='0 0 0 16px'>
                  <P display='block' margin='0 0 4px'>{data.lot.salesOrder.soNumber}</P>
                  <SmallText margin='0 0 0 16px'>ล็อตที่ {data.lot.lotIndex}</SmallText>
                </Div>
              }
              {
                !_.isEmpty(data.lot.pickingTicket)
                && <Div margin='8px 0 0 16px'>
                  <P display='block' margin='0 0 4px'>{data.lot.pickingTicket.number}</P>
                </Div>
              }
            </Div>
            }
            {
              (!_.isEmpty(data?.purchaseOrderList) || !_.isEmpty(data?.goodsReceiveList))
            && <Div moreStyle={{ flexGrow: 1 }}>
              {
                !_.isEmpty(data?.purchaseOrderList)
                && <Div width='100%'>
                  <Div display='flex'>
                    <SmallText bold style={{ width: '30%' }} margin='0 16px 0 0'>ใบ PO</SmallText>
                    <SmallText bold>PO Ref</SmallText>
                  </Div>
                  {
                    data?.purchaseOrderList?.map((obj, i) => (
                      <Div width='100%' key={obj?.id} display='flex' margin={i !== 0 ? '4px 0 0' : '0'}>
                        <P style={{ width: '30%', color: theme.color.secondaryColor, cursor: 'pointer' }} margin='0 16px 0 0' onClick={() => {
                          history.push({
                            pathname: PATH.poList,
                            state: { search: obj?.number },
                          })
                        }}>{obj?.number}</P>
                        <P>{obj?.extRef}</P>
                      </Div>
                    ))
                  }
                </Div>
              }
              {
                !_.isEmpty(data?.goodsReceiveList)
                && <Div width='100%' margin={!_.isEmpty(data?.purchaseOrderList) ? '16px 0 0' : '0'}>
                  <Div display='flex'>
                    <SmallText bold style={{ width: '30%' }} margin='0 16px 0 0'>ใบ GR</SmallText>
                    <SmallText bold>Invoice Reference</SmallText>
                  </Div>
                  {
                    data?.goodsReceiveList?.map((obj, i) => (
                      <Div width='100%' key={obj?.id} display='flex' margin={i !== 0 ? '4px 0 0' : '0'}>
                        <P style={{ width: '30%', color: theme.color.secondaryColor, cursor: 'pointer' }} margin='0 16px 0 0' onClick={() => {
                          history.push({
                            pathname: PATH.gr,
                            state: { search: obj?.number },
                          })
                        }}>{obj?.number}</P>
                        <P>{obj?.refId || '-'}</P>
                      </Div>
                    ))
                  }
                </Div>
              }
            </Div>
            }
          </Div>
          {
            !_.isEmpty(data) && !_.isEmpty(data.files)
            && <LargeTextWithLabel
              style={{ maxWidth: '100%', wordBreak: 'break-all' }}
              title={{ text: 'เอกสาร' }}
              margin='0 0 16px'
              content={data.files.map((v, i) => ({
                text: v.fileName,
                type: 'a',
                link: v.url,
                margin: i === data.files.length - 1 ? 0 : '0 0 4px',
                target: '_blank',
              }))}
            />
          }
        </Div>
        {
          data && data.lot && (_.some(data.lot.items, (o) => o.requireTaxInvoice))
          && <Div margin='0 24px 16px 0'>
            <Div display='flex'>
              <LargeTextWithLabel margin='0 16px 0 0' title={{ text: 'ประเภทใบกำกับภาษี' }} content={{ text: _.find(TAX_INVOICE_TYPE, (v) => v.value === taxInvoiceType)?.text || '-' }} />
              <LargeTextWithLabel title={{ text: 'Sale ที่สร้าง SO นี้' }} content={{ text: _.isEmpty(data?.lot?.salesOrder?.creator) ? '-' : `${data?.lot?.salesOrder?.creator?.firstName} ${data?.lot?.salesOrder?.creator?.lastName}` }} />
              {
                !_.isEmpty(data?.pickupSale)
                && <LargeTextWithLabel margin='0 0 0 16px' title={{ text: 'Sale ที่รับ tax invoice' }} content={{ text: `${data?.pickupSale?.firstName} ${data?.pickupSale?.lastName}` }} />
              }
            </Div>
            {
              (!_.isEmpty(props?.invoiceDetail?.invoiceDetail?.data?.trackingNumber) || !_.isEmpty(props?.invoiceDetail?.invoiceDetail?.data?.providerName))
              && <Div display='flex' margin='16px 0 0'>
                <LargeTextWithLabel margin='0 16px 0 0' title={{ text: 'ผู้ให้บริการ' }} content={{ text: props?.invoiceDetail?.invoiceDetail?.data?.providerName || '-' }} />
                <LargeTextWithLabel title={{ text: 'เลข Tracking Number' }} content={{ text: props?.invoiceDetail?.invoiceDetail?.data?.trackingNumber || '-' }} />
              </Div>
            }
            <Div margin='16px 0 0' padding='16px' bgColor={theme.color.lightBlue}>
              <Icon icon='far fa-file-check' margin='0 8px 0 0' /><SmallText bold margin='0 0 8px'>ต้องการใบกำกับภาษี</SmallText>
              <P display='block' margin='0 0 4px'>{`${renderText(data?.lot?.billingAddress?.contactName, true)} ${renderText(data?.lot?.billingAddress?.taxId, true)} ${renderText(data?.lot?.billingAddress?.addressNo, true)} ${renderText(data?.lot?.billingAddress?.subDistrict, true)} ${renderText(data?.lot?.billingAddress?.district, true)} ${renderText(data?.lot?.billingAddress?.province, true)} ${renderText(data?.lot?.billingAddress?.postcode, true)} ${renderText(data?.lot?.billingAddress?.contactPhoneNumber, true)}`}</P>
            </Div>
          </Div>
        }
        <Remark description={renderText(data.remark)} />
        { !_.isNil(data.pickingTicket) && _.size(data.pickingTicket) > 0 && <Remark style={{ marginTop: 16 }} showIcon={true} message='การจัดส่งที่นำส่ง' description={data.pickingTicket?.number} /> }
      </Div>
      <Collapse text='รายละเอียดสินค้า' fullHeight open={openCollapse} onClick={() => setOpenCollapse(!openCollapse)}>
        <Div>
          <TableList data={tableListData} headerData={headerData} columnStyled={tableStyled} />
        </Div>
      </Collapse>
      {
        data.lot && <Div margin='32px 0 0'>
          <SummaryPrice
            isWithShipping
            data={{
              total: data.lot.subtotal,
              shipping: {
                value: data.lot.deliveryCost || 0,
              },
              discount: {
                value: data.lot.discountAmount || 0,
                disabled: true,
                type: 'Baht',
              },
            }}
          />
        </Div>
      }
    </Div>
  )
}

const DebtList = (props) => {
  const [filterLoading, setFilterLoading] = useState(false)
  const [firstLoad, setFirstLoad] = useState(true)
  const [currentCard, setCurrentCard] = useState(0)
  const [searchText, setSearchText] = useState('')
  const [selectedStatus, setSelectedStatus] = useState(undefined)
  const [selectedOrderField, setSelectedOrderField] = useState(orderField[0].value)
  const [selectedOrderBy, setSelectedOrderBy] = useState(orderBy[0].value)
  const [isLoading, setIsLoading] = useState(false)
  const isInitialMount = useRef(true)
  const [isLoadingDeliveryNote, setIsLoadingDeliveryNote] = useState(false)
  const { authorization } = useContext(AuthorizationContext)
  const {
    data, loading, refetch, fetchMore,
  } = useQuery(INVOICES, {
    variables: {
      limit: LIST_LIMIT,
      offset: 0,
    },
  })
  const { data: statusData } = useQuery(INVOICE_STATUS_COUNT_LIST)

  const downloadDeliveryNote = (id) => {
    setIsLoadingDeliveryNote(true)
    window.open(
      `${URL.FILE_SERVER}/print/front-page/invoice/${id}?t=${getAccessToken()}`,
      '_blank',
      'toolbar=false,scrollbars=yes,resizable=false',
    )
    setIsLoadingDeliveryNote(false)
  }
  const statusOptions = statusData?.invoiceStatusList?.data.map((v) => (
    { text: `${INV_STATUS.find((o) => o.key === v.status)?.text || 'ทั้งหมด'} (${v.count})`, value: v.status }
  )) || []

  const download = (id) => {
    setIsLoading(true)
    window.open(
      `${URL.FILE_SERVER}/print/debit-credit/invoice/${id}?t=${getAccessToken()}`,
      '_blank',
      'toolbar=false,scrollbars=yes,resizable=false',
    )
    setIsLoading(false)
  }

  const invoiceList = (!loading || !_.isEmpty(data)) && data.invoiceList.data.list.map((v) => {
    const paymentType = _.find(PAYMENT_TYPE, (o) => o.value === v.customer?.paymentType)
    const scStatus = _.find(INV_STATUS, (o) => o.key === v.status)
    return (
      {
        title: !_.isEmpty(v.debtHistories) ? <Div>
          <SmallText margin='0 8px 0 0' bold>{v.number}</SmallText>
          <Div display='inline-block'>
            <Icon color={theme.color.success} fontSize={theme.fonts.size.label} icon='fas fa-check-circle' />
            <Label margin='0 0 0 4px'>มีใบเพิ่มหนี้/ลดหนี้</Label>
          </Div>
        </Div> : v.number,
        status: scStatus,
        content: {
          one: {
            title: {
              text: v.customer.name,
            },
            content: {
              text: !_.isEmpty(paymentType) ? paymentType.text : '-',
              width: '110px',
            },
          },
          two: {
            title: { text: 'ยอดชำระ' },
            content: { text: numeral(v.subtotalAfterDiscount).format('0,0.00') },
            margin: '0',
          },
        },
        detail: {
          title: _.filter([
            {
              id: 'view',
              text: 'รายละเอียด',
              icon: 'fal fa-info-square',
            },
            {
              id: 'CNDN',
              text: 'ประวัติเพิ่มลดหนี้',
              icon: 'fal fa-money-check-alt',
            },
            {
              id: 'viewHistoryLog',
              text: 'ประวัติการใช้งาน',
              icon: 'fal fa-history',
            },
          ], (o) => authorization.invoice[o.id]),
          content: [
            <Detail
              key={0}
              data={v}
              status={scStatus}
              customer={v.customer}
              customerPaymentType={paymentType}
            />,
            authorization.invoice.CNDN && <Div key={1} padding='16px 0 0'>
              {
                !_.isEmpty(v.debtHistories)
                  ? <Div>
                    {
                      authorization.invoice.printCNDN
                    && <Div textAlign='right'>
                      <Button margin='0 0 24px' disabled={isLoading} loading={isLoading} small text='พิมพ์ประวัติเพิ่มลดหนี้' onClick={() => download(v.id)} />
                    </Div>
                    }
                    {
                      v.debtHistories.map((debtData, i) => (
                        <Div key={i} display='flex' margin={i === v.debtHistories.length ? '0' : '0 0 32px 0'}>
                          <P margin='0 8px 0 0'>{i + 1}.</P>
                          <DebtDetail
                            block
                            data={{
                              ...debtData,
                              date: moment(debtData.createDate).format('DD/MM/YYYY'),
                              customer: v.customer?.name,
                              paymentType: _.find(PAYMENT_TYPE, (o) => o.value === v.paymentType).text,
                              remark: debtData.remarks,
                              oldCost: debtData.totalPriceBefore,
                              newCost: debtData.totalPriceAfter,
                              itemChanges: debtData.itemChanges,
                              files: debtData.files,
                              deliveryCost: v.deliveryCost,
                            }}
                          />
                        </Div>
                      ))
                    }
                  </Div>
                  : <MessagePage
                    margin='16px 0'
                    icon={{
                      icon: 'fal fa-times', fontSize: theme.fonts.size.title, color: theme.color.lightBlue, margin: '0 0 16px',
                    }}
                    text={MESSAGE.empty.list}
                    color={theme.color.lightBlue}
                    bold
                  />
              }
            </Div>,
            authorization.invoice.viewHistoryLog && <History
              key={2}
              data={v}
              moduleName='INVOICE'
            />,
          ],
          buttonList: [
            authorization.invoice.CNDN && v.debtHistories.length <= 0 && <Link key={2} to={`${PATH.account}${PATH.invoice.debitNote}/${v.id}`}>
              <Button small margin='4px 8px 4px 0' text='สร้างใบเพิ่มหนี้/ลดหนี้' icon='far fa-receipt' />
            </Link>,
            authorization.invoice.printInvoice && <TextLink target='_blank' key={3} href={`${URL.FILE_SERVER}/print/invoice/${v.id}?t=${getAccessToken()}`}>
              <Button small icon='fal fa-print' margin='4px 8px 4px 0' text='พิมพ์ใบแจ้งหนี้' />
            </TextLink>,
            authorization.invoice.printInvoice && <Button disabled={v.deliveryType !== DELIVERY_TYPE.DELIVERY} small icon='fal fa-print' margin='4px 8px 4px 0' key={0} text='พิมพ์ใบปะหน้าขนส่ง' loading={isLoadingDeliveryNote} onClick={() => downloadDeliveryNote(v.id)} />,
            authorization.invoice.edit && <Link to={`${PATH.account}${PATH.invoice.edit}/${v.id}`} key={1}>
              <Button small icon='fal fa-edit' margin='4px 8px 4px 0' text='แก้ไขใบแจ้งหนี้' />
            </Link>,
          ],
        },
      }
    )
  })

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false
    } else {
      setFirstLoad(false)
      setFilterLoading(true)
      refetch({
        search: searchText,
        orderField: selectedOrderField,
        orderBy: selectedOrderBy,
        status: selectedStatus,
      }).then(() => {
        setFilterLoading(false)
      })
    }
  }, [searchText, selectedStatus, selectedOrderField, selectedOrderBy])

  return (
    <ListWithoutTab
      listLoading={filterLoading || (firstLoad && _.isEmpty(data))}
      currentCard={currentCard}
      setCurrentCard={(value) => setCurrentCard(value)}
      list={invoiceList}
      filterHeight={100}
      filter={[
        <Div key={0} margin='0 0 24px'>
          <Div display='flex' alignItems='flex-start'>
            <Div moreStyle={{ flexGrow: 1 }} margin='0 16px 0 0'>
              <Input
                type='text'
                width='100%'
                prefix={<Icon icon='prefix fal fa-search' />}
                placeholder='Client Name, SO Number, ชื่อสินค้า, รหัสสินค้า'
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
                suffix={(!loading && !_.isEmpty(data?.invoiceList?.data) && !_.isEmpty(searchText)) && <Div position='absolute' right='16px' top='50%' moreStyle={{ transform: 'translateY(-50%)' }}><SmallText>{numeral(data?.invoiceList?.data?.total || 0).format('0,0')} รายการ</SmallText></Div>}
              />
            </Div>
            <Select
              style={{ width: '100%', flexBasis: '17%', margin: '0 16px 0 0' }}
              suffixIcon={<Icon icon='fal fa-chevron-down' />}
              placeholder='กรองสถานะ'
              options={statusOptions}
              value={selectedStatus}
              onChange={(value) => setSelectedStatus(value)}
            />
          </Div>
          <Div display='flex' margin='8px 0 0' justifyContent='flex-end'>
            <Div display='flex' moreStyle={{ flexGrow: 1 }} margin='0 56px 0 0'>
              <Div margin='0 16px 0 0' moreStyle={{ flexGrow: 1 }}>
                <Select
                  value={selectedOrderField}
                  style={{ minWidth: '200px', margin: '0 16px 0 0', width: '100%' }}
                  options={orderField}
                  onChange={(value) => setSelectedOrderField(value)}
                />
              </Div>
              <Div moreStyle={{ flexBasis: '40%' }}>
                <Select
                  value={selectedOrderBy}
                  style={{ width: '100%', minWidth: '200px' }}
                  options={orderBy}
                  onChange={(value) => setSelectedOrderBy(value)}
                />
              </Div>
            </Div>
            <Div display='flex' alignItems='center'>
              <Button text='ล้าง' icon='fal fa-sync' small ghost onClick={() => {
                setSearchText('')
                setSelectedStatus(undefined)
                setSelectedOrderField(orderField[0].value)
                setSelectedOrderBy(orderBy[0].value)
                setFilterLoading(true)
                refetch({
                  search: '',
                  status: undefined,
                  orderField: undefined,
                  orderBy: undefined,
                }).then(() => {
                  setFilterLoading(false)
                })
              }} />
            </Div>
          </Div>
        </Div>,
      ]}
      onInfiniteScroll={() => {
        if (props.setFetchMoreLoading) {
          props.setFetchMoreLoading(true)
        }
        fetchMore({
          variables: {
            offset: data.invoiceList.data.list.length,
          },
          updateQuery: (prev, { fetchMoreResult }) => {
            if (props.setFetchMoreLoading) {
              props.setFetchMoreLoading(false)
            }
            if (!fetchMoreResult) return prev
            return { ...prev,
              invoiceList: {
                ...prev.invoiceList,
                data: {
                  ...prev.invoiceList.data,
                  total: prev.invoiceList.data.total,
                  list: [...prev.invoiceList.data.list, ...fetchMoreResult.invoiceList.data.list],
                },
              } }
          },
        })
      }}
    />
  )
}

export default DebtList
