import React, {
  useState, useEffect, useRef, useContext,
} from 'react'
import { Link } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import _ from 'lodash'
import {
  Div, Button, Icon, Input,
} from '@konsys-ui-custom'
import numeral from 'numeral'
import { Helmet } from 'react-helmet'
import { PRODUCT_SET_LIST } from '../../api/query'
import { ListContainer, Select } from '../../components'
import QuickView from './quick-view'
import { AuthorizationContext } from '../../store/StoreProvider'
import { theme } from '../../styles/_variables'
import { delayInput } from '../../utils'

const KEY = 'ProductSetList'

const statusOption = [
  { value: 'ALL', text: 'ทั้งหมด' },
  { value: 'ACTIVE', text: 'Active' },
  { value: 'INACTIVE', text: 'Inactive' },
]

export default (props) => {
  const [searchText, setSearchText] = useState('')
  const [filterLoading, setFilterLoading] = useState(false)
  const [dateType, setDateType] = useState('createdDate')
  const [date, setDate] = useState([])
  const [fetchMoreLoading, setFetchMoreLoading] = useState(false)
  const [selectedStatus, setSelectedStatus] = useState()
  const isInitialMount = useRef(true)
  const { authorization } = useContext(AuthorizationContext)
  const {
    data, loading, fetchMore, refetch,
  } = useQuery(PRODUCT_SET_LIST, {
    variables: {
      limit: 10, offset: 0,
    },
  })

  const [orderField, setOrderField] = useState('createdAt')
  const [orderBy, setOrderby] = useState('DESC')

  const mapped = (!loading || !_.isEmpty(data)) && data[KEY].data.list.map((v) => ({
    id: v.sku,
    title: v.sku,
    status: {
      text: `สินค้า ${v.itemCount} รายการ`,
      // color: soStatusMaptoColor(v.status), // TODO
    },
    content: {
      one: {
        title: {
          text: 'ชื่อเซตสินค้า',
        },
        content: {
          text: v.name,
        },
      },
      three: {
        title: {
          text: 'ราคา',
        },
        content: {
          text: numeral(v.subtotal).format('0,0.00'),
        },
      },
    },
    detail: {
      title: [
        {
          text: 'รายละเอียด',
          icon: 'fad fa-info-square',
        },
      ],
      buttonList:
        [
          authorization.productSet.edit && <Link key='edit' to={`${props.match.url}/edit/${v.id}`}>
            <Button small ghost margin='4px 8px 4px 0' text='แก้ไขเซตสินค้า' icon='far fa-edit' />
          </Link>,
        ],
      content: [
        <QuickView key={v.id} data={v}/>,
      ],
    },
  }))

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false
    } else {
      const selectedDate = dateType === 'createdDate' ? {
        startCreatedDate: date[0], endCreatedDate: date[0], startDueDate: undefined, endDueDate: undefined,
      } : {
        startDueDate: date[0], endDueDate: date[1], startCreatedDate: undefined, endCreatedDate: undefined,
      }
      setFilterLoading(true)
      refetch({
        search: searchText,
        status: selectedStatus,
        ...selectedDate,
        orderBy,
        orderField,
      }).then(() => {
        setFilterLoading(false)
      })
    }
  }, [searchText, selectedStatus, date, dateType, orderField, orderBy])

  return (
    <Div>
      <Helmet>
        <meta name="viewport" content="width=1280" />
      </Helmet>
      <ListContainer
        onInfiniteScroll={() => {
          setFetchMoreLoading(true)
          fetchMore({
            variables: {
              offset: data[KEY].data.list.length,
              limit: 10,
            },
            updateQuery: (prev, { fetchMoreResult }) => {
              setFetchMoreLoading(false)
              if (!fetchMoreResult) return prev
              return { ...prev,
                [KEY]: {
                  ...fetchMoreResult[KEY],
                  data: {
                    ...fetchMoreResult[KEY].data,
                    list: [...prev[KEY].data.list, ...fetchMoreResult[KEY].data.list],
                  },
                } }
            },
          })
        }}
        title={[
          {
            text: 'รายชื่อเซตสินค้า',
            icon: 'fad fa-receipt',
          },
        ]}
        filterHeight={58}
        filter={[
          <Div margin='0 0 24px' key={0}>
            <Div display='flex' alignItems='center' moreStyle={{ flexGrow: 1 }}>
              <Input
                type='text'
                margin='0 16px 0 0'
                width='100%'
                prefix={<Icon icon='prefix fal fa-search' />}
                placeholder='ค้นหา SKU เซตสินค้า, ชื่อเซตสินค้า'
                // value={searchText}
                onChange={(e) => {
                  const searchValue = e.target.value
                  delayInput(() => {
                    setSearchText(searchValue)
                  })
                }}
              />
              <Select
                style={{ width: '50%', margin: '0 16px 0 0' }}
                placeholder='สถานะเซตสินค้า'
                options={statusOption}
                onChange={(value) => setSelectedStatus(value)}
                value={selectedStatus}
              />
              <Div display='flex' alignItems='center'>
                <Button text='ล้าง' icon='fal fa-sync' width='max-content' small ghost onClick={() => {
                  setDate([])
                  setDateType('createdDate')
                  setSearchText('')
                  setSelectedStatus()
                  setOrderField('createdAt')
                  setOrderby('DESC')
                  const selectedDate = dateType === 'createdDate' ? {
                    startCreatedDate: undefined, endCreatedDate: undefined, startDueDate: undefined, endDueDate: undefined,
                  } : {
                    startDueDate: undefined, endDueDate: undefined, startCreatedDate: undefined, endCreatedDate: undefined,
                  }
                  setFilterLoading(true)
                  refetch({
                    search: '',
                    status: undefined,
                    orderBy: 'DESC',
                    orderField: 'createdAt',
                    ...selectedDate,
                  }).then(() => {
                    setFilterLoading(false)
                  })
                }} />
              </Div>
            </Div>
            {/* <Div display='flex' margin='8px 0 0'>
              <Div display='flex' moreStyle={{ flexGrow: 1 }} margin='0 56px 0 0'>
                <Select
                  defaultValue='createdAt'
                  onChange={value => setOrderField(value)}
                  value={orderField}
                  style={{ width: '41.65%', minWidth: '200px', margin: '0 16px 0 0' }}
                  options={[{ text: 'เรียงตามวันที่สร้าง', value: 'createdAt' }, { text: 'เรียงตามวันที่อัพเดท', value: 'updatedAt' }]}
                />
                <Select
                  suffixIcon={<Icon icon={orderBy === 'ASC' ? 'fal fa-sort-amount-down-alt' : 'fal fa-sort-amount-up-alt'} />}
                  defaultValue='DESC'
                  value={orderBy}
                  onChange={value => setOrderby(value)}
                  options={[{ text: 'น้อยไปมาก', value: 'ASC' }, { text: 'มากไปน้อย', value: 'DESC' }]}
                />
              </Div>
            </Div> */}
          </Div>,
        ]}
        tabLoading={fetchMoreLoading}
        listLoading={filterLoading || (loading && _.isEmpty(data))}
        list={mapped}
        buttonList={[
          authorization.productSet.create && <Link key='create' to={`${props.match.url}/create`}>
            <Button small ghost inverse color={theme.color.success} margin='4px 8px 4px 0' text='สร้างเซตสินค้า' icon='far fa-plus' />
          </Link>,
        ]}
      >
      </ListContainer>
    </Div>
  )
}
