import { useMutation, useQuery } from '@apollo/client'
import { Checkbox } from '@konsys-ui-custom'
import {
  DatePicker,
  Skeleton,
  message,
} from 'antd'
import {
  Button,
  Div,
  Input,
  Label,
  P,
  SmallText,
} from 'konsys-ui'
import _, { first, isEmpty, uniqBy } from 'lodash'
import moment from 'moment-timezone'
import numeral from 'numeral'
import React, { useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import {
  CREATE_CN_TO_DR, CREATE_RETURN_SALES_ORDER,
} from '../../api/mutation'
import {
  GET_DEPOSIT_RECEIPT,
  GET_RECEIPT,
  INVOICE_DETAIL,
  NEXT_CN_NUMBER, NEXT_DN_NUMBER,
  SALE_ORDER,
} from '../../api/query'
import { theme } from '../../styles/_variables'
import { showMessageMutation, toNumber } from '../../utils/index'
import { validateData } from '../../utils/util-services'
import {
  ItemCard,
  Tab,
  TextBetween,
  TextareaWithLabel,
  Uploader,
} from '../index'

export const DebtForm = (props) => {
  const history = useHistory()
  const typeData = props.moduleType === 'invoice' ? {
    key: 'invoiceDetail',
    link: '/account/invoice',
    enum: 'INVOICE',
    query: INVOICE_DETAIL,
  } : props.moduleType === 'receipt' ? {
    key: 'receiptInfo',
    link: '/account/receipt-cash',
    enum: 'RECEIPT',
    query: GET_RECEIPT,
  } : props.moduleType === 'saleOrderLot' ? {
    key: 'salesOrderDetail',
    link: '/sale-orders',
    enum: 'SALEORDER',
    query: SALE_ORDER,
  } : {
    key: 'salesOrderDetail',
    link: '/sale-orders',
    enum: '',
    query: SALE_ORDER,
  }

  const searchItemInputRef = useRef()
  const [mutationVariables, setMutationVariables] = useState({
    remarks: '',
    returnDate: moment(),
    deliveryAdd: 0,
    files: null,
  })
  const [changeProductList, setChangeProductList] = useState([{ id: 1 }])
  const [isDataChange, setIsDataChange] = useState({ price: false, quantity: false })
  const [itemCounts, setItemCounts] = useState([])
  const { loading, data } = useQuery(typeData.query, { variables: { id: toNumber(props.id) } })
  const [doCreate, { loading: creating }] = useMutation(CREATE_RETURN_SALES_ORDER, { refetchQueries: ['remainingJob'] })

  const getMutationVariables = () => {
    let baseMutationVariables = {
      thisModule: typeData.enum,
      id: typeData?.key === 'salesOrderDetail' ? props.selectedLotId : _.isEmpty(data) ? null : data[typeData.key]?.data?.id,
      remarks: mutationVariables.remarks,
      createDate: moment(mutationVariables.date).format('YYYY-MM-DD'),
      files: mutationVariables.files,
    }
    if (props.type === 'increase') {
      baseMutationVariables = { ...baseMutationVariables, deliveryAdd: parseFloat(mutationVariables.deliveryAdd), itemList: _.reduce(changeProductList, (arr, n) => [...arr, { orderItemId: n.orderItemId, itemFinalPrice: n.oldPrice + n.price }], []) }
    } else {
      baseMutationVariables = { ...baseMutationVariables, deliveryDiscount: parseFloat(mutationVariables.deliveryAdd), itemList: _.reduce(changeProductList, (arr, n) => [...arr, { orderItemId: n.orderItemId, decreaseQuantity: (n.quantity === 0 || (!_.isNumber(n.quantity) && _.isEmpty(n.quantity))) ? undefined : n.quantity, decreasePrice: (n.price === 0 || (!_.isNumber(n.price) && _.isEmpty(n.price))) ? undefined : n.price }], []) }
    }
    return baseMutationVariables
  }

  const validateDisabledButton = () => {
    const validate = validateData(true, _.flatten([
      mutationVariables.date,
      mutationVariables.remarks,
      props.type === 'increase'
        ? _.flatten(_.map(changeProductList, (o) => [o.orderItemId, o?.price === null || o?.price === 0 ? null : o.price]))
        : _.flatten(_.map(changeProductList, (o) => [o.orderItemId, o?.price === null || o?.price === 0 ? o.quantity : o?.price])),
    ]), mutationVariables)
    return validate
  }

  useEffect(() => {
    if (props.getInputValue) props.getInputValue(getMutationVariables(), validateDisabledButton())
  }, [changeProductList, mutationVariables])

  useEffect(() => {
    if (!_.isEmpty(props?.defaultValue)) {
      setMutationVariables({
        ...mutationVariables,
        remarks: props?.defaultValue?.remarks,
        returnDate: moment(props?.defaultValue?.createDate),
        files: [...props?.defaultValue?.files],
        deliveryAdd: props?.defaultValue?.deliveryCost,
      })
      setChangeProductList(_.filter(props?.defaultValue?.itemChanges, (o) => _.isEmpty(o?.remarks))?.map((o) => {
        const newObj = _.isNil(o?.price) ? { quantity: true } : { price: true }
        setIsDataChange({ ...isDataChange, ...newObj })
        return (
          {
            id: o?.id,
            orderItemId: o?.orderItem?.id,
            oldPrice: o?.orderItem?.salesPrice,
            oldQuantity: o?.orderItem?.quantity,
            discount: 0,
            price: o?.price,
            quantity: o?.quantity,
            quantityBefore: o?.quantity,
            isNotRemovable: true,
          }
        )
      }))
    }
  }, [props?.defaultValue])

  const mapReceipt = (lots) => {
    const firstMapped = _.flattenDeep(lots?.map((v) => v.items.map((o) => (
      o.type === 'SET'
        ? o.childOrderItems.map((child) => ({
          text: child.variantName || '-',
          value: child.id,
          salesPrice: child.salesPrice,
          discount: child.discount,
          quantity: child.quantity,
          item: child,
        }))
        : {
          text: o.variantName || '-',
          value: o.id,
          salesPrice: o.salesPrice,
          discount: o.discount,
          quantity: o.quantity,
          item: o,
        }
    ))))

    return firstMapped
  }

  const itemOption = !loading && data
    ? props.moduleType === 'invoice'
      ? data[typeData.key].data?.lot?.items.map((v) => (
        {
          text: (v.type === 'SET' ? v.productSet?.name : v.pack?.inventoryOption?.variantName) || '-',
          value: v.id,
          salesPrice: v.salesPrice,
          discount: v.discount,
          quantity: v.quantity,
          oldDiscountAmount: data[typeData.key].data?.lot?.discountAmount,
          item: v,
        }
      ))
      : props.moduleType === 'receipt'
        ? mapReceipt(data[typeData.key].data.lots)
        : _.flatten(_.filter(data[typeData.key].data?.lots, (lot) => lot?.id === props?.selectedLotId)?.map((v) => v?.items?.map((o) => (
          {
            text: o?.pack?.variantName || '-',
            value: o?.id,
            salesPrice: o?.salesPrice,
            discount: o?.discount,
            quantity: o?.quantity,
            item: o,
          }
        ))))
    : []

  useEffect(() => {
    if (!isEmpty(itemOption)) {
      setItemCounts(itemOption?.map((itemDetail) => (
        { id: itemDetail?.value, barcode: (itemDetail?.item?.pack || itemDetail?.item?.productSet)?.inventoryOption?.inventory?.barcode, count: 0, quantity: itemDetail.quantity || 0 }
      )))
      searchItemInputRef?.current?.focus()
    }
  }, [data])

  const oldPrice = props.moduleType === 'invoice' ? data ? data[typeData.key].data?.subtotalAfterDiscount : 0 : data ? data[typeData.key].data?.lots[0].subtotalAfterDiscount : 0
  const totalNewPrice = () => {
    const listWithUnchangeItem = [...itemOption]
    _.map(changeProductList, (v) => _.remove(listWithUnchangeItem, (o) => o.value === v.orderItemId))
    const allNewChangePrice = _.reduce(changeProductList, (sum, n) => {
      if (n.price !== 0 && (_.isNumber(n.price))) {
        return sum + (n.price * n.oldQuantity)
      }
      if (n.quantity !== 0 && (_.isNumber(n.quantity))) {
        return sum + ((n.oldQuantity - n.quantity) * n.oldPrice)
      }
      if (n?.quantity === 0) {
        return sum + (n.oldQuantity * n.oldPrice)
      }
      return sum
    }, 0)
    if (props.type === 'increase') {
      return oldPrice + allNewChangePrice + parseFloat(mutationVariables.deliveryAdd || 0)
    }
    return oldPrice - allNewChangePrice - parseFloat(mutationVariables.deliveryAdd || 0)
  }

  const newPrice = !_.isEmpty(changeProductList) && _.isUndefined(changeProductList[0]?.orderItemId)
    ? _.isEmpty(mutationVariables.deliveryAdd)
      ? oldPrice
      : props.type === 'increase'
        ? oldPrice + parseFloat(mutationVariables.deliveryAdd)
        : oldPrice - parseFloat(mutationVariables.deliveryAdd)
    : totalNewPrice()

  const filterItemOption = _.compact(itemOption?.map((o) => {
    if (!_.isEmpty(_.find(changeProductList, (product) => product?.orderItemId === o?.value))) {
      return null
    }
    return o
  }))

  const getAllDefectiveCost = () => {
    const filterItemCount = _.filter(itemCounts, (obj) => obj?.count > 0)
    const cost = _.reduce(filterItemCount, (total, itemCount) => {
      const findObj = _.find(filterItemOption, (item) => item?.value === itemCount?.id)
      return total + (itemCount?.count * findObj?.item?.avgCost)
    }, 0)
    return cost
  }

  return (
    <Div>
      <Div textAlign='right'>
        <Div>
          <SmallText bold margin='0 16px 0 0'>วันที่<Label margin='0 0 0 4px' color={theme.color.error}>*</Label></SmallText>
          <DatePicker format='DD/MM/YYYY' value={mutationVariables?.returnDate} allowClear={false} onChange={(value) => setMutationVariables({ ...mutationVariables, returnDate: value })} />
        </Div>
      </Div>
      <Div margin='16px 0 32px 0'>
        <Div>
          <Div textAlign='right' margin='0 0 8px'>
            <input
              placeholder='หากมีสินค้าชำรุด ให้สแกนที่นี่'
              ref={searchItemInputRef}
              style={{
                cursor: 'pointer',
                width: 190,
                border: 'none',
                outline: 'unset',
                textAlign: 'right',
              }}
              onKeyDown={(e) => {
                if (e.keyCode === 13) {
                  let isFound = false
                  setItemCounts(
                    itemCounts.map((v) => {
                      if (v.barcode === e.target.value && v.count < v.quantity && !isFound) {
                        isFound = true
                        return {
                          ...v,
                          count: v.count + 1,
                        }
                      }
                      return v
                    }),
                  )
                  searchItemInputRef.current.value = ''
                }
              }}
            />
          </Div>
          {
            isEmpty(filterItemOption)
              ? <Skeleton />
              : filterItemOption?.map((itemData) => (
                <ItemCard
                  key={itemData?.value}
                  count={_.find(itemCounts, (o) => o.id === itemData.value)?.count}
                  item={{
                    ...itemData?.item,
                    quantity: itemData?.quantity,
                  }}
                  isReceipt={true}
                  removeItem={(id) => setItemCounts(
                    itemCounts.map((v) => {
                      if (v.id === id) {
                        return {
                          ...v,
                          count: v.count - 1,
                        }
                      }
                      return v
                    }),
                  )}
                />
              ))
          }
        </Div>
      </Div>
      <Div display='flex' alignItems='flex-start'>
        <Div width='45%' margin='0 40px 0 0'>
          <TextareaWithLabel
            onChange={(e) => setMutationVariables({ ...mutationVariables, remarks: e.target.value })}
            width='100%'
            title={{
              text: <SmallText bold>หมายเหตุ<Label bold margin='0 0 0 4px' color={theme.color.error}>*</Label></SmallText>,
              margin: '0 0 8px',
            }}
            textarea={{
              rows: 2,
              placeholder: 'กรอกหมายเหตุเพิ่มเติม',
              style: { width: '100%' },
              value: mutationVariables?.remarks,
            }}
          />
          <Div margin='16px 0 0' width='100%' display='flex' justifyContent='space-between' alignItems='flex-start'>
            <SmallText bold>แนบเอกสาร {!mutationVariables.isDeliveryAndBoxCount ? <Label color={theme.color.error}>* หากไม่หักค่าส่งและกล่อง กรุณาบันทึกสลิปค่าปรับยกเลิกออเดอร์ลูกค้า 100.-</Label> : null}</SmallText>
            <Uploader
              onChange={(list) => setMutationVariables({ ...mutationVariables, files: list })}
              data={mutationVariables?.files?.map((file) => {
                if (!_.isEmpty(file?.url)) {
                  return (
                    { name: file?.fileName || file?.name, uid: file?.id || file?.uid, url: file?.url }
                  )
                }
                return file
              }) || []}
              accept='image/*'
            />
          </Div>
        </Div>
        <Div width='55%' textAlign='right' margin='6px 0 0'>
          {
            !props?.hideDeliveryCostAndDetail
              ? <>
                <Div margin='0 0 8px' display='flex' justifyContent='space-between' alignItems='center'>
                  <P>ค่าขนส่ง</P>
                  <Div display='flex' alignItems='center'>
                    <Input width='100px' rule={{ type: 'float' }} state='default' value={mutationVariables?.deliveryAdd} onChange={(e) => setMutationVariables({ ...mutationVariables, deliveryAdd: e.target.value })} />
                    <P margin='0 0 0 4px'>บาท</P>
                  </Div>
                </Div>
                <TextBetween margin='0 0 8px 0' title={{ text: 'รวมมูลค่าราคาสินค้าเดิม' }} content={{ text: `${numeral(oldPrice).format('0,0.00')} บาท` }} />
                <TextBetween margin='0 0 8px 0' title={{ text: 'มูลค่าที่ถูกต้อง' }} content={{ text: `${numeral(newPrice).format('0,0.00')} บาท` }} />
                <TextBetween title={{ text: 'ผลต่างรวม', bold: true }} content={{ text: `${numeral(Math.abs(newPrice - oldPrice)).format('0,0.00')} บาท`, bold: true }} />
              </>
              : <TextBetween margin='0 0 8px 0' title={{ text: 'รวมต้นทุนสินค้าที่ชำรุด' }} content={{ text: `${numeral(getAllDefectiveCost()).format('0,0.00')} บาท` }} />
          }
          <Div display='flex' alignItems='center'>
            <Checkbox
              checked={mutationVariables.isDeliveryAndBoxCount}
              data={{ value: 'isDeliveryAndBoxCount' }}
              onChange={(e) => setMutationVariables({ ...mutationVariables, isDeliveryAndBoxCount: e.target.checked })}
            />
            <SmallText bold>หักค่าส่งและค่ากล่อง (แอดมิน: {first(first(data?.receiptInfo?.data?.lots)?.items)?.seller?.firstName} {first(first(data?.receiptInfo?.data?.lots)?.items)?.seller?.lastName})</SmallText>
          </Div>
        </Div>
      </Div>
      {
        !props?.hideButton
        && <Button
          disabled={
            creating
            || (
              !mutationVariables.isDeliveryAndBoxCount && isEmpty(mutationVariables.files)
            )
            || isEmpty(mutationVariables.remarks)
          }
          loading={creating}
          inverse
          color={theme.color.success}
          text={props.type === 'increase' ? 'ยืนยันสร้างใบเพิ่มหนี้' : 'ยืนยันสร้างใบตีกลับ'}
          icon='far fa-check'
          margin='48px auto 0'
          style={{ display: 'block' }}
          onClick={() => {
            doCreate({
              variables: {
                receiptId: toNumber(props.id),
                input: {
                  isDeliveryAndBoxCount: mutationVariables.isDeliveryAndBoxCount,
                  remarks: mutationVariables.remarks,
                  file: first(mutationVariables.files),
                  returnDate: mutationVariables.returnDate,
                  brokenItems: itemCounts.map((v) => ({
                    orderItemId: v.id,
                    quantity: v.count,
                  })),
                },
              },
            }).then((res) => {
              showMessageMutation(res.data?.createReturnSalesOrder, () => history.push('/receipts'))
            })
          }}
        />
      }
    </Div>
  )
}

export const DebtTab = (props) => {
  const [currentTab, setCurrentTab] = useState(props.isCreditNote ? 'decrease' : 'increase')
  const { loading, data } = useQuery(currentTab === 'increase' ? NEXT_DN_NUMBER : NEXT_CN_NUMBER)
  const isReceipt = props?.type === 'receipt'
  const getMenu = isReceipt ? [
    {
      text: 'ใบตีกลับ',
      icon: 'far fa-file-minus',
      link: props.creditNoteLink,
    },
  ] : [
    {
      text: 'ใบเพิ่มหนี้',
      icon: 'far fa-file-plus',
      link: props.debitNoteLink,
    },
    {
      text: 'ใบตีกลับ',
      icon: 'far fa-file-minus',
      link: props.creditNoteLink,
    },
  ]
  return (
    <Tab
      loading={loading}
      title={getMenu}
      white
      onChangeTab={() => setCurrentTab(currentTab === 'decrease' ? 'increase' : 'decrease')}
      contentHeight='calc(100vh - 234px)'
      current={currentTab === 'increase' ? 0 : 1}
    >
      {/* <DebtForm key='increase' type='increase' moduleType={props.type} id={props.id} nextNumber={data?.nextDNNumber} /> */}
      <DebtForm key='decrease' type='decrease' moduleType={props.type} id={props.id} nextNumber={data?.nextCNNumber} hideRowColumn={isReceipt} hideDeliveryCostAndDetail={isReceipt} />
    </Tab>
  )
}

export const DRTab = (props) => {
  const history = useHistory()
  const [currentTab, setCurrentTab] = useState('decrease')
  const [mutationVariables, setMutationVariables] = useState({})
  const [decreasePrice, setDecreasePrice] = useState(0)
  const [disabledButton, setDisabledButton] = useState(false)
  const { loading: drLoading, data: drDetail } = useQuery(GET_DEPOSIT_RECEIPT, { variables: { id: toNumber(props.id) } })
  const [doCreate] = useMutation(CREATE_CN_TO_DR, { refetchQueries: ['remainingJob'] })

  return (
    <Tab
      loading={drLoading}
      title={[
        {
          id: 'creditNote',
          text: 'ใบลดหนี้',
          icon: 'far fa-file-minus',
          link: props.creditNoteLink,
        },
      ]}
      white
      onChangeTab={() => setCurrentTab(currentTab === 'decrease' ? 'increase' : 'decrease')}
      contentHeight='calc(100vh - 234px)'
      current={currentTab === 'increase' || props.type === 'depositReceipt' ? 0 : 1}
    >
      <Div display='flex' justifyContent='center' flexDirection='column' width='100%'>
        <Div display='flex' alignItems='flex-start'>
          <Div width='45%' margin='0 40px 0 0'>
            <TextareaWithLabel
              onChange={(e) => setMutationVariables({ ...mutationVariables, remarks: e.target.value })}
              width='100%'
              title={{
                text: <SmallText bold>หมายเหตุ<Label bold margin='0 0 0 4px' color={theme.color.error}>*</Label></SmallText>,
                margin: '0 0 8px',
              }}
              textarea={{
                rows: 2,
                placeholder: 'กรอกหมายเหตุเพิ่มเติม',
                style: { width: '100%' },
              }}
            />
            <Div margin='16px 0 0' width='100%' display='flex' justifyContent='space-between' alignItems='flex-start'>
              <SmallText bold>แนบเอกสาร</SmallText>
              <Uploader
                onChange={(e) => setMutationVariables({ ...mutationVariables, files: e })}
                data={mutationVariables.files?.map((file) => {
                  if (file.url) {
                    return ({
                      name: file.fileName,
                      url: file.url,
                      uid: file.fileName,
                    })
                  }
                  return file
                }) || []}
                accept='image/*'
                isMultiple={true}
              />
            </Div>
          </Div>
          <Div width='55%' textAlign='right' margin='6px 0 0'>
            <TextBetween margin='0 0 8px 0' title={{ text: 'รวมมูลค่าราคาสินค้าเดิมทั้งหมด' }} content={{ text: `${numeral(drDetail?.depositReceiptDetail?.data?.salesOrder?.subtotalAfterDiscount).format('0,0.00')} บาท` }} />
            <TextBetween margin='0 0 8px 0' title={{ text: 'มูลค่าที่ชำระแล้ว' }} content={{ text: `${numeral(drDetail?.depositReceiptDetail?.data?.salesOrder?.paidTotal).format('0,0.00')} บาท` }} />
            <Div margin='0 0 8px' display='flex' justifyContent='space-between' alignItems='center'>
              <P>จำนวนที่ต้องการลดหนี้</P>
              <Div display='flex' alignItems='center'>
                <Input width='100px' rule={{ type: 'float' }} state='default' onChange={(e) => setDecreasePrice(e.target.value)} />
                <P margin='0 0 0 4px'>บาท</P>
              </Div>
            </Div>
            <TextBetween title={{ text: 'คงเหลือ', bold: true }} content={{ text: `${numeral(drDetail?.depositReceiptDetail?.data?.salesOrder?.subtotalAfterDiscount - decreasePrice).format('0,0.00')} บาท`, bold: true }} />
          </Div>
        </Div>
        <Button
          disabled={
            drDetail?.depositReceiptDetail?.data?.salesOrder?.subtotalAfterDiscount - decreasePrice < 0
            || decreasePrice < 1
          }
          loading={disabledButton}
          inverse
          color={theme.color.success}
          text={'ยืนยันสร้างใบลดหนี้'}
          icon='far fa-check'
          margin='48px auto 0'
          style={{ display: 'block' }}
          onClick={() => {
            setDisabledButton(true)
            doCreate({
              variables: {
                drId: toNumber(props.id),
                input: {
                  decreasePrice: parseFloat(decreasePrice),
                  remarks: mutationVariables.remarks,
                  createDate: Date,
                  files: mutationVariables.files,
                },
              },
            }).then((res) => {
              setDisabledButton(false)
              const key = 'createCreditNoteToDepositReceipt'
              if (res.data[key].success) {
                message.success(res.data[key].message)
                history.push('/account')
              } else message.error(res.data[key].message)
            })
          }}
        />
      </Div>
    </Tab>
  )
}

export default DebtTab
