import { useMutation, useQuery } from '@apollo/client'
import {
  Button,
  Div, Icon,
  Input,
  P, SmallText,
} from '@konsys-ui-custom'
import { Col, Row } from 'antd'
import { some } from 'lodash'
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { nanoid } from 'nanoid'
import { UPDATE_PRICING_TYPE } from '../../api/mutation'
import { PRICING_TYPES_LISTS } from '../../api/query'
import { Tab } from '../../components'
import { theme } from '../../styles/_variables'
import { showMessageMutation } from '../../utils'

export default () => {
  const { loading: pricingLoading, data: pricingData } = useQuery(PRICING_TYPES_LISTS)
  const [doUpdate, { loading: updating }] = useMutation(UPDATE_PRICING_TYPE)
  const [pricingTiers, setPricingTiers] = useState(pricingLoading ? [] : pricingData.pricingTypeList.data.list.map((v) => ({ id: v.id, tierName: v.name, price: v.price })))

  useEffect(() => {
    setPricingTiers(pricingLoading ? [] : pricingData.pricingTypeList.data.list.map((v) => ({ id: v.id, tierName: v.name, price: v.price })))
  }, [pricingData])

  return (
    <Div height='100%'>
      <Helmet key='meta'>
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>
      <Tab
        current={0}
        fullHeight
        loading={pricingLoading}
        contentHeight='calc(100% - 38px)'
        title={
          [
            { id: 'pricing', text: 'แก้ไขระดับลูกค้า', link: '#' },
          ]
        }
        noDefaultTab
        key='content'
      >
        <Div display='flex' flexDirection='column' padding={window.innerWidth <= 820 ? '0' : '0 60px'}>
          <Div display='flex' flexDirection='column' padding='8px' margin='8px 0' style={{ border: `1px solid ${theme.color.lightBlue}`, borderRadius: 5, background: theme.color.primaryColor10 }}>
            <P bold margin='0 0 16px 0'>ข้อมูลระดับลูกค้า</P>
            <Row type='flex' align='middle' gutter={24}>
              <Col span={16}>
                <SmallText bold style={{ width: '100%' }}>ชื่อระดับ</SmallText>
              </Col>
              <Col span={8}>
                <SmallText bold style={{ width: '100%' }}>ยอดเงิน</SmallText>
              </Col>
            </Row>
            {
              pricingTiers.map((tier, idx) => (
                <Row type='flex' key={tier.id || tier.newId} align='middle' style={{ marginBottom: 8 }} gutter={24}>
                  <Col span={16}>
                    <Input
                      width='100%'
                      value={tier.tierName}
                      prefix={<Icon icon='prefix far fa-money-check-edit-alt' />}
                      onChange={(e) => setPricingTiers(pricingTiers.map((v) => {
                        if (v.id === tier.id) {
                          return ({ ...v, tierName: e.target.value })
                        } return v
                      }))}
                      title={{ text: 'ชื่อระดับ' }}
                    />
                  </Col>
                  <Col span={8}>
                    <Input
                      width='100%'
                      rule={{ type: 'int' }}
                      style={idx === 0 || ((parseFloat(tier.price) || 0) > (parseFloat(pricingTiers[idx - 1]?.price) || 0)) ? {} : { borderColor: theme.color.error }}
                      value={tier.price}
                      prefix={<Icon icon='prefix far fa-coin' />}
                      onChange={(e) => setPricingTiers(pricingTiers.map((v) => {
                        if (v.id === tier.id) {
                          return ({ ...v, price: e.target.value })
                        } return v
                      }))}
                      title={{ text: 'ยอดเงิน' }}
                    />
                  </Col>
                </Row>
              ))
            }
            <Button onlyIcon icon='fal fa-plus' margin='16px auto' inverse onClick={() => {
              setPricingTiers([...pricingTiers, { id: nanoid(), isNew: true }])
            }} />
            <Div display='flex' style={{ alignSelf: 'flex-end' }}>
              <Button bold color={theme.color.success} inverse text='บันทึก' disabled={
                some(pricingTiers, (o, i) => i > 0 && ((parseFloat(o.price) || 0) < (parseFloat(pricingTiers[i - 1]?.price) || 0)))
              } loading={updating} onClick={() => {
                doUpdate({ variables: {
                  pricingTypes: pricingTiers.map((v) => ({ name: v.tierName, pricingTypeId: v.isNew ? undefined : v.id, price: parseFloat(v.price) })),
                } }).then((res) => showMessageMutation(res.data?.updatePricingType))
              }}/>
            </Div>
          </Div>
        </Div>
      </Tab>
    </Div>
  )
}
