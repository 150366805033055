import React, { useState, useEffect } from 'react'
import _ from 'lodash'
import {
  Div,
  InputWithLabel,
  Icon,
  Button,
  H4,
  SmallText,
  Label,
} from '@konsys-ui-custom'
import { message } from 'antd'
import { useMutation, useQuery } from '@apollo/client'
import { Helmet } from 'react-helmet'
import { CREATE_WAREHOUSE, UPDATE_WAREHOUSE } from '../../api/mutation'
import { WAREHOUSE } from '../../api/query'
import {
  TextareaWithLabel,
  FullPageContainer,
  Divider,
  NewAddress,
} from '../../components'
import history from '../../history'
import { theme } from '../../styles/_variables'
import { validateData } from '../../utils/util-services'

export default (props) => {
  const isCreateMode = _.includes(props.match.url, 'create')
  const [doAction, { loading: mutationLoading }] = useMutation(
    isCreateMode ? CREATE_WAREHOUSE : UPDATE_WAREHOUSE,
  )
  const { loading, data } = useQuery(WAREHOUSE, {
    skip: isCreateMode,
    variables: { id: parseInt(props.match.params.id) },
  })
  const [mutationVariables, setMutationVariables] = useState({})

  useEffect(() => {
    if (!isCreateMode && data && !loading) {
      setMutationVariables({
        ...mutationVariables,
        ...data.findWarehouse.data.address,
        zipcode: data.findWarehouse.data.address.postcode,
        subdistrict: data.findWarehouse.data.address.subDistrict,
      })
    }
  }, [data])

  return (
    <Div>
      <Helmet>
        <meta name="viewport" content="width=1280" />
      </Helmet>
      <FullPageContainer
        loading={loading}
        title={[
          {
            text: 'Warehouse',
            icon: 'fad fa-map-marked-alt',
          },
        ]}
        detail={{
          title: [
            {
              text: isCreateMode ? 'สร้างคลังสินค้าใหม่' : 'แก้ไขคลังสินค้า',
              icon: isCreateMode ? 'far fa-plus' : 'far fa-edit',
            },
          ],
        }}
      >
        {!loading ? (
          !isCreateMode && _.isNull(data.findWarehouse.data) ? (
            <H4>ไม่พบ</H4>
          ) : (
            <Div display="flex" flexDirection="column" padding="0 60px">
              <InputWithLabel
                input={{
                  width: '100%',
                  defaultValue: isCreateMode
                    ? undefined
                    : data.findWarehouse.data.warehouseCode,
                  state:
                  _.isEmpty(
                    isCreateMode
                      ? mutationVariables.warehouseCode
                      : !_.isUndefined(mutationVariables.warehouseCode)
                        ? mutationVariables.warehouseCode
                        : data.findWarehouse.data.warehouseCode,
                  ) && 'error',
                }}
                onChange={(e) => setMutationVariables({
                  ...mutationVariables,
                  warehouseCode: e.target.value,
                })
                }
                title={{
                  text: (
                    <SmallText bold>
                    รหัสคลังสินค้า <Label color={theme.color.error}>*</Label>
                    </SmallText>
                  ),
                }}
              />
              <InputWithLabel
                input={{
                  width: '100%',
                  prefix: <Icon icon="prefix far fa-font" />,
                  defaultValue: isCreateMode
                    ? undefined
                    : data.findWarehouse.data.warehouseName,
                  state:
                  _.isEmpty(
                    isCreateMode
                      ? mutationVariables.warehouseName
                      : !_.isUndefined(mutationVariables.warehouseName)
                        ? mutationVariables.warehouseName
                        : data.findWarehouse.data.warehouseName,
                  ) && 'error',
                }}
                onChange={(e) => setMutationVariables({
                  ...mutationVariables,
                  warehouseName: e.target.value,
                })
                }
                title={{
                  text: (
                    <SmallText bold>
                    ชื่อคลังสินค้า <Label color={theme.color.error}>*</Label>
                    </SmallText>
                  ),
                }}
              />
              <Divider
              // color='#C2E2BF'
                margin="40px 0"
              />

              <NewAddress
                type="address"
                data={{
                  ...mutationVariables,
                  zipcode: mutationVariables.postcode,
                  subdistrict: mutationVariables.subDistrict,
                }}
                onChange={(key, value) => {
                  setMutationVariables({ ...mutationVariables, [key]: value })
                }}
                onSelect={(obj) => {
                  setMutationVariables({
                    ...mutationVariables,
                    ...obj,
                    postcode: obj.zipcode,
                    subDistrict: obj.subdistrict,
                  })
                }}
              />
              <TextareaWithLabel
                margin="8px 0"
                onChange={(e) => setMutationVariables({
                  ...mutationVariables,
                  contactRemark: e.target.value,
                })
                }
                title={{
                  text: 'หมายเหตุ',
                }}
                textarea={{
                  style: { width: '100%' },
                  rows: 8,
                  margin: '8px 0 0',
                  defaultValue: isCreateMode
                    ? undefined
                    : data.findWarehouse.data.address.contactRemark,
                }}
              />
              <Button
                loading={mutationLoading}
                disabled={
                  mutationLoading
                || validateData(
                  isCreateMode,
                  [
                    mutationVariables.warehouseName,
                    mutationVariables.warehouseCode,
                  ],
                  mutationVariables,
                )
                }
                text={isCreateMode ? 'สร้างคลังสินค้า' : 'แก้ไขคลังสินค้า'}
                inverse
                color={theme.color.success}
                icon={isCreateMode ? 'far fa-plus' : 'far fa-edit'}
                style={{ alignItems: 'center' }}
                width="fit-content"
                margin="20px auto"
                onClick={() => {
                  doAction({
                    variables: isCreateMode
                      ? { ...mutationVariables }
                      : {
                        ...mutationVariables,
                        id: parseInt(props.match.params.id),
                      },
                  }).then((resp) => {
                    const KEY = isCreateMode
                      ? 'createWarehouse'
                      : 'updateWarehouse'
                    if (resp.data[KEY].success) {
                      message.success(resp.data[KEY].message)
                      history.push('/warehouses')
                    } else message.error(resp.data[KEY].message)
                  })
                }}
              />
            </Div>
          )
        ) : null}
      </FullPageContainer>
    </Div>
  )
}
