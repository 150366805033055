import React, { createContext, useReducer } from 'react'

export const RemainingJobsContext = createContext({})
export const AccountantRemainingJobsContext = createContext({})
export const AuthorizationContext = createContext({})
export const RoleContext = createContext({})
export const TaxRemainingJobsContext = createContext({})

const initialState = {
  role: undefined,
  accountantRemainingJobs: {
    remainingDepositReceipt: undefined,
    remainingInvoice: undefined,
    remainingBillingNote: undefined,
    remainingCashReceipt: undefined,
    remainingCreditReceipt: undefined,
    totalRemainingIncome: undefined,
    remainingPaymentNote: undefined,
    totalRemainingOutcome: undefined,
  },
  taxRemainingJobs: {
    waitingTaxInvoice: undefined,
    waitingDeliver: undefined,
    waitingIVConfirm: undefined,
    taxInvoiced: undefined,
    all: undefined,
  },
  authorization: {
    test: true,
    salesOrder: {
      view: true,
      viewHistoryLog: true,
      create: true,
      createWithDiscount: true,
      edit: true,
      confirmCredit: true,
      recordCashPayment: true,
      showCost: true,
    },
    purchaseOrder: {
      view: true,
      create: true,
      edit: true,
      supplierConfirm: true,
      viewHistoryLog: true,
    },
    goodsReceive: {
      view: true,
      create: false,
      edit: true,
      viewHistoryLog: true,
      createDraft: true,
      confirmDraft: true,
      confirmGR: true,
    },
    pickingTicket: {
      view: true,
      create: true,
      viewHistoryLog: true,
      confirmReceive: true,
      addTrackingNumber: true,
    },
    inventory: {
      view: true,
      viewStockLog: true,
      viewCostChangeLog: false,
      viewHistoryLog: true,
      createBasic: true,
      createAdvance: true,
      adjustStock: true,
      edit: true,
      transfer: true,
      pairProduct: true,
    },
    client: {
      view: true,
      viewHistoryLog: true,
      create: true,
      createCreditCustomer: true,
      createPricingTier: true,
      editBasic: true,
      editAdvance: true,
    },
    supplier: {
      view: true,
      create: true,
      viewHistoryLog: true,
      editBasic: true,
      editAdvance: false,
    },
    productStructure: {
      view: true,
      create: true,
      edit: true,
    },
    productSet: {
      view: true,
      viewHistoryLog: true,
      create: true,
      edit: true,
      showCost: true,
    },
    depositReceipt: {
      view: true,
      printDR: true,
      confirmCashPayment: true,
    },
    invoice: {
      view: true,
      CNDN: true,
      viewHistoryLog: true,
      printInvoice: true,
      edit: true,
      create: true,
      printCNDN: true,
    },
    bill: {
      view: true,
      viewHistoryLog: true,
      printBill: true,
      create: true,
      edit: true,
    },
    cashReceipt: {
      view: true,
      viewHistoryLog: true,
      printCashReceipt: true,
      CNDN: true,
      printCNDN: true,
      create: true,
    },
    creditReceipt: {
      view: true,
      viewHistoryLog: true,
      printCreditReceipt: true,
      create: true,
    },
    paymentNote: {
      view: true,
      viewHistoryLog: true,
      create: true,
      edit: true,
    },
    warehouse: {
      view: true,
      create: true,
      edit: true,
    },
    user: {
      view: true,
      viewHistoryLog: true,
      create: true,
      edit: true,
    },
    permission: {
      view: true,
      edit: true,
    },
    userPagePermissions: {
      view: true,
      edit: true,
    },
    setting: {
      all: true,
    },
    report: {
      all: true,
    },
    taxInvoice: {
      all: true,
      editIVNumber: false,
    },
    commission: {
      all: true,
    },
    tag: {
      all: true,
    },
    facebook: {
      all: true,
    },
    unMatchedSalesOrder: {
      all: true,
    },
  },
  remainingJobs: {
    po: undefined,
    gr: undefined,
    pt: undefined,
    accountant: undefined,
  },
}

// -------------- Reducer -------------- //
const remainingJobsReducer = (state, action) => {
  console.info('action ->', action)
  switch (action.type) {
    case 'UPDATE_JOBS':
      return {
        ...state,
        remainingJobs: action.payload,
      }
    default: return state
  }
}
const accountantRemainingJobsReducer = (state, action) => {
  console.info('action ->', action)
  switch (action.type) {
    case 'UPDATE_JOBS':
      return {
        ...state,
        accountantRemainingJobs: action.payload,
      }
    default: return state
  }
}
const taxRemainingJobsReducer = (state, action) => {
  console.info('action ->', action)
  switch (action.type) {
    case 'UPDATE_JOBS':
      return {
        ...state,
        taxRemainingJobs: action.payload,
      }
    default: return state
  }
}
const authorizationReducer = (state, action) => {
  console.info('action ->', action)
  switch (action.type) {
    case 'UPDATE_AUTHORIZATION':
      return {
        ...state,
        authorization: action.payload,
      }
    default: return state
  }
}
const roleReducer = (state, action) => {
  console.info('action ->', action)
  switch (action.type) {
    case 'UPDATE_ROLE':
      return {
        ...state,
        role: action.payload,
      }
    default: return state
  }
}

// -------------- Provider -------------- //
export const RemainingJobsProvider = ({ children }) => {
  const [remainingJobsState, remainingJobsDispatch] = useReducer(
    remainingJobsReducer,
    initialState,
  )

  const { remainingJobs } = remainingJobsState

  const updateRemainingJobs = (payload) => remainingJobsDispatch({ type: 'UPDATE_JOBS', payload })

  return (
    <RemainingJobsContext.Provider value={{ remainingJobs, updateRemainingJobs }}>
      {children}
    </RemainingJobsContext.Provider>
  )
}

export const AccountantRemainingJobsProvider = ({ children }) => {
  const [accountantRemainingJobsState, accountantRemainingJobsDispatch] = useReducer(
    accountantRemainingJobsReducer,
    initialState,
  )

  const { accountantRemainingJobs } = accountantRemainingJobsState

  const updateJobs = (payload) => accountantRemainingJobsDispatch({ type: 'UPDATE_JOBS', payload })

  return (
    <AccountantRemainingJobsContext.Provider value={{ accountantRemainingJobs, updateJobs }}>
      {children}
    </AccountantRemainingJobsContext.Provider>
  )
}

export const AutorizationProvider = ({ children }) => {
  const [authorizationState, authorizationStateDispatch] = useReducer(
    authorizationReducer,
    initialState,
  )

  const { authorization } = authorizationState

  const updateAuthorization = (payload) => authorizationStateDispatch({ type: 'UPDATE_AUTHORIZATION', payload })

  return (
    <AuthorizationContext.Provider value={{ authorization, updateAuthorization }}>
      {children}
    </AuthorizationContext.Provider>
  )
}

export const RoleProvider = ({ children }) => {
  const [roleState, roleStateDispatch] = useReducer(
    roleReducer,
    initialState,
  )

  const { role } = roleState

  const updateRole = (payload) => roleStateDispatch({ type: 'UPDATE_ROLE', payload })

  return (
    <RoleContext.Provider value={{ role, updateRole }}>
      {children}
    </RoleContext.Provider>
  )
}

export const TaxRemainingJobsProvider = ({ children }) => {
  const [taxRemainingJobsState, taxRemainingJobsDispatch] = useReducer(
    taxRemainingJobsReducer,
    initialState,
  )

  const { taxRemainingJobs } = taxRemainingJobsState

  const updateTaxJobs = (payload) => taxRemainingJobsDispatch({ type: 'UPDATE_JOBS', payload })

  return (
    <TaxRemainingJobsContext.Provider value={{ taxRemainingJobs, updateTaxJobs }}>
      {children}
    </TaxRemainingJobsContext.Provider>
  )
}
