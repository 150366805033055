import { Col, DatePicker, Row, message, notification } from 'antd'
import { useMutation, useQuery } from '@apollo/client'
import {
  Button, Checkbox, Div, H4, Icon, Input, Label, P, SmallText,
} from '@konsys-ui-custom'
import _, { filter, first, isEmpty, isNil, some } from 'lodash'
import moment from 'moment-timezone'
import numeral from 'numeral'
import React, { useContext, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useHistory } from 'react-router-dom'
import { CREATE_CUSTOMER, UPDATE_CUSTOMER } from '../../api/mutation'
import { CUSTOMER } from '../../api/query'
import {
  Collapse, ComponentWithLabelLarge, FullPageContainer, InputWithLabel, NewAddress, TextareaWithLabel,
} from '../../components'
import { AuthorizationContext } from '../../store/StoreProvider'
import { theme } from '../../styles/_variables'
import {
  permissionRedirect,
  validateData,
} from '../../utils/util-services'
import { PAYMENT_TYPE, PERSON_TYPE } from './constants'

const Page = ({
  isCreateMode,
  infoLoading,
  infoData,
  id,
  onCreateSuccess,
  authorization,
  quickAdd,
}) => {
  const history = useHistory()
  const [doAction, { loading: mutationLoading }] = useMutation(
    isCreateMode ? CREATE_CUSTOMER : UPDATE_CUSTOMER,
    { refetchQueries: ['remainingJob', 'remainingAccountant'] },
  )
  const [mutationVariables, setMutationVariables] = useState({
    isVat: false,
    type: PERSON_TYPE.INDIVIDUAL,
    paymentType: PAYMENT_TYPE.CASH,
    prefix: 'คุณ',
    shippingAddress: {},
    billingAddress: {},
    pricingTypeId: 1,
  })
  const [emails, setEmails] = useState([{ id: 1, email: '', isPrimary: true }])
  const [phones, setPhones] = useState([
    {
      id: 1,
      name: 'เบอร์มือถือ',
      phoneNumber: '',
      isPrimary: true,
    },
    {
      id: 2,
      name: 'เบอร์บ้าน',
      phoneNumber: '',
      isPrimary: false,
    },
  ])
  const [deliveryAddresses, setDeliveryAddresses] = useState([
    {
      id: 1,
      isPrimary: true,
    },
  ])
  const [billingAddresses, setBillingAddresses] = useState([
    {
      id: 1,
      isPrimary: true,
    },
  ])
  const [contactAddresses, setContactAddresses] = useState([
    {
      id: 1,
      isPrimary: true,
    },
  ])
  const [openCollapse, setOpenCollapse] = useState({
    deliveryAddress: true,
    billingAddress: false,
    contactAddress: false,
  })

  useEffect(() => {
    if (!infoLoading && !isCreateMode) {
      setMutationVariables({
        ...infoData.customerDetail.data,
      })
      if (!_.isEmpty(infoData.customerDetail.data.shippingAddress)) {
        setDeliveryAddresses(
          _.map(infoData.customerDetail.data.shippingAddress, (v) => ({
            ..._.omit(v, ['supplierId', 'customerId', '__typename']),
            isDefault: true,
          })),
        )
      }
      if (!_.isEmpty(infoData.customerDetail.data.contactAddress)) {
        setContactAddresses(
          _.map(infoData.customerDetail.data.contactAddress, (v) => ({
            ..._.omit(v, ['supplierId', 'customerId', '__typename']),
            isDefault: true,
          })),
        )
      }
      if (!_.isEmpty(infoData.customerDetail.data.billingAddress)) {
        setBillingAddresses(
          _.map(infoData.customerDetail.data.billingAddress, (v) => ({
            ..._.omit(v, ['supplierId', 'customerId', '__typename']),
            isDefault: true,
          })),
        )
      }
      if (!_.isEmpty(infoData.customerDetail.data.contactPhoneNumber)) {
        setPhones(
          _.map(infoData.customerDetail.data.contactPhoneNumber, (v) => ({
            ..._.omit(v, ['__typename']),
          })),
        )
      }
      if (!_.isEmpty(infoData.customerDetail.data.contactEmail)) {
        setEmails(
          _.map(infoData.customerDetail.data.contactEmail, (v) => ({
            ..._.omit(v, ['__typename']),
          })),
        )
      }
    }
  }, [infoData])

  const addressMutationData = (addressDataList, type) => _.compact(
    _.map(addressDataList, (v) => {
      const addressData = !_.isUndefined(v.type)
        ? v.isDefault
          ? { id: v.id }
          : _.omit(v, ['isPrimary', 'isDefault'])
        : _.omit(v, ['isPrimary', 'id', 'isDefault'])
      if (_.isEmpty(addressData)) return null
      return {
        isPrimary: v.isPrimary,
        address: type === 'CONTACT'
          ? _.pick(addressData, ['id', 'contactFacebook', 'contactLineId'])
          : _.omit(addressData, ['zipcode', 'subdistrict']),
      }
    }),
  )

  const requiredFields = _.concat([
    mutationVariables.name,
  ])

  const getrequiredFields = () => _.concat(requiredFields)

  const isCanEditAdvanceContent = isCreateMode
    ? true
    : authorization.editAdvance

  return (
    <Div>
      {!infoLoading ? (
        !isCreateMode && _.isNull(infoData.customerDetail.data) ? (
          <H4>ไม่พบ</H4>
        ) : (
          <Div
            display='flex'
            flexDirection='column'
            padding={window.innerWidth <= 820 ? '0' : '0 60px'}
          >
            <Row type='flex' gutter={16}>
              <Col xs={24} sm={12}>
                <InputWithLabel
                  width='100%'
                  input={{
                    defaultValue: !isCreateMode
                      ? infoData.customerDetail.data.name
                      : undefined,
                    width: '100%',
                    prefix: <Icon icon='prefix far fa-signature' />,
                    state:
                      _.isEmpty(
                        !isCreateMode
                          ? infoData.customerDetail.data.name
                          : mutationVariables.name,
                      ) && 'error',
                    disabled: !isCanEditAdvanceContent,
                  }}
                  onChange={(e) => setMutationVariables({
                    ...mutationVariables,
                    name: e.target.value,
                  })
                  }
                  title={{
                    text: (
                      <SmallText bold>
                        ชื่อ <Label color={theme.color.error}>*</Label>
                      </SmallText>
                    ),
                  }}
                />
              </Col>
              <Col xs={24} sm={12}>
                <ComponentWithLabelLarge width='100%' margin='8px 0'>
                  <SmallText bold display='block'>วันเกิด</SmallText>
                  <DatePicker
                    format='DD/MM/YYYY'
                    style={{ width: '100%' }}
                    value={mutationVariables?.birthDate ? moment.tz(mutationVariables?.birthDate, 'Asia/Bangkok') : undefined}
                    allowClear={false}
                    onChange={(value) => {
                      setMutationVariables({ ...mutationVariables, birthDate: value })
                    }}
                    disabledDate={(current) => current && (current > moment())}
                  />
                </ComponentWithLabelLarge>
              </Col>
            </Row>
            {(mutationVariables.paymentType === PAYMENT_TYPE.CREDIT
              || (!isCreateMode
                && infoData.customerDetail.data.paymentType
                  === PAYMENT_TYPE.CREDIT
                && !mutationVariables.paymentType)) && (
              <Div
                display='flex'
                flexDirection='column'
                padding='8px'
                margin='8px 0'
                style={{
                  border: `1px solid ${theme.color.lightBlue}`,
                  borderRadius: 5,
                }}
              >
                <InputWithLabel
                  input={{
                    defaultValue: !isCreateMode
                      ? infoData.customerDetail.data.creditLimit
                      : undefined,
                    width: '100%',
                    prefix: <Icon icon='prefix far fa-credit-card-blank' />,
                    rule: { type: 'float' },
                    state:
                      (_.isEmpty(
                        !isCreateMode
                          ? infoData.customerDetail.data.creditLimit
                          : mutationVariables.creditLimit,
                      )
                        && !_.isNumber(
                          !isCreateMode
                            ? infoData.customerDetail.data.creditLimit
                            : mutationVariables.creditLimit,
                        ))
                      || _.isNaN(
                        !isCreateMode
                          ? infoData.customerDetail.data.creditLimit
                          : mutationVariables.creditLimit,
                      )
                        ? 'error'
                        : 'default',
                  }}
                  onChange={(e) => setMutationVariables({
                    ...mutationVariables,
                    creditLimit: parseFloat(e.target.value),
                  })
                  }
                  title={{
                    text: (
                      <SmallText bold>
                        วงเงิน <Label color={theme.color.error}>*</Label>
                      </SmallText>
                    ),
                  }}
                />
                {isCreateMode ? (
                  <InputWithLabel
                    input={{
                      width: '100%',
                      prefix: <Icon icon='prefix far fa-credit-card-blank' />,
                      rule: { type: 'float' },
                      state: 'default',
                    }}
                    onChange={(e) => setMutationVariables({
                      ...mutationVariables,
                      creditUsage: parseFloat(e.target.value),
                    })
                    }
                    title={{ text: 'วงเงินที่ใช้ไปแล้ว' }}
                  />
                ) : (
                  <Div display='flex' flexDirection='column' margin='8px 0 0'>
                    <SmallText bold margin='8px 0 0'>
                      วงเงินที่ใช้ไปแล้ว
                    </SmallText>
                    <H4 bold>
                      {numeral(infoData.customerDetail.data.creditUsage).format(
                        '0,0.00',
                      )}{' '}
                      บาท
                    </H4>
                  </Div>
                )}
                <InputWithLabel
                  input={{
                    defaultValue: !isCreateMode
                      ? infoData.customerDetail.data.creditTerm
                      : undefined,
                    width: '100%',
                    prefix: <Icon icon='prefix far fa-calendar-alt' />,
                    rule: { type: 'int' },
                    state:
                      (_.isEmpty(
                        !isCreateMode
                          ? infoData.customerDetail.data.creditTerm
                          : mutationVariables.creditTerm,
                      )
                        && !_.isNumber(
                          !isCreateMode
                            ? infoData.customerDetail.data.creditTerm
                            : mutationVariables.creditTerm,
                        ))
                      || _.isNaN(
                        !isCreateMode
                          ? infoData.customerDetail.data.creditTerm
                          : mutationVariables.creditTerm,
                      )
                        ? 'error'
                        : 'default',
                  }}
                  onChange={(e) => setMutationVariables({
                    ...mutationVariables,
                    creditTerm: parseInt(e.target.value),
                  })
                  }
                  title={{
                    text: (
                      <SmallText bold>
                        ระยะเครดิต <Label color={theme.color.error}>*</Label>
                      </SmallText>
                    ),
                  }}
                />
              </Div>
            )}
            {
              !quickAdd
              && <Div
                display='flex'
                flexDirection='column'
                padding='8px'
                margin='8px 0'
                style={{
                  border: `1px solid ${theme.color.lightBlue}`,
                  borderRadius: 5,
                  background: theme.color.primaryColor10,
                }}
              >
                <Div
                  width='100%'
                  margin='8px 0'
                  display='flex'
                  alignItems='center'
                >
                  {phones.map((phone, index) => (
                    <Div
                      key={phone.id}
                      width='50%'
                    >
                      <SmallText bold>{phone.name} {index === 0 ? <SmallText color={theme.color.error}>*</SmallText> : ''}</SmallText>
                      <Div
                        key={phone.id}
                        width='100%'
                        margin='8px 0'
                        display='flex'
                        alignItems='center'
                      >
                        <Input
                          width='100%'
                          margin='0 16px 0 0'
                          placeholder='หมายเลข'
                          rule={{ type: 'int' }}
                          state='default'
                          defaultValue={!isCreateMode ? phone.phoneNumber : undefined}
                          onChange={(e) => setPhones(
                            phones.map((v) => {
                              if (v.id === phone.id) { return { ...v, phoneNumber: e.target.value } }
                              return v
                            }),
                          )
                          }
                        />
                      </Div>
                    </Div>
                  ))}
                </Div>
              </Div>
            }
            {
              !quickAdd
              && <Div
                display='flex'
                flexDirection='column'
                padding='8px'
                margin='8px 0'
                style={{
                  border: `1px solid ${theme.color.lightBlue}`,
                  borderRadius: 5,
                  background: theme.color.primaryColor10,
                }}
              >
                <SmallText bold>Email</SmallText>
                {emails.map((email) => (
                  <Div
                    key={email.id}
                    width='100%'
                    margin='8px 0'
                    display='flex'
                    alignItems='center'
                  >
                    <Input
                      width='100%'
                      margin='0 16px 0 0'
                      defaultValue={!isCreateMode ? email.email : undefined}
                      onChange={(e) => setEmails(
                        emails.map((v) => {
                          if (v.id === email.id) { return { ...v, email: e.target.value } }
                          return v
                        }),
                      )
                      }
                    />
                  </Div>
                ))}
              </Div>
            }
            <Collapse
              text={{
                render: (
                  <P bold margin='0 0 4px'>
                    ที่อยู่จัดส่ง <SmallText color={theme.color.error}>*</SmallText>
                  </P>
                ),
              }}
              margin='16px 0'
              fullHeight
              open={openCollapse.deliveryAddress}
              onClick={() => setOpenCollapse({
                ...openCollapse,
                deliveryAddress: !openCollapse.deliveryAddress,
              })
              }
            >
              <Div
                display='flex'
                flexDirection='column'
                padding='8px'
                margin='8px 0'
              >
                {deliveryAddresses.map((address) => (
                  <Div
                    key={address.id}
                    width='100%'
                    margin='8px 0'
                    display='flex'
                    alignItems='flex-start'
                  >
                    <Checkbox
                      margin='8px 8px 0 0'
                      checked={address.isPrimary}
                      data={{ value: `address${address.id}` }}
                      onChange={(e) => setDeliveryAddresses(
                        deliveryAddresses.map((v) => {
                          if (!address.isPrimary) {
                            if (v.id === address.id) {
                              return {
                                ...v,
                                isPrimary: e.target.checked,
                                isDefault: false,
                              }
                            }
                            return {
                              ...v,
                              isPrimary: false,
                              isDefault: false,
                            }
                          }
                          return v
                        }),
                      )
                      }
                    />
                    <NewAddress
                      type='customer'
                      noTitle
                      data={{
                        ..._.find(
                          deliveryAddresses,
                          (o) => o.id === address.id,
                        ),
                        zipcode: _.find(
                          deliveryAddresses,
                          (o) => o.id === address.id,
                        ).postcode,
                        subdistrict: _.find(
                          deliveryAddresses,
                          (o) => o.id === address.id,
                        ).subDistrict,
                      }}
                      onChange={(key, value) => {
                        setDeliveryAddresses(
                          deliveryAddresses.map((val) => {
                            if (val.id === address.id) {
                              return { ...val, [key]: value, isDefault: false }
                            }
                            return val
                          }),
                        )
                      }}
                      onSelect={(obj) => {
                        setDeliveryAddresses(
                          deliveryAddresses.map((val) => {
                            if (val.id === address.id) {
                              return {
                                ...val,
                                ...obj,
                                postcode: obj.zipcode,
                                subDistrict: obj.subdistrict,
                                isDefault: false,
                              }
                            }
                            return val
                          }),
                        )
                      }}
                    />
                    <Icon
                      margin='8px 0 0 8px'
                      icon='fal fa-trash-alt'
                      color={theme.color.gray80}
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        if (address.isPrimary) {
                          notification.error({
                            message: 'การลบล้มเหลว',
                            description: 'กรุณาเปลี่ยนค่าค่าเริ่มต้นก่อน',
                          })
                        } else {
                          setDeliveryAddresses(
                            _.remove(
                              _.cloneDeep(deliveryAddresses),
                              (o) => o.id !== address.id,
                            ),
                          )
                        }
                      }}
                    />
                  </Div>
                ))}
                <Button
                  onlyIcon
                  icon='fal fa-plus'
                  margin='16px auto'
                  inverse
                  onClick={() => {
                    setDeliveryAddresses([
                      ...deliveryAddresses,
                      {
                        id:
                          _.size(deliveryAddresses) > 0
                            ? _.maxBy(deliveryAddresses, 'id').id + 1
                            : 1,
                        isPrimary: false,
                      },
                    ])
                  }}
                />
              </Div>
            </Collapse>
            {
              !quickAdd
              && <Collapse
                text='ที่อยู่ส่งใบกำกับภาษี'
                margin='16px 0'
                fullHeight
                open={openCollapse.billingAddress}
                onClick={() => setOpenCollapse({
                  ...openCollapse,
                  billingAddress: !openCollapse.billingAddress,
                })
                }
              >
                <Div
                  display='flex'
                  flexDirection='column'
                  padding='8px'
                  margin='8px 0'
                >
                  {billingAddresses.map((address) => (
                    <Div
                      key={address.id}
                      width='100%'
                      margin='8px 0'
                      display='flex'
                      alignItems='flex-start'
                    >
                      <Checkbox
                        margin='8px 8px 0 0'
                        checked={address.isPrimary}
                        data={{ value: `address${address.id}` }}
                        onChange={(e) => setBillingAddresses(
                          billingAddresses.map((v) => {
                            if (!address.isPrimary) {
                              if (v.id === address.id) {
                                return {
                                  ...v,
                                  isPrimary: e.target.checked,
                                  isDefault: false,
                                }
                              }
                              return {
                                ...v,
                                isPrimary: false,
                                isDefault: false,
                              }
                            }
                            return v
                          }),
                        )
                        }
                      />
                      <NewAddress
                        type='billing'
                        noTitle
                        onChangePersonType={(personType) => {
                          setBillingAddresses(
                            billingAddresses.map((val) => {
                              if (val.id === address.id) {
                                return { ...val, billingType: personType }
                              }
                              return val
                            }),
                          )
                        }}
                        data={{
                          ..._.find(billingAddresses, (o) => o.id === address.id),
                          zipcode: _.find(
                            billingAddresses,
                            (o) => o.id === address.id,
                          ).postcode,
                          subdistrict: _.find(
                            billingAddresses,
                            (o) => o.id === address.id,
                          ).subDistrict,
                        }}
                        onChange={(key, value) => {
                          setBillingAddresses(
                            billingAddresses.map((val) => {
                              if (val.id === address.id) {
                                return { ...val, [key]: value, isDefault: false }
                              }
                              return val
                            }),
                          )
                        }}
                        onSelect={(obj) => {
                          setBillingAddresses(
                            billingAddresses.map((val) => {
                              if (val.id === address.id) {
                                return {
                                  ...val,
                                  ...obj,
                                  postcode: obj.zipcode,
                                  subDistrict: obj.subdistrict,
                                  isDefault: false,
                                }
                              }
                              return val
                            }),
                          )
                        }}
                      />
                      <Icon
                        margin='8px 0 0 8px'
                        icon='fal fa-trash-alt'
                        color={theme.color.gray80}
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          if (address.isPrimary) {
                            notification.error({
                              message: 'การลบล้มเหลว',
                              description: 'กรุณาเปลี่ยนค่าค่าเริ่มต้นก่อน',
                            })
                          } else {
                            setBillingAddresses(
                              _.remove(
                                _.cloneDeep(billingAddresses),
                                (o) => o.id !== address.id,
                              ),
                            )
                          }
                        }}
                      />
                    </Div>
                  ))}
                  <Button
                    onlyIcon
                    icon='fal fa-plus'
                    margin='16px auto'
                    inverse
                    onClick={() => {
                      setBillingAddresses([
                        ...billingAddresses,
                        {
                          id:
                          _.size(billingAddresses) > 0
                            ? _.maxBy(billingAddresses, 'id').id + 1
                            : 1,
                          isPrimary: false,
                        },
                      ])
                    }}
                  />
                </Div>
              </Collapse>
            }
            {
              !quickAdd
              && <Collapse
                text='ผู้ติดต่อ'
                margin='16px 0'
                fullHeight
                open={openCollapse.contactAddress}
                onClick={() => setOpenCollapse({
                  ...openCollapse,
                  contactAddress: !openCollapse.contactAddress,
                })
                }
              >
                <Div
                  display='flex'
                  flexDirection='column'
                  padding='8px'
                  margin='8px 0'
                >
                  {contactAddresses.map((address) => (
                    <Div
                      key={address.id}
                      width='100%'
                      margin='8px 0'
                      display='flex'
                      alignItems='flex-start'
                    >
                      <Checkbox
                        margin='8px 8px 0 0'
                        checked={address.isPrimary}
                        data={{ value: `address${address.id}` }}
                        onChange={(e) => setContactAddresses(
                          contactAddresses.map((v) => {
                            if (!address.isPrimary) {
                              if (v.id === address.id) {
                                return {
                                  ...v,
                                  isPrimary: e.target.checked,
                                  isDefault: false,
                                }
                              }
                              return {
                                ...v,
                                isPrimary: false,
                                isDefault: false,
                              }
                            }
                            return v
                          }),
                        )
                        }
                      />
                      <Div display='flex' flexDirection='column' width='100%'>
                        <InputWithLabel
                          width='100%'
                          margin='8px auto'
                          key='line'
                          input={{ width: '100%', value: address.contactLineId }}
                          onChange={(e) => setContactAddresses(
                            contactAddresses.map((val) => {
                              if (val.id === address.id) {
                                return {
                                  ..._.omitBy(val, _.isNull),
                                  contactLineId: e.target.value,
                                  isDefault: false,
                                }
                              }
                              return val
                            }),
                          )
                          }
                          title={{ text: 'Line Id' }}
                        />
                        <InputWithLabel
                          width='100%'
                          margin='8px auto'
                          key='facebook'
                          input={{
                            width: '100%',
                            value: address.contactFacebook,
                          }}
                          onChange={(e) => setContactAddresses(
                            contactAddresses.map((val) => {
                              if (val.id === address.id) {
                                return {
                                  ..._.omitBy(val, _.isNull),
                                  contactFacebook: e.target.value,
                                  isDefault: false,
                                }
                              }
                              return val
                            }),
                          )
                          }
                          title={{ text: 'Facebook' }}
                        />
                      </Div>
                      <Icon
                        margin='8px 0 0 8px'
                        icon='fal fa-trash-alt'
                        color={theme.color.gray80}
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          if (address.isPrimary) {
                            notification.error({
                              message: 'การลบล้มเหลว',
                              description: 'กรุณาเปลี่ยนค่าค่าเริ่มต้นก่อน',
                            })
                          } else {
                            setContactAddresses(
                              _.remove(
                                _.cloneDeep(contactAddresses),
                                (o) => o.id !== address.id,
                              ),
                            )
                          }
                        }}
                      />
                    </Div>
                  ))}
                  <Button
                    onlyIcon
                    icon='fal fa-plus'
                    margin='16px auto'
                    inverse
                    onClick={() => {
                      setContactAddresses([
                        ...contactAddresses,
                        {
                          id:
                          _.size(contactAddresses) > 0
                            ? _.maxBy(contactAddresses, 'id').id + 1
                            : 1,
                          isPrimary: false,
                        },
                      ])
                    }}
                  />
                </Div>
              </Collapse>
            }
            {
              !quickAdd
              && <TextareaWithLabel
                onChange={(e) => setMutationVariables({
                  ...mutationVariables,
                  contactRemark: e.target.value,
                })
                }
                title={{
                  text: 'หมายเหตุ',
                  margin: '0 0 8px',
                }}
                textarea={{
                  defaultValue: !isCreateMode
                    ? infoData.customerDetail.data.contactRemark
                    : undefined,
                  style: { width: '100%' },
                  rows: 8,
                  margin: '16px 0 0',
                }}
              />
            }
            <Button
              loading={mutationLoading}
              disabled={
                mutationLoading
                || validateData(
                  isCreateMode,
                  getrequiredFields(),
                  _.omitBy(
                    mutationVariables,
                    (o) => _.isEmpty(o) && !_.isArray(o),
                  ),
                )
                || (first(phones)?.phoneNumber.length !== 10 && !quickAdd)
                || (
                  some(filter(deliveryAddresses, (o) => isNil(o.thirdPartyAddress)).map((address) => address.contactName), (o) => isNil(o) || isEmpty(o))
                  || some(filter(deliveryAddresses, (o) => isNil(o.thirdPartyAddress)).map((address) => address.addressNo), (o) => isNil(o) || isEmpty(o))
                  || some(filter(deliveryAddresses, (o) => isNil(o.thirdPartyAddress)).map((address) => address.contactPhoneNumber), (o) => isNil(o) || isEmpty(o))
                  || some(filter(deliveryAddresses, (o) => isNil(o.thirdPartyAddress)).map((address) => address.postcode), (o) => isNil(o) || isEmpty(o))
                  || some(filter(deliveryAddresses, (o) => isNil(o.thirdPartyAddress)).map((address) => address.subDistrict), (o) => isNil(o) || isEmpty(o))
                  || some(filter(deliveryAddresses, (o) => isNil(o.thirdPartyAddress)).map((address) => address.district), (o) => isNil(o) || isEmpty(o))
                  || some(filter(deliveryAddresses, (o) => isNil(o.thirdPartyAddress)).map((address) => address.province), (o) => isNil(o) || isEmpty(o))
                )
              }
              text={isCreateMode ? 'สร้างลูกค้า' : 'แก้ไขลูกค้า'}
              inverse
              color={theme.color.success}
              icon={isCreateMode ? 'far fa-plus' : 'far fa-edit'}
              style={{ alignItems: 'center' }}
              width='150px'
              margin='20px auto'
              onClick={() => {
                const shippingAddressData = addressMutationData(deliveryAddresses, 'SHIPPING')
                const contactAddressData = addressMutationData(contactAddresses, 'CONTACT')
                const billingAddressData = addressMutationData(billingAddresses, 'BILLING')
                const createVariables = _.omitBy(
                  {
                    ...mutationVariables,
                    birthDate: moment(mutationVariables.birthDate).format('YYYY-MM-DD'),
                    contactPhoneNumber: phones.map((v) => _.omit(v, ['id'])),
                    contactEmail: emails.map((v) => _.omit(v, ['id'])),
                    shippingAddress: _.isEmpty(shippingAddressData)
                      ? null
                      : shippingAddressData,
                    contactAddress: _.isEmpty(contactAddressData)
                      ? null
                      : contactAddressData,
                    billingAddress: _.isEmpty(billingAddressData)
                      ? null
                      : billingAddressData,
                  },
                  _.isNull,
                )
                doAction({
                  variables: isCreateMode
                    ? createVariables
                    : { ...createVariables, id: parseInt(id) },
                }).then((resp) => {
                  const KEY = isCreateMode ? 'createCustomer' : 'updateCustomer'
                  if (resp.data[KEY].success) {
                    message.success(resp.data[KEY].message)
                    if (onCreateSuccess) {
                      onCreateSuccess(resp.data[KEY].data)
                    } else {
                      history.push('/customers')
                    }
                  } else message.error(resp.data[KEY].message)
                })
              }}
            />
          </Div>
        )
      ) : null}
    </Div>
  )
}

const CustomerForm = (props) => {
  const isCreateMode = props?.createMode || _.includes(props?.match?.url, 'create')
  const { loading: infoLoading, data: infoData } = useQuery(CUSTOMER, {
    skip: isCreateMode,
    variables: { id: parseInt(props.match?.params?.id) },
  })
  const authorization = useContext(AuthorizationContext)
  const clientAutho = authorization.authorization?.client || {}

  useEffect(() => {
    if (
      (isCreateMode && !clientAutho.create)
      || (!isCreateMode && !clientAutho.editBasic)
    ) {
      permissionRedirect()
    }
  }, [])

  if (!props?.createMode) {
    return (
      <Div>
        <Helmet>
          <meta name='viewport' content='width=device-width, initial-scale=1.0' />
        </Helmet>
        <FullPageContainer
          loading={infoLoading}
          title={[
            {
              text: 'Customers',
              icon: 'fad fa-user-tie',
            },
          ]}
          detail={{
            title: [
              {
                text: isCreateMode ? 'สร้างลูกค้าใหม่' : 'แก้ไขลูกค้าใหม่',
                icon: isCreateMode ? 'far fa-plus' : 'far fa-edit',
              },
            ],
          }}
        >
          <Page
            authorization={clientAutho}
            infoLoading={infoLoading}
            infoData={infoData}
            isCreateMode={isCreateMode}
            id={parseInt(props?.match?.params?.id)}
          />
        </FullPageContainer>
      </Div>
    )
  }
  return (
    <Div>
      <Helmet>
        <meta name='viewport' content='width=device-width, initial-scale=1.0' />
      </Helmet>
      <Page
        authorization={clientAutho}
        infoLoading={infoLoading}
        infoData={infoData}
        isCreateMode={isCreateMode}
        id={parseInt(props?.match?.params?.id)}
        onCreateSuccess={(res) => props?.onCreateSuccess(res)}
        quickAdd={props.quickAdd}
        containerPadding='0'
      />
    </Div>
  )
}

export default CustomerForm
