import { LeftOutlined } from '@ant-design/icons'
import { useMutation, useQuery } from '@apollo/client'
import { Button, Drawer, message, Skeleton, Switch } from 'antd'
import {
  Div, Icon, SmallText,
} from 'konsys-ui'
import { find, flatten, isEmpty } from 'lodash'
import React, { useState } from 'react'
import {
  UPDATE_ROLE_PERMISSION,
} from '../../../api/mutation'
import { GET_ROLES_MOBILE } from '../../../api/query'
import { P } from '../../../components/common/konsys-ui'
import { theme } from '../../../styles/_variables'
import { roleTitle } from '../util-functions'

const PermissionRoleList = ({
  permissionDetail, roleList, refetch, authorization, closePermissionRoleList,
}) => {
  const [doUpdateRolePermission] = useMutation(UPDATE_ROLE_PERMISSION)

  const callUpdateRolePerm = (roleId, permissionId) => {
    doUpdateRolePermission({
      variables: {
        roleId,
        permissionId,
      },
    }).then((resp) => {
      if (resp.data.updateRolePermission.success) {
        message.success(resp.data.updateRolePermission.message)
      } else {
        message.error(resp.data.updateRolePermission.message)
      }
      if (refetch) refetch()
    })
  }
  return (
    <Div padding='16px'>
      <Div display='flex' justifyContent='space-between' width='100%' alignItems='center'>
        <Button type="primary" shape="round" icon={<LeftOutlined />} style={{ marginRight: 16 }} onClick={() => closePermissionRoleList(false)}/>
        <P bold color={theme.color.primaryColor}>{permissionDetail.title}</P>
      </Div>
      {
        roleList?.map((roleDetail) => (
          <Div key={roleDetail?.id} padding='16px 0' display='flex' alignItems='center' justifyContent='space-between' moreStyle={{ borderBottom: `1px solid ${theme?.color?.gray10}` }}>
            <SmallText bold>{roleDetail?.name}</SmallText>
            <Switch
              defaultChecked={roleDetail?.active}
              onChange={() => callUpdateRolePerm(roleDetail?.id, permissionDetail?.id)}
              disabled={!authorization?.Permission?.all}
            />
          </Div>
        ))
      }
    </Div>
  )
}

export const Role = () => {
  const [drawerDetail, setDrawerDetail] = useState({
    visible: false,
  })
  const roles = useQuery(GET_ROLES_MOBILE)

  if (roles.loading && isEmpty(roles.data)) {
    return <Div bgColor={theme.color.offWhite} padding='16px'>
      <Skeleton active/>
      <Skeleton active/>
      <Skeleton active/>
      <Skeleton active/>
      <Skeleton active/>
    </Div>
  }

  const closeDrawer = () => setDrawerDetail({ ...drawerDetail, visible: false })

  const module = roles?.data?.newRolePermissionList?.data?.map((rolePermData) => (
    {
      name: rolePermData?.moduleName,
      permissionList: rolePermData?.roleList?.[0]?.permissionList?.map((pmDetail) => (
        {
          id: pmDetail?.id,
          name: pmDetail?.description,
          key: pmDetail?.name,
          roleList: rolePermData?.roleList?.map((roleData) => (
            {
              id: roleData?.role?.id,
              name: roleData?.role?.description,
              key: roleData?.role?.name,
              active: find(roleData?.permissionList, (obj) => obj?.id === pmDetail?.id)?.status,
            }
          )),
        }
      )),
    }
  ))

  const getAllPermissionList = module?.map((obj) => obj?.permissionList)
  const findRole = !isEmpty(drawerDetail?.permissionDetail) && find(flatten(getAllPermissionList), (obj) => obj?.id === drawerDetail?.permissionDetail?.id)

  const drawer = (
    <Drawer
      placement='right'
      closable={false}
      width='100%'
      visible={drawerDetail?.visible}
      bodyStyle={{ padding: 0, height: '100vh' }}
      style={{ transform: 'translateX(0) !important' }}
    >
      <PermissionRoleList
        closePermissionRoleList={closeDrawer}
        permissionDetail={drawerDetail?.permissionDetail}
        roleList={findRole?.roleList}
        refetch={roles?.refetch}
        authorization={{ Permission: {
          all: true,
        } }}
      />
    </Drawer>
  )

  return (
    <Div padding='16px' style={{ overflow: 'auto', background: theme.color.offWhite }}>
      { drawerDetail?.visible && drawer }
      {
        module?.map((moduleDetail, i) => (
          <Div key={moduleDetail?.name} margin={i !== 0 ? '32px 0 0' : '8px 0 0'}>
            <P bold color={theme.color.primaryColor} margin='0 0 8px'>{roleTitle[moduleDetail.name]}</P>
            {
              moduleDetail?.permissionList?.map((pe) => (
                <Div key={pe?.name} padding='16px 0' display='flex' alignItems='center' justifyContent='space-between' moreStyle={{ cursor: 'pointer', borderBottom: `1px solid ${theme?.color?.gray10}` }} onClick={() => setDrawerDetail({ ...drawerDetail, visible: true, permissionDetail: { title: pe.name, id: pe?.id } })}>
                  <SmallText bold>{pe.name}</SmallText>
                  <Icon icon='fal fa-chevron-right' color={theme?.color?.gray20} />
                </Div>
              ))
            }
          </Div>
        ))
      }
    </Div>
  )
}

export default Role
