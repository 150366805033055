import React, { useState, useEffect } from 'react'
import moment from 'moment-timezone'
import _ from 'lodash'
import { Link } from 'react-router-dom'
import numeral from 'numeral'
import { useQuery, useMutation } from '@apollo/client'
import { Row, Col, message } from 'antd'
import { Div, SmallText, P, Button, Input, Label } from '@konsys-ui-custom'
import {
  Tab,
  Divider,
  SmallTextWithLabel,
  TextareaWithLabel,
  Uploader,
  PaymentMethodFormInAccount,
} from '../../../components'
import { GET_RECEIPT, FINNIC_BANKS } from '../../../api/query'
import { UPDATE_RECEIPT } from '../../../api/mutation'
import { PAYMENT_TYPE } from '../../sale-orders/constants'
import { theme } from '../../../styles/_variables'

const Form = ({ data, loading }) => {
  const [variables, setVariables] = useState({ files: [] })

  const [doUpdate, { loading: updating }] = useMutation(UPDATE_RECEIPT, {
    refetchQueries: ['remainingJob', 'remainingAccountant'],
  })

  useEffect(() => {
    if (!loading) {
      setVariables({
        files: data.receiptInfo.data.files.map((file) => ({
          name: file.fileName,
          url: file.url,
          uid: file.id,
        })),
        finnicBankId: data.receiptInfo.data.finnicBank?.id,
        paidDate: moment(data.receiptInfo.data.paidDate),
        paidTime: data.receiptInfo.data.paidTime
          ? moment(data.receiptInfo.data.paidTime, 'HH:mm:ss').format('HH:mm')
          : moment().format('HH:mm'),
        remark: data.receiptInfo.data.remark,
        reference: data.receiptInfo.data.reference,
        paymentMethod: data.receiptInfo.data.paymentMethod,
        chequeDate: data.receiptInfo.data.chequeDate
          ? moment(data.receiptInfo.data.chequeDate)
          : moment(),
        chequeNumber: data.receiptInfo.data.chequeNumber,
        creditCard: data.receiptInfo.data.creditCard,
        referenceNO: data.receiptInfo.data.referenceNO,
        fee: data.receiptInfo.data.fee,
      })
    }
  }, [data])
  const { data: finnicBankData, loading: finnicBankLoading } = useQuery(FINNIC_BANKS)
  const finnicBankList = finnicBankLoading || _.isEmpty(finnicBankData)
    ? []
    : finnicBankData.finnicBankList.data.map((v) => ({
      text: `${v.bank?.name ? v.bank.name : '-'} ${
            v.bankAccountNumber ? `(${v.bankAccountNumber})` : '(-)'
          }`,
      value: v.id,
      number: v.bankAccountNumber,
    }))
  const customer = loading ? {} : data.receiptInfo.data.customer
  // const invoices = loading ? {} : data.receiptInfo.data.invoices

  return (
    <Div>
      <Tab
        loading={loading}
        white
        title={[
          {
            text: 'แก้ไขใบเสร็จ',
            icon: 'far fa-pencil',
          },
        ]}
      >
        {loading ? null : (
          <Div margin="0 0 24px">
            <P bold>รายละเอียด</P>
            <Divider />
            <Row gutter={16} type="flex" style={{ marginBottom: 8 }}>
              <Col span={1} />
              <Col span={4}>
                <SmallTextWithLabel
                  title={{ text: 'ชื่อลูกค้า' }}
                  content={{
                    text: `${
                      !_.isNil(customer.prefix) ? `${customer.prefix} ` : ''
                    }${customer.name}`,
                  }}
                />
              </Col>
              <Col span={4}>
                <SmallTextWithLabel
                  title={{ text: 'ประเภทการชำระเงิน' }}
                  content={{
                    text:
                      customer.paymentType === PAYMENT_TYPE.COD
                        ? 'ชำระเงินปลายทาง'
                        : 'โอนเงิน',
                  }}
                />
              </Col>
              <Col span={4}>
                <SmallTextWithLabel
                  title={{ text: 'เบอร์สำนักงาน' }}
                  content={{ text: customer.contactFaxNumber }}
                />
              </Col>
              <Col span={4}>
                <SmallTextWithLabel
                  title={{ text: 'ชื่อผู้ติดต่อ' }}
                  content={{ text: customer.contactName }}
                />
              </Col>
              <Col span={4}>
                <SmallTextWithLabel
                  title={{ text: 'เบอร์ผู้ติดต่อ' }}
                  content={{ text: customer.contactPhoneNumber }}
                />
              </Col>
            </Row>
            <PaymentMethodFormInAccount
              paymentDate={{
                value: variables.paidDate,
                onChange: (value) => setVariables({ ...variables, paidDate: value }),
              }}
              paymentTime={{
                value: variables.paidTime,
                onChange: (value) => setVariables({ ...variables, paidTime: value }),
              }}
              reference={{
                value: variables.reference,
                onChange: (value) => setVariables({ ...variables, reference: value }),
              }}
              paymentMethod={{
                value: variables.paymentMethod,
                onChange: (value) => setVariables({ ...variables, paymentMethod: value }),
              }}
              account={{
                list: finnicBankList,
                value: variables.finnicBankId,
                onChange: (value) => setVariables({ ...variables, finnicBankId: value }),
              }}
              cheque={{
                date: {
                  value: variables.chequeDate,
                  onChange: (value) => setVariables({ ...variables, chequeDate: value }),
                },
                number: {
                  value: variables.chequeNumber,
                  onChange: (value) => setVariables({ ...variables, chequeNumber: value }),
                },
              }}
              creditCard={{
                number: {
                  value: variables.creditCard,
                  onChange: (value) => setVariables({ ...variables, creditCard: value }),
                },
                ref: {
                  value: variables.referenceNO,
                  onChange: (value) => setVariables({ ...variables, referenceNO: value }),
                },
              }}
            />
            <Div>
              <Row gutter={16} type="flex" style={{ margin: '16px 0 8px 0' }}>
                <Col span={1} />
                {data.receiptInfo.data.paymentType === 'CREDIT' && (
                  <Col span={3}>
                    <Label bold>Invoice</Label>
                  </Col>
                )}
                <Col span={3}>
                  <Label bold>วันที่</Label>
                </Col>
                <Col span={3}>
                  <Label bold>วันที่กำหนดชำระ</Label>
                </Col>
                {data.receiptInfo.data.paymentType === 'CREDIT' && (
                  <Col span={3}>
                    <Label bold>เลขที่ Bill</Label>
                  </Col>
                )}
                <Col span={3}>
                  <Label bold>เลขที่ SO</Label>
                </Col>
                <Col span={3}>
                  <Label bold>เลขที่ PT</Label>
                </Col>
                <Col span={3}>
                  <Label bold>ราคารวม</Label>
                </Col>
              </Row>
              {data.receiptInfo.data.lots.map((v, i) => (
                <Row
                  key={v.id}
                  gutter={16}
                  type="flex"
                  style={{ marginBottom: 8 }}
                >
                  <Col span={1}>
                    <SmallText>{i + 1}.</SmallText>
                  </Col>
                  {data.receiptInfo.data.paymentType === 'CREDIT' && (
                    <Col span={3}>
                      <SmallText>
                        {data.receiptInfo.data.paymentType === 'CASH'
                          ? '-'
                          : `#${v.invoice.number}`}
                      </SmallText>
                    </Col>
                  )}
                  <Col span={3}>
                    <SmallText>
                      {data.receiptInfo.data.paymentType === 'CASH'
                        ? '-'
                        : moment(v.invoice.createDate).format('DD/MM/YYYY')}
                    </SmallText>
                  </Col>
                  <Col span={3}>
                    <SmallText>
                      {data.receiptInfo.data.paymentType === 'CASH'
                        ? '-'
                        : moment(v.invoice.paymentDueDate).format('DD/MM/YYYY')}
                    </SmallText>
                  </Col>
                  {data.receiptInfo.data.paymentType === 'CREDIT' && (
                    <Col span={3}>
                      <SmallText>
                        {data.receiptInfo.data.paymentType === 'CASH'
                          ? '-'
                          : `#${v.invoice.billingNote?.number}`}
                      </SmallText>
                    </Col>
                  )}
                  <Col span={3}>
                    <SmallText>#{v.salesOrder.soNumber}</SmallText>
                  </Col>
                  <Col span={3}>
                    <SmallText>#{v.pickingTicket.number}</SmallText>
                  </Col>
                  <Col span={3}>
                    <SmallText>
                      {numeral(v.subtotal).format('0,0.00')} บาท
                    </SmallText>
                  </Col>
                </Row>
              ))}
            </Div>
            <Divider />
            <Divider margin="16px 0" color={theme.color.gray80} />
            <Div
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <SmallText bold margin="0 16px 0 0">
                ยอดชำระ
              </SmallText>
              <SmallText bold>{`${numeral(
                _.sum(data.receiptInfo.data.lots.map((lot) => lot.subtotal)),
              ).format('0,0.00')} บาท`}</SmallText>
            </Div>
            <Div
              margin="8px 0"
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <SmallText bold>ค่าธรรมเนียมธนาคาร</SmallText>
              <Div display="flex" alignItems="center">
                <Input
                  value={variables.fee}
                  state="default"
                  // rule={{ type: 'int' }}
                  margin="0 8px 0 0"
                  onChange={(e) => setVariables({
                    ...variables,
                    fee: parseFloat(e.target.value),
                  })
                  }
                />
                <SmallText bold>บาท</SmallText>
              </Div>
            </Div>
            <TextareaWithLabel
              width="100%"
              onChange={(e) => setVariables({ ...variables, remark: e.target.value })
              }
              title={{
                text: 'หมายเหตุ',
                margin: '0 0 8px',
              }}
              textarea={{
                value: variables.remark,
                style: { width: '100%' },
                rows: 8,
                margin: '16px 0 0',
              }}
            />
            <Uploader
              isMultiple
              buttonText="คลิกเพื่อเลือกไฟล์"
              inverse={false}
              icon="fal fa-paperclip"
              onChange={(e) => {
                setVariables({ ...variables, files: e })
              }}
              data={variables.files.map((file) => {
                if (file.url) {
                  return {
                    name: file.name,
                    url: file.url,
                    uid: file.uid,
                  }
                }
                return file
              })}
            />
            <Div
              width="100%"
              display="flex"
              justifyContent="center"
              margin="16px 0"
            >
              <Link to="/account">
                <Button
                  text="ยกเลิก"
                  icon="far fa-arrow-left"
                  margin="0 16px 0 0"
                  ghost
                  disabled={updating}
                />
              </Link>
              <Button
                text="แก้ไขใบเสร็จ"
                icon="far fa-check"
                color={theme.color.success}
                disabled={updating}
                loading={updating}
                inverse
                onClick={() => doUpdate({
                  variables: {
                    id: data.receiptInfo.data.id,
                    paymentMethod: variables.paymentMethod,
                    remark: variables.remark,
                    reference: variables.reference,
                    fee: variables.fee,
                    paidDate: variables.paidDate
                      ? moment(variables.paidDate).format('YYYY-MM-DD')
                      : null,
                    chequeDate: variables.chequeDate
                      ? moment(variables.chequeDate).format('YYYY-MM-DD')
                      : null,
                    paidTime: variables.paidTime,
                    chequeNumber: variables.chequeNumber,
                    creditCard: variables.creditCard,
                    referenceNO: variables.referenceNO,
                    finnicBankId: variables.finnicBankId,
                    files: variables.files.map((file) => {
                      if (file.url) {
                        return { ...file, id: file.uid }
                      }
                      return file
                    }),
                  },
                }).then((res) => {
                  if (res.data.updateReceipt.success) { message.success(res.data.updateReceipt.message) } else message.error(res.data.updateReceipt.message)
                })
                }
              />
            </Div>
          </Div>
        )}
      </Tab>
    </Div>
  )
}

export default (props) => {
  const { data, loading } = useQuery(GET_RECEIPT, {
    variables: { id: parseInt(props.match.params.id) },
  })
  return <Form data={data} loading={loading} />
}
